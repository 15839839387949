import * as React from 'react';
import classnames from 'classnames';
import {
  DropTarget,
  // DropTargetConnector,
  // DropTargetMonitor,
  // DragSource,
  // DragSourceMonitor,
  // DragSourceCollector,
  // ConnectDropTarget,
} from 'react-dnd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

const Types = {
  CARD: 'card',
};

const cardBullseye = {
  canDrop(props, monitor, component) {
    if (!props.canDrop) return false; // The user must have permission to drop onto to the target (e.g., be an author)

    const { item } = monitor.getItem();

    return item.point._id !== props.point._id; // Can't drop a Point into itself
  },
  drop(props, monitor) {
    // When dropped on a compatible target, do something
    // const { item } = monitor.getItem();

    const sourceType = props.point.isCollection ? 'collection' : 'point';

    // console.log('Drop on target - going to update target info.');
    props.actions.updateTargetInfo(sourceType, props.point._id, 0, 0);

    return { dropTarget: 'bullseye' };
  },
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

const cardBullseyeComponent = props => {
  const {
    connectDropTarget,
    isOver,
    // isDragging,
    canDrop,
    dnd,
    // toolTipText,
  } = props;

  // if (!canDrop) return null;
  // const display = dnd.isDragging || canDrop;
  let className = classnames(
    'bullseye m-0 pt-4 pb-4 text-center av-blue position-absolute bg-white',
    {
      hover: isOver,
      // 'd-block': canDrop,
      'can-drop': canDrop,
      // 'd-none': 'none',
    },
  );

  return connectDropTarget(<h5 className={className}>+ Sub-point</h5>);
};

const DnDcardBullseyeComponent = DropTarget(
  Types.CARD,
  cardBullseye,
  dropCollect,
)(cardBullseyeComponent);

function mapStateToProps(state, ownProps) {
  // const { user } = state.user;
  // // let canDrop = ownProps.point.authorId == user._id; // only the author of the Point can drop into the Point
  // const pointObject = state.points[ownProps.point._id];
  // let canDrop =
  //   pointObject &&
  //   pointObject.permissionLevel === 'write' &&
  //   pointObject.invitationStatus === 'accepted'; // isOwner
  // if (state.page.dnd.sourceInfo.type == 'collection') {
  //   if (!ownProps.point.isCollection) {
  //     canDrop = false; // can't drop a collection into Point
  //   }
  // }
  // return {
  //   user,
  //   dnd: state.page.dnd,
  //   // dndDragParent: state.page.dnd.sourceInfo && state.page.dnd.sourceInfo.dragParent,
  //   // dndDragParentLevel: state.page.dnd.sourceInfo && state.page.dnd.sourceInfo.dragParent && state.page.dnd.sourceInfo.dragParent.level,
  //   // canDrop,
  //   // canDrag: false,
  // };
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export const DnDBullseye = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DnDcardBullseyeComponent);

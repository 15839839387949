import * as React from 'react';
import * as Actions from '../actions/index';

// function showHeader (state,action) {
//             return {
// 				showHeader : action.data.showHeader
//             }
// }

export default function page(
  state = {
    showHeader: true,
    parentStack: [],
    parentIndex: null,
    dnd: initialDnD,
    cloudinary: {
      imageId: null,
      isOpen: false,
      onLicenseTermsAccepted: null,
    },
  },
  action,
) {
  switch (action.type) {
    case Actions.SHOW_HEADER:
      return {
        ...state,
        showHeader: action.data.showHeader,
      };
      break;
    case Actions.SET_PARENT:
      var newParentStack = state.parentStack.concat(action.data.parentObject);
      return {
        ...state,
        parentStack: newParentStack,
      };
      break;
    case Actions.SET_PARENT_INDEX:
      var newParentStack = state.parentStack;
      newParentStack[newParentStack.length - 1].index = action.data.index;
      return Object.assign({}, state, {
        parentStack: newParentStack,
      });

    case Actions.REMOVE_PARENT:
      var newParentStack = state.parentStack.slice(
        0,
        state.parentStack.length - 1,
      );
      return {
        ...state,
        parentStack: newParentStack,
      };
    case Actions.CLEAR_PARENT_STACK:
      return {
        ...state,
        parentStack: [],
      };
    case Actions.SET_RECENT_TAGS:
      return {
        ...state,
        recentTags: action.data.recentTags,
      };
    case Actions.SET_RECENT_SOURCE_INFO:
      return {
        ...state,
        recentSourceInfo: action.data.recentSourceInfo,
      };
    case 'START_DND':
      // return {
      //   ...state,
      //   dnd: {
      //     isDragging: true,
      //     sourceInfo: {
      //       type: action.data.sourceType,
      //       id: action.data.sourceId,
      //       originalParent: {...action.data.originalParent},
      //       dragParent: {...action.data.originalParent},
      //     },
      //     targetParent: {...state.dnd.targetParent},
      //     lastMoveTimeStamp: 0
      //   },
      // };
      state.dnd = {
        isDragging: true,
        sourceInfo: {
          type: action.data.sourceType,
          id: action.data.sourceId,
          originalParent: action.data.originalParent,
          dragParent: action.data.originalParent,
        },
        targetParent: state.dnd.targetParent,
        lastMoveTimeStamp: 0,
      };

      return state;
    case 'UPDATE_DRAG_INFO':
      return {
        ...state,
        dnd: {
          ...state.dnd,
          sourceInfo: {
            ...state.dnd.sourceInfo,
            x: action.data.x,
            y: action.data.y,
          },
        },
      };
    case 'END_DRAG':
      return {
        ...state,
        dnd: {
          ...state.dnd,
          isDragging: false,
        },
      };
    case Actions.MOVE_SUBPOINT: {
      const {
        // dragParent,
        targetParent,
      } = action;
      console.log('Page reducer DND targetParent');
      console.log(targetParent);
      // return {
      //   ...state,
      //   dnd: {
      //     ...state.dnd,
      //     sourceInfo: {
      //       ...state.dnd.sourceInfo,
      //       dragParent: { ...targetParent },
      //     },
      //     lastMoveTimeStamp: Date.now(),
      //   },
      // };
      state.dnd = {
        ...state.dnd,
        sourceInfo: {
          ...state.dnd.sourceInfo,
          dragParent: { ...targetParent },
        },
        lastMoveTimeStamp: Date.now(),
      };
      return state;
    }
    case 'UPDATE_TARGET_INFO':
      return {
        ...state,
        dnd: {
          ...state.dnd,
          targetParent: { ...action.data.targetParent },
        },
      };
    case 'CLEAR_DND':
      return {
        ...state,
        dnd: { ...initialDnD },
      };
    case 'SET_CLOUDINARY':
      const newCloudinary = {};
      if (action.imageId) newCloudinary.imageId = action.imageId;
      if (action.isOpen !== undefined) newCloudinary.isOpen = action.isOpen;
      if (action.onLicenseTermsAccepted)
        newCloudinary.onLicenseTermsAccepted = action.onLicenseTermsAccepted;
      return {
        ...state,
        cloudinary: { ...newCloudinary },
      };
    default:
      return state;
  }
}

const initialDnD = {
  isDragging: false,
  sourceInfo: {
    type: null, // collectionPoint, Point
    id: null,
    x: null,
    y: null,
    originalParent: {
      type: null, // portfolio, collectionPoint, Point
      id: null,
      level: null,
      index: null,
    },
  },
  targetParent: {
    type: null, // collectionPoint, Point
    id: null,
    level: null,
    index: null,
  },
};

import * as Actions from './../actions/index';

const profileImage = (state = {}, action) => {
  switch (action.type) {
    case Actions.SET_CHECKING_CLOUDINARY:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          checkingCloudinary: action.payload.checkingCloudinary,
        },
      };
    case Actions.SET_IMAGE_AVAILABLE_IN_CLOUDINARY:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          checkingCloudinary: false,
          isImageAvailable: action.payload.isImageAvailable,
        },
      };
    default:
      return state;
  }
};

export default profileImage;

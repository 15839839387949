import * as React from "react";
import {Modal} from 'react-bootstrap';
import SupportersOpponentsComponent from './SupportersOpponentsComponent';

export default class SupportersOpponentsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} dialogClassName="supporters-opposers-modal" bsSize="sm">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <SupportersOpponentsComponent
                            supporters={this.props.supporters}
                            opponents={this.props.opponents}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

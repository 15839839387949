import React from 'react';
import _ from 'lodash';

import QTPQuestions from 'helpers/QTP.json';

const variables = ['why', 'whatsYourTake'];

export default function QTPSummary({ qtps }) {
  function renderSummary(question, optionText, count) {
    if (!count) {
      return null;
    }

    const option = question.options.find(i => i.text === optionText);

    if (!option) {
      return null;
    }

    const aggregateText = option.aggregateText;

    if (!aggregateText) {
      return null;
    }

    return (
      <div className="d-flex align-items-center mb-2" key={optionText}>
        <span
          className="pt-2 pb-2 pl-3 pr-3 d-inline-block bg-av-light-blue font-weight-600 av-blue"
          style={{ borderRadius: '2px' }}
        >
          {count}
        </span>
        <div className="ml-3 gray-4">
          {aggregateText[0]}
          <span className="font-weight-600 av-blue">{aggregateText[1]}</span>
        </div>
      </div>
    );
  }

  return variables.map(variable => {
    const question = QTPQuestions.find(i => i.variable === variable);
    const aggregates = _.groupBy(qtps, i => i.surveyResponse[variable]);
    const options = Object.keys(aggregates).filter(i => i !== 'undefined');

    if (!options.length) {
      return null;
    }

    const max = options.reduce((a, b) => {
      return aggregates[a].length > aggregates[b].length ? a : b;
    });

    return renderSummary(question, max, aggregates[max].length);
  });
}

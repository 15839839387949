import React, { forwardRef, Fragment, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from 'redux/actions/index';
import QuestionText from './QuestionText';
import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';

function MultipleChoicePointQuestion(
  { question, onChange, model, point },
  ref,
) {
  useImperativeHandle(ref, () => ({
    allowContinue: () => {
      const value = getValue();
      return !question.required || value.length;
    },
  }));

  function getValue() {
    return model[question.variable] || [];
  }

  function isChecked(option) {
    return getValue().indexOf(option.text) !== -1;
  }

  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderCheckbox(option, key) {
    const value = getValue();

    const checked = isChecked(option);

    return (
      <Checkbox
        onClick={e => {
          e.preventDefault();

          if (checked) {
            value.splice(value.indexOf(option.text), 1);
            onChange([...value]);
          } else {
            onChange([...value, option.text]);
          }
        }}
        onChange={e => {}}
        checked={checked}
        key={key}
      >
        {option.text}
      </Checkbox>
    );
  }

  function renderCheckboxes() {
    return question.options.map((option, index) => {
      const supportingCount = point.subPoints.filter(
        ({ stance }) => stance === 'For',
      ).length;
      const opposingCount = point.subPoints.filter(
        ({ stance }) => stance === 'Against',
      ).length;

      if (
        option.conditionType === 'NO_SUPPORTING_FACTS' &&
        supportingCount > 0
      ) {
        return null;
      }

      if (option.conditionType === 'SUPPORTING' && supportingCount === 0) {
        return null;
      }

      if (option.conditionType === 'OPPOSING' && opposingCount === 0) {
        return null;
      }

      return renderCheckbox(option, index);
    });
  }

  return (
    <Fragment>
      {renderText()}
      {renderCheckboxes()}
    </Fragment>
  );
}

export default forwardRef(MultipleChoicePointQuestion);

// function mapStateToProps(state) {
//   return {
//     points: state.points,
//     user: state.user,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(Actions, dispatch),
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(forwardRef(MultipleChoicePointQuestion));

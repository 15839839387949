import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import * as Actions from '../../../redux/actions';
import { Icon, ICONS } from '../../Icons';

import './Badges.scss';

function Badges({
  onClose,
  badges,
  notEarnedBadges = [],
  openBadgeDetailsModal,
}) {
  const renderBadge = (badge, notEarned, totalBadges = 0) => (
    <div
      className="badge-container"
      onClick={() => {
        openBadgeDetailsModal(badge, notEarned);
      }}
    >
      <img src={badge.image} />
      {totalBadges > 1 && <div className="badge-count">{totalBadges}</div>}
      <div className="badge-name">{badge.badge}</div>
    </div>
  );

  const showBadges = (badge) => {
    return renderBadge(badge, false, badge.earnedLevel);
  };

  const regularBadges = notEarnedBadges.filter((b) => !b.forWeek);
  const specialBadges = notEarnedBadges.filter((b) => b.forWeek);

  return (
    <Modal show={true} className="badges-modal">
      <Modal.Body>
        <div className="header">
          <span>Earned Badges</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="badges">{badges.map(showBadges)}</div>
        {regularBadges.length > 0 && (
          <React.Fragment>
            <div className="header mt-3">
              <span>Other Badges</span>
            </div>
            <div className="badges inactive">
              {regularBadges.map((badge) => renderBadge(badge, true))}
            </div>
          </React.Fragment>
        )}
        {specialBadges.length > 0 && (
          <React.Fragment>
            <div className="header mt-3">
              <span>Special Badges</span>
            </div>
            <div className="badges inactive">
              {specialBadges.map((badge) => renderBadge(badge, true))}
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openBadgeDetailsModal: (badge, notEarned) => {
      const props = {
        badge,
        notEarned,
      };
      dispatch(Actions.openModal('badge-details', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Badges);

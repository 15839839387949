import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Icon, ICONS } from 'components/Icons';
import QuestionText from './QuestionText';

export default function InfoQuestion({ question }) {
  return (
    <div className="info-question">
      <QuestionText question={question} />
      <Sections sections={question.sections} />
    </div>
  );
}

function Sections({ sections }) {
  return sections.map(section => {
    switch (section.type) {
      case 'TEXT':
        return <TextSection section={section} />;
      case 'LIST':
        return <ListSection section={section} />;
      case 'CAROUSEL':
        return <CarouselSection section={section} />;
    }
  });
}

function TextSection({ section }) {
  return <p>{section.text}</p>;
}

function ListSection({ section }) {
  return (
    <ul>
      {section.items.map(i => (
        <li>{i}</li>
      ))}
    </ul>
  );
}

function CarouselSection({ section }) {
  const [interval, setInterval] = useState(4000);

  function renderVideo(item) {
    return <video src={item.url} controls className="w-100" />;
  }

  function renderImage(item) {
    return <img src={item.url} className="w-100" />;
  }

  function renderCarouselItem(item) {
    switch (item.type) {
      case 'IMAGE':
        return renderImage(item);
      case 'VIDEO':
        return renderVideo(item);
      default:
        return null;
    }
  }

  function renderCarouselItems() {
    return section.media.map((item, index) => {
      return (
        <Carousel.Item key={index}>{renderCarouselItem(item)}</Carousel.Item>
      );
    });
  }

  if (section.media.length === 1) {
    return renderCarouselItem(section.media[0]);
  } else {
    return (
      <Carousel
        controls={true}
        interval={interval}
        indicators={true}
        className="carousel-section"
        nextIcon={<Icon icon={ICONS.CHEVRON_RIGHT} />}
        prevIcon={<Icon icon={ICONS.CHEVRON_LEFT} />}
        onSelect={(_eventKey, event) => {
          if (event.type === 'click') {
            setInterval(null);
          }
        }}
      >
        {renderCarouselItems()}
      </Carousel>
    );
  }
}

import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';

// import * as groupSvc from 'services/groups.svc';
import { UPDATE_POINTS, pointCreated, updatePoint } from './point';
import { toast } from 'components/elements/molecules/Toast/Toast';
import { resolved, rejected } from '../createAction';

export const ADD_COLLECTION = 'ADD_COLLECTION';
export const LOAD_COLLECTION = 'LOAD_COLLECTION';
export const COLLECTION_CREATED = 'COLLECTION_CREATED';
export const COLLECTION_UPDATED = 'COLLECTION_UPDATED';
export const COLLECTION_DELETED = 'COLLECTION_DELETED';

export const USERS_INVITED_TO_COLLECTION = 'USERS_INVITED_TO_COLLECTION';
export const POINT_ADDED_TO_COLLECTION = 'POINT_ADDED_TO_COLLECTION';
export const FACT_ADDED_TO_COLLECTION = 'FACT_ADDED_TO_COLLECTION';
export const FACT_REMOVED_FROM_COLLECTION = 'FACT_REMOVED_FROM_COLLECTION';
export const POINT_REMOVED_FROM_COLLECTION = 'POINT_REMOVED_FROM_COLLECTION';
export const COLLECTION_REMOVED_FROM_PORTFOLIO =
  'COLLECTION_REMOVED_FROM_PORTFOLIO';
export const COLLECTION_ADDED_TO_PORTFOLIO = 'COLLECTION_ADDED_TO_PORTFOLIO';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const ADD_COLLECTION_TO_GROUP = 'ADD_COLLECTION_TO_GROUP';
export const SEARCH_COLLECTION_PORTFOLIO = 'SEARCH_COLLECTION_PORTFOLIO';
export const RESET_SEARCH_COLLECTION_PORTFOLIO =
  'RESET_SEARCH_COLLECTION_PORTFOLIO';
export const START_PORTFOLIO_SEARCH = 'START_PORTFOLIO_SEARCH';

export function loadCollection(collectionObject, user) {
  return {
    type: LOAD_COLLECTION,
    collectionObject,
    user,
  };
}

//TODO - move to portfolio object
export function collectionCreated(collectionPoint, portfolio) {
  return {
    type: COLLECTION_CREATED,
    collectionPoint,
    portfolio,
  };
}

//Used to change collection properties (text, tags, description)
export function collectionUpdated(collectionPoint, portfolio) {
  return {
    type: COLLECTION_UPDATED,
    collectionPoint,
    portfolio,
  };
}

export function collectionDeleted(collection, portfolio, permissionLevel) {
  return {
    type: COLLECTION_UPDATED,
    collection,
    portfolio,
    permissionLevel,
  };
}

export function pointAddedToCollection(collectionId, point, collectionPoint) {
  return {
    type: POINT_ADDED_TO_COLLECTION,
    point,
    collectionId,
    collectionPoint,
  };
}

export function factAddedToCollection(collectionId, fact) {
  return {
    type: FACT_ADDED_TO_COLLECTION,
    fact,
    collectionId,
  };
}

export function pointRemovedFromCollection(collectionId, pointId) {
  return {
    type: POINT_REMOVED_FROM_COLLECTION,
    pointId,
    collectionId,
  };
}

export function factRemovedFromCollection(collectionId, factId) {
  return {
    type: FACT_REMOVED_FROM_COLLECTION,
    factId,
    collectionId,
  };
}

export function collectionAddedToPortfolio(portfolio) {
  return {
    type: COLLECTION_ADDED_TO_PORTFOLIO,
    portfolio,
  };
}

export function modalError(message) {
  return {
    type: 'MODAL_ERROR',
    message,
  };
}

export function collectionError(collectionId, errorMessage) {
  return {
    type: COLLECTION_ERROR,
    pointId: collectionId,
    errorMessage,
  };
}

export function createCollection(
  collectionPoint,
  additionalAuthors,
  isTopLevelCollection = true,
  lookedUpSourceInfo,
  filter,
  sourceInfoHtml,
) {
  return (dispatch, getState) => {
    console.log(
      'createCollection got called for user ' + JSON.stringify(collectionPoint),
    );

    return axios
      .post(
        collectionPoint.sourceURL
          ? '/api/mediaCollections/createMediaCollection'
          : '/api/collections/createCollection',
        {
          collectionPoint,
          additionalAuthors,
          isTopLevelCollection,
          lookedUpSourceInfo,
          sourceInfoHtml,
        },
        {
          params: {
            lightweightForCL: filter ? filter.lightweightForCL : undefined,
          },
        },
      )
      .then(
        (response) => {
          console.log('Create Collection responded successfully');
          //console.log(response);
          const data = response.data;
          //dispatch(collectionCreated(data.collectionPoint, data.portfolio));
          dispatch(pointCreated(data.collectionPoint));

          // if (collectionPoint.sourceURL) {
          dispatch(collectionCreated(data.collectionPoint, data.portfolio));
          // }

          return data.collectionPoint;
        },
        (res) => {
          throw res.response.data.error;
        },
      );
  };
}

//Update the collection name, description, tags, visibility
//Returns the updated collection - and the updated user portfolio to reflect changes
//User can only change collections in user's portfolio
//Could individually break out updates to name, description, tags, and visibility to spare data across network
export function updateCollection(collectionPoint, options) {
  return (dispatch, getState) => {
    console.log(
      'updateCollection got called for user ' + JSON.stringify(collectionPoint),
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/updateCollection',
        data: {
          collectionPoint,
          options,
        },
      })
        .then((response) => {
          console.log('Update Collection responded successfully');
          //console.log(response);
          const data = response.data;
          dispatch(collectionUpdated(data.collectionPoint, data.portfolio));
          let updatedPoints = [data.collectionPoint];
          if (data.points) updatedPoints = updatedPoints.concat(data.points);
          dispatch({
            type: UPDATE_POINTS,
            updatedPoints,
          });
          toast('Collection updated.');
          resolve();
        })
        .catch((response) => {
          console.log('Update Collection responded error');
          reject();
          //console.log(response);
          //reject(response);
        });
    });
  };
}

export function getCollection(
  collectionId,
  analyze = false,
  getFollowing = true,
  getSubPoints = true,
  populateAuthorDetail = true,
  populateGroupDetail = true,
) {
  //TODO - could first check if it's already loaded and call DB only if not loaded
  return (dispatch, getState) => {
    //console.log("getCollection got called for user "+JSON.stringify(collectionId));

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/collections/getCollection',
        params: {
          collectionId,
          analyze,
          getFollowing,
          getSubPoints,
          populateAuthorDetail,
          populateGroupDetail,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          //console.log("Get Collection responded successfully")
          //console.log(response);
          const data = response.data;

          if (data.errorMessage) {
            //dispatch(collectionError(data.errorMessage));
            console.log('Error getting collection');
            console.log(data.errorMessage);
            reject();
            return;
          }
          const collectionObject = {
            collectionPoint: data.collectionPoint,
            points: data.points,
            isAuthor: data.isAuthor,
            permissionLevel: data.permissionLevel,
            invitationStatus: data.invitationStatus,
            notificationPreferences: data.notificationPreferences,
            following: data.following,
            followingGroup: data.followingGroup,
            followingAuthor: data.followingAuthor,
          };
          dispatch(loadCollection(collectionObject, getState().user));
          if (data.followingGroup)
            dispatch({
              type: 'FOLLOW_GROUP',
              payload: { groupId: data.followingGroup.subjectId },
            });
          if (data.followingAuthor)
            dispatch({
              type: 'FOLLOW_PORTFOLIO',
              payload: { username: data.collectionPoint.authorName },
            });
          resolve(collectionObject);
          return collectionObject;
        })
        .catch((response) => {
          console.log('Get Collection responded error:', response);
          dispatch(collectionError(collectionId, response.error));
          //console.log(response);
          //reject(response.response);
        });
    });
  };
}

export function getMediaCollection(collectionId, sourceURL) {
  return (dispatch, getState) => {
    //console.log("getCollection got called for user "+JSON.stringify(collectionId));

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `/api/mediaCollections/getMediaCollection/${collectionId}`,
        params: { sourceURL },
      })
        .then((response) => {
          //console.log("Get Collection responded successfully")
          //console.log(response);
          const data = response.data;
          console.log(data);

          if (data.errorMessage) {
            //dispatch(collectionError(data.errorMessage));
            console.log('Error getting collection');
            console.log(data.errorMessage);
            reject();
            return;
          }
          const collectionObject = {
            collectionPoint: data.collectionPoint,
            points: data.points,
            isAuthor: data.isAuthor,
            permissionLevel: data.permissionLevel,
            invitationStatus: data.invitationStatus,
            notificationPreferences: data.notificationPreferences,
            following: data.following,
            followingGroup: data.followingGroup,
            followingAuthor: data.followingAuthor,
          };
          dispatch(loadCollection(collectionObject, getState().user));
          if (data.followingGroup)
            dispatch({
              type: 'FOLLOW_GROUP',
              payload: { groupId: data.followingGroup.subjectId },
            });
          if (data.followingAuthor)
            dispatch({
              type: 'FOLLOW_PORTFOLIO',
              payload: { username: data.collectionPoint.authorName },
            });
          resolve(collectionObject);
          return collectionObject;
        })
        .catch((response) => {
          console.log('Get Collection responded error:', response);

          if (collectionId) {
            dispatch(
              collectionError(collectionId, response.response.data.error),
            );
          }

          reject();
          //console.log(response);
          //reject(response.response);
        });
    });
  };
}

export function deleteCollection(collectionId) {
  return (dispatch, getState) => {
    console.log(
      'deleteCollectionFromPortfolio got called for user ' +
      JSON.stringify(collectionId),
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/deleteCollection',
        params: {
          collectionId,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log(
            'Delete Collection From Portfolio responded successfully',
          );
          //console.log(response);
          const data = response.data;
          dispatch(
            collectionDeleted(
              data.collectionPoint,
              data.portfolio,
              data.permissionLevel,
            ),
          );
          resolve();
        })
        .catch((response) => {
          console.log('Get Collection responded error');
          reject();
          //console.log(response);
          //reject(response);
        });
    });
  };
}

export function removeCollectionFromPortfolio(collectionId) {
  return (dispatch, getState) => {
    console.log(
      'removeCollectionFromPortfolio got called for collection ' +
      JSON.stringify(collectionId),
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/removeCollectionFromPortfolio',
        params: {
          collectionId,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log(
            'Remove Collection From Portfolio responded successfully',
          );
          //console.log(response);
          const data = response.data;
          //This action should not affect CollectionUsers - the inivted users. Keep 2nd arg null
          dispatch({
            type: COLLECTION_REMOVED_FROM_PORTFOLIO,
            portfolio: data.portfolio,
            collectionPoint: data.collectionPoint,
          });
          dispatch({
            type: 'DELETE_POINTS',
            deletedPointIds: data.deletedPointIds,
          });
          dispatch({
            type: 'UPDATE_POINTS',
            updatedPoints: data.updatedPoints,
          });

          toast('Collection removed from your Portfolio.');
          resolve();
        })
        .catch((response) => {
          console.log('Get Collection responded error');
          reject();
          //console.log(response);
          //reject(response);
        });
    });
  };
}

export function addCollectionToPortfolio(collectionId) {
  return (dispatch, getState) => {
    console.log(
      'addCollectionToPortfolio got called for collection ' +
      JSON.stringify(collectionId),
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/addCollectionToPortfolio',
        params: {
          collectionId,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('Add Collection to Portfolio responded successfully');
          const data = response.data;
          dispatch(collectionAddedToPortfolio(data.portfolio));
          resolve();
          toast(
            'You have successfully added this Collection to your Portfolio',
          );
        })
        .catch((response) => {
          console.log('Get Collection responded error');
          reject(response);
          toast(response.response.data.error, {}, 'error');
          //console.log(response);
          //reject(response);
        });
    });
  };
}

// export const addCollectionToGroup = (collectionId, groupId) => ({
//   type: ADD_COLLECTION_TO_GROUP,
//   payload: {
//     promise: groupSvc.addCollectionToGroup(groupId, collectionId),
//     groupId,
//     collectionId,
//   },
// });

export function addCollectionToGroup(collectionId, groupId) {
  return (dispatch, getState) => {
    console.log(`addCollectionToGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `/api/collections/${collectionId}/groups/${groupId}`,
        // data: group
      })
        .then((response) => {
          console.log('addCollectionToGroup responded successfully');
          console.log(response);
          let collection = response.data;

          dispatch({
            type: resolved(ADD_COLLECTION_TO_GROUP),
            meta: {
              collectionId: collection._id,
            },
            payload: collection,
          });
          resolve(collection);
        })
        .catch((error) => {
          console.log('addCollectionToGroup responded error');
          console.log(error);
          dispatch({
            type: rejected(ADD_COLLECTION_TO_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

export function addReferenceCollection(collectionId, referenceCollectionId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/addReferenceCollection',
        data: {
          collectionId,
          referenceCollectionId,
        },
      })
        .then((response) => {
          console.log('Add referenceCollection responded successfully');
          //console.log(response);
          const data = response.data;
          dispatch({
            type: 'UPDATE_POINT',
            point: data.mediaCollection,
          });
          toast('Added reference Collection.');
          resolve();
        })
        .catch((response) => {
          console.log('Add referenceCollection responded error');
          reject();
          //console.log(response);
          //reject(response);
        });
    });
  };
}

export function removeReferenceCollection(collectionId, referenceCollectionId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/collections/removeReferenceCollection',
        data: {
          collectionId,
          referenceCollectionId,
        },
      })
        .then((response) => {
          console.log('Remove referenceCollection responded successfully');
          //console.log(response);
          const data = response.data;
          dispatch({
            type: 'UPDATE_POINT',
            point: data.mediaCollection,
          });
          toast('Reference Collection added.');
          resolve();
        })
        .catch((response) => {
          console.log('Remove referenceCollection responded error');
          reject();
          //console.log(response);
          //reject(response);
        });
    });
  };
}

export function createMediaClaim(mediaClaim) {
  return async (dispatch, getState) => {
    let res;

    if (mediaClaim.mediaClaimPoint.isMock) {
      if (!mediaClaim.mediaClaimPoint._id) {
        mediaClaim.mediaClaimPoint._id = Date.now().toString();
        mediaClaim.mediaClaimPoint.authorId = getState().user.user._id;
        mediaClaim.mediaClaimPoint.authorName = getState().user.user.username;
        mediaClaim.articleMediaCollection.subPoints.push({
          _id: Date.now().toString(),
          pointId: mediaClaim.mediaClaimPoint._id,
          postedBy: getState().user.user._id,
          postedByName: getState().user.user.username,
        });
      }
      res = { data: mediaClaim };
    } else {
      res = await axios.post(
        '/api/mediaCollections/createMediaClaim',
        mediaClaim,
      );
    }

    if (res.data.mediaClaimPoint) {
      dispatch(pointCreated(res.data.mediaClaimPoint));
      dispatch(updatePoint(res.data.articleMediaCollection, getState().user));
    }

    return res.data;
  };
}

export function URLtoMediaCollection(URL) {
  return async () => {
    const res = await axios.post('/api/mediaCollections/URLtoMediaCollection', {
      URL,
    });
    return res.data;
  };
}

export function collectionSearch(
  searchTerm,
  pageType,
  searchId,
  collectionId = null,
  isTree = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: START_PORTFOLIO_SEARCH,
        payload: {
          searchTerm,
          pageType,
          searchId,
          collectionId,
        },
      });
      axios
        .get('/api/collectionSearch/searchCollection', {
          params: { searchTerm, collectionId, isTree },
        })
        .then((response) => {
          const data = response.data;

          let points = data.filter((i) => !i.isCollection);
          let collectionPoints = data.filter((i) => i.isCollection);
          points = points.map((p) => {
            if (!p.subPoints) p.subPoints = [];
            if (!p.authors) p.authors = [];
            return p;
          });
          collectionPoints = collectionPoints.map((p) => {
            if (!p.subPoints) p.subPoints = [];
            if (!p.authors) p.authors = [];
            return p;
          });

          const searchData = summarizeSearchResults({
            points,
            collectionPoints,
            searchTerm,
            pageType,
            searchId,
          });
          dispatch({
            type: SEARCH_COLLECTION_PORTFOLIO,
            payload: searchData,
          });

          resolve(searchData);
        })
        .catch((error) => {
          console.log('Error while searching collection', error);
          reject(error);
        });
    });
  };
}

export function resetCollectionSearch(searchTerm, pageType, searchId) {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH_COLLECTION_PORTFOLIO,
      payload: {
        searchTerm,
        pageType,
        searchId,
      },
    });
  };
}

const summarizeSearchResults = (data) => {
  data.topSources = _.filter(data.points, (point) => {
    return point.sourceName !== null && point.sourceName !== undefined;
  });
  data.topSources = _.countBy(data.topSources, 'sourceName');

  return data;
};

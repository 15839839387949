import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';

const SpinElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .tip {
    color: inherit;
    margin-top: 5px;
    text-shadow: 0 1px 2px #fff;
    font-weight: 500;
  }

  svg {
    fill: ${props => props.color};
    height: ${props => props.size}px;
  }
`;

const NestedContainer = styled.div`
  position: relative;
  .spinning {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
`;

const ChildContainer = styled.div`
  opacity: 0.4;
  position: relative;
  &:after {
    content: '';
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
  }
`;

class Spin extends React.PureComponent {
  hasChildren = () => {
    return !!(this.props && this.props.children);
  };

  render() {
    const {
      spinning,
      tip,
      size = 16,
      color,
      className,
      style,
      children,
    } = this.props;
    const hasChildren = this.hasChildren();

    const spinElement = (
      <SpinElement
        className={cx(className, { spinning })}
        size={size}
        color={color}
      >
        <svg
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 120 30"
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
        >
          <circle cx="15" cy="15" r="15">
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="0.8s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="0.8s"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="60" cy="15" r="9" fillOpacity=".3">
            <animate
              attributeName="r"
              from="9"
              to="9"
              begin="0s"
              dur="0.8s"
              values="9;15;9"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from=".5"
              to=".5"
              begin="0s"
              dur="0.8s"
              values=".5;1;.5"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="105" cy="15" r="15">
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="0.8s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="0.8s"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
        {tip ? <div className="tip">{tip}</div> : null}
      </SpinElement>
    );
    if (hasChildren) {
      if (!spinning) return children;
      return (
        <NestedContainer className={className} style={style}>
          {spinElement}
          <ChildContainer>{children}</ChildContainer>
        </NestedContainer>
      );
    } else if (spinning) {
      return spinElement;
    }
    return null;
  }
}

Spin.defaultProps = {
  size: 16,
  color: '#2186EB',
};

Spin.propTypes = {
  spinning: PropTypes.bool,
  tip: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Spin;

import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import config from 'config/config.public.js';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import * as Actions from '../../../redux/actions';
import { Icon, ICONS } from '../../Icons';

import './VerifySubscriptionRequest.scss';

let cloudinaryWidget = null;

function VerifySubscriptionRequest({ onClose, sourceName, user, actions }) {
  const [secureUrl, setSecureUrl] = useState(null);
  const [userNote, setUserNote] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const username = user && user.user && user.user.username;

  const afterImageAdded = (error, result) => {
    if (error) {
      console.log('Error uploading evidence image', error);
      return;
    }

    console.log('The result event was ', result.event);

    if (result.event == 'success') {
      console.log(result.info.secure_url);

      setSecureUrl(result.info.secure_url);

      cloudinaryWidget.close();
    }
  };

  const generateSignature = (callback, params_to_sign) => {
    axios({
      method: 'post',
      url: '/api/utility/getCloudinarySignature',
      params: {},
      data: {
        ...params_to_sign,
      },
    })
      .then((result) => {
        callback(result.data.signature);
        console.log('Got signature ');
        console.log(result.data);
      })
      .catch((result) => {
        console.log('Error getting signature');
        console.log(result);
      });
  };

  const openUploadWidget = () => {
    const cloudinaryOptions = {
      cloudName: config.cloudinary.cloud_name,
      uploadPreset: 'yxidy7xn',
      apiKey: config.cloudinary.api_key,
      cropping: 'true',
      cropping_aspect_ratio: 1,
      resource_type: 'image',
      uploadSignature: generateSignature,
      publicId: null,
      invalidate: true,
      sources: ['local', 'url', 'image_search'],
      folder: 'source-subscription',
    };

    if (!cloudinaryWidget) {
      cloudinaryWidget = window.cloudinary.createUploadWidget(
        cloudinaryOptions,
        afterImageAdded,
      );
      cloudinaryWidget.open();
    } else {
      // if (this.props.cloudinary.imageId != prevProps.cloudinary.imageId) {
      //   cloudinaryWidget.update(cloudinaryOptions);
      // }
      if (!cloudinaryWidget.isShowing()) {
        cloudinaryWidget.open();
      }
    }
  };

  const onSubmit = () => {
    setLoading(true);

    actions
      .submitSourceSubscriptionRequest({
        sourceName,
        evidenceUrl: secureUrl,
        userNote,
        username,
      })
      .then((data) => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.error(
          'Error while submitting source subscription request',
          error,
        );
        setError(
          error.message || 'Error while submitting source subscription request',
        );
        setLoading(false);
      });
  };

  return (
    <Modal show={true} className="badge-details-modal">
      <Modal.Body>
        <div className="header">
          <span>
            Verify your <b>{sourceName}</b> Subscription
          </span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="subscription-container my-5">
          <div className="mb-5">
            Please upload a screenshot of a receipt that shows
            your active subscription with <b>{sourceName}</b>
          </div>
          {!secureUrl ? (
            <RectangularButton
              className="light-blue"
              iconName="EVIDENCE"
              iconSize={24}
              onClick={openUploadWidget}
            >
              Upload Evidence
            </RectangularButton>
          ) : (
            <React.Fragment>
              <img src={secureUrl} className="image-preview" />
              <div className="my-4">
                <FloatingInput
                  value={userNote}
                  labelName="Note"
                  onChange={(e) => setUserNote(e.target.value)}
                />
              </div>
              {error && <div className="error-message my-3">{error}</div>}
            </React.Fragment>
          )}
        </div>
        <div className="submit-container">
          {loading ? (
            <div className="text-center mt-4">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '40px' }}
              />
            </div>
          ) : (
            <RectangularButton disabled={!secureUrl} onClick={onSubmit}>
              Submit
            </RectangularButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifySubscriptionRequest);

import React from 'react';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import PointImage from 'components/cards/Point/Component/PointImage/PointImage';

export default function ArticleDetails({
  point,
  url,
  loading,
  setUrl,
  lookupURL,
  changePoint,
}) {
  const emptyPoint = point.sourceURL;

  return (
    <div className="body">
      <div className="input-container">
        <FloatingInput
          autoFocus={true}
          value={url || point.sourceURL}
          labelName="Article URL"
          disabled={emptyPoint}
          error={''}
          onChange={e => setUrl(e.target.value)}
        />
        {!emptyPoint && (
          <RectangularButton onClick={lookupURL} disabled={!url}>
            Lookup
          </RectangularButton>
        )}
      </div>
      {loading && (
        <div className="text-center mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '40px' }}
          />
        </div>
      )}
      {emptyPoint && !loading && (
        <React.Fragment>
          <FloatingInput
            value={point.sourceTitle}
            labelName="Headline"
            onChange={e => changePoint('sourceTitle', e.target.value)}
          />
          <FloatingInput
            value={point.sourceAuthor}
            labelName="Author"
            onChange={e => changePoint('sourceAuthor', e.target.value)}
          />
          <div className="source-container">
            <FloatingInput
              value={point.sourceName}
              labelName="Source Name"
              onChange={e => changePoint('sourceName', e.target.value)}
            />
            <FloatingInput
              value={point.sourceDate}
              labelName="Date"
              onChange={e => changePoint('sourceDate', e.target.value)}
            />
          </div>
          <div className="image-container">
            <PointImage
              point={point}
              user={{}}
              isSaved
              isEditing={true}
              onPointFieldChanged={changePoint}
              cardType={'page'}
              isExtension={false}
              className="mt-3"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

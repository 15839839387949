export default [
  { label: 'Newspaper', value: 'newspaper' },
  { label: 'Magazine', value: 'magazine' },
  { label: 'Reference', value: 'reference' },
  { label: 'Academic Journal', value: 'academicJournal' },
  { label: 'Government', value: 'government' },
  { label: 'NGO', value: 'ngo' },
  { label: 'Company', value: 'company' },
  { label: 'Website', value: 'website' },
  { label: 'News Agency', value: 'news agency' },
  { label: 'Industry News', value: 'industry news' },
  { label: 'Advocacy News', value: 'advocacy news' },
  { label: 'Radio', value: 'radio' },
  { label: 'TV', value: 'tv' },
  { label: 'Textbook', value: 'textbook' },
  { label: 'Think Tank', value: 'think tank' },
  { label: 'Info Service', value: 'info service' },
  { label: 'Traffic Factory', value: 'trafficFactory' },
  { label: 'University', value: 'university' },
  { label: 'Non-profit', value: 'nonprofit' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Advocacy organization', value: 'advocacyOrganization' },
];
import * as Actions from 'redux/actions/index';

const initialState = {
  show: false,
  target: null,
  placement: 'bottom',
  icon: null,
  title: '',
  description: '',
  imageUrl: null,
  videoUrl: null,
  actionLinks: [],
};

export default function popover(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_POPOVER: {
      return {
        ...state,
        show: true,
        target: action.data.target,
        placement: action.data.placement,
        icon: action.data.icon,
        title: action.data.title,
        description: action.data.description,
        imageUrl: action.data.imageUrl,
        videoUrl: action.data.videoUrl,
        actionLinks: action.data.actionLinks,
        isHTML: action.data.isHTML,
      };
    }
    case Actions.HIDE_POPOVER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import isNode from 'detect-node';
import { createMemoryHistory, createBrowserHistory } from 'history';

export const createUniversalHistory = ({ initialEntries = [] }) => {
  if (!isNode) {
    return createBrowserHistory();
  }
  return createMemoryHistory({ initialEntries });
};

export default createUniversalHistory;

import * as React from "react";
import AddPictureComponent from "./AddPictureComponent";
import { Modal } from "react-bootstrap";

class AddPictureModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {}
    };
  }

  onSave() {
    if (this.validate()) {
      this.props.onAction(this.props.onSave, this.props.model).then(() => {
        this.props.onClose(true);
      });
    }

    this.setState({
      isSubmitted: true
    });
  }

  onClose = () => {
    this.props.onClose(false);
  }

  onLicenseTermsAccepted = (terms) => {
    //var id = this.props.cloudinaryImageId;
    terms.originalURL = this.props.originalURL;
    this.props.onLicenseTermsAccepted(terms, this.props.hostedImageURL, this.props.hostedImageVersion);
    this.props.onClose(true);
  }

  render() {
    return (
      <Modal show={true} className="general-modal">
        <Modal.Header>
          <Modal.Title>Add a Picture</Modal.Title>
          <div className="actions-container pull-right">
            <button
              type="button"
              className="btn btn-close"
              onClick={this.onClose}
            >
              Close
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddPictureComponent
              cloudinaryImageId={this.props.cloudinaryImageId}
              onLicenseTermsAccepted={this.onLicenseTermsAccepted}
              hostedImageVersion=
              {this.props.hostedImageVersion}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddPictureModal;

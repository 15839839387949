import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ToolTip.scss';

class ToolTip extends Component {
  static = {
    toolTipText: PropTypes.string,
    toolTipPosition: PropTypes.string,
    children: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    const {
      toolTipText,
      toolTipPosition,
      children,
      className,
      style,
    } = this.props;

    if (this.props.hide) {
      return (
        <span className={className} style={style}>
          {children}
        </span>
      );
    } else {
      return (
        <span
          tooltip={toolTipText}
          tooltip-position={toolTipPosition}
          className={className}
          style={style}
        >
          {children}
        </span>
      );
    }
  }
}

export default ToolTip;

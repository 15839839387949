import React from 'react';
import classnames from 'classnames';

import { Icon, ICONS } from 'components/Icons';
import './Checkbox.scss';
import PropTypes from 'prop-types';

const Checkbox = props => {
  let disabled = false;
  if (props.disabled) disabled = true;
  let checkboxClassName = disabled ? 'fake-checkbox-disabled' : 'fake-checkbox';
  const disabledClass = disabled ? 'disabled' : '';
  let icon = props.checked ? <Icon icon={ICONS.VERIFIED} /> : '';

  let className = classnames('checkbox', props.className || '');
  return (
    <div className={className} onClick={props.onClick}>
      <label className={'av-checkbox ' + disabledClass}>
        <input
          type="checkbox"
          onChange={props.handleChange}
          name={props.name}
          checked={props.checked}
          disabled={disabled}
        />
        {<span className={checkboxClassName}> {icon} </span>}
        {props.children}
      </label>
      {props.error && <div className="error-message">{props.error}</div>}
    </div>
  );
};
//     !props.checked ? <span className={checkboxClassName} /> : <span className='fake-checkbox'><Icon icon={ICONS.VERIFIED} /></span>

export default Checkbox;

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
};

import * as Actions from '../actions/index';

const initialState = {};

export default function blogs(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_BLOGS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case Actions.ADD_BLOG:
      return {
        ...state,
        blogs: [...state.blogs, action.blog],
      };
    case Actions.UPDATE_BLOG: {
      const index = state.blogs.findIndex(i => i._id === action.blog._id);
      return {
        ...state,
        blogs: [
          ...state.blogs.slice(0, index),
          action.blog,
          ...state.blogs.slice(index + 1),
        ],
      };
    }
    case Actions.DELETE_BLOG: {
      const index = state.blogs.findIndex(i => i._id === action.blog._id);
      return {
        ...state,
        blogs: [
          ...state.blogs.slice(0, index),
          ...state.blogs.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Modal, Tabs, Tab, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions/index';

import { Icon, ICONS } from '../../Icons';
import './AddPoint.scss';
import AddPoint from './AddPoint';
import { CLEAR_PARENT_STACK } from '../../../redux/actions/index';

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

function getDocumentWidth() {
  return Math.max(
    document.documentElement['clientWidth'],
    document.body['scrollWidth'],
    document.documentElement['scrollWidth'],
    document.body['offsetWidth'],
    document.documentElement['offsetWidth'],
  );
}

export default class AddPointModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionObject: null,
    };
  }

  onClose = () => {
    this.props.onClose(false);
  }

  render() {
    // Uncomment for production
    const { model } = this.props;

    // Uncomment to test Chrome extension
    // const params = {
    //   pdfThruBrowser: false,
    //   source: "https://www.nytimes.com/2019/08/02/world/middleeast/isis-afghanistan-us-military.html?action=click&module=Top%20Stories&pgtype=Homepage",
    //   text: "test highlighted text",
    //   image: null,
    //   userHighlightType: null
    // }
    // const model = {
    //   //afterPointCreated: this.afterPointCreated,
    //   title: "Test Popup thru browser",
    //   hideImportFromPortfolio: true,
    //   showStance: false,
    //   showAddToCollection: true, // Choose which Collection to add the new Point into
    //   isExtension: true,
    //   pdfThruBrowser: params.pdfThruBrowser == 'true',
    //   sourceText: params.text,
    //   sourceUrl: params.source,
    //   sourceImage: params.image,
    //   userHighlightType: params.userHighlightType,
    //   onClose: this.onClose,
    // }

    let title = model.title;
    if (getDocumentWidth() < 767 && title.length > 50) {
      title = 'Add a Point';
    }
    if (getDocumentWidth() > 768 && title.length > 100) {
      title = 'Add a Point';
    }
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );
    return (
      <Modal show={true} className="add-fact-modal" container={container}>
        <Modal.Header>
          <Button bsStyle="link" className="btn-close" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <Modal.Title className="d-flex align-items-center justify-content-center">
            <Icon icon={ICONS.SAVE} size={24} className="mr-2" />{' '}
            {model.isExtension ? 'Create a Point on AverPoint' : title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <AddPoint model={model} onClose={this.onClose} />
        </Modal.Body>
      </Modal>
    );
  }
}

import React from 'react';
import { Alert, Checkbox } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { slideInUp } from 'react-animations';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, ICONS } from 'components/Icons';
import * as Actions from '../../../../redux/actions/index';
import './Hint.scss';
import { Link } from 'react-router-dom';

const slideUpAnimation = keyframes`${slideInUp}`;

const Slide = styled.div`
  animation: 0.5s ${slideUpAnimation};
`;

class Hint extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    hint: PropTypes.object,
    newsletter: PropTypes.object,
    user: PropTypes.object,
  };

  state = {
    checked: false,
  };

  handleDismiss = () => {
    this.props.actions.hideHint();
  };

  checkedChange = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };

  renderIcon = () => {
    const { type, hintIcon } = this.props.hint;
    let icon = ICONS.INFO;
    let iconClass = 'info-icon';
    let size = 16;
    if (type === 'info') {
      icon = ICONS.INFO;
      iconClass = 'info-icon';
    }
    if (type === 'error') {
      icon = ICONS.ERROR_CIRCLE;
      iconClass = '';
      size = 20;
    }
    if (type === 'warning') {
      iconClass = '';
      icon = ICONS.WARNING;
    }
    if (type === 'success') {
      iconClass = '';
      icon = ICONS.CHECK;
    }
    if (hintIcon) {
      icon = ICONS[hintIcon];
    }
    return <Icon icon={icon} size={size} className={iconClass} />;
  };

  render() {
    const { user, newsletter } = this.props;
    const { show, message, type, showLinkToNewsClub, onTop } = this.props.hint;
    const alertClasses = show ? classnames('slideup') : classnames('slidedown');
    let alertHtml = null;

    const newsClubLink = !user
      ? '/newsClub'
      : `/portfolio/${user.username}/club`;

    alertHtml = (
      <div
        className={`alert-container position-fixed w-100 ${newsletter.show ? 'lift-up-hint' : ''}
        ${onTop ? 'on-top' : ''}
        ${newsletter.subscribed ? 'subscribed-newsletter' : ''}`}
      >
        <Alert
          className={alertClasses}
          bsStyle={(type === 'error' ? 'danger' : type) || 'info'}
          onDismiss={this.handleDismiss}
        >
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row flex-fill justify-content-center">
              <div className="hint-icon-container">{this.renderIcon()}</div>
              <div className="ml-2">
                {message}
                {showLinkToNewsClub && (
                  <div>
                    Click to{' '}
                    <Link
                      to={newsClubLink}
                      onClick={() => this.props.actions.hideHint()}
                    >
                      learn more.
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {/* <div>
                <Checkbox value={checked} onChange={this.checkedChange}>
                  Don’t show this again
                </Checkbox>
              </div> */}
          </div>
        </Alert>
      </div>
    );

    return (
      <div className="alert-container">
        <Slide className={show ? 'd-block' : 'd-none'}>{alertHtml}</Slide>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user.user : null,
    hint: state.hint,
    newsletter: state.newsletter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Hint);

import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {
  writingPrompts: [],
};

export default function classRooms(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_WRITING_PROMPTS: {
      const writingPrompts = action.writingPrompts;
      const writingPromptIds = writingPrompts.map((w) => w._id);

      const cloneWritingPrompts = _.cloneDeep(state.writingPrompts);

      return {
        ...state,
        writingPrompts: cloneWritingPrompts
          .filter((w) => !writingPromptIds.includes(w._id))
          .concat(writingPrompts),
      };
    }
    case Actions.ADD_WRITING_PROMPT: {
      const writingPrompt = action.writingPrompt;

      const cloneWritingPrompts = _.cloneDeep(state.writingPrompts);

      return {
        ...state,
        writingPrompts: cloneWritingPrompts.concat([writingPrompt]),
      };
    }
    case Actions.UPDATE_WRITING_PROMPT: {
      const promptId = action.promptId;
      const writingPrompt = action.writingPrompt;

      const cloneWritingPrompts = _.cloneDeep(state.writingPrompts);
      const writingPromptIndex = cloneWritingPrompts.findIndex(
        (wt) => wt._id === promptId,
      );
      cloneWritingPrompts.splice(writingPromptIndex, 1, writingPrompt);

      return {
        ...state,
        writingPrompts: cloneWritingPrompts,
      };
    }
    case Actions.REMOVE_WRITING_PROMPT: {
      const promptId = action.promptId;

      let cloneWritingPrompts = _.cloneDeep(state.writingPrompts);
      cloneWritingPrompts = cloneWritingPrompts.filter(
        (wt) => wt._id !== promptId,
      );

      return {
        ...state,
        writingPrompts: cloneWritingPrompts,
      };
    }
    case Actions.RESET_WRITING_PROMPTS: {
      return {
        writingPrompts: [],
      };
    }
    default:
      return state;
  }
}

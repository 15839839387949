import React from "react";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import ReviewSummary from "./ReviewSummary/ReviewSummary";
import ReviewDetails from "./ReviewDetails/ReviewDetails";
import "./ReviewWidget.scss";
import { Mobile } from "components/layout/ResponsiveContainer";

class ReviewWidget extends React.Component {
  static propTypes = {
    reviewModalType: PropTypes.string,
    pointObject: PropTypes.object,
    pointId: PropTypes.string,
    toggleReviewModal: PropTypes.func,
    user: PropTypes.object,
    isChallenge: PropTypes.bool,
    actions: PropTypes.object,
    openConfirmationModal: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.reviewModalType || "reviewDetails",
      selectedReview: this.props.pointObject.userPointReview || null
    };
  }

  componentDidUpdate(nextProps){
    if(this.props.reviewModalType != nextProps.reviewModalType){
      this.setState({reviewModalType: nextProps.reviewModalType});
    }
  }

  handleReviewModal = (view, selectedReview) => {
    this.setState({
      view,
      selectedReview
    });
  }

  renderPointText(point) {
    const pointText = point.text;
    if (pointText.length < 300) return pointText;
    else {
      return pointText.substring(0, 300) + "...";
    }
  }

  render() {
    if (this.props.reviewModalType == "none") return <div />;

    const { pointObject } = this.props;

    if(!pointObject) return <div className="loading">Loading Point to Revew</div>;

    const {point, reviews } = pointObject;

    return (
      <div className="review-widget-position">
        {
          <div className="review-widget">
            <div className="top-row">
              <span
                className="close-button"
                onClick={this.props.toggleReviewModal}
              >
                X
              </span>
            </div>
            <Mobile>
              <div className="point-type">{point.type}</div>
              <div className="point-text">{this.renderPointText(point)}</div>
            </Mobile>
            {this.state.view === "reviewSummary" && (
              <ReviewSummary
                handleReviewModal={this.handleReviewModal}
                point={point}
                reviews={reviews}
              />
            )}
            {this.state.view === "reviewDetails" && (
              <ReviewDetails 
                handleReviewModal={this.handleReviewModal}
                user={this.props.user}
                point={point}
                pointObject={pointObject}
                reviews={reviews}
                displayReview={this.state.selectedReview}
                onSubmitFactReview={this.onSubmitFactReview}
                onSubmitOpinionReview={this.onSubmitOpinionReview}
                isChallenge={this.props.isChallenge}
                user={this.props.user}
                actions={this.props.actions}
              />
            )}
          </div>
        }
      </div>
    );
  }

  onSubmitFactReview = (point,userReview, neutralLanguage, verifiedInSource) => {
    let confirmationMessage;

    const isChallenge = this.props.isChallenge;

    if(isChallenge){
        confirmationMessage = "Are you sure you want to submit this challenge?";

        this.props.openConfirmationModal('Confirm Challenge', confirmationMessage, (success) => {
            if (success) {
                let pointObject = this.props.pointObject;
                this.props.actions.challengeFactReview(point,userReview, neutralLanguage, verifiedInSource, pointObject.userPointReview)
                .then( ( (submittedReview) => {
                    this.handleReviewModal("reviewDetails", submittedReview);
                }));
            }
        });
    } else {
        confirmationMessage = "Are you sure you want to submit this review?";

        this.props.openConfirmationModal('Confirm Submit', confirmationMessage, (success) => {
            if (success) {
                let pointObject = this.props.pointObject;
                this.props.actions.submitFactReview(point,userReview, neutralLanguage, verifiedInSource, pointObject.userPointReview)
                .then( ( (submittedReview) => {
                  this.handleReviewModal("reviewDetails", submittedReview);
                }));
            }
        });
    }
  }

  onSubmitOpinionReview = (point,userReview, balancedLanguage, factsSupportClaims, afterReviewSubmitted) => {
    var confirmationMessage = "Are you sure you want to submit this review?";
    this.props.openConfirmationModal('Confirm Submit', confirmationMessage, (success) => {
        if (success) {
            let pointObject = this.props.pointObject;
            this.props.actions.submitOpinionReview(point,userReview,balancedLanguage, factsSupportClaims, pointObject.userPointReview)
            .then( ( (submittedReview) => {
              this.handleReviewModal("reviewDetails", submittedReview);
            }));
        }
    });
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.user.user;

  const pointId = ownProps.pointId;
  const pointObject = state.points[pointId];

  return {
      pointObject,
      user: user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
      actions: bindActionCreators(Actions, dispatch),
      showHeader : (status) =>{
          dispatch(Actions.showHeader(status));
      },
      openConfirmationModal: (title, message, onResponse) => {
          let props = {
              model: {title, message},
              onResponse
          };
          dispatch(Actions.openModal('confirm', props))
      },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewWidget);

import axios from 'axios';

import * as Actions from '../actions';

export function addInteractionReview(interactionReview) {
  return async (dispatch, getState) => {
    const res = await axios.post(
      '/api/interactionReviews/addInteractionReview',
      interactionReview,
    );

    return res.data;
  };
}

export function getInteractionReviews(interaction, interactionType, point) {
  return async (dispatch, getState) => {
    let interactionId;

    if (interactionType) {
      interactionId = interaction._id;
    }

    const res = await axios.get(
      `api/interactionReviews/getInteractionReviews?interactionId=${interactionId}&interactionType=${interactionType}`,
    );

    if (interactionType === 'QTP') {
      dispatch({
        type: Actions.ADD_QTP,
        point,
        qtp: {
          ...interaction,
          interactionReviews: res.data.interactionReviews,
        },
      });
    } else if (interactionType === 'RTP') {
      dispatch({
        type: Actions.ADD_RTP,
        point,
        rtp: {
          ...interaction,
          interactionReviews: res.data.interactionReviews,
        },
      });
    }
    return res.data;
  };
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from '../../Icons';
import { toast } from 'components/elements/molecules/Toast/Toast';

import './DuplicatePointDetails.scss';

function DuplicatePointDetails({ sourceURL, sourceTitle, pointCreationFeature, refetch, onClose }) {
  const [loading, setLoading] = useState(false);
  const [pointDetails, setPointDetails] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios
      .get('/api/diagnosis/getDuplicatePointDetails', {
        params: {
          sourceURL,
        },
      })
      .then((response) => {
        setPointDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [sourceURL]);

  const resolve = (sourceURL) => {
    setLoading(true);

    axios
      .get('/api/diagnosis/fixDuplicatePoint', {
        params: {
          sourceURL,
        },
      })
      .then(() => {
        refetch();
        onClose();
        setLoading(false);
        toast('The duplicates points have been removed.', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const showCourses = () => {
    if (!Array.isArray(pointDetails) || pointDetails.length === 0) {
      return <div className="d-flex justify-content-center pt-2">
        This point is not part of any courses
      </div>;
    }

    return <React.Fragment>
      <div class="pb-3 mb-2" style={{ borderBottom: '1px solid #a4b4bd' }}>
        <div class="row pl-4">
          <div class="col-xs-4">Course Name</div>
          <div class="col-xs-8">Teachers</div>
        </div>
      </div>
      {
        pointDetails.map((point, index) => (
          <div className="row py-2 pl-4" key={index}>
            <div className="col-xs-4">{point.name}</div>
            <div className="col-xs-8">{(point.teacherUsernames || []).join(', ')}</div>
          </div>
        ))
      }
    </React.Fragment>;
  };

  return (
    <Modal
      show={true}
      className="resolve-confirmation-modal"
    >
      <Modal.Body>
        <div className="header">
          <span>Point Details</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div>
          <div>
            <div className="mb-4 mt-2 source-title">
              <span className="font-weight-600">Source Title:</span>
              <a href={sourceURL} target="_blank" className="ml-2">{sourceTitle}</a>
            </div>
            <div className="mb-4 mt-2 features">
              <span className="font-weight-600">Features:</span>
              <span className="ml-2">{[...(new Set(pointCreationFeature || []))].join(', ') || 'N/A'}</span>
            </div>
            {loading ? (
              <div className="text-center mt-4">
                <div
                  className="fa fa-spin fa-spinner text-primary"
                  style={{ fontSize: '40px' }}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="courses-title mb-3 font-weight-600">Courses:</div>
                {showCourses()}
                <div className="d-flex justify-content-end mt-4">
                  <RectangularButton
                    className="blue ml-3"
                    onClick={() => resolve(sourceURL)}
                    darkButton
                  >
                    Remove Duplicates
                  </RectangularButton>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps() {
  return {};
}

export default connect(null, mapDispatchToProps)(DuplicatePointDetails);

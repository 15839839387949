import axios from 'axios';

export const LOGGED_MEDIA_ANALYTICS = 'LOGGED_MEDIA_ANALYTICS';

export function mediaLoggedAnalysis(username) {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: '/api/dashboard/mediaLoggedAnalysis',
      params: { username },
    })
      .then((response) => {
        const data = response.data;

        dispatch({
          type: LOGGED_MEDIA_ANALYTICS,
          payload: data,
        });

        return data;
      })
      .catch((e) => {
        console.error('Error while fetching logged media analytics', e);
      });
  };
}

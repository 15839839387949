import React, { useReducer, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { MenuItem, Dropdown } from 'react-bootstrap';

import * as Actions from 'redux/actions/index';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import { Icon, ICONS } from 'components/Icons';
import './CTPCard.scss';

function getDate(date) {
  let createdAt = moment(date);
  let isSameDay = createdAt.isSame(new Date(), 'day');
  let dateStr;

  if (isSameDay) {
    dateStr = `today at ${createdAt.format('hh:mm A')}`;
  } else {
    dateStr = createdAt.format('DD MMM, yyyy');
  }

  return dateStr;
}

function CommentCard({ ctp, className, user, actions }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_REPLIES':
          return {
            ...state,
            replies: action.payload,
          };
        case 'SET_REPLY':
          return {
            ...state,
            reply: action.payload,
          };
        default:
          return state;
      }
    },
    {
      reply: {
        text: '',
      },
    },
  );

  useEffect(() => {
    loadReplies();
  }, []);

  async function loadReplies() {
    const replies = await actions.getRepliesToCTP(ctp._id);
    dispatch({ type: 'SET_REPLIES', payload: replies });
  }

  async function onReply() {
    const reply = {
      text: state.reply.text,
      parentCommentId: ctp._id,
      pointId: ctp.pointId,
      articleMediaCollectionId: ctp.articleMediaCollectionId,
    };
    const { ctp: res } = await actions.addCTP(reply);
    dispatch({ type: 'SET_REPLY', payload: { text: '' } });
    dispatch({ type: 'SET_REPLIES', payload: [...state.replies, res] });
  }

  function onRemoveCTP(ctp) {
    actions.removeCTP(ctp);
  }

  function renderDeleteCommentMenuItem(ctp) {
    return (
      <MenuItem
        onClick={() => {
          onRemoveCTP(ctp);
        }}
      >
        Delete
      </MenuItem>
    );
  }

  function renderMenu() {
    if (ctp.userId !== user.user._id) {
      return null;
    }

    return (
      <Dropdown
        pullRight={true}
        className="more-menu ml-auto"
        onClick={(e) => e.stopPropagation()}
        onToggle={() => dispatch({ type: 'TOGGLE_MENU' })}
        id="qtp-card-dropdown"
        open={state.showMenu}
      >
        <Dropdown.Toggle noCaret>
          <Icon icon={ICONS.MORE} />
        </Dropdown.Toggle>
        <Dropdown.Menu>{renderDeleteCommentMenuItem(ctp)}</Dropdown.Menu>
      </Dropdown>
    );
  }

  function renderHeader() {
    return (
      <div className="d-flex align-items-center">
        <ProfileImage
          username={ctp.user ? ctp.user.username : ''}
          userId={ctp.userId}
          allowUserToChangePic={false}
          allowClickToPortfolio={false}
          size={24}
        />
        <div className="ml-3 font-weight-600 gray-3 font-size-12">
          {ctp.userId === user.user._id
            ? 'You Commented this Point'
            : 'Commented this Point'}{' '}
          {getDate(ctp.createdAt)}
        </div>
        {renderMenu()}
      </div>
    );
  }

  function renderReplyForm() {
    return (
      <div className="reply">
        <textarea
          className="form-control font-size-12"
          placeholder="Enter Reply"
          value={state.reply.text}
          onChange={(e) =>
            dispatch({
              type: 'SET_REPLY',
              payload: { ...state.reply, text: e.target.value },
            })
          }
        />
        <div className="mt-2 text-right">
          <button
            type="button"
            className="btn btn-xs btn-primary"
            disabled={!state.reply.text}
            onClick={onReply}
          >
            Reply
          </button>
        </div>
      </div>
    );
  }

  function renderReply(reply) {
    return (
      <div className="reply" key={reply._id}>
        <div className="d-flex align-items-center">
          <ProfileImage
            username={ctp.user ? ctp.user.username : ''}
            userId={ctp.userId}
            allowUserToChangePic={false}
            allowClickToPortfolio={false}
            size={20}
          />
          <div className="ml-2 gray-3">
            {reply.userId === user.user._id
              ? 'You Replied this Comment'
              : 'Replied this Comment'}{' '}
            {getDate(reply.createdAt)}
          </div>
          {/* {renderMenu()} */}
        </div>
        <div className="mt-2">
          "<i>{reply.text}</i>"
        </div>
      </div>
    );
  }

  function renderReplies() {
    return (
      <div className="replies">
        <p className="font-size-12 mt-3 font-weight-600 av-grayish-cyan">
          Replies:
        </p>
        {state.replies ? state.replies.map(renderReply) : null}
        {renderReplyForm()}
      </div>
    );
  }

  function renderComment() {
    return (
      <div className="comment">
        "<i>{ctp.text}</i>"
      </div>
    );
  }

  const containerClassName = classnames('comment-card p-3', className);
  return (
    <div className={containerClassName}>
      {renderHeader()}
      {renderComment()}
      {/* {renderCaptions()}
        {renderSurvey()}
        {renderInteractions()} */}
      {renderReplies()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentCard);

/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import { MenuItem, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';

import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { Icon, ICONS } from 'components/Icons';
import PointDropDownHeader from 'components/cards/Point/Component/PointHeader/PointDropDown/PointDropDownHeader/PointDropDownHeader';
import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';

import './pointActions.scss';

class PointActions extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    onPointFieldChanged: PropTypes.func,
    onEdit: PropTypes.func,
    isEditing: PropTypes.bool,
    isSaved: PropTypes.bool,
    onFollow: PropTypes.func,
    onUnfollow: PropTypes.func,
    /** *redux */
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    pointObject: PropTypes.object,
    point: PropTypes.object,
    actions: PropTypes.object,
    openSharePointModal: PropTypes.func,
    openConfirmationModal: PropTypes.func,
    openAddToCollectionModal: PropTypes.func,
    openInvitePointModal: PropTypes.func,
    openAcceptInviteToPointModal: PropTypes.func,
    allowRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    allowImageChange: PropTypes.bool,
    allowDownload: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      showClassifyBox: false,
      pointTypeOption: '',
      openMenu: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Open the Menu on selection, if first selected Point
    // if (this.props.selectedPoints !== prevProps.selectedPoints) {
    //   if (
    //     this.props.selectedPoints.length === 1 &&
    //     this.props.selectedPoints[0] === this.props.pointId
    //   ) {
    //     this.setState({ openMenu: true });
    //   } else if (this.props.selectedPoints.length === 2) {
    //     this.setState({ openMenu: false });
    //   }
    // }
  }

  onToggleMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  render() {
    const { point } = this.props;

    if (!point._id) {
      return null;
    }

    const className = classnames(
      'point-actions position-relative',
      this.props.className,
    );
    return (
      <div className={className}>
        <Dropdown
          pullRight={true}
          className="more-menu"
          onClick={(e) => e.stopPropagation()}
          onToggle={this.onToggleMenu}
          id="point-actions-dropdown"
          ref={(ref) => (this.dropdownMenuRef = ref)}
          open={this.state.openMenu}
        >
          <Dropdown.Toggle noCaret>
            <Icon icon={ICONS.MORE} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.renderHeader()}
            {/* {this.renderSaveToCollection()} */}
            {/* {this.renderViewDetail()} */}
            {this.renderEditOrSaveMenuItem()}
            {this.renderEditTimeStamp()}
            {this.renderPublish()}
            {/* {this.renderClassify()} */}
            {this.renderInvite()}
            {/* {this.renderRespondToInvite()} */}
            {/* {this.renderShare()} */}
            {this.renderDownload()}
            {this.renderFollow()}
            {this.renderAddorEditCallToActionMenuItem()}
            {this.renderNotificationSettingsMenuItem()}
            <div className="separator" />
            {this.renderRemove()}
            {this.renderDelete()}
            {this.renderMoreActions()}
          </Dropdown.Menu>
        </Dropdown>
        {this.renderClassifyBox()}
      </div>
    );
  }

  renderHeader() {
    const { point } = this.props;

    if (!point._id) {
      return null;
    }

    return (
      <MenuItem className="header">
        <PointDropDownHeader point={this.props.point} user={this.props.user} />
      </MenuItem>
    );
  }

  renderSaveToCollection() {
    const { authenticated } = this.props;

    if (!authenticated) {
      return null;
    }

    return (
      <MenuItem onClick={this.onSaveToCollection}>
        <Icon icon={ICONS.COLLECTION} className="mr-3" />
        Add to Collection
      </MenuItem>
    );
  }

  onSaveToCollection = () => {
    this.props.openAddToCollectionModal(this.props.point._id, (success) => {});
  };

  renderViewDetail() {
    return (
      <MenuItem onClick={this.onViewDetail}>
        <Icon icon={ICONS.PREVIEW} className="mr-3" />
        View Detail
      </MenuItem>
    );
  }

  onViewDetail = () => {
    this.props.openPointModal(this.props.point, this.props.parentObject);
  };

  renderEditOrSaveMenuItem() {
    const { point, user, isSaved, isAuthor } = this.props;

    if (!isAuthor) return;

    const label = this.props.isEditing ? 'Finish editing' : 'Edit Point';

    return (
      <MenuItem onClick={this.onEdit}>
        <Icon icon={ICONS.PENCIL} className="mr-3" />
        {label}
      </MenuItem>
    );
  }

  onEdit = () => {
    if (this.props.point.status != 'private') {
      this.props.actions.revisePoint(
        this.props.point._id,
        'inline edit',
        false,
      );
    }
    this.props.onEdit();
  };

  renderEditTimeStamp() {
    const { subPointInfoPermissionLevel } = this.props;
    if (subPointInfoPermissionLevel !== 'write') return;

    return (
      <MenuItem
        onClick={() => {
          // this.props.onEdit();
          this.props.onEditPointField('sourceTimeStampStart');
        }}
      >
        Edit Timestamp
      </MenuItem>
    );
  }

  renderFollow = () => {
    const { point, user, pointObject, authenticated, onFollow, onUnfollow } =
      this.props;

    if (!point._id || point.authorId === user._id) return null; // Author can't follow their own Point

    if (!authenticated) return null;

    return (
      <MenuItem
        onClick={() => {
          pointObject.following ? onUnfollow(point._id) : onFollow(point._id);
        }}
      >
        <Icon icon={ICONS.FOLLOW} className="mr-3" />
        {pointObject.following ? 'Unfollow' : 'Follow'}
      </MenuItem>
    );
  };

  renderCollect = () => {
    if (!this.props.authenticated) return null;

    return (
      <ActionLink
        onClick={this.onCollect}
        linkClassName=""
        iconType=""
        iconName=""
        label="Collect"
        toolTipText="Add this Point to one of your Collections"
      />
    );
  };

  onCollect = () => {
    this.props.openAddToCollectionModal(this.props.point._id, (success) => {});
  };

  renderShare = () => {
    const { point } = this.props;

    if (point.status === 'private') return null; // Can't share unless the Point is published

    // let disabledToolTipText = '';
    // let disabled = false;
    // if (!this.props.authenticated) {
    //   disabled = true;
    //   disabledToolTipText = 'Please log in to share the Point';
    // }

    return (
      <MenuItem
        onClick={() => {
          this.onSharePoint();
        }}
      >
        <Icon icon={ICONS.SHARE} className="mr-3" />
        Share
      </MenuItem>
    );
  };

  onSharePoint = () => {
    const { point } = this.props;
    point.shareUrl = `${window.location.origin}/Point/${point._id}`;
    point.iframeUrl = `${window.location.origin}/PointCard/${point._id}`;
    this.props.openSharePointModal(point, 'POINT');
  };

  renderDownload = () => {
    if (!this.props.allowDownload) return null;

    const { point } = this.props;

    if (point.status === 'private') return null; // Can't share unless the Point is published

    let disabledToolTipText = '';
    let disabled = false;
    if (!this.props.authenticated) {
      disabled = true;
      disabledToolTipText = 'Please log in to download the Point';
    }

    return (
      <MenuItem
        onClick={() => {
          this.onDownloadPoint();
        }}
      >
        Download
      </MenuItem>
    );
  };

  onDownloadPoint = () => {
    const { point } = this.props;
    this.props.actions.downloadPoint(point._id, 'all');
  };

  renderRemove = () => {
    if (!this.props.authenticated || !this.props.allowRemove) return null;

    return (
      <MenuItem
        onClick={() => {
          this.props.onRemove();
        }}
        className="av-red"
      >
        <Icon icon={ICONS.REMOVE_FROM_COLLECTION} className="mr-3" />
        Remove from Collection
      </MenuItem>
    );
  };

  onRemove(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.onRemove) {
      this.props.onRemove();
    }
  }

  renderDelete = () => {
    if (!this.props.authenticated) return null;

    const allowDelete = this.props.user._id === this.props.point.authorId;

    if (!allowDelete) return null;

    return (
      <MenuItem
        onClick={() => {
          this.onDelete();
        }}
        className="av-red"
      >
        <Icon icon={ICONS.DELETE} className="mr-3" />
        Delete
      </MenuItem>
    );
  };

  renderMoreActions = () => {
    const { moreHeaderActions } = this.props;

    if (!moreHeaderActions) {
      return null;
    }

    return moreHeaderActions.map(({ title, onClick }) => {
      return <MenuItem onClick={onClick}>{title}</MenuItem>;
    });
  };

  onDelete = () => {
    //e.preventDefault();
    //e.stopPropagation();

    const subPoints = this.props.point.subPoints.length;

    if (subPoints > 0) {
      const errorMessage =
        "You can't delete a Point with subPoints or we'll have a world full of little orphan Points! Please remove the subPoints first, and then try again.";

      this.props.openErrorModal(errorMessage);
      return;
    }

    const message = (
      <div className="confirmHeader">
        Are you sure you want to delete this Point?
        <div className="description">
          You will delete this Point across everywhere it exists.
        </div>
      </div>
    );

    this.props.openConfirmationModal('Confirm Submit', message, (success) => {
      if (success) {
        this.props.actions.deletePoint(this.props.point._id);
      }
    });
  };

  renderInvite() {
    const { point, user, isSaved, isAuthor } = this.props;

    if (!isAuthor) return null;

    if (!isSaved) return null; // Can't invite people while you are editing the Point

    return (
      <MenuItem
        onClick={() => {
          this.onInvitePoint();
        }}
      >
        <Icon icon={ICONS.INVITE_USER} className="mr-3" />
        Invite
      </MenuItem>
    );
  }

  onInvitePoint = () => {
    const { pointObject } = this.props;
    this.props.openInvitePointModal(pointObject.point);
  };

  // renderRespondToInvite() {
  //   const { point, user } = this.props;

  //   const { invitationStatus } = this.props.pointObject;

  //   if (invitationStatus !== 'invited') return null;

  //   return (
  //     <MenuItem
  //       onClick={() => {
  //         this.onRespondInvite(point._id);
  //       }}
  //     >
  //       Repond to Invite
  //     </MenuItem>
  //   );
  // }

  onRespondInvite = () => {
    const { pointObject } = this.props;
    if (pointObject.invitationStatus === 'invited') {
      this.props.openAcceptInviteToPointModal(pointObject.point);
    }
  };

  renderPublish() {
    const { point, user, isAuthor } = this.props;

    if (!isAuthor) return null;

    if (point.status !== 'private') return null; // Only publish a private Point

    const publishOptions = (
      <div
        className="sub-menu right position-absolute"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className="p-3 item hover"
          onClick={() => {
            this.props.actions.publishPoint(point._id);
          }}
        >
          <h5 className="av-black mb-2 mt-0">Publish Point</h5>
          <div
            className="gray-4 body-medium"
            style={{ width: '230px', whiteSpace: 'break-spaces' }}
          >
            Make this Point public (This will not affect Sub-points)
          </div>
        </div>
      </div>
    );
    return (
      <MenuItem className="has-sub-menu position-relative">
        <Icon icon={ICONS.GLOBE} className="mr-3" />
        Publish
        {publishOptions}
      </MenuItem>
    );
  }

  onPublishPoint(pointId) {
    const numSubPoints = this.props.pointObject.point.subPoints.length;

    let subPointMessage = '';
    if (numSubPoints > 0) {
      subPointMessage += ` This will not affect your ${numSubPoints} subPoint`;
      if (numSubPoints > 1) subPointMessage += 's';
      subPointMessage +=
        '. If you want to make a sub-Point public, publish it individually.';
    }

    const message = (
      <div className="confirmHeader">
        Are you sure you want to publish the Point?
        <div className="description">{subPointMessage}</div>
      </div>
    );

    this.props.openConfirmationModal('Confirm Submit', message, (success) => {
      if (success) {
        this.props.actions.publishPoint(this.props.point._id);
      }
    });
  }

  onRevisePoint(revisionReason) {
    // var confirmationMessage = "Are you sure you want to revise the Point. You will lose one point.";
    const confirmationMessage = 'Are you sure you want to revise the Point?'; // Don't subtract a Point for revising. We'll explore gamification later.

    this.props.openConfirmationModal(
      'Confirm Submit',
      confirmationMessage,
      (success) => {
        if (success) {
          this.props.actions.revisePoint(
            this.props.point._id,
            revisionReason,
            true,
          );
        }
      },
    );
  }

  renderClassify() {
    const { point, user, isAuthor } = this.props;

    if (!isAuthor) return null;

    if (point.type !== 'Decide later') return null; // Only classify an unclassified Point

    return (
      <MenuItem
        onClick={() => {
          this.setState({ showClassifyBox: true });
        }}
      >
        <Icon icon={ICONS.CLASSIFY} className="mr-3" />
        Classify
      </MenuItem>
    );
  }

  onClassifyPoint = (e) => {
    console.log('classify Point');
    this.setState({ showClassifyBox: false });
    const newPoint = {
      ...this.props.point,
      type: this.state.pointTypeOption,
    };
    this.props.actions.savePoint(newPoint);
  };

  renderClassifyBox = () => {
    if (!this.state.showClassifyBox) return null;

    if (this.props.point.type === 'Decide later') {
      return (
        <div
          className="classify position-absolute p-2 text-center bg-av-white"
          onClick={(e) => e.stopPropagation()}
        >
          This Point is a
          <div className="option-row">
            <RadioGroup
              name="pointTypeOption"
              label=""
              handleChange={this.handleChangePointType}
              errorMessage=""
            >
              <ToolTip
                toolTipText="A neutral statement reviewers can verify in a Source"
                toolTipPosition="bottom"
              >
                <RadioOption
                  name="pointTypeOption"
                  id="fact"
                  value="Fact"
                  label="Fact"
                  isChecked
                />
              </ToolTip>
              <ToolTip
                className="ml-3"
                toolTipText={"A point of view that isn't true or false"}
                toolTipPosition="bottom"
              >
                <RadioOption
                  name="pointTypeOption"
                  id="opinion"
                  value="Opinion"
                  label="Opinion"
                  isChecked
                />
              </ToolTip>
            </RadioGroup>
            <RoundedButton label="Go" onClick={this.onClassifyPoint} />
          </div>
        </div>
      );
    }
  };

  handleChangePointType = (event) => {
    event.stopPropagation();

    this.setState({
      pointTypeOption: event.target.value,
    });
  };

  renderAddorEditCallToActionMenuItem() {
    const { point, user, isAuthor } = this.props;

    if (!isAuthor) return null;

    let isEditing = point.callToAction.text && point.callToAction.url;

    return (
      <MenuItem
        onClick={() => {
          this.onAddorEditCallToAction();
        }}
      >
        <Icon icon={ICONS.CTA} className="mr-3" />
        {isEditing ? 'Edit Call to Action' : 'Add Call to Action'}
      </MenuItem>
    );
  }

  onChangeNotificationPref() {
    const { point } = this.props;
    let notificationPreferences = point.notificationPreferences;
    this.props.actions.setPointNotificationPreferences(
      point._id,
      notificationPreferences,
    );
  }

  renderNotificationSetting(text, pref) {
    return (
      <div className="p-3 item">
        <p className="font-weight-600">{text}</p>
        <div className="d-flex align-items-center">
          <Checkbox
            onClick={(e) => {
              pref.email = pref.email === 2 ? 0 : 2;
              this.onChangeNotificationPref();
            }}
            checked={pref.email === 2}
            disabled={false}
          >
            Email
          </Checkbox>
          <Checkbox
            className="ml-4"
            onClick={() => {
              pref.push = pref.push == 0 ? 1 : 0;
              this.onChangeNotificationPref();
            }}
            checked={pref.push === 1}
          >
            Push Notification
          </Checkbox>
        </div>
      </div>
    );
  }

  renderNotificationSettingsMenuItem() {
    const { point, user, isAuthor } = this.props;

    if (!isAuthor) return null;

    let notificationPreferences = point.notificationPreferences;

    let notificationSettings;

    if (notificationPreferences) {
      notificationSettings = (
        <div
          className="notification-settings right position-absolute"
          style={{ top: '-190px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {this.renderNotificationSetting(
            'SubPoints added to this Point',
            notificationPreferences.subPointAdded,
          )}
          {this.renderNotificationSetting(
            'Point is reviewed',
            notificationPreferences.reviewed,
          )}
          {this.renderNotificationSetting(
            'Review requested',
            notificationPreferences.reviewRequested,
          )}
          {this.renderNotificationSetting(
            'Point shared',
            notificationPreferences.shared,
          )}
        </div>
      );
    } else {
      notificationSettings = (
        <div
          className="notification-settings right position-absolute"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="p-3 font-weight-600">Loading Preferences...</div>
        </div>
      );
    }

    return (
      <MenuItem
        className="notification-settings-menu-item position-relative"
        onMouseEnter={() => {
          if (!point.notificationPreferences) {
            this.props.actions.getPointNotificationPreferences(point._id);
          }
        }}
      >
        <Icon icon={ICONS.NOTIFICATIONS} className="mr-3" />
        Notification Settings
        {notificationSettings}
      </MenuItem>
    );
  }

  onAddorEditCallToAction() {
    let point = this.props.point;
    this.props.openCallToActionModal(point);
  }
}

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;

  const { point } = ownProps;

  let pointObject, isAuthor;

  if (point._id) {
    pointObject = state.points[point._id];
    // point = pointObject.point;
    // isAuthor =
    //   pointObject.invitationStatus === 'accepted' &&
    //   pointObject.permissionLevel === 'write';
    isAuthor = pointObject && pointObject.isAuthor;
  }

  return {
    user,
    authenticated,
    pointObject,
    // point,
    isAuthor,
    selectedPoints: state.points.selectedPoints,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
    openSharePointModal: (model, type) => {
      const props = { model, type };
      dispatch(Actions.openModal('Share', props));
    },
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: { title, message },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
    openAddToCollectionModal: (pointId, onResponse) => {
      const props = {
        model: { pointId },
        onResponse,
      };
      dispatch(Actions.openModal('add-to-collection', props));
    },
    openErrorModal: (message) => {
      const title = 'Error';
      const props = {
        model: {
          title,
          message,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    openInvitePointModal: (point) => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal('invite-point', props));
    },
    openAcceptInviteToPointModal: (point) => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal('respond-invite-point', props));
    },
    onFollow: (id) => {
      dispatch(Actions.followPoint(id));
    },
    onUnfollow: (id) => {
      dispatch(Actions.unfollowPoint(id));
    },
    openCallToActionModal: (point) => {
      const props = {
        model: {
          point,
          type: 'POINT',
        },
      };
      dispatch(Actions.openModal('call-to-action', props));
    },
    openPointModal: (point, parentObject) => {
      let props = {
        model: {
          point,
          parentObject,
          isModal: true,
          showReviewOnOpen: false,
        },
      };
      dispatch(Actions.setParent(parentObject));
      dispatch(Actions.openModal('Point', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PointActions);

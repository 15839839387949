/* eslint-disable no-underscore-dangle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PointDetail from 'components/cards/Point/Component/PointDetail/PointDetail';
import PointImage from 'components/cards/Point/Component/PointImage/PointImage';
import PointHeader from 'components/cards/Point/Component/PointHeader/PointHeader';
import PointEditControls from 'components/cards/Point/Component/PointEditControls/PointEditControls';
import EditableTags from 'components/elements/molecules/tags/editableTags';
import { DnDBullseye } from 'components/cards/DnDBullseye';
import { Icon, ICONS } from 'components/Icons';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import MultipleProfileImages from 'components/elements/molecules/Images/MultipleProfileImages/MultipleProfileImages';
import * as Actions from 'redux/actions/index';
import Editor from 'components/elements/molecules/Editor/Editor';
import { isMobile } from 'helpers';
import FourPack from './../FourPack/FourPack';
import FourPackTabs from './../FourPackTabs/FourPackTabs';
import QTPCard from 'components/cards/Point/Views/FourPackTabs/QTPCard';
import RTPCard from 'components/cards/Point/Views/FourPackTabs/RTPCard';
import PointContainer from 'components/cards/Point/PointContainer.js';

// scss
import './PointCard.scss';
import PointSelectText from './PointSelectText';

// images
// import topEvidence from 'assets/images/top-evidence.svg';

const REACT_APP_AVERAGE_READING_SPEED =
  process.env.REACT_APP_AVERAGE_READING_SPEED;
class PointCard extends React.Component {
  static propTypes = {
    cardType: PropTypes.oneOf([
      'default',
      'page',
      'preview',
      'preview highlight-on-hover',
      'embed',
      'list',
      'page-sub-points',
      'outline',
      'claim',
      'evidence',
      'top-evidence',
    ]),
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    point: PropTypes.object.isRequired,
    citationStyle: PropTypes.string,
    isEditing: PropTypes.bool,
    isEditingFields: PropTypes.object,
    onToggleEdit: PropTypes.func,
    onPointFieldChanged: PropTypes.func,
    errorMessages: PropTypes.object,
    onEditPointField: PropTypes.func,
    allowRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    showTotalSubPoints: PropTypes.bool,
    onReviewPoint: PropTypes.func,
    showReviews: PropTypes.bool,
    allowDownload: PropTypes.bool,
    focused: PropTypes.bool,
    isAuthor: PropTypes.bool,
    subPointInfo: PropTypes.object,
    onPointDetailView: PropTypes.func,
    pointDetailView: PropTypes.object,
    parentObject: PropTypes.object,
  };

  static defaultProps = {
    isEditingFields: {},
    errorMessages: {},
  };

  constructor(props) {
    super(props);

    let fourPackActiveTab = '';

    if (
      props.pointDetailView &&
      props.pointDetailView.point._id === props.point._id
    ) {
      fourPackActiveTab = props.pointDetailView.tab;
    } else if (props.fourPackActiveTab) {
      fourPackActiveTab = props.fourPackActiveTab;
    }

    this.state = {
      showTimeStampWarning: false,
      timeStampWordCount: 0,
      fourPackActiveTab,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.point._id !== this.props.point._id ||
      (prevProps.point !== this.props.point &&
        !prevProps.point._id &&
        !this.props.point._id)
    ) {
      this.setState({ fourPackActiveTab: this.props.fourPackActiveTab });
    }

    if (this.props.fourPackActiveTab !== prevProps.fourPackActiveTab) {
      this.setState({ fourPackActiveTab: this.props.fourPackActiveTab });
    }
  }

  getFourPackActiveTab() {
    const { onPointDetailView, pointDetailView } = this.props;
    const { fourPackActiveTab } = this.state;

    let activeTab;

    if (onPointDetailView) {
      if (pointDetailView) {
        activeTab = pointDetailView.tab;
      }
    } else {
      activeTab = fourPackActiveTab;
    }

    return activeTab;
  }

  getTimeStampString(timeStamp) {
    let seconds = timeStamp % 60;
    let minutes = parseInt(timeStamp / 60, 10);
    let hours = 0;

    if (minutes >= 60) {
      hours = parseInt(hours / 60, 10);
      minutes = minutes % 60;
    }

    let str = '';

    if (hours > 0) {
      str += `${hours.toString().padStart(2, '0')}:`;
    }

    str += `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    return str;
  }

  onClickTimeStamp = (event) => {
    let { point } = this.props;
    let timeStamp = point.sourceTimeStampStart;

    if (this.props.onClickTimeStamp && timeStamp) {
      event.stopPropagation();
      this.props.onClickTimeStamp(timeStamp);
    }
  };

  onHideTimeStampWarning = (e) => {
    this.setState({
      showTimeStampWarning: false,
    });

    e.preventDefault();
    e.stopPropagation();
  };

  onTimeStampChange = (text) => {
    const { onPointFieldChanged, previousPoint } = this.props;

    let splits = text.split(':');
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (splits.length === 3) {
      hours = parseInt(splits[0] || 0, 10);
      minutes = parseInt(splits[1] || 0, 10);
      seconds = parseInt(splits[2] || 0, 10);
    } else if (splits.length === 2) {
      minutes = parseInt(splits[0] || 0, 10);
      seconds = parseInt(splits[1] || 0, 10);
    } else if (splits.length === 1) {
      seconds = parseInt(splits[1] || 0, 10);
    }

    let timestamp = hours * 3600 + minutes * 60 + seconds;

    onPointFieldChanged('sourceTimeStampStart', timestamp);

    if (previousPoint && previousPoint.sourceTimeStampStart && timestamp) {
      let wordCount = (previousPoint.text || '').split(' ').length;
      let neededSeconds = parseInt(
        wordCount / REACT_APP_AVERAGE_READING_SPEED,
        10,
      );

      if (neededSeconds > timestamp - previousPoint.sourceTimeStampStart) {
        this.setState({
          showTimeStampWarning: true,
          timeStampWordCount:
            neededSeconds - (timestamp - previousPoint.sourceTimeStampStart),
        });
      }
    }
  };

  renderDonate() {
    const { cardType } = this.props;

    // Do not render if card type is outline
    if (cardType === 'outline') {
      return null;
    }

    const { point } = this.props;
    const { callToAction } = point;

    // Check call to action is present
    if (!callToAction || !callToAction.text || !callToAction.url) {
      return null;
    }

    const text = callToAction.text;
    const url = callToAction.url;

    return (
      <a
        className="d-flex align-items-center av-blue donate-section"
        href={url}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        <Icon icon={ICONS.LINK} className="mr-3 p-2" size={26} />
        <h5 className="m-0 mr-3">{text}</h5>
        <Icon icon={ICONS.ARROW_FORWARD} size={15} />
      </a>
    );
  }

  renderHeader() {
    const { cardType } = this.props;

    if (
      cardType === 'evidence' ||
      cardType === 'questioned' ||
      cardType === 'reviewed' ||
      isMobile()
    ) {
      return null;
    }

    return (
      <PointHeader
        point={this.props.point}
        cardType={this.props.cardType}
        user={this.props.user}
        authenticated={this.props.authenticated}
        onPointFieldChanged={this.props.onPointFieldChanged}
        errorMessages={this.props.errorMessages}
        isEditing={this.props.isEditing}
        isEditingFields={this.props.isEditingFields}
        onToggleEdit={this.props.onToggleEdit}
        onEditPointField={this.props.onEditPointField}
        onSavePoint={this.props.onSavePoint}
        allowRemove={this.props.allowRemove}
        onRemove={this.props.onRemove}
        showImport={this.props.showImport}
        onImport={this.props.onImport}
        subPointInfo={this.props.subPointInfo}
        subPointInfoPermissionLevel={this.props.subPointInfoPermissionLevel}
        isInvited={this.props.isInvited}
        onClickTimeStamp={this.onClickTimeStamp}
        onTimeStampChange={this.onTimeStampChange}
        showTimeStamp={this.props.showTimeStamp}
        parentObject={this.props.parentObject}
        hideSave={this.props.hideSave}
        hideMenu={this.props.hideMenu}
        hideHeader={this.props.hideHeader}
        onReviewPoint={this.props.onReviewPoint}
        showReviews={this.props.showReviews}
        totalReviews={this.props.totalReviews}
        userPointReview={this.props.userPointReview}
        toggleReviewModal={this.props.toggleReviewModal}
        moreHeaderActions={this.props.moreHeaderActions}
      />
    );
  }

  render4Pack() {
    const {
      point,
      cardType,
      onPointDetailView,
      parentObject,
      fourPackShowActiveTab,
      fourPackEvidenceDisabled,
      fourPackQTPDisabled,
      fourPackRTPDisabled,
      fourPackShareDisabled,
      onFourPackAction,
      collection,
      priorityUsers,
      priorityNumQTP,
      priorityNumRTP,
      showCTPs,
    } = this.props;

    // if (cardType === 'outline') {
    //   return null;
    // }

    return (
      <FourPack
        point={point}
        collection={collection}
        activeTab={this.getFourPackActiveTab() || fourPackShowActiveTab}
        onChange={(tab) => {
          if (onPointDetailView) {
            onPointDetailView({ tab, point, parentPoint: parentObject });
          } else {
            this.setState({ fourPackActiveTab: tab });
          }
          onFourPackAction && onFourPackAction(false);
        }}
        className="d-flex"
        evidenceDisabled={fourPackEvidenceDisabled}
        qtpDisabled={fourPackQTPDisabled}
        rtpDisabled={fourPackRTPDisabled}
        shareDisabled={fourPackShareDisabled}
        priorityUsers={priorityUsers}
        priorityNumQTP={priorityNumQTP}
        priorityNumRTP={priorityNumRTP}
        showCTPs={showCTPs}
      />
    );
  }

  onRemoveUserFromPoint = (username) => {
    return this.props.actions.removeAuthorFromPoint(
      this.props.point._id,
      username,
    );
  };

  renderAuthor() {
    const { cardType } = this.props;

    // Do not render if card type is outline
    if (cardType === 'outline') {
      return null;
    }

    const { point, user } = this.props;

    let otherAuthors = point.authors.filter((i) => i.userId !== point.authorId);

    if (point.authorId !== user._id) {
      otherAuthors = otherAuthors.filter(
        (i) => i.invitationStatus === 'accepted',
      );
    }

    return (
      <div className="pt-3 pb-3 pl-4 pr-4 author-container d-flex align-items-center">
        <ProfileImage
          userId={point.authorId}
          allowUserToChangePic={false}
          allowClickToPortfolio
          username={point.authorName}
          hostedImageVersion={point.authorHostedImageVersion}
          size={24}
        />
        <p className="m-0 ml-3 body-medium">{point.authorName}</p>
        <div className="ml-auto">
          <MultipleProfileImages
            authors={otherAuthors}
            canInvite={this.props.isAuthor}
            onInvite={this.onInviteCollection}
            onRemove={this.onRemoveUserFromPoint}
            primaryAuthorId={point.authorId}
            pullRight={true}
            maxImages={2}
          />
        </div>
      </div>
    );
  }

  renderEvidenceStack() {
    const { point, cardType } = this.props;

    if (!point.totalSubPoints || cardType === 'claim') {
      return null;
    }

    return (
      <div className="position-relative point-stack-container">
        {point.totalSubPoints >= 1 && <div className="point-stack-line-1" />}
        {point.totalSubPoints >= 2 && <div className="point-stack-line-2" />}
        {point.totalSubPoints >= 2 && (
          <div className="position-absolute point-stack-sub-points-count">
            {point.totalSubPoints}
          </div>
        )}
      </div>
    );
  }

  renderCard() {
    let { point, cardType, className } = this.props;
    let containerClassName = classnames('point-card', cardType, className);
    let content;

    content = (
      <React.Fragment>
        {this.renderSourceURLLink()}
        {this.renderTimeStamp()}
        {this.renderTextOrHTML()}
        {this.renderPointDetails()}
        {this.renderImage()}
        {this.renderTags()}
        {this.renderBullseye()}
        {this.renderEditRow()}
        {this.render4Pack()}
        {this.renderDonate()}
      </React.Fragment>
    );

    return (
      <Fragment>
        <div className={containerClassName} onClick={this.onClick}>
          {this.renderHeader()}
          {content}
        </div>
        {this.renderEvidenceStack()}
      </Fragment>
    );
  }

  renderFourPackConnector() {
    const activeTab = this.getFourPackActiveTab();

    if (!activeTab || activeTab === 'EVIDENCE') {
      return null;
    }

    let connectorClassName = classnames(`four-pack-tabs-connect ${activeTab}`, {
      'is-mobile': isMobile(),
    });

    return <div className={connectorClassName} />;
  }

  renderFourPackTabs() {
    const {
      point,
      pointDetailView,
      onPointDetailView,
      onFourPackAction,
      isSelectionEnabled,
      isUpdateReview,
      claimQTPId,
      claimRTPId,
      collection,
      rid,
      onEvent,
      collectionPoint,
      isMock,
      captions,
      showProvideEvidence,
      isExtensionCL,
      courseId,
      priorityUsers,
      teacherMode,
      studentMode,
      topicId,
    } = this.props;

    const activeTab = this.getFourPackActiveTab();

    if (!activeTab) {
      return null;
    }

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {this.renderFourPackConnector()}
        <FourPackTabs
          activeTab={activeTab}
          point={point}
          pointDetailView={pointDetailView}
          onPointDetailView={onPointDetailView}
          showPointSelectText={(show, captions) => {
            this.setState({ showPointSelectText: show });

            if (show && captions) {
              this.setState({ selectedCaptions: captions });
            }
          }}
          selectedCaptions={this.state.selectedCaptions}
          onRTPCreate={this.props.onRTPCreate}
          onAction={onFourPackAction}
          isSelectionEnabled={isSelectionEnabled}
          isUpdateReview={isUpdateReview}
          qtpId={claimQTPId}
          rtpId={claimRTPId}
          collection={collection}
          rid={rid}
          onEvent={onEvent}
          collectionPoint={collectionPoint}
          isMock={isMock}
          captions={captions}
          showProvideEvidence={showProvideEvidence}
          isExtensionCL={isExtensionCL}
          courseId={courseId}
          priorityUsers={priorityUsers}
          teacherMode={teacherMode}
          studentMode={studentMode}
          topicId={topicId}
        />
      </div>
    );
  }

  renderTopEvidence() {
    const {
      cardType,
      point,
      points,
      user,
      pointDetailView,
      onPointDetailView,
      isSelectionEnabled,
    } = this.props;
    const fourPackActiveTab = this.getFourPackActiveTab();

    if (cardType !== 'claim' || !point.subPoints.length || fourPackActiveTab) {
      return null;
    }

    const subPointInfo = point.subPoints[0];

    if (!points[subPointInfo.pointId]) {
      console.log("Point has top evidence, but it didn't load.");
      return null;
    }

    const subPointObject = points[subPointInfo.pointId];

    if (!subPointObject.point) {
      console.log('TopEvidence - SubPoint loaded without a Point');
      console.log(subPointObject);

      if (subPointObject.errorMessage) {
        return (
          <div className="error mt-3 font-size-12">
            {subPointObject.errorMessage}
          </div>
        );
      }
    }

    const connectorClassName = classnames('top-evidence-triangle', {
      'is-mobile': isMobile(),
    });
    const connector = (
      <div className={connectorClassName}>
        <div />
      </div>
    );

    return (
      <div className="position-relative">
        {connector}
        <PointContainer
          point={subPointObject.point}
          user={user}
          cardType="top-evidence"
          subPointInfo={subPointInfo}
          pointDetailView={pointDetailView}
          onPointDetailView={onPointDetailView}
          isSelectionEnabled={isSelectionEnabled}
        />
      </div>
    );
  }

  renderQuestionedPoint() {
    const { point, cardType } = this.props;

    if (cardType !== 'questioned') {
      return null;
    }

    const qtp = point.qtps[0];

    if (!qtp) {
      return null;
    }

    const connectorClassName = classnames('questioned-connector', {
      'is-mobile': isMobile(),
    });
    const connector = (
      <div className={connectorClassName}>
        <div />
      </div>
    );
    return (
      <div className="position-relative">
        {connector}
        <QTPCard qtp={qtp} className="questioned-point-qtp" />
      </div>
    );
  }

  renderReviewedPoint() {
    const { point, cardType } = this.props;

    if (cardType !== 'reviewed') {
      return null;
    }

    const rtp = point.rtps[0];

    if (!rtp) {
      return null;
    }

    const connectorClassName = classnames('reviewed-connector', {
      'is-mobile': isMobile(),
    });
    const connector = (
      <div className={connectorClassName}>
        <div />
      </div>
    );
    return (
      <div className="position-relative">
        {connector}
        <RTPCard rtp={rtp} className="reviewed-point-rtp" />
      </div>
    );
  }

  render() {
    let { point } = this.props;

    if (!point) {
      return <div>No Point</div>;
    }

    return (
      <React.Fragment>
        {this.renderCard()}
        {this.renderFourPackTabs()}
        {this.renderTopEvidence()}
        {this.renderQuestionedPoint()}
        {this.renderReviewedPoint()}
      </React.Fragment>
    );
  }

  renderBullseye = () => {
    const { canDrop } = this.props;

    if (!canDrop) {
      return null;
    }

    return (
      <DnDBullseye
        canDrop={this.props.canDrop}
        point={this.props.point}
        toolTipText={'Drop to add as a SubPoint'}
      />
    );
  };

  renderSourceURLLink() {
    const { point, cardType } = this.props;

    if (
      (cardType !== 'questioned' && cardType !== 'reviewed') ||
      !point.sourceURL
    ) {
      return null;
    }

    return (
      <a
        className="d-inline-flex align-items-center av-blue mt-3"
        target="_blank"
        href={point.sourceURL}
      >
        <Icon
          icon={ICONS.VIDEO_PLAY}
          className="av-medium-blue mr-3"
          width={24}
          height={14}
        />
        {point.sourceTitle || point.sourceURL}
      </a>
    );
  }

  renderTimeStamp() {
    const { cardType } = this.props;

    // Do not render if card type is outline
    if (cardType === 'outline' || cardType === 'claim') {
      return null;
    }

    let { point, showTimeStamp } = this.props;

    let { showTimeStampWarning, timeStampWordCount } = this.state;

    if (!showTimeStamp) {
      return null;
    }

    let timeStamp = point.sourceTimeStampStart;

    if (timeStamp) {
      let seconds = timeStamp % 60;
      let minutes = parseInt(timeStamp / 60, 10);
      let hours = 0;

      if (minutes >= 60) {
        hours = parseInt(hours / 60, 10);
        minutes = minutes % 60;
      }

      timeStamp = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    let warning;

    if (showTimeStampWarning) {
      warning = (
        <div className="position-relative d-flex align-items-start timestamp-warning ml-4 mr-4 p-4">
          <Icon icon={ICONS.INFO} className="av-gold mr-3" />
          <div className="fill-flex">
            <h5 className="m-0 mb-3">Point is too close to the previous one</h5>
            <div className="body-medium">
              Given the number of words in previous point, we recommend to have
              at least an {timeStampWordCount}-second gap before the previous
              point.
            </div>
          </div>
          <Icon
            icon={ICONS.CLOSE}
            className="av-gold"
            onClick={this.onHideTimeStampWarning}
          />
        </div>
      );
    }

    return <div>{warning}</div>;
  }

  renderTextOrHTML = () => {
    let {
      point,
      onPointFieldChanged,
      isEditing,
      cardType,
      captions,
      activeCaption,
      showOnlyActiveCaption,
    } = this.props;
    let { showPointSelectText, selectedCaptions, textExpanded } = this.state;

    // No need to render if point text is empty
    if (!isEditing && !point.text) {
      return;
    }

    if (this.props.hideText) return;

    let text;

    if (isEditing) {
      text = (
        <Editor
          onChange={(text) => {
            onPointFieldChanged('text', text);
          }}
          placeholder="Type your comment here"
          html={point.html || point.text}
        />
      );
    } else {
      let pointImage;

      if (cardType === 'outline' && point.sourceImageURL) {
        pointImage = (
          <div
            className="d-inline mr-3 point-image-inline"
            onClick={this.showFullScreenImagePreview}
          >
            <img src={point.sourceImageURL} />
            <div className="position-absolute p-3 d-none hover-preview">
              <img src={point.sourceImageURL} />
            </div>
          </div>
        );
      }

      let pointText;

      if (captions && captions.length && showPointSelectText) {
        pointText = (
          <PointSelectText
            point={point}
            captions={captions}
            selectedCaptions={selectedCaptions}
            onChange={(captions) =>
              this.setState({ selectedCaptions: captions })
            }
            activeCaption={activeCaption}
          />
        );
      } else {
        if (captions && captions.length) {
          let filteredCaptions;

          if (showOnlyActiveCaption) {
            if (activeCaption) {
              filteredCaptions = captions.filter(
                (i) => activeCaption && activeCaption.start === i.start,
              );
            }

            if (!filteredCaptions || !filteredCaptions.length) {
              filteredCaptions = captions.slice(0, 1);
            }
          }

          pointText = (filteredCaptions || captions).map((caption) => {
            const className = classnames({
              'bg-av-light-blue':
                activeCaption && activeCaption.start === caption.start,
            });
            return <span className={className}>{caption.text + ' '}</span>;
          });
        } else {
          let html, hasMoreText;
          const MAX_CHARACTER_LIMIT = 150;

          if (point.html) {
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = point.html;
            hasMoreText = tempDiv.textContent.length > MAX_CHARACTER_LIMIT;

            if (hasMoreText && !textExpanded) {
              html =
                htmlSubstring(point.html, MAX_CHARACTER_LIMIT) +
                '<span> ...</span>';
            } else {
              html = point.html;
            }
          } else {
            hasMoreText = point.text.length > MAX_CHARACTER_LIMIT;
            if (hasMoreText && !textExpanded) {
              html = point.text.substr(0, MAX_CHARACTER_LIMIT) + ' ...';
            } else {
              html = point.text;
            }
          }

          pointText = (
            <Fragment>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              {hasMoreText ? (
                <span
                  className="av-blue font-size-12 cursor-pointer"
                  onClick={(e) => {
                    this.setState({ textExpanded: !textExpanded });
                    e.stopPropagation();
                  }}
                >
                  {textExpanded ? 'less' : 'more'}
                </span>
              ) : null}
            </Fragment>
          );
        }
      }

      text = (
        <React.Fragment>
          {pointImage}
          {pointText}
        </React.Fragment>
      );
    }
    const className = classnames('body-large point-text', {
      'body-large': !isMobile(),
      'body-small': isMobile(),
    });
    return <div className={className}>{text}</div>;
  };

  renderEditRow = () => {
    const { isEditing, cardType } = this.props;

    if (!isEditing) return null;

    let hint;

    if (cardType === 'outline') {
      const pointBtn = (
        <button
          type="button"
          className="ml-3 btn btn-primary btn-submit d-inline-flex align-items-center font-weight-600"
          onClick={this.props.onSavePoint}
        >
          <Icon icon={ICONS.EVIDENCE} />
          Point
        </button>
      );

      hint = (
        <div
          className="gray-3 font-weight-bold ml-auto"
          style={{ right: 0, top: '5px' }}
        >
          <small>
            <span className="gray-4">Shift + Return</span> to save{' '}
          </small>
          {pointBtn}
        </div>
      );
    }

    const className = classnames('d-flex align-items-center', {
      // 'pl-3 pr-3 pb-3': cardType !== 'outline',
    });

    return (
      <div className={className}>
        <PointEditControls
          point={this.props.point}
          onToggleEdit={this.props.onToggleEdit}
          onEditPointField={this.props.onEditPointField}
          isEditing={this.props.isEditing}
          isEditingFields={this.props.isEditingFields}
          errorMessages={this.props.errorMessages}
          onPointFieldChanged={this.props.onPointFieldChanged}
        />
        {hint}
      </div>
    );
  };

  onClick = (e) => {
    if (this.props.isEditing) {
      e.stopPropagation();
      return;
    }
  };

  renderPointDetails = () => (
    <PointDetail
      point={this.props.point}
      view={'card'}
      citationStyle={this.props.citationStyle}
      isEditing={this.props.isEditing}
      isEditingFields={this.props.isEditingFields}
      onPointFieldChanged={this.props.onPointFieldChanged}
      errorMessages={this.props.errorMessages}
      updateMasonry={this.props.updateMasonry}
      cardType={this.props.cardType}
    />
  );

  renderTags() {
    const { cardType } = this.props;

    // Do not render if card type if outline
    if (cardType === 'outline') {
      return null;
    }

    const { point, view, isEditingFields, onPointFieldChanged, isEditing } =
      this.props;

    if (view === 'outline') {
      //Tags only visible in edit mode for outline view
      if (!this.props.isEditingFields.tags) return null;
    }

    if (!point.tags || point.tags.length === 0) {
      if (!this.props.isEditingFields.tags) return null;
    }

    if (isEditing && !point.showTags) {
      return;
    }

    return (
      <div className="point-tags">
        <EditableTags
          tags={point.tags}
          // displayType={'piped'}
          setTags={(tags) => onPointFieldChanged('tags', tags)}
          tagsEditable={isEditingFields.tags}
          startEditable={isEditingFields.tags}
        />
      </div>
    );
  }

  renderImage() {
    const { cardType, isEditing } = this.props;

    if (cardType === 'outline' && !isEditing) {
      return null;
    }

    const { point, user } = this.props;

    if (isEditing) {
      if (!point.showPhoto) {
        return;
      }
    } else if (!point.sourceImageURL && !point.hostedImageURL) {
      return null;
    }

    return (
      <PointImage
        point={point}
        showPhoto={point.showPhoto}
        user={user}
        isSaved
        isEditing={this.props.isEditing}
        onPointFieldChanged={this.props.onPointFieldChanged}
        cardType={this.props.cardType}
        className="point-image-container"
      />
    );
  }

  onInviteCollection = () => {
    const { point } = this.props;
    this.props.openInviteCollectionModal(point);
  };

  showFullScreenImagePreview = (e) => {
    e.stopPropagation();
    const props = {
      point: this.props.point,
      user: this.props.user,
    };
    this.props.actions.openModal('full-picture', props);
  };
}

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;

  const { point } = ownProps;
  let isAuthor;

  if (point._id) {
    const pointObject = state.points[point._id];

    // isAuthor =
    //   pointObject &&
    //   pointObject.invitationStatus === 'accepted' &&
    //   pointObject.permissionLevel === 'write';
    isAuthor = pointObject && pointObject.isAuthor;
  }

  return {
    isAuthor,
    points: state.points,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
    openInviteCollectionModal: (point) => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal('invite-point', props));
    },
    openPointModal: (point) => {
      let props = {
        model: {
          point: point,
          isModal: true,
          showReviewOnOpen: false,
        },
      };
      dispatch(Actions.openModal('Point', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PointCard);

function htmlSubstring(s, n) {
  var m,
    r = /<([^>\s]*)[^>]*>/g,
    stack = [],
    lasti = 0,
    result = '';

  //for each tag, while we don't have enough characters
  while ((m = r.exec(s)) && n) {
    //get the text substring between the last tag and this one
    var temp = s.substring(lasti, m.index).substr(0, n);
    //append to the result and count the number of characters added
    result += temp;
    n -= temp.length;
    lasti = r.lastIndex;

    if (n) {
      result += m[0];
      if (m[1].indexOf('/') === 0) {
        //if this is a closing tag, than pop the stack (does not account for bad html)
        stack.pop();
      } else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
        //if this is not a self closing tag than push it in the stack
        stack.push(m[1]);
      }
    }
  }

  //add the remainder of the string, if needed (there are no more tags in here)
  result += s.substr(lasti, n);

  //fix the unclosed tags
  while (stack.length) {
    result += '</' + stack.pop() + '>';
  }

  return result;
}

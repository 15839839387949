/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "redux/actions/index";
import { withRouter } from "react-router-dom";

import cx from "classnames";
import _ from "lodash";

import "./tags.scss";

class DisplayTags extends Component {
  static propTypes = {
    autofocus: PropTypes.bool,
    handleTagClick: PropTypes.func,
    deleteTag: PropTypes.bool,
    tags: PropTypes.array,
    selectTopTags: PropTypes.bool,
    tagsEditable: PropTypes.bool,
    hasTag: PropTypes.func,
    onTagSelect: PropTypes.func,
  };
  constructor(props) {
    super(props);
  }

  handleTagClick = (e, tag) => {
    e.stopPropagation();
    if (!this.props.tagsEditable) {
      const location = {
        pathname: "/search",
        search: "?searchTerm=" + tag,
      };
      this.props.dispatch(push(location));
      return;
    }

    if (tag && this.props.handleTagClick) {
      this.props.handleTagClick(tag.trim());
    }
  };

  renderDeleteTag(tag) {
    if (!this.props.deleteTag) return null;

    const dismisButtonCss = {
      display: this.props.deleteTag ? "" : "none",
    };

    return (
      <span
        className="fa fa-times ml-3"
        style={dismisButtonCss}
        onClick={() => this.props.deleteTag(tag)}
      />
    );
  }

  renderTags() {
    if (!this.props.tags) return;
    if (this.props.selectTopTags) {
      return this.renderSelectTags();
    }

    const tagsForDisplay = _.filter(this.props.tags, tag => {
      return tag && tag.length >= 3;
    });

    let className = "tag";
    if (this.props.displayType == "piped") className += "-piped";

    return tagsForDisplay.map((tag, index) => (
      <div
        className={className}
        key={index}
        title={
          this.props.tagsEditable
            ? "Click to edit tag"
            : "Click to see all Points with a " + tag + " tag"
        }
      >
        <span className="tag-text" onClick={e => this.handleTagClick(e, tag)}>
          {tag}
        </span>
        {this.renderDeleteTag(tag)}
      </div>
    ));
  }

  renderSelectTags() {
    return this.props.tags.map((tag, index) => {
      return (
        <div
          className={cx("tag", { active: this.props.hasTag("tags", tag) })}
          key={index}
          onClick={() => this.props.onTagSelect(tag)}
        >
          <span>{tag}</span>
        </div>
      );
    });
  }

  render() {
    let className = "tags-container";
    if (this.props.displayType == "piped") className += " piped";

    return <div className={className}>{this.renderTags()}</div>;
  }
}

DisplayTags.propTypes = {
  tags: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisplayTags)
);

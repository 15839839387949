import React from "react";
import { Row, Col } from "react-bootstrap";

const MessageInput = props => {
  const {
    message,
    onChange,
    onSend,
    point,
    user,
    displayReview,
    onAddPoint
  } = props;
  let recipientName;
  if (user._id == point.authorId) {
    recipientName = displayReview.reviewerUsername;
  } else if (user._id == displayReview.reviewerId) {
    recipientName = point.authorName;
  } else {
    console.log(
      "Error: user is not logged, not the Point's author, or not the reviewer"
    );
  }
  return (
    <div className="review-send">
      <div className="message">
        <div>
          <textarea
            className="form-control"
            name="message"
            rows={2}
            cols={40}
            value={message}
            onChange={onChange}
            placeholder="Type your message to"
          />{" "}
          {!message && <span className="from-name">{recipientName}</span>}
          <div
            className="pull-right"
            style={{
              display: "inline-block"
            }}
          >
            <span>
              <i className="fa fa-paperclip" onClick={onAddPoint} />
            </span>
            <button
              className="btn send-btn pointer pull-right"
              onClick={onSend}
              disabled={!message.length}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MessageInput;

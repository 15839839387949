/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { withRouter } from 'react-router-dom';

import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import CollectionTree from 'components/elements/organisms/CollectionTree/CollectionTree';

import './ImportPoint.scss';

class ImportPoint extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    // search: PropTypes.object,
    // portfolioObject: PropTypes.object,
    // points: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      // collectionObjects: [],
      // view: 'portfolio', //"portfolio","collection","confirmation"
      stance: 'Neutral',
      // searchTermCollections: '',
      // searchTermPoints: '',
      selectedPointIds: [],
    };
  }

  onSelectCollection(collectionId) {
    const index = this.state.selectedPointIds.indexOf(collectionId);

    if (index == -1) {
      this.setState({
        selectedPointIds: [...this.state.selectedPointIds, collectionId],
      });
    } else {
      this.setState({
        selectedPointIds: [
          ...this.state.selectedPointIds.slice(0, index),
          ...this.state.selectedPointIds.slice(index + 1),
        ],
      });
    }
  }

  renderFooter() {
    // const selectedPointId = this.state.selectedPoint
    //   ? this.state.selectedPoint._id
    //   : null;

    return (
      <div className="mt-4 d-flex align-items-center">
        <h3 className="m-0 av-blue">
          {this.state.selectedPointIds.length} Points Selected
        </h3>
        <RoundedButton
          onClick={() => this.props.onPointImported(
            this.state.selectedPointIds,
            this.state.stance
          )}
          disabled={this.state.selectedPointIds.length == 0}
          label="Import"
          mainClassName="ml-auto"
        />
      </div>
    );
  }

  renderTitle() {
    let text;

    if (this.state.view === 'portfolio') {
      text = "Navigate to the Point you'd like to import";
    } else {
      text = 'Select Points to import';
    }

    return <h3 className="m-0">{text}</h3>;
  }

  renderCollectionPointsTree() {
    return (
      <CollectionTree
        selectCollection={collectionId => {
          this.onSelectCollection(collectionId);
        }}
        selectedIds={this.state.selectedPointIds}
        multiple={true}
        mode="ONLY_POINTS"
        searchPlaceholder="Search Points"
        showPointPreview={true}
        showSubPointCount={true}
        className="mt-4"
      />
    );
  }

  render() {
    return (
      <div className="import-import p-4">
        {this.renderTitle()}
        {this.renderCollectionPointsTree()}
        {this.renderFooter()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {

  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ImportPoint),
);

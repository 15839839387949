import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import ArticleDetails from './ArticleDetails/ArticleDetails';
import AnalyticsDetails from './AnalyticsDetails/AnalyticsDetails';
import QuestionsStep from 'components/elements/organisms/Questions/Questions';
import { Icon, ICONS } from '../../Icons';

import './CurateArticleModal.scss';

const initialArticleData = {
  sourceImages: [],
  sourceTitle: '',
  sourceAuthor: '',
  sourceName: '',
  sourceDate: '',
  sourceImageURL: '',
  questions: [],
};

function CurateArticleModal({ pointData, appData, onClose, actions, refetch }) {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [point, setPoint] = useState({
    ...initialArticleData,
  });

  useEffect(() => {
    if (pointData && Object.keys(pointData)) {
      const copyPointData = JSON.parse(JSON.stringify(pointData));

      if (!copyPointData.sourceImages && copyPointData.sourceImageURL) {
        copyPointData.sourceImages = [copyPointData.sourceImageURL];
        copyPointData.sourceImageActiveIndex = 0;
      }

      if (!copyPointData.sourceDate) {
        copyPointData.sourceDate = copyPointData.createDate || '';
      }

      if (!copyPointData.sourceAuthor) {
        copyPointData.sourceAuthor = copyPointData.authorName || '';
      }

      let topics = copyPointData.sourceAnalytics.topic
        ? copyPointData.sourceAnalytics.topic
        : [];
      topics = Array.isArray(topics) ? topics : [topics];

      const additionalMainTopics = copyPointData.sourceAnalytics.topLevelTopics
        ? copyPointData.sourceAnalytics.topLevelTopics
        : [];
      topics = topics.concat(additionalMainTopics);

      copyPointData.sourceAnalytics.topic = topics;

      setPoint({
        ...point,
        ...copyPointData,
      });
    }
  }, [pointData]);

  const changeSourceAnalytics = (property, value) => {
    setPoint((prevPoint) => ({
      ...prevPoint,
      sourceAnalytics: {
        ...prevPoint.sourceAnalytics,
        [property]: value,
      },
    }));
  };

  const changeQuestions = (questions) => {
    setPoint({
      ...point,
      questions: [...questions],
    });
  };

  const changePoint = (property, value) => {
    if (property === 'sourceImageURL') {
      setPoint({
        ...point,
        sourceImageURL: value,
        sourceImageActiveIndex: point.sourceImages.indexOf(value),
      });
    } else if (property === 'sourceImages') {
      setPoint({
        ...point,
        sourceImages: value,
        sourceImageURL: value[0],
        sourceImageActiveIndex: 0,
      });
    } else if (property === 'hostedImage') {
      const hostedImageFields = value;
      setPoint({
        ...point,
        ...hostedImageFields,
        sourceImages: [...point.sourceImages, hostedImageFields.hostedImageURL],
        sourceImageURL: hostedImageFields.hostedImageURL,
        sourceImageActiveIndex: point.sourceImages.length,
      });
    } else {
      setPoint({
        ...point,
        [property]: value,
      });
    }
  };

  const lookupURL = () => {
    if (!url) {
      return;
    }

    setLoading(true);

    actions
      .lookupURL(url)
      .then((data) => {
        setPoint({
          ...data,
          sourceAnalytics: {},
          sourceImageActiveIndex: 0,
          questions: [],
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const saveCuratedArticle = () => {
    setLoading(true);

    actions
      .saveCuratedArticle(point)
      .then(() => {
        setLoading(false);
        onClose();
        if (refetch) {
          refetch();
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const renderStep = () => {
    switch (step) {
      case 1: {
        return (
          <ArticleDetails
            point={point}
            url={url}
            loading={loading}
            setUrl={setUrl}
            lookupURL={lookupURL}
            changePoint={changePoint}
          />
        );
      }
      case 2: {
        return (
          <AnalyticsDetails
            appData={appData}
            point={point}
            changeSourceAnalytics={changeSourceAnalytics}
          />
        );
      }
      case 3: {
        return (
          <QuestionsStep
            questions={point.questions}
            loading={loading}
            changeQuestions={changeQuestions}
          />
        );
      }
    }
  };

  return (
    <Modal show={true} className="log-reading-modal">
      <Modal.Body>
        <div className="header">
          <span>Add an article</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {renderStep()}
        <div className="actions">
          <RectangularButton
            className="back-button"
            iconName="ARROW_BACKWARD"
            iconSize={16}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Back
          </RectangularButton>
          <RectangularButton
            className="next-button"
            disabled={!point.sourceURL}
            onClick={() => {
              step === 3 ? saveCuratedArticle() : setStep(step + 1);
            }}
          >
            {step === 3 ? 'Save' : 'Next'}
          </RectangularButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    appData: state.app ? state.app : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurateArticleModal);

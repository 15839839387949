/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import CloudinaryLink from 'components/elements/molecules/Images/CloudinaryLink';
import { Icon, ICONS } from 'components/Icons';
import { isMac } from 'helpers';

import './PointEditControls.scss';

export default class PointEditControls extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    onToggleEdit: PropTypes.func,
    isEditing: PropTypes.bool,
    onEditPointField: PropTypes.func,
    isEditingFields: PropTypes.object,
    onPointFieldChanged: PropTypes.func,
    errorMessages: PropTypes.object,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onPointFieldChanged, point, type } = this.props;
    const sourceClassName = classnames({
      active: point.showQuote,
    });
    const attachPicClassName = classnames('ml-2', {
      active: point.showPhoto,
    });
    const tagsClassName = classnames('ml-2', {
      active: point.showTags,
    });
    const className = classnames(
      'point-edit-controls d-flex align-items-center toggle-controls',
      this.props.className,
    );

    let addSourceToolTipText = '',
      addTagToolTipText = '',
      addPhotoToolTipText = '';

    // if (type === 'LIGHTWEIGHT') {
    addSourceToolTipText += `${isMac() ? 'Command' : 'Ctrl'} + Shift + S `;
    addTagToolTipText += `${isMac() ? 'Command' : 'Ctrl'} + Shift + T `;
    addPhotoToolTipText += `${isMac() ? 'Command' : 'Ctrl'} + Shift + I `;
    // }

    addSourceToolTipText += 'Add a quote and source info';
    addTagToolTipText += 'Add a tag';
    addPhotoToolTipText += 'Add a photo';

    return (
      <div className={className}>
        <Icon
          icon={ICONS.SOURCE}
          className={sourceClassName}
          size={36}
          onClick={() => {
            onPointFieldChanged('showQuote', !point.showQuote);
          }}
          toolTipText={addSourceToolTipText}
          toolTipPosition="top"
          toolTipStyle={{ whiteSpace: 'nowrap' }}
        />
        <Icon
          icon={ICONS.TAGS}
          size={36}
          className={tagsClassName}
          onClick={() => {
            onPointFieldChanged('showTags', !point.showTags);
          }}
          toolTipText={addTagToolTipText}
          toolTipPosition="top"
          toolTipStyle={{ whiteSpace: 'nowrap' }}
        />
        <Icon
          icon={ICONS.ATTACH_PIC}
          size={36}
          className={attachPicClassName}
          onClick={() => {
            onPointFieldChanged('showPhoto', !point.showPhoto);
          }}
          toolTipText={addPhotoToolTipText}
          toolTipPosition="top"
          toolTipStyle={{ whiteSpace: 'nowrap' }}
        />
      </div>
    );
  }

  renderErrorMessage = () => (
    <span className="error">{this.props.errorMessages.overall}</span>
  );

  renderAddSource = () => {
    if (this.props.point.sourceName) return null;

    return (
      <div
        className="add-link add-source-link"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionLink
          onClick={() => this.props.onEditPointField('source')}
          linkClassName=""
          iconType="averPointIcon"
          iconName="ADD"
          iconClassName="av-blue mr-2"
          label="Source"
          toolTipText="Add a Source to back up your Point"
          toolTipPosition="bottom"
        />
      </div>
    );
  };

  renderAddTags = () => {
    // if (this.props.point.tags && this.props.point.tags.length > 0) return null;

    if (this.props.isEditingFields['tags']) return null;

    return (
      <div
        className="add-link add-tags-link"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionLink
          onClick={() => this.props.onEditPointField('tags')}
          linkClassName=""
          iconType="averPointIcon"
          iconName="ADD"
          iconClassName="av-blue mr-2"
          label="Tags"
          toolTipText="Add tags to help you organize your Points"
          toolTipPosition="bottom"
        />
      </div>
    );
  };

  renderAddPicture = () => {
    if (this.props.isEditingFields['image']) return null;

    const { point } = this.props;

    const imageExists =
      (point.photoLicenseTerms &&
        point.photoLicenseTerms.status == 'accepted') ||
      point.sourceImageURL;

    if (imageExists) {
      if (!point.showPhoto) {
        return (
          <div
            className="add-link add-tags-link"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <ActionLink
              onClick={() => this.props.onPointFieldChanged('showPhoto', true)}
              linkClassName=""
              iconType="averPointIcon"
              iconName="ADD"
              iconClassName="av-blue mr-2"
              label="Image"
              toolTipText="Show the Point's image."
              toolTipPosition="bottom"
            />
          </div>
        );
      }
      return null; // If showing the photo, edit through the photo controls.
    }

    const photoId = `points/${point._id}`;

    return (
      <div
        className="add-link add-photo-link action-link"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <CloudinaryLink
          imageExists={false}
          cloudinaryImageId={photoId}
          onLicenseTermsAccepted={this.onLicenseTermsAccepted}
        >
          {/* <ActionLink
              onClick={()=>this.props.onEditPointField("image")}
              linkClassName=""
              iconType="averPointIcon"
              iconName="ADD"
              label="Image"
              toolTipText="Add an image to the Point."
              toolTipPosition="bottom"
            /> */}
          {/* <span>+ Image</span> */}
          <Icon icon={ICONS.ADD} className="av-blue mr-2" /> Image
        </CloudinaryLink>
      </div>
    );
  };

  onLicenseTermsAccepted = (terms, hostedImageURL, hostedImageVersion) => {
    let hostedImage = {
      hostedImageURL,
      hostedImageVersion,
      photoLicenseTerms: terms,
      imageType: 'hosted',
    };
    this.props.onPointFieldChanged('hostedImage', hostedImage);
    // this.props.onPointFieldChanged("hostedImageVersion", hostedImageVersion);
    // this.props.onPointFieldChanged("hostedImageURL", hostedImageURL);
    // this.props.onPointFieldChanged("photoLicenseTerms", terms);
    // this.props.onPointFieldChanged("imageType", "hosted");
    // this.props.onPointFieldChanged("showPhoto", true);
  };

  renderEditMessage = () => {
    if (!this.props.isEditing) return null;
    if (!this.props.onToggleEdit) return null;

    return (
      <ActionLink
        onClick={this.props.onToggleEdit}
        linkClassName="editMessage"
        // iconType="averPointIcon"
        // iconName="PENCIL"
        label="Finish editing"
        toolTipText="Click to edit this Point's text, source, etc."
      />
    );
  };
}

import React, { useReducer, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MenuItem, Dropdown } from 'react-bootstrap';

import ProfileImage from 'components/elements/molecules/Images/profileImage';
// import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import InteractionReviews from './InteractionReview';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './RTPCard.scss';

function RTPCard({
  rtp,
  className,
  onUpdate,
  user,
  point,
  collection,
  openShareModal,
  courseId,
  actions,
  teacherMode,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'TOGGLE_MENU':
          return { ...state, showMenu: !state.showMenu };
        default:
          return state;
      }
    },
    { showMenu: false },
  );

  useEffect(() => {
    if (rtp.interactionReviews) {
      return;
    }

    actions.getInteractionReviews(rtp, 'RTP', point);
  }, [rtp]);

  function onShare() {
    const url = `${window.location.origin}/collection/${collection._id}?claimId=${point._id}&claimRTPId=${rtp._id}`;
    const model = {
      shareUrl: url,
      iframeUrl: url,
      directLink: url,
      mediumUrl: url,
    };
    openShareModal(model, 'Review');
  }
  function renderUpdateMyReviewMenuItem() {
    if (!onUpdate || user.user._id !== rtp.userId) {
      return null;
    }

    return (
      <MenuItem onClick={() => onUpdate(rtp)} bas>
        <Icon icon={ICONS.REVIEW} className="mr-3" />
        Update my Review
      </MenuItem>
    );
  }

  function renderMenu() {
    if (!point && !collection) {
      return null;
    }

    return (
      <Dropdown
        pullRight={true}
        className="more-menu ml-auto"
        onClick={(e) => e.stopPropagation()}
        onToggle={() => dispatch({ type: 'TOGGLE_MENU' })}
        id="qtp-card-dropdown"
        open={state.showMenu}
      >
        <Dropdown.Toggle noCaret>
          <Icon icon={ICONS.MORE} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {renderUpdateMyReviewMenuItem()}
          <MenuItem onClick={onShare}>
            <Icon icon={ICONS.SAVE_1} className="mr-3" />
            Share
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function renderHeader() {
    const { surveyResponse } = rtp;
    return (
      <div className="d-flex align-items-center">
        <ProfileImage
          username={rtp.user.username}
          userId={rtp.userId}
          allowUserToChangePic={false}
          allowClickToPortfolio={false}
          size={24}
        />
        <div className="ml-3 font-weight-600 gray-3 font-size-12">
          {surveyResponse.sufficientEvidence === 'Yes'
            ? 'Verified'
            : 'Rejected'}{' '}
          the Point
        </div>
        {renderMenu()}
      </div>
    );
  }

  function renderCaptions() {
    const { surveyResponse } = rtp;

    if (!surveyResponse.captions || !surveyResponse.captions.length) {
      return null;
    }

    return (
      <div className="captions">
        ...
        {surveyResponse.captions.map((caption) => (
          <span>{caption.text}</span>
        ))}
        ...
      </div>
    );
  }

  function renderSurveyItem(text, key) {
    return (
      <div className="d-flex align-items-center mt-2" key={key}>
        <div
          className="av-medium-blue flex-shrink-0 justify-content-center d-inline-flex align-items-center"
          style={{ width: '24px' }}
        >
          <Icon icon={ICONS.CHECK} size={16} />
        </div>
        <div className="ml-3">{text}</div>
      </div>
    );
  }

  function renderSufficientEvidenceItem() {
    const surveyResponse = rtp.surveyResponse || {};
    const sufficientEvidence = surveyResponse.sufficientEvidence;

    if (!sufficientEvidence) {
      return null;
    }

    if (sufficientEvidence === 'Yes') {
      return renderSurveyItem('Sufficient Evidence');
    } else if (sufficientEvidence === 'No') {
      return renderSurveyItem('No Sufficient Evidence');
    }
  }

  function renderWhatsWrongItem() {
    const surveyResponse = rtp.surveyResponse || {};
    const whatsWrong = surveyResponse.whatsWrong;

    if (!whatsWrong || !whatsWrong.length) {
      return null;
    }

    return whatsWrong.map(renderSurveyItem);
  }

  function renderSurvey() {
    return (
      <Fragment>
        {renderSufficientEvidenceItem()}
        {renderWhatsWrongItem()}
      </Fragment>
    );
  }

  function renderUpdateMyReviewBtn() {
    if (!onUpdate || user.user._id !== rtp.userId) {
      return null;
    }

    return (
      <div className="d-flex align-items-center justify-content-center mt-3">
        <RectangularButton
          onClick={() => onUpdate(rtp)}
          className="font-size-12"
        >
          Update my Review
        </RectangularButton>
      </div>
    );
  }

  function renderInteractions() {
    // if (!courseId) {
    //   return null;
    // }

    return (
      <InteractionReviews
        interactionId={rtp._id}
        interactionType="RTP"
        courseId={courseId}
        actions={actions}
        interactionReviews={rtp.interactionReviews}
        user={user}
        interaction={rtp}
        point={point}
        teacherMode={teacherMode}
      />
    );
  }

  const containerClassName = classnames('rtp-card p-3', className);

  return (
    <div className={containerClassName}>
      {renderHeader()}
      {renderCaptions()}
      {renderSurvey()}
      {renderUpdateMyReviewBtn()}
      {renderInteractions()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openShareModal: (model, type) => {
      const props = { model, type };
      dispatch(Actions.openModal('Share', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RTPCard);

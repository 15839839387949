/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { connect } from 'react-redux';
import * as Actions from 'redux/actions';
import { bindActionCreators } from 'redux';

import { selectGroupDetails } from 'redux/selectors/groups';

import ProfileImage from 'components/elements/molecules/Images/profileImage';
import { Icon, ICONS } from 'components/Icons';

import './InviteResponse.scss';

function InviteResponse({
  isInvited,
  inviterUserId,
  inviterUsername,
  source,
  pointId,
  groupId,
  groupLeaderOrMember,
  actions,
  className,
}) {
  const renderInviterProfileImage = () => {
    if (inviterUserId) {
      return (
        <ProfileImage
          userId={inviterUserId}
          allowUserToChangePic={false}
          allowClickToPortfolio
          username={inviterUsername}
          size={24}
        />
      );
    } else {
      return (
        <div
          style={{ width: '8px', height: '8px', borderRadius: '8px' }}
          className="bg-av-red"
        />
      );
    }
  };

  const renderInviteText = () => {
    let className = classnames('m-0 ml-3 mr-4 font-size-12 av-gray', {
      'font-weight-bold': !!inviterUserId,
    });

    if (inviterUsername) {
      let sourceText;

      switch (source) {
        case 'COLLECTION':
          sourceText = 'collection';
          break;
        case 'POINT':
          sourceText = 'point';
          break;
        case 'GROUP':
          sourceText = 'group';
          break;
        default:
          break;
      }

      return (
        <p className={className}>
          <strong className="av-blue">{inviterUsername}</strong> has invited you
          to collaborate on this {sourceText}
        </p>
      );
    } else {
      return <p className={className}>You are invited</p>;
    }
  };

  const renderAcceptButton = () => {
    let className = classnames(
      'btn bg-transparent border-av-green accept m-0 ml-auto border-radius-25 font-size-12',
      {
        'font-weight-bold': !!inviterUserId,
      },
    );

    let onAccept;
    if (source === 'COLLECTION' || source === 'POINT') {
      onAccept = () => actions.respondInvitePoint(pointId, 'accepted');
    } else if (source === 'GROUP')
      onAccept = () =>
        actions.respondInviteGroup(groupId, groupLeaderOrMember, 'accepted');

    return (
      <button
        className={className}
        onClick={e => {
          e.preventDefault();
          onAccept();
        }}
      >
        <Icon
          icon={ICONS.CHECK}
          className="align-middle"
          style={{ strokeWidth: '2px' }}
        />{' '}
        Accept
      </button>
    );
  };

  const renderDeclineButton = () => {
    let className = classnames(
      'btn bg-transparent border-av-red decline m-0 ml-4 border-radius-25 font-size-12',
      {
        'font-weight-bold': !!inviterUserId,
      },
    );

    let onDecline;
    if (source === 'COLLECTION' || source === 'POINT') {
      onDecline = () => actions.respondInvitePoint(pointId, 'rejected');
    } else if (source === 'GROUP')
      onDecline = () =>
        actions.respondInviteGroup(groupId, groupLeaderOrMember, 'rejected');

    return (
      <button
        className={className}
        onClick={e => {
          e.preventDefault();
          onDecline();
        }}
      >
        <Icon
          icon={ICONS.CLOSE}
          className="align-middle"
          style={{ strokeWidth: '2px' }}
        />{' '}
        Decline
      </button>
    );
  };

  if (!isInvited) {
    return null;
  }

  const containerClassName = classnames(
    'd-flex align-items-center justify-content-center invite-reponse',
    className,
  );

  return (
    <div className={containerClassName}>
      {renderInviterProfileImage()}
      {renderInviteText()}
      {renderAcceptButton()}
      {renderDeclineButton()}
    </div>
  );
}

InviteResponse.propTypes = {
  isInvited: PropTypes.bool,
  inviterUserId: PropTypes.string,
  inviterUsername: PropTypes.string,
  // onAccept: PropTypes.func.isRequired,
  // onDecline: PropTypes.func.isRequired,
  source: PropTypes.oneOf(['COLLECTION', 'POINT', 'GROUP']).isRequired,
  pointId: PropTypes.string,
  groupId: PropTypes.string,
  groupLeaderOrMember: PropTypes.string,
  actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const { pointId, groupId } = ownProps;
  const user = state.user.user;
  let isInvited;
  let inviterUserId;
  let inviterUsername;
  let groupLeaderOrMember;
  if (pointId) {
    // getPointInvite info
    const pointObject = state.points[pointId];
    const { invitationStatus, point } = pointObject;
    isInvited = invitationStatus === 'invited';
    if (isInvited) {
      const inviteObject = point.authors.find(i => i.userId === user._id);
      inviterUserId = inviteObject.inviterUserId;
      inviterUsername = inviteObject.inviterUsername;
    }
  } else if (groupId) {
    // getGroupInviteInfo
    const { group } = selectGroupDetails(state, groupId);
    isInvited = group.meta.isInvitedMember || group.meta.isInvitedLeader;
    groupLeaderOrMember = group.meta.isInvitedLeader ? 'leader' : 'member';
  }
  return { isInvited, inviterUserId, inviterUsername, groupLeaderOrMember };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openSharePointModal: (model, type) => {
      const props = { model, type };
      dispatch(Actions.openModal('Share', props));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteResponse);

import React, { useEffect, useReducer, Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'redux/actions/index';
import { withRouter } from 'react-router-dom';

import './ProgressBar.scss';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { Icon, ICONS } from 'components/Icons';
import GettingStartedProgressBar from 'helpers/gettingStartedProgressBar.json';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

function ProgressBar({
  steps,
  title,
  activeIndex,
  type,
  className,
  history,
  actions,
  openProgressBarNextSteps,
}) {

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_POPUP':
          return {
            ...state,
            showPopup: true,
            showSummaryPopup: false,
          };
        case 'HIDE_POPUP':
          return {
            ...state,
            showPopup: false,
            showSummaryPopup: false,
          };
        case 'SHOW_SUMMARY_POPUP':
          return {
            ...state,
            showSummaryPopup: true,
            showPopup: false,
          };
        case 'HIDE_SUMMARY_POPUP':
          return {
            ...state,
            showSummaryPopup: false,
            showPopup: false,
          };
        default:
          return state;
      }
    },
    {
      showPopup: false,
      showSummaryPopup: false,
    },
  );

  useEffect(() => {
    if (!state.showPopup && !state.showSummaryPopup) {
      dispatch({ type: 'SHOW_SUMMARY_POPUP' });
    }
  }, [activeIndex]);

  function renderTransparentLayer() {
    if (!state.showSummaryPopup) {
      return null;
    }

    return <div className="progress-bar-transparent-layer" />;
  }

  function renderTitle() {
    return (
      <div className="text-center">
        <span className="d-none d-md-inline">{title} : </span>
        {activeIndex + 1}/{steps.length}
      </div>
    );
  }

  function renderStep(i) {
    const className = classnames('step flex-fill', {
      checked: i < activeIndex,
    });
    return <div className={className} key={i} />;
  }

  function renderSteps() {
    return (
      <div className="steps d-flex">
        {_.range(steps.length).map(renderStep)}
      </div>
    );
  }

  function renderPopup() {
    if (!state.showPopup) {
      return null;
    }

    return (
      <ProgressBarPopup
        activeIndex={activeIndex}
        steps={steps}
        title={title}
        onClose={() => {
          dispatch({ type: 'HIDE_POPUP' });
        }}
      />
    );
  }

  function renderSummaryPopup() {
    if (!state.showSummaryPopup) {
      return null;
    }

    return (
      <ProgressBarSummaryPopup
        activeIndex={activeIndex}
        steps={steps}
        onClose={() => {
          dispatch({ type: 'HIDE_POPUP' });
        }}
        onProgressDetail={() => {
          dispatch({ type: 'SHOW_POPUP' });
        }}
        onSkip={async () => {
          if (steps[activeIndex].nextStepURL) {
            await actions.updateUserProfile({
              gettingStartedStatus: steps[activeIndex + 1].id,
            });
            history.push(steps[activeIndex].nextStepURL);
          } else {
            await actions.updateUserProfile({
              gettingStartedStatus: 'finished',
            });
            openProgressBarNextSteps();
          }
        }}
      />
    );
  }

  function renderProgressBar() {
    const containerClassName = classnames('progress-bar', className, {
      active: state.showPopup || state.showSummaryPopup,
    });
    return (
      <div
        class={containerClassName}
        onClick={() => {
          if (state.showSummaryPopup) {
            dispatch({ type: 'HIDE_SUMMARY_POPUP' });
          } else {
            dispatch({ type: state.showPopup ? 'HIDE_POPUP' : 'SHOW_POPUP' });
          }
        }}
      >
        {renderTitle()}
        {renderSteps()}
      </div>
    );
  }

  if (!type) {
    return null;
  }

  return (
    <Fragment>
      {renderTransparentLayer()}
      <div className="position-relative mr-4">
        {renderProgressBar()}
        {renderPopup()}
        {renderSummaryPopup()}
      </div>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  const profile = state.user.user.profile;

  let type, activeIndex;

  let gettingStartedStatus = profile.gettingStartedStatus;

  if (gettingStartedStatus !== 'finished') {
    type = 'GETTING_STARTED';

    if (gettingStartedStatus === 'notStarted') {
      activeIndex = 0;
    } else {
      activeIndex = GettingStartedProgressBar.steps.findIndex(
        step => step.id === gettingStartedStatus,
      );

      if (activeIndex === -1) {
        activeIndex = 0;
      }
    }
  }

  return {
    type,
    activeIndex,
    steps: GettingStartedProgressBar.steps.filter(i => i.id !== 'NEXT_STEPS'),
    title: GettingStartedProgressBar.title,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openProgressBarNextSteps: () => {
      const props = {
        step:
          GettingStartedProgressBar.steps[
          GettingStartedProgressBar.steps.length - 1
          ],
      };
      dispatch(Actions.openModal('progress-bar-next-steps', props));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProgressBar),
);

function ProgressBarPopup({ activeIndex, title, steps, onClose }) {
  function renderTitle() {
    return (
      <div className="title">
        {activeIndex + 1}/{steps.length} {title}
      </div>
    );
  }

  function renderClose() {
    return (
      <ActionLink
        iconType="averPointIcon"
        iconName="CLOSE"
        iconClassName="mr-0 gray-3"
        linkClassName="position-absolute close"
        onClick={onClose}
      />
    );
  }

  function renderDescription() {
    return (
      <div className="gray-4 description">{steps[activeIndex].description}</div>
    );
  }

  function renderCheck(index) {
    if (activeIndex > index) {
      return (
        <div className="check d-inline-flex align-items-center justify-content-center">
          <Icon icon={ICONS.CHECK} className="av-white" size={12} />
        </div>
      );
    } else {
      return <div className="check" />;
    }
  }

  function renderStepText(step, index) {
    const className = classnames('flex-fill font-weight-600 text');

    return <div className={className}>{step}</div>;
  }

  function renderStep(step, index) {
    const className = classnames('d-flex align-items-center step', {
      checked: index < activeIndex,
    });
    return (
      <div className={className}>
        {renderCheck(index)}
        {renderStepText(step.title, index)}
        <Icon icon={ICONS.QUESTION} className="av-medium-blue" />
      </div>
    );
  }

  function renderSteps() {
    return steps.map(renderStep);
  }

  function renderTriangle() {
    return <div className="triangle position-absolute" />;
  }

  return (
    <div className="progress-bar-popup position-absolute">
      {renderTitle()}
      {renderClose()}
      {renderDescription()}
      {renderSteps()}
      {renderTriangle()}
    </div>
  );
}

function ProgressBarSummaryPopup({
  steps,
  activeIndex,
  onClose,
  onProgressDetail,
  onSkip,
}) {
  function renderTitle() {
    return <div className="title">{steps[activeIndex].title}</div>;
  }

  function renderClose() {
    return (
      <ActionLink
        iconType="averPointIcon"
        iconName="CLOSE"
        iconClassName="mr-0 gray-3"
        linkClassName="position-absolute close"
        onClick={onClose}
      />
    );
  }

  function renderDescription() {
    return (
      <div className="gray-4 description">{steps[activeIndex].description}</div>
    );
  }

  function renderLetsDoIt() {
    return (
      <RectangularButton
        className="blue w-100 mt-4"
        onClick={() => {
          onClose();
        }}
      >
        Let’s do it!
      </RectangularButton>
    );
  }

  function renderProgressDetails() {
    return <ActionLink label="Progress Details" onClick={onProgressDetail} />;
  }
  function renderSkipStep() {
    if (steps[activeIndex].hideSkip) {
      return null;
    }

    return (
      <ActionLink
        linkClassName="ml-auto d-inline-flex align-items-center"
        label="Skip Step"
        onClick={onSkip}
      >
        <Icon icon={ICONS.ARROW_FORWARD} className="ml-2 mt-2" />
      </ActionLink>
    );
  }

  function renderTriangle() {
    return <div className="triangle position-absolute" />;
  }

  return (
    <div className="progress-bar-summary-popup position-absolute">
      {renderTitle()}
      {renderClose()}
      {renderDescription()}
      {renderLetsDoIt()}
      <div className="actions d-flex align-items-center">
        {renderProgressDetails()}
        {renderSkipStep()}
      </div>
      {renderTriangle()}
    </div>
  );
}

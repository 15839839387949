import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import './BottomSheet.scss';

function BottomSheet({ bottomSheet, hideBottomSheet }) {
  if (!bottomSheet.open) {
    return null;
  }

  const Child = bottomSheet.child;

  return (
    <div className="bottom-sheet">
      <Child />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    bottomSheet: state.bottomSheet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    hideBottomSheet: (...args) => {
      dispatch(Actions.hideBottomSheet(...args));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BottomSheet);

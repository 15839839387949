import * as React from 'react';
import { Modal } from 'react-bootstrap';
import InvitePointComponent from './InvitePointComponent';

export default class InvitePointContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: [],
      errorMessage: '',
      message: '',
      inviteOption: 'Invite',
    };

    if (props.point) {
      if (props.point.isCollection) {
        this.inviteOptions = [
          {
            label: 'Invite to Points and sub-Points in this Collection',
            value: 'InviteChildren',
          },
          {
            label: 'Invite to just the Collection',
            value: 'Invite',
          },
        ];
      } else {
        this.inviteOptions = [
          {
            label: 'Invite to sub-Points in this Point',
            value: 'InviteChildren',
          },
          {
            label: 'Invite to just the Point',
            value: 'Invite',
          },
        ];
      }
    }
  }

  render() {
    return (
      <InvitePointComponent
        selectedUsers={this.state.selectedUsers}
        invitedUsers={
          this.props.type !== 'GROUP' ? this.props.point.authors : []
        }
        addUser={this.addUser}
        removeUser={this.removeUser}
        message={this.state.message}
        setMessage={this.setMessage}
        onInvite={this.onInvite}
        userList={this.props.userList}
        inviteOption={this.state.inviteOption}
        inviteOptions={this.inviteOptions}
        setInviteOption={inviteOption => {
          this.setState({ inviteOption });
        }}
        isSending={this.props.isSending}
        type={this.props.type}
        searchTerm={this.props.searchTerm}
        setSearchTerm={this.props.setSearchTerm}
      />
    );
  }

  onClose() {
    this.setState({
      errorMessage: '',
      message: '',
    });
  }

  addUser = user => {
    this.setState({
      selectedUsers: [...this.state.selectedUsers, user],
    });
  };

  removeUser = user => {
    let index;

    if (user._id) {
      index = this.state.selectedUsers.findIndex(i => i._id === user._id);
    } else {
      index = this.state.selectedUsers.findIndex(i => i === user);
    }

    this.setState({
      selectedUsers: [
        ...this.state.selectedUsers.slice(0, index),
        ...this.state.selectedUsers.slice(index + 1),
      ],
    });
  };

  setMessage = e => {
    this.setState({
      message: e.target.value,
    });
  };

  onInvite = () => {
    console.log('send invite got called');

    var permissionsLevel = 'write'; //TODO - hardcoded this for now
    this.props.onInvite(
      this.state.selectedUsers,
      permissionsLevel,
      this.state.inviteOption,
    );
  };
}

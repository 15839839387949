import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import { Tabs, Tab } from 'react-bootstrap';

import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import { renderToString } from 'components/elements/organisms/PointEmbed/PointEmbed';
import { Icon, ICONS } from '../../Icons';
import PortfolioCard from '../../cards/PortfolioCard/PortfolioCard.js';
import CollectionCard from '../../cards/CollectionCard';
import PointContainer from '../../cards/Point/PointContainer.js';
import { isMobile } from '../../../helpers';

require('./Share.scss');

class ShareComponent extends Component {
  static propTypes = {
    user: PropTypes.object,
    model: PropTypes.object,
    type: PropTypes.string,
    actions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: {
        emailRecipient: '',
      },
      success: {
        emailRecipient: '',
      },
      iframeOptions: {
        width: {
          value: '600',
          unit: 'px',
        },
        height: {
          value: '500',
          unit: 'px',
        },
        frameborder: '0',
      },
      isEmbedCard: false,
      showDirectLink: false,
      linkCopied: false,
      showEmailSection: false,
      emailRecipient: '',
      subPointEmailOption: 'firstLevel',
      showEmbedSection:
        props.showEmbedSection !== undefined ? props.showEmailSection : true,
      shareAV: false,
      showSubPointsInEmail:
        props.showSubPointsInEmail !== undefined
          ? props.showSubPointsInEmail
          : true,
    };
  }

  renderAVShare() {
    const { type, showAV, user } = this.props;

    if (type !== 'POINT') {
      return null;
    }

    return (
      <button
        className="btn btn-av d-flex align-items-center w-100 p-3 mr-3"
        onClick={() => {
          showAV(true);
        }}
        disabled={!user._id}
      >
        <Icon icon={ICONS.LOGO_NO_TEXT} size={28} className="mr-3" /> My
        AverPoint Collections
      </button>
    );
  }

  render() {
    let username;
    if (this.props.type == 'COLLECTION') {
      username = `${this.props.model.authorName}’s`;
    } else if (this.props.type == 'POINT') {
      username = `${this.props.model.authorName}’s`;
    } else {
      username = `${this.props.model.username}’s`;
    }

    return (
      <div className="share-component">
        {/* {this.renderCard()} */}
        <div className="p-4">
          {this.renderAVShare()}
          {this.renderDirectLink()}
          {this.renderFacebook()}
          {this.renderTwitter()}
          {this.renderEmail()}
          {this.renderEmbedSection()}
        </div>
        {this.renderEmailSection()}
        {/* {this.renderEmbedSection()} */}
        {this.state.linkCopied ? (
          <div className="av-green d-flex align-items-center justify-content-center p-4">
            <Icon icon={ICONS.CHECK} className="mr-2" />
            Code copied to clipboard
          </div>
        ) : null}
      </div>
    );
  }

  embedCard = (isEmbedCard, embedType) => {
    if (embedType === 'html') {
      renderToString(
        { point: this.props.model, user: this.props.user },
        (html) => {
          this.setState({
            isEmbedCard,
            embedType,
            html,
          });
        },
      );
    } else {
      this.setState({
        isEmbedCard,
        embedType,
      });
    }
  };

  shareUrl = (socialNetwork) => {
    let url;
    if (socialNetwork == 'facebook') {
      url = `https://www.facebook.com/sharer.php?u=${this.props.model.shareUrl}`;
    }
    if (socialNetwork == 'twitter') {
      url = `https://twitter.com/share?url=${this.props.model.shareUrl}`;
    }
    if (socialNetwork == 'linkedIn') {
      url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.props.model.shareUrl}`;
    }
    const win = window.open(
      url,
      '_blank',
      'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
    );
    axios.post('/api/events/shared', {
      type: this.props.type,
      model: this.props.model,
      shareType: 'social',
      socialNetwork,
    });
  };

  onEmail = () => {
    switch (this.props.type) {
      case 'POINT':
        const point = this.props.model;
        this.props.actions
          .emailPointSummary(
            point._id,
            this.state.emailRecipient,
            this.state.subPointEmailOption,
          )
          .then((successMessage) => {
            const { success } = this.state;
            success.emailRecipient = `Your email was sent to ${this.state.emailRecipient}`;

            const { error } = this.state;
            error.emailRecipient = '';

            this.setState({
              success,
              error,
            });
          })
          .catch(() => {
            const { success } = this.state;
            success.emailRecipient = '';

            const { error } = this.state;
            error.emailRecipient = `Error sending your email to  ${this.state.emailRecipient}`;

            this.setState({
              success,
              error,
            });
          });
        break;
      case 'COLLECTION':
        const collectionPoint = this.props.model;
        this.props.actions
          .emailCollectionSummary(
            collectionPoint._id,
            this.state.emailRecipient,
            this.state.subPointEmailOption,
          )
          .then((successMessage) => {
            const { success } = this.state;
            success.emailRecipient = `Your email was sent to ${this.state.emailRecipient}`;

            const { error } = this.state;
            error.emailRecipient = '';

            this.setState({
              success,
              error,
            });
          })
          .catch(() => {
            const { success } = this.state;
            success.emailRecipient = '';

            const { error } = this.state;
            error.emailRecipient = `Error sending your email to  ${this.state.emailRecipient}`;

            this.setState({
              success,
              error,
            });
          });
        break;
      default:
        break;
    }
  };

  // renderCard() {
  //   if (isMobile()) {
  //     return null;
  //   }

  //   let card;

  //   switch (this.props.type) {
  //     case 'POINT':
  //       card = (
  //         <PointContainer
  //           cardType="preview"
  //           point={this.props.model}
  //           citationStyle="short"
  //           disableClickThru
  //         />
  //       );
  //       break;
  //     case 'COLLECTION':
  //       card = (
  //         <CollectionCard
  //           cardType="default"
  //           preview={this.props.preview}
  //           point={this.props.model}
  //         />
  //       );
  //       // }
  //       break;
  //     case 'PORTFOLIO':
  //       card = <PortfolioCard Portfolio={this.props.model} />;
  //       break;
  //     default:
  //       return null;
  //   }

  //   if (card) {
  //     return <div className="preview p-4 bg-gray-2">{card}</div>;
  //   }
  // }

  handleChange = (event) => {
    const key = event.target.name;
    // this.setState({
    //   [key]: event.target.value
    // })
    this.setState({
      subPointEmailOption: event.target.value,
    });
  };

  renderDirectLink = () => {
    const { type } = this.props;
    let url;

    if (this.props.model.directLink) {
      url = this.props.model.directLink;
    } else {
      let typePath = '';

      if (type == 'COLLECTION') {
        typePath = 'collection';
      } else if (type == 'POINT') {
        typePath = 'point';
      } else if (type == 'PORTFOLIO') {
        typePath = 'portfolio';
      }

      if (type === 'COLLECTION' || type === 'POINT') {
        url = `${window.location.origin}/${typePath}/${this.props.model._id}`;
      } else if (type === 'PORTFOLIO') {
        url = `${window.location.origin}/${typePath}/${this.props.model.username}`;
      }
    }

    return (
      <CopyToClipboard text={url}>
        <button
          type="button"
          onClick={() => {
            this.setState({ linkCopied: true });
          }}
          className="btn btn-copy-link d-flex align-items-center w-100 p-3"
        >
          <Icon icon={ICONS.LINK_2} size={24} className="mr-3" /> Copy Link
        </button>
      </CopyToClipboard>
    );
  };

  renderFacebook() {
    return (
      <button
        className="btn btn-facebook d-flex align-items-center w-100 p-3 mr-3"
        onClick={() => this.shareUrl('facebook')}
      >
        <Icon icon={ICONS.FACEBOOK} size={24} className="mr-3" /> Facebook
      </button>
    );
  }

  renderTwitter() {
    return (
      <button
        className="btn btn-twitter d-flex align-items-center w-100 p-3 mr-3"
        onClick={() => this.shareUrl('twitter')}
      >
        <Icon icon={ICONS.TWITTER} size={24} className="mr-3" /> Twitter
      </button>
    );
  }

  renderEmail() {
    return (
      <button
        className="btn btn-email d-flex align-items-center w-100 p-3 mr-3"
        onClick={() =>
          this.setState({
            showEmailSection: !this.state.showEmailSection,
          })
        }
      >
        <Icon icon={ICONS.EMAIL} size={24} className="mr-3" /> Email
      </button>
    );
  }

  renderSubPointsInEmail = () => {
    if (!this.state.showSubPointsInEmail) {
      return null;
    }

    return (
      <div className="">
        <RadioGroup
          name="subPointEmailOption"
          label="What subpoints should appear in the email?"
          handleChange={this.handleChange}
          errorMessage={this.state.error.subPointEmailOption}
        >
          <ToolTip
            toolTipText="If a SubPoint has a SubPoint, only include the first one"
            toolTipPosition="bottom"
          >
            <RadioOption
              name="subPointEmailOption"
              id="firstLevel"
              value="firstLevel"
              label="Just the first level"
              isChecked
            />
          </ToolTip>
          &nbsp;
          <ToolTip
            toolTipText="If a SubPoint has a SubPoint, include everything"
            toolTipPosition="bottom"
          >
            <RadioOption
              name="subPointEmailOption"
              id="all"
              value="all"
              label="Everything"
            />
          </ToolTip>
        </RadioGroup>
      </div>
    );
  };

  renderEmailSection = () => {
    if (!this.state.showEmailSection) return null;

    return (
      <div className="pl-3 pr-3">
        <div className="position-absolute" style={{ right: '20px' }}>
          <Icon
            onClick={() => {
              this.setState({
                showEmailSection: false,
              });
            }}
            icon={ICONS.CLOSE}
          />
        </div>
        <div className="d-flex align-items-center mt-4">
          <div className="flex-fill">
            <FloatingInput
              name="emailRecipient"
              value={this.state.emailRecipient}
              labelName="Enter a recipient"
              onChange={(e) => this.setEmailRecipient(e.target.value)}
              className="flex-fill"
            />
          </div>
          <RectangularButton
            disabled={this.state.error.emailRecipient != ''}
            onClick={this.onEmail}
            className="ml-3"
          >
            Send
          </RectangularButton>
        </div>
        {this.renderSubPointsInEmail()}
        <div className="error-message mt-2">
          {this.state.error.emailRecipient}
        </div>
        <div className="success-message">
          {this.state.success.emailRecipient}
        </div>
      </div>
    );
  };

  setEmailRecipient = (email) => {
    // TODO - validate the email

    const { error } = this.state;
    error.emailRecipient = '';

    const { success } = this.state;
    success.emailRecipient = '';

    this.setState({
      emailRecipient: email,
      error,
      success,
    });
  };

  setIframeOption = (property, type, value) => {
    const { iframeOptions } = this.state;
    iframeOptions[property][type] = value;
    this.setState({
      iframeOptions,
    });
  };

  getFrameOptions = () => {
    const { iframeOptions } = this.state;
    const options = [];
    Object.keys(iframeOptions).forEach((key) => {
      const val = iframeOptions[key];
      if (typeof val === 'object') {
        options.push(`${key}="${val.value}${val.unit}"`);
      } else {
        options.push(`${key}="${val}"`);
      }
    });
    return options.join(' ');
  };

  renderIframeOptions = () => {
    const { iframeOptions } = this.state;
    return (
      <div className="iframe-options">
        <div className="option">
          <FloatingInput
            value={iframeOptions.width.value}
            labelName="Width"
            onChange={(e) =>
              this.setIframeOption('width', 'value', e.target.value)
            }
          />
          <select
            onChange={(e) =>
              this.setIframeOption('width', 'unit', e.target.value)
            }
          >
            <option value="px">px</option>
            <option value="%">%</option>
          </select>
        </div>
        <div className="option">
          <FloatingInput
            value={iframeOptions.height.value}
            labelName="Height"
            onChange={(e) =>
              this.setIframeOption('height', 'value', e.target.value)
            }
          />
          <select
            onChange={(e) =>
              this.setIframeOption('height', 'unit', e.target.value)
            }
          >
            <option value="px">px</option>
            <option value="%">%</option>
          </select>
        </div>
      </div>
    );
  };

  renderEmbedSection = () => {
    const { showEmbedSection, isEmbedCard, embedType, html } = this.state;
    if (!showEmbedSection) return null;

    const option = this.getFrameOptions();
    const iframeUrl = `<iframe ${option} src='${this.props.model.iframeUrl}'></iframe>`;
    const htmlUrl = this.state.html;
    let mediumUrl;

    if (this.props.model.mediumUrl) {
      mediumUrl = this.props.model.mediumUrl;
    } else {
      let typePath = '';
      if (this.props.type == 'COLLECTION') {
        typePath = 'collection';
      } else if (this.props.type == 'POINT') {
        typePath = 'point';
      }
      mediumUrl = `${window.location.origin}/${typePath}?id=${this.props.model._id}`;
    }

    return (
      <CopyToClipboard text={iframeUrl}>
        <button
          className="btn btn-copy-link d-flex align-items-center w-100 p-3"
          onClick={() => {
            this.setState({ linkCopied: true });
          }}
        >
          <Icon icon={ICONS.LINK} size={24} className="mr-3" />
          iFrame
        </button>
      </CopyToClipboard>
    );
  };
}

export default ShareComponent;

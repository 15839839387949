import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/index";

import PointContainer from 'components/cards/Point/PointContainer.js';
import Source from "components/elements/organisms/Source/Source"
import { Icon, ICONS } from 'components/Icons';

import { DragableCard } from 'components/cards/DnDContainer.js';

import "./Collection.scss";
class CollectionEmbed extends React.Component {
  static propTypes = {
    collectionId: PropTypes.string,
    collectionPoint: PropTypes.object,
    actions: PropTypes.object,
    points: PropTypes.object,
  }

  constructor(props){
    super(props);
    this.state = {}

    this.elem = React.createRef();
  }

  componentDidMount() {
    this.props.actions.getCollection(this.props.collectionId);
    this.setEmbedHeight();
    window.addEventListener('resize', this.setEmbedHeight);
  }

  componentDidUpdate(prevProps){
    if(prevProps.points.length != this.props.points.length){
      //If Points were loaded into state - reset the embed length
      console.log("Component did update prev points: "+prevProps.points.length+" newPoints: "+this.props.points.length);
      this.setEmbedHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setEmbedHeight);
  }
    
  render() {
    const { collectionPoint } = this.props;

    console.log("In embed/Collection/render");

    if(!collectionPoint) {
      return(<div>Loading Collection...</div>);
    }

    // let width = window.screen.availWidth;
    // if(this.elem && this.elem.current){
    //   width = Math.min(this.elem.current.clientWidth, window.screen.availWidth || 0);
    // }


    return (
      <div className="collection-list-embed" id="collection-list-embed" ref={this.elem}>
        {this.renderHeader()}
        {this.renderList()}
        {this.renderFooter()}
      </div>
    );
  }

  renderHeader = () => {
    
    const { collectionPoint } = this.props;

    return(
      <div className="header-row" id="header-row">
        <div className="actions-row">

        </div>
        <div className="title-row">
          <div className="title">
            {collectionPoint.text}
          </div>
          <div className="total-points">
            {collectionPoint.totalSubPoints} Points
          </div>
        </div>
      </div>
    )
  }

  renderList = () => {
    const { collectionPoint } = this.props;

    let listHeight = 300;
    console.log("List height starting at 300");

    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    const header = document.getElementById('header-row');

    const footer = document.getElementById('footer');

    if(header && footer){
      listHeight = h - header.clientHeight - footer.clientHeight-20;
      console.log("List height adjusted to "+listHeight);
    }

    const listHeightText = listHeight + "px";

    console.log("Rendering list with listHeight as "+listHeightText);

    // Subtract app inner margin;
    //const w = Math.min(document.documentElement.clientWidth - 30, window.screen.availWidth - 20 || 0);

    let listWidth = Math.min(document.documentElement.clientWidth - 30, window.screen.availWidth - 20 || 0);

    const embedElement = document.getElementById('collection-list-embed');
    if(embedElement){
      listWidth = embedElement.clientWidth;
    }

    const listWidthText = listWidth + "px";

    console.log("Rendering list width as "+listWidthText);
    console.log("Document clientWidth: "+document.documentElement.clientWidth);
    console.log("Window innerWidth: "+window.innerWidth);
    console.log("Window availWidth: "+window.screen.availWidth);

    return(
      <div className="list" style={{height: listHeightText, width: listWidthText}}>
        {
          collectionPoint.subPoints.map( (subPoint)=>{
            return this.renderPoint(subPoint.pointId)
          })
        }
      </div>
    )
  }

  renderPoint = (pointId)=>{
    const pointObject = this.props.points[pointId];
    if(!pointObject || !pointObject.point) return(
      <div className="loading">
        Loading point {pointId}
      </div>
    )

    const point = pointObject.point;

    // return(
    //   <div className="point">
    //     <div className="text">
    //       {point.text}
    //     </div>
    //     <div className="citation">
    //       <Source 
    //         point={point}
    //         // onPointFieldChanged={this.props.onPointFieldChanged}
    //         editable={false}
    //         // openEditFields={openEditFields}
    //         citationStyle={"short"}
    //         // sourceFieldsFromURL={this.props.sourceFieldsFromURL}
    //         // lookupSourceFieldsFromURL={this.props.lookupSourceFieldsFromURL}
    //         // onUrlChange={this.props.onUrlChange}
    //         hideAddSourceLink
    //         isExtension={false}
    //         errorMessages={{}}
    //         // isFetching={this.props.isFetching}
    //         // updateMasonry={this.props.updateMasonry}
    //         viewType="pointDetail"
    //       />
    //     </div>
    //   </div>

    // )

    const { allowRemove, onRemove, canDrag } = this.props;

    if(point.isCollection) return null;

    return (
      <DragableCard
        point={point}
        view={'card'}
        citationStyle="short"
        //parentObject={parentObject}
        allowRemove={false}
        onRemove={null}
        allowDownload={false}
        showTotalSubPoints={true}
        canDrag={false}
        moveCardOnHover={false}
        isEmbed={true}
        toggleExpandedPoint={this.toggleExpandedPoint}
        isExpanded={this.state.isExpanded}
        cardType="embed"
        isInEmbeddedCollection
      />
    )
  }

  renderFooter = ()=>{

    const link = window.location.origin + "/collection/"+this.props.collectionPoint._id;

    console.log("In embed/Colleciton/renderFooter");

    return(
      <div className="footer" id="footer">
        <div className="text">
        View the full <a target="_blank" href={link}>collection</a> at AverPoint
        </div>
        <Icon icon={ICONS.LOGO_NO_TEXT}/>
      </div>
    )
  }

  setEmbedHeight = () => {
    const height = Math.min(this.elem.current.clientHeight, 1000);
    //console.log("The height of the card is "+height);
    const adjustedHeight = height + 50;
    console.log("set embed called with adjust height as "+adjustedHeight);

    const width = Math.min(this.elem.current.clientWidth, window.screen.availWidth || 0);

    console.log("The client width is "+this.elem.current.clientWidth);
    console.log("The available width is "+window.screen.availWidth);

    window.parent.postMessage(
      JSON.stringify({
        src: window.location.toString(),
        context: 'iframe.resize',
        height: adjustedHeight,
        width
      }),
      '*',
    );
  };
}

function mapStateToProps(state, ownProps) {
  const collectionId = ownProps.match.params.id;
  const collectionObject = state.points[collectionId];
  const collectionPoint = (collectionObject) ? collectionObject.point : null;

  // let pointsLoaded = true;
  // for(let i=0; i<collectionPoint.subPoints.length; i++){
  //   const pointId = collectionPoint.subPoints[i];
  //   const pointObject = state.points[pointId];
  //   if(!pointObject) {
  //     pointsLoaded = false;
  //     break
  //   }
  // }


  return {
    user: state.user.user,
    authenticated: state.user.authenticated,
    collectionPoint,
    collectionId,
    points: state.points,
    //pointsLoaded
  };
}
  
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});
  
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectionEmbed)
);


import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import './Icon.scss';

const Icon = props => {
  const path =
    props.icon.format === 'data' ? (
      <path d={props.icon.path} />
    ) : (
      props.icon.markup
    );
  let style = {};

  if (props.width || props.height) {
    if (props.width) {
      style.width = props.width;
    }

    if (props.height) {
      style.height = props.height;
    }
  } else if (props.size) {
    style.height = props.size;
    style.width = props.size;
  }

  style = {
    ...style,
    ...props.style,
  };

  if (props.toolTipText) {
    const toolTipPosition = props.toolTipPosition
      ? props.toolTipPosition
      : 'bottom';

    let toolTipStyle = {};
    toolTipStyle = {
      ...toolTipStyle,
      ...props.toolTipStyle,
    };

    if (props.width || props.height) {
      if (props.width) {
        toolTipStyle.width = props.width;
      }

      if (props.height) {
        toolTipStyle.height = props.height;
      }
    } else if (props.size) {
      toolTipStyle.height = props.size;
      toolTipStyle.width = props.size;
    }

    return (
      <ToolTip
        className={`icon-with-tool-tip ${props.toolTipClassName}`}
        toolTipText={props.toolTipText}
        toolTipPosition={toolTipPosition}
        style={toolTipStyle}
      >
        <svg
          className={`icon ${props.icon.class} ${props.className}`}
          viewBox={props.icon.viewBox}
          onClick={props.onClick}
          style={style}
          tabIndex={props.tabIndex}
        >
          {path}
        </svg>
      </ToolTip>
    );
  }

  return (
    <svg
      className={`icon ${props.icon.class} ${props.className}`}
      viewBox={props.icon.viewBox}
      onClick={props.onClick}
      style={style}
      tabIndex={props.tabIndex}
    >
      {path}
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.object,
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  toolTipClassName: PropTypes.string,
  toolTipStyle: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  tabIndex: PropTypes.number,
  toolTipText: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  toolTipClassName: '',
};

export default Icon;

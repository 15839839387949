import keyBy from 'lodash/keyBy';
import {
  INVITE_LEADER,
  INVITE_MEMBER,
  GET_FEATURED_GROUPS,
  GET_MY_GROUPS,
  GET_GROUP,
  JOIN_PUB_GROUP,
  LEAVE_GROUP,
  RESPOND_INVITE_GROUP,
  GET_GROUP_COLLECTIONS,
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENT,
  REMOVE_COLLECTION_FROM_GROUP,
  SAVE_GROUP,
  DELETE_GROUP,
} from '../actions/group';
import { resolved, rejected } from '../createAction';

const initialState = {
  featured: [],
  mine: [],
  entities: {},
  groupCollections: {
    loading: false,
  },
  groupAnnouncements: {
    loading: false,
  },
  loading: false,
};

export default function groups(state = initialState, action) {
  const { type, payload, meta } = action;
  switch (type) {
    case GET_GROUP:
    case GET_MY_GROUPS:
    case GET_FEATURED_GROUPS: {
      return {
        ...state,
        errorMessage: '',
        loading: true,
      };
    }
    // case 'SYNC_SESSION_SUCCESS':
    case 'LOGIN_SUCCESS_USER':
    case 'INVITED_GROUPS': {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...keyBy(action.data.groups, '_id'),
        },
      };
    }
    case resolved(GET_FEATURED_GROUPS): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        featured: payload.groups.map(i => i._id),
        entities: {
          ...state.entities,
          ...keyBy(payload.groups, '_id'),
        },
      };
    }
    case rejected(DELETE_GROUP):
    case rejected(GET_GROUP):
    case rejected(GET_MY_GROUPS):
    case rejected(GET_FEATURED_GROUPS): {
      return {
        ...state,
        loading: false,
        errorMessage: payload.message,
      };
    }
    case resolved(GET_MY_GROUPS): {
      console.log('GET_MY_GROUPS and payload is');
      console.log(payload);
      return {
        ...state,
        loading: false,
        errorMessage: '',
        mine: payload.groups.map(i => i._id),
        entities: {
          ...state.entities,
          ...keyBy(payload.groups, '_id'),
        },
      };
    }
    case resolved(INVITE_LEADER):
    case resolved(INVITE_MEMBER):
    case resolved(RESPOND_INVITE_GROUP):
    case resolved(JOIN_PUB_GROUP):
    case resolved(LEAVE_GROUP):
    case resolved(GET_GROUP): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: {
          ...state.entities,
          [meta.groupId]: payload,
        },
      };
    }

    case resolved(SAVE_GROUP): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: {
          ...state.entities,
          [payload._id]: payload,
        },
      };
    }

    case resolved(DELETE_GROUP): {
      let entities = { ...state.entities };
      delete entities[action.meta.id];
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: {
          ...entities,
        },
      };
    }

    case GET_GROUP_COLLECTIONS: {
      return {
        ...state,
        groupCollections: {
          ...state.groupCollections,
          loading: true,
        },
      };
    }
    case resolved(GET_GROUP_COLLECTIONS): {
      return {
        ...state,
        errorMessage: '',
        groupCollections: {
          ...state.groupCollections,
          loading: false,
          [meta.groupId]: payload,
        },
      };
    }
    case GET_ANNOUNCEMENTS: {
      return {
        ...state,
        errorMessage: '',
        groupAnnouncements: {
          ...state.groupAnnouncements,
          loading: true,
        },
      };
    }
    case resolved(GET_ANNOUNCEMENTS): {
      return {
        ...state,
        errorMessage: '',
        groupAnnouncements: {
          ...state.groupAnnouncements,
          loading: false,
          [meta.groupId]: payload,
        },
      };
    }
    case resolved(CREATE_ANNOUNCEMENT): {
      const announcements = state.groupAnnouncements[meta.groupId] || [];
      return {
        ...state,
        errorMessage: '',
        groupAnnouncements: {
          ...state.groupAnnouncements,
          [meta.groupId]: [payload, ...announcements],
        },
      };
    }

    case resolved(REMOVE_COLLECTION_FROM_GROUP): {
      let groupCollections = state.groupCollections[meta.groupId];
      if (groupCollections) {
        groupCollections = groupCollections.filter(
          c => c._id !== meta.collectionId,
        );
        return {
          ...state,
          errorMessage: '',
          groupCollections: {
            ...state.groupCollections,
            [meta.groupId]: groupCollections,
          },
        };
      }
      return state;
    }

    case 'FOLLOW_GROUP': {
      const group = state.entities[action.payload.groupId];
      //group.following = true;
      return {
        ...state,
        errorMessage: '',
        entities: {
          ...state.entities,
          [action.payload.groupId]: {
            ...group,
            _id: action.payload.groupId,
            following: true,
          },
        },
      };
    }

    case 'UNFOLLOW_GROUP': {
      const group = state.entities[action.payload.groupId];
      //group.following = false;
      return {
        ...state,
        errorMessage: '',
        entities: {
          ...state.entities,
          [action.payload.groupId]: {
            ...group,
            _id: action.payload.groupId,
            following: false,
          },
        },
      };
    }

    // case Actions.UPDATE_GROUP: {
    //   const newGroupObject = { ...state[action.group._id] };
    //   if (action.group) newGroupObject.group = action.group;
    //   if (action.status) newGroupObject.status = action.status;
    //   if (action.isLeader !== undefined)
    //     newGroupObject.isLeader = action.isLeader;
    //   if (action.isMember !== undefined)
    //     newGroupObject.isMember = action.isMember;
    //   if (action.isInvitedLeader !== undefined)
    //     newGroupObject.isInvitedLeader = action.isInvitedLeader;
    //   if (action.isInvitedMember !== undefined)
    //     newGroupObject.isInvitedMember = action.isInvitedMember;
    //
    //   return {
    //     ...state,
    //     [action.group._id]: newGroupObject,
    //   };
    // }
    // case Actions.UPDATE_GROUPS: {
    //   const newGroups = {};
    //   action.groups.forEach(group => {
    //     let newGroupObject = {};
    //
    //     const existingGroupObject = state[group._id];
    //     if (existingGroupObject) {
    //       newGroupObject = {
    //         ...existingGroupObject,
    //       };
    //     }
    //
    //     newGroupObject.group = group;
    //     newGroups[group._id] = newGroupObject;
    //   });
    //
    //   return {
    //     ...state,
    //     ...newGroups,
    //   };
    // }
    // case 'GROUP_ERROR_MESSAGE': {
    //   return {
    //     ...state,
    //     errorMessage: action.errorMessage,
    //   };
    // }
    // case Actions.LOGOUT_SUCCESS_USER: {
    //   return {};
    // }
    default:
      return state;
  }
}

import axios from 'axios';

export function getSTPsForPoint(pointId) {
  return async (dispatch, getState) => {
    let stps;

    if (getState().points[pointId] && getState().points[pointId].point.isMock) {
      stps = getState().points[pointId].stps || [];
    } else {
      const res = await axios.get('/api/stps/getSTPsForPoint/' + pointId);
      stps = res.data;
    }

    dispatch({
      type: 'UPDATE_POINT',
      point: { _id: pointId },
      stps,
    });
  };
}

export function getSavedPointsForUser(username) {
  return async (dispatch, getState) => {
    const res = await axios.get('/api/stps/getSavedPointsForUser/' + username);
    console.log(res.data);
    const { points, stps } = res.data;

    for (let i = 0; i < stps.length; i++) {
      const stp = stps[i];
      // console.log('Checking this stp');
      // console.log(stp);
      for (let j = 0; j < points.length; j++) {
        // console.log("Checking if it's this point");
        // console.log(points[j]._id);
        if (points[j]._id === stp.pointId) {
          // console.log('Found the pointId. Assigning RTP');
          points[j].stps = [{ ...stp, type: 'STP' }];
          // console.log('Not point.stps is ');
          // console.log(points[j].stps);
          break;
        }
      }
    }

    dispatch({
      type: 'UPDATE_POINTS',
      updatedPoints: points,
    });

    return res.data;
  };
}

export function getEvidencePointsForUser(username) {
  return async (dispatch, getState) => {
    const res = await axios.get('/api/stps/getEvidencePointsForUser/' + username);
    console.log(res.data);
    const { points, etps } = res.data;

    for (let i = 0; i < etps.length; i++) {
      const etp = etps[i];
      // console.log('Checking this etp');
      // console.log(etp);
      for (let j = 0; j < points.length; j++) {
        // console.log("Checking if it's this point");
        // console.log(points[j]._id);
        if (points[j]._id === etp.pointId) {
          // console.log('Found the pointId. Assigning RTP');
          points[j].etps = [{ ...etp, type: 'ETP' }];
          // console.log('Not point.etps is ');
          // console.log(points[j].etps);
          break;
        }
      }
    }

    dispatch({
      type: 'UPDATE_POINTS',
      updatedPoints: points,
    });

    return res.data;
  };
}

import React from 'react';
import PropTypes from 'prop-types';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import './css/SearchableDropDown.scss';

class SearchableDropDown extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    objKey: PropTypes.string,
    hideFullList: PropTypes.bool,
    renderItem: PropTypes.func,
    hideSearchInput: PropTypes.bool,
    labelName: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      searchText: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length != this.props.data.length) {
      this.setState(
        {
          data: this.props.data,
        },
        function () {
          if (this.state.searchText) {
            const input = document.getElementById('item-name');
            this.handleChangeName(input);
          }
        },
      );
    }
  }

  handleChangeName = (input) => {
    var objKey = this.props.objKey;
    var value = (input.target && input.target.value) || input.value;
    var filteredData = this.props.data.filter(function (item) {
      if (value) {
        if (
          item &&
          item[objKey] &&
          item[objKey].toLowerCase().indexOf(value.toLowerCase()) >= 0
        ) {
          return item;
        }
      } else {
        return item;
      }
    });
    this.setState({
      data: filteredData,
      searchText: value,
    });
  }

  render() {
    return (
      <div className="searchableDropDown">
        {this.renderSearchInput()}
        {!this.props.hideFullList || this.state.searchText ? (
          <div className="itemList" id="itemList">
            {this.state.data.map((item, index) => {
              return this.props.renderItem(item, this.props, index);
            })}
          </div>
        ) : null}
      </div>
    );
  }

  renderSearchInput = () => {
    if (this.props.hideSearchInput) return;

    const labelName = this.props.labelName ? this.props.labelName : 'Search';

    return (
      <FloatingInput
        name="name"
        id="item-name"
        onChange={this.handleChangeName}
        labelName={labelName}
        autoComplete="off"
      />
    );
  };
}

export default SearchableDropDown;

import React from 'react';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';

import './BadgeDetails.scss';

export default function BadgeDetails({ onClose, badge, notEarned = false }) {
  return (
    <Modal show={true} className="badge-details-modal">
      <Modal.Body>
        <div className="header">
          <span>{badge.badge}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {!notEarned && (
          <div className="text">
            <b>Earned On:</b> {moment(badge.badgeEarned).format('DD MMM YYYY')}
          </div>
        )}
        <div className="text">{badge.text}</div>
        <div className="image">
          <img src={badge.image} width={250} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

import * as React from "react";
import { Modal, Button } from "react-bootstrap";

import SuccessComponent from "./components/SuccessComponent";

require("./css/SuccessModal.scss");

class SuccessModal extends React.Component {
  constructor(props) {
    super(props);
  }

  onClose = (e) => {
    this.props.onClose(false);
    if (this.props.model.onClose) {
      this.props.model.onClose();
    }
  }

  render() {
    let title = this.props.model.title || "Success";
    return (
      <Modal
        show={true}
        className={
          "success-modal " + (this.props.model.isExtension ? "active" : "")
        }
      >
        {/*
                <Modal.Header >
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    <Modal.Title className="title">{title}</Modal.Title>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </Modal.Header>
                */}
        <Modal.Body className="modalBody">
          <SuccessComponent
            {...this.props}
            message={this.props.model.message}
            point={this.props.model.point}
            isExtension={this.props.model.isExtension}
            doneLabel={
              this.props.model.doneLabel
                ? this.props.model.doneLabel
                : "Continue"
            }
            onClose={this.onClose}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuccessModal;

import React, { useRef, useState } from 'react';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import SyncAutoComplete from 'components/elements/molecules/SyncAutoComplete/SyncAutoComplete';
import { Icon, ICONS } from '../../../Icons';

import './MultiChipComponent.scss';

export default function MultiChipComponent({
  title,
  placeholder,
  type,
  onChange,
  className = '',
  containerClass = '',
  values = [],
  isMulti = false,
  isAutoComplete = false,
  options = [],
  icon = null,
  onBottom = false,
  isReadOnly = false,
}) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const addValue = (value) => {
    if (!value) {
      return;
    }

    if (isMulti) {
      onChange(type, [...values, value]);
    } else {
      onChange(type, value);
    }
    setValue('');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const isChecked = (value) => {
    if (isMulti && values.includes(value)) {
      return true;
    }

    return false;
  };

  const removeValue = (index) => {
    if (isMulti) {
      const newValues = [...values];
      newValues.splice(index, 1);
      onChange(type, newValues);
    } else {
      onChange(type, null);
    }
  };

  const showValues = () => {
    return values.map((value, index) => (
      <div key={index} className={`chip-container ${containerClass}`}>
        <div className={`chip ${className}`}>
          {icon && <Icon icon={icon} className="mr-3" />}
          <div className="chip-value">{value}</div>
          {!isReadOnly && (
            <Icon
              icon={ICONS.DELETE}
              className="icon"
              onClick={() => removeValue(index)}
            />
          )}
        </div>
      </div>
    ));
  };

  const showInput = () => {
    if (isReadOnly) {
      return null;
    }

    if (!isMulti && values.length !== 0) {
      return null;
    }

    return (
      <div className={`chip-container input ${containerClass}`}>
        <div className={`chip add ${className}`}>
          {!isAutoComplete ? (
            <React.Fragment>
              <FloatingInput
                value={value}
                placeholder={placeholder}
                error={''}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Tab') {
                    addValue(value);
                  }
                }}
                inputRef={inputRef}
              />
              <Icon
                icon={ICONS.ADD}
                className="icon"
                onClick={() => addValue(value)}
              />
            </React.Fragment>
          ) : (
            <SyncAutoComplete
              autoFocus={false}
              value=""
              onSelect={addValue}
              label="Start typing..."
              isChecked={isChecked}
              options={options}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="multi-chip-component w-100">
      {title && <div className="title">{title}</div>}
      {!onBottom && showValues()}
      {showInput()}
      {onBottom && showValues()}
    </div>
  );
}

import Example1 from './Example1.json';
import Example2 from './Example2.json';
import Example3 from './Example3.json';
import Example4 from './Example4.json';
import Example5 from './Example5.json';
import Example6 from './Example6.json';
import Example7 from './Example7.json';
import Example8 from './Example8.json';

export default [
  ...Example1,
  ...Example2,
  ...Example3,
  ...Example4,
  ...Example5,
  ...Example6,
  ...Example7,
  ...Example8,
];

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubscribeNewsletter from 'routes/LandingPage/components/SubscribeNewsletter';
import * as Actions from '../../../../redux/actions/index';

function SubscribeToNewsletter({ user, pathname, newsletter, actions }) {
  useEffect(() => {
    if (
      pathname.includes('/home/news') ||
      pathname.includes('/collection') ||
      pathname.includes('/blog') ||
      pathname.includes('/newsClub')
    ) {
      actions.showSubscribeNewsletter();
    } else {
      actions.hideSubscribeNewsletter();
    }
  }, [pathname]);

  const closePanel = () => {
    actions.closeNewsletter();
  };

  const subscribed = () => {
    actions.subscribedNewsletter();
  };

  if (typeof user === 'object' && Object.keys(user).length !== 0) {
    return null;
  }

  if (!newsletter.show) {
    return null;
  }

  return (
    <SubscribeNewsletter
      onSubscribe={(data) => {
        return actions.subscribeNewsletter(data);
      }}
      newsletter="averpointNewsletter"
      subscribed={subscribed}
      closePanel={closePanel}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user ? state.user.user : null,
    pathname: ownProps.location.pathname,
    newsletter: state.newsletter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscribeToNewsletter),
);

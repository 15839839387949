import axios from 'axios';

export const LOAD_BLOGS = 'LOAD_BLOGS';
export const ADD_BLOG = 'ADD_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';

export function loadBlogs(blogs) {
  return {
    type: LOAD_BLOGS,
    blogs,
  };
}

export function addBlog(blog) {
  return {
    type: ADD_BLOG,
    blog,
  };
}

export function updateBlogAction(blog) {
  return {
    type: UPDATE_BLOG,
    blog,
  };
}

export function deleteBlogAction(blog) {
  return {
    type: DELETE_BLOG,
    blog,
  };
}

export function getBlogs() {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'get',
      url: '/api/blogs/getBlogs',
    });
    const { blogs } = response.data;
    dispatch(loadBlogs(blogs));
    return blogs;
  };
}

export function createBlog(blog) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/blogs/createBlog',
      data: blog,
    });
    dispatch(addBlog(response.data.blog));
    return response.data.blog;
  };
}

export function updateBlog(blog) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/blogs/updateBlog',
      data: blog,
    });
    dispatch(updateBlogAction(response.data.blog));
    return response.data.blog;
  };
}

export function deleteBlog(blog) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'delete',
      url: '/api/blogs/deleteBlog',
      params: {
        id: blog._id,
        title: blog.title,
      },
    });
    dispatch(deleteBlogAction(blog));
    // return response.data.blog;
    return response.data;
  };
}

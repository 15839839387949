export const SHOW_POPOVER = 'SHOW_POPOVER';
export const HIDE_POPOVER = 'HIDE_POPOVER';

export function showPopover(
  target,
  placement,
  icon = null,
  title,
  description,
  imageUrl = null,
  videoUrl = null,
  actionLinks = [],
  isHTML,
) {
  return {
    type: SHOW_POPOVER,
    data: {
      target,
      placement,
      icon,
      title,
      description,
      imageUrl,
      videoUrl,
      actionLinks,
      isHTML,
    },
  };
}

export function hidePopover() {
  return {
    type: HIDE_POPOVER,
  };
}

import React from 'react';
import CreateCollectionComponent from './CreateCollectionComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions/index';
import { selectMyGroups } from 'redux/selectors/groups';

import './createCollection.scss';

class CreateCollectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      showCreateCollection: true,
    };
  }

  componentDidMount() {
    this.setState({ showCreateCollection: true });
    this.props.actions.getMyGroups();
  }

  onCreateCollection = async (collectionPoint, additionalAuthors) => {
    const { isMedia, actions, openCollectionSelectGroups } = this.props;

    actions.showHint(
      'info',
      isMedia ? 'Preparing media' : 'Preparing collection',
    );

    var that = this;
    try {
      let res = await this.props.onAction(
        this.props.onCreateCollection,
        collectionPoint,
        additionalAuthors,
        this.props.isTopLevelCollection,
      );

      // Make it sub-collection if parentColletionId passed
      if (this.props.parentCollectionId) {
        await this.props.actions.saveSubPointToPoint(
          res._id,
          this.props.parentCollectionId,
        );
      }

      that.props.afterNewCollectionCreated(res);
      that.props.onClose();

      if (isMedia) {
        openCollectionSelectGroups(res._id);
      }
    } catch (error) {
      console.log('error', error);
      this.setState({
        errorMessage: error, //How to display?
      });
    } finally {
      actions.hideHint();
    }
  };

  onClose = () => {
    this.setState({ showCreateCollection: false });
    this.props.onClose();
  };

  render() {
    return (
      <CreateCollectionComponent
        onCreateCollection={this.onCreateCollection}
        onClose={this.onClose}
        showCreateCollection={this.state.showCreateCollection}
        user={this.props.user}
        errorMessage={this.state.errorMessage || this.props.errorMessage}
        groups={this.props.groups}
        groupId={this.props.groupId}
        isMedia={this.props.isMedia}
        lookupURL={(url) => {
          return this.props.onAction(this.props.lookupURL, url);
        }}
        parentCollectionId={this.props.parentCollectionId}
      />
    );
  }
}

function mapStateToProps(state) {
  const authenticated = state.user.authenticated;
  const user = state.user.user;
  let portfolio;
  if (authenticated) {
    let portfolioObject = state.portfolios[user.username];
    if (portfolioObject) portfolio = portfolioObject.portfolio;
  }

  return {
    authenticated,
    user,
    portfolio,
    groups: selectMyGroups(state).groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      let props = {
        model: {
          title,
          message,
        },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
    openErrorModal: (type, message) => {
      var title = 'Error';
      let props = {
        model: {
          title,
          message,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    openCollectionSelectGroups: (collectionId) => {
      const props = { collectionId };
      dispatch(Actions.openModal('collection-select-groups', props));
    },
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCollectionModal);

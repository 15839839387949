import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

// import * as cloudinaryForPage from 'cloudinary';
// import { CloudinaryConfig } from 'components/elements/molecules/Images/CloudinaryConfig';

import axios from 'axios';
import * as Actions from 'redux/actions/index';
const config = require('config/config.public.js');

let cloudinaryWidget = null;

class CloudinaryWidget extends React.Component {
  static propTypes = {
    imageExists: PropTypes.bool,
    cloudinaryImageId: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.object,
    onLicenseTermsAccepted: PropTypes.func,
    /** redux */
    openAddPictureModal: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.cloudinary.isOpen) {
      const cloudinaryOptions = {
        cloudName: config.cloudinary.cloud_name,
        uploadPreset: 'yxidy7xn',
        apiKey: config.cloudinary.api_key,
        cropping: 'true',
        cropping_aspect_ratio: 1,
        resource_type: 'image',
        uploadSignature: this.generateSignature,
        publicId: this.props.cloudinary.imageId,
        invalidate: true,
        sources: ['local', 'url', 'image_search'],
      };

      if (!cloudinaryWidget) {
        cloudinaryWidget = window.cloudinary.createUploadWidget(
          cloudinaryOptions,
          this.afterImageAdded,
        );
        cloudinaryWidget.open();
      } else {
        if (this.props.cloudinary.imageId != prevProps.cloudinary.imageId) {
          cloudinaryWidget.update(cloudinaryOptions);
        }
        if (!cloudinaryWidget.isShowing()) {
          cloudinaryWidget.open();
        }
      }
    }

    if (this.props.cloudinary.isOpen != prevProps.cloudinary.isOpen) {
      this.setState();
    }
  }

  componentWillUnmount() {
    if (cloudinaryWidget) cloudinaryWidget.close();
  }

  render() {
    return <div className="cloudinary-widget"></div>;
  }

  afterImageAdded = (error, result) => {
    if (error) {
      console.log('Error uploading profile image');
      console.log(error);
      return;
    }

    console.log('The result event was ' + result.event);
    if (result.event == 'success') {
      console.log(
        `${this.props.user.username} changed the picture ${this.props.cloudinaryImageId}`,
      );

      const originalURL = 'TestURL'; // TODO - get URL from result

      cloudinaryWidget.close();
      setTimeout(() => {
        this.props.openAddPictureModal(
          this.props.cloudinary.imageId,
          this.props.cloudinary.onLicenseTermsAccepted,
          originalURL,
          result.info.secure_url,
          result.info.version,
        );
        this.props.dispatch({
          type: 'SET_CLOUDINARY',
          imageId: null,
          isOpen: false,
          onLicenseTermsAccepted: null,
        });
      }, 500);
    }
  };

  generateSignature = (callback, params_to_sign) => {
    axios({
      method: 'post',
      url: '/api/utility/getCloudinarySignature',
      params: {},
      data: {
        ...params_to_sign,
      },
    })
      .then((result) => {
        callback(result.data.signature);
        console.log('Got signature ');
        console.log(result.data);
      })
      .catch((result) => {
        console.log('Error getting signature');
        console.log(result);
      });
  };
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;

  return {
    authenticated,
    user,
    cloudinary: state.page.cloudinary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
    openAddPictureModal: (
      cloudinaryImageId,
      onLicenseTermsAccepted,
      originalURL,
      hostedImageURL,
      hostedImageVersion,
    ) => {
      const props = {
        cloudinaryImageId,
        onLicenseTermsAccepted,
        originalURL,
        hostedImageURL,
        hostedImageVersion,
        model: {
          isExtensionModal: false,
        },
      };
      dispatch(Actions.openModal('add-picture', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloudinaryWidget);

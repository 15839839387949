import React from 'react';
import PropTypes from 'prop-types';

const DisplayName = props => {
  let { name } = props;
  const { user } = props;
  // let organizationName = null;
  if (user) {
    // organizationName = user.profile.organizationName;
    const { firstName, lastName } = user.profile;
    if (firstName && lastName) {
      name = `${firstName} ${lastName}`;
    } else if (firstName) {
      name = firstName;
    } else if (lastName) {
      name = lastName;
    } else {
      name = user.username;
    }
  }

  const atIndex = name.indexOf('@');

  if (atIndex !== -1) name = name.substring(0, atIndex);

  if (props.maxLength) name = name.substring(0, props.maxLength);

  return (
    <span className="displayName">
      {name}
      {/* TBD: Add later */}
      {/* {organizationName && <i>{` ${organizationName}`}</i>} */}
    </span>
  );
};

DisplayName.propTypes = {
  user: PropTypes.object,
  name: PropTypes.string,
  maxLength: PropTypes.number,
};

export default DisplayName;

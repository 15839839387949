import axios from "axios";
import qs from "qs";

export function emailCollectionSummary(collectionId, recipient) {
  return (dispatch, getState) => {
    console.log("emailCollectionSummary got called for collection ");

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/emails/emailCollectionSummary",
        params: {
          collectionId,
          recipient
        },
        paramsSerializer: function(params) {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
        .then(response => {
          console.log("Email Collection summary responded successfully");
          //console.log(response);
          const data = response.data;
          //dispatch(emailStatus("success"));
          resolve("success");
        })
        .catch(response => {
          console.log("Email Collection summary responded error");
          reject();
        });
    });
  };
}

export function emailPointSummary(pointId, recipient, subPointOption) {
  return (dispatch, getState) => {
    console.log("emailPointSummary got called for Point ");

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/emails/emailPointSummary",
        params: {
          pointId,
          recipient,
          subPointOption
        },
        paramsSerializer: function(params) {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
        .then(response => {
          console.log("Email Point summary responded successfully");
          //console.log(response);
          const data = response.data;
          //dispatch(emailStatus("success"));
          resolve("success");
        })
        .catch(response => {
          console.log("Email Point summary responded error");
          reject();
        });
    });
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProfileImage from 'components/elements/molecules/Images/profileImage';
import FactReviewImage from 'components/elements/molecules/Images/FactReviewImage';

import './pointDropDownHeader.scss';

class PointDropDownHeader extends Component {
  static propTypes = {
    point: PropTypes.object,
    user: PropTypes.object,
    onPublish: PropTypes.func,
    onRevise: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let icon = null;
    const { point, user } = this.props;

    if (point.status == 'private') {
      icon = <span className="glyphicon glyphicon-lock-grey" />;
    } else {
      icon = <span className="glyphicon glyphicon-globe-grey" />;
    }

    const authorname =
      point.authorName.length > 20
        ? `${point.authorName.substring(0, 19)}...`
        : point.authorName;

    let typeDisplay;
    let statusDisplay;
    if (point.type === 'Fact') {
      typeDisplay = 'Fact | ';
      statusDisplay = point.status;
    }
    if (point.type === 'Opinion') {
      typeDisplay = 'Opinion | ';
      const numAgree = point.numAgree || 0;
      const numDisagree = point.numDisagree || 0;
      const numReviews = numAgree + numDisagree;

      let agreePercentage = 0;
      if (numReviews > 0)
        agreePercentage = Math.floor((numAgree / numReviews) * 100);

      statusDisplay = `${agreePercentage}% Agree`;
    }
    if (point.type === 'Decide later') {
      typeDisplay = 'Unclassified';
      statusDisplay = '';
    }

    const reviewImage = <FactReviewImage review={statusDisplay} size={10} />;

    return (
      <div className="point-card-header">
        <div className="top-section p-2">
          <div className="point-profilepic">
            {/* {point.authorHasPhoto && ( */}
            <ProfileImage
              userId={point.authorId}
              hostedImageVersion={point.authorHostedImageVersion}
              count={14}
              size={20}
              noImageSize={10}
              allowUserToChangePic={false}
              allowClickToPortfolio
              username={point.authorName}
            />
            {/* )} */}
            <div className="user-name">{authorname}</div>
          </div>
          <div className="av-white mt-1 body-small text-center">
            <span className="type">{typeDisplay}</span>
            <span className="mr-2">{statusDisplay}</span>
            {reviewImage}
          </div>
        </div>
      </div>
    );
  }
}

export default PointDropDownHeader;

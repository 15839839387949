import React from 'react';
import PropTypes from 'prop-types';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';

import './EvidenceFilter.scss';

const EvidenceFilter = props => {
  const {
    filterSubPoints,
    filter,
    numFor,
    numAgainst,
    numNeutral,
    numTotal,
  } = props;
  return (
    <div className="evidence-filter d-flex align-items-center">
      <ActionLink
        onClick={() => filterSubPoints('All')}
        linkClassName="mr-3"
        iconType={'none'}
        iconName={''}
        label={numTotal + ' Sub-Points'}
        toolTipText="All the Points that support or oppose this argument."
        toolTipPosition="bottom"
      />
      <RoundedButton
        className="for ml-3"
        label={numFor + ' For'}
        onClick={() => filterSubPoints('For')}
      />
      <RoundedButton
        className="against ml-3"
        label={numAgainst + ' Against'}
        onClick={() => filterSubPoints('Against')}
      />
      <RoundedButton
        className="neutral ml-3"
        label={numNeutral + ' Neutral'}
        onClick={() => filterSubPoints('Neutral')}
      />
    </div>
  );
};

EvidenceFilter.propTypes = {
  user: PropTypes.object,
  filter: PropTypes.string,
  filterSubPoints: PropTypes.func,
  handleEvidenceTab: PropTypes.object,
  numTotal: PropTypes.number,
  numFor: PropTypes.number,
  numAgainst: PropTypes.number,
};

export default EvidenceFilter;

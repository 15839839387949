import React, { Fragment, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { isValidEmail, isValidPhone } from 'helpers/validation';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';

import { toast } from 'components/elements/molecules/Toast/Toast';

import "../css/ClassRoom.scss";

function RequestClassroomDemo({ onClose, actions, onSubmit = null }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_REGISTRATION_FIELD':
          return {
            ...state,
            registration: {
              ...state.registration,
              [action.key]: action.value,
            },
          };
        case 'SET_SUBMITTED':
          return {
            ...state,
            submitted: action.payload,
          };
        case 'SET_REGISTERED':
          return {
            ...state,
            registered: action.payload,
          };
        case 'SET_LOADING':
          return {
            ...state,
            loading: action.payload,
          };
        default:
          return state;
      }
    },
    {
      registration: {
        name: '',
        email: '',
        phone: '',
        schoolName: '',
        cityName: '',
        stateName: '',
        role: 'Teacher',
        bestWayToCommunicate: 'Phone',
      },
      loading: false,
      submitted: false,
      registered: false,
    },
  );

  function isValidName() {
    const regex = /^[a-zA-Z0-9 ]+$/i;

    return (
      state.registration.name.trim().length &&
      regex.test(state.registration.name.toLowerCase())
    );
  }

  function isValidSchoolName() {
    const regex = /^[a-zA-Z0-9 ]+$/i;

    return (
      state.registration.schoolName.trim().length &&
      regex.test(state.registration.schoolName.toLowerCase())
    );
  }

  async function onContactUs() {
    dispatch({ type: 'SET_SUBMITTED', payload: true });

    if (
      !isValidSchoolName() ||
      !isValidName() ||
      !state.registration.cityName ||
      !state.registration.stateName ||
      !state.registration.role ||
      !state.registration.bestWayToCommunicate ||
      !isValidEmail(state.registration.email) ||
      !isValidPhone(state.registration.phone)
    ) {
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    await actions.classRoomRegistration(state.registration).then(() => {
      if (onClose) {
        onClose();
      }

      if (onSubmit) {
        onSubmit();
      }

      toast('Your request for a demo has been submitted successfully');
    }).catch((error) => {
      console.error('Error while requesting a demo', error);

      toast('Failed to request for a demo. Please try again later.', {
        type: 'error',
      });
    }).finish(() => {
      dispatch({ type: 'SET_LOADING', payload: true });
    });
  }

  function renderError(error) {
    return (
      <p className="av-red m-0">
        <small>
          <i>{error}</i>
        </small>
      </p>
    );
  }

  function renderSchoolName() {
    return (
      <Fragment>
        <FloatingInput
          value={state.registration.schoolName}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'schoolName',
              value: e.target.value,
            });
          }}
          labelName="School name *"
        />
        {isValidSchoolName() || !state.submitted
          ? null
          : renderError('* School name should be alphanumeric')}
      </Fragment>
    );
  }

  function renderRole() {
    return (
      <div className="mt-3 mb-4 d-flex align-items-center">
        <span className="av-gray">Role</span>
        <div className="dropdown ml-4">
          <select
            className="request-demo-role-selector"
            value={state.registration.role}
            onChange={(e) => {
              dispatch({
                type: 'SET_REGISTRATION_FIELD',
                key: 'role',
                value: e.target.value,
              });
            }}
          >
            <option value="Teacher">Teacher</option>
            <option value="SchoolAdmin">School Admin</option>
            <option value="Student">Student</option>
            <option value="Parent">Parent</option>
          </select>
        </div>
      </div>
    );
  }

  function renderName() {
    return (
      <div className="mt-3">
        <FloatingInput
          value={state.registration.name}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'name',
              value: e.target.value,
            });
          }}
          labelName="Name *"
        />
        {isValidName() || !state.submitted
          ? null
          : renderError('* name should be alphanumeric')}
      </div>
    );
  }

  function renderEmail() {
    return (
      <Fragment>
        <FloatingInput
          value={state.registration.email}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'email',
              value: e.target.value,
            });
          }}
          labelName="Email *"
        />
        {isValidEmail(state.registration.email) || !state.submitted
          ? null
          : renderError('* Enter valid email')}
      </Fragment>
    );
  }

  function renderPhone() {
    return (
      <div className="position-relative">
        <FloatingInput
          className="request-demo-phone"
          value={state.registration.phone}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'phone',
              value: e.target.value,
            });
          }}
          labelName="Phone *"
          hint="hint : (xxx)xxx-xxxx or xxx-xxx-xxxx"
        />
        {isValidPhone(state.registration.phone) || !state.submitted
          ? null
          : renderError('* Enter valid phone number')}
      </div>
    );
  }

  function renderCity() {
    return (
      <Fragment>
        <FloatingInput
          value={state.registration.cityName}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'cityName',
              value: e.target.value,
            });
          }}
          labelName="City *"
        />
        {state.registration.cityName || !state.submitted
          ? null
          : renderError('* city is required')}
      </Fragment>
    );
  }

  function renderState() {
    return (
      <Fragment>
        <FloatingInput
          value={state.registration.stateName}
          onChange={(e) => {
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'stateName',
              value: e.target.value,
            });
          }}
          labelName="State *"
        />
        {state.registration.stateName || !state.submitted
          ? null
          : renderError('* state is required')}
      </Fragment>
    );
  }

  function renderBestWayToCommunicate() {
    return (
      <div className="mb-4 d-flex align-items-center">
        <span className="av-gray">Best way to communicate</span>
        <RadioGroup
          name="bestWayToCommunicate"
          handleChange={(event) =>
            dispatch({
              type: 'SET_REGISTRATION_FIELD',
              key: 'bestWayToCommunicate',
              value: event.target.value,
            })
          }
        >
          <div className="d-flex ml-4">
            <RadioOption
              id="phone"
              name="bestWayToCommunicate"
              value="Phone"
              label="Phone"
              checked={state.registration.bestWayToCommunicate === 'Phone'}
            />
            <div className="ml-4">
              <RadioOption
                id="email"
                name="bestWayToCommunicate"
                value="Email"
                label="Email"
                checked={state.registration.bestWayToCommunicate === 'Email'}
              />
            </div>
          </div>
        </RadioGroup>
      </div>
    );
  }

  function renderSubmit() {
    if (state.loading) {
      return (
        <div className="text-center mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '32px' }}
          />
        </div>
      );
    }

    const {
      name,
      schoolName,
      email,
      phone,
      cityName,
      stateName,
      role,
      bestWayToCommunicate,
    } = state.registration;
    const disabled =
      !name.trim().length ||
      !cityName.trim().length ||
      !stateName.trim().length ||
      !schoolName.trim().length ||
      !email.trim().length ||
      !role.trim().length ||
      !bestWayToCommunicate.trim().length ||
      !phone.trim().length;
    return (
      <RectangularButton
        onClick={() => {
          onContactUs();
        }}
        className="d-flex justify-content-center blue submit-request-btn"
        disabled={disabled}
        darkButton
      >
        Submit
      </RectangularButton>
    );
  }

  function renderForm() {
    return (
      <Fragment>
        {/* <h4 className="title m-0 p-0" id="sign-for-classroom-demo">Sign up for a demo</h4> */}
        {renderName()}
        {renderEmail()}
        {renderPhone()}
        {renderBestWayToCommunicate()}
        {renderSchoolName()}
        {renderRole()}
        {renderCity()}
        {renderState()}
        {renderSubmit()}
      </Fragment>
    );
  }

  function renderSubmitted() {
    return (
      <h2 className="av-green text-center font-weight-700 m-0">
        Request submitted successfully.
      </h2>
    );
  }

  return (
    <div className="contact-us">
      <div className="ml-auto mr-auto">
        {renderForm()}
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) { }

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestClassroomDemo);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';

import { Icon, ICONS } from 'components/Icons';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import './ActionLink.scss';

class ActionLink extends Component {
  static propTypes = {
    label: PropTypes.string,
    iconType: PropTypes.string,
    iconName: PropTypes.string,
    linkClassName: PropTypes.string,
    onClick: PropTypes.func,
    toolTipText: PropTypes.string,
    disabled: PropTypes.bool,
    disabledToolTipText: PropTypes.string,
    toolTipPosition: PropTypes.string,
    authenticationRequired: PropTypes.bool,
    authenticated: PropTypes.bool,
    authenticationText: PropTypes.string,
    openErrorModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      iconType,
      iconName,
      iconSize,
      iconClassName,
      disabled,
      disabledToolTipText,
      toolTipPosition,
      label,
      children,
    } = this.props;

    const icon = this.getIcon(iconType, iconName, iconSize, iconClassName);

    let linkClassName = `action-link ${this.props.linkClassName}`;

    let { toolTipText } = this.props;
    if (disabled) {
      toolTipText = disabledToolTipText || 'Please log in';
      linkClassName += ' disabled';
    }
    if (toolTipText) {
      const toolTipPositionForDisplay = toolTipPosition || 'left';

      return (
        <div className={linkClassName} onClick={this.onClick}>
          <ToolTip
            toolTipText={toolTipText}
            toolTipPosition={toolTipPositionForDisplay}
          >
            <div className="action-link-text d-flex align-items-center">
              {icon}
              <span>{label}</span>
              <span>{children}</span>
            </div>
          </ToolTip>
        </div>
      );
    }
    return (
      <div className={linkClassName} onClick={this.onClick}>
        <div className="action-link-text d-flex align-items-center">
          {icon}
          <span>{label}</span>
          <span>{children}</span>
        </div>
      </div>
    );
  }

  onClick = e => {
    e.stopPropagation();

    if (this.props.authenticationRequired && !this.props.authenticated) {
      const errorMessage = this.props.authenticationText
        ? this.props.authenticationText
        : 'Please login.';
      this.props.openErrorModal(errorMessage);
      return;
    }

    if (!this.props.disabled && this.props.onClick) this.props.onClick();
  };

  getIcon = (iconType, iconName, iconSize, iconClassName) => {
    // return("Test");
    let icon = '';
    switch (iconType) {
      case 'averPointIcon':
        iconClassName = iconClassName || 'mr-3';
        icon = (
          <Icon
            icon={ICONS[iconName]}
            size={iconSize}
            className={iconClassName}
          />
        );
        break;
      case 'glyphicon':
        const glyphiconClassName = `glyphicon ${iconName}`;
        icon = <span className={glyphiconClassName} />;
        break;
      case 'image':
        break;
      default:
        icon = '';
    }
    return icon;
  };
}

function mapStateToProps(state, ownProps) {
  return {
    authenticated: state.user.authenticated,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
  dispatch,
  openErrorModal: message => {
    const title = 'Error';
    const props = {
      model: {
        title,
        message,
      },
    };
    dispatch(Actions.openModal('error', props));
  },
  openConfirmationModal: (title, message, onResponse) => {
    const props = {
      model: { title, message },
      onResponse,
    };
    dispatch(Actions.openModal('confirm', props));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ActionLink),
);

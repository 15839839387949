import React from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';

import moment from 'moment';

// import { Image, Transformation } from 'cloudinary-react';

const config = require('config/config.public.js');

const styles = {
  container: {
    fontFamily: 'Arial',
  },
  title: {
    color: '#979797',
    fontSize: 18,
    fontWeight: 'regular',
  },
  source: {
    fontSize: 14,
    fontWeight: 'regular',
  },
  sourceText: {
    fontStyle: 'italic',
    padding: '5px 15px',
    width: '100%',
  },
  citation: {
    marginLeft: '15px',
    verticalAlign: 'middle',
  },
  sourceName: {
    fontSize: 14,
    fontWeight: 'regular',
  },
  sourceAuthorName: {
    fontSize: 14,
    fontWeight: 'regular',
    width: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  sourceLogo: {
    marginLeft: '5px',
  },
  sourceImage: {
    maxHeight: '100px',
    marginLeft: '15px',
    marginTop: '10px',
  },
  hostedImage: {
    maxHeight: '100px',
    marginLeft: '15px',
    marginTop: '10px',
  },
  sourceLogoImage: {
    width: '16px',
    marginTop: '-3px',
  },
};

const PointEmbed = ({ point, user }) => {
  return (
    <div style={styles.container}>
      <h5 style={styles.title}>{point.text}</h5>
      <div style={styles.sourceText}>{point.sourceText}</div>
      <div style={styles.source}>
        <a href={point.shareUrl} target="_blank">
          {/* - {point.sourceAuthor}, {point.sourceDate} */}
          {renderCitation(point)}
        </a>
      </div>
      {renderImage(point, user)}
    </div>
  );
};

const renderImage = (point, user) => {
  //const { user, point } = this.props;

  const isOwner = user._id === point.authorId;
  const userShowPic = user.settings.reading.picture === 'show';
  let shouldShowPic = true;
  if (isOwner) {
    shouldShowPic = point.showPhoto;
  } else {
    shouldShowPic = point.showPhoto && userShowPic;
  }

  if (!shouldShowPic) return null;

  if (point.imageType === 'source') {
    return (
      <div className="image-wrapper">
        <img
          style={styles.sourceImage}
          src={point.sourceImageURL}
          alt="From the source"
        />
      </div>
    );
  }

  // If there's no sourceImage, go for a user added image - if it exists
  const photoId = `points/${point._id}`;

  const licenseTermsAccepted =
    point.photoLicenseTerms &&
    (point.photoLicenseTerms.status == 'accepted' ||
      point.photoLicenseTerms.acceptTerms);
  if (!licenseTermsAccepted) return null;

  const className = 'image-wrapper';

  const height = 100;
  const width = 150;

  const transformations = [];

  transformations.push(
    <div width={width} height={height} crop={'fit'} key="transform-size" />,
  );

  const imageURL =
    'http://res.cloudinary.com/averpoint/image/upload/c_fit,h_100,w_150/dpr_1.0/v1/points/' +
    point._id;

  return <img style={styles.hostedImage} src={imageURL} />;

  // return (
  //   <Image
  //     dpr="auto"
  //     responsive
  //     width="auto"
  //     cloudName={config.cloudinary.cloud_name}
  //     version={point.hostedImageVersion}
  //     publicId={photoId}
  //     style={{}}
  //   >
  //     {transformations}
  //   </Image>
  // );
};

const renderCitation = (point, citationStyle = 'short') => {
  if (!point.sourceName) return null; // Must have a sourceName to render the citation

  //Don't show the citation if the user is editing
  // if(!this.props.isExtension && this.props.editable && this.state.showSourceDetails) return null;

  const {
    sourceName,
    sourceTitle,
    sourceAuthor,
    sourceURL,
    sourceDate,
    sourceDateFormatted,
  } = point;

  // Step 1: Prepare the sourceName (e.g. NYT), headline, and what will be the hyperlink
  let sourceDisplayHyperlink; // source title if it exists or else the source name
  let sourceNameDisplay;
  if (citationStyle == 'long' && sourceTitle) {
    sourceDisplayHyperlink =
      sourceTitle.length <= 30
        ? sourceTitle
        : `${sourceTitle.substr(0, 30)}...`;
    sourceNameDisplay = (
      <span>
        {', '}
        <span style={styles.sourceName}>{sourceName}</span>
      </span>
    );
  } else {
    sourceDisplayHyperlink = (
      <span style={styles.sourceName}>{sourceName}</span>
    );
    sourceNameDisplay = '';
  }

  // Step 2: Prepare the source author, if it exists
  let sourceAuthorDisplay = '';
  if (sourceAuthor) {
    const sourceAuthorText =
      sourceAuthor.length > 50
        ? `${sourceAuthor.substring(0, 50)}...`
        : sourceAuthor;
    sourceAuthorDisplay = (
      <span style={styles.sourceAuthorName}>{sourceAuthorText}</span>
    );
  }

  // Step 3: Prepare the source date, if it exists
  let momentDate = '';
  if (sourceDateFormatted) momentDate = sourceDateFormatted;
  else if (moment(sourceDate).isValid()) {
    momentDate = moment(sourceDate).format('MMMM D YYYY');
  }

  const sourceDateDisplay = momentDate ? <time>,&nbsp;{momentDate}</time> : '';

  return (
    <div style={styles.citation}>
      {/* {'- '} */}
      <span>
        {sourceAuthorDisplay}
        {' in '}
        <a href={sourceURL} target="_blank" tabIndex="-1">
          <span
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {sourceDisplayHyperlink}
          </span>
        </a>
        {sourceNameDisplay}
        {sourceDateDisplay}
      </span>
      {renderSourceLogo(point)}
    </div>
  );
};

const renderSourceLogo = point => {
  if (!point.sourceLogoURL) return null;

  return (
    <span style={styles.sourceLogo}>
      <img style={styles.sourceLogoImage} src={point.sourceLogoURL} />
    </span>
  );
};

PointEmbed.propTypes = {
  point: PropTypes.object,
  user: PropTypes.object,
};

export const renderToString = (props, cb) => {
  const div = document.createElement('div');
  render(<PointEmbed {...props} />, div, () => cb(div.innerHTML));
};

export default PointEmbed;

import * as Actions from "../actions/index";

const initialState = {
  mails: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.MESSAGES_LOADED:
      return {
        ...state,
        mails: payload
      };
    case Actions.DRAFT_SAVED:
    case Actions.MAIL_SENT:
      const exists = state.mails.find(s => s._id === payload._id);
      if (!exists) {
        return {
          ...state,
          mails: [...state.mails, payload]
        };
      }
      return {
        ...state,
        mails: state.mails.map(m => {
          if (m._id === payload._id) return payload;
          return m;
        })
      };

    default:
      return state;
  }
};

import * as React from 'react';
import InviteAverpointContainer from './InviteAverpointContainer';
import { Modal } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions/index';
import { toast } from 'components/elements/molecules/Toast/Toast';
require('./css/inviteAverpointModal.scss');

class InviteAverpointModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      message: '',
    };
  }

  componentDidMount() {
  }

  onInvite = (emails) => {
    var userName = emails[0];
    this.props.onAction(this.props.onInvite, emails)
      .then(response => {
        this.props.onClose(true);
        if (emails.length > 1) {
          toast('Your invitations were sent');
        } else {
          toast('Your invitation was sent to ' + userName);
        }
      })
      .catch(error => {
        this.props.openErrorModal('Error', error.message);
      });
  }

  onClose = () => {
    this.props.onClose(false);
  }

  render() {
    return (
      <Modal show={true} className="inviteAverpoint">
        <Modal.Header>
          <div className="header">
            <Icon icon={ICONS.INVITE} /> Invite users to join Averpoint
          </div>
          <div
            className="actions-container pull-right"
            onClick={this.onClose}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <InviteAverpointContainer onInvite={this.onInvite} />
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const authenticated = state.user.authenticated;
  const user = state.user.user;

  return { authenticated: authenticated, user: user };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openErrorModal: (type, message) => {
      var title = 'Error';
      let props = {
        model: {
          title,
          message,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteAverpointModal);

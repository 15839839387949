import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_CLASSROOM_GROUPS = 'LOAD_CLASSROOM_GROUPS';
export const ADD_CLASSROOM_GROUP = 'ADD_CLASSROOM_GROUP';
export const UPDATE_CLASSROOM_GROUP = 'UPDATE_CLASSROOM_GROUP';
export const UPDATE_DEFAULT_CLASSROOM_GROUP = 'UPDATE_DEFAULT_CLASSROOM_GROUP';
export const REMOVE_CLASSROOM_GROUP = 'REMOVE_CLASSROOM_GROUP';
export const LOAD_GROUP_STUDENTS_PROGRESS = 'LOAD_GROUP_STUDENTS_PROGRESS';

export function loadClassroomGroups(courseId, classroomGroups) {
  return {
    type: LOAD_CLASSROOM_GROUPS,
    courseId,
    classroomGroups,
  };
}

export function addClassroomGroup(courseId, classroomGroup) {
  return {
    type: ADD_CLASSROOM_GROUP,
    courseId,
    classroomGroup,
  };
}

export function modifyClassroomGroup(courseId, classroomGroup) {
  return {
    type: UPDATE_CLASSROOM_GROUP,
    courseId,
    classroomGroup,
  };
}

export function modifyDefaultClassroomGroup(courseId, classroomGroup) {
  return {
    type: UPDATE_DEFAULT_CLASSROOM_GROUP,
    courseId,
    classroomGroup,
  };
}

export function removeClassroomGroup(courseId, classroomGroupId) {
  return {
    type: REMOVE_CLASSROOM_GROUP,
    courseId,
    classroomGroupId,
  };
}

export function loadGroupStudentProgress(courseId, topicId, studentsProgress) {
  return {
    type: LOAD_GROUP_STUDENTS_PROGRESS,
    courseId,
    topicId,
    studentsProgress,
  };
}

export function getClassroomGroups(courseId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/classroomGroups/getClassroomGroups', { params: { courseId } })
        .then((response) => {
          const data = response.data;
          dispatch(loadClassroomGroups(courseId, data.classroomGroups));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching groups', error);
          toast('Error occurred while fetching groups. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function createClassroomGroup(courseId, name, students, visibilitySettings) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/classroomGroups/createClassroomGroup', {
          courseId, name, students, visibilitySettings,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addClassroomGroup(courseId, data.classroomGroup));
          toast('Group has been created successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while creating Group', error);
          toast('Error occurred while creating Group. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function updateClassroomGroup(courseId, classroomGroupId, name, students, visibilitySettings) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/classroomGroups/updateClassroomGroup', {
          courseId, classroomGroupId, name, students, visibilitySettings,
        })
        .then((response) => {
          const data = response.data;
          if (data.classroomGroup) {
            dispatch(modifyClassroomGroup(courseId, data.classroomGroup));
          }
          toast('Group has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating Group', error);
          toast('Error occurred while updating Group. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}


export function updateDefaultClassroomGroup(courseId, visibilitySettings) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/classroomGroups/updateDefaultClassroomGroup', {
          courseId, visibilitySettings,
        })
        .then((response) => {
          const data = response.data;
          if (data.classroomGroup) {
            dispatch(modifyDefaultClassroomGroup(courseId, data.classroomGroup));
          }
          toast('Group has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating Group', error);
          toast('Error occurred while updating Group. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function deleteClassroomGroup(
  courseId,
  classroomGroupId,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/classroomGroups/deleteClassroomGroup', {
          params: { courseId, classroomGroupId },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(removeClassroomGroup(courseId, classroomGroupId));
            toast('Group has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting Group', error);
          toast('Error occurred while deleting Group. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getGroupStudentReadingActivity(courseId, topicId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/classroomGroups/getGroupStudentReadingActivity', { params: { courseId, topicId } })
        .then((response) => {
          const data = response.data;
          dispatch(loadGroupStudentProgress(courseId, topicId, data.studentsProgress));
          return resolve(data.studentsProgress);
        })
        .catch((error) => {
          console.log('Error while fetching groups', error);
          return resolve([]);
        });
    });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

class CloudinaryLink extends React.Component {
  static propTypes = {
    imageExists: PropTypes.bool,
    cloudinaryImageId: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.object,
    onLicenseTermsAccepted: PropTypes.func,
    /**redux */
    openAddPictureModal: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const className = classnames('cloudinary-link', this.props.className);
    return (
      <div className={className} onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  }

  onClick = (e) => {
    console.log('onClick');
    e.stopPropagation();

    //this.picChange();
    this.props.dispatch({
      type: 'SET_CLOUDINARY',
      imageId: this.props.cloudinaryImageId,
      isOpen: true,
      onLicenseTermsAccepted: this.props.onLicenseTermsAccepted,
    });

    if (!this.props.onClick) return;

    return this.props.onClick(e);
  }
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;

  return {
    authenticated,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloudinaryLink);

import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from '../../Icons';
import { convertCamelCase } from 'helpers';
import { toast } from 'components/elements/molecules/Toast/Toast';

import './StudentCommentsOnSource.scss';

function StudentCommentsOnSource({ studentsComments, refetch, onClose }) {
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [loading, setLoading] = useState(false);

  const resolve = (commentId) => {
    setLoading(true);

    axios
      .get('/api/browserExtensionStatus/resolveStudentComment', {
        params: {
          id: commentId,
        },
      })
      .then(() => {
        refetch();
        onClose();
        toast('The Source has been resolved.', {
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal
      show={true}
      className={`student-comments-modal ${
        showConfirmation ? 'resolve-confirmation-modal' : ''
      }`}
    >
      <Modal.Body>
        <div className="header">
          <span>Student Comments</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div>
          {showConfirmation ? (
            <div>
              <div className="mb-4 mt-2">
                You are about to resolve the Source{' '}
                {showConfirmation.sourceName}. Are you sure you checked it, and
                it's working as expected?
              </div>
              {loading ? (
                <div className="text-center mt-4">
                  <div
                    className="fa fa-spin fa-spinner text-primary"
                    style={{ fontSize: '40px' }}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <RectangularButton
                    className="red"
                    onClick={() => setShowConfirmation(null)}
                    noBorder
                  >
                    Cancel
                  </RectangularButton>
                  <RectangularButton
                    className="blue ml-3"
                    onClick={() => resolve(showConfirmation.commentId)}
                    darkButton
                  >
                    Confirm
                  </RectangularButton>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <div className="row">
                <div className="col-md-2">
                  <b>User</b>
                </div>
                <div className="col-md-4">
                  <b>Status</b>
                </div>
                <div className="col-md-3">
                  <b>Comment</b>
                </div>
                <div className="col-md-1">
                  <b>Reading Time</b>
                </div>
                <div className="col-md-2"></div>
              </div>
              {studentsComments.map((comment) => (
                <div className="row mt-2">
                  <div className="col-md-2">{comment.userName}</div>
                  <div className="col-md-4">
                    {comment.componentReviews.map((ws) => (
                      <React.Fragment>
                        <b>{convertCamelCase(ws.componentType)}</b> -
                        {convertCamelCase(ws.status)}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="col-md-3">{comment.comment}</div>
                  <div className="col-md-1">{comment.readingTime || '-'}</div>
                  <div className="col-md-2">
                    <RectangularButton
                      onClick={() =>
                        setShowConfirmation({
                          commentId: comment._id,
                          sourceName: comment.sourceName,
                        })
                      }
                    >
                      Resolve
                    </RectangularButton>
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps() {
  return {};
}

export default connect(null, mapDispatchToProps)(StudentCommentsOnSource);

import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import RTPCard from './RTPCard';
import ReviewPointForm from 'routes/Collection/Component/CollectionDetails/ReviewPoint/ReviewPointForm';
import * as Actions from 'redux/actions/index';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import RTPSummary from './RTPSummary';

function RTPTab({
  point,
  user,
  actions,
  rtps,
  // points,
  showPointSelectText,
  selectedCaptions,
  onRTPCreate,
  onAction,
  isUpdateReview,
  rtpId,
  collection,
  collectionPoint,
  isMock,
  captions,
  isExtensionCL,
  courseId,
  priorityUsers,
  teacherMode,
  studentMode,
  topicId,
}) {
  // let rtps;

  // if (point._id) {
  //   rtps = points[point._id].rtps;
  // } else {
  //   rtps = [];
  // }

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_RTP':
          return {
            ...state,
            showRTP: action.payload.show,
            updatingRTP: action.payload.rtp,
          };
        case 'TOGGLE_SHOW_HIDE_ALL':
          return {
            ...state,
            showAll: !state.showAll,
          };
        case 'SET_TOPIC_USER_SUMMARY':
          return {
            ...state,
            topicUserSummary: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showRTP: false,
      updatingRTP: null,
      // showAll: !rtpId,
      showAll: !!priorityUsers,
      rtpId,
    },
  );

  useEffect(() => {
    // load point
    if (point._id && !rtps) {
      actions.getRTPsForPoint(point._id);
    }
  }, [point._id]);

  useEffect(() => {
    showPointSelectText(
      state.showRTP,
      state.updatingRTP ? state.updatingRTP.surveyResponse.captions : [],
    );
    onAction && onAction('RTP_TAB_SHOW_RTP_FORM', state.showRTP);
  }, [state.showRTP]);

  useEffect(() => {
    if (isUpdateReview) {
      if (rtps.length) {
        onUpdate(rtps[0]);
      }
    }
  }, [isUpdateReview]);

  useEffect(() => {
    loadTopicUserSummary();
  }, [topicId, rtps ? rtps.length : 0]);

  async function loadTopicUserSummary() {
    if (studentMode && topicId) {
      const res = await actions.getTopicGoals(topicId, courseId, false, true);
      dispatch({ type: 'SET_TOPIC_USER_SUMMARY', payload: res.userSummary });
    }
  }

  function isStudentNotInteracted() {
    return (
      !teacherMode &&
      studentMode &&
      (!state.topicUserSummary ||
        state.topicUserSummary.qtps + state.topicUserSummary.rtps === 0)
    );
  }

  function onUpdate(rtp) {
    dispatch({ type: 'SHOW_RTP', payload: { show: true, rtp } });
  }

  function renderLoading() {
    if (rtps) {
      return null;
    }

    return (
      <div
        className="fa fa-spin fa-spinner text-primary"
        style={{ fontSize: '14px' }}
      />
    );
  }

  function renderRTP() {
    return (
      <ReviewPointForm
        point={point}
        close={(isCreated, rtp) => {
          dispatch({ type: 'SHOW_RTP', payload: false });

          if (isCreated) {
            onRTPCreate && onRTPCreate(rtp);
          }
        }}
        selectedCaptions={selectedCaptions}
        rtp={state.updatingRTP}
        collectionPoint={collectionPoint}
        onAction={onAction}
        isMock={isMock}
        captions={captions}
        isExtensionCL={isExtensionCL}
      />
    );
  }

  function renderReviewPointButton() {
    if (!rtps || rtps.findIndex((i) => i.userId === user.user._id) !== -1) {
      return null;
    }

    if (teacherMode) {
      return null;
    }

    const btn = (
      <RectangularButton
        className="w-100 mb-3 big-button"
        iconName="REVIEW"
        iconSize={24}
        onClick={() => {
          dispatch({
            type: 'SHOW_RTP',
            payload: { show: true },
          });
        }}
        disabled={!user.user._id}
      >
        Review this Point
      </RectangularButton>
    );

    if (user.user._id) {
      return btn;
    } else {
      return (
        <ToolTip
          toolTipText="Please login to RTP"
          toolTipPosition="bottom"
          className="w-100"
        >
          {btn}
        </ToolTip>
      );
    }
  }

  function renderSummary() {
    if (!rtps || !rtps.length) {
      return null;
    }

    if (teacherMode && priorityUsers && priorityUsers.length === 1) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    return <RTPSummary rtps={rtps} />;
  }

  function renderStudentNoRTPMessage() {
    let text;

    if (teacherMode && rtps && !rtps.length) {
      text = 'Students have not Reviewed this Point.';
    }

    if (teacherMode && rtps && rtps.length && priorityUsers) {
      const filterRTPs = rtps.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );

      if (!filterRTPs.length) {
        if (priorityUsers.length === 1) {
          text = `${priorityUsers[0].username} hasn't Reviewed this Point.`;
        } else if (priorityUsers.length === 0) {
          text = 'The selected students have not Reviewed this Point.';
        }
      }
    }

    if (isStudentNotInteracted() && state.topicUserSummary) {
      text =
        'You need to question or review at least one claim to view the others.';
    }

    if (text) {
      return (
        <div className="text-center font-italic font-size-12 av-gray">
          {text}
        </div>
      );
    }

    return null;
  }

  function renderCard(rtp) {
    const className = classnames('mt-3');
    return (
      <RTPCard
        rtp={rtp}
        className={className}
        key={rtp._id}
        onUpdate={onUpdate}
        point={point}
        collection={collection}
        courseId={courseId}
        teacherMode={teacherMode}
      />
    );
  }

  function renderMyCard() {
    if (!rtps || !rtps.length) {
      return null;
    }

    const rtp = rtps.find((i) => i.userId === user.user._id);

    if (!rtp) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    return renderCard(rtp);
  }

  function renderShowHideToggleButton() {
    if (!rtps || !rtps.length) {
      return null;
    }

    let otherRTPs = rtps.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      otherRTPs = otherRTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }

    if (!otherRTPs.length) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let text;

    if (state.showAll) {
      text = 'Hide Reviews';
    } else {
      if (otherRTPs.length === 1) {
        text = 'See 1 Review';
      } else if (otherRTPs.length === 2) {
        text = 'See both Reviews';
      } else {
        text = `See all ${otherRTPs.length} Reviews`;
      }
    }

    return (
      <div className="">
        <ActionLink
          linkClassName="btn-show-hide-toggle"
          onClick={() => dispatch({ type: 'TOGGLE_SHOW_HIDE_ALL' })}
        >
          {text}
        </ActionLink>
      </div>
    );
  }

  function renderOtherCards() {
    if (!rtps || !rtps.length || !state.showAll) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let sortedRTPs;

    if (state.showAll) {
      sortedRTPs = rtps.sort((i) => (i.userId === user.user._id ? -1 : 1));
    } else {
      sortedRTPs = rtps.filter((i) => i._id === state.rtpId);
    }

    sortedRTPs = sortedRTPs.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      sortedRTPs = sortedRTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }
    return sortedRTPs.map((rtp) => {
      return renderCard(rtp);
    });
  }

  function renderContent() {
    if (state.showRTP) {
      return renderRTP();
    } else {
      return (
        <React.Fragment>
          {renderLoading()}
          {renderReviewPointButton()}
          {renderSummary()}
          {renderStudentNoRTPMessage()}
          {renderMyCard()}
          {renderShowHideToggleButton()}
          {renderOtherCards()}
        </React.Fragment>
      );
    }
  }

  return <div className="">{renderContent()}</div>;
}

function mapStateToProps(state, props) {
  let rtps = [];

  if (props.point._id) {
    rtps = state.points[props.point._id].rtps;
  }

  return {
    // points: state.points,
    user: state.user,
    rtps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RTPTab);

import axios from 'axios';
import { toast } from 'components/elements/molecules/Toast/Toast';
import qs from 'qs';

export const DRAFT_SAVED = 'email/DRAFT_SAVED';
export const MESSAGES_LOADED = 'email/MESSAGES_LOADED';
export const MAIL_SENT = 'email/MAIL_SENT';

export const CREATE_POINT_URL = 'admin/CREATE_POINT_URL';

export function loadMails() {
  return dispatch => {
    return axios.get('/api/emails/').then(({ data }) => {
      dispatch({
        type: MESSAGES_LOADED,
        payload: data,
      });
    });
  };
}

export function emailAdminMessage(subject, message, _id) {
  return (dispatch, getState) => {
    console.log('emailAdminMessage got called ');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/emails/emailAdminMessage',
        params: {
          subject,
          message,
          _id,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then(response => {
          console.log('Email admin message responded successfully');
          //console.log(response);
          const data = response.data;
          dispatch({
            type: MAIL_SENT,
            payload: data.sentEmail,
          });
          //dispatch(emailStatus("success"));
          resolve(data);
        })
        .catch(response => {
          console.log('Email admin message responded error');
          reject();
        });
    });
  };
}

export function saveAdminMessage(payload) {
  return dispatch => {
    return axios({
      method: 'post',
      url: '/api/emails/saveAdminMessage',
      data: payload,
    })
      .then(response => {
        const data = response.data;
        dispatch({
          type: DRAFT_SAVED,
          payload: data,
        });
        return data;
      })
      .catch(e => {
        throw e.response.data;
      });
  };
}

export function updateElasticBeanstalkStatus(action, environmentName) {
  return dispatch => {
    return axios({
      method: 'get',
      url: '/api/utility/updateElasticBeanstalkStatus',
      params: { action, environmentName },
    })
      .then(response => {
        toast(`Server ${action === 'restart' ? 'restart' : 'termination'} initiated.`);

        return response;
      })
      .catch(e => {
        toast(`Failed to ${action} server`, { type: 'error' });

        return null;
      });
  };
}

export function getElasticBeanstalkStatus(environmentName) {
  return dispatch => {
    return axios({
      method: 'get',
      url: '/api/utility/getElasticBeanstalkStatus',
      params: { environmentName },
    })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        toast('Failed to get the status of server', { type: 'error' });

        return null;
      });
  };
}

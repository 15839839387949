import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import FactReviewImage from "components/elements/molecules/Images/FactReviewImage";
//import Users from './Users'

class ReviewListItem extends Component {
  constructor(props) {
    super(props);
    this.setState = {};
  }

  render() {
    return (
      <div className="user-list">
        <div className="review-list-item header">
          <div className="username header">Username</div>
          <div className="review header">Review</div>
          <div className="review-level header">Level</div>
        </div>
        {this.renderReviews()}
      </div>
    );
  }

  renderReviews = () => {
    const { reviews, handleReviewModal } = this.props;

    if (!reviews) return;

    return (
      <div className="review-list">
        {reviews.map((review, i) => {
          return (
            <div
              key={i}
              className="review-list-item"
              onClick={() => handleReviewModal("reviewDetails", review)}
            >
              {this.renderChallengeRow(review)}
              <div className="review-row">
                <div className="username">{review.reviewerUsername}</div>
                <div className="review">
                  {review.factReview ? (
                    <FactReviewImage review={review.factReview.userReview} />
                  ) : (
                    review.opinionReview.agree
                  )}
                </div>
                <div className="challenge">
                  {/* {
                    review.isChallenge ?
                    "(Challenge)"
                    :
                    ""
                  } */}
                </div>
                <div className="review-level">
                  {review.factReview ? review.pointReviewLevel : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderChallengeRow = review => {
    if (!review.isChallenge) return;

    return (
      <div className="challenge-row">
        ---------- {review.reviewerUsername} challenged the level{" "}
        {review.pointReviewLevel - 1} review ---------
      </div>
    );
  };
}
export default ReviewListItem;

import axios from 'axios';

export const ADD_QTP = 'ADD_QTP';

export function addQTP(qtp) {
  return async (dispatch, getState) => {
    let res;
    // if (qtp.pointId === 'Mock-Point-1') {
    if (
      qtp.pointId.indexOf('Mock-Point') === 0 ||
      qtp.pointId.indexOf('example') === 0 ||
      qtp.isMock
    ) {
      const point = getState().points[qtp.pointId].point;
      qtp.user = getState().user.user;
      res = addMockQTP(point, qtp);
    } else {
      res = await axios.post('/api/qtps/addQTP', { qtp });
    }

    console.log(res.data);
    dispatch({
      type: 'UPDATE_POINT',
      point: res.data.point,
      user: getState().user,
    });
    dispatch({
      type: ADD_QTP,
      point: res.data.point,
      qtp: res.data.qtp,
    });

    return res.data;
  };
}

function addMockQTP(point, qtp) {
  point.numQTP++;

  if (!qtp._id) {
    qtp._id = Date.now().toString();
  }

  const mockResult = {
    data: {
      point,
      qtp,
    },
  };
  return mockResult;
}

// updateQTP
export function updateQTP(qtp) {
  return (dispatch, getState) => {
    const res = axios.post('/api/qtps/updateQTP', { qtp });
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
  };
}

// removeQTP
export function removeQTP(pointId) {
  return (dispatch, getState) => {
    const res = axios.delete('/api/qtps/removeQTP' + pointId);
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
  };
}

// getQTPsForPoint
export function getQTPsForPoint(pointId) {
  return async (dispatch, getState) => {
    let qtps;

    if (getState().points[pointId] && getState().points[pointId].point.isMock) {
      qtps = getState().points[pointId].qtps || [];
    } else {
      const res = await axios.get('/api/qtps/getQTPsForPoint/' + pointId);
      console.log(res.data);
      qtps = res.data;
    }

    dispatch({
      type: 'UPDATE_POINT',
      point: { _id: pointId },
      qtps,
    });
  };
}

export function getQuestionedPointsForUser(userId) {
  return async (dispatch, getState) => {
    const res = await axios.get(
      '/api/qtps/getQuestionedPointsForUser/' + userId,
    );
    // console.log(res.data);
    const { points, qtps } = res.data;

    for (let i = 0; i < qtps.length; i++) {
      const qtp = qtps[i];
      // console.log('Checking this qtp');
      // console.log(qtp);
      for (let j = 0; j < points.length; j++) {
        // console.log("Checking if it's this point");
        // console.log(points[j]._id);
        if (points[j]._id === qtp.pointId) {
          // console.log('Found the pointId. Assigning qtp');
          points[j].qtps = [{ ...qtp, type: 'QTP' }];
          // console.log('Not point.qtps is ');
          // console.log(points[j].qtps);
          break;
        }
      }
    }

    dispatch({
      type: 'UPDATE_POINTS',
      updatedPoints: points,
    });

    // resolve(res.data);
    console.log(res.data);
    return res.data;
  };
}

export function pointQTPRTPGroup(properties) {
  return (dispatch, getState) => {
    return axios.post('/api/events/handleEvent', {
      event: 'GROUP_QTP_RTP_ADDED',
      properties,
    });
  };
}

import React, { useEffect } from 'react';
import { getLogo } from 'helpers/sourceIcon';
import { Icon, ICONS } from 'components/Icons';
import _ from 'lodash';

export default function Reading({
  topicId,
  courseId,
  reading,
  updateReading,
  actions,
  setUnapprovedSources,
  setReferenceSources,
  showQuiz,
  openQuestionsModal,
  quizQuestions,
}) {
  const quizQuestionsByPointId = _.groupBy(quizQuestions, (q) => q.pointId);

  useEffect(() => {
    if (typeof reading.success === 'boolean') {
      updateReading({
        ...reading,
        loading: false,
      });
      return;
    }

    if (reading.loading || reading.isUploadingPdf) {
      return;
    }

    updateReading({
      ...reading,
      loading: true,
    });

    actions
      .addReading(
        topicId,
        courseId,
        reading.url || reading.pdfUrl,
        reading._id,
        reading.readingFormat,
        reading.text,
        reading.headline,
        reading.authors,
        reading.date,
        reading.sourceName,
        reading.isReference,
      )
      .then((newReading) => {
        console.log('newReading', newReading);
        updateReading({
          ...reading,
          ...newReading,
          loading: false,
          success: true,
        });
        try {
          if (!newReading.isApprovedForAnalysis) {
            setUnapprovedSources((current) => [
              ...current,
              new URL(newReading.url).host,
            ]);
          }
          if (newReading.isReference && !reading.isReference) {
            setReferenceSources((current) => [
              ...current,
              new URL(newReading.url).host,
            ]);
          }
        } catch (error) {
          console.error('Error while parsing URL', error);
        }
      })
      .catch((error) => {
        console.log('Error while adding reading', error);
        updateReading({
          ...reading,
          loading: false,
          success: false,
          error:
            error.error || 'Failed to add reading to topic. Please try again',
        });
      });
  }, []);

  const showStatus = () => {
    if (reading.loading) {
      return (
        <div className="loader text-center">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '20px' }}
          />
        </div>
      );
    }

    if (!reading.success) {
      return (
        <div className="check error d-inline-flex align-items-center justify-content-center">
          <Icon icon={ICONS.CLOSE} className="av-white" size={12} />
        </div>
      );
    }

    return (
      <img
        className="mr-2 source-logo"
        src={getLogo({ domain: reading.url })}
      />
    );
  };

  const showReading = () => {
    if (reading.loading) {
      return (
        <div className="reading-url">{reading.url}</div>
      );
    }

    if (!reading.success) {
      return (
        <React.Fragment>
          <div className="reading-url">{reading.url}</div>
          {reading.error && (
            <div className="av-red reading-error-message">{reading.error}</div>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="font-weight-600">{reading.sourceName}</div>
        <div>{reading.title}</div>
      </React.Fragment>
    );
  }

  return (
    <tr className="readings-container">
      <td className="pr-3 reading-status-column">
        <div className="reading-status">{showStatus()}</div>
      </td>
      <td className="readings" key={reading.title}>
        <div className="reading-title">
          {showReading()}
        </div>
      </td>
      {
        showQuiz && (
          <td className="create-quiz-column">
            {
              (reading.pointId && quizQuestionsByPointId[reading.pointId]) ? (
                <span className="text-underline cursor-pointer av-blue" onClick={() => openQuestionsModal()}>
                  View
                </span>
              ) : (
                <Icon className="cursor-pointer av-blue" icon={ICONS.ADD_CIRCLE} size={20} onClick={() => openQuestionsModal()} />
              )
            }
          </td>
        )
      }
    </tr>
  );
}

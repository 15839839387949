import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import classnames from 'classnames';

import ProfileImage from 'components/elements/molecules/Images/profileImage';
import { Icon, ICONS } from 'components/Icons';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { toast } from 'components/elements/molecules/Toast/Toast';

import './MultipleProfileImages.scss';

const MultipleProfileImages = props => {
  const renderAuthorProfileImage = author => {
    return (
      <ProfileImage
        key={author._id || author.userId}
        userId={author.userId}
        hostedImageVersion={author.authorHostedImageVersion}
        allowUserToChangePic={false}
        allowClickToPortfolio={true}
        username={author.username}
      />
    );
  };

  const renderAuthorsProfileImages = authors => {
    let maxImages = props.maxImages;

    const elms = [authors.slice(0, maxImages).map(renderAuthorProfileImage)];

    if (authors.length > maxImages) {
      elms.push(
        <div className="count" key="count">
          {authors.length - maxImages}
        </div>,
      );
    }

    return elms;
  };

  function onRemove(userId) {
    props.openConfirmationModal('Confirm', 'Are you sure?', async success => {
      if (success) {
        await props.onRemove(userId);
        toast('Author removed successfully');
      }
    });
  }

  function renderRemove(author) {
    if (props.user._id !== props.primaryAuthorId) return null;

    if (props.onRemove && author.userId !== props.primaryAuthorId) {
      return (
        <Icon
          icon={ICONS.CLOSE}
          onClick={() => onRemove(author.username)}
          className="ml-auto av-grayish-cyan"
        />
      );
    }
  }

  const renderInvite = () => {
    if (!props.canInvite) {
      return;
    }

    const menuItems = [
      <MenuItem className="header" key="header">
        <div className="d-flex align-items-center">
          <div className="av-grayish-cyan">{props.authors.length} Authors</div>
          <ActionLink
            onClick={props.onInvite}
            linkClassName="ml-auto"
            iconType="averPointIcon"
            iconName="USER_PLUS"
            iconClassName="av-blue mr-2"
            iconSize={24}
            label="Invite"
          />
        </div>
      </MenuItem>,
      ...props.authors.map(author => {
        let menuItemClassName = classnames(
          'd-flex align-items-center',
          author.invitationStatus,
        );
        return (
          <MenuItem key={author._id || author.userId}>
            <div className={menuItemClassName}>
              <ProfileImage
                userId={author.userId}
                hostedImageVersion={author.authorHostedImageVersion}
                allowUserToChangePic={false}
                allowClickToPortfolio={true}
                username={author.username}
              />
              <span className="ml-2">{author.username}</span>
              {renderRemove(author)}
            </div>
          </MenuItem>
        );
      }),
    ];
    return (
      <Dropdown
        id="invite-dropdown"
        className="invite-dropdown ml-2"
        onClick={e => e.stopPropagation()}
        pullRight={props.pullRight}
      >
        <Dropdown.Toggle noCaret>
          <Icon icon={ICONS.USER_PLUS} size={25} />
        </Dropdown.Toggle>
        <Dropdown.Menu>{menuItems}</Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="multiple-profile-images d-flex align-items-start">
      {renderAuthorsProfileImages(props.authors)}
      {renderInvite()}
    </div>
  );
};

MultipleProfileImages.propTypes = {
  authors: PropTypes.array,
  canInvite: PropTypes.bool,
  onInvite: PropTypes.func,
  onRemove: PropTypes.func,
  maxImages: PropTypes.number,
  primaryAuthorId: PropTypes.string,
  pullRight: PropTypes.bool,
};

MultipleProfileImages.defaultProps = {
  maxImages: 5,
  pullRight: false,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: { title, message },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleProfileImages);

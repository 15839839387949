import axios from 'axios';
import qs from 'qs';

export const LOAD_PORTFOLIO = 'LOAD_PORTFOLIO';
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const FOLLOW_PORTFOLIO = 'FOLLOW_PORTFOLIO';
export const UNFOLLOW_PORTFOLIO = 'UNFOLLOW_PORTFOLIO';
export const LOAD_PORTFOLIO_COLLECTION_POINTS =
  'LOAD_PORTFOLIO_COLLECTION_POINTS';
export const UPDATE_STUDENT_ROLE_PORTFOLIO = 'UPDATE_STUDENT_ROLE_PORTFOLIO';
export const UPDATE_TEACHER_ROLE_PORTFOLIO = 'UPDATE_TEACHER_ROLE_PORTFOLIO';

export function loadPortfolio(
  user,
  portfolio,
  collectionPoints,
  userScore,
  userProfile,
  followersCount,
  following,
  stats,
  personalStats,
  currentUser,
) {
  return {
    type: LOAD_PORTFOLIO,
    data: {
      user,
      portfolio,
      collectionPoints,
      userScore,
      userProfile,
      followersCount,
      following,
      stats,
      personalStats,
      currentUser,
    },
  };
}

export function getPortfolio(username) {
  return (dispatch, getState) => {
    //console.log("getPortfolio got called for user "+JSON.stringify(username));

    return new Promise((resolve, reject) => {
      dispatch({
        type: 'START_GETTING_PORTFOLIO',
        username,
      });
      axios({
        method: 'get',
        url: '/api/portfolios/getPortfolio',
        params: {
          username,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          //("Get Portfolio responded successfully")
          //console.log(response);
          const data = response.data;

          dispatch(
            loadPortfolio(
              data.publicUser,
              data.portfolio,
              data.collectionPoints,
              data.userScore,
              data.userProfile,
              data.followersCount,
              data.following,
              data.stats,
              data.personalStats,
              getState().user && getState().user.user,
            ),
          );
          resolve(data.portfolio, data.collections);
        })
        .catch((response) => {
          console.log('Get Portfolio responded error ', response);
          reject();
        });
    });
  };
}

export function getPortfolioCollectionPoints(username) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/portfolios/getPortfolioCollectionPoints',
        params: {
          username,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          const data = response.data;

          dispatch({
            type: LOAD_PORTFOLIO_COLLECTION_POINTS,
            data: {
              publicUser: data.publicUser,
              collectionPoints: data.collectionPoints,
              currentUser: getState().user && getState().user.user,
            },
          });
          resolve(data);
        })
        .catch((response) => {
          console.log(
            'Get Portfolio CollectionPoints responded error ',
            response,
          );
          reject();
        });
    });
  };
}

export function testSendGrid() {
  return (dispatch) => {
    return axios.post(`/api/testSendGrid`);
  };
}

export function followPortfolio(id) {
  return (dispatch) => {
    return axios.post(`/api/portfolios/${id}/follow`).then(({ data }) => {
      dispatch({
        type: FOLLOW_PORTFOLIO,
        payload: {
          username: data.username,
        },
      });
    });
  };
}

export function unFollowPortfolio(id) {
  return (dispatch) => {
    return axios.delete(`/api/portfolios/${id}/follow`).then(({ data }) => {
      dispatch({
        type: UNFOLLOW_PORTFOLIO,
        payload: {
          username: data.username,
        },
      });
    });
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { gotoNextStepAsync } from 'redux/actions/walkthrough';

import { toastByType } from 'components/elements/molecules/Toast/Toast';
import CreatePointComponent from './CreatePointComponent';

class CreatePointContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    status: PropTypes.string,
    isExtension: PropTypes.bool,
    user: PropTypes.object,
    userHighlightType: PropTypes.string,
    recentSourceInfo: PropTypes.object,
    presetPointFields: PropTypes.object,
    pointObject: PropTypes.object,
    point: PropTypes.object,
    pointUrl: PropTypes.string,
    showAddToCollection: PropTypes.bool,
    collectionId: PropTypes.string,
    sourceText: PropTypes.string,
    sourceImageURL: PropTypes.string,
    hostedImageURL: PropTypes.string,
    imageType: PropTypes.string,
    pdfThruBrowser: PropTypes.bool,
    setCollectionId: PropTypes.func,
    errorMessage: PropTypes.string,
    showStance: PropTypes.bool,
    afterPointCreated: PropTypes.func,
    dispatch: PropTypes.func,
    walkthrough: PropTypes.object,
    gotoNextStepAsync: PropTypes.func,
    user: PropTypes.object,
    onClose: PropTypes.func,
    onAction: PropTypes.func,
    openCreateCollectionModal: PropTypes.func,
    focusQuote: PropTypes.bool,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
    placeholder: PropTypes.string,
    focusPointText: PropTypes.bool,
    isProvidingEvidence: PropTypes.bool,
    createClaim: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      collectionId: props.collectionId,
      additionalAuthors: props.additionalAuthors,
    };

    if (this.state.collectionId) {
      let pointObject = props.points[this.state.collectionId];

      if (!pointObject) {
        let promise;

        if (props.isCollection) {
          promise = this.props.actions.getCollection(this.state.collectionId);
        } else {
          promise = this.props.actions.getPoint(this.state.collectionId);
        }

        promise.then(({ point, collectionPoint }) => {
          if (!props.additionalAuthors) {
            this.setAdditionalAuthors(
              (point || collectionPoint).authors
                .filter((i) => i.invitationStatus === 'accepted')
                .map((i) => i.userId)
                .filter((i) => i !== this.props.user._id),
            );
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.collectionId !== prevProps.collectionId) {
      this.setCollectionId(this.props.collectionId);
    }
  }

  onSavePoint = async (point, stance) => {
    const {
      openPointModal,
      showAddToCollection,
      dispatch,
      isProvidingEvidence,
      createClaim,
      isMock,
    } = this.props;
    let collectionId = this.state.collectionId;
    point.isMock = isMock;
    const savedPoint = await this.props.actions.createPoint(
      point,
      this.state.additionalAuthors,
      this.state.collectionId,
    );

    //Remember the tags so user can easily re-use them next time
    this.props.actions.setRecentTags(savedPoint.tags); // This is currently driven by local storage

    // update localStorage
    localStorage.setItem('recentTags', JSON.stringify(savedPoint.tags));

    if (isProvidingEvidence) {
      const claim = await createClaim();
      collectionId = claim._id;
    }

    if (collectionId) {
      // Providing evidence while QTP/RTP should not update recentCollectionId
      if (!isProvidingEvidence) {
        localStorage.setItem(
          'recentCollectionId',
          JSON.stringify({
            _id: collectionId,
            isCollection: this.props.points[collectionId].point.isCollection,
          }),
        );
      }

      localStorage.setItem(
        'recentAdditionalAuthors',
        JSON.stringify(this.state.additionalAuthors),
      );

      const pointAddedToCollectionResult =
        await this.props.actions.saveSubPointToPoint(
          savedPoint._id,
          collectionId,
          stance,
          // this.props.timeStamp,
          undefined,
          this.props.selectedCaptions,
        );
    }

    this.props.actions.setRecentSourceInfo(savedPoint);

    if (!this.props.isExtension) {
      await this.props.afterPointCreated(
        savedPoint._id,
        this.props.dispatch,
        stance,
        collectionId,
        savedPoint,
      );
    } else {
      await this.props.afterPointCreated(savedPoint, collectionId);
    }

    const { tourStatus, tourStep } = this.props.walkthrough;

    if (tourStatus === 'inProgress' && tourStep === 9) {
      this.props.gotoNextStepAsync({
        user: this.props.user,
        step: 10,
      });
    }

    if (!this.props.isExtension) {
      toastByType('NEW_POINT_ADDED', {
        target: savedPoint,
        onViewCollection: showAddToCollection
          ? () => {
              const location = {
                pathname: `/collection/${collectionId}`,
              };
              dispatch(push(location));
            }
          : null,
        onViewPoint: () => {
          openPointModal(savedPoint);
        },
      });

      if (this.props.onClose) {
        this.props.onClose(false, savedPoint);

        // this.props.history.replace({
        //   search: `point=${point._id}`,
        // });
      }
    }
  };

  onClose = (e) => {
    this.props.onClose(false);
    if (window.parent) {
      window.close();
    }
  };

  setCollectionId = (collectionId) => {
    this.setState({
      collectionId,
    });
  };

  /**
   *
   * @param {string[]} additionalAuthors Array of additional authors ids
   */
  setAdditionalAuthors = (additionalAuthors) => {
    this.setState({ additionalAuthors });
  };

  onCreateCollection = async (collectionPoint) => {
    console.log('onCreateCollection in create point container');
    // return this.props
    //   .onAction(this.props.onCreateCollection, collectionPoint)
    //   .then(collectionPoint => {
    //     // Add the fact or point to the new collection
    //     this.setState({
    //       collectionId: collectionPoint._id,
    //     });
    //   })
    //   .catch(error => {
    //     this.setState({
    //       errorMessage: error.message, // How to display?
    //     });
    //   });
    const newCollectionPoint = await this.props.actions.createCollection(
      collectionPoint,
    );
    this.setCollectionId(newCollectionPoint._id);
    this.setAdditionalAuthors([]);
    return newCollectionPoint;
  };

  openCreateCollectionModal = () => {
    this.props.openCreateCollectionModal(this.afterNewCollectionCreated);
  };

  afterNewCollectionCreated = (collectionPoint) => {
    this.setState({
      collectionId: collectionPoint._id,
    });
  };

  render() {
    // const collectionId = this.props.collectionId
    //   ? this.props.collectionId
    //   : this.state.collectionId;
    return (
      <div className="point-start-container">
        <CreatePointComponent
          actions={this.props.actions}
          status={this.props.status} // same as point.type
          isExtension={this.props.isExtension}
          user={this.props.user}
          userHighlightType={this.props.userHighlightType}
          recentSourceInfo={this.props.recentSourceInfo}
          presetPointFields={this.props.presetPointFields}
          pointObject={this.props.pointObject}
          point={this.props.point} // TODO - check if this is used
          pointId={this.props.pointId}
          pointUrl={this.props.pointUrl}
          showAddToCollection={this.props.showAddToCollection}
          collectionId={this.state.collectionId}
          sourceText={this.props.sourceText}
          sourceImageURL={this.props.sourceImageURL}
          hostedImageURL={this.props.hostedImageURL}
          imageType={this.props.imageType}
          pdfThruBrowser={this.props.pdfThruBrowser}
          onCreateCollection={this.onCreateCollection}
          openCreateCollectionModal={this.openCreateCollectionModal}
          onClose={this.onClose}
          onSavePoint={this.onSavePoint}
          errorMessage={this.state.errorMessage}
          showStance={this.props.showStance}
          stance={this.props.stance}
          setCollectionId={this.setCollectionId}
          additionalAuthors={this.state.additionalAuthors}
          setAdditionalAuthors={this.setAdditionalAuthors}
          focusQuote={this.props.focusQuote}
          type={this.props.type}
          placeholder={this.props.placeholder}
          focusPointText={this.props.focusPointText}
          hideImportFromPortfolio={this.props.hideImportFromPortfolio}
          onImportPointTab={this.props.onImportPointTab}
          isExtensionCL={this.props.isExtensionCL}
          submitText={this.props.submitText}
        />
      </div>
    );
  }
}

CreatePointContainer.defaultProps = {
  afterPointCreated: () => {},
  focusQuote: false,
};

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;

  let collectionId = ownProps.collectionId;
  let additionalAuthors;

  let recentCollectionId;
  let recentIsCollection = true;

  try {
    let value = JSON.parse(localStorage.getItem('recentCollectionId'));

    if (value && value._id) {
      recentCollectionId = value._id;
      recentIsCollection = value.isCollection;
    }
  } catch (e) {
    console.log('Error in parsing recentCollectionId key from local storage.');
  }

  if (!collectionId && ownProps.showAddToCollection && recentCollectionId) {
    collectionId = recentCollectionId;
  }

  if (collectionId && collectionId === recentCollectionId) {
    try {
      const additionalAuthorsInStorage = JSON.parse(
        localStorage.getItem('recentAdditionalAuthors'),
      );
      if (additionalAuthorsInStorage)
        additionalAuthors = additionalAuthorsInStorage;
      // if (localStorage.hasOwnProperty('recentAdditionalAuthors')) {
      //   additionalAuthors = JSON.parse(
      //     localStorage.getItem('recentAdditionalAuthors'),
      //   );
      // }
    } catch (e) {
      console.log(
        'Error in parsing recentAdditionalAuthors key from local storage.',
      );
    }
  }

  if (collectionId && !additionalAuthors) {
    // let pointObject = state.points[collectionId];

    // if (pointObject) {
    //   let point = pointObject.point;
    //   additionalAuthors = point.authors
    //     .filter(i => i.invitationStatus === 'accepted')
    //     .map(i => i.userId)
    //     .filter(i => i !== user._id);
    // }

    additionalAuthors = [];
  }

  return {
    points: state.points,
    user,
    walkthrough: state.walkthrough,
    recentSourceInfo: state.page.recentSourceInfo,
    collectionId,
    isCollection: recentIsCollection,
    additionalAuthors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openErrorModal: (type, isExtension, message) => {
      let title = 'Error';
      const props = {
        model: {
          title,
          message,
          isExtension,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    openCreateCollectionModal: (afterNewCollectionCreated) => {
      const props = {
        afterNewCollectionCreated,
      };
      dispatch(Actions.openModal('create-collection', props));
    },
    openPointModal: (point) => {
      const props = {
        model: {
          point,
        },
      };

      dispatch(Actions.openModal('Point', props));
    },
    dispatch,
    gotoNextStepAsync: (payload) => dispatch(gotoNextStepAsync(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreatePointContainer));

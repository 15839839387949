import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Make sure REACT_APP_BASE_URL is set in env
const BASE_URL = process.env.PUBLIC_URL;

class Metatags extends Component {
  // static propTypes = {
  //   page: PropTypes.string,
  //   point: PropTypes.object,
  //   collectionPoint: PropTypes.object,
  // };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let helmetTitle;
    let helmetURL;
    let helmetDescription = '';
    const { page, point, collectionPoint } = this.props;

    switch (page) {
      case 'point':
        if (!point.text) point.text = '';
        if (!point.sourceText) point.sourceText = '';

        // const imageValid = (point.sourceImageURL.slice(-3) != "svg");

        const pageTitle = `${point.text.substring(0, 50)}...`;
        helmetTitle = `A Point by ${point.authorName}`;
        helmetURL = `${BASE_URL}/Point/${point._id}`;
        // helmetURL = window.location.origin + "/Point/"+point._id;

        helmetDescription =
          point.text.substring(0, 200) + (point.text.length > 200 ? '...' : '');

        if (helmetDescription.length < 200)
          helmetDescription += `"${point.sourceText.substring(0, 200)}"`;

        return (
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={point.text} />
            <meta property="fb:app_id" content="1668981423383827" />
            <meta property="twitter:card" content="summary" />
            <meta property="og:author" content={point.authorName} />
            <meta property="og:site_name" content="AverPoint" />
            <meta property="twitter:site" content="AverPoint" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={helmetURL} />
            <meta property="og:title" content={helmetTitle} />
            <meta property="twitter:title" content={helmetTitle} />
            <meta property="og:description" content={helmetDescription} />
            <meta property="twitter:description" content={helmetDescription} />
            <meta property="og:image" content={point.sourceImageURL} />
            {/* {
                        (imageValid) ?
                        <meta
                            property="og:image"
                            content={point.sourceImageURL}
                        />
                        :
                        ""
                    } */}
            <meta property="twitter:image" content={point.sourceImageURL} />
          </Helmet>
        );

      case 'collection':
        if (collectionPoint.collectionType === 'media') {
          helmetTitle = `${collectionPoint.sourceTitle} with Points`;

          let descriptions = [];
          if (collectionPoint.description) {
            // Need this because "All collectionPoint used to have no description"
            descriptions.push(collectionPoint.description);
          }

          if (collectionPoint.sourceDescription) {
            descriptions.push(collectionPoint.sourceDescription);
          }

          helmetDescription = descriptions.join(' ');
        } else {
          helmetTitle = `${collectionPoint.authorName}'s Collection: ${collectionPoint.text}`;
          if (collectionPoint.description)
            // Need this because "All collectionPoint used to have no description"
            helmetDescription =
              collectionPoint.description.substring(0, 200) +
              (collectionPoint.description.length > 200 ? '...' : '');

          if (
            collectionPoint.totalSubPoints &&
            collectionPoint.totalSubPoints > 0
          ) {
            helmetDescription += `with ${collectionPoint.totalSubPoints} Points and SubPoints`;
          }
        }
        helmetURL = `${BASE_URL}/Collection/${collectionPoint._id}`;

        return (
          <Helmet>
            <title>{helmetTitle}</title>
            <meta name="description" content={helmetDescription} />
            <meta property="fb:app_id" content="1668981423383827" />
            <meta property="twitter:card" content="summary" />
            <meta property="og:author" content={collectionPoint.authorName} />
            <meta property="og:site_name" content="AverPoint" />
            <meta property="twitter:site" content="AverPoint" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={helmetURL} />
            <meta property="og:title" content={helmetTitle} />
            <meta property="twitter:title" content={helmetTitle} />
            <meta property="og:description" content={helmetDescription} />
            <meta property="twitter:description" content={helmetDescription} />
            {collectionPoint.sourceImageURL ? (
              <meta
                property="og:image"
                content={collectionPoint.sourceImageURL}
              />
            ) : null}
          </Helmet>
        );
      case 'portfolio':
        helmetTitle = `${this.props.username}'s Collections`;
        helmetDescription = `All of ${this.props.username}'s Collections on AverPoint`;
        return (
          <Helmet>
            <title>{helmetTitle}</title>
            <meta name="description" content={helmetDescription} />
          </Helmet>
        );
      case 'schools':
        helmetTitle = 'Schools';
        helmetDescription = 'Schools';
        return (
          <Helmet>
            <title>{helmetTitle}</title>
            <meta name="description" content={helmetDescription} />
          </Helmet>
        );
      case 'readers':
        helmetTitle = 'Individuals';
        helmetDescription = 'Individuals';
        return (
          <Helmet>
            <title>{helmetTitle}</title>
            <meta name="description" content={helmetDescription} />
          </Helmet>
        );
      default:
        return null;
    }
  }
}

export default Metatags;

import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';
import _ from 'lodash';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import './AsyncAutoComplete.scss';
import { getLogo } from 'helpers/sourceIcon';

export default function AsyncAutoComplete(props) {
  const {
    value,
    onSelect,
    error,
    scopes = '',
    label = '',
    autoFocus = false,
    isChecked = null,
    quickOptions = [],
    onSearch,
  } = props;

  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(value);
  const [loading, setLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const searchOptions = async searchTerm => {
    if (!searchTerm || !searchTerm.trim() || searchTerm.length <= 1) {
      return;
    }

    try {
      setLoading(true);
      // setOptions([]);

      // const queryScopes = scopes ? `&scopes=${scopes}` : '';

      // const response = await axios({
      //   method: 'get',
      //   url: `${
      //     process.env.REACT_APP_SOURCE_PROVIDER_LAMBDA
      //   }/default?searchterm=${searchTerm}&type=source${queryScopes}`,
      // });
      const response = await onSearch(searchTerm);
      const options = response.data;

      if (!Array.isArray(options) || !options.length) {
        setOptions([]);
        // setSearchTerm(value);
        setLoading(false);
        return;
      }

      setOptions(options);
      setLoading(false);
      return;
    } catch (error) {
      console.error('Error while fetching suggestions', error);
      setOptions([]);
      setSearchTerm(value);
      setLoading(false);
    }
  };

  // Adds debounce before searching for options
  let searchFunction = useRef(debounce(500, searchOptions));

  // Cancels pending debounce on unmount
  useEffect(() => {
    return () => {
      if (searchFunction && searchFunction.current) {
        searchFunction.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    searchFunction.current = debounce(500, searchOptions);
  }, [scopes]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  // On outside click, close the options
  // const closeOptions = () => {
  //   setOptions([]);
  //   setSearchTerm(value);
  // };

  // On click of option, set the value and close options
  const selectOption = option => {
    if (isDisabled(option)) {
      return;
    }

    onSelect(option);
    setOptions([]);
    setSearchTerm(value);
    setOpenMenu(false);
  };

  // On search, call the debounce function
  const search = value => {
    setSearchTerm(value);
    if (searchFunction) {
      searchFunction.current(value);
    }
  };

  const isDisabled = option => {
    if (!isChecked) {
      return false;
    }

    return isChecked(option) || false;
  };

  function renderOptions() {
    if (!openMenu || !searchTerm.trim().length) {
      return null;
    }

    let opts = _.uniqBy(
      [
        ...quickOptions.filter(
          i => i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
        ),
        ...options,
      ],
      i => i.name,
    );

    if (!opts.length) {
      return null;
    }

    return (
      <React.Fragment>
        {/* <div className="async-auto-complete-backdrop" /> */}
        <div className="options-container">
          {opts
            .filter(option => option.name)
            .map(option => (
              <div
                key={option.name}
                className={
                  !isDisabled(option.name) ? 'option' : 'option disabled'
                }
                onClick={() => selectOption(option.name)}
              >
                <div className="source-icon-container">
                  {isDisabled(option.name) && <div className="overlay" />}
                  <img src={getLogo(option)} />
                </div>
                <div>{option.name}</div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="async-auto-complete">
      {loading && (
        <div
          className="fa fa-spin fa-spinner text-primary spinner"
          style={{ fontSize: '16px' }}
        />
      )}
      <FloatingInput
        autoFocus={autoFocus}
        value={searchTerm}
        placeholder={label || 'Start typing to get suggestions...'}
        error={error}
        onChange={e => search(e.target.value)}
        onFocus={() => {
          setOpenMenu(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setOpenMenu(false);
          }, 500);
        }}
      />
      {renderOptions()}
    </div>
  );
}

import moment from 'moment-timezone';

export function isMobile() {
  // console.log(`The userAgent is ${navigator.userAgent}`);
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    // console.log("Detected a mobile device or tablet.");
    return true;
  }
  // console.log("Not a mobile device or tablet - assuming it's a laptop or PC.");
  return false;
}

export function getBrowserType() {
  const browserResults = {};

  // browser detection logic is updated here: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769

  // Chrome 1 - 71
  // browserResults.isChrome =
  //   !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  // browserResults.isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
  browserResults.isChrome =
    navigator.userAgent.indexOf('Chrome') !== -1 &&
    navigator.userAgent.indexOf('Edg') === -1;

  // Firefox 1.0+
  browserResults.isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  browserResults.isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
        (typeof window.safari !== 'undefined' &&
          window.safari.pushNotification),
    );

  // Internet Explorer 6-11
  browserResults.isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Opera 8.0+
  browserResults.isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;

  // Edge 20+
  // browserResults.isEdge = !browserResults.isIE && !!window.StyleMedia;
  browserResults.isEdge =
    navigator.userAgent.indexOf('Chrome') !== -1 &&
    navigator.userAgent.indexOf('Edg') !== -1;

  // Blink engine detection
  browserResults.isBlink =
    (browserResults.isChrome || browserResults.isOpera) && !!window.CSS;

  return browserResults;
}

export function isMac() {
  return navigator.userAgent.indexOf('Mac OS X') != -1;
}

export function downloadTextAsFile(text, fileName) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
  );
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function generateHash(str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '000000' : 'FFFFFF';
  }

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  return r.padStart(2, '0') + g.padStart(2, '0') + b.padStart(2, '0');
}

export function isAndroid() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android/i.test(userAgent);
}

export function isIOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

export function convertCamelCase(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function showCount(count, label, hideIfZero = false) {
  if (hideIfZero && count === 0) {
    return null;
  }

  const plural = count > 1 ? 's' : '';
  return `${count} ${label}${plural}`;
}

export function replaceGoalText(goalDisplayAs, value) {
  return goalDisplayAs
    .replace('{{VALUE}}', value)
    .replace('(s)', value > 1 ? 's' : '');
}

export function offsetToTimeString(offset) {
  // Convert the offset to minutes
  const offsetMinutes = offset * 60;

  // Calculate hours and minutes
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;

  // Create the formatted string
  const sign = offsetMinutes < 0 ? '-' : '+';
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');

  return `${sign}${hoursStr}:${minutesStr}`;
}

export function adjustTimezone(date, timezone) {
  const offsetTimeString = offsetToTimeString(timezone || 0);

  return date.replace('Z', offsetTimeString);
}

export function adjustDateToUserTimezone(date) {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const momentZone = moment.tz.zone(currentTimezone);
  const currentTimezoneAbbr = momentZone
    ? momentZone.abbr(new Date().getTime())
    : '';

  return `${moment(date)
    .tz(currentTimezone)
    .format('MM/DD/YYYY hh:mm A')} ${currentTimezoneAbbr}`;
}

import * as Actions from "../actions/index";

export default function tags(
  state = {
    tags: [],
    topTags: [],
    featuredTags: []
  },
  action
) {
  switch (action.type) {
    case Actions.UPDATE_TAGS: {
      return Object.assign({}, state, {
        tags: action.tagObject.tags,
        timeStamp: action.tagObject.timeStamp
      });
    }
    case Actions.FEATURED_TAGS:
      return {
        ...state,
        featuredTags: action.tags
      };
    case Actions.TOP_TAGS:
      return {
        ...state,
        topTags: action.tags
      };
    default:
      return state;
  }
}

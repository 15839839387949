import React, { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import './LocationPicker.scss';
import { Icon, ICONS } from 'components/Icons';

const REACT_APP_GOOGLE_API_PLACES = process.env.REACT_APP_GOOGLE_API_PLACES;

export default function LocationPicker({
  onChange,
  autoFocus = false,
  value = {},
  label = '',
  isHome = false,
  isCurrent = false,
  required = false,
}) {
  const [input, setInput] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);

  const selectedValue =
    value && typeof value === 'object' && Object.keys(value).length > 0;

  function picker() {
    return (
      <div className="position-relative">
        <input
          ref={ref}
          type="text"
          className="form-control school-location"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={!selectedValue ? `${label}${required ? ' *' : ''}` : ''}
          autoFocus={autoFocus}
        />
        {selectedValue && (
          <Icon
            icon={ICONS.CLOSE}
            className="cancel-icon"
            onClick={() => {
              onLocationSelection(selectedPlace);
              setInput('');
              setSelectedPlace(null);
            }}
          />
        )}
        {selectedValue && <div className="selected-value">{showAddress()}</div>}
      </div>
    );
  }

  function onLocationSelection(address) {
    const addressData = { ...address };

    return onChange(addressData);
  }

  useEffect(() => {
    if (selectedPlace) {
      onLocationSelection(selectedPlace);
      setInput('');
      setSelectedPlace(null);
    }
  }, [selectedPlace]);

  const { ref } = usePlacesWidget({
    apiKey: REACT_APP_GOOGLE_API_PLACES,
    onPlaceSelected: (place) => {
      if (!place || !Array.isArray(place.address_components)) {
        return;
      }
      const address_components = place.address_components;

      const country = address_components.find(
        (c) => c.types.indexOf('country') > -1,
      );
      const state = address_components.find(
        (c) => c.types.indexOf('administrative_area_level_1') > -1,
      );
      const city = address_components.find(
        (c) =>
          c.types.indexOf('locality') > -1 ||
          c.types.indexOf('administrative_area_level_2') > -1,
      );

      const address = {
        id: new Date().getTime(),
        country: country ? country.long_name : '',
        state: state ? state.long_name : '',
        city: city ? city.long_name : '',
        countryCode: country ? country.short_name : '',
        isHome,
        isCurrent,
      };

      setSelectedPlace(address);
    },
  });

  const showLabel = () => {
    console.log('selectedValue', selectedValue, label);

    if (!selectedValue) {
      return null;
    }

    return (
      <div className="label">
        {label}
        {required && <span className="required-field">*</span>}
      </div>
    );
  };

  const showAddress = () => {
    return (
      <div className="show-address">
        {value.city}, {value.state}, {value.country}, {value.countryCode}
      </div>
    );
  };

  return (
    <div className="location-picker">
      {showLabel()}
      {picker()}
    </div>
  );
}

import React, { useEffect, useReducer, useState } from 'react';
import classnames from 'classnames';
import { isValidEmail } from 'helpers/validation';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './SubscribeNewsletter.scss';
import { Icon, ICONS } from 'components/Icons';

export default function SubscribeNewsletter({
  newsletter,
  showStatic,
  onSubscribe,
  closePanel = () => { },
  subscribed = () => { },
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_EMAIL':
          return {
            ...state,
            email: action.payload,
          };
        case 'SET_SUBMITTED':
          return {
            ...state,
            submitted: action.payload,
          };
        case 'SET_REGISTERED':
          return {
            ...state,
            registered: action.payload,
          };
        default:
          return state;
      }
    },
    {
      email: '',
      submitted: false,
      registered: false,
    },
  );

  const [error, setError] = useState('');

  async function onSubmit(e) {
    e.preventDefault();

    dispatch({ type: 'SET_SUBMITTED', payload: true });

    if (!isValidEmail(state.email)) {
      setError('Enter valid email');
      return;
    }

    await onSubscribe({
      email: state.email,
      newsletter,
    })
      .then(() => {
        dispatch({ type: 'SET_REGISTERED', payload: true });
        subscribed();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }

  useEffect(() => {
    if (state.email && error) {
      setError('');
    }
  }, [state.email]);

  function renderEmail() {
    const className = classnames({
      error: !!error,
    });
    return (
      <input
        type="text"
        placeholder="Email Address"
        className={className}
        onBlur={() => {
          if (!isValidEmail(state.email) && state.submitted) {
            setError('Enter valid email');
          }
        }}
        onChange={(e) =>
          dispatch({ type: 'SET_EMAIL', payload: e.target.value })
        }
      />
    );
  }

  function renderSubscribe() {
    return (
      <React.Fragment>
        <RectangularButton
          onClick={onSubmit}
          disabled={!!error}
          className="d-inline-flex justify-content-center subscribe-button"
        >
          Subscribe
        </RectangularButton>
      </React.Fragment>
    );
  }

  if (state.registered) {
    return (
      <div className="subscribe-newsletter">
        <div className="close-button" onClick={() => closePanel()}>
          <Icon icon={ICONS.CLOSE} />
        </div>
        <div className="container">
          <div className="row">
            <h4 className="title av-green text-center">
              Thanks for subscribing.
            </h4>
          </div>
        </div>
      </div>
    );
  }

  const mainClasses = classnames('subscribe-newsletter', {
    static: !!showStatic,
  });

  return (
    <div className={mainClasses}>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center main-container">
            <div className="d-flex align-items-center title-container">
              <svg
                width="24"
                height="25"
                viewBox="0 0 48 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0"
              >
                <path
                  d="M7.43695 48.2634H40.4972C44.7049 48.2634 47.9107 44.8572 47.9107 40.8499V23.0174C47.9107 21.8152 47.1092 21.0138 45.9071 21.0138H37.091V2.17943C37.091 1.17761 36.0892 0.175781 35.0873 0.175781H2.02709C0.824898 0.175781 0.0234375 1.17761 0.0234375 2.17943V40.8499C0.0234375 42.8536 0.824898 44.8572 2.22745 46.2598C3.63001 47.6623 5.4333 48.2634 7.43695 48.2634ZM37.091 25.0211H44.1038V40.8499C44.1038 42.8536 42.5009 44.2561 40.6976 44.2561C38.8943 44.2561 37.2914 42.6532 37.2914 40.8499V25.0211H37.091ZM9.4406 8.19039H26.8724C28.0746 8.19039 28.876 9.19221 28.876 10.194C28.876 11.1959 28.0746 12.1977 26.8724 12.1977H9.4406C8.23841 12.1977 7.43695 11.1959 7.43695 10.194C7.43695 9.19221 8.23841 8.19039 9.4406 8.19039ZM9.4406 17.4072H26.8724C28.0746 17.4072 28.876 18.409 28.876 19.4108C28.876 20.613 28.0746 21.4145 26.8724 21.4145H9.4406C8.23841 21.4145 7.43695 20.613 7.43695 19.4108C7.43695 18.409 8.23841 17.4072 9.4406 17.4072ZM9.4406 26.8243H26.8724C28.0746 26.8243 28.876 27.8262 28.876 28.828C28.876 29.8298 28.0746 30.8317 26.8724 30.8317H9.4406C8.23841 30.8317 7.43695 29.8298 7.43695 28.828C7.43695 27.8262 8.23841 26.8243 9.4406 26.8243ZM9.4406 36.2415H26.8724C28.0746 36.2415 28.876 37.043 28.876 38.2452C28.876 39.247 28.0746 40.2488 26.8724 40.2488H9.4406C8.23841 40.2488 7.43695 39.247 7.43695 38.2452C7.43695 37.2433 8.23841 36.2415 9.4406 36.2415Z"
                  fill="#C4DDF1"
                />
              </svg>
              <div className="ml-4">
                <h4 className="title">Join our Newsletter</h4>
              </div>
            </div>
            {state.registered ? (
              <h3 className="av-green text-center font-weight-700 m-0">
                Subscribed successfully.
              </h3>
            ) : (
              <div>
                <div className="form-container d-flex align-items-center">
                  <form onSubmit={(e) => onSubmit(e)}>
                    {renderEmail()}
                    {renderSubscribe()}
                  </form>
                </div>
                {error && <span className="error-message">{error}</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Row, Col } from "react-bootstrap";
import PointContainer from "../../../../../components/cards/Point/PointContainer.js";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

const MessageThread = props => {
  const {
    handleReviewSubmitted,
    verification,
    point,
    message,
    onChange,
    onSend,
    messageThread,
    user,
    displayReview,
    onAddPointInMessage
  } = props;
  const showMessageInput =
    user &&
    (user._id == point.authorId || user._id == displayReview.reviewerId);

  return (
    <div className="messenger-section">
      <div className="message-heading">Messages</div>
      <div className="message-list">
        <MessageList
          point={point}
          messageThread={messageThread}
          user={user}
          displayReview={displayReview}
        />
      </div>
      {showMessageInput && (
        <MessageInput
          point={point}
          message={message}
          onSend={onSend}
          onChange={onChange}
          user={user}
          displayReview={displayReview}
          onAddPoint={props.onAddPoint}
        />
      )}
    </div>
  );
};
export default MessageThread;

import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { Carousel } from 'react-bootstrap';

import get from 'lodash-es/get';

import CloudinaryImage from 'components/elements/molecules/Images/CloudinaryImage';
import CloudinaryLink from 'components/elements/molecules/Images/CloudinaryLink';
import { Icon, ICONS } from 'components/Icons';

import './pointImage.scss';

class PointImage extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    user: PropTypes.object,
    isEditing: PropTypes.bool,
    onPointFieldChanged: PropTypes.func,
    cardType: PropTypes.string,
    actions: PropTypes.object,
    isExtension: PropTypes.bool,
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = e => {
    if (e.data.type === 'point:capturescreen:success') {
      const { point, onPointFieldChanged } = this.props;
      const { sourceImages } = point;
      onPointFieldChanged('sourceImages', [
        e.data.data.imageUrl,
        ...sourceImages,
      ]);
    }
  };

  onCaptureScreen = () => {
    window.parent.postMessage({ type: 'point:capturescreen' }, '*');
  };

  renderCaouselControls() {
    const { point, onPointFieldChanged } = this.props;
    const { sourceImageActiveIndex } = point;
    let { sourceImages } = point;

    sourceImages = sourceImages.slice(0, 5);

    const carouselControlsClassName = classnames(
      'd-inline-flex align-items-center navigation justify-content-between mr-3',
    );

    const carouselPreviousClassName = classnames('pl-2 pr-2', {
      disabled: sourceImageActiveIndex - 1 < 0,
    });

    const carouselNextClassName = classnames('pl-2 pr-2', {
      disabled: sourceImageActiveIndex + 1 > sourceImages.length - 1,
    });

    return (
      <div className={carouselControlsClassName}>
        <Icon
          icon={ICONS.CHEVRON_LEFT}
          className={carouselPreviousClassName}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            const previousIndex = sourceImageActiveIndex - 1;

            if (previousIndex < 0) {
              return;
            }

            onPointFieldChanged('sourceImageURL', sourceImages[previousIndex]);
          }}
        />
        <Icon
          icon={ICONS.CHEVRON_RIGHT}
          className={carouselNextClassName}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            const nextIndex = sourceImageActiveIndex + 1;

            if (nextIndex > sourceImages.length - 1) {
              return;
            }

            onPointFieldChanged('sourceImageURL', sourceImages[nextIndex]);
          }}
        />
      </div>
    );
  }

  renderImageCarousel() {
    const { point, onPointFieldChanged, isExtension } = this.props;
    const { sourceImageActiveIndex } = point;
    let { sourceImages } = point;

    let carousel;

    if (sourceImages && sourceImages.length) {
      sourceImages = sourceImages.slice(0, 5);

      let carouselItems = sourceImages.map(imageUrl => {
        return (
          <Carousel.Item key={imageUrl}>
            <img src={imageUrl} className="image d-block ml-auto mr-auto" />
          </Carousel.Item>
        );
      });

      carousel = (
        <Carousel
          controls={false}
          interval={null}
          indicators={false}
          activeIndex={sourceImageActiveIndex}
        >
          {carouselItems}
        </Carousel>
      );
    } else {
      carousel = (
        <div className="carousel-placeholder text-center font-size-12">
          <Icon icon={ICONS.CAMERA} size={60} className="gray-3" />
          {point.sourceURL ? (
            <div className="gray-4 mt-4">
              We couldn't find any images on this page
            </div>
          ) : null}
          <div className="gray-4">
            {isExtension
              ? 'You can capture an area or upload an image.'
              : 'You can upload an image'}
          </div>
        </div>
      );
    }

    let captureArea;

    if (isExtension) {
      captureArea = (
        <div
          className="d-inline-flex align-items-center capture-area mr-3 justify-content-center"
          onClick={this.onCaptureScreen}
        >
          <Icon icon={ICONS.CAPTURE_AREA} size={24} />
        </div>
      );
    }

    const id = point._id || new Date().getTime();

    const photoId = `points/${id}`;
    let uploadPicture = (
      <CloudinaryLink
        imageExists={false}
        cloudinaryImageId={photoId}
        onLicenseTermsAccepted={this.onLicenseTermsAccepted}
        className="d-inline-flex align-items-center capture-area mr-3 justify-content-center"
      >
        <Icon icon={ICONS.UPLOAD_PICTURE} />
      </CloudinaryLink>
    );

    return (
      <div className="position-relative">
        {carousel}
        <div className="d-flex align-items-center position-absolute w-100 controls p-3">
          {this.renderCaouselControls()}
          {captureArea}
          {uploadPicture}
          <div
            className="d-inline-flex align-items-center close-image ml-auto justify-content-center"
            onClick={event => {
              event.stopPropagation();
              onPointFieldChanged('showPhoto', false);
            }}
          >
            <Icon icon={ICONS.CLOSE} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isEditing } = this.props;
    const className = classnames('point-image', this.props.className);

    if (isEditing) {
      return <div className={className}>{this.renderImageCarousel()}</div>;
    } else {
      if (
        !this.props.point.sourceImageURL &&
        !this.props.point.hostedImageURL
      ) {
        return null;
      }

      const image = this.renderImage();

      if (image) {
        return <div className={className}>{image}</div>;
      }
    }

    return null;
  }

  renderImage() {
    const { user, point } = this.props;

    const isOwner = user._id === point.authorId;
    const userShowPic =
      get(user, 'settings.reading.picture', 'show') === 'show';
    let shouldShowPic = true;
    if (isOwner) {
      shouldShowPic = point.showPhoto;
    } else {
      shouldShowPic = point.showPhoto && userShowPic;
    }

    if (!shouldShowPic) return null;

    if (point.imageType === 'source') {
      return (
        <img
          src={point.sourceImageURL}
          alt="From the source"
          className="image d-block mr-auto ml-auto"
        />
      );
    }

    const id = point._id || new Date().getTime();

    // If there's no sourceImage, go for a user added image - if it exists
    const photoId = `points/${id}`;

    const licenseTermsAccepted =
      point.photoLicenseTerms &&
      (point.photoLicenseTerms.status == 'accepted' ||
        point.photoLicenseTerms.acceptTerms);
    if (!licenseTermsAccepted) return null;

    const className = 'image-wrapper';

    let height = 200;
    let width = 300;
    let cropType = 'fit';

    if (this.props.cardType == 'page' || this.props.cardType == 'full-screen') {
      const wH = window.innerHeight;
      const wW = window.innerWidth;
      height = 300;
      width = 300;

      if (wW >= 1281) {
        height = 900;
        width = 900;
      } else if (wW >= 1025) {
        height = 800;
        width = 800;
      } else if (wW >= 800) {
        height = 700;
        width = 700;
      } else if (wW >= 600) {
        height = 450;
        width = 450;
      } else if (wW >= 480) {
        height = 450;
        width = 450;
      }

      cropType = 'limit';
    }

    return (
      <div className="position-relative">
        <CloudinaryImage
          cloudinaryImageId={photoId}
          cloudinaryVersion={point.hostedImageVersion}
          handleClick={null}
          allowUserToChangePic={false}
          photoOwnerId={point.authorId}
          height={height}
          width={width}
          cropType={cropType}
          photoLicenseTerms={point.photoLicenseTerms}
          imageClass="image d-block mr-auto ml-auto"
        />
        {this.renderImageFullScreenAction()}
      </div>
    );
  }

  renderImageCloseAction() {
    const { point, isEditing, onPointFieldChanged } = this.props;

    if (!isEditing) return null;

    return (
      point.showPhoto && (
        <Icon
          onClick={() => onPointFieldChanged('showPhoto', false)}
          icon={ICONS.CLOSE}
        />
      )
    );
  }

  renderImageEditAction() {
    const { point, isEditing, onPointFieldChanged } = this.props;

    // if (!isEditing) return null;

    const id = point._id || new Date().getTime();

    const photoId = `points/${id}`;

    return (
      point.showPhoto && (
        <CloudinaryLink
          imageExists={false}
          cloudinaryImageId={photoId}
          onLicenseTermsAccepted={this.onLicenseTermsAccepted}
        >
          <Icon
            onClick={() => onPointFieldChanged('showPhoto', false)}
            icon={ICONS.PENCIL}
          />
        </CloudinaryLink>
      )
    );
  }

  onLicenseTermsAccepted = (terms, hostedImageURL, hostedImageVersion) => {
    const hostedImage = {
      hostedImageURL,
      hostedImageVersion,
      photoLicenseTerms: terms,
      imageType: 'hosted',
    };
    this.props.onPointFieldChanged('hostedImage', hostedImage);
  };

  renderImageFullScreenAction() {
    const { point, isEditing, cardType } = this.props;

    if (isEditing) return null;
    if (cardType === 'full-screen') return null;

    return (
      point.showPhoto && (
        <Icon
          onClick={this.showFullScreen}
          icon={ICONS.FULLSCREEN}
          className="position-absolute"
          style={{ top: '5px', right: 0 }}
        />
      )
    );
  }

  showFullScreen = e => {
    e.stopPropagation();
    const props = {
      point: this.props.point,
      user: this.props.user,
    };
    this.props.actions.openModal('full-picture', props);
  };
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
    openAddPictureModal: (
      cloudinaryImageId,
      onLicenseTermsAccepted,
      originalURL,
      hostedImageURL,
      hostedImageVersion,
    ) => {
      const props = {
        cloudinaryImageId,
        onLicenseTermsAccepted,
        originalURL,
        hostedImageURL,
        hostedImageVersion,
        model: {
          isExtensionModal: false,
        },
      };
      dispatch(Actions.openModal('full-picture', props));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointImage);

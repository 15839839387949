import React from "react";
import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";

const StepFinal = ({ onComplete }) => (
  <div>
    <p>
      That’s the end of the tour! Be sure to explore features like
      <ul>
        <li>Invite friends and co-workers to research and review</li>
        <li>
          Share a <b>Point</b> on social media
        </li>
        <li>Fact-checking from your private network and public audience</li>
      </ul>
      <br />
      Welcome to AverPoint and, remember… There is a <b>Point</b>!
    </p>
    <br />
    <RoundedButton label="Finish tour!" onClick={onComplete} />
  </div>
);

export default StepFinal;

import React from 'react';
import classnames from 'classnames';

import QuestionText from './QuestionText';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

export default function SingleChoiceQuestion({ question, onChange, model }) {
  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderButton(option, key) {
    const value = model[question.variable];
    const className = classnames('mr-3', { active: value === option.text });
    return (
      <RectangularButton
        className={className}
        onClick={() => onChange(option.text)}
        key={key}
      >
        {option.text}
      </RectangularButton>
    );
  }

  function renderButtons() {
    return (
      <div className="mt-3">
        {question.options.map((option, index) => renderButton(option, index))}
      </div>
    );
  }

  return (
    <div className="single-choice-button">
      {renderText()}
      {renderButtons()}
    </div>
  );
}

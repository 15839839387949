import * as Actions from 'redux/actions/index';

const initialState = {
  mediaLogs: {},
  logs: [],
  graphData: {},
  mediaChannel: {},
  curatedArticles: {
    today: {},
    week: {},
    month: {},
  },
  showChannelPageQuiz: false,
};

export default function mediaLogs(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_ANALYZE_MEDIA_LOGS: {
      return {
        ...state,
        mediaLogs: action.mediaLogs,
      };
    }
    case Actions.LOAD_LAST_WEEK_ANALYZE_MEDIA_LOGS: {
      return {
        ...state,
        lastWeekMediaLogs: action.mediaLogs,
      };
    }
    case Actions.LOAD_READING_HISTORY: {
      return {
        ...state,
        logs: action.logs,
      };
    }
    case Actions.LOAD_GRAPH_DATA: {
      const currentGraphData = JSON.parse(JSON.stringify(state.graphData));

      currentGraphData[action.timeRange] = action.graphData;
      return {
        ...state,
        graphData: currentGraphData,
      };
    }
    case Actions.LOAD_MEDIA_CHANNEL: {
      return {
        ...state,
        mediaChannel: action.mediaChannel,
      };
    }
    case Actions.LOAD_CURATED_ARTICLES: {
      return {
        ...state,
        curatedArticles: {
          ...state.curatedArticles,
          [action.range]: action.articles,
        },
      };
    }
    case Actions.LOAD_PAST_TALKS: {
      return {
        ...state,
        pastTalks: {
          ...(state.pastTalks || {}),
          ...action.pastTalks,
        },
      };
    }
    case Actions.LOAD_ARTICLES_FOR_CLASSIFICATION: {
      return {
        ...state,
        articlesForClassification: [
          ...action.articles,
        ],
      };
    }
    case Actions.REMOVE_ARTICLE_FROM_CLASSIFICATION: {
      let articlesForClassificationClone = JSON.parse(JSON.stringify(state.articlesForClassification));
      articlesForClassificationClone = articlesForClassificationClone.filter((article) => article._id !== action.articleId);

      return {
        ...state,
        articlesForClassification: articlesForClassificationClone,
      };
    }
    case Actions.UPDATE_NOTE_ARTICLE_FROM_CLASSIFICATION: {
      let articlesForClassificationClone = JSON.parse(JSON.stringify(state.articlesForClassification));
      const articleForClassification = articlesForClassificationClone.find((article) => article._id === action.articleId);

      articleForClassification.adminNotes = [{ note: action.note, isResolved: false }];

      return {
        ...state,
        articlesForClassification: articlesForClassificationClone,
      };
    }
    case Actions.UPDATE_APPROVED_FOR_NEWS_FEED: {
      let articlesForClassificationClone = JSON.parse(JSON.stringify(state.articlesForClassification));
      const articleForClassification = articlesForClassificationClone.find((article) => article._id === action.articleId);

      articleForClassification.isApprovedForNewsFeed = true;

      return {
        ...state,
        articlesForClassification: articlesForClassificationClone,
      };
    }
    case Actions.UPDATE_CURATED_ARTICLE: {
      const newState = {
        ...state,
      };

      if (newState.curatedArticles) {
        const curatedArticles = newState.curatedArticles;

        Object.keys(curatedArticles).forEach((range) => {
          Object.keys(curatedArticles[range]).forEach((topic) => {
            const articles = curatedArticles[range][topic];
            const index = articles.findIndex(
              (i) => i._id === action.article._id,
            );

            if (index !== -1) {
              curatedArticles[range][topic] = [...articles];
              curatedArticles[range][topic][index] = {
                ...articles[index],
                ...action.article,
              };
            }
          });
          curatedArticles[range] = { ...curatedArticles[range] };
        });

        newState.curatedArticles = { ...curatedArticles };
      }

      return newState;
    }
    case Actions.UPDATE_ARTICLE_OF_DAY: {
      const newState = {
        ...state,
      };

      if (newState.curatedArticles) {
        const curatedArticles = newState.curatedArticles;

        Object.keys(curatedArticles).forEach((range) => {
          Object.keys(curatedArticles[range]).forEach((topic) => {
            const articles = curatedArticles[range][topic];
            const index = articles.findIndex(
              (i) => i._id === action.article._id,
            );

            if (index !== -1) {
              curatedArticles[range][topic] = [...articles];
              curatedArticles[range][topic][index] = {
                ...articles[index],
                isArticleOfDay: action.article.status,
              };
            }
          });
          curatedArticles[range] = { ...curatedArticles[range] };
        });

        newState.curatedArticles = { ...curatedArticles };
      }

      return newState;
    }
    case Actions.REMOVE_COMMUNITY_PICK_ARTICLE: {
      const newState = {
        ...state,
      };

      if (
        newState.mediaChannel &&
        newState.mediaChannel.communityPickArticles
      ) {
        const communityPickArticles =
          newState.mediaChannel.communityPickArticles.filter(
            (a) => a._id !== action.pointId,
          );

        newState.mediaChannel = {
          ...newState.mediaChannel,
          communityPickArticles,
        };
      }

      return newState;
    }
    case Actions.RECLASSIFY_COMMUNITY_PICK_ARTICLE: {
      const newState = {
        ...state,
      };

      if (
        newState.mediaChannel &&
        newState.mediaChannel.communityPickArticles
      ) {
        const communityPickArticles =
          newState.mediaChannel.communityPickArticles.map((a) => ({
            ...a,
            sourceAnalytics:
              a._id !== action.pointId
                ? a.sourceAnalytics
                : action.sourceAnalytics,
          }));

        newState.mediaChannel = {
          ...newState.mediaChannel,
          communityPickArticles,
        };
      }

      return newState;
    }
    case Actions.UPDATE_CHANNEL_ARTICLE_QUESTIONS: {
      const newState = {
        ...state,
      };

      if (
        newState.mediaChannel &&
        newState.mediaChannel.topStories
      ) {
        const topStories =
          newState.mediaChannel.topStories.map((a) => ({
            ...a,
            quizQuestions:
              a._id !== action.pointId
                ? (a.quizQuestions || [])
                : action.quizQuestions,
          }));

        newState.mediaChannel = {
          ...newState.mediaChannel,
          topStories,
        };
      }

      return newState;
    }
    case Actions.TOGGLE_CHANNEL_PAGE_QUIZ: {
      return {
        ...state,
        showChannelPageQuiz: action.showChannelPageQuiz,
      };
    }
    case 'MEDIA_LOGGED': {
      const mediaLog = action.mediaLog;

      let newAnalyzedMediaLogs = state.mediaLogs;

      newAnalyzedMediaLogs.numArticlesRead++;

      newAnalyzedMediaLogs = handleNewMediaLog(
        newAnalyzedMediaLogs,
        mediaLog,
        'source',
        'sourceName',
      );

      // const existingSourceIndex = newAnalyzedMediaLogs.sources.findIndex(s => s.name === mediaLog.sourceName);
      // if (existingSourceIndex === -1) {
      //   newAnalyzedMediaLogs.sources.push({
      //     name: mediaLog.sourceName,
      //     number: 1
      //   });
      // } else {
      //   newAnalyzedMediaLogs.sources[existingSourceIndex].number++;
      // }

      if (!['No topic', 'None'].includes(mediaLog['topic'])) {
        newAnalyzedMediaLogs = handleNewMediaLog(
          newAnalyzedMediaLogs,
          mediaLog,
          'topic',
          'topic',
        );
      }

      // const existingTopicIndex = newAnalyzedMediaLogs.topics.findIndex(s => s.name === mediaLog.topic);
      // if (existingTopicIndex === -1) {
      //   newAnalyzedMediaLogs.topics.push({
      //     name: mediaLog.topic,
      //     number: 1
      //   });
      // } else {
      //   newAnalyzedMediaLogs.topics[existingTopicIndex].number++;
      // }

      if (mediaLog['geographicScope'] !== 'No geographic scope') {
        newAnalyzedMediaLogs = handleNewMediaLog(
          newAnalyzedMediaLogs,
          mediaLog,
          'geographicScope',
          'geographicScope',
        );
      }

      // const existingGeographicScopeIndex = newAnalyzedMediaLogs.geographicScopes.findIndex(s => s.name === mediaLog.geographicScope);
      // if (existingGeographicScopeIndex === -1) {
      //   newAnalyzedMediaLogs.geographicScopes.push({
      //     name: mediaLog.geographicScope,
      //     number: 1
      //   });
      // } else {
      //   newAnalyzedMediaLogs.geographicScopes[existingGeographicScopeIndex].number++;
      // }

      return {
        ...state,
        mediaLogs: {
          ...newAnalyzedMediaLogs,
        },
        logs: [...state.logs, mediaLog],
      };
    }
    default:
      return state;
  }
}

const handleNewMediaLog = (analyzedMediaLogs, mediaLog, item, logField) => {
  if (!analyzedMediaLogs[`${item}s`]) {
    analyzedMediaLogs[`${item}s`] = [];
  }
  const existingItemIndex = analyzedMediaLogs[`${item}s`].findIndex(
    (i) => i.name === mediaLog[logField],
  );

  if (existingItemIndex === -1) {
    analyzedMediaLogs[`${item}s`].push({
      name: mediaLog[logField],
      number: 1,
    });
  } else {
    analyzedMediaLogs[`${item}s`][existingItemIndex].number++;
  }

  return analyzedMediaLogs;
};

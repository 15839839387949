/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MenuItem, Dropdown } from 'react-bootstrap';
// import ReactTimeAgo from "react-time-ago";

import PointReviewHeader from 'components/cards/Point/Component/PointReviewHeader/PointReviewHeader';
import PointActions from 'components/cards/Point/Component/PointHeader/PointDropDown/PointActions/PointActions';
import * as Actions from 'redux/actions';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import InviteResponse from './../../../../elements/organisms/InviteResponse/InviteResponse';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import { Icon, ICONS } from 'components/Icons';
// import EditableText from 'components/elements/atoms/EditableComponent/EditableComponent';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
// import { timestamp } from 'cloudinary/lib/utils';

const REACT_APP_NEW_TAG_TIME_DIFF = process.env.REACT_APP_NEW_TAG_TIME_DIFF;
// const REACT_APP_NUM_DAYS_FOR_SUBPOINT_TIMESTAMP =
//   process.env.REACT_APP_NUM_DAYS_FOR_SUBPOINT_TIMESTAMP;
const REACT_APP_NO_TRANSCRIPT_DURATION =
  process.env.REACT_APP_NO_TRANSCRIPT_DURATION;

class PointHeader extends React.Component {
  static propTypes = {
    point: PropTypes.object.isRequired,
    cardType: PropTypes.oneOf([
      'default',
      'preview',
      'preview highlight-on-hover',
      'embed',
      'page',
      'list',
      'page-sub-point',
      'outline',
    ]),
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    onPointFieldChanged: PropTypes.func,
    errorMessages: PropTypes.object,
    isEditing: PropTypes.bool,
    isEditingFields: PropTypes.object,
    onToggleEdit: PropTypes.func,
    onEditPointField: PropTypes.func,
    onSavePoint: PropTypes.func,
    allowRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    actions: PropTypes.object,
    subPointInfo: PropTypes.object,
    subPointInfoPermissionLevel: PropTypes.string,
    isInvited: PropTypes.bool,
    onClickTimeStamp: PropTypes.func,
    onTimeStampChange: PropTypes.func,
    showTimeStamp: PropTypes.bool,
    parentObject: PropTypes.object,
    showReviews: PropTypes.bool,
    onReviewPoint: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      showHoverBox: false,
    };
  }

  getTimeStampString(timeStamp) {
    let seconds = timeStamp % 60;
    let minutes = parseInt(timeStamp / 60, 10);
    let hours = 0;

    if (minutes >= 60) {
      hours = parseInt(hours / 60, 10);
      minutes = minutes % 60;
    }

    let str = '';

    if (hours > 0) {
      str += `${hours.toString().padStart(2, '0')}:`;
    }

    str += `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    return str;
  }

  renderActions() {
    const { isInvited, cardType } = this.props;

    if (isInvited || cardType === 'preview') {
      return null;
    }

    return (
      <div className="d-flex align-items-center ml-auto">
        {/* {this.renderReviewHeader()} */}
        <div className="ml-3" onClick={(e) => e.stopPropagation()}>
          {/* {this.renderReviewButton()} */}
          {this.renderImportButton()}
        </div>
        {this.renderLockIcon()}
        {this.renderMenu()}
      </div>
    );
  }

  renderNewTag() {
    const { point, isEditing } = this.props;

    if (isEditing) {
      return;
    }

    if (
      new Date() - new Date(point.updatedAt) <
      REACT_APP_NEW_TAG_TIME_DIFF * 60 * 60 * 1000
    ) {
      return (
        <span className="badge new-badge pt-2 pb-2 pl-3 pr-3 mr-3">NEW</span>
      );
    }
  }

  renderTimestamp() {
    const {
      isEditingFields,
      onClickTimeStamp,
      onTimeStampChange,
      showTimeStamp,
      point,
      cardType,
      onPointFieldChanged,
    } = this.props;

    if (cardType === 'outline') {
      return null;
    }

    if (!showTimeStamp) {
      return null;
    }

    let timeStamp = point.sourceTimeStampStart;

    if (timeStamp !== undefined) {
      timeStamp = `${this.getTimeStampString(parseInt(timeStamp, 10))}`;

      // if (!point.text) {
      //   timeStamp += ` - ${this.getTimeStampString(
      //     parseInt(
      //       point.sourceTimeStampStart + +REACT_APP_NO_TRANSCRIPT_DURATION,
      //     ),
      //   )}`;
      // }
    }

    let close;

    if (timeStamp && this.props.isEditingFields.sourceTimeStampStart) {
      close = (
        <Icon
          icon={ICONS.CLOSE}
          className="av-red ml-3"
          onClick={(e) => {
            e.stopPropagation();
            onPointFieldChanged('sourceTimeStampStart', undefined);
          }}
        />
      );
    }

    let timeStampElm;

    if (isEditingFields.sourceTimeStampStart) {
      timeStampElm = (
        <input
          defaultValue={timeStamp}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => (e = onTimeStampChange(e.target.value))}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onTimeStampChange(e.target.value);
            }
          }}
          placeholder="hh:mm:ss"
          ref={(input) => {
            // need timeout otherwise it looses focus
            setTimeout(() => {
              input && input.focus();
            }, 100);
          }}
        />
      );
    } else if (point.sourceTimeStampStart !== undefined) {
      timeStampElm = <span onClick={onClickTimeStamp}># {timeStamp}</span>;
    } else {
      timeStampElm = <span>No timestamp</span>;
    }

    return (
      <div className="d-inline-flex align-items-center timestamp">
        {/* <Icon icon={ICONS.TIMECODES} className="gray-2 mr-2" size={24} /> */}
        <div
          className="av-blue d-inline-flex align-items-center"
          style={{ cursor: 'pointer' }}
        >
          {timeStampElm}
          {close}
        </div>
      </div>
    );
  }

  renderAuthor() {
    const { cardType, point } = this.props;

    if (cardType === 'claim' || cardType === 'top-evidence') {
      return null;
    }

    let moreAuthorsCount;

    if (point.authors.length > 1) {
      moreAuthorsCount = (
        <div className="more-authors-count d-inline-flex align-items-center bg-av-light-blue font-size-12 font-weight-bold av-blue text-center">
          +{point.authors.length - 1}
        </div>
      );
    }

    return (
      <React.Fragment>
        <ProfileImage
          userId={point.authorId}
          allowUserToChangePic={false}
          allowClickToPortfolio
          username={point.authorName}
          hostedImageVersion={point.authorHostedImageVersion}
          size={24}
        />
        {moreAuthorsCount}
      </React.Fragment>
    );
  }

  render() {
    // const isEditing =
    //   this.props.isEditing || this.props.isEditingFields.timeStamp;

    const className = classnames('point-header d-flex align-items-center', {
      // 'bg-av-light-gray': this.props.cardType !== 'page' && !isEditing,
      // 'bg-av-light-gray': this.props.cardType !== 'page',
    });

    return (
      <div className={className}>
        {/* {this.renderNewTag()} */}
        {this.renderTimestamp()}
        {this.renderAuthor()}
        {this.renderTopEvidenceAuthors()}
        {!this.props.hideHeader && (
          <div className="d-inline-flex align-items-center ml-auto actions">
            {this.renderInvite()}
            {this.renderActions()}
            {this.renderSaveCancelChanges()}
          </div>
        )}
      </div>
    );
  }

  renderTopEvidenceAuthors() {
    const { cardType, point, subPointInfo } = this.props;

    if (cardType !== 'evidence' && cardType !== 'top-evidence') {
      return null;
    }

    if (!subPointInfo) {
      return null;
    }

    let poster,
      author,
      stance = '';

    if (subPointInfo.stance === 'For') {
      stance = 'Supported';
    } else if (subPointInfo.stance === 'Against') {
      stance = 'Opposed';
    }

    const stanceClassName = classnames('font-weight-600 ml-3', {
      'av-accent-cyan': subPointInfo.stance === 'For',
      'av-red': subPointInfo.stance === 'Against',
    });

    author = (
      <React.Fragment>
        <ProfileImage
          userId={point.authorId}
          allowUserToChangePic={false}
          allowClickToPortfolio
          username={point.authorName}
          // hostedImageVersion={point.authorHostedImageVersion}
          size={24}
        />
        <span className={stanceClassName}>{stance}</span>
      </React.Fragment>
    );

    if (subPointInfo && subPointInfo.postedBy !== point.authorId) {
      poster = (
        <React.Fragment>
          <ProfileImage
            userId={subPointInfo.postedBy}
            allowUserToChangePic={false}
            allowClickToPortfolio
            username={subPointInfo.postedByName}
            // hostedImageVersion={point.authorHostedImageVersion}
            size={24}
          />
          <Icon
            icon={ICONS.RETWEET_ARROWS}
            size={18}
            className="ml-3 mr-3 gray-3"
          />
        </React.Fragment>
      );
    }

    return (
      <div className="top-evidence-title d-flex align-items-center font-size-12">
        {poster}
        {author}
      </div>
    );
  }

  renderReviewButton = () => {
    const { point, user, isEditing, cardType } = this.props;

    if (
      isEditing ||
      (point.type !== 'Fact' && point.type !== 'Opinion') ||
      point.authorId === user._id ||
      (cardType !== 'default' && cardType !== 'embed' && cardType !== 'page') ||
      cardType === 'outline'
    ) {
      return null;
    }

    // Don't show review link in page, preview, or embed
    const style = {
      borderRadius: '0',
    };
    let className = 'hover-action p-2 mr-2';
    const toolTipText = this.props.authenticated
      ? "Review this Point's language, factual backup, and sources"
      : 'Login to Review this Point';

    if (!this.props.authenticated) className += ' disabled';

    return (
      <RoundedButton
        mainClassName="point-review-button-container hover-action"
        className={className}
        label="Review"
        onClick={this.onReviewPoint}
        disabled={!this.props.authenticated}
        title={toolTipText}
        style={style}
      />
    );
  };

  onReviewPoint = (e) => {
    e.stopPropagation();
    this.props.onReviewPoint();
  };

  renderInvite() {
    const { isInvited, cardType, point } = this.props;

    if (
      cardType === 'page' ||
      cardType === 'preview' ||
      !isInvited ||
      cardType === 'outline'
    ) {
      return null;
    }

    return (
      <div
        className="flex-fill"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <InviteResponse source="POINT" pointId={point._id} />
      </div>
    );
  }

  renderReviewHeader = () => {
    const { isEditing, showReviews, cardType } = this.props;
    if (!showReviews || isEditing || cardType === 'outline') return null;

    return (
      <PointReviewHeader
        point={this.props.point}
        user={this.props.user}
        totalReviews={this.props.totalReviews}
        userPointReview={this.props.userPointReview}
        toggleReviewModal={this.props.toggleReviewModal}
        errorMessages={this.props.errorMessages}
      />
    );
  };

  renderImportButton() {
    const { isEditing, showImport, cardType } = this.props;

    if (isEditing || !showImport || cardType === 'outline') {
      return null;
    }

    return (
      <RoundedButton
        mainClassName="point-save-button-container hover-action"
        className="ml-3"
        label="Import this point"
        onClick={this.props.onImport}
        title=""
      />
    );
  }

  renderSaveCancelChanges() {
    const { isEditing, cardType } = this.props;

    if (!isEditing || cardType === 'preview' || cardType === 'outline') {
      return null;
    }

    return (
      <React.Fragment>
        <RoundedButton
          mainClassName=""
          className="btn-sm green"
          label="Save Changes"
          onClick={this.props.onSavePoint}
        />
        <RoundedButton
          mainClassName=""
          className="ml-3 btn-sm red"
          label="Cancel"
          onClick={this.props.onToggleEdit}
        />
      </React.Fragment>
    );
  }

  // onSaveToCollection(e) {
  //   e.stopPropagation();
  //   // if (!this.props.authenticated) return;
  //   this.props.openAddToCollectionModal(this.props.point._id, success => {});
  // }

  async onUpdatePublishOption() {
    await this.props.actions.publishPoint(this.props.point._id);
  }

  renderEditIcon = () => {
    if (this.props.point.status !== 'private') return null;

    if (this.props.isEditing) return null;

    return (
      <ActionLink
        onClick={this.onEdit}
        linkClassName="editIcon"
        iconType="averPointIcon"
        iconName="PENCIL"
        label=""
        toolTipText="Click to edit this Point's text, source, etc."
      />
    );
  };

  onEdit = () => {
    this.props.onToggleEdit();
  };

  renderEditMessage = () => {
    if (!this.props.isEditing) return null;

    return (
      <ActionLink
        onClick={this.props.onToggleEdit}
        linkClassName="editMessage"
        label="Finish editing"
        toolTipText="Click to edit this Point's text, source, etc."
      />
    );
  };

  renderLockIcon = () => {
    const { isEditing, point, isAuthor, cardType } = this.props;

    if (isEditing || point.status !== 'private' || cardType === 'outline') {
      return null;
    }

    return (
      <Dropdown
        id="collection-header-dropdown"
        pullRight
        className="ml-3 more-menu"
        onClick={(e) => e.stopPropagation()}
        disabled={!isAuthor}
      >
        <Dropdown.Toggle noCaret>
          <ToolTip
            toolTipText="This Point is private. Click to see publish options."
            toolTipPosition="left"
          >
            <Icon icon={ICONS.LOCK} className="m-0" />
          </ToolTip>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onClick={() => this.onUpdatePublishOption()}>
            <div className="pt-2 pb-2">
              <h5 className="av-black mb-2 mt-0">Publish Point</h5>
              <div
                className="gray-4 body-medium"
                style={{ width: '230px', whiteSpace: 'break-spaces' }}
              >
                Make this Point public (This will not affect Sub-points)
              </div>
            </div>
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderMenu() {
    const { point, hideMenu, isEditing } = this.props;

    if (isEditing || hideMenu) {
      return null;
    }

    return (
      // <div className="ml-3">
      <PointActions
        // pointId={point._id}
        point={point}
        onPointFieldChanged={this.onPointFieldChanged}
        onEdit={this.onEdit}
        isEditing={this.props.isEditing}
        onEditPointField={this.props.onEditPointField}
        toggleEditPoint={this.props.onToggleEdit} // Don't show edit/save button
        isSaved
        allowImageChange={false}
        allowRemove={this.props.allowRemove}
        onRemove={this.props.onRemove}
        user={this.props.user}
        subPointInfoPermissionLevel={this.props.subPointInfoPermissionLevel}
        className="ml-3"
        parentObject={this.props.parentObject}
        moreHeaderActions={this.props.moreHeaderActions}
      />
      // </div>
    );
  }

  onRespondInvite = () => {
    const { pointObject } = this.props;
    if (pointObject.invitationStatus == 'invited') {
      this.props.openAcceptInviteToPointModal(pointObject.point);
    }
  };
}

function mapStateToProps(state, ownProps) {
  const { point } = ownProps;
  let pointObject, isAuthor;

  if (point._id) {
    pointObject = state.points[point._id];
    // isAuthor =
    //   pointObject.invitationStatus === 'accepted' &&
    //   pointObject.permissionLevel === 'write';
    isAuthor = pointObject && pointObject.isAuthor;
  }

  return {
    pointObject,
    isAuthor,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    // openAddToCollectionModal: (pointId, onResponse) => {
    //   const props = {
    //     model: { pointId },
    //     onResponse,
    //   };
    //   dispatch(Actions.openModal('add-to-collection', props));
    // },
    openAcceptInviteToPointModal: (point) => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal('respond-invite-point', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PointHeader);

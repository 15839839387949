import React from "react";
import ReviewFilter from "./ReviewFilter/ReviewFilter";
import ReviewListItem from "./ReviewListItem/ReviewListItem";

const ReviewList = props => {
  const {
    handleFilter,
    checked,
    reviews,
    searchVisible,
    handleSearch,
    searchText,
    onChange,
    handleReviewModal
  } = props;
  return (
    <div className="review-list">
      {/* <ReviewFilter
        searchText={searchText}
        searchVisible={searchVisible}
        onChange={onChange}
        handleSearch={handleSearch}
        handleFilter= {handleFilter}
        checked={checked}
      /> */}
      <div className="reviewers-label">
        Reviewers &nbsp;
        <span className="hide-link" onClick={props.toggleReviewList}>
          (Hide)
        </span>
      </div>
      <ReviewListItem
        handleReviewModal={handleReviewModal}
        reviews={reviews}
      />
    </div>
  );
};
export default ReviewList;

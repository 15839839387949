import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/ProcessCircle.scss';

class ProcessCircle extends Component {
  static propTypes = {
    percentage: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props;
    let border, color, fillborder;
    color = '#0085CB';
    border = 'solid 0.08em #0085CB';
    return (
      <div>
        <div
          className={`c100 small p${props.percentage ? props.percentage : 0}`}
        >
          <span style={{ color: color }}>
            {props.percentage && props.percentage}%
          </span>
          <div className="slice">
            <div className="bar" style={{ border: border }} />
            <div className="fill" style={{ border: fillborder }} />
          </div>
        </div>
      </div>
    );
  }
}

export default ProcessCircle;

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, FormGroup, FormControl } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageGoals.scss';

const TOPIC_GOALS = [
  {
    goal: 'NUM_ARTICLES_READ',
    goalDisplayAs: 'Articles read',
  },
  {
    goal: 'READING_TIME',
    goalDisplayAs: 'Reading minutes',
  },
  {
    goal: 'NUM_POINTS_QUESTIONED',
    goalDisplayAs: 'Questioned claims',
  },
  {
    goal: 'POINTS_REVIEWED',
    goalDisplayAs: 'Reviewed claims',
  },
  {
    goal: 'EVIDENCE_ADDED',
    goalDisplayAs: 'Evidence added to claims',
  },
  {
    goal: 'POINTS_SAVED',
    goalDisplayAs: 'Saved claims',
  },
];

function ManageGoals({
  courseId,
  topicId,
  goals: existingGoals,
  readingId,
  title,
  onClose,
  goalsDefinition,
  actions,
}) {
  const [goals, setGoals] = useState(existingGoals || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!goalsDefinition.length) {
      setLoading(true);
      actions.getGoalsDefinition().then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!goalsDefinition.length) {
      return;
    }

    const goalsList = [];

    for (const topicGoal of TOPIC_GOALS) {
      if (readingId && topicGoal.goal === 'NUM_ARTICLES_READ') {
        continue;
      }

      const existingGoal = goals.find((g) => g.goal === topicGoal.goal);

      if (existingGoal) {
        goalsList.push({
          ...existingGoal,
          ...topicGoal,
          value: existingGoal.target || 0,
        });

        continue;
      }

      const goalDefinition = goalsDefinition.find(
        (g) => g.goal === topicGoal.goal,
      );

      goalsList.push({
        ...goalDefinition,
        ...topicGoal,
        value: 0,
      });
    }

    setGoals(goalsList);
  }, [goalsDefinition]);

  const createGoals = () => {
    const updatedGoals = goals.map((g) => ({
      goal: g._id,
      goalAs: g.goal,
      goalDisplayAs: g.goalDisplayAs,
      value: g.value,
    }));

    let apiPromise = readingId
      ? actions.createReadingGoals(topicId, courseId, readingId, updatedGoals)
      : actions.createGoals(topicId, courseId, updatedGoals);

    apiPromise
      .then(() => {
        actions.getTopicGoals(topicId, courseId, true, true);
        onClose();
      })
      .catch((error) => {
        setError(
          error.message ||
          'Error occurred while adding goals. Please try again.',
        );
      });
  };

  const updateGoal = (index, decrease = false, value = null) => {
    const clonedGoals = JSON.parse(JSON.stringify(goals));

    const updatedGoal = clonedGoals[index];

    if (updatedGoal && value) {
      if (value > 9999) {
        return;
      }

      updatedGoal.value = Number(value) || 0;

      setGoals(clonedGoals);
    } else if (updatedGoal && (!decrease || updatedGoal.value > 0)) {
      updatedGoal.value = decrease
        ? updatedGoal.value - 1
        : updatedGoal.value + 1;

      setGoals(clonedGoals);
    }
  };

  function integerNumberHandler(event) {
    const codes = ['.', 'e'];

    if (codes.includes(event.key)) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  const renderEditGoal = (goalSetting, index) => (
    <div className="activity py-3">
      <div className="d-flex">
        <div className="activity-text">{goalSetting.goalDisplayAs}</div>
      </div>
      <div className="d-flex align-items-center">
        <div className="icon">
          <Icon
            icon={ICONS.REMOVE}
            size={16}
            onClick={() => updateGoal(index, true)}
          />
        </div>
        <div className="count">
          <input
            className="goal-number-input"
            type="number"
            value={goalSetting.value}
            onChange={(event) => updateGoal(index, true, event.target.value)}
            onKeyDown={integerNumberHandler}
            max={1000}
          />
        </div>
        <div className="icon">
          <Icon icon={ICONS.ADD} size={16} onClick={() => updateGoal(index)} />
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <div className="text-center mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '40px' }}
          />
        </div>
      );
    }

    return goals.map(renderEditGoal);
  };

  return (
    <Modal show={true} className="manage-goals-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>{readingId ? 'Goals for this reading' : 'Manage Goals'}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {title && (
          <div>
            <b>Reading:</b> {title}
          </div>
        )}
        <div className="body">{renderContent()}</div>
        {error && <div className="mb-4 av-red">{error}</div>}
        <RectangularButton
          className="next-button"
          disabled={!goals.length}
          onClick={() => createGoals()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    goalsDefinition: state.weeklyGoals.goalsDefinition || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageGoals);

export const CognitoStatusCodes = {
  LOGIN_SUCCESSFUL: 200,
  LOGIN_FAILED: 400,
  RESET_PASSWORD: 205,
  MIGRATED_USER_RESET_REQUIRED: 206,
  SIGNUP_SUCCESSFUL: 200,
  SIGNUP_FAILED: 400,
  UPDATE_SUCCESSFUL: 200,
  UPDATE_FAILED: 400,
  VERIFICATION_CODE_REQUIRED: 203,
  VERIFICATION_CODE_SUCCESSFUL: 200,
  VERIFICATION_CODE_FAILED: 400,
  RESET_PASSWORD_SUCCESSFUL: 200,
  RESET_PASSWORD_FAILED: 400,
  FORGOT_PASSWORD_SUCCESSFUL: 200,
  FORGOT_PASSWORD_FAILED: 400,
  CHANGE_PASSWORD_SUCCESSFUL: 200,
  CHANGE_PASSWORD_FAILED: 400,
  GET_SESSION_SUCCESSFUL: 200,
  GET_SESSION_FAILED: 400,
};

import * as Actions from 'redux/actions/index';

const initialState = {
  show: false,
  type: 'warning',
  message: '',
  onTop: false,
};

export default function hint(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_HINT: {
      return {
        ...state,
        show: true,
        type: action.data.type,
        message: action.data.message,
        hintIcon: action.data.hintIcon || '',
        showLinkToNewsClub: action.data.showLinkToNewsClub || '',
        onTop: action.data.onTop || '',
      };
    }
    case Actions.HIDE_HINT:
      return {
        ...state,
        show: false,
        message: '',
        hintIcon: '',
        showLinkToNewsClub: '',
        onTop: false,
      };
    default:
      return state;
  }
}

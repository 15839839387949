import React from "react";
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Radio,
  TextBox,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import _ from "lodash";

import { Validator } from "../../../config/Validator/Validator";

import { Points } from "../../../config/Rules.js";

const erroMessageTypes = {
  NotesError: "NotesError",
};

class SuggestRevisionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new Validator();
    this.points = new Points();

    this.state = {
      errorMessage: "",
      publishProofOption: true,
      notes: "",
    };
  }

  render() {
    var submitDisabled = this.state.errorMessage != "";
    return (
      <div className="send-back-writer-container">
        <FormGroup>
          <div>
            You can request the writer to re-submit this Fact. This means you
            are not verifying or rejecting, but giving the writer a chance to
            revise the Fact. and re-submit. Compared to an outright rejection,
            this is a nicer way to respond to typos or statistics that are
            slightly off.
            <br />
            Score impact: You will earn {this.points.REVISE_REVISE.string}{" "}
            points if the writer revises the Fact. You will not lose any points
            if the writer takes no action.
          </div>
        </FormGroup>
        <FormGroup
          controlId="notes"
          validationState={this.isFieldValid("NotesError")}
        >
          <ControlLabel className="notes-label">
            <span>Notes:</span>
            <OverlayTrigger placement="top" overlay={this.renderToolTip()}>
              <span className="help-icon">
                <img
                  className="img-responsive"
                  src="../../../assets/images/QuestionMarkIcon.png"
                />
              </span>
            </OverlayTrigger>
          </ControlLabel>
          <FormControl
            componentClass="textarea"
            onChange={this.onNotesChange}
          />
          <HelpBlock>{this.state.errorMessage}</HelpBlock>
          <Button onClick={this.onSubmit} disabled={submitDisabled}>
            Submit
          </Button>
        </FormGroup>
      </div>
    );
  }

  renderToolTip() {
    return (
      <Tooltip id="noteTooltip">
        <strong>Your note</strong> to the writer, noting any quick errors they
        can fix.
      </Tooltip>
    );
  }

  onNotesChange = event => {
    const notes = event.target.value;
    //this.props.handleRevisionNotesToWriterChange(value);
    const sanitizedNotes = this.validator.sanitizeUserInput(notes);
    const errorMessage = this.validator.validateText(
      sanitizedNotes,
      this.validator.TEXTAREA_INPUT,
      ""
    );
    this.setState({
      notes: sanitizedNotes,
      errorMessage: errorMessage,
    });

    //this.textAreaChange(value, 'NotesError', 'notes')
  };

  onSubmit = () => {
    if (this.state.errorMessage != "") return;

    this.props.onSuggestRevision(this.state.notes);
  };

  textAreaChange(value, type, valueType) {
    const sanitizedValue = this.validator.sanitizeUserInput(value);
    const errorMessage = this.validator.validateText(
      sanitizedValue,
      this.validator.TEXTAREA_INPUT,
      ""
    );
    const stateErrorMessage = this.state.errorMessages;

    stateErrorMessage[erroMessageTypes[type]] = errorMessage;

    this.setState({
      [valueType]: sanitizedValue,
      errorMessage: stateErrorMessage,
    });
  }

  isFieldValid(fieldName) {
    if (!this.state.errorMessage || this.state.errorMessage == "") {
      return "";
    }

    return "error";
  }

  isFormValid() {
    this.textAreaChange(this.state.notes, "NotesError", "notes");

    return _.keys(erroMessageTypes).reduce((isValid, messageType) => {
      return isValid && _.isEmpty(this.state.errorMessages[messageType]);
    }, true);
  }

  getValue() {
    return this.state;
  }
}

export default SuggestRevisionComponent;

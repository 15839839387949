import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Overlay, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gotoNextStepAsync } from 'redux/actions/walkthrough';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import './css/TourHighlight.scss';

class TourHighlight extends Component {
  static propTypes = {
    walkthrough: PropTypes.object,
    step: PropTypes.number,
    visible: PropTypes.bool,
    gotoNextStep: PropTypes.bool,
    onClick: PropTypes.func,
    user: PropTypes.object,
    buttonText: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      show:
        props.walkthrough.tourStatus === 'inProgress' &&
        props.step === props.walkthrough.tourStep,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.walkthrough.tourStep != this.props.walkthrough.tourStep) {
      this.setState({
        show:
          this.props.walkthrough.tourStatus === 'inProgress' &&
          this.props.step === this.props.walkthrough.tourStep,
      });
    }
  }

  handleCancel = () => {
    this.setState({ show: false });
  };

  onClick = () => {
    this.handleCancel();
    if (this.props.gotoNextStep) {
      this.props.gotoNextStepAsync({
        user: this.props.user,
        step: this.props.step + 1,
      });
    } else {
      this.props.onClick();
    }
  };

  render() {
    const {
      step,
      placement,
      walkthrough,
      target,
      children,
      onClick,
      gotoNextStep,
      visible = true,
    } = this.props;

    const inProgress = walkthrough.tourStatus === 'inProgress';

    const title = (
      <div className="highlight-header">AverPoint Tutorial: {step}/12</div>
    );

    let buttonText = this.props.buttonText ? this.props.buttonText : 'Got it';

    let onClickHandler =
      onClick || gotoNextStep ? this.onClick : this.handleClick;

    return (
      <div style={{ position: 'relative' }} className="d-inline">
        {inProgress && (
          <Overlay
            show={this.state.show && visible}
            placement={placement}
            container={this}
            target={() => ReactDOM.findDOMNode(target.current)}
            flip={true}
          >
            <Popover id="highlight-container" title={title}>
              {/* <div className="highlight-header">
                AverPoint Tutorial: {step}/11
              </div> */}
              <div className="highlight-content">
                {children}
                <RoundedButton label={buttonText} onClick={onClickHandler} />
                {/* {onClick || gotoNextStep ? (
                  <RoundedButton label="Got It" onClick={this.onClick} />
                ) : (
                  <RoundedButton label="Okay" onClick={this.handleCancel} />
                )} */}
              </div>
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  walkthrough: state.walkthrough,
});

const mapDispatchToProps = dispatch => ({
  gotoNextStepAsync: payload => dispatch(gotoNextStepAsync(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TourHighlight);

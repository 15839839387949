import * as Actions from 'redux/actions/index';

const initialState = {
  show: false,
};

export default function hint(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_SUBSCRIBE_NEWSLETTER: {
      return {
        ...state,
        show: true,
      };
    }
    case Actions.HIDE_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        show: false,
      };
    case Actions.SUBSCRIBED_NEWSLETTER:
      return {
        ...state,
        subscribed: true,
      };
    case Actions.CLOSE_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        show: false,
        subscribed: true,
        closed: true,
      };
    default:
      return state;
  }
}

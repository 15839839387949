import * as Actions from 'redux/actions/index';

const initialState = {
  clubTalks: [],
};

export default function weeklyGoals(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_CLUB_TALKS: {
      return {
        ...state,
        clubTalks: action.clubTalks,
      };
    }
    case Actions.LOAD_TALK_READINGS: {
      const id = action.id;
      const readings = action.readings;

      const newClubTalks = state.clubTalks.map((clubTalk) => ({
        ...clubTalk,
        readings: id === clubTalk._id ? readings : clubTalk.readings,
      }));

      return {
        ...state,
        clubTalks: newClubTalks,
      };
    }
    default:
      return state;
  }
}

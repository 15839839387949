import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './../../../../redux/actions/index';
import classnames from 'classnames';

// Components
import PointImage from 'components/cards/Point/Component/PointImage/PointImage';
import Source from 'components/elements/organisms/Source/Source';
import SelectionTag from 'components/elements/molecules/tags/SelectionTag';
import { EditableTags } from 'components/elements/molecules/tags';
import PointEditControls from 'components/cards/Point/Component/PointEditControls/PointEditControls';
import CollectionTree from 'components/elements/organisms/CollectionTree/CollectionTree';
import { Icon, ICONS } from 'components/Icons';
import { InviteAuthors } from './components/InviteAuthors/InviteAuthors';
// import TextareaAutosize from './../../../../components/elements/molecules/TextareaAutosize/TextareaAutosize';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import { isMac } from 'helpers';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import Editor from 'components/elements/molecules/Editor/Editor';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

// SCSS
import './css/CreatePoint.scss';

class CreatePointEntryForm extends Component {
  static propTypes = {
    point: PropTypes.object,
    user: PropTypes.object,
    userHighlightType: PropTypes.string,
    onPointFieldChanged: PropTypes.func,
    errorMessages: PropTypes.object,
    errorMessage: PropTypes.string,
    isExtension: PropTypes.bool,
    showStance: PropTypes.bool,
    stance: PropTypes.string,
    onSelectStance: PropTypes.func,
    showAddToCollection: PropTypes.bool,
    openCreateCollectionModal: PropTypes.func,
    collectionId: PropTypes.string,
    setCollectionId: PropTypes.func,
    onCreateCollection: PropTypes.func,
    onClose: PropTypes.func,
    isEditing: PropTypes.bool,
    onToggleEdit: PropTypes.func,
    setAdditionalAuthors: PropTypes.func.isRequired,
    newCollection: PropTypes.object,
    onNewCollection: PropTypes.func,
    setNewCollection: PropTypes.func,
    focusQuote: PropTypes.bool,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
    isSaving: PropTypes.bool,
    placeholder: PropTypes.string,
    focusPointText: PropTypes.bool,
  };

  static defaultProps = {
    focusQuote: false,
    isSaving: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      sourceEditable: false,
      showCollectionDropdown: false,
      showInviteAuthors: false,
      inviteAuthorsSearchTerm: '',
      newCollection: null,
    };
    this.ref = React.createRef();
  }

  componentWillMount() {
    window.addEventListener('click', this.onDocumentClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick, false);
  }

  getPointText() {
    const { point } = this.props;
    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = point.html || point.text || '';
    return (tmpDiv.textContent || tmpDiv.innerText || '').trim();
  }

  onDocumentClick = (e) => {
    const { type, actions } = this.props;

    this.setState({
      showCollectionDropdown: false,
      showInviteAuthors: false,
    });

    if (!this.ref.current.contains(e.target)) {
      if (type === 'LIGHTWEIGHT') {
        actions.keyboardShortcutsShow(false);
      }
    }
  };

  /**
   * @returns {boolean}
   */
  isAllAuthorsSelected() {
    return (
      this.getPointAuthors(this.props.collectionId).length ===
      this.props.additionalAuthors.length
    );
  }

  onFocus = (e) => {
    const { type, actions } = this.props;

    if (type === 'LIGHTWEIGHT' && e.currentTarget.contains(e.target)) {
      actions.keyboardShortcutsShow(true, 'ADD_POINT');
    }
  };

  onBlur = (e) => {
    const { type, actions } = this.props;

    if (type === 'LIGHTWEIGHT' && e.currentTarget === e.relatedTarget) {
      actions.keyboardShortcutsShow(false);
    }
  };

  onKeyDown = (e) => {
    const { type, onSave, onPointFieldChanged, point } = this.props;

    if (e.keyCode === 13 && e.shiftKey) {
      onSave();
      e.stopPropagation();
      e.preventDefault();
    }

    // if (type === 'LIGHTWEIGHT') {
    if (e.shiftKey && ((isMac() && e.metaKey) || (!isMac() && e.ctrlKey))) {
      if (e.keyCode === 83) {
        onPointFieldChanged('showQuote', !point.showQuote);
        e.stopPropagation();
        e.preventDefault();
      } else if (e.keyCode === 84) {
        onPointFieldChanged('showTags', !point.showTags);
        e.stopPropagation();
        e.preventDefault();
      } else if (e.keyCode === 73) {
        onPointFieldChanged('showPhoto', !point.showPhoto);
        e.stopPropagation();
        e.preventDefault();
      }
    }
    // }
  };

  onPointTextChange(text) {
    const { onPointFieldChanged } = this.props;
    onPointFieldChanged('text', text);
    // const matches = text.match(/(https?:\/\/[^\s]+)/g);

    // if (matches && matches.length) {
    //   onPointFieldChanged('sourceURL', matches[0]);
    // }
  }

  renderStanceLightWeight() {
    const { type, showStance, onSelectStance, stance } = this.props;

    if (type !== 'LIGHTWEIGHT' || !showStance) {
      return null;
    }

    const forClassName = classnames({
      active: stance === 'For',
    });
    const forLink = (
      <ActionLink
        linkClassName={forClassName}
        onClick={() => onSelectStance('For')}
      >
        FOR
      </ActionLink>
    );

    const againstClassName = classnames({
      active: stance === 'Against',
    });
    const againstLink = (
      <ActionLink
        linkClassName={againstClassName}
        onClick={() => onSelectStance('Against')}
      >
        AGAINST
      </ActionLink>
    );

    return (
      <div className="lightweight-stance pt-4 pl-4 pr-4">
        {forLink}
        {againstLink}
      </div>
    );
  }

  render() {
    const { keyboardShortcuts, type, showStance, point } = this.props;
    const className = classnames('create-point-entry-form', {
      'lightweight-show':
        type === 'LIGHTWEIGHT' &&
        (keyboardShortcuts.show || point.sourceImageURL),
      'show-stance': showStance,
    });
    return (
      <div
        className={className}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        tabIndex="-1"
        ref={this.ref}
      >
        {this.renderStanceLightWeight()}
        <div className="point-and-quote p-4">
          {this.renderPointText()}
          {this.renderEditRow()}
          {this.renderSource()}
          {this.renderPointTags()}
          {this.renderImage()}
        </div>
        {this.renderCreateNewCollection()}
        {this.renderAddToCollections()}
        {this.renderInviteAuthors()}
        {this.renderStance()}
        <div className="p-4 bg-white privacy-save-container">
          <div className="d-flex align-items-center">
            {this.renderPrivacy()}
            {this.renderSubmitButton()}
          </div>
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderPointText = () => {
    const { point, type, errorMessages, focusPointText } = this.props;
    let { placeholder } = this.props;

    if (!placeholder) {
      placeholder =
        type === 'LIGHTWEIGHT'
          ? 'What’s your Point?'
          : 'Type your comment here';
    }

    return (
      <div className="point-text position-relative">
        {/* <TextareaAutosize
          value={point.text}
          onChange={e => {
            this.onPointTextChange(e.target.value);
          }}
          placeholder={placeholder}
          className="d-block"
          autoFocus={type !== 'LIGHTWEIGHT'}
        /> */}
        <Editor
          onChange={(text) => this.onPointTextChange(text)}
          placeholder={placeholder}
          html={point.html || point.text}
          autoFocus={focusPointText || type !== 'LIGHTWEIGHT'}
          handleReturn={(e) => {
            if (e.shiftKey) {
              return true;
            }
          }}
        />
        {this.props.errorMessages.pointText}
        <small
          className="position-absolute text-danger"
          style={{ right: '30px', top: '-16px' }}
        >
          <i>{errorMessages.sourceError}</i>
        </small>
      </div>
    );
  };

  renderSource() {
    const { type, keyboardShortcuts } = this.props;

    if (type === 'LIGHTWEIGHT' && !keyboardShortcuts.show) {
      return;
    }

    return (
      <Source
        point={this.props.point}
        onPointFieldChanged={this.props.onPointFieldChanged}
        editable={true}
        openEditFields={this.props.isEditingFields.source}
        onToggleShowSourceDetails={() => {
          this.props.onEditPointField('source');
        }}
        citationStyle="full"
        userHighlightType={this.props.userHighlightType}
        isExtension={this.props.isExtension}
        errorMessages={this.props.errorMessages}
        viewType="pointEntry"
        hideAddSourceLink
        focusQuote={this.props.focusQuote}
        className="mt-3"
        type={type}
        isExtensionCL={this.props.isExtensionCL}
      />
    );
  }

  renderImage() {
    const {
      point,
      user,
      isEditing,
      onPointFieldChanged,
      cardType,
      isExtension,
    } = this.props;

    if (!point.showPhoto) {
      return;
    }

    return (
      <PointImage
        point={point}
        user={user}
        isSaved
        isEditing={isEditing}
        onPointFieldChanged={onPointFieldChanged}
        cardType={cardType}
        isExtension={isExtension}
        className="mt-3"
      />
    );
  }

  renderEditRow = () => {
    const { type, onSave, isSaving, point } = this.props;

    let pointBtn;

    if (type === 'LIGHTWEIGHT') {
      const tmpDiv = document.createElement('div');
      tmpDiv.innerHTML = point.html || point.text || '';
      const text = (tmpDiv.textContent || tmpDiv.innerText || '').trim();
      const disabled =
        !text && !point.sourceText && !point.sourceTitle && !point.sourceURL;
      pointBtn = (
        <button
          type="button"
          className="ml-3 btn btn-primary btn-submit d-inline-flex align-items-center font-weight-600"
          onClick={onSave}
          disabled={disabled}
        >
          <Icon icon={ICONS.EVIDENCE} size={22} />
          {isSaving ? 'Saving...' : this.props.submitText || 'Point'}
        </button>
      );
    }

    const hint = (
      <div
        className="gray-3 font-weight-bold position-absolute"
        style={{ right: 0, top: '5px' }}
      >
        <small className="hint">
          <span className="gray-4">Shift + Return</span> to save
        </small>
        {pointBtn}
      </div>
    );

    return (
      <div className="position-relative">
        <PointEditControls
          point={this.props.point}
          onToggleEdit={this.props.onToggleEdit}
          onEditPointField={this.props.onEditPointField}
          isEditing={this.props.isEditing}
          isEditingFields={this.props.isEditingFields}
          errorMessages={this.props.errorMessages}
          onPointFieldChanged={this.props.onPointFieldChanged}
          type={this.props.type}
        />
        {hint}
      </div>
    );
  };

  renderSelectPointType() {
    const { point } = this.props;
    let tagTypes = ['Fact', 'Opinion', 'Decide later'];
    let disabledTags = [false, false, false];
    if (point.status != 'private') {
      //tagTypes.push('Decide later');
      disabledTags[2] = true;
    }
    const toolTips = [
      'Neutral, verifiable statement.',
      'A value or judgment.',
      'Must be private.',
    ];

    const helpText =
      point.status == 'private'
        ? 'You can optionally mark this Point as a'
        : '';

    return (
      <div className="fact-or-opinion focus-helptext-within">
        <p className="help-text">{helpText}</p>
        <div className="type">
          <SelectionTag
            fieldName="type"
            tags={tagTypes}
            disabledTags={disabledTags}
            toolTips={toolTips}
            defaultTag={point.type}
            onSelectType={(type) => {
              this.props.onPointFieldChanged('type', type);
            }}
          />
        </div>
      </div>
    );
  }

  renderStance() {
    const { showStance, type, stance } = this.props;

    if (!showStance || type === 'LIGHTWEIGHT') {
      return null;
    }

    const tagsStance = ['For', 'Against', 'Neutral'];

    return (
      <div className="stance focus-helptext-within p-4">
        <p className="av-blue font-size-10 font-weight-600">
          Is this Point for or against the main Point?
        </p>
        <div className="againstType">
          <SelectionTag
            fieldName="stance"
            tags={tagsStance}
            defaultTag={stance}
            onSelectType={this.props.onSelectStance}
          />
        </div>
      </div>
    );
  }

  renderErrorMessage() {
    const { errorMessage } = this.props;

    if (!errorMessage) {
      return;
    }

    return (
      <div className="error-message pb-3 mr-4 text-right">
        <small>{errorMessage}</small>
      </div>
    );
  }

  renderPrivacy() {
    const { point, onPointFieldChanged, newCollection } = this.props;
    const privateClassName = classnames({
      'not-selected': point.status !== 'private',
    });
    const publicClassName = classnames({
      'not-selected': point.status !== 'public',
    });
    return (
      <div className="privacy ml-auto mr-3">
        <RoundedButton
          onClick={() => {
            onPointFieldChanged('status', 'private');
          }}
          label=""
          mainClassName={privateClassName}
          className="btn-sm"
          iconName="LOCK"
          iconClassName="mr-0"
          disabled={!!newCollection}
        />
        <RoundedButton
          onClick={() => {
            onPointFieldChanged('status', 'public');
          }}
          label=""
          className="btn-sm"
          iconName="GLOBE"
          iconClassName="mr-0"
          mainClassName={publicClassName}
          disabled={newCollection}
        />
      </div>
    );
  }

  // renderTags() {
  //   return (
  //     <div
  //       id="point-tags-container"
  //       className="point-tags-container focus-helptext-within"
  //     >
  //       <div id="point-tags-text" className="help-text">
  //         Tags
  //       </div>
  //       <div className="tags-row">
  //         <AddTags
  //           tags={this.props.point.tags}
  //           setTags={tags => this.props.onPointFieldChanged('tags', tags)}
  //           autofocus={false}
  //           //tagsLimit={10}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  renderPointTags = () => {
    const { point, view, isEditingFields, onPointFieldChanged, isEditing } =
      this.props;

    // if (!point.tags || point.tags.length == 0) {
    //   if (!this.props.isEditingFields.tags) return null;
    // }

    if (!point.showTags) {
      return;
    }

    return (
      <div className="point-tags-container mt-3">
        <EditableTags
          tags={point.tags}
          displayType={'piped'}
          setTags={(tags) => onPointFieldChanged('tags', tags)}
          tagsEditable={isEditing}
          startEditable={point.tags.length === 0}
        />
      </div>
    );
  };

  renderAddToCollections() {
    if (!this.props.showAddToCollection || this.props.newCollection) {
      return null;
    }

    let error;

    if (!this.props.collectionId) {
      error = (
        <p className="ml-4 mr-4 av-red mt-1 font-size-10">
          Select a destination Point or Collection where you will save this
          Point.
        </p>
      );
    }

    return (
      <Fragment>
        <div
          className="collection-container d-flex align-items-center p-2 ml-4 mr-4"
          style={{ minWidth: 0 }}
        >
          <div className="gray-3 flex-shrink-0">Saving to:</div>
          <div
            className="flex-fill ml-3 position-relative"
            style={{ minWidth: 0 }}
          >
            {this.renderCollectionDropdown()}
            {this.renderCollectionTree()}
          </div>
        </div>
        {error}
      </Fragment>
    );
  }

  renderCollectionDropdown() {
    let point;

    if (this.props.collectionId && this.props.points[this.props.collectionId]) {
      point = this.props.points[this.props.collectionId].point;
    }

    let icon = (
      <Icon icon={ICONS.ARROW_DOWN} size={14} className="flex-shrink-0" />
    );

    return (
      <div
        className="collection-name d-flex align-items-center"
        onClick={(event) => {
          this.setState({
            showCollectionDropdown: !this.state.showCollectionDropdown,
          });
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div
          className="m-0 flex-fill d-flex align-items-center"
          style={{
            minWidth: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {point ? (
            <Icon
              icon={point.isCollection ? ICONS.COLLECTION : ICONS.BULLET}
              className="mr-2 av-blue"
              size={24}
            />
          ) : null}
          {point
            ? point.text || point.sourceText
            : 'Select a Collection or Point'}
        </div>
        {icon}
      </div>
    );
  }

  renderCreateNewCollection() {
    if (!this.props.showAddToCollection || !this.props.newCollection) {
      return null;
    }

    let publicIconClassName = classnames(
      'flex-shrink-0 p-2 d d-inline-flex align-items-center justify-content-center privacy-icon',
      {
        selected: this.props.newCollection.status === 'public',
      },
    );
    let publicIcon = (
      <div
        className={publicIconClassName}
        onClick={() => {
          this.props.setNewCollection({
            ...this.props.newCollection,
            status: 'public',
          });
        }}
      >
        <Icon icon={ICONS.GLOBE} size={16} />
      </div>
    );

    let privateIconClassName = classnames(
      'flex-shrink-0 ml-3 p-2 d-inline-flex align-items-center justify-content-center privacy-icon',
      {
        selected: this.props.newCollection.status === 'private',
      },
    );
    let privateIcon = (
      <div
        className={privateIconClassName}
        onClick={() => {
          this.props.setNewCollection({
            ...this.props.newCollection,
            status: 'private',
          });
        }}
      >
        <Icon icon={ICONS.LOCK} size={16} />
      </div>
    );

    return (
      <div className="d-flex align-items-center pl-4 pr-4 create-new-collection">
        <div className="flex-fill d-flex align-items-center">
          <input
            type="text"
            placeholder="Enter Collection Name"
            className="flex-fill p-2"
            onChange={(e) => {
              this.props.setNewCollection({
                ...this.props.newCollection,
                text: e.target.value,
              });
            }}
            value={this.props.newCollection.text}
            autoFocus={true}
          />
        </div>
        {privateIcon}
        {publicIcon}
        <RoundedButton
          className="ml-3 btn-create pl-3 pr-3"
          iconType="averPointIcon"
          iconName="CHECK"
          label="Create"
          onClick={this.onCreateNewCollection}
        />
        <RoundedButton
          className="ml-3 btn-close pl-3 pr-3"
          iconType="averPointIcon"
          iconName="CLOSE"
          onClick={() => {
            this.props.setNewCollection(null);
          }}
          iconClassName="mr-0"
        />
      </div>
    );
  }

  onCreateNewCollection = async () => {
    if (this.props.newCollection.text) {
      await this.props.onCreateCollection(this.props.newCollection);
      this.props.setNewCollection(null);
    }
  };

  onSelectCollectionId = (id) => {
    if (id !== this.props.collectionId) {
      this.props.setCollectionId(id);
      this.props.setAdditionalAuthors(
        this.getPointAuthors(id).map((i) => i.userId),
      );
    }
  };

  /**
   *
   * @param {string} authorId author user id or 'ALL'
   */
  onSelectInviteAuthor = (authorId) => {
    let authors;

    if (authorId == 'ALL') {
      if (this.isAllAuthorsSelected()) {
        authors = [];
      } else {
        authors = this.getPointAuthors(this.props.collectionId).map(
          (i) => i.userId,
        );
      }
    } else {
      let index = this.props.additionalAuthors.findIndex((i) => i === authorId);

      if (index === -1) {
        authors = [...this.props.additionalAuthors, authorId];
      } else {
        authors = [
          ...this.props.additionalAuthors.slice(0, index),
          ...this.props.additionalAuthors.slice(index + 1),
        ];
      }
    }

    this.props.setAdditionalAuthors(authors);
  };

  /**
   *
   * @param {string} authorId author user id or 'ALL'
   */
  onRemoveInviteAuthor = (authorId) => {
    let authors;

    if (authorId === 'ALL') {
      authors = [];
    } else {
      let index = this.props.additionalAuthors.findIndex((i) => i === authorId);
      authors = [
        ...this.props.additionalAuthors.slice(0, index),
        ...this.props.additionalAuthors.slice(index + 1),
      ];
    }

    this.props.setAdditionalAuthors(authors);
  };

  getPointAuthors(pointId) {
    const point = this.props.points[pointId].point;
    return point
      ? point.authors.filter(
          (i) =>
            i.invitationStatus === 'accepted' &&
            i.userId !== this.props.user._id,
        )
      : [];
  }

  renderCollectionTree() {
    if (this.state.showCollectionDropdown) {
      return (
        <div
          className="position-absolute bg-av-white"
          style={{
            zIndex: 1,
            top: '31px',
            left: '-79px',
            width: 'calc(100% + 85px)',
          }}
        >
          <CollectionTree
            selectCollection={this.onSelectCollectionId}
            selectedIds={[this.props.collectionId]}
            close={() => this.setState({ showCollectionDropdown: false })}
            onNewCollection={this.props.onNewCollection}
          />
        </div>
      );
    }
  }

  renderInviteAuthors() {
    if (!this.props.collectionId) {
      return null;
    }

    let point = this.props.points[this.props.collectionId];

    if (!point || this.getPointAuthors(this.props.collectionId).length == 0) {
      return null;
    }

    let authors = this.getPointAuthors(this.props.collectionId);

    return (
      <div className="invite-authors-container d-flex align-items-center position-relative pl-2 pr-2 mt-4 ml-4 mr-4">
        <div className="gray-3 flex-shrink-0">Invited Authors:</div>
        <div className="flex-fill ml-3" style={{ minWidth: 0 }}>
          <InviteAuthors
            authors={authors}
            selectedAuthors={this.props.additionalAuthors}
            onSelect={this.onSelectInviteAuthor}
            onRemove={this.onRemoveInviteAuthor}
            allAuthorsText={
              point.isCollection
                ? 'All Collection Authors'
                : 'All Point Authors'
            }
          />
        </div>
      </div>
    );
  }

  renderSubmitButton() {
    const { newCollection, collectionId, onSave, isSaving } = this.props;
    const disabled = !!newCollection || !collectionId;
    const btn = (
      <button
        type="button"
        className="btn btn-primary btn-submit d-inline-flex align-items-center font-weight-600"
        disabled={disabled}
        onClick={onSave}
      >
        <Icon icon={ICONS.EVIDENCE} />
        {isSaving ? 'Saving...' : this.props.submitText || 'Point'}
      </button>
    );

    if (disabled) {
      return (
        <ToolTip
          toolTipText="Please select a destination Point or Collection where you will save this Point."
          toolTipPosition="left"
          style={{ '--width': '300px' }}
        >
          {btn}
        </ToolTip>
      );
    } else {
      return btn;
    }
  }
}

let mapStateToProps = (state) => {
  return {
    points: state.points,
    keyboardShortcuts: state.keyboardShortcuts,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePointEntryForm);

import React from 'react';
import AddToCollectionComponent from './AddToCollectionComponent';
import { Modal, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions/index';

import { Icon, ICONS } from '../../Icons';
import './css/addToCollection.scss';

import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import { toast } from 'components/elements/molecules/Toast/Toast';

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class AddToCollectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionId: null,
      errorMessage: {},
    };
  }

  onClose = () => {
    this.props.onClose(false);
  }

  onCollectionSelected = (collectionId) => {
    console.log('onCollectionSelected', collectionId);
    this.setState({
      collectionId: collectionId,
    });
  }

  openCreateCollectionModal = () => {
    this.props.openCreateCollectionModal(this.afterNewCollectionCreated);
  }

  afterNewCollectionCreated = (collectionPoint) => {
    this.setState({
      collectionId: collectionPoint._id,
    });
  }

  onCreateCollection = (collectionPoint) => {
    this.props
      .onAction(this.props.onCreateCollection, collectionPoint)
      .then(collectionPoint => {
        this.setState({
          collectionId: collectionPoint._id,
        });
        //this.props.onClose(true);
      })
      .catch(error => {
        this.setState({
          errorMessage: error.message, //How to display?
        });
      });
  }

  handleDoneClick = () => {
    const { pointId, addSelectedPoints, saveSelectedPoints } = this.props.model;
    const { collectionId } = this.state;

    if (!collectionId) {
      console.log('No Collection selected.');
      return;
    }

    if (addSelectedPoints) {
      saveSelectedPoints(collectionId);
      this.props.onClose(true);
    } else if (pointId) {
      this.props.actions
        .saveSubPointToPoint(pointId, collectionId)
        .then(() => {
          toast('This Point was successfully added to your Collection.');
          this.props.onClose(true);
        })
        .catch(errorMessage => {
          console.log('Error adding to collection ' + errorMessage);
          // this.props.openErrorModal(
          //   "Point",
          //   that.props.model.isExtension,
          //   errorMessage
          // );
          this.props.onClose(true);
        });
    } else {
      console.log('Error trying to add Point to Collection');
    }
  }

  render() {
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    return (
      <Modal
        className={
          'general-modal add-to-collection ' +
          (this.props.model.isExtension ? 'active' : '')
        }
        show={true}
        container={container}
      >
        <Modal.Header>
          <Button bsStyle="link" className="btn-close" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <Modal.Title>
            <Icon icon={ICONS.ADD} /> Add to collection
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AddToCollectionComponent
            selectedCollectionId={this.state.collectionId}
            onCollectionSelected={this.onCollectionSelected}
            onCreateCollection={this.onCreateCollection}
            openCreateCollectionModal={this.openCreateCollectionModal}
            onClose={this.onClose}
          />
        </Modal.Body>

        <Modal.Footer>
          <RoundedButton label="Done" onClick={this.handleDoneClick} />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openCreateCollectionModal: afterNewCollectionCreated => {
      let props = {
        afterNewCollectionCreated,
      };
      dispatch(Actions.openModal('create-collection', props));
    },
    openConfirmationModal: (title, message, onResponse) => {
      let props = {
        model: {
          title,
          message,
        },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
    openErrorModal: (type, isExtension, message) => {
      var title = 'Error';
      let props = {
        model: {
          title,
          message,
          isExtension,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    dispatch,
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(AddToCollectionModal);

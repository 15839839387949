import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import { Row, Col } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
// import {DebounceInput} from 'react-debounce-input'
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import FloatingTextarea from 'components/elements/atoms/FloatingTextarea/FloatingTextarea';
import { Icon, ICONS } from 'components/Icons';

// scss
import './SourceInfo.scss';

class SourceInfo extends React.Component {
  static propTypes = {
    isExtension: PropTypes.bool,
    point: PropTypes.object,
    editable: PropTypes.bool,
    onPointFieldChanged: PropTypes.func,
    showSourceDetails: PropTypes.bool,
    toggleShowSourceDetails: PropTypes.func,
    errorMessages: PropTypes.object,
    /** ** Redux */
    actions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      sourceURL: '',
      lookUpURL: '',
      isFetching: false,
      valid: false,
      showURLInput: true,
      errorMessages: {},
      focusField: '',
    };
  }

  componentDidMount() {
    if (this.props.isExtension) {
      console.log(
        'Source info - is extension, so going to look up the point fields',
      );
      this.lookupSourceFieldsFromURL(this.props.point.sourceURL);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { point } = this.props;

    // if sourceURL is updated outside
    if (
      point.sourceURL &&
      point.sourceURL !== prevProps.point.sourceURL &&
      point.sourceURL !== this.state.lookUpURL
    ) {
      this.lookupSourceFieldsFromURL(point.sourceURL);
    }

    if (
      prevProps.errorMessages.sourceName !==
        this.props.errorMessages.sourceName &&
      this.props.errorMessages.sourceName === 'Required'
    ) {
      this.setState({ focusField: 'sourceName' }, () => {
        // reset needed so next focus work
        this.setState({ focusField: '' });
      });
    }
  }

  renderURLInput() {
    if (!this.state.showURLInput) return null;

    const { point, onPointFieldChanged } = this.props;

    // const URL = point.sourceURL ? point.sourceURL : this.state.sourceURL;
    const URL = point.sourceURL ? point.sourceURL : this.state.sourceURL;
    // const URLtoDisplay = URL.substring(0, 50);
    const error = this.state.errorMessages.sourceURL;
    return (
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <FloatingInput
            // value={URLtoDisplay}
            value={point.sourceURL}
            onBlur={(e) => {
              // onPointFieldChanged('sourceURL', e.target.value);
              const url = e.target.value;
              // this.lookupSourceFieldsFromURL(url);
              this.setState({
                lookUpURL: url,
              });
            }}
            // onBlur={this.onUrlChange}
            onPaste={(e) => {
              const clipboardText = e.clipboardData.getData('Text');
              setTimeout(() => {
                // onPointFieldChanged('sourceURL', clipboardText);
                // this.lookupSourceFieldsFromURL(clipboardText);
                // this.setState({ sourceURL: clipboardText });
                this.lookupSourceFieldsFromURL(clipboardText);
              }, 1000);
            }}
            labelName="Source URL (*)"
            disabled={!this.props.editable}
            error={error}
          />
        </div>
        {this.state.isFetching && (
          <span className="fa fa-spin fa-spinner ml-2" />
        )}
        {this.props.editable && (
          <button
            type="button"
            disabled={!!error || !this.state.lookUpURL}
            onClick={() => this.lookupSourceFieldsFromURL(this.state.lookUpURL)}
            className="btn btn-default btn-sm lookup-url-button ml-2"
          >
            Lookup
          </button>
        )}
        {!this.props.editable && (
          <CopyToClipboard text={URL}>
            <button
              type="button"
              className="btn btn-default btn-sm copy-url-button ml-2"
            >
              Copy
            </button>
          </CopyToClipboard>
        )}
      </div>
    );
  }

  // renderEditableSourceInfo = () => {
  //   if (!this.props.showSourceDetails) {
  //     return <div className="no-source-details" />; // Return this so SourceInfo mounts and looks up extension SourceURL
  //   }

  //   const { showCloseButton, toggleShowSourceDetails } = this.props;
  //   const showSourceTextEntry = !this.props.isExtension; // PointDetail lets you enter through editable text

  //   return (
  //     <div className="editable-source-info pt-4">
  //       {/* {showCloseButton && (
  //         <div className="hide-source-details">
  //           <Icon icon={ICONS.CLOSE} onClick={toggleShowSourceDetails} />
  //         </div>
  //       )} */}
  //       {/* {showSourceTextEntry && (
  //         <div className="form-group url-form-group">
  //           {this.renderTextarea("sourceText","Quote from the source")}
  //         </div>
  //       )} */}
  //       {this.renderURLInput()}
  //       <div className="form-group url-form-group">
  //         {this.renderInput('sourceTitle', 'Headline')}
  //       </div>
  //       <div className="form-group url-form-group">
  //         {this.renderInput('sourceAuthor', 'Source Author')}
  //       </div>
  //       <Row>
  //         <Col xs={6} sm={6}>
  //           <div className="form-group url-form-group">
  //             {this.renderInput('sourceName', 'Source Name (*)')}
  //           </div>
  //         </Col>
  //         <Col xs={6} sm={6}>
  //           <div className="form-group url-form-group">
  //             {this.renderInput('sourceDate', 'Date')}
  //             <Icon
  //               icon={ICONS.HELP}
  //               toolTipText="We accept 3 date formats: MM-DD-YYYY, MM-YYYY, or YYYY"
  //             />
  //           </div>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // };

  renderInput = (fieldName, label) => {
    const { point } = this.props;

    return (
      <FloatingInput
        value={point[fieldName] || ''}
        onBlur={(e) =>
          this.props.onPointFieldChanged(fieldName, e.target.value)
        }
        onChange={(e) => {
          // if (this.props.errorMessages[fieldName]) {
          this.props.onPointFieldChanged(fieldName, e.target.value);
          // }
        }}
        labelName={label}
        disabled={!this.props.editable}
        error={this.props.errorMessages[fieldName]}
        autoFocus={fieldName === this.state.focusField}
      />
    );
  };

  // renderTextarea = (fieldName, label) => {
  //   const { point } = this.props;

  //   return (
  //     <FloatingTextarea
  //       value={point[fieldName] || ''}
  //       onBlur={(e) =>
  //         this.props.onPointFieldChanged(fieldName, e.target.value)
  //       }
  //       onChange={(e) => {
  //         if (this.props.errorMessages[fieldName]) {
  //           this.props.onPointFieldChanged(fieldName, e.target.value);
  //         }
  //       }}
  //       labelName={label}
  //       disabled={!this.props.editable}
  //       error={this.props.errorMessages[fieldName]}
  //     />
  //   );
  // };

  render() {
    // return (
    //   <div>
    //     {this.renderEditableSourceInfo()}
    //   </div>
    // );
    const className = classnames('source-info', this.props.className);
    return (
      <div className={className}>
        {this.renderURLInput()}
        {this.renderInput('sourceTitle', 'Headline')}
        {this.renderInput('sourceAuthor', 'Source Author')}
        <div className="row">
          <div className="col-xs-6">
            {this.renderInput('sourceName', 'Source Name(*)')}
          </div>
          <div className="col-xs-6">
            {this.renderInput('sourceDate', 'Date')}
            <Icon
              icon={ICONS.HELP}
              toolTipText="We accept 3 date formats: MM-DD-YYYY, MM-YYYY, or YYYY"
              toolTipClassName="position-absolute"
              toolTipStyle={{ top: '25px', right: '20px' }}
              toolTipPosition="left"
            />
          </div>
        </div>
      </div>
    );
  }

  onUrlChange = (e) => {
    const url = e.target.value;
    const expression =
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    let isValid = false;
    const { errorMessages } = this.state;
    if (!url || url.match(regex)) {
      isValid = true;
      errorMessages.sourceURL = '';
    } else {
      errorMessages.sourceURL = 'Invalid URL';
    }

    this.setState({
      errorMessages,
      sourceURL: url,
    });
  };

  lookupSourceFieldsFromURL(url) {
    const expression =
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

    const regex = new RegExp(expression);

    const { isExtension, actions } = this.props;

    const { errorMessages } = this.state;

    if (!url.match(regex)) {
      errorMessages.sourceURL = 'Invalid URL characters';
      this.props.onPointFieldChanged('sourceURL', url);
      this.setState({
        errorMessages,
      });
      return;
    }

    this.setState({
      isFetching: true,
      lookUpURL: url,
    });

    actions.showHint('info', 'Looking up source information ');
    actions
      .lookupURL(url)
      .then(
        (data) => {
          errorMessages.sourceURL = '';
          const sourceInfo = {
            sourceDomain: data.sourceDomain,
            sourceName: data.sourceName,
            sourceTitle: data.sourceTitle,
            sourceAuthor: data.sourceAuthor,
            sourceURL: data.sourceURL,
            sourceDate: data.sourceDateFormatted,
            sourceDateFormatted: data.sourceDateFormatted,
            sourceDescription: data.sourceDescription,
            sourceLogoURL: data.sourceLogoURL,
            sourcePublisher: data.sourcePublisher,
            // sourceImageURL: data.sourceImageURL
            sourceImageURL:
              data.sourceImageURL || this.props.point.sourceImageURL,
            sourceImages: data.sourceImages || [],
            showQuote: true,
          };
          this.setState(
            {
              lookUpURL: data.sourceURL,
              sourceFieldsFromURL: true,
              sourceEditable: !isExtension,
              isFetching: false,
              errorMessages,
            },
            () => {
              this.props.onPointFieldChanged('sourceInfo', sourceInfo);
            },
          );
        },
        (res) => {
          errorMessages.sourceURL = 'Invalid URL lookup';
          this.setState(
            {
              sourceFieldsFromURL: false,
              sourceEditable: !isExtension,
              isFetching: false,
              errorMessages,
              point: {
                ...this.state.point,
                sourceURL: url,
              },
            },
            () => {
              this.props.onPointFieldChanged('sourceInfo', {
                sourceURL: url,
                sourceImages: [],
              });
            },
          );
          this.props.onPointFieldChanged('sourceError', 'Invalid URL lookup');
        },
      )
      .then(() => {
        actions.hideHint();
      });
  }
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;
  return {
    user,
    authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SourceInfo);

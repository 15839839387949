import * as Actions from '../actions/index';

export default function sources(
  state = {
    pendingActions: [],
  },
  action,
) {
  switch (action.type) {
    case Actions.LOAD_PENDING_ACTIONS: {
      const pendingActions = JSON.parse(JSON.stringify(state.pendingActions));

      return {
        ...state,
        pendingActions: [...pendingActions, ...action.pendingActions],
      };
    }
    default:
      return state;
  }
}

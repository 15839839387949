/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, ICONS } from 'components/Icons';

import './EditableComponent.scss';

export default class EditableComponent extends Component {
  static propTypes = {
    onSave: PropTypes.func,
    value: PropTypes.string,
    editOnClick: PropTypes.bool,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      editableText: '',
    };
  }

  toggleEdit = e => {
    e.stopPropagation();
    if (this.state.editing) {
      this.setState({
        editing: false,
      });
    } else {
      // if (this.props.placeholder == this.domElm.textContent) {
      //   this.domElm.textContent = '';
      // }
      let { value } = this.props;
      // if (this.props.placeholder) {
      //   if (!value || value.length == 0) value = this.props.placeholder;
      // }
      this.setState(
        {
          editing: true,
          editableText: value,
        },
        () => {
          // this.domElm.focus();
          var tx = document.getElementsByTagName('textarea');
          for (var i = 0; i < tx.length; i++) {
            tx[i].setAttribute(
              'style',
              'height:' + tx[i].scrollHeight + 'px;overflow-y:hidden;',
            );
            tx[i].addEventListener('input', this.OnInput, false);
          }
          document.getElementById('editable-input').focus();
        },
      );
    }
  };

  OnInput = () => {
    if (!this || !this.style) return;

    this.style.height = 'auto';
    this.style.height = this.scrollHeight + 'px';
    //this.style.width = '400px';
  };

  save = () => {
    this.setState(
      {
        editing: false,
      },
      () => {
        if (this.props.onSave && this.props.value !== this.state.editableText) {
          this.props.onSave(this.state.editableText);
        }
      },
    );
  };

  handleKeyDown = e => {
    const { key } = e;
    switch (key) {
      case 'Enter':
      case 'Escape':
        this.save();
        break;
      default:
        break;
    }
  };

  render() {
    if (!this.state.editing) {
      let className = 'editable-text display';
      if (this.props.editOnClick) {
        className += ' edit-on-click';
      }

      if (this.props.className) {
        className += ` ${this.props.className}`;
      }

      let value = this.props.value;
      if (!value || value.length == 0) {
        if (
          this.props.editOnClick &&
          this.props.placeholder &&
          this.props.placeholder.length > 0
        ) {
          value = this.props.placeholder;
        }
      }

      const { maxLength } = this.props;
      console.log('My Stack error check');
      //console.log("Value is " + value);
      console.log('maxLength is ' + maxLength);
      let finalValue = '';
      if (value) {
        console.log('Value exists and is ' + value);
        if (maxLength) finalValue = value.substring(0, maxLength);
      } else {
        console.log('Value does not exist and is ' + value);
      }
      console.log('finalValue is ' + finalValue);

      // const finalValue =
      //   maxLength && value && value.length > maxLength
      //     ? `${value.subString(0, maxLength)}...`
      //     : value;

      return (
        <div
          className={className}
          onClick={this.props.editOnClick ? this.toggleEdit : undefined}
        >
          {finalValue}
          {this.renderEditIcon()}
        </div>
      );
    }

    return (
      <textarea
        id="editable-input"
        className={`editable-text editing ${this.props.className}`}
        onChange={e => {
          this.setState({ editableText: e.target.value });
        }}
        onBlur={this.save}
        onKeyDown={this.handleKeyDown}
        value={this.state.editableText}
        placeholder={this.props.placeholder}
        maxLength={this.props.maxLength}
      />
    );
  }

  renderEditIcon = () => {
    if (!this.props.editOnIcon) return null;

    return (
      <Icon icon={ICONS.PENCIL} onClick={this.toggleEdit} className="ml-1" />
    );
  };
}

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/index";

import "./modals.scss";

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClose = (...args) => {
    if (this.props.modals[0].isExtensionModal) {
      window.close();
    }
    this.props.hideModal(...args);
  }

  renderModal(Component, props, modalIndex) {
    let onAction = (action, ...args) => {
      return action(this.props.actions, ...args);
    };

    let onClose = response => {
      this.onClose(props.key, response);
    };

    return (
      <div key={`modal${modalIndex}`}>
        <Component
          onClose={onClose}
          onAction={onAction}
          {...props}
        />
      </div>
    );
  }

  render() {
    let modals = this.props.modals.map((modal, index) => {
      return this.renderModal(modal.component, modal.props, index);
    });

    return <div>{modals}</div>;
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    hideModal: (...args) => {
      dispatch(Actions.hideModal(...args));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalComponent);

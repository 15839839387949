export const SET_CHECKING_CLOUDINARY = 'SET_CHECKING_CLOUDINARY';
export const SET_IMAGE_AVAILABLE_IN_CLOUDINARY =
  'SET_IMAGE_AVAILABLE_IN_CLOUDINARY';

export function setCheckingCloudinary(userId, checkingCloudinary) {
  return {
    type: SET_CHECKING_CLOUDINARY,
    payload: {
      userId,
      checkingCloudinary,
    },
  };
}

export function setImageAvailablility(userId, isImageAvailable) {
  return {
    type: SET_IMAGE_AVAILABLE_IN_CLOUDINARY,
    payload: {
      userId,
      isImageAvailable,
    },
  };
}

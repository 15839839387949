import {
  START_TUTORIAL,
  RESTORE_TUTORIAL_STATE,
  GOTO_NEXT_STEP,
  COMPLETE_TUTORIAL
} from "../actions/walkthrough";

import * as Actions from "../actions/index";

// const initialState = {
//   collectionId: '',
//   pointId: '',
//   tourStatus: 'notStarted', // initial state
//   tourStep: 0,
// };

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case START_TUTORIAL:
    case RESTORE_TUTORIAL_STATE:
      return {
        ...state,
        ...payload,
        tourStatus: "finished",
      };
    // case Actions.SYNC_SESSION_SUCCESS:
    //   if (!action.data.user || !action.data.user.siteProgress) {
    //     return state;
    //   }
    //   const siteProgress = action.data.user.siteProgress;
    //   return {
    //     ...state,
    //     //tourStatus: siteProgress.tourStatus,
    //     tourStep: siteProgress.tourStep,
    //     // tourStatus: "inProgress",
    //     // tourStep: 3,
    //     tourStatus: "finished",
    //   };

    case Actions.LOGIN_SUCCESS_USER:
      const siteProgress2 = action.data.user.siteProgress;
      return {
        ...state,
        // tourStatus: siteProgress.tourStatus,
        tourStep: siteProgress2.tourStep,
        tourStatus: "finished",
      };
    case GOTO_NEXT_STEP:
      return {
        ...state,
        tourStep: payload
      };
    case Actions.COLLECTION_CREATED:
      return {
        ...state,
        collectionId:
          state.tourStatus === "inProgress" ? action.collectionPoint._id : ""
      };
    case Actions.POINT_CREATED:
      return {
        ...state,
        pointId: state.tourStatus === "inProgress" ? action.point._id : ""
      };
    case COMPLETE_TUTORIAL:
      return {
        ...initialState,
        tourStatus: payload
      };
    case Actions.LOGOUT_SUCCESS_USER:
      return initialState;
    default:
      return state;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Divider from 'components/elements/atoms/Divider/Divider';

import { Icon, ICONS } from 'components/Icons';

import './DropDown.scss';

class DropDown extends Component {
  static propTypes = {
    containerClassName: PropTypes.string,
    componentClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    menuItems: PropTypes.array, // each item has a label, className, and onClick
    setMenuState: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const containerClassName = this.props.containerClassName
      ? this.props.containerClassName
      : 'drop-down-container';
    const componentClassName = this.props.componentClassName
      ? this.props.componentClassName
      : 'drop-down-component';

    return (
      <div className={containerClassName} id={containerClassName}>
        <div
          className={componentClassName}
          id={componentClassName}
          onClick={this.toggleMenu}
        >
          {this.renderDropDownIcon()}
          {this.renderDropDownMenu()}
        </div>
      </div>
    );
  }

  renderDropDownIcon() {
    const iconClassName = this.props.iconClassName
      ? this.props.iconClassName
      : 'icon-container';

    return (
      <div className={iconClassName}>
        <Icon icon={ICONS[this.props.iconName]} />
      </div>
    );
  }

  renderDropDownMenu() {
    if (!this.state.open) return;

    const menuClassName = this.props.menuClassName
      ? this.props.menuClassName
      : 'menu';

    return (
      <div className={menuClassName}>
        {this.props.menuItems.map((menuItem, index) => {
          const menuItemClassName = menuItem.className
            ? menuItem.className
            : 'menu-item';
          return (
            <div
              key={index}
              className={menuItemClassName}
              onClick={e => {
                this.onClick(e, menuItem.onClick);
              }}
            >
              {menuItem.label}
            </div>
          );
        })}
      </div>
    );
  }

  onClick = (e, handler) => {
    e.stopPropagation();
    if (handler) {
      handler();
    }
    this.setState({ open: !this.state.open });
  };

  toggleMenu = e => {
    e.stopPropagation();
    this.setState(
      {
        open: !this.state.open,
      },
      () => {
        if (this.props.setMenuState) this.props.setMenuState(this.state.open);
      },
    );
  };
}

export default DropDown;

//Props

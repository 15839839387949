import React from 'react';
import { Modal, Button, FormControl, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import { Icon, ICONS } from 'components/Icons';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { InviteAuthors } from 'components/modals/CreatePoint/CreatePointEntryForm/components/InviteAuthors/InviteAuthors';

class CreateCollectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionPoint: {
        description: '',
        text: '',
        tags: [],
        groupId: props.groupId,
        status: props.user.settings.privacy.collection,
        // media: {
        //   type: '',
        // },
        collectionType: props.isMedia ? 'media' : 'research',
        sourceType: null,
        callToAction: {},
      },
      additionalAuthors: [],
      showCallToAction: false,
    };
  }

  onCollectionPointFieldChanged = (key, value) => {
    let collectionPoint = {
      ...this.state.collectionPoint,
    };

    collectionPoint[key] = value;

    this.setState({
      collectionPoint,
    });
  };

  onCollectionPointMetaFieldChanged = (key, value) => {
    let collectionPoint = {
      ...this.state.collectionPoint,
    };
    // collectionPoint.media[key] = value;
    if (key === 'type') collectionPoint.sourceType = value;
    this.setState({
      collectionPoint,
    });
  };

  onCollectionPointCallToActionFieldChanged = (key, value) => {
    let collectionPoint = {
      ...this.state.collectionPoint,
    };
    collectionPoint.callToAction[key] = value;
    this.setState({
      collectionPoint,
    });
  };

  validate = () => {
    const { isMedia } = this.props;
    const { collectionPoint, showCallToAction } = this.state;
    const {
      text,
      description,
      sourceURL,
      // media,
      callToAction,
      sourceType,
    } = collectionPoint;

    if (isMedia && !sourceURL) {
      this.setState({
        errorMessage: 'Please enter media URL',
      });

      return false;
    }

    if (isMedia && !sourceType) {
      this.setState({
        errorMessage: 'Please select media type',
      });

      return false;
    }

    if (text.length < 3 || text.length > 200) {
      this.setState({
        errorMessage:
          'Please give your Collection a name between 3 and 20 characters.',
      });
      return false;
    }

    if (showCallToAction && !callToAction.text) {
      this.setState({
        errorMessage: 'Please enter call to action text',
      });

      return false;
    }

    if (showCallToAction && !callToAction.url) {
      this.setState({
        errorMessage: 'Please enter call to action URL',
      });

      return false;
    }

    return true;
  };

  onSubmit = values => {
    // Do validation
    if (!this.validate()) return;

    this.props.onCreateCollection(
      this.state.collectionPoint,
      this.state.additionalAuthors,
    );
  };

  lookupURL = () => {
    let expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);
    let { collectionPoint } = this.state;

    if (collectionPoint.sourceURL && collectionPoint.sourceURL.match(regex)) {
      this.props.lookupURL(collectionPoint.sourceURL).then(res => {
        console.log(res);
        this.setState({
          collectionPoint: {
            ...this.state.collectionPoint,
            text: res.sourceTitle,
            ...res,
          },
        });
      });
    }
  };

  getAuthors() {
    const { parentCollectionId, points, groups } = this.props;
    const { collectionPoint } = this.state;

    let authors = [];

    if (parentCollectionId) {
      const pointObject = points[parentCollectionId];

      if (pointObject) {
        let pointAuthors = pointObject.point.authors.filter(
          i =>
            i.invitationStatus === 'accepted' &&
            i.userId !== this.props.user._id,
        );
        authors.push(...pointAuthors);
      }
    }

    if (collectionPoint.groupId) {
      let group = groups.find(group => group._id === collectionPoint.groupId);
      if (group) {
        authors.push(
          ...group.leaders.map(i => {
            return { userId: i._id, username: i.username };
          }),
        );
        authors.push(
          ...group.members.map(i => {
            return { userId: i._id, username: i.username };
          }),
        );
      }
    }

    return authors.reduce((unique, item) => {
      return unique.findIndex(i => i.userId === item.userId) !== -1
        ? unique
        : [...unique, item];
    }, []);
  }

  /**
   * @returns {boolean}
   */
  isAllAuthorsSelected() {
    return this.getAuthors().length === this.state.additionalAuthors.length;
  }

  /**
   *
   * @param {string} authorId author user id or 'ALL'
   */
  onSelectInviteAuthor = authorId => {
    let { additionalAuthors } = this.state;
    let authors;

    if (authorId == 'ALL') {
      if (this.isAllAuthorsSelected()) {
        authors = [];
      } else {
        authors = this.getAuthors().map(i => i.userId);
      }
    } else {
      let index = additionalAuthors.findIndex(i => i === authorId);

      if (index === -1) {
        authors = [...additionalAuthors, authorId];
      } else {
        authors = [
          ...additionalAuthors.slice(0, index),
          ...additionalAuthors.slice(index + 1),
        ];
      }
    }

    this.setState({
      additionalAuthors: authors,
    });
  };

  /**
   *
   * @param {string} authorId author user id or 'ALL'
   */
  onRemoveInviteAuthor = authorId => {
    let { additionalAuthors } = this.state;
    let authors;

    if (authorId === 'ALL') {
      authors = [];
    } else {
      let index = additionalAuthors.findIndex(i => i === authorId);
      authors = [
        ...additionalAuthors.slice(0, index),
        ...additionalAuthors.slice(index + 1),
      ];
    }

    this.setState({
      additionalAuthors: authors,
    });
  };

  renderMediaURL() {
    const { isMedia } = this.props;

    if (!isMedia) {
      return;
    }

    const collectionPoint = this.state.collectionPoint;

    return (
      <FloatingInput
        name="url"
        onChange={e =>
          this.onCollectionPointFieldChanged('sourceURL', e.target.value)
        }
        labelName="Media URL"
        value={collectionPoint.sourceURL}
        onBlur={this.lookupURL}
        autoFocus={isMedia}
      />
    );
  }

  toggleCallToAction() {
    const collectionPoint = this.state.collectionPoint;

    this.setState({
      collectionPoint: {
        ...collectionPoint,
        callToAction: {},
      },
      showCallToAction: !this.state.showCallToAction,
    });
  }

  renderMediaType() {
    const { isMedia } = this.props;

    if (!isMedia) {
      return;
    }

    const collectionPoint = this.state.collectionPoint;

    return (
      <select
        onChange={e =>
          this.onCollectionPointMetaFieldChanged('type', e.target.value)
        }
        name="type"
        value={collectionPoint.sourceType}
        className="form-control mb-4"
      >
        <option value="">Select Media Type</option>
        <option value="VIDEO">Video</option>
      </select>
    );
  }

  renderVideoMeta() {
    let { collectionPoint } = this.state;

    return (
      <div>
        <FloatingInput
          name="title"
          onChange={e =>
            this.onCollectionPointFieldChanged('sourceTitle', e.target.value)
          }
          labelName="Media Title"
          value={collectionPoint.sourceTitle}
        />
        <FloatingInput
          name="description"
          onChange={e =>
            this.onCollectionPointFieldChanged(
              'sourceDescription',
              e.target.value,
            )
          }
          labelName="Media Description"
          value={collectionPoint.sourceDescription}
        />
        <FloatingInput
          name="placeholder"
          onChange={e =>
            this.onCollectionPointFieldChanged('sourceImageURL', e.target.value)
          }
          labelName="Media Thumbnail"
          value={collectionPoint.sourceImageURL}
        />
      </div>
    );
  }

  renderMeta() {
    let { collectionPoint } = this.state;

    switch (collectionPoint.sourceType) {
      case 'VIDEO':
        return this.renderVideoMeta();
    }
  }

  renderToggleActions() {
    return (
      <div className="d-flex mb-2">
        <ActionLink
          onClick={() => this.toggleCallToAction()}
          linkClassName="ml-auto"
          iconType="averPointIcon"
          iconName="ADD"
          iconClassName="av-blue mr-2"
          label="Call To Action"
        />
      </div>
    );
  }

  renderCallToAction() {
    let { collectionPoint, showCallToAction } = this.state;

    if (!showCallToAction) {
      return null;
    }

    return (
      <div>
        <FloatingInput
          name="text"
          onChange={e =>
            this.onCollectionPointCallToActionFieldChanged(
              'text',
              e.target.value,
            )
          }
          labelName="CTA Action"
          value={collectionPoint.callToAction.text}
        />
        <FloatingInput
          name="url"
          onChange={e =>
            this.onCollectionPointCallToActionFieldChanged(
              'url',
              e.target.value,
            )
          }
          labelName="URL"
          value={collectionPoint.callToAction.url}
        />
      </div>
    );
  }

  renderInviteAuthors() {
    let authors = this.getAuthors();

    if (authors.length === 0) {
      return null;
    }

    return (
      <div className="position-relative">
        <p className="av-blue">Invite Authors</p>

        <InviteAuthors
          authors={authors}
          selectedAuthors={this.state.additionalAuthors}
          onSelect={this.onSelectInviteAuthor}
          onRemove={this.onRemoveInviteAuthor}
          allAuthorsText="All Authors"
        />
      </div>
    );
  }

  renderSelectGroup = () => {
    const { isMedia } = this.props;

    if (this.props.groupId || this.props.groups.length === 0 || isMedia) {
      return null;
    }

    const collectionPoint = this.state.collectionPoint;

    return (
      <FormGroup controlId="formControlsSelect">
        {/* <ControlLabel>Add to your group</ControlLabel> */}
        <p className="av-blue">Add to your group</p>
        <FormControl
          componentClass="select"
          placeholder="Select group"
          value={collectionPoint.groupId}
          onChange={e => {
            console.log('selecting a group');
            this.onCollectionPointFieldChanged('groupId', e.target.value);

            this.setState({ additionalAuthors: [] });
          }}
        >
          <option value="">Select</option>
          {this.props.groups.map(g => (
            <option value={g._id} key={g._id}>
              {g.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  };

  render() {
    let { isMedia } = this.props;
    const collectionPoint = this.state.collectionPoint;

    return (
      <Modal
        show={this.props.showCreateCollection}
        className="create-collection-modal"
      >
        <Modal.Header>
          <Button
            bsStyle="link"
            className="btn-close"
            onClick={this.props.onClose}
          >
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <Modal.Title>
            <Icon icon={ICONS.ADD} /> Add new collection
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4">
          <div className="create-collection-wrapper">
            {this.renderMediaURL()}
            {this.renderMediaType()}
            {this.renderMeta()}
            <div className="focus-helptext-within">
              <FloatingInput
                text="name"
                id="collection-name"
                value={collectionPoint.text}
                onChange={e =>
                  this.onCollectionPointFieldChanged('text', e.target.value)
                }
                labelName="Title"
                autoFocus={!isMedia}
              />

              <FloatingInput
                name="name"
                id="collection-name"
                value={collectionPoint.description}
                onChange={e =>
                  this.onCollectionPointFieldChanged(
                    'description',
                    e.target.value,
                  )
                }
                labelName="Description"
              />
              {this.renderSelectGroup()}
            </div>
            {this.renderInviteAuthors()}
            {this.renderToggleActions()}
            {this.renderCallToAction()}
            <div className="focus-helptext-within">
              <p className="av-blue">Visibility</p>
              <div className="collectionPoint-visibility-btns form-group">
                <button
                  type="button"
                  onClick={() =>
                    this.onCollectionPointFieldChanged('status', 'public')
                  }
                  className={`btn btn-outline btn-default ${
                    collectionPoint.status === 'public' ? 'selected' : ''
                  }`}
                >
                  <Icon icon={ICONS.GLOBE} /> Public
                </button>

                <button
                  type="button"
                  onClick={() =>
                    this.onCollectionPointFieldChanged('status', 'private')
                  }
                  className={`btn btn-outline btn-default ${
                    collectionPoint.status === 'private' ? 'selected' : ''
                  } ml-2`}
                >
                  <Icon icon={ICONS.LOCK} /> Private
                </button>
              </div>
            </div>

            {/* <div className="focus-helptext-within">
              <p className="help-text">Tags</p>
              <AddTags
                tags={collectionPoint.tags}
                setTags={(tags)=>this.onCollectionPointFieldChanged("tags",tags)}
                autofocus={false}
                //tagsLimit={10}
              />
            </div> */}
            <div className="error-message">{this.state.errorMessage}</div>
            <div className="error-message">{this.props.errorMessage}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <RoundedButton label="Create" onClick={this.onSubmit} />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const points = state.points;
  return {
    points,
  };
}

export default connect(mapStateToProps)(CreateCollectionComponent);

import React, { useState } from "react";
import classnames from "classnames";
import _ from "lodash";

import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";
import RadioGroup from "components/elements/molecules/Radio/RadioGroup";
import RadioOption from "components/elements/molecules/Radio/RadioOption";
import Checkbox from "components/elements/atoms/Checkbox/Checkbox";
import ProfileImage from "components/elements/molecules/Images/profileImage";
import { Icon, ICONS } from "components/Icons";

export default class InvitePointComponent extends React.Component {
  renderInviteOptions() {
    let { inviteOptions } = this.props;

    if (inviteOptions) {
      return (
        <div className="mt-3">
          <RadioGroup
            name="inviteOption"
            label=""
            handleChange={e => {
              this.props.setInviteOption(e.target.value);
            }}
            errorMessage=""
          >
            {inviteOptions.map(opt => {
              return (
                <RadioOption
                  key={opt.label}
                  name="inviteOption"
                  id={opt.value}
                  value={opt.value}
                  label={opt.label}
                  checked={this.props.inviteOption === opt.value}
                />
              );
            })}
          </RadioGroup>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <InviteUsers
          users={this.props.userList}
          selectedUsers={this.props.selectedUsers}
          invitedUsers={this.props.invitedUsers}
          addUser={this.props.addUser}
          removeUser={this.props.removeUser}
          type={this.props.type}
          searchTerm={this.props.searchTerm}
          setSearchTerm={this.props.setSearchTerm}
        />
        {this.renderInviteOptions()}
        <div className="mt-3 text-center">
          <RoundedButton
            disabled={
              this.props.selectedUsers.length === 0 || this.props.isSending
            }
            onClick={this.props.onInvite}
            label={this.props.isSending ? "Sending ..." : "Send"}
          />
        </div>
      </div>
    );
  }

  filterOptions(dataList, searchString, selectedValues = []) {
    // console.log("In filter options: there are "+dataList.length+" users");
    if (searchString) {
      dataList = _.filter(
        dataList,
        data =>
          //console.log("In filter options - and the user is "+JSON.stringify(data));
          data &&
          data.username.toLowerCase().search(searchString.toLowerCase()) > -1
      );

      dataList = _.sortBy(dataList, "username");
    }

    return _.difference(dataList, selectedValues);
    // return _.difference(_.sortBy(dataList, 'username'), selectedValues);
  }

  renderUserOption(user) {
    return user.username;
  }
}

function InviteUsers({
  users,
  selectedUsers,
  invitedUsers,
  removeUser,
  addUser,
  type,
  searchTerm,
  setSearchTerm,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [clearSearchTermOnClose, setClearSearchTermOnClose] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  function setSearchInputFocus() {
    searchInput && searchInput.focus();
  }

  const onAddUser = user => {
    setClearSearchTermOnClose(true);
    addUser(user);
    setShowDropdown(false);
    setSearchTerm("");
    setSearchInputFocus();
  };

  const onRemoveUser = user => {
    removeUser(user);
    setSearchInputFocus();
  };

  const close = () => {
    if (clearSearchTermOnClose) {
      setSearchTerm("");
      setClearSearchTermOnClose(false);
    }

    setSearchInputFocus();
    setShowDropdown(false);
  };

  const open = () => {
    setShowDropdown(true);
    setSearchInputFocus();
  };

  const renderUser = user => {
    const checked = selectedUsers.findIndex(i => i._id === user._id) !== -1;
    const isInvited = invitedUsers.findIndex(i => i._id === user._id) !== -1;
    let invited;

    if (invited) {
      invited = (
        <div>
          <Icon icon={ICONS.CHECK} size={12} className="mr-3" /> Invited
        </div>
      );
    }

    let className = classnames("user p-3 d-flex align-items-cente", {
      "av-grayish-cyan": isInvited,
    });

    return (
      <div key={user._id} className={className}>
        <Checkbox
          handleChange={() => {
            checked ? onRemoveUser(user) : onAddUser(user);
          }}
          checked={checked || isInvited}
          disabled={isInvited}
        />
        <div
          className="flex-fill"
          onClick={e => {
            checked ? onRemoveUser(user) : onAddUser(user);
          }}
        >
          <div className="d-inline mr-3">
            <ProfileImage
              userId={user._id}
              allowUserToChangePic={false}
              allowClickToPortfolio={false}
              username={user.username}
              size={24}
            />
          </div>
          <span>{user.username}</span>
        </div>
        {invited}
      </div>
    );
  };

  const renderUsers = () => {
    return users
      .filter(
        i => i.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      )
      .slice(0, 200)
      .map(renderUser);
  };

  const renderSelectedUser = user => {
    return (
      <div
        className="d-inline-block mr-3 user pt-1 pb-1 pl-3 pr-3 mb-2"
        key={user._id || user}
      >
        <span>{user.username || user}</span>
        <Icon
          icon={ICONS.CLOSE}
          className="av-red ml-3"
          size={9}
          onClick={() => {
            removeUser(user);
          }}
        />
      </div>
    );
  };

  const renderSelectedUsers = () => {
    return selectedUsers.map(renderSelectedUser);
  };

  const addIfPossible = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(searchTerm);

    if (isValidEmail) {
      if (selectedUsers.indexOf(searchTerm) === -1) {
        addUser(searchTerm);
        setSearchTerm("");
        close();
      } else {
        setSearchError(`${searchTerm} email already added.`);
      }
    } else if (selectedUsers.findIndex(i => i.username === searchTerm) === -1) {
      if (type === "GROUP") {
        setSearchError("Invalid email");
      } else {
        const user = users.find(i => i.username === searchTerm);

        if (user) {
          addUser(user);
          setSearchTerm("");
          close();
        } else {
          setSearchError(`Invalid username ${searchTerm}`);
        }
      }
    } else {
      setSearchError(`${searchTerm} username already added.`);
    }
  };

  const onKeyDownSearch = e => {
    setSearchError("");

    if (e.keyCode === 27) {
      close();
    } else if (e.keyCode === 9) {
      close();
      addIfPossible();
    } else if (
      searchTerm &&
      (e.keyCode === 13 || e.keyCode === 188 || e.keyCode === 32)
    ) {
      addIfPossible();
      e.preventDefault();
    } else {
      if (searchTerm.length >= 2) {
        open();
      }
    }
  };

  function renderDropdown() {
    if (type === "GROUP") {
      return;
    }

    if (searchTerm.length < 3) {
      return null;
    }

    let dropdownClassName = classnames("user-list position-absolute", {
      "d-block": showDropdown || searchTerm.length,
      "d-none": !showDropdown || searchTerm.length === 0,
    });

    return (
      <div className={dropdownClassName}>
        <Icon
          icon={ICONS.CLOSE}
          className="position-absolute av-grayish-cyan"
          style={{ right: "10px", top: "10px", cursor: "pointer" }}
          onClick={() => close()}
        />
        {type !== "GROUP" ? renderUsers() : null}
      </div>
    );
  }

  let inputClassName = classnames("border-0 mb-2 pt-1 pb-1", {
    "w-100": selectedUsers.length == 0,
  });

  let error;

  if (searchError) {
    error = (
      <p className="text-danger mt-1 mb-1">
        <small>
          <i>{searchError}</i>
        </small>
      </p>
    );
  }

  let placeholder =
    type === "GROUP"
      ? "Enter emails separated by commas"
      : "Search users or enter email to invite";

  return (
    <div className="invite-users position-relative">
      <div className="selected-container">
        {renderSelectedUsers()}
        <input
          type="text"
          className={inputClassName}
          placeholder={selectedUsers.length == 0 ? placeholder : ""}
          value={searchTerm}
          // onFocus={() => open()}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={onKeyDownSearch}
          style={{ width: "100px" }}
          ref={ref => setSearchInput(ref)}
          autoFocus={true}
        />
      </div>
      {error}
      {renderDropdown()}
    </div>
  );
}

export const OPEN_BOTTOM_SHEET = 'OPEN_BOTTOM_SHEET';
export const HIDE_BOTTOM_SHEET = 'HIDE_BOTTOM_SHEET';

export function openBottomSheet(props = {}) {
  return {
    type: OPEN_BOTTOM_SHEET,
    data: {
      child: props.child,
    },
  };
}

export function hideBottomSheet() {
  return {
    type: HIDE_BOTTOM_SHEET,
  };
}

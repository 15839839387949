/* eslint-disable no-underscore-dangle */
import move from 'lodash-move';
import * as Actions from '../actions/index';
import { resolved } from '../createAction';
import { ADD_COLLECTION_TO_GROUP } from '../actions/index';

import mockPoints from './mockData/point.json';
import examplePoints from './mockData/Examples';

const initialState = {
  pointList: [],
  selectedPoints: [],
};

mockPoints.forEach((mp) => {
  initialState[mp._id] = { point: mp };
});

examplePoints.forEach(
  (point) => (initialState[point._id] = { point, rtps: [], qtps: [] }),
);

const getPointAuthorInfo = (point, user) => {
  const pointObject = { point };
  if (user) {
    const author = point.authors.find((a) => {
      return a.username === user.username;
    });
    if (author) {
      pointObject.permissionLevel = author.permissionLevel;
      pointObject.invitationStatus = author.invitationStatus;
      pointObject.inviterUsername = author.inviterUsername;
      pointObject.inviterUserId = author.inviterUserId;
      pointObject.isAuthor =
        author.permissionLevel === 'write' &&
        author.invitationStatus === 'accepted';
    }
  }
  return pointObject;
};

export default function points(state = initialState, action) {
  switch (action.type) {
    case Actions.POINT_CREATED: {
      const pointObject = {
        point: action.point,
        invitationStatus: 'accepted',
        permissionLevel: 'write',
        isAuthor: true,
      };
      return Object.assign({}, state, {
        [action.point._id]: pointObject,
      });
    }
    case Actions.LOAD_POINT: {
      const user = action.user ? action.user.user : null;

      const pointObjectWithAuthorInfo = getPointAuthorInfo(
        action.pointObject.point,
        user,
      );

      action.pointObject = {
        ...pointObjectWithAuthorInfo,
        ...action.pointObject,
      };

      const subPoints = action.pointObject.subPoints;
      const subPointsForState = {};
      subPoints.forEach((subPoint) => {
        let pointObject;

        pointObject = getPointAuthorInfo(subPoint, user);

        pointObject.following = action.pointObject.following;

        subPointsForState[subPoint._id] = pointObject;
      });

      return Object.assign({}, state, {
        ...subPointsForState,
        [action.pointObject.point._id]: {
          ...state[action.pointObject.point._id],
          ...action.pointObject,
        },
      });
    }
    case resolved(Actions.GET_GROUP_COLLECTIONS): {
      const collectionPoints = action.payload;
      let collectionPointsJSON = {};
      collectionPoints.map((collectionPoint) => {
        collectionPointsJSON[collectionPoint._id] = { point: collectionPoint };
      });
      return Object.assign({}, state, {
        ...collectionPointsJSON,
      });
    }
    // case Actions.SYNC_SESSION_SUCCESS:
    case Actions.LOGIN_SUCCESS_USER:
    case Actions.LOAD_PORTFOLIO_COLLECTION_POINTS: {
      const collectionPoints = action.data.collectionPoints;
      let collectionPointsJSON = {};
      collectionPoints.map((collectionPoint) => {
        let newCollectionPointObject;

        newCollectionPointObject = getPointAuthorInfo(
          collectionPoint,
          action.data.currentUser,
        );

        collectionPointsJSON[collectionPoint._id] = newCollectionPointObject;
      });

      return Object.assign({}, state, {
        ...collectionPointsJSON,
        // ...invitedPointsJSON,
      });
    }
    case Actions.LOAD_COLLECTION: {
      const points = action.collectionObject.points;
      const pointsForState = {};
      points.forEach((point) => {
        let pointObject;
        const user = action.user ? action.user.user : null;
        pointObject = getPointAuthorInfo(point, user);
        pointsForState[point._id] = pointObject;
      });
      const pointObject = {
        ...state[action.collectionObject.collectionPoint._id],
        point: {
          ...action.collectionObject.collectionPoint,
          following: action.collectionObject.following,
        },
        permissionLevel: action.collectionObject.permissionLevel,
        invitationStatus: action.collectionObject.invitationStatus,
        isAuthor: action.collectionObject.isAuthor,
      };
      pointsForState[action.collectionObject.collectionPoint._id] = pointObject;

      return Object.assign({}, state, {
        ...pointsForState,
      });
    }
    case 'START_SAVING_POINT': {
      var newPointObject = state[action.pointId];
      if (!newPointObject) {
        console.log(
          "Saving a Point that's not in state yet. It's probably new.",
        );
        return state;
      }
      newPointObject.isSaving = true;
      return {
        ...state,
        [action.pointId]: newPointObject,
      };
    }
    case 'START_EDITING_POINT': {
      let newPointObject = state[action.data.pointId];
      if (!newPointObject) {
        console.log("Editing a Point that's not in state yet.");
        return state;
      }
      newPointObject.isEditing = true;
      return {
        ...state,
        [action.data.pointId]: newPointObject,
      };
    }
    case 'FINISH_EDITING_POINT': {
      let newPointObject = {
        ...state[action.data.pointId],
      };
      if (!newPointObject) {
        console.log("Finish editing a Point that's not in state yet.");
        return state;
      }
      newPointObject.isEditing = false;
      return {
        ...state,
        [action.data.pointId]: newPointObject,
      };
    }
    case Actions.UPDATE_POINT: {
      let newPointObject = state[action.point._id];
      //if action has field - update that field, otherwise leave it as is

      if (!newPointObject) {
        console.log("Error - trying to update Point but it's not in state");
        return state;
      }

      newPointObject = {
        ...newPointObject,
        point: {
          ...newPointObject.point,
          ...action.point,
        },
      };

      // newPointObject.point = {
      //   ...newPointObject.point,
      //   ...action.point
      // };

      newPointObject.isSaving = false;

      const subPointsForState = {};
      if (action.subPoints) {
        newPointObject.subPoints = action.subPoints;
        const subPoints = action.subPoints;

        subPoints.forEach((subPoint) => {
          let pointObject;
          const user = action.user ? action.user.user : null;
          pointObject = getPointAuthorInfo(subPoint, user);

          subPointsForState[subPoint._id] = pointObject;
        });
      }
      if (action.userSupports != null) {
        newPointObject.userSupports = action.userSupports;
      }
      if (action.userOpposes != null) {
        newPointObject.userOpposes = action.userOpposes;
      }
      if (action.supporters) newPointObject.supporters = action.supporters;
      if (action.opponents) newPointObject.opponents = action.opponents;
      if (action.userPortfolio)
        newPointObject.userPortfolio = action.userPortfolio;
      if (action.suggestedRevisions)
        newPointObject.suggestedRevisions = action.suggestedRevisions;
      if (action.alreadyReviewed)
        newPointObject.alreadyReviewed = action.alreadyReviewed;
      if (action.reviews) newPointObject.reviews = action.reviews;
      if (action.pointId) newPointObject.reviews = action.pointId;
      if (action.permissionLevel)
        newPointObject.permissionLevel = action.permissionLevel;
      if (action.invitationStatus)
        newPointObject.invitationStatus = action.invitationStatus;
      if (action.notificationPreferences)
        newPointObject.notificationPreferences = action.notificationPreferences;

      if (action.requestsFor) {
        newPointObject.requestsFor = action.requestsFor;
      }
      if (action.qtps) {
        newPointObject.qtps = action.qtps;
      }
      if (action.rtps) {
        newPointObject.rtps = action.rtps;
      }
      if (action.ctps) {
        newPointObject.ctps = action.ctps;
      }
      if (action.stps) {
        newPointObject.stps = action.stps;
      }
      return Object.assign({}, state, {
        [action.point._id]: newPointObject,
        ...subPointsForState,
      });
    }
    case Actions.UPDATE_POINTS: {
      if (action.updatedPoints) {
        const updatedPoints = action.updatedPoints;

        const pointsForState = {};
        updatedPoints.forEach((point) => {
          //If the point is in state, replace it with the updated Point
          let pointObject;
          const user = action.user ? action.user.user : null;
          pointObject = getPointAuthorInfo(point, user);

          if (state[point._id]) {
            pointObject = {
              ...state[point._id],
              ...pointObject,
            };
          }

          pointsForState[point._id] = pointObject;
        });
        return Object.assign({}, state, {
          ...pointsForState,
        });
      }
      break;
    }
    case 'DELETE_POINTS': {
      let newState = {};
      if (action.deletedPointIds) {
        action.deletedPointIds.forEach((pointId) => {
          newState[pointId] = null;
        });
      }
      return Object.assign({}, state, {
        ...newState,
      });
    }
    case Actions.POINT_REVIEWED: {
      var newPointObject = state[action.point._id];
      newPointObject.point = action.point;
      newPointObject.userPointReview = action.newPointReview;
      if (action.updatedPointReviews) {
        //If the server returned all the reviews, accept those
        newPointObject.reviews = action.updatedPointReviews;
      } else {
        //Otherwise, just update the single review
        var existingReviewIndex = newPointObject.reviews.findIndex((review) => {
          return review._id == action.newPointReview._id;
        });

        if (existingReviewIndex != -1) {
          newPointObject.reviews[existingReviewIndex] = action.newPointReview;
        } else {
          newPointObject.reviews.push(action.newPointReview);
        }
      }

      return Object.assign({}, state, {
        [action.point._id]: newPointObject,
      });
    }
    case Actions.POINT_CHALLENGED: {
      var newPointObject = state[action.point._id];

      newPointObject.point = action.point;
      newPointObject.userPointReview = action.newPointReview;
      newPointObject.reviews = action.updatedPointReviews;

      return Object.assign({}, state, {
        [action.point._id]: newPointObject,
      });
    }
    case Actions.POINT_AUTHORS_INVITED: {
      var newPointObject = state[action.point._id];
      newPointObject.point = action.point;
      return Object.assign({}, state, {
        [action.point._id]: newPointObject,
      });
    }
    case Actions.POINT_AUTHORS_ACCEPTED: {
      var newPointObject = state[action.point._id];
      newPointObject.point = action.point;
      newPointObject.invitationStatus = action.invitationStatus;
      newPointObject.permissionLevel = action.permissionLevel;
      return Object.assign({}, state, {
        [action.point._id]: newPointObject,
      });
    }
    case Actions.COLLECTION_ERROR:
    case Actions.POINT_ERROR: {
      if (action.pointId) {
        return {
          ...state,
          [action.pointId]: {
            errorMessage: action.errorMessage,
          },
        };
      }

      return {
        ...state,
      };
    }
    case Actions.NEW_POINT_LIST_ITEMS: {
      var newPointList = state.pointList;
      newPointList =
        newPointList && newPointList.concat(action.newPointListItems);
      return Object.assign({}, state, {
        pointList: newPointList,
      });
    }
    case Actions.POINT_ADDED_TO_COLLECTION: {
      const user = action.user ? action.user.user : null;
      let newState = {};

      if (action.point) {
        let newPointObject = getPointAuthorInfo(action.point, user);
        if (state[action.point._id])
          newPointObject = {
            ...state[action.point._id],
            ...newPointObject,
          };
        newState[action.point._id] = newPointObject;
      }

      if (action.points)
        action.points.forEach((newPoint) => {
          let newPointObject = getPointAuthorInfo(newPoint, user);
          if (state[newPoint._id])
            newPointObject = {
              ...state[newPoint._id],
              ...newPointObject,
            };
          newState[newPoint._id] = newPointObject;
        });

      let newCollectionPointObject = state[action.collectionPoint._id];
      if (newCollectionPointObject) {
        newCollectionPointObject.point = action.collectionPoint;
      } else {
        newCollectionPointObject = {
          point: action.collectionPoint,
        };
      }
      newState[action.collectionPoint._id] = newCollectionPointObject;
      return Object.assign({}, state, newState);
    }
    case Actions.POINT_CLEAR: {
      return initialState;
    }
    case Actions.UPDATE_USER: {
      let updatedPoints = {};
      Object.keys(state).forEach((pointId) => {
        const pointObject = state[pointId];
        if (
          pointObject &&
          pointObject.point &&
          pointObject.point.authorId === action.user._id
        ) {
          const newPointObject = {
            ...pointObject,
          };
          newPointObject.point.hostedImageVersion =
            action.user.hostedImageVersion;
          updatedPoints.pointId = newPointObject;
        }
      });
      return Object.assign({}, state, {
        ...updatedPoints,
      });
    }
    case Actions.UPDATE_MESSAGE_THREAD: {
      var newPointObject = state[action.pointId];
      newPointObject.reviews.forEach((item) => {
        if (item._id == action.reviewId) {
          item.messageThread = action.messageThread;
        }
      });
      if (
        newPointObject.userPointReview &&
        newPointObject.userPointReview._id == action.reviewId
      ) {
        newPointObject.userPointReview.messageThread = action.messageThread;
      }
      return Object.assign({}, state, {
        [action.pointId]: newPointObject,
      });
    }
    case Actions.MOVE_SUBPOINT: {
      const { dragParent, targetParent } = action;

      if (dragParent.type != 'point' && dragParent.type != 'collection') {
        console.log("The dragParent is not a Point - can't handle this here.");
        return state;
      }

      if (targetParent.type != 'point' && targetParent.type != 'collection') {
        console.log(
          "The targetParent is not a Point - can't handle this here.",
        );
        return state;
      }

      let dragParentPointObject = state[dragParent.id];
      let targetParentPointObject = state[targetParent.id];

      let dragParentPoint = dragParentPointObject.point;
      let dragSubPoints = [...dragParentPoint.subPoints];

      /************ Dragging with in the same parent
       *  Just swap indeces
       */
      if (dragParent.id == targetParent.id) {
        console.log('moving points within the same parent');
        const dragSubPointIndex = dragSubPoints.findIndex(
          (i) => i.pointId === targetParent.list[dragParent.index],
        );
        const targetSubPointIndex = dragSubPoints.findIndex(
          (i) => i.pointId === targetParent.list[targetParent.index],
        );
        const dragSubPoint = { ...dragSubPoints[dragSubPointIndex] };
        dragSubPoints[dragSubPointIndex] = {
          ...dragSubPoints[targetSubPointIndex],
        };
        dragSubPoints[targetSubPointIndex] = dragSubPoint;
        dragParentPointObject.point.subPoints = dragSubPoints;

        return Object.assign({}, state, {
          [dragParent.id]: dragParentPointObject,
        });
      }

      console.log('moving points across different parents');

      /************ Dragging across different levels
       * 1. Remove the source from the dragParent
       * 2. Add the source to the targetParent
       * 3. Account for boundary cases
       */

      let dragSubPoint;

      console.log('removing a subPoint from drag parent point');
      dragSubPoint = dragParentPoint.subPoints[action.dragParent.index];
      const newSourceSubPoints = dragSubPoints
        .slice(0, dragParent.index)
        .concat(
          dragSubPoints.slice(dragParent.index + 1, dragSubPoints.length),
        );
      dragParentPoint.subPoints = newSourceSubPoints;
      dragParentPointObject.point = dragParentPoint;

      console.log('adding a subPoint to target parent point');
      let targetParentPoint = state[targetParent.id].point;
      const targetSubPoints = targetParentPoint.subPoints;

      const beginning = targetSubPoints.slice(0, targetParent.index);
      const end = targetSubPoints.slice(
        targetParent.index,
        targetSubPoints.length,
      );
      const newTargetSubPoints = beginning.concat(dragSubPoint, end);
      targetParentPoint.subPoints = newTargetSubPoints;

      targetParentPointObject.point = targetParentPoint;

      return Object.assign({}, state, {
        [dragParent.id]: dragParentPointObject,
        [targetParent.id]: targetParentPointObject,
      });
    }
    case Actions.SEARCH_PORTFOLIO:
      const data = {};
      action.payload.points.forEach((point) => {
        if (!state[point._id]) {
          point.dataFrom = 'search';
          data[point._id] = {
            point,
          };
        }
      });
      action.payload.collectionPoints.forEach((point) => {
        if (!state[point._id]) {
          point.dataFrom = 'search';
          data[point._id] = {
            point,
          };
        }
      });
      return {
        ...state,
        ...data,
      };
    case Actions.SEARCH_COLLECTION_PORTFOLIO:
      const newData = {};
      action.payload.points.forEach((point) => {
        if (!state[point._id]) {
          point.dataFrom = 'search';
          newData[point._id] = {
            point,
          };
        }
      });
      action.payload.collectionPoints.forEach((point) => {
        if (!state[point._id]) {
          point.dataFrom = 'search';
          newData[point._id] = {
            point,
          };
        }
      });
      return {
        ...state,
        ...newData,
      };
    case Actions.FOLLOW_COLLECTION: {
      const pointState = state[action.id];
      pointState.point.followersCount =
        (pointState.point.followersCount || 0) + 1;
      pointState.point.following = true;
      return {
        ...state,
      };
    }
    case Actions.UNFOLLOW_COLLECTION: {
      const pointState = state[action.id];
      pointState.point.followersCount =
        (pointState.point.followersCount || 0) - 1;
      pointState.point.following = false;
      return {
        ...state,
      };
    }
    case Actions.FOLLOW_POINT: {
      const pointState = state[action.id];
      pointState.following = true;
      return {
        ...state,
        [action.id]: { ...pointState },
      };
    }
    case Actions.UNFOLLOW_POINT: {
      const pointState = state[action.id];
      pointState.following = false;
      return {
        ...state,
        [action.id]: { ...pointState },
      };
    }
    case Actions.TOGGLE_SELECT_POINT: {
      const { selectedPoints } = state;
      if (selectedPoints.includes(action.point)) {
        return {
          ...state,
          selectedPoints: selectedPoints.filter((p) => p !== action.point),
        };
      }
      return {
        ...state,
        selectedPoints: [...selectedPoints, action.point],
      };
    }
    case Actions.CLEAR_POINT_SELECTIONS:
      return {
        ...state,
        selectedPoints: [],
      };
    case resolved(Actions.REMOVE_COLLECTION_FROM_GROUP):
    case resolved(Actions.ADD_COLLECTION_TO_GROUP): {
      const point = state[action.meta.collectionId];
      point.point = action.payload;
      return {
        ...state,
        [point._id]: {
          ...point,
        },
      };
    }
    case Actions.ADD_QTP: {
      const { point, qtp } = action;
      const qtps = state[point._id].qtps;

      if (qtps && qtps.find((i) => i._id === qtp._id)) {
        const index = qtps.findIndex((i) => i._id === qtp._id);
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            qtps: [...qtps.slice(0, index), qtp, ...qtps.slice(index + 1)],
          },
        };
      } else {
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            qtps: [...(state[point._id].qtps || []), qtp],
          },
        };
      }
    }
    case Actions.ADD_RTP: {
      const { point, rtp } = action;
      const rtps = state[point._id].rtps || [];

      const index = rtps.findIndex((i) => i._id === rtp._id);

      if (index !== -1) {
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            rtps: [...rtps.slice(0, index), rtp, ...rtps.slice(index + 1)],
          },
        };
      } else {
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            rtps: [...rtps, rtp],
          },
        };
      }
    }
    case Actions.ADD_CTP: {
      const { point, ctp } = action;
      const ctps = state[point._id].ctps;

      if (ctps && ctps.find((i) => i._id === ctp._id)) {
        const index = ctps.findIndex((i) => i._id === ctp._id);
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            ctps: [...ctps.slice(0, index), ctp, ...ctps.slice(index + 1)],
          },
        };
      } else {
        return {
          ...state,
          [point._id]: {
            ...state[point._id],
            ctps: [...(state[point._id].ctps || []), ctp],
          },
        };
      }
    }
    case Actions.REMOVE_CTP: {
      const { point, ctp } = action;
      const ctps = state[point._id].ctps;

      const newCTPs = ctps.filter((i) => i._id !== ctp._id);
      return {
        ...state,
        [point._id]: {
          ...state[point._id],
          ctps: newCTPs,
        },
      };
    }
    case Actions.LOAD_DUPLICATE_POINTS: {
      const { duplicatePoints } = action;

      return {
        ...state,
        duplicatePoints,
      };
    }
    default:
      return state;
  }
}

import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {};

export default function writingReviews(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_WRITING_REVIEWS: {
      return {
        ...state,
        [action.writingId]: action.reviews,
      };
    }
    default:
      return state;
  }
}

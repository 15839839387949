import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import CreatePointContainer from 'components/modals/CreatePoint/CreatePointContainer';
import * as Actions from '../../../redux/actions/index';

import ImportPoint from './ImportPoint.js';
import { Icon, ICONS } from '../../Icons';
import './AddPoint.scss';

class AddPoint extends Component {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    model: PropTypes.object,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
    placeholder: PropTypes.string,
    focusPointText: PropTypes.bool,
  };

  static defaultProps = {
    type: 'DEFAULT',
  };

  constructor(props) {
    super(props);
    this.state = {
      collectionObject: null,
      lightWeightTab: 'CREATE',
    };
  }

  componentDidMount() {
    const { user, actions, history, portfolioObject } = this.props;

    if (!user || !user.username) {
      history.push('/login');
    } else {
      if (!portfolioObject) {
        actions.getPortfolio(user.username);
        actions.getPortfolioCollectionPoints(user.username);
      } else if (!portfolioObject.isCollectionPointsLoaded) {
        actions.getPortfolioCollectionPoints(user.username);
      }
    }
  }

  createClaim = async () => {
    let mediaClaim = this.props.mediaClaim;
    if (!mediaClaim._id) {
      // mediaClaim hasn't been created yet
      mediaClaim.isMock = this.props.isMock;
      const claim = {
        mediaClaimPoint: mediaClaim,
        articleMediaCollection: this.props.collectionPoint,
      };
      const res = await this.props.actions.createMediaClaim(claim);
      mediaClaim = res.mediaClaimPoint;
    }

    return mediaClaim;
  };

  onPointImported = async (pointId, stance) => {
    const { model, points } = this.props;
    let collectionId = model.collectionId;

    if (model.isProvidingEvidence) {
      const claim = await this.createClaim();
      collectionId = claim._id;
    }

    this.props.actions.saveSubPointToPoint(
      null,
      collectionId,
      stance,
      // model.timeStamp,
      pointId,
    );
    this.props.onClose(
      false,
      pointId.map((i) => points[i].point),
    );
  };

  quote = (string) => {
    const escapable = /[\\\"\x00-\x1f\x7f-\uffff]/g;
    let meta = {
      // table of character substitutions
      '\b': '\\b',
      '\t': '\\t',
      '\n': '\\n',
      '\f': '\\f',
      '\r': '\\r',
      '"': '\\"',
      '\\': '\\\\',
    };

    // If the string contains no control characters, no quote characters, and no
    // backslash characters, then we can safely slap some quotes around it.
    // Otherwise we must also replace the offending characters with safe escape
    // sequences.

    escapable.lastIndex = 0;
    return escapable.test(string)
      ? `"${string.replace(escapable, function (a) {
          let c = meta[a];
          return typeof c === 'string'
            ? c
            : `\\u${('0000' + a.charCodeAt(0).toString(16)).slice(-4)}`;
        })}"`
      : `"${string}"`;
  };

  renderPointTabs() {
    let { model, type, placeholder } = this.props;

    // Decode potentially encoded sourceText - ask Amitava
    let decodedSourceText = '';
    if (model.sourceText) {
      decodedSourceText = model.sourceText.replace(/%20/g, ' '); // white space
      decodedSourceText = decodedSourceText.replace(/%C2%A0/g, ''); // white space
      decodedSourceText = decodedSourceText.replace(/%E2%80%94 /g, ' - '); // dash
    }

    //For testing extension
    // model = {
    //   ...model,
    //   pointId: "5d2130c5d41d2f42c331c74b",
    //   sourceUrl: "https://www.nytimes.com/2019/08/08/upshot/biden-iowa-biggest-weakness.html?action=click&module=MoreInSection&pgtype=Article&region=Footer&contentCollection=The%20Upshot",
    //   isExtension: true,
    //   hostedImage: "https://res.cloudinary.com/averpoint/image/upload/v1565807647/points/5d2130c5d41d2f42c331c74b.jpg",
    //   imageType: "hosted",
    // }

    // const decodedSourceText = this.quote(model.sourceText);
    // console.log('In AddPoint');
    // console.log(`isExtension is ${model.isExtension}`);
    // console.log(`sourceUrl is ${model.sourceUrl}`);
    return (
      <CreatePointContainer
        state={model.state}
        pointObject={model.pointObject}
        pointId={model.pointId}
        presetPointFields={model.presetPointFields}
        afterPointCreated={model.afterPointCreated}
        showStance={model.showStance}
        stance={model.stance}
        showAddToCollection={model.showAddToCollection}
        onClose={model.isExtension ? model.onClose : this.props.onClose}
        collectionId={model.collectionId}
        isExtension={model.isExtension || false}
        pdfThruBrowser={model.pdfThruBrowser || false}
        pointUrl={model.sourceUrl}
        sourceImageURL={model.sourceImage}
        hostedImageURL={model.hostedImage}
        imageType={model.imageType}
        sourceText={decodedSourceText}
        userHighlightType={model.userHighlightType}
        status={model.status}
        // timeStamp={model.timeStamp}
        focusQuote={model.focusQuote}
        type={type}
        placeholder={placeholder}
        focusPointText={this.props.focusPointText}
        hideImportFromPortfolio={model.hideImportFromPortfolio}
        onImportPointTab={() =>
          this.setState({ lightWeightTab: 'IMPORT_POINTS' })
        }
        isProvidingEvidence={model.isProvidingEvidence}
        createClaim={this.createClaim}
        selectedCaptions={this.props.selectedCaptions}
        isMock={this.props.isMock}
        isExtensionCL={model.isExtensionCL}
        submitText={this.props.submitText}
      />
      // </Tabs>
    );
  }

  renderImportPointsTab() {
    const { actions, portfolioObject, model, user } = this.props;

    return (
      <ImportPoint
        portfolioObject={portfolioObject}
        getCollection={actions.getCollection}
        onPointImported={this.onPointImported}
        showStance={model.showStance}
        model={model}
        user={user}
        username={user.username}
      />
    );
  }

  renderTabsWithImport() {
    return (
      <Tabs id="tabs-id" className="create-or-import-tabs" mountOnEnter={true}>
        <Tab
          eventKey={1}
          title={
            <span>
              <Icon icon={ICONS.PENCIL} /> New item
            </span>
          }
        >
          {this.renderPointTabs()}
        </Tab>
        <Tab
          eventKey={2}
          title={
            <span>
              <Icon icon={ICONS.IMPORT} /> Import
              <span className="hidden-xs"> from Portfolio</span>
            </span>
          }
        >
          {this.renderImportPointsTab()}
        </Tab>
      </Tabs>
    );
  }

  renderLightWeight() {
    const { lightWeightTab } = this.state;

    if (lightWeightTab === 'CREATE') {
      return this.renderPointTabs();
    } else {
      return this.renderImportPointsTab();
    }
  }

  render() {
    const { type } = this.props;

    if (type === 'DEFAULT') {
      return this.props.model.hideImportFromPortfolio
        ? this.renderPointTabs()
        : this.renderTabsWithImport();
    } else if (type === 'LIGHTWEIGHT') {
      return (
        <div className="create-point-light-weight">
          {this.renderLightWeight()}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;
  let portfolioObject = null;
  if (authenticated) {
    portfolioObject = state.portfolios[user.username];
    if (!portfolioObject)
      console.log(
        'Error - trying to add to collection but no  portfolio object',
      );
  }
  return { authenticated, user, portfolioObject, points: state.points };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPoint),
);

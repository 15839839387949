import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageClassroomGroup.scss';
import DropDown from 'components/elements/atoms/DropDown/DropDown';

const visibilitySettingsOptions = [
  { label: 'Just the student', value: 'student' },
  { label: 'The student\'s class group', value: 'group' },
  { label: 'All students in the course', value: 'course' },
  // { label: 'All students in the school', value: 'school' },
  // { label: 'The entire AverPoint community', value: 'allUsers' },
];

const classroomVisibilitySettingsOptions = [
  { label: 'Just the student', value: 'student' },
  { label: 'All students in the course', value: 'course' },
  // { label: 'All students in the school', value: 'school' },
  // { label: 'The entire AverPoint community', value: 'allUsers' },
];

function ManageClassroomGroup({ courseId, onClose, actions, classroomGroup = {}, students = [], isDefaultClassroomGroup = false }) {
  const [name, setName] = useState(classroomGroup.name || '');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [visibilitySettings, setVisibilitySettings] = useState({
    studentReadingActivity: 'student',
    credibilityLayer: 'student',
    writingSubmission: 'student',
  });
  const [error, setError] = useState('');

  const settingsOptions = isDefaultClassroomGroup ? classroomVisibilitySettingsOptions : visibilitySettingsOptions;

  const studentsOptions = useMemo(() => {
    return students.map((student) => ({
      label: student.username, value: student._id,
    }));
  }, [students]);

  useEffect(() => {
    if (!isDefaultClassroomGroup && Array.isArray(classroomGroup.studentIds) && Array.isArray(students)) {
      const selectedStudentsOptions = [];
      for (const studentId of classroomGroup.studentIds) {
        const student = students.find(student => student._id === studentId);

        if (student) {
          selectedStudentsOptions.push({ label: student.username, value: student._id });
        }
      }
      setSelectedStudents(selectedStudentsOptions);
    }

    const groupSettings = classroomGroup.visibilitySettings;
    if (typeof groupSettings === 'object') {
      const visibilitySettings = {
        studentReadingActivity: groupSettings.studentReadingActivity || 'student',
        credibilityLayer: groupSettings.credibilityLayer || 'student',
        writingSubmission: groupSettings.writingSubmission || 'student',
      };

      setVisibilitySettings(visibilitySettings);
    }
  }, [students, classroomGroup]);

  const manageClassroomGroup = () => {
    if (isDefaultClassroomGroup) {
      actions
        .updateDefaultClassroomGroup(courseId, visibilitySettings)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while updating group. Please try again.',
          );
        });

      return;
    }

    if (!classroomGroup._id) {
      actions
        .createClassroomGroup(courseId, name, selectedStudents, visibilitySettings)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while adding groups. Please try again.',
          );
        });
    } else {
      actions
        .updateClassroomGroup(
          courseId,
          classroomGroup._id,
          name,
          selectedStudents,
          visibilitySettings,
        )
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while adding groups. Please try again.',
          );
        });
    }
  };

  const showTitle = () => {
    if (isDefaultClassroomGroup) {
      return 'Update Course Group';
    }

    return !classroomGroup._id ? 'Create Group' : 'Update Group';
  };

  const showGroupName = () => {
    if (isDefaultClassroomGroup) {
      return null;
    }

    return (
      <FloatingInput
        autoFocus={true}
        value={name}
        labelName="Group Name"
        onChange={(e) => setName(e.target.value)}
        error={error || ''}
      />
    );
  }

  const showStudentsDropdown = () => {
    if (isDefaultClassroomGroup) {
      return null;
    }

    return (
      <DropDown
        labelName="Students"
        value={selectedStudents}
        onChange={(selectedOptions) => setSelectedStudents(selectedOptions)}
        options={studentsOptions}
        isMulti
      />
    );
  }

  const getValueDropdown = (value) => {
    return settingsOptions.find(option => option.value === value);
  };

  const showFeatureAndOptions = (featureName, featureLabel) => (
    <div className="d-flex align-items-center">
      <div className="feature-dropdown-label">{featureLabel}</div>
      <DropDown
        className="feature-dropdown"
        value={getValueDropdown(visibilitySettings[featureName])}
        onChange={(selectedOptions) => setVisibilitySettings((prevSettings) => ({
          ...prevSettings,
          [featureName]: selectedOptions.value,
        }))}
        options={settingsOptions}
      />
    </div>
  );

  const showVisibilitySettings = () => (
    <div className="configure-visibility-container">
      <div className={`configure-visibility ${isDefaultClassroomGroup ? 'default-group' : ''}`}>Configure visibility</div>
      <div className="configure-visibility-subtitle av-gray">
        Besides teachers and administrators, who else should be able to see a student's activity on AverPoint?
      </div>
      <div className="mt-2">{showFeatureAndOptions('studentReadingActivity', 'Student reading activity')}</div>
      <div className="mt-2">{showFeatureAndOptions('credibilityLayer', 'Credibility layer')}</div>
      <div className="mt-2">{showFeatureAndOptions('writingSubmission', 'Writing submissions')}</div>
    </div>
  );

  const showSubmitButton = () => (
    <RectangularButton
      className="next-button"
      disabled={!isDefaultClassroomGroup && !name}
      onClick={() => manageClassroomGroup()}
    >
      Submit
    </RectangularButton>
  );

  return (
    <Modal show={true} className="manage-classroom-groups-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>{showTitle()}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {showGroupName()}
        {showStudentsDropdown()}
        {showVisibilitySettings()}
        {showSubmitButton()}
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageClassroomGroup);

import axios from 'axios';
import * as Actions from 'redux/actions';

export function loginUsingGoogle(data) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/googleAuth/handleGoogleLogin',
      data,
    });
    return response.data;
  };
}

export function signupUsingGoogle(data) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/googleAuth/handleGoogleSignup',
      data,
    });
    return response.data;
  };
}

export function linkToGoogle(googleAuthorizationCode) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/googleAuth/linkToGoogle',
        data: {
          googleAuthorizationCode,
        },
      })
        .then((response) => {
          const data = response.data;

          dispatch({
            type: Actions.ADD_GOOGLE_DETAILS,
            googleUserObject: data.googleUserObject,
          });

          resolve(data.googleUserObject);
        })
        .catch((error) => {
          console.log('Error while linking to google', error);
          reject(error);
        });
    });
}

export const unapprovedSources = [
  {
    name: 'fox news',
    domain: 'foxnews.com',
    logo: 'https://logo.clearbit.com/foxnews.com',
  },
  {
    name: 'cnn international',
    domain: 'cnn.com',
    logo: 'https://logo.clearbit.com/cnn.com',
  },
  {
    name: 'msnbc news',
    domain: 'msnbc.com',
    logo: 'https://logo.clearbit.com/msnbc.com',
  },
  {
    name: 'oann',
    domain: 'oann.com',
    logo: 'https://logo.clearbit.com/oann.com',
  },
  {
    name: 'huffington post',
    domain: 'huffpost.com',
    logo: 'https://logo.clearbit.com/huffpost.com',
  },
];

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import { Modal } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import capitalize from 'lodash-es/capitalize';

import ShareComponent from './ShareComponent';
import CollectionTree from 'components/elements/organisms/CollectionTree/CollectionTree';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import { toast } from 'components/elements/molecules/Toast/Toast';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

require('./Share.scss');

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class Share extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showShareAV: false,
      sharePoint: {
        collectionId: null,
        status: 'public',
      },
    };
  }

  componentDidMount() {
    this.props.actions.getPortfolio(this.props.user.username);
    this.props.actions.getPortfolioCollectionPoints(this.props.user.username);
  }

  onClose = () => {
    this.props.onClose(false);
  };

  onSelectCollectionId = (id) => {
    const { sharePoint } = this.state;

    if (id !== sharePoint.collectionId) {
      this.setState({
        sharePoint: {
          ...sharePoint,
          collectionId: id,
        },
        showCollectionDropdown: false,
      });
    }
  };

  onSave = async () => {
    const { actions, model: point } = this.props;
    const { sharePoint } = this.state;

    this.setState({ isSaving: true });
    await actions.saveSubPointToPoint(point._id, sharePoint.collectionId);
    toast('Point shared to collection successfully.');
    this.onClose();
  };

  renderTitle() {
    const { showShareAV } = this.state;
    const { type, model } = this.props;

    let typeForDisplay = '';

    if (type === 'COLLECTION' && model.sourceType === 'VIDEO') {
      typeForDisplay = 'Video';
    } else {
      typeForDisplay = capitalize(this.props.type);
    }

    let title;

    if (showShareAV) {
      title = (
        <React.Fragment>
          <button
            className="btn btn-link"
            style={{ position: 'absolute', left: 0 }}
            onClick={() =>
              this.setState({ showShareAV: !this.state.showShareAV })
            }
          >
            <Icon icon={ICONS.BACK} className="gray-3" width={30} height={24} />
          </button>
          <Icon icon={ICONS.LOGO_NO_TEXT} size={24} className="mr-2" /> Share to
          my Collection or Point
        </React.Fragment>
      );
    } else {
      title = (
        <React.Fragment>
          <Icon icon={ICONS.SAVE_1} size={24} className="mr-2" /> Share this{' '}
          {typeForDisplay} to ...
        </React.Fragment>
      );
    }

    return (
      <div className="header d-inline-flex align-items-center">{title}</div>
    );
  }

  renderClose() {
    return (
      <div
        className="actions-container pull-right"
        onClick={this.onClose}
        style={{ cursor: 'pointer' }}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </div>
    );
  }

  renderCollectionDropdown() {
    const { showCollectionDropdown, sharePoint } = this.state;
    const { points } = this.props;

    let point;

    if (sharePoint.collectionId && points[sharePoint.collectionId]) {
      point = points[sharePoint.collectionId].point;
    }

    let icon = (
      <Icon icon={ICONS.ARROW_DOWN} size={14} className="flex-shrink-0" />
    );

    return (
      <div
        className="collection-name d-flex align-items-center"
        onClick={(event) => {
          this.setState({
            showCollectionDropdown: !this.state.showCollectionDropdown,
          });
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div
          className="m-0 flex-fill d-flex align-items-center"
          style={{
            minWidth: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {point ? (
            <Icon
              icon={point.isCollection ? ICONS.COLLECTION : ICONS.BULLET}
              className="mr-2 av-blue"
              size={24}
            />
          ) : null}
          {point
            ? point.text || point.sourceText
            : 'Select a Collection or Point'}
        </div>
        {icon}
      </div>
    );
  }

  renderCollectionTree() {
    const { showCollectionDropdown, sharePoint } = this.state;

    if (showCollectionDropdown) {
      return (
        <div
          className="position-absolute bg-av-white w-100"
          style={{ zIndex: 1 }}
        >
          <CollectionTree
            selectCollection={this.onSelectCollectionId}
            selectedIds={[sharePoint.collectionId]}
            close={() => this.setState({ showCollectionDropdown: false })}
            onNewCollection={this.props.onNewCollection}
          />
        </div>
      );
    }
  }

  renderAddToCollections() {
    return (
      <div className="collection-container d-flex align-items-center p-2 w-100">
        <div className="gray-3">Saving to:</div>
        <div
          className="flex-fill ml-3 position-relative"
          style={{ minWidth: 0 }}
        >
          {this.renderCollectionDropdown()}
          {this.renderCollectionTree()}
        </div>
      </div>
    );
  }

  // renderPrivacy() {
  //   const { sharePoint } = this.state;
  //   const privateClassName = classnames({
  //     'not-selected': sharePoint.status !== 'private',
  //   });
  //   const publicClassName = classnames({
  //     'not-selected': sharePoint.status !== 'public',
  //   });
  //   return (
  //     <div className="privacy ml-auto mr-3">
  //       <RoundedButton
  //         onClick={() => {
  //           this.setState({ sharePoint: { ...sharePoint, status: 'private' } });
  //         }}
  //         label=""
  //         mainClassName={privateClassName}
  //         className="btn-sm"
  //         iconName="LOCK"
  //         iconClassName="mr-0"
  //       />
  //       <RoundedButton
  //         onClick={() => {
  //           this.setState({ sharePoint: { ...sharePoint, status: 'public' } });
  //         }}
  //         label=""
  //         className="btn-sm"
  //         iconName="GLOBE"
  //         iconClassName="mr-0"
  //         mainClassName={publicClassName}
  //       />
  //     </div>
  //   );
  // }

  renderNewCollectionButton() {
    return (
      <RectangularButton
        className=""
        iconName="ADD"
        iconSize={12}
        onClick={() => {
          this.props.openCreateCollectionModal(() => {});
        }}
      >
        New Collection
      </RectangularButton>
    );
  }

  renderSubmitButton() {
    const { sharePoint, isSaving } = this.state;
    const disabled = !sharePoint.collectionId;
    const btn = (
      <button
        type="button"
        className="btn btn-primary btn-submit d-inline-flex align-items-center font-weight-600 ml-auto"
        disabled={disabled}
        onClick={this.onSave}
      >
        <Icon icon={ICONS.EVIDENCE} />
        {isSaving ? 'Saving...' : 'Point'}
      </button>
    );

    if (disabled) {
      return (
        <ToolTip
          toolTipText={
            disabled
              ? 'Please select a destination Collection where you will save this Point.'
              : ''
          }
          toolTipPosition="left"
          className="ml-auto"
        >
          {btn}
        </ToolTip>
      );
    } else {
      return btn;
    }
  }

  renderContent() {
    const { showShareAV } = this.state;

    if (showShareAV) {
      return (
        <React.Fragment>
          <div className="w-100 p-4">
            {this.renderAddToCollections()}
            <div className="d-flex align-items-center mt-4">
              {/* {this.renderPrivacy()} */}
              {this.renderNewCollectionButton()}
              {this.renderSubmitButton()}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <ShareComponent
          {...this.props}
          showAV={(show) => this.setState({ showShareAV: show })}
        />
      );
    }
  }

  render() {
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );
    return (
      <Modal show={true} dialogClassName="share" container={container}>
        <Modal.Header>
          {this.renderTitle()}
          {this.renderClose()}
        </Modal.Header>
        <Modal.Body className="flex-fill d-flex" style={{ minHeight: 0 }}>
          {this.renderContent()}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user.user,
  points: state.points,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  openCreateCollectionModal: (afterNewCollectionCreated) => {
    const props = {
      afterNewCollectionCreated,
      isMedia: false,
      isTopLevelCollection: true,
    };
    dispatch(Actions.openModal('create-collection', props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Share);

//export default Share;

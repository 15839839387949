import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import './SyncAutoComplete.scss';

export default function SyncAutoComplete(props) {
  const {
    value,
    onSelect,
    error,
    label = '',
    autoFocus = false,
    isChecked = null,
    options = [],
  } = props;

  const [searchTerm, setSearchTerm] = React.useState(value);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  // On click of option, set the value and close options
  const selectOption = option => {
    if (isDisabled(option)) {
      return;
    }

    onSelect(option);
    setSearchTerm(value);
    setOpenMenu(false);
  };

  // On search, call the debounce function
  const search = value => {
    setSearchTerm(value);
  };

  const isDisabled = option => {
    if (!isChecked) {
      return false;
    }

    return isChecked(option) || false;
  };

  function renderOptions() {
    // if (!openMenu || !searchTerm.trim().length) {
    if (!openMenu) {
      return null;
    }

    let opts = _.uniqBy(
      [
        ...options.filter(
          i => i.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1,
        ),
      ],
      i => i,
    );

    if (!opts.length) {
      return null;
    }

    return (
      <React.Fragment>
        {/* <div className="async-auto-complete-backdrop" /> */}
        <div className="options-container">
          {opts
            .filter(option => option)
            .map(option => (
              <div
                key={option}
                className={!isDisabled(option) ? 'option' : 'option disabled'}
                onClick={() => selectOption(option)}
              >
                <div>{option}</div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="sync-auto-complete">
      <FloatingInput
        autoFocus={autoFocus}
        value={searchTerm}
        placeholder={label || 'Start typing to get suggestions...'}
        error={error}
        onChange={e => search(e.target.value)}
        onFocus={() => {
          setOpenMenu(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setOpenMenu(false);
          }, 500);
        }}
      />
      {renderOptions()}
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import './pointHeader.scss';

class PointReviewHeader extends Component {
  static propTypes = {
    point: PropTypes.object,
    totalReviews: PropTypes.number,
    user: PropTypes.object,
    userPointReview: PropTypes.object,
    toggleReviewModal: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.renderReviewRow();
  }

  renderReviewRow = () => {
    const {
      point,
      user,
      totalReviews,
      userPointReview,
      toggleReviewModal,
    } = this.props;

    if (point.type == 'Decide later' || point.status == 'private') return null; //Can't review a Point that is not classified or published

    return (
      <div className="text-right hover-action">
        <span className="">{totalReviews} reviews</span>
        {this.renderSeeAllLink(user, toggleReviewModal)}
        {/* {this.renderReviewButton(
          point,
          user,
          userPointReview,
          toggleReviewModal,
        )} */}
      </div>
    );
  };

  renderSeeAllLink = (user, toggleReviewModal) => {
    if (!user || !user._id) {
      return (
        <span
          className="ml-3"
          onClick={() => alert('Please log in to see reviews')}
        >
          See all{' '}
        </span>
      );
    } else {
      return (
        <ToolTip
          toolTipText="See how other users reviewed this Point's language and evidence"
          toolTipPosition="bottom"
        >
          <span
            className="ml-3"
            onClick={() => toggleReviewModal('reviewSummary')}
          >
            See all
          </span>
        </ToolTip>
      );
    }
  };

  renderReviewButton = (point, user, userPointReview, toggleReviewModal) => {
    if (point.authorId == user._id) return;

    let reviewText = '';
    let toolTipText = '';
    let handler = null;
    let disabledText = '';
    let isChallenge = false;

    //Check if logged in
    if (!user || !user._id) {
      return (
        <div
          className="d-inline ml-3 round-btn disabled"
          onClick={() => alert('Please login to review this Point')}
        >
          Review
        </div>
      );
    }

    if (userPointReview) {
      //Check existing review

      reviewText = 'Reviewed';
      toolTipText = "See your review of this Point's language and evidence.";
    } else if (user.score.level < point.level) {
      //Check level

      reviewText = 'Review';
      toolTipText =
        'This is a level ' +
        point.level +
        ' Point. Your level is ' +
        user.score.level +
        '. To review this Point, you must increase your score.';
      disabledText = 'disabled';
    } else if (point.status == 'under review' || point.status == 'escalated') {
      //Check status
      reviewText = 'Review';
      toolTipText = "Review this Point's language and evidence.";
    } else if (point.status == 'verified' || point.status == 'rejected') {
      reviewText = 'Challenge';
      toolTipText =
        'Reviewers have ' +
        point.status +
        ' this Point at level ' +
        point.level +
        '. You can Challenge to escalate the Point, and re-start the review process at level ' +
        (point.level + 1);
      isChallenge = true;
      if (user.score.level == point.level) {
        disabledText = 'disable';
        toolTipText =
          'Reviewers have ' +
          point.status +
          ' this Point at level ' +
          point.level +
          " - which is your current level. To Challenge this Point's review, you must be a higher level.";
      }
    }
    // reviewText = "Challenge";
    // toolTipText = "Challenge functionality is TBD"
    // disabledText = "challengeTest";

    return (
      <span className="ml-3">
        <ToolTip toolTipText={toolTipText} toolTipPosition="bottom">
          <div
            className={'round-btn' + disabledText}
            onClick={() =>
              !disabledText
                ? toggleReviewModal(
                    'reviewDetails',
                    userPointReview,
                    isChallenge,
                  )
                : ''
            }
          >
            {reviewText}
          </div>
        </ToolTip>
      </span>
    );
  };
}

export default PointReviewHeader;

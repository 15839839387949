import * as Actions from '../actions';

const initialState = {
  show: false,
  type: '',
};

export default function keyboardShortcuts(state = initialState, action) {
  switch (action.type) {
    case Actions.KEYBOARD_SHORTCUTS_SHOW:
      return {
        ...state,
        show: action.data.show,
        type: action.data.type,
      };
    default:
      return state;
  }
}

import axios from 'axios';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import unset from 'lodash-es/unset';
import * as Sentry from '@sentry/react';
import { AWSCognitoInstance } from './AWSCognito';
import * as Actions from '../redux/actions';
import { isStaging } from 'services/adminCheck';

const { NODE_ENV } = process.env;

const REACT_APP_AXIOS_BASE_URL = isStaging
  ? 'https://api-stage.averpoint.com'
  : process.env.REACT_APP_AXIOS_BASE_URL;

let dispatch = null;

axios.defaults.baseURL = REACT_APP_AXIOS_BASE_URL;
axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    try {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        dispatch
      ) {
        console.log('[Auth Provider] Retrying with refresh token...');

        originalRequest._retry = true;

        return AWSCognitoInstance.getAccessToken().then((accessToken) => {
          dispatch(Actions.refreshToken(accessToken.data));
          return axios(originalRequest);
        });
      }
    } catch (error) {
      console.error('Error while refreshing token', error);
    }

    if (NODE_ENV === 'production') {
      // Send error response to sentry
      Sentry.captureException(error.response);
    }

    if (error.response) {
      throw error.response.data || error.response;
    }

    throw error;
  },
);

const addAuthorizationToHeaders = (state, config) => {
  const userToken = get(state, 'user.user.token');
  if (!userToken) {
    return config;
  }

  unset(config, 'headers.authorization');
  set(config, 'headers.Authorization', `Bearer ${userToken}`);

  if (!config.url.includes(process.env.REACT_APP_SOURCE_PROVIDER_LAMBDA)) {
    const impersonateUsername = get(state, 'user.impersonateUsername');
    const impersonateUserId = get(state, 'user.impersonateUserId');

    if (impersonateUsername) {
      set(config, 'headers.impersonate-user-name', impersonateUsername);
      set(config, 'headers.impersonate-user-id', impersonateUserId);
    } else {
      unset(config, 'headers.impersonate-user-name');
      unset(config, 'headers.impersonate-user-id');
    }
  }
  return config;
};

const authorizationProvider = (store) => {
  dispatch = store.dispatch;

  axios.interceptors.request.use((config) => {
    const state = store.getState(store);
    return addAuthorizationToHeaders(state, config);
  });
};

export default authorizationProvider;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, ICONS } from 'components/Icons';

const FactReviewImage = props => {
  let statusIcon, statusTxt, iconColor;
  switch (props.review) {
    case 'rejected':
      statusIcon = ICONS.REJECTED;
      statusTxt = 'Rejected';
      break;
    case 'reject':
      statusIcon = ICONS.REJECTED;
      statusTxt = 'Reject';
      break;
    case 'verified':
      statusIcon = ICONS.VERIFIED;
      statusTxt = 'Verified';
      break;
    case 'verify':
      statusIcon = ICONS.VERIFIED;
      statusTxt = 'Verify';
      break;
    case 'escalated':
      statusIcon = ICONS.ESCALATED;
      statusTxt = 'Escalated';
      break;
    default:
      statusIcon = ICONS.REVIEW;
      statusTxt = 'Under Review';
  }
  const size = props.size || 23;
  return (
    <span className="fact-status-image" title={statusTxt}>
      <Icon icon={statusIcon} size={size} />
    </span>
  );
};

FactReviewImage.propTypes = {
  review: PropTypes.string,
  size: PropTypes.number
};

export default FactReviewImage;

import * as React from "react";
//import { Provider } from 'react-redux';
import ModalComponent from "./ModalComponent";
//import store from '/imports/ui/redux/index';

class ModalContainer extends React.Component {
  render() {
    return (
      <div className="modal-container">
        <ModalComponent />
      </div>
    );
  }
}

export default ModalContainer;

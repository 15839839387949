import * as React from "react";
import PropTypes from "prop-types";

import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import "./CollectionEditControls.scss";

export default class PointEditControls extends React.Component {
    static propTypes = {
      collectionPoint: PropTypes.object,
      onEditCollectionPointField: PropTypes.func,
      isEditing: PropTypes.bool,
      errorMessage: PropTypes.object
    }

    constructor(props){
      super(props);
      this.state = {

      }
    }

    render(){
      return(
        <div className="point-edit-container">
          <div className="point-edit-controls">
            {/* {this.renderAddTags()} */}
            {this.renderEditMessage()}
          </div>
          <div className="point-edit-messages">
            {this.renderErrorMessage()}
          </div>
        </div>
      )
    }

    renderErrorMessage = () => (
        <span className="error">
          {this.props.errorMessages.overall}
        </span>
      )

    renderAddTags = ()=>{
      if (this.props.collectionPoint.tags && this.props.collectionPoint.tags.length > 0) return null;
        
      return(
        <div
          className="add-link add-tags-link"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <ActionLink
            onClick={()=>this.props.onEditCollectionPointField("tags")}
            linkClassName=""
            iconType="averPointIcon"
            iconName="ADD"
            label="Tags"
            toolTipText="Add tags to help you organize your Points"
            toolTipPosition="bottom"
          />
        </div>
      )
    }

    renderEditMessage = () => {
      if(!this.props.isEditing) return null;
  
      return(
        <ActionLink
          onClick={this.props.onToggleEdit}
          linkClassName="editMessage"
          // iconType="averPointIcon"
          // iconName="PENCIL"
          label="Finish editing"
          toolTipText="Click to edit this Point's text, source, etc."
        />        
      )
    }
    

}
import React, { forwardRef, useImperativeHandle } from 'react';
import InputTextQuestion from './InputTextQuestion';
import InputTextAreaQuestion from './InputTextAreaQuestion';
import InputCheckboxQuestion from "./InputCheckboxQuestion";
import { SingleChoiceQuestion } from './Survey';

function InputQuestions({ question, onChange, model, submitted }, ref) {
  useImperativeHandle(ref, () => ({
    allowContinue: () => {
      return (
        question.questions.filter(i => {
          if (!i.required) {
            return false;
          }

          if (!i.variable) {
            return false;
          }

          if (model[i.variable]) {
            if (i.minLength) {
              return model[i.variable].length < i.minLength;
            }

            return false;
          }

          return true;
        }).length === 0
      );
    },
  }));

  function renderInputQuestion(question, index) {
    let Component;

    if (question.type === 'INPUT_TEXT') {
      Component = InputTextQuestion;
    } else if (question.type === 'INPUT_TEXT_AREA') {
      Component = InputTextAreaQuestion;
    } else if (question.type === 'INPUT_CHECKBOX') {
      Component = InputCheckboxQuestion;
    } else if (question.type === 'SINGLE_CHOICE') {
      Component = SingleChoiceQuestion;
    }

    if (Component) {
      return (
        <div className="mb-4">
          <Component
            key={index}
            question={question}
            onChange={value => onChange(value, question.variable)}
            model={model}
            submitted={submitted}
          />
        </div>
      );
    }

    return null;
  }

  return question.questions.map(renderInputQuestion);
}

export default forwardRef(InputQuestions);

import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_RESEARCH_PROMPTS = 'LOAD_RESEARCH_PROMPTS';
export const ADD_RESEARCH_PROMPT = 'ADD_RESEARCH_PROMPT';
export const UPDATE_RESEARCH_PROMPT = 'UPDATE_RESEARCH_PROMPT';
export const REMOVE_RESEARCH_PROMPT = 'REMOVE_RESEARCH_PROMPT';
export const RESET_RESEARCH_PROMPTS = 'RESET_RESEARCH_PROMPTS';

const loadResearchPrompts = (researchPrompts) => {
  return {
    type: LOAD_RESEARCH_PROMPTS,
    researchPrompts,
  };
};

const addResearchPrompt = (researchPrompt) => {
  return {
    type: ADD_RESEARCH_PROMPT,
    researchPrompt,
  };
};

const updateResearchPromptFn = (promptId, researchPrompt) => {
  return {
    type: UPDATE_RESEARCH_PROMPT,
    promptId,
    researchPrompt,
  };
};

const deleteResearchPromptFn = (promptId) => {
  return {
    type: REMOVE_RESEARCH_PROMPT,
    promptId,
  };
};

export function resetResearchPrompts() {
  return {
    type: RESET_RESEARCH_PROMPTS,
  };
}

export function getResearchPrompt(promptId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchPrompts/getResearchPrompt', {
          params: { promptId },
        })
        .then((response) => {
          const data = response.data;
          if (data.researchPrompt) {
            dispatch(loadResearchPrompts([data.researchPrompt]));
          } else {
            toast(
              'Error occurred while fetching research prompt. It is not assigned to you or the deadline has passed.',
              {
                type: 'error',
              },
            );
          }
          return resolve(data.researchPrompt);
        })
        .catch((error) => {
          console.log('Error while fetching research prompt', error);
          toast(
            'Error occurred while fetching research prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getResearchPrompts(courseId, topicId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchPrompts/getResearchPrompts', {
          params: { courseId, topicId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadResearchPrompts(data.researchPrompts));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching research prompts', error);
          toast(
            'Error occurred while fetching research prompts. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function createResearchPrompt(researchPrompt) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/researchPrompts/createResearchPrompt', {
          ...researchPrompt,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addResearchPrompt(data.researchPrompt));
          toast('Research Prompt has been created successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding Research Prompt', error);
          toast(
            'Error occurred while adding research prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function updateResearchPrompt(researchPrompt) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/researchPrompts/updateResearchPrompt', {
          ...researchPrompt,
        })
        .then((response) => {
          const data = response.data;
          dispatch(
            updateResearchPromptFn(researchPrompt.promptId, data.researchPrompt),
          );
          toast('Research Prompt has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating Research Prompt', error);
          toast(
            'Error occurred while updating research prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function deleteResearchPrompt(
  courseId,
  topicId,
  promptId,
  forceDelete = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/researchPrompts/deleteResearchPrompt', {
          params: { courseId, topicId, promptId, forceDelete },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(deleteResearchPromptFn(promptId));
            toast('Research Prompt has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting Research Prompt', error);
          toast(
            'Error occurred while deleting research prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export const EXTERNAL_APP_CONFIG = {
  google: {
    appName: 'Google Classroom',
    providerName: 'Google',
  },
  canvas: {
    appName: 'Canvas',
    providerName: 'Canvas',
  },
  schoology: {
    appName: 'Schoology',
    providerName: 'Schoology',
  },
};
import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = [];

export default function researchSubmissions(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_RESEARCH_SUBMISSIONS: {
      let newState = [...state];
      action.researchSubmissions.forEach((researchSubmission) => {
        const index = newState.findIndex((i) => i._id === researchSubmission._id);

        if (index === -1) {
          newState.push(researchSubmission);
        } else {
          newState.splice(index, 1, researchSubmission);
        }
      });
      return newState;
    }
    case Actions.ADD_RESEARCH_SUBMISSION: {
      const index = state.findIndex((i) => i._id === action.researchSubmission._id);

      if (index === -1) {
        return [...state, action.researchSubmission];
      } else {
        return [...state.splice(index, 1, action.researchSubmission)];
      }
    }
    case Actions.UPDATE_RESEARCH_SUBMISSION: {
      const index = state.findIndex((i) => i._id === action.researchSubmission._id);
      return [
        ...state.slice(0, index),
        action.researchSubmission,
        ...state.slice(index + 1),
      ];
    }
    case Actions.REMOVE_RESEARCH_SUBMISSION: {
      const index = state.findIndex((i) => i._id === action.researchSubmissionId);

      if (index === -1) {
        return state;
      } else {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
    }
    default:
      return state;
  }
}

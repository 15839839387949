import * as React from 'react';
import PropTypes from 'prop-types';

import Metatags from 'components/elements/atoms/metatags/metatags';
import PointContainer from 'components/cards/Point/PointContainer';
import SupportersOpponentsContainer from './modals/SupportersOpponents/SupportersOpponentsContainer';
import ReviewWidget from './ReviewWidget/ReviewWidget';
import PointEvidence from './Component/PointEvidence/PointEvidence';
import PointParentHeader from './Component/PointParentHeader/PointParentHeader';
import InviteResponse from '../../components/elements/organisms/InviteResponse/InviteResponse';

import './pointPageComponent.scss';

export default class PointPageComponent extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    pointObject: PropTypes.object,
    point: PropTypes.object,
    actions: PropTypes.object,
    parentObject: PropTypes.object,
    isModal: PropTypes.bool,
    showReviewOnOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      stance: null,
      supportOpposeTab: 's',
      isStickyHeader: false,
      reviewModalType: 'reviewDetails', //"none", "reviewSummary", "reviewDetails"
      showReviewModal: this.props.showReviewOnOpen === true,
      isChallenge: false,
      errorMessages: [],
      errorMessage: '',
    };
  }

  openSupportersOpponentsModal(stance) {
    this.setState({ showSupportersOpponents: true });
  }

  hideSuppotersOpponentsModal = () => {
    this.setState({ showSupportersOpponents: false });
  };

  renderSupportersOpponents() {
    return (
      <SupportersOpponentsContainer
        onClose={this.hideSuppotersOpponentsModal}
        show={this.state.showSupportersOpponents}
        supporters={this.props.pointObject.supporters}
        opponents={this.props.pointObject.opponents}
      />
    );
  }

  stickyHeaderStateChange = ({ status }) => {
    // console.log("sticky header status is... "+status);
    this.setState({ isStickyHeader: status == 2 });
  };

  toggleReviewModal = (reviewModalType, isChallenge) => {
    this.setState({
      showReviewModal: !this.state.showReviewModal,
      reviewModalType,
      isChallenge,
    });
  };

  renderErrorMessage() {
    if (this.state.errorMessage) {
      return;
    }

    return <div className="error-message">{this.state.errorMessage}</div>;
  }

  renderInvite() {
    const { point, user } = this.props;

    const { invitationStatus } = this.props.pointObject;

    if (invitationStatus !== 'invited') return null;
    if (point.authorId == user._id) return null; // Author can't respond to an invite to their own Collection

    return (
      <div className="mt-4 mb-4 pl-4 pr-4 pt-2 pb-2 bg-av-light-gray">
        <InviteResponse source="POINT" pointId={point._id} />
      </div>
    );
  }

  render() {
    const { point, permissionLevel } = this.props.pointObject;
    const { pointDetailView, onFourPackAction } = this.props;

    const { user } = this.props;

    // Need to show point authors modal
    return (
      <div className="point-page-component">
        <Metatags page="point" point={point} />
        <div className="point-container">
          {this.renderReviewModal(point)}
          <PointParentHeader
            parentObject={this.props.parentObject}
            isModal={this.props.isModal}
            showSiblingArrows={true}
            scrolled={false}
          />
          {this.renderInvite()}
          <PointContainer
            cardType="page"
            user={user}
            authenticated={this.props.authenticated}
            point={point}
            citationStyle="long"
            view="card"
            editable={permissionLevel == 'write'}
            disableClickThru
            allowRemove={false}
            onRemove={null}
            showReviews
            allowDownload={
              permissionLevel == 'write' || point.status != 'private'
            }
            toggleReviewModal={this.toggleReviewModal}
            showTotalSubPoints={false}
            pointDetailView={pointDetailView}
            onFourPackAction={onFourPackAction}
          />
          {this.renderErrorMessage()}
          {/* <div className="">
            <PointEvidence
              pointId={point._id}
              user={user}
              permissionLevel={permissionLevel}
            />
          </div> */}
        </div>
      </div>
    );
  }

  renderReviewModal = point => {
    if (!this.state.showReviewModal) return;

    return (
      <ReviewWidget
        toggleReviewModal={this.toggleReviewModal}
        reviewModalType={this.state.reviewModalType}
        pointId={point._id}
        isChallenge={this.state.isChallenge}
        user={this.props.user}
      />
    );
  };
}

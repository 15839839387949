import axios from 'axios';

export const LOAD_HELPS = 'LOAD_HELPS';
export const ADD_HELP = 'ADD_HELP';
export const UPDATE_HELP = 'UPDATE_HELP';
export const DELETE_HELP = 'DELETE_HELP';

export function loadHelps(helps) {
  return {
    type: LOAD_HELPS,
    helps,
  };
}

export function addHelp(help) {
  return {
    type: ADD_HELP,
    help,
  };
}

export function updateHelpAction(help) {
  return {
    type: UPDATE_HELP,
    help,
  };
}

export function deleteHelpAction(help) {
  return {
    type: DELETE_HELP,
    help,
  };
}

export function getHelps() {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'get',
      url: '/api/helpPages/getHelpPages',
    });
    const { helpPages } = response.data;
    dispatch(loadHelps(helpPages));
    return helpPages;
  };
}

export function createHelp(help) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/helpPages/createHelpPage',
      data: help,
    });
    dispatch(addHelp(response.data.helpPage));
    return response.data.helpPage;
  };
}

export function updateHelp(help) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/helpPages/updateHelpPage',
      data: help,
    });
    dispatch(updateHelpAction(response.data.helpPage));
    return response.data.helpPage;
  };
}

export function deleteHelp(help) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'delete',
      url: '/api/helpPages/deleteHelpPage',
      params: {
        id: help._id,
        title: help.title,
      },
    });
    dispatch(deleteHelpAction(help));
    return response.data;
  };
}

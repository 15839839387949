import * as Actions from '../actions/index';

export default function portfolios(state = {}, action) {
  switch (action.type) {
    // case Actions.SYNC_SESSION_SUCCESS:
    case Actions.LOGIN_SUCCESS_USER: {
      const portfolioObject = {
        portfolio: action.data.portfolio,
        userScore: action.data.user.score,
        userProfile: action.data.user.profile,
        followersCount: action.data.user.followersCount,
        following: false,
      };
      return Object.assign({}, state, {
        [action.data.portfolio.username]: portfolioObject,
      });
    }
    case 'START_GETTING_PORTFOLIO': {
      return {
        ...state,
        loading: true,
        loadingUsername: action.username.toLowerCase(),
      };
    }
    case Actions.LOAD_PORTFOLIO: {
      const portfolioObject = {
        publicUser: action.data.user,
        portfolio: action.data.portfolio,
        userScore: action.data.userScore,
        userProfile: action.data.userProfile,
        hostedImageVersion: action.data.hostedImageVersion,
        followersCount: action.data.followersCount,
        following: action.data.following,
        stats: action.data.stats,
        personalStats: action.data.personalStats,
      };
      return Object.assign({}, state, {
        [action.data.portfolio.username]: {
          ...state[action.data.portfolio.username],
          ...portfolioObject,
        },
        loading: false,
        loadingUser: '',
      });
    }
    case Actions.UPDATE_PORTFOLIO: {
      var newPortfolioObject = state[action.portfolio.username];
      //if action has field - update that field, otherwise leave it as is
      newPortfolioObject.portfolio = action.portfolio;

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.UPDATE_ANOTHER_USER:
    case Actions.UPDATE_USER: {
      let newPortfolioObject = state[action.user.username];

      newPortfolioObject = {
        ...newPortfolioObject,
        userProfile: action.user.profile,
      };
      return Object.assign({}, state, {
        [action.user.username]: newPortfolioObject,
      });
    }
    case Actions.COLLECTION_UPDATED: {
      if (!action.portfolio) return state; //The portfolio wasn't affected
      var newPortfolioObject = state[action.portfolio.username];
      //if portfolio is not loaded, do nothing
      if (!newPortfolioObject) return state;

      newPortfolioObject.portfolio = action.portfolio;

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.COLLECTION_REMOVED_FROM_PORTFOLIO: {
      if (!action.portfolio) return state; //The portfolio wasn't affected

      var newPortfolioObject = state[action.portfolio.username];
      //if portfolio is not loaded, do nothing
      if (!newPortfolioObject) return state;

      newPortfolioObject.portfolio = action.portfolio;

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.COLLECTION_ADDED_TO_PORTFOLIO: {
      if (!action.portfolio) return state; //The portfolio wasn't affected

      var newPortfolioObject = state[action.portfolio.username];

      //if portfolio is not loaded, do nothing
      if (!newPortfolioObject) return state;

      newPortfolioObject.portfolio = action.portfolio;
      //No longer maintaining portfolioObject.collections
      //Find the index of the removed collection
      // var oldCollections = newPortfolioObject.collections;
      // newPortfolioObject.collections = oldCollections
      //                                     .concat(action.collection);

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.COLLECTION_CREATED: {
      if (!action.portfolio) return state; // Collection Created, but not top-level

      var newPortfolioObject = state[action.portfolio.username];
      if (!newPortfolioObject) return state;

      newPortfolioObject.portfolio = action.portfolio;

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.COLLECTION_DELETED: {
      var newPortfolioObject = state[action.portfolio.username];
      //if action has field - update that field, otherwise leave it as is
      newPortfolioObject.portfolio = action.portfolio;

      return Object.assign({}, state, {
        [action.portfolio.username]: newPortfolioObject,
      });
    }
    case Actions.MOVE_SUBPOINT: {
      const { dragParent, targetParent } = action;
      if (dragParent.type != 'portfolio' || targetParent.type != 'portfolio') {
        return state;
      }

      console.log('moving collectionPoints within the same portfolio');
      let newPortfolioObject = state[dragParent.id];
      let collectionPoints = [...newPortfolioObject.portfolio.collectionPoints];
      const dragCollectionPoint = targetParent.list[dragParent.index];
      const dragCollectionPointIndex =
        collectionPoints.indexOf(dragCollectionPoint);
      const targetCollectionPoint = targetParent.list[targetParent.index];
      const targetCollectionPointIndex = collectionPoints.indexOf(
        targetCollectionPoint,
      );
      console.log(
        'The drag collectionPoint is ' +
          dragCollectionPoint +
          ' and index is ' +
          dragCollectionPointIndex,
      );
      console.log(
        'The target collectionPoint is ' +
          targetCollectionPoint +
          ' and index is ' +
          targetCollectionPointIndex,
      );
      collectionPoints[dragCollectionPointIndex] = targetCollectionPoint;
      collectionPoints[targetCollectionPointIndex] = dragCollectionPoint;
      newPortfolioObject.portfolio.collectionPoints = collectionPoints;

      // return Object.assign({}, state, {
      //   [dragParent.id]: newPortfolioObject,
      // });
      return state;
    }
    case 'LOAD_STORED_STATE': {
      if (!action.storedState.user) return state;
      if (!action.storedState.portfolios) return state;
      var storedUser = action.storedState.user.user;
      var storedPortfolioObject =
        action.storedState.portfolios[storedUser.username];
      return Object.assign({}, state, {
        [storedUser.username]: storedPortfolioObject,
      });
    }
    case 'PORTFOLIO_CLEAR': {
      return {};
    }
    case Actions.FOLLOW_PORTFOLIO: {
      const portfolio = state[action.payload.username];
      if (portfolio) portfolio.followersCount++;
      return {
        ...state,
        [action.payload.username]: {
          ...portfolio,
          following: true,
        },
      };
    }
    case Actions.UNFOLLOW_PORTFOLIO: {
      const portfolio = state[action.payload.username];
      if (portfolio) portfolio.followersCount--;
      return {
        ...state,
        [action.payload.username]: {
          ...portfolio,
          following: false,
        },
      };
    }
    case Actions.LOAD_PORTFOLIO_COLLECTION_POINTS:
      var newPortfolioObject = state[action.data.publicUser.username];
      if (!newPortfolioObject) {
        newPortfolioObject = {};
      }
      newPortfolioObject.isCollectionPointsLoaded = true;
      return Object.assign({}, state, {
        [action.data.publicUser.username]: newPortfolioObject,
      });
    case Actions.UPDATE_STUDENT_ROLE_PORTFOLIO: {
      let newPortfolioObject = JSON.parse(
        JSON.stringify(state[action.username]),
      );

      const roles = newPortfolioObject.userProfile.roles || [];
      roles.push({ type: 'student', status: 'activated' });
      newPortfolioObject.userProfile.roles = roles;

      return Object.assign({}, state, {
        [action.username]: newPortfolioObject,
      });
    }
    case Actions.UPDATE_TEACHER_ROLE_PORTFOLIO: {
      let newPortfolioObject = JSON.parse(
        JSON.stringify(state[action.username]),
      );

      const roles = newPortfolioObject.userProfile.roles || [];
      roles.push({ type: 'teacher', status: 'activated' });
      newPortfolioObject.userProfile.roles = roles;

      return Object.assign({}, state, {
        [action.username]: newPortfolioObject,
      });
    }
    default:
      return state;
  }
}

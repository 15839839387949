import uniqBy from 'lodash/uniqBy';
import * as Actions from '../actions/index';

const initialState = {
  points: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.CREATE_POINT_URL:
      return {
        ...state,
        points: uniqBy([payload.point, ...state.points], '_id'),
      };
    default:
      return state;
  }
};

import React, { useReducer } from 'react';
import { Modal, Button } from 'react-bootstrap';
// import Topic, { Frame } from 'routes/Help/Topic';
import { Icon, ICONS } from '../../Icons';
import topics from 'helpers/help.json';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import './WelcomeModal.scss';

export default function WelcomeModal({ onClose }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_INDEXES':
          return {
            ...state,
            indexes: action.payload,
          };
        case 'SET_TOPIC':
          return {
            ...state,
            topic: action.payload,
          };
        default:
          return state;
      }
      return state;
    },
    {
      topic: topics[1],
      indexes: [1],
    },
  );

  function renderBackToIndex() {
    if (state.indexes.length === 1) {
      return null;
    }

    return (
      <ActionLink
        label="Back to index"
        iconType="averPointIcon"
        iconName="ARROW_BACKWARD"
        linkClassName="back-to-index"
        onClick={() => {
          dispatch({ type: 'SET_INDEXES', payload: [1] });
          dispatch({ type: 'SET_TOPIC', payload: topics[1] });
        }}
      />
    );
  }

  function renderHelpTopic() {
    // return (
    //   <Topic
    //     topic={state.topic}
    //     indexes={state.indexes}
    //     showAction={state.indexes.length > 1}
    //     onSelectTopic={indexes => {
    //       dispatch({ type: 'SET_INDEXES', payload: indexes });
    //       dispatch({
    //         type: 'SET_TOPIC',
    //         payload: topics[1].topics[indexes[indexes.length - 1]],
    //       });
    //     }}
    //   />
    // );
    return null;
  }

  return (
    <Modal show={true} className="welcome-modal">
      <Modal.Body>
        {renderBackToIndex()}
        <Button bsStyle="link" className="btn-close" onClick={onClose}>
          <Icon icon={ICONS.CLOSE} />
        </Button>
        {renderHelpTopic()}
      </Modal.Body>
    </Modal>
  );
}

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon, ICONS } from 'components/Icons';

import './css/RoundedButton.scss';

const RoundedButton = props => {
  const renderLabel = () => {
    let label, hoverLabel;

    if (props.label) {
      label = <span className="label">{props.label}</span>;
    }

    if (props.hoverLabel) {
      hoverLabel = <span className="hover-label">{props.hoverLabel}</span>;
    }

    let className = classnames({ 'has-hover-label': !!props.hoverLabel });

    return (
      <span className={className}>
        {label}
        {hoverLabel}
      </span>
    );
  };

  return (
    <div className={`btn-container ${props.mainClassName || ''}`}>
      <button
        className={`btn ${props.className ||
          ''} d-inline-flex align-items-center justify-content-center`}
        style={props.style}
        id={props.id}
        tabIndex={props.tabIndex}
        disabled={props.disabled}
        onClick={props.onClick}
        title={props.title}
      >
        {props.iconName && (
          <Icon icon={ICONS[props.iconName]} className={props.iconClassName} />
        )}
        {renderLabel()}
      </button>
    </div>
  );
};

RoundedButton.propTypes = {
  mainClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  iconName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default RoundedButton;

import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import ProcessCircle from "components/elements/atoms/ProcessCircle/ProcessCircle";
import { Line, Circle } from "rc-progress";
import { Icon, ICONS } from "components/Icons";
import ReviewList from "./ReviewList/ReviewList";
import FactReviewImage from "components/elements/molecules/Images/FactReviewImage";
import "./ReviewSummary.scss";

class ReviewSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewFilter: "all",
      searchVisible: false,
      searchText: "",
      showReviewList: false
    };
  }

  handleReviewTab = (e) => {
    this.setState({
      activeTab: e.target.id
    });
  }

  handleFilter = (e) => {
    this.setState({
      reviewFilter: e.target.name
    });
  }

  handleSearch = (e) => {
    this.setState(
      {
        searchVisible: !this.state.searchVisible
      },
      () => {
        if (!this.state.searchVisible) {
          this.setState({
            searchText: ""
          });
        }
      }
    );
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const { point } = this.props;
    const { reviewFilter, searchVisible, searchText } = this.state;
    let processLabel;

    let componentLabel = "Reviews of this " + point.type; //summary of " + reviews.length + " review" + ((reviews.length==1) ? "" : "s");

    // if (point && point.type === 'Fact') {
    //   let neutralPercentage=0, sourcesPercentage = 0;
    //   //const numReviews = point.numVerifications + point.numRejections;
    //   const numReviews = reviews.length; //Includes reviews at lower levels
    //   if(numReviews > 0){
    //     neutralPercentage = Math.floor((point.numNeutralLanguage/numReviews) * 100);
    //     sourcesPercentage = Math.floor((point.numVerifiedInSource/numReviews) * 100);
    //   }
    //   processLabel = [{percentage: neutralPercentage, label: 'Neutral language'}, {percentage: sourcesPercentage, label:'Sources behind claims'}];
    // }

    // if (point && point.type === 'Opinion') {
    //   let balancedPercentage=0, factualPercentage = 0;
    //   const numReviews = point.numAgree + point.numDisagree;
    //   if(numReviews > 0){
    //     balancedPercentage = Math.floor((point.numBalanced/numReviews) * 100);
    //     factualPercentage = Math.floor((point.numFactual/numReviews) * 100);
    //   }
    //   processLabel = [{percentage: balancedPercentage, label:'Balanced language'}, {percentage: factualPercentage, label:'Facts behind claims'}];
    // }
    return (
      <div className="review-summary">
        <div className="summary-head">
          <span className="head-label">{componentLabel} </span>
        </div>
        <div className="review-count">
          {this.renderOpinionOverall()}
          {this.renderFactOverall()}
          {/* <Row className="process">
            {
              processLabel.map((process, i) => (
                <Col key={i} md={6} sm={6} xs={6}>
                  <div className="inline-block">
                    <ProcessCircle percentage={process.percentage}/>
                  </div>
                  <p>{process.label}</p>
                </Col>
              ))
            }
          </Row> */}
        </div>
        {this.renderReviewList()}
      </div>
    );
  }

  renderReviewList = () => {
    const { handleReviewModal, reviews } = this.props;
    const { reviewFilter, searchVisible, searchText } = this.state;

    if (!this.state.showReviewList) {
      return (
        <div className="show-reviews-link" onClick={this.toggleReviewList}>
          Show all reviews
        </div>
      );
    } else {
      return (
        <ReviewList
          searchText={searchText}
          searchVisible={searchVisible}
          handleReviewModal={handleReviewModal}
          onChange={this.onChange}
          handleSearch={this.handleSearch}
          handleFilter={this.handleFilter}
          checked={reviewFilter}
          reviews={reviews}
          toggleReviewList={this.toggleReviewList}
        />
      );
    }
  };

  toggleReviewList = () => {
    this.setState({
      showReviewList: !this.state.showReviewList
    });
  };

  renderOpinionOverall() {
    const { point } = this.props;

    if (point.type == "Fact") return;

    const numReviews = point.numAgree + point.numDisagree;

    let agreePercentage = 0;
    if (numReviews > 0)
      agreePercentage = Math.floor((point.numAgree / numReviews) * 100);
    let disagreePercentage = 100 - agreePercentage;

    const outOfLabelText =
      point.numAgree + " of " + numReviews + " reviewers agree";

    let balancedPercentage = 0,
      factualPercentage = 0;
    //const numReviews = point.numAgree + point.numDisagree;
    if (numReviews > 0) {
      balancedPercentage = Math.floor((point.numBalanced / numReviews) * 100);
      factualPercentage = Math.floor((point.numFactual / numReviews) * 100);
    }

    return (
      <div className="stats">
        <div className="status-row">
          <span className="outOfLabel">{outOfLabelText}</span>
          <Line
            className="agreeLineGraph"
            percent={agreePercentage}
            strokeWidth="4"
            strokeColor="#0085CB"
            className="agreeLineGraph"
          />
          <span className="agreePercentLabel">({agreePercentage}%)</span>
        </div>
        <div className="breakdown-row">
          <div className="criteria-row">
            <div className="balanced">
              <div className="percent-label">Balanced language</div>
              <div className="percent-graph">
                <ProcessCircle percentage={balancedPercentage} />
              </div>
            </div>
            <div className="sources">
              <div className="percent-label">Facts back up claims</div>
              <div className="percent-graph">
                <ProcessCircle percentage={factualPercentage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFactOverall() {
    const { point } = this.props;

    if (point.type == "Opinion") return;

    let neutralPercentage = 0,
      sourcesPercentage = 0;
    //const numReviews = point.numVerifications + point.numRejections;
    const numReviews = this.props.reviews.length;

    if (numReviews > 0) {
      neutralPercentage = Math.floor(
        (point.numNeutralLanguage / numReviews) * 100
      );
      sourcesPercentage = Math.floor(
        (point.numVerifiedInSource / numReviews) * 100
      );
    }

    return (
      <div className="stats">
        <div className="status-row">
          <span className="label">Status: </span>
          <span className="status">
            <FactReviewImage review={point.status} />
            &nbsp;
            {point.status}
          </span>
          <span className="level-section">
            <span className="label">Review Level: {point.level}</span>
          </span>
        </div>
        <div className="breakdown-row">
          <div className="verifications">
            <Icon icon={ICONS.VERIFIED} /> Verified: {point.numVerifications}
          </div>
          <div className="rejections">
            <Icon icon={ICONS.REJECTED} /> Rejected: {point.numRejections}
          </div>
          <div className="criteria-row">
            <div className="neutral">
              <div className="percent-label">Neutral language</div>
              <div className="percent-graph">
                <ProcessCircle percentage={neutralPercentage} />
              </div>
            </div>
            <div className="sources">
              <div className="percent-label">Sources verify claims</div>
              <div className="percent-graph">
                <ProcessCircle percentage={sourcesPercentage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewSummary;

import React from 'react';

import QuestionText from './QuestionText';
import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';

export default function MultipleChoiceQuestion({ question, onChange, model }) {
  function getValue() {
    return model[question.variable] || [];
  }

  function isChecked(option) {
    if (option.clear) {
      return getValue().length === 0;
    } else {
      return getValue().indexOf(option.text) !== -1;
    }
  }

  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderCheckbox(option, key) {
    const value = getValue();
    const checked = isChecked(option);

    return (
      <Checkbox
        onClick={e => {
          e.preventDefault();

          if (checked) {
            value.splice(value.indexOf(option.text), 1);
            onChange([...value]);
          } else {
            if (option.clear) {
              onChange([]);
            } else {
              onChange([...value, option.text]);
            }
          }
        }}
        onChange={e => {}}
        checked={checked}
        key={key}
      >
        {option.text}
      </Checkbox>
    );
  }

  function renderCheckboxes() {
    let sources = [];

    if (Array.isArray(model.sourceFavoriteNews)) {
      sources = model.sourceFavoriteNews.map(s => ({
        text: s,
      }));
    }

    if (Array.isArray(model.localSources)) {
      sources = sources.concat(
        model.localSources.map(s => ({
          text: s,
        })),
      );
    }

    if (
      model.followAnyIndependentCreators &&
      model.followAnyIndependentCreators.trim()
    ) {
      sources = sources.concat(
        model.followAnyIndependentCreators.split(',').map(s => ({
          text: s,
        })),
      );
    }

    return sources.map((source, index) => renderCheckbox(source, index));
  }

  function hintForNPR() {
    if (
      model &&
      Array.isArray(model['sourceFavoriteNews']) &&
      model['sourceFavoriteNews'].includes('NPR')
    ) {
      return (
        <div className="hintForNpr">* For NPR, check if you contribute.</div>
      );
    }

    return null;
  }

  return (
    <React.Fragment>
      {renderText()}
      {hintForNPR()}
      {renderCheckboxes()}
    </React.Fragment>
  );
}

import * as Actions from '../actions/index';

function createInitialState(pageType) {
  return {
    points: [],
    collectionPoints: [],
    activeSearch: false,
    pageType,
    searchTerm: '',
    topSources: [],
    topTags: [],
    searchKey: '',
    searchId: null,
  };
}

const pageTypes = [
  'portfolio',
  'allAverPoint',
  'collectionTree',
  'importPoint',
];
const initialState = {};
pageTypes.forEach((pageType) => {
  initialState[pageType] = createInitialState(pageType);
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.START_SEARCH:
      // return {
      //   ...state,
      //   activeSearch: true,
      //   pageType: action.payload.pageType,
      //   searchTerm: action.payload.searchTerm,
      //   searchId: action.payload.searchId,
      // };
      return {
        ...state,
        [action.payload.pageType]: {
          ...state[action.payload.pageType],
          activeSearch: true,
          pageType: action.payload.pageType,
          searchTerm: action.payload.searchTerm,
          searchId: action.payload.searchId,
        },
      };
    case Actions.SEARCH_PORTFOLIO:
      // return {
      //   ...state,
      //   points: action.payload.points.map(p => p._id),
      //   collectionPoints: action.payload.collectionPoints.map(c => c._id),
      //   activeSearch: false,
      //   searchTerm: action.payload.searchTerm,
      //   topSources: action.payload.topSources,
      //   topTags: action.payload.topTags,
      // };
      return {
        ...state,
        [action.payload.pageType]: {
          ...state[action.payload.pageType],
          points: action.payload.points.map((p) => p._id),
          collectionPoints: action.payload.collectionPoints.map((c) => c._id),
          activeSearch: false,
          searchTerm: action.payload.searchTerm,
          topSources: action.payload.topSources,
          topTags: action.payload.topTags,
        },
      };
    case Actions.START_PORTFOLIO_SEARCH:
      return {
        ...state,
        [action.payload.pageType]: {
          ...state[action.payload.pageType],
          activeSearch: true,
          pageType: action.payload.pageType,
          searchTerm: action.payload.searchTerm,
          searchId: action.payload.searchId,
        },
      };
    case Actions.SEARCH_COLLECTION_PORTFOLIO:
      return {
        ...state,
        [action.payload.pageType]: {
          ...state[action.payload.pageType],
          points: action.payload.points.map((p) => p._id),
          collectionPoints: action.payload.collectionPoints.map((c) => c._id),
          activeSearch: false,
          searchTerm: action.payload.searchTerm,
          topSources: action.payload.topSources,
          topTags: action.payload.topTags,
        },
      };
    case Actions.RESET_SEARCH_COLLECTION_PORTFOLIO:
      return {
        ...state,
        [action.payload.pageType]: {
          ...state[action.payload.pageType],
          activeSearch: false,
          searchTerm: action.payload.searchTerm,
        },
      };
    case 'DELETE_POINTS': {
      // const collectionPoints = state.collectionPoints.filter(id => {
      //   return action.deletedPointIds.indexOf(id) == -1;
      // });
      // const points = state.points.filter(id => {
      //   return action.deletedPointIds.indexOf(id) == -1;
      // });

      // return Object.assign({}, state, {
      //   collectionPoints,
      //   points,
      // });

      const newState = { ...state };

      pageTypes.forEach((pageType) => {
        newState[pageType].collectionPoints = newState[
          pageType
        ].collectionPoints.filter((id) => {
          return action.deletedPointIds.indexOf(id) == -1;
        });
        newState[pageType].points = newState[pageType].points.filter((id) => {
          return action.deletedPointIds.indexOf(id) == -1;
        });
      });

      return newState;
    }
    case Actions.CLEAR_SEARCH:
      // return {
      //   ...initialState,
      //   searchKey: state.searchKey,
      // };
      if (action.payload && action.payload.pageType) {
        return {
          ...state,
          [action.payload.pageType]: createInitialState(
            action.payload.pageType,
          ),
        };
      } else {
        const state = {};

        pageTypes.forEach((pageType) => {
          state[pageType] = createInitialState(pageType);
        });

        return state;
      }
    case Actions.GET_SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './FlagCommunityPickedArticle.scss';

function FlagCommunityPickedArticle({ pointId, onClose, actions }) {
  const [note, setNote] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [note]);

  const flagArticle = () => {
    axios
      .post('/api/mediaChannel/flagArticle', {
        pointId,
        note,
      })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  return (
    <Modal show={true} className="clubhouse-signup-modal">
      <Modal.Body>
        <div className="header">
          <span>Flag This Article</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingInput
          autoFocus={true}
          value={note}
          labelName="Note"
          onChange={(e) => setNote(e.target.value)}
          error={error || ''}
        />
        <RectangularButton
          className="next-button"
          disabled={!note}
          onClick={() => flagArticle()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(FlagCommunityPickedArticle);

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCollectionToGroup } from 'redux/actions/collection';
import { Modal, Button } from "react-bootstrap";
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';

import { selectMyGroups } from 'redux/selectors/groups';
import { getMyGroups } from 'redux/actions/group';

import { Icon, ICONS } from "../../Icons";


class AddToGroup extends React.Component {
  static propTypes = {
    collectionPoint: PropTypes.object.isRequired,
    getMyGroups: PropTypes.func,
    addCollectionToGroup: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      groupId: undefined,
    };
  }

  componentDidMount() {
    if (this.props.collectionPoint.groups[0]) {
      this.setState({
        groupId: this.props.collectionPoint.groups[0],
      });
    } else {
      this.props.getMyGroups();
    }
  }

  onSave = () => {
    this.props
      .addCollectionToGroup(this.props.collectionPoint._id, this.state.groupId)
      .then(() => {
        this.onClose();
      });
  };

  onClose = () => {
    this.props.onClose(false);
  };

  onSelect = e => {
    this.setState({
      groupId: e.target.value,
    });
  };

  render() {
    return (
      <Modal show>
        <Modal.Header>
          <Button bsStyle="link" className="btn-close" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <Modal.Title>
            <Icon icon={ICONS.ADD} /> Add this Collection to a Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <p>
            Select group
            &nbsp;
            <select
              name=""
              id=""
              onChange={this.onSelect}
              value={this.state.groupId}
            >
              <option value="" selected disabled hidden>Choose here</option>
              {this.props.groups.map(g => (
                <option key={g._id} value={g._id} >
                  {g.name}
                </option>
              ))}
            </select>
          </p>
          &nbsp;
          <div className="mt-3 d-flex justify-content-center">
            <RoundedButton
              onClick={this.onSave}
              label="Save"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.user;

  return {
    user,
    groups: selectMyGroups(state).groups,
  };
}

export default connect(
  mapStateToProps,
  {
    addCollectionToGroup,
    getMyGroups,
  },
)(AddToGroup);

import React from "react";
import RoundedButton from "../../../elements/molecules/RoundedButton/RoundedButton";

const Step10 = ({ gotoNextStep }) => (
  <div>
    <p>
      To support a larger <b>Point</b>, you can add evidence or sub-
      <b>Points</b>. This main <b>Point</b> has 3 supporting sub-<b>Points</b>.
    </p>
    {/* <div className="video-container">
      <iframe
        src="https://www.useloom.com/embed/9fe08aba55ab41c589fff20569d25489"
        frameBorder="0"
        webkitAllowFullScreen="true"
        mozAllowFullScreen="true"
        allowFullScreen="true"
      />
    </div> */}
    <RoundedButton label="Proceed" onClick={() => gotoNextStep(11)} />
  </div>
);

export default Step10;

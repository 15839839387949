import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {
  courses: [],
  loadingCourses: false,
  courseStats: {},
  selectedCourse: null,
  showCourseDashboard: false,
  courseDashboardFilters: {},
  selectedTopic: null,
  selectedStudent: null,
  selectedGroupStudent: null,
  selectedReading: null,
  selectedReadingGrades: null,
  selectedWritingPrompt: null,
  showResearchEditor: false,
  selectedResearchPrompt: null,
  selectedResearchSubmission: null,
  questionsByTopicId: {},
  readingsInWild: {},
  readingsInCourse: {},
  readingsInAll: {},
};

export default function classRooms(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_COURSES: {
      const courses = JSON.parse(JSON.stringify(state.courses));
      const newCourses = JSON.parse(JSON.stringify(action.courses || []));

      const oldCoursesMap = new Map();
      courses.forEach((course) => oldCoursesMap.set(course.courseId, course));

      // Merge oldCourses into newCourses based on courseId
      const mergedCourses = newCourses.map((newCourse) => {
        const courseId = newCourse.courseId;
        const oldCourse = oldCoursesMap.get(courseId);

        if (oldCourse) {
          // If a matching course is found in oldCourses, merge and override properties
          return { ...newCourse, ...oldCourse };
        }

        // If no matching course is found, keep the new course as is
        return newCourse;
      });

      return {
        ...state,
        courses: mergedCourses,
        loadingCourses: false,
      };
    }
    case Actions.SET_LOADING_COURSES: {
      return {
        ...state,
        loadingCourses: true,
      };
    }
    case Actions.LOAD_COURSE: {
      const index = state.courses.findIndex(
        (i) => i.courseId === action.courseId,
      );
      return {
        ...state,
        courses: [
          ...state.courses.slice(0, index),
          ...state.courses.slice(index + 1),
          action.course,
        ],
      };
    }
    case Actions.LOAD_TOPIC: {
      const index = state.courses.findIndex(
        (i) => i.courseId === action.courseId,
      );
      const topicIndex = state.courses[index].topics.findIndex(
        (i) => i._id === action.topicId,
      );
      state.courses[index].topics[topicIndex] = {
        ...state.courses[index].topics[topicIndex],
        ...action.topic,
      };
      return {
        ...state,
        courses: [...state.courses],
      };
    }
    case Actions.ADD_COURSES: {
      return {
        ...state,
        courses: [...state.courses, action.course],
      };
    }
    case Actions.UPDATE_COURSES: {
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === action.course._id);

      if (!course) {
        return state;
      }

      course.name = action.course.name;
      course.startDate = action.course.startDate || '';
      course.endDate = action.course.endDate || '';
      course.isFavorite = action.course.isFavorite || false;
      course.courseSettings = action.course.courseSettings || false;

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_DEFAULT_CLASSROOM_GROUP: {
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === action.courseId);

      if (!course) {
        return state;
      }

      course.defaultClassroomGroup = action.classroomGroup.visibilitySettings;

      return {
        ...state,
        courses,
      };
    }
    case Actions.ADD_TOPIC: {
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === action.courseId);

      course.topics = [
        ...course.topics.map((topic) => ({
          ...topic,
          new: false,
        })),
        {
          ...action.topic,
          new: true,
        },
      ];

      return {
        ...state,
        courses,
      };
    }
    case Actions.REMOVE_NEW_TAG_FROM_TOPIC: {
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === action.courseId);
      const topic = (course.topics || []).find((t) => t._id === action.topicId);

      topic.new = false;

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_TOPIC: {
      const courses = JSON.parse(JSON.stringify(state.courses));
      const updatedTopic = action.topic;

      const course = courses.find((c) => c.courseId === updatedTopic.courseId);

      course.topics = course.topics.map((topic) =>
        topic._id !== action.topicId
          ? topic
          : {
              ...topic,
              ...updatedTopic,
            },
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.ACCEPT_COURSE: {
      return {
        ...state,
        courses: state.courses.map((course) => ({
          ...course,
          invitedAsStudent:
            course.courseId !== action.courseId
              ? course.invitedAsStudent
              : false,
          invitedAsTeacher:
            course.courseId !== action.courseId
              ? course.invitedAsTeacher
              : false,
        })),
      };
    }
    case Actions.REMOVE_INVITED_COURSE: {
      return {
        ...state,
        courses: state.courses.filter((c) => c.courseId !== action.courseId),
      };
    }
    case Actions.CANCEL_INVITED_COURSE: {
      const courseId = action.courseId;
      const email = action.email;
      const isTeacher = action.isTeacher;
      const inviteUserType = isTeacher ? 'invitedTeachers' : 'invitedStudents';
      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        [inviteUserType]:
          c.courseId !== courseId
            ? c[inviteUserType]
            : c[inviteUserType].filter((c) => c.email !== email),
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.REMOVE_COURSE_USER: {
      const courseId = action.courseId;
      const username = action.username;
      const isTeacher = action.isTeacher;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);

      if (isTeacher) {
        course.teachers = course.teachers.filter(
          (t) => t.username !== username,
        );
      } else {
        course.students = course.students.filter(
          (s) => s.username !== username,
        );
      }

      return {
        ...state,
        courses,
      };
    }
    case Actions.ADD_INVITED_STUDENTS_COURSE: {
      const courseId = action.courseId;
      const emails = action.emails;
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);

      course.invitedStudents = [...(course.invitedStudents || []), ...emails];
      course.invitedTeachers = course.invitedTeachers.filter(
        (teacher) => !emails.includes(teacher.email),
      );
      course.teachers = course.teachers.filter(
        (teacher) => !emails.includes(teacher.email),
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.ADD_INVITED_TEACHERS_COURSE: {
      const courseId = action.courseId;
      const emails = action.emails;
      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);

      course.invitedTeachers = [...(course.invitedTeachers || []), ...emails];
      course.invitedStudents = course.invitedStudents.filter(
        (s) => !emails.includes(s.email),
      );
      course.students = course.students.filter(
        (student) => !emails.includes(student.email),
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.LOAD_TOPIC_GOALS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const data = action.data;
      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        topics:
          c.courseId !== courseId
            ? c.topics
            : c.topics.map((t) => ({
                ...t,
                goalsProgress:
                  t._id !== topicId ? t.goalsProgress : data.goalsProgress,
                readingStatistics:
                  t._id !== topicId
                    ? t.readingStatistics
                    : data.readingStatistics,
              })),
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.LOAD_ALL_STUDENT_PROGRESS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const data = action.data;
      const students = data.students;
      const goalsCompletedByUsers = data.goalsCompletedByUsers;
      const writingGoalsCompletedByUsers = data.writingGoalsCompletedByUsers;
      const invitedStudents = data.invitedStudents;
      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        topics:
          c.courseId !== courseId
            ? c.topics
            : c.topics.map((t) => ({
                ...t,
                goalsProgress:
                  t._id !== topicId ? t.goalsProgress : goalsCompletedByUsers,
                writingGoalsCompletedByUsers:
                  t._id !== topicId
                    ? t.writingGoalsCompletedByUsers
                    : writingGoalsCompletedByUsers,
                students: t._id !== topicId ? t.students : students,
              })),
        invitedStudents:
          c.courseId !== courseId ? c.invitedStudents : invitedStudents,
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.UPDATE_TOPIC_GOALS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const goals = action.goals || [];
      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        topics:
          c.courseId !== courseId
            ? c.topics
            : c.topics.map((t) => ({
                ...t,
                goals: t._id !== topicId ? t.goals : goals,
              })),
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.UPDATE_READING_GOALS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const readingId = action.readingId;
      const goals = action.goals.map((g) => ({
        _id: g.goal,
        goal: g.goalAs,
        target: g.value,
      }));
      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        topics:
          c.courseId !== courseId
            ? c.topics
            : c.topics.map((t) => ({
                ...t,
                readingStatistics:
                  t._id !== topicId
                    ? t.readingStatistics
                    : t.readingStatistics.map((r) => ({
                        ...r,
                        goals: r._id !== readingId ? r.goals : goals,
                      })),
              })),
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.LOAD_QUIZ_QUESTIONS: {
      return {
        ...state,
        questionsByTopicId: {
          ...(state.questionsByTopicId || {}),
          ...action.questions,
        },
      };
    }
    case Actions.UPDATE_READING_QUESTIONS: {
      const clonedQuestions = _.cloneDeep(state.questionsByTopicId) || {};

      const updatedQuestions = action.questions || [];
      const updatedQuestionIds = updatedQuestions.map((q) => q._id);
      const deletedQuestions = action.deletedQuestions || [];

      const questionsByTopic = clonedQuestions[action.topicId] || [];

      const otherQuestions = questionsByTopic.filter(
        (q) =>
          !updatedQuestionIds.includes(q._id) &&
          !deletedQuestions.includes(q._id),
      );

      clonedQuestions[action.topicId] = otherQuestions.concat(updatedQuestions);

      return {
        ...state,
        questionsByTopicId: clonedQuestions,
      };
    }
    case Actions.ADD_READING: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const reading = action.reading;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);
      const topic = course.topics.find((t) => t._id === topicId);
      const readingStatistics = topic.readingStatistics.find(
        (r) => r._id === reading._id,
      );

      if (!readingStatistics) {
        topic.readingStatistics = [...topic.readingStatistics, reading];
      } else {
        topic.readingStatistics = topic.readingStatistics.map((r) => {
          if (r._id !== reading._id) {
            return r;
          }

          return {
            ...r,
            ...reading,
            isUploadingPdf: false,
          };
        });
      }

      return {
        ...state,
        courses,
      };
    }
    case Actions.CLEAR_ADDED_READINGS: {
      const courseId = action.courseId;
      const topicId = action.topicId;

      const newCourses = JSON.parse(JSON.stringify(state.courses));

      const course = newCourses.find((c) => c.courseId === courseId);
      const topic = course.topics.find((t) => t._id === topicId);

      topic.readingStatistics = topic.readingStatistics
        .filter(
          (r) =>
            !r.isUploadingPdf &&
            (!r.new || ((r.loading || r.success) && !r.error)),
        )
        .map((r) => {
          let updates = {};

          if (r.new && !r.loading) {
            updates.new = false;
          }

          return {
            ...r,
            ...updates,
          };
        });

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.REMOVE_READING: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const readingId = action.readingId;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);
      const topic = course.topics.find((t) => t._id === topicId);
      topic.readingStatistics = topic.readingStatistics.filter(
        (r) => r._id !== readingId,
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_READING: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const reading = action.reading;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const newCourses = courses.map((c) => ({
        ...c,
        topics:
          c.courseId !== courseId
            ? c.topics
            : c.topics.map((t) => ({
                ...t,
                readingStatistics:
                  t._id !== topicId
                    ? t.readingStatistics
                    : t.readingStatistics
                        .filter((r) => r._id !== reading._id)
                        .concat([reading]),
              })),
      }));

      return {
        ...state,
        courses: newCourses,
      };
    }
    case Actions.PDF_PARSING: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const readingId = action.readingId;
      const body = action.body;

      const courses = JSON.parse(JSON.stringify(state.courses));
      const course = courses.find((c) => c.courseId === courseId);

      if (course) {
        const topic = course.topics.find((t) => t._id === topicId);

        if (topic && Array.isArray(topic.readingStatistics)) {
          topic.readingStatistics = topic.readingStatistics.map((r) => {
            if (r._id !== readingId) {
              return r;
            }

            return {
              ...r,
              ...body,
            };
          });
        }
      }

      return {
        ...state,
        courses,
      };
    }
    case Actions.REMOVE_COURSE: {
      const courseId = action.courseId;

      let courses = JSON.parse(JSON.stringify(state.courses));

      courses = courses.filter((c) => c.courseId !== courseId);

      return {
        ...state,
        courses,
      };
    }
    case Actions.REMOVE_TOPIC: {
      const courseId = action.courseId;
      const topicId = action.topicId;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);
      course.topics = course.topics.filter((t) => t._id !== topicId);

      return {
        ...state,
        courses,
      };
    }
    case Actions.ADD_STUDENT_STATS: {
      const courseId = action.courseId;
      const stats = action.stats;

      const courseStats = JSON.parse(JSON.stringify(state.courseStats));

      courseStats[courseId] = {
        ...(courseStats[courseId] || {}),
        ...stats,
      };

      return {
        ...state,
        courseStats,
      };
    }
    case Actions.UPDATE_SOURCE_FORMAT_READING: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const pointId = action.pointId;
      const sourceFormat = action.sourceFormat;

      const courses = JSON.parse(JSON.stringify(state.courses));

      const course = courses.find((c) => c.courseId === courseId);
      const topic = course.topics.find((t) => t._id === topicId);
      const reading = topic.readingStatistics.find(
        (r) => r.pointId === pointId,
      );

      reading.sourceFormat = sourceFormat;

      return {
        ...state,
        courses,
      };
    }
    case Actions.SELECT_CLASSROOM_ENTITIES: {
      return {
        ...state,
        ...action.entities,
      };
    }
    case Actions.SELECT_COURSE: {
      return {
        ...state,
        selectedCourse: action.courseId,
        showCourseDashboard: false,
      };
    }
    case Actions.SELECT_SHOW_COURSE_DASHBOARD: {
      return {
        ...state,
        showCourseDashboard: action.showCourseDashboard,
        courseDashboardFilters: action.courseDashboardFilters,
        courseDashboardReportName: action.courseDashboardReportName || null,
      };
    }
    case Actions.REMOVE_COURSE_FILTERS: {
      return {
        ...state,
        courseDashboardFilters: {},
      };
    }
    case Actions.SET_COURSE_DASHBOARD_REPORT_NAME:
      return {
        ...state,
        courseDashboardReportName: action.courseDashboardReportName,
      };
    case Actions.SELECT_SHOW_COURSE_TEACHERS:
      return {
        ...state,
        showCourseTeachers: action.showCourseTeachers,
      };
    case Actions.SELECT_SHOW_COURSE_STUDENTS:
      return {
        ...state,
        showCourseStudents: action.showCourseStudents,
      };
    case Actions.SELECT_SHOW_COURSE_TOP_STUDENTS:
      return {
        ...state,
        showCourseTopStudents: action.showCourseTopStudents,
      };
    case Actions.SELECT_TOPIC: {
      return {
        ...state,
        selectedTopic: action.topicId,
      };
    }
    case Actions.SELECT_STUDENT: {
      return {
        ...state,
        selectedStudent: action.studentId,
      };
    }
    case Actions.SELECT_GROUP_STUDENT: {
      return {
        ...state,
        selectedGroupStudent: action.groupStudentId,
      };
    }
    case Actions.SELECT_READING: {
      return {
        ...state,
        selectedReading: action.readingId,
      };
    }
    case Actions.SELECT_READING_GRADES: {
      return {
        ...state,
        selectedReadingGrades: action.readingId,
      };
    }
    case Actions.SELECT_READING_GRADES_USERS: {
      return {
        ...state,
        selectedReadingGradesUsers: action.users,
      };
    }
    case Actions.SELECT_WRITING_PROMPT: {
      return {
        ...state,
        selectedWritingPrompt: action.writingPromptId,
        selectedResearchPrompt: null,
        selectedResearchSubmission: null,
        showResearchEditor: false,
      };
    }
    case Actions.SELECT_RESEARCH_PROMPT: {
      return {
        ...state,
        selectedResearchPrompt: action.researchPromptId,
      };
    }
    case Actions.SELECT_RESEARCH_SUBMISSION: {
      return {
        ...state,
        showResearchEditor: action.researchPromptId ? true : false,
        selectedResearchPrompt: action.researchPromptId,
        selectedResearchSubmission: action.researchSubmissionId,
      };
    }
    case Actions.RESET_COURSE: {
      return JSON.parse(JSON.stringify(initialState));
    }
    case Actions.UPDATE_WRITING_PROMPT_SETTING: {
      const courses = JSON.parse(JSON.stringify(state.courses));
      const updatedTopic = action.topic;

      const course = courses.find((c) => c.courseId === updatedTopic.courseId);

      course.topics = course.topics.map((topic) =>
        topic._id !== action.topicId
          ? topic
          : {
              ...topic,
              ...updatedTopic,
            },
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_RESEARCH_PROMPT_SETTING: {
      const courses = JSON.parse(JSON.stringify(state.courses));
      const updatedTopic = action.topic;

      const course = courses.find((c) => c.courseId === updatedTopic.courseId);

      course.topics = course.topics.map((topic) =>
        topic._id !== action.topicId
          ? topic
          : {
              ...topic,
              ...updatedTopic,
            },
      );

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_READING_IN_WILD_SETTING: {
      let courses = JSON.parse(JSON.stringify(state.courses));
      const courseId = action.courseId;

      const course = courses.find((course) => course.courseId === courseId);
      course.readingsInWild = action.readingsInWild;

      return {
        ...state,
        courses,
      };
    }
    case Actions.UPDATE_COURSE_READING_IN_WILD: {
      const readingsInWild = JSON.parse(
        JSON.stringify(state.readingsInWild || {}),
      );
      const courseId = action.courseId;
      const readingsByStudents = action.data;

      readingsInWild[courseId] = readingsByStudents;

      return {
        ...state,
        readingsInWild,
      };
    }
    case Actions.UPDATE_COURSE_READING_IN_COURSE: {
      const readingsInCourse = JSON.parse(
        JSON.stringify(state.readingsInCourse || {}),
      );
      const courseId = action.courseId;
      const readingsByStudents = action.data;

      readingsInCourse[courseId] = readingsByStudents;

      return {
        ...state,
        readingsInCourse,
      };
    }
    case Actions.UPDATE_COURSE_READING_IN_ALL: {
      const readingsInAll = JSON.parse(
        JSON.stringify(state.readingsInAll || {}),
      );
      const courseId = action.courseId;
      const readingsByStudents = action.data;

      readingsInAll[courseId] = readingsByStudents;

      return {
        ...state,
        readingsInAll,
      };
    }
    case Actions.LOAD_TOPIC_READINGS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const courseIndex = state.courses.findIndex(
        (i) => i.courseId === courseId,
      );
      const topicIndex = state.courses[courseIndex].topics.findIndex(
        (i) => i._id === topicId,
      );

      return {
        ...state,
        courses: [
          ...state.courses.slice(0, courseIndex),
          {
            ...state.courses[courseIndex],
            topics: [
              ...state.courses[courseIndex].topics.slice(0, topicIndex),
              {
                ...state.courses[courseIndex].topics[topicIndex],
                ...action.topic,
              },
              ...state.courses[courseIndex].topics.slice(topicIndex + 1),
            ],
          },
          ...state.courses.slice(courseIndex + 1),
        ],
      };
    }
    case Actions.LOAD_TOPIC_WRITING_PROMPTS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const courseIndex = state.courses.findIndex(
        (i) => i.courseId === courseId,
      );
      const topicIndex = state.courses[courseIndex].topics.findIndex(
        (i) => i._id === topicId,
      );

      return {
        ...state,
        courses: [
          ...state.courses.slice(0, courseIndex),
          {
            ...state.courses[courseIndex],
            topics: [
              ...state.courses[courseIndex].topics.slice(0, topicIndex),
              {
                ...state.courses[courseIndex].topics[topicIndex],
                ...action.topic,
              },
              ...state.courses[courseIndex].topics.slice(topicIndex + 1),
            ],
          },
          ...state.courses.slice(courseIndex + 1),
        ],
      };
    }
    case Actions.LOAD_TOPIC_RESEARCH_PROMPTS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const courseIndex = state.courses.findIndex(
        (i) => i.courseId === courseId,
      );
      const topicIndex = state.courses[courseIndex].topics.findIndex(
        (i) => i._id === topicId,
      );

      return {
        ...state,
        courses: [
          ...state.courses.slice(0, courseIndex),
          {
            ...state.courses[courseIndex],
            topics: [
              ...state.courses[courseIndex].topics.slice(0, topicIndex),
              {
                ...state.courses[courseIndex].topics[topicIndex],
                ...action.topic,
              },
              ...state.courses[courseIndex].topics.slice(topicIndex + 1),
            ],
          },
          ...state.courses.slice(courseIndex + 1),
        ],
      };
    }
    default:
      return state;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import CloudinaryImage from './CloudinaryImage';
import * as Actions from 'redux/actions/index';
import * as Helpers from 'helpers';

import './profileImage.scss';

const ProfileImageAvailability = {};

class ProfileImage extends Component {
  static propTypes = {
    allowUserToChangePic: PropTypes.bool,
    hostedImageVersion: PropTypes.number,
    loggedInUser: PropTypes.object,
    userId: PropTypes.string,
    username: PropTypes.string,
    allowClickToPortfolio: PropTypes.bool,
    imageClass: PropTypes.string,
    // history: PropTypes.function,
    actions: PropTypes.object,
    size: PropTypes.number,
    isGroup: PropTypes.bool,
    onImageChanged: PropTypes.func,
    onComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { userId, profileImage, actions } = this.props;

    if (userId) {
      if (profileImage && profileImage.checkingCloudinary !== true) {
        return;
      }

      if (
        ProfileImageAvailability[userId] &&
        ProfileImageAvailability.checkingCloudinary !== true
      ) {
        return;
      }

      actions.setCheckingCloudinary(userId, true);
      ProfileImageAvailability[userId] = { checkingCloudinary: true };

      const cloudinaryUrlToCheck = this.props.hostedImageVersion
        ? `https://res.cloudinary.com/averpoint/image/upload/v${
            this.props.hostedImageVersion
          }/profile_pics/${this.props.userId}`
        : `https://res.cloudinary.com/averpoint/image/upload/v1/profile_pics/${
            this.props.userId
          }`;

      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', cloudinaryUrlToCheck, true);
      xhr.onload = e => {
        if (xhr.status == '404') {
          ProfileImageAvailability[userId] = {
            checkingCloudinary: false,
            isImageAvailable: false,
          };
          actions.setImageAvailablility(userId, false);
        } else {
          ProfileImageAvailability[userId] = {
            checkingCloudinary: false,
            isImageAvailable: true,
          };
          actions.setImageAvailablility(userId, true);
        }
      };
      xhr.send();
    }
  }

  renderUserProfileImage() {
    const { userId, username, profileImage } = this.props;

    const allowUserToChangePic =
      this.props.allowUserToChangePic &&
      (this.props.loggedInUser._id === this.props.userId || this.props.isGroup);

    const imageClickableClassName =
      allowUserToChangePic || this.props.allowClickToPortfolio
        ? ' clickable'
        : '';

    let imageClass;

    imageClass = this.props.imageClass
      ? this.props.imageClass
      : 'profile-image';

    imageClass += imageClickableClassName;

    const cloudinaryOptions = {
      client_hints: true,
      transformation: [{}],
    };

    let profilePicId = 'xyz';
    if (this.props.userId) profilePicId = `profile_pics/${this.props.userId}`;

    const size = this.props.size || 30;
    const style = {
      width: size,
      height: size,
    };

    if (userId && (!profileImage || profileImage.checkingCloudinary)) {
      return null;
    }

    let defaultImage = this.props.isGroup
      ? 'Help_tkj9g9.svg'
      : 'baseline-face-24px_rjk81k.svg';

    let placeholderImage = '';

    if (username) {
      const background = Helpers.intToRGB(Helpers.generateHash(username));
      const color = Helpers.invertColor(background, true);
      placeholderImage = `https://ui-avatars.com/api/?background=${background}&color=${color}&bold=true&name=${username}`;
    }

    return (
      <CloudinaryImage
        cloudinaryImageId={profilePicId}
        cloudinaryVersion={this.props.hostedImageVersion}
        imageClass={imageClass}
        style={style}
        width={size}
        height={size}
        face
        cropType="fit"
        defaultImage={defaultImage}
        allowUserToChangePic={allowUserToChangePic}
        onLicenseTermsAccepted={this.onLicenseTermsAccepted}
        photoOwnerId={this.props.userId}
        handleClick={this.handleClick}
        placeholderImage={
          profileImage && profileImage.isImageAvailable ? '' : placeholderImage
        }
      />
    );
  }

  render() {
    let hideToolTip = true;
    let toolTipText = '';

    const allowUserToChangePic =
      this.props.allowUserToChangePic &&
      (this.props.loggedInUser._id === this.props.userId || this.props.isGroup);

    if (allowUserToChangePic) {
      hideToolTip = false;
      toolTipText = 'Click to change your picture';
    } else if (this.props.allowClickToPortfolio) {
      hideToolTip = false;
      toolTipText = `Click to go to ${this.props.username}'s Profile`;
    }

    return (
      <ToolTip
        toolTipText={toolTipText}
        toolTipPosition="bottom"
        hide={hideToolTip}
      >
        {this.renderUserProfileImage(this.props.userId)}
      </ToolTip>
    );
  }

  handleClick = e => {
    if (!this.props.allowClickToPortfolio) return;

    e.stopPropagation();

    const route = `/portfolio/${this.props.username}`;

    this.props.history.push(route);
  };

  onLicenseTermsAccepted = async (
    terms,
    hostedImageURL,
    hostedImageVersion,
  ) => {
    // Set image availability
    const { userId, onComplete, actions } = this.props;
    actions.setImageAvailablility(userId, true);

    // User method to update license terms
    if (this.props.isGroup) {
      await this.props.onImageChanged(
        terms,
        hostedImageURL,
        hostedImageVersion,
      );
    } else {
      const user = {
        ...this.props.loggedInUser,
        photoLicenseTerms: terms,
        hostedImageURL,
        hostedImageVersion,
      };
      await this.props.actions.updateUserPhotoLicenseTerms(user);
    }
    if (onComplete) {
      onComplete();
    }
  };
}

ProfileImage.defaultProps = {
  imageClass: '',
  profileImageChangable: false,
};

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const loggedInUser = state.user.user;
  const profileImage = state.profileImage;

  return {
    authenticated,
    loggedInUser,
    profileImage: profileImage[ownProps.userId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileImage),
);

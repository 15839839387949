import React from 'react';
import { Link } from 'react-router-dom';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './LandingCopy.scss';

export default function LandingCopy() {
  return (
    <div className="landing-copy">
      <div className="content-info">
        <div className="text-center title av-black mt-4">
          A news experience to navigate the <br />{' '}
          <span className="av-red">information crisis.</span>
        </div>
        <div className="text-center how-it-works">How AverPoint works</div>
        <ul className="">
          <li>
            News feed: Read articles chosen for you to learn more about our
            complex world. Avoid the outrage traps and filter bubbles.
          </li>
          <li>
            News analytics: Track and improve what news you're consuming, like a
            Fitbit for news. You can only improve what you measure.
          </li>
          <li>
            Community review: Read, view, and listen with a community review,
            like a Yelp review for ideas.
          </li>
        </ul>
      </div>
      <div className="footer text-center">
        <div className="title">
          Sign up now for a more elegant news experience
        </div>
        <Link to="/auth/signUp">
          <RectangularButton className="bg-av-blue av-white d-inline-flex align-items-center justify-content-center">
            Sign Up Now
          </RectangularButton>
        </Link>
      </div>
    </div>
  );
}

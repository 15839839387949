import React, { Fragment } from 'react';

export default function RTPSummary({ rtps }) {
  function renderSummary(text, usersCount) {
    if (!usersCount) {
      return null;
    }

    return (
      <div className="d-flex align-items-center mb-2">
        <span className="font-weight-600 av-blue mr-3">{text}</span>
        <span
          className="pt-2 pb-2 pl-3 pr-3 d-inline-block bg-av-light-blue font-weight-600 av-blue"
          style={{ borderRadius: '2px' }}
        >
          {usersCount}
        </span>
        <div className="ml-3 gray-4">{usersCount > 1 ? 'users' : 'user'}</div>
      </div>
    );
  }

  const sufficientEvidence = rtps.filter(
    rtp => rtp.surveyResponse.sufficientEvidence === 'Yes',
  ).length;
  const inSufficientEvidence = rtps.filter(
    rtp => rtp.surveyResponse.sufficientEvidence === 'No',
  ).length;

  return (
    <Fragment>
      {renderSummary('The evidence is sufficient', sufficientEvidence)}
      {renderSummary('The evidence is insufficient', inSufficientEvidence)}
    </Fragment>
  );
}

import React, { Fragment, useReducer } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import SearchItems from 'components/elements/molecules/SearchItems/SearchItems';
import * as Actions from '../../../redux/actions/index';

import './CollectionSelectGroupsModal.scss';

function CollectionSelectGroupsModal({
  onClose,
  topGroups,
  groups,
  actions,
  collectionId,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_GROUPS_SELECTION':
          return { ...state, showGroupsSelection: true };
        case 'TOGGLE_SELECT_GROUP': {
          const index = state.selectedGroups.indexOf(action.payload);

          if (index === -1) {
            return {
              ...state,
              selectedGroups: [...state.selectedGroups, action.payload],
            };
          } else {
            return {
              ...state,
              selectedGroups: [
                ...state.selectedGroups.slice(0, index),
                ...state.selectedGroups.slice(index + 1),
              ],
            };
          }
        }
        case 'SET_SEARCH_TERM':
          return {
            ...state,
            searchTerm: action.payload,
          };
        case 'SET_SAVING':
          return {
            ...state,
            saving: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showGroupsSelection: false,
      selectedGroups: [],
      searchTerm: '',
      saving: false,
    },
  );

  function onToggleGroup(groupId) {
    dispatch({ type: 'TOGGLE_SELECT_GROUP', payload: groupId });
  }

  async function onDone() {
    dispatch({ type: 'SET_SAVING', payload: true });

    for (let i = 0; i < state.selectedGroups.length; i++) {
      await actions.addCollectionToGroup(collectionId, state.selectedGroups[i]);
    }

    onClose();
  }

  function renderHeader() {
    return (
      <Modal.Header closeButton={true} onHide={onClose}>
        <Modal.Title>AverPoint Groups</Modal.Title>
      </Modal.Header>
    );
  }

  function renderTopGroup(group) {
    const className = classnames('mr-2 mb-2 btn-group', {
      selected: state.selectedGroups.indexOf(group.id) !== -1,
    });
    return (
      <RoundedButton
        key={group.id}
        mainClassName={className}
        className=""
        label={group.name}
        onClick={() => {
          onToggleGroup(group.id);
        }}
        title={group.name}
        hoverLabel={group.name}
      />
    );
  }

  function renderTopGroups() {
    return <div className="mt-4">{topGroups.map(renderTopGroup)}</div>;
  }

  function renderGroup(group) {
    const className = classnames('group', {
      selected: state.selectedGroups.indexOf(group._id) !== -1,
    });
    return (
      <div
        className={className}
        onClick={() => onToggleGroup(group._id)}
        key={group._id}
      >
        {group.name}
      </div>
    );
  }

  function renderGroups() {
    return (
      <div className="groups-container mt-2">
        {groups.mine
          .map(groupId => groups.entities[groupId])
          .filter(group =>
            group.name
              ? group.name
                  .toLowerCase()
                  .indexOf(state.searchTerm.toLowerCase()) !== -1
              : true,
          )
          .map(group => renderGroup(group))}
      </div>
    );
  }

  function renderSearchGroups() {
    if (state.showGroupsSelection) {
      return (
        <Fragment>
          <p className="av-blue font-weight-normal">
            Not related to any of the above? Search for other groups on
            AverPoint:
          </p>
          <SearchItems
            placeholder="Search Groups"
            searchOnEnter={searchTerm =>
              dispatch({ type: 'SET_SEARCH_TERM', payload: searchTerm })
            }
          />
          {renderGroups()}
        </Fragment>
      );
    } else {
      return (
        <ActionLink
          label="I don’t see a relevant group"
          linkClassName="av-blue dont-see-group"
          onClick={() => dispatch({ type: 'SHOW_GROUPS_SELECTION' })}
        />
      );
    }
  }

  function renderBody() {
    return (
      <Modal.Body>
        <p>
          Looks like this is the first time this video’s been added to
          AverPoint. What group should we add this to?
        </p>
        {renderTopGroups()}
        <div className="mt-4">{renderSearchGroups()}</div>
      </Modal.Body>
    );
  }

  function renderDoneButton() {
    const disabled = !state.selectedGroups.length || state.saving;
    return (
      <RoundedButton
        mainClassName="ml-2"
        className=""
        label="Done"
        onClick={onDone}
        title="Done"
        hoverLabel="Done"
        disabled={disabled}
      />
    );
  }

  function renderFooter() {
    return (
      <Modal.Footer>
        <RoundedButton
          mainClassName="mr-2"
          className=""
          label="Cancel"
          onClick={() => {
            onClose();
          }}
          title="Cancel"
          hoverLabel="Cancel"
        />
        {renderDoneButton()}
      </Modal.Footer>
    );
  }

  return (
    <Modal show={true} className="collection-select-groups-modal">
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    topGroups: state.app.topGroups,
    groups: state.groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionSelectGroupsModal);

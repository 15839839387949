import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactTextareaAutosize } from 'react-textarea-autosize';
import classnames from 'classnames';

// scss
import './TextareaAutosize.scss';

function TextareaAutosize(props) {
  const className = classnames(props.className, 'textarea-autosize m-0 p-0');
  return (
    <ReactTextareaAutosize
      className={className}
      onChange={props.onChange}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      value={props.value}
      autoFocus={props.autoFocus}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      style={props.style}
    />
  );
}

TextareaAutosize.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
};

TextareaAutosize.defaultProps = {
  readOnly: false,
  autoFocus: false,
  style: {},
  className: '',
};

export default TextareaAutosize;

import React, { Component } from 'react';

import ProfileImage from 'components/elements/molecules/Images/profileImage';
import DisplayName from 'components/elements/atoms/DisplayName/DisplayName';

import TourHighlight from 'components/elements/molecules/TourHighlight'; // STEP count for tutorial

import './NavbarProfile.scss';

const TUTORIAL_STEP = 3;

class NavbarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileDropDown: false,
      showModal: false,
      modalToShow: '',
    };

    this.target = React.createRef();
  }
  componentDidMount() {
    document.body.addEventListener(
      'click',
      this.onBodyClickedOutsideProfile,
      false,
    );
  }

  componentWillUnmount() {
    document.body.removeEventListener(
      'click',
      this.onBodyClickedOutsideProfile,
    );
  }

  onBodyClickedOutsideProfile = (e) => {
    const target = e.target;
    const content_area = document.getElementById('navbar-profile-container');
    const controlsicon = document.getElementById('profile-notes');

    if (
      target !== controlsicon &&
      target !== content_area &&
      !this.isChildOf(target, content_area) &&
      !this.isChildOf(target, controlsicon)
    ) {
      this.setState({
        showProfileDropDown: false,
      });
    }
  }

  isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    }
    return this.isChildOf(child.parentNode, parent);
  }

  toggleProfileDropDown = () => {
    this.setState({
      showProfileDropDown: !this.state.showProfileDropDown,
    });
  }

  hideProfileDropDown = () => {
    this.setState({
      showProfileDropDown: false,
    });
  }

  render() {
    return (
      <div
        className="navbar-profile-container"
        id="navbar-profile-container"
        onClick={() =>
          this.props.goToPage('/portfolio/' + this.props.user.username)
        }
      >
        <div id="profile-notes" ref={this.target}>
          <ProfileImage
            userId={this.props.user._id}
            photoLicenseTerms={this.props.user.photoLicenseTerms}
            hostedImageVersion={this.props.user.hostedImageVersion}
            size={30}
            allowUserToChangePic={false}
            username={this.props.user.username}
          />
          <TourHighlight
            target={this.target}
            placement="bottom"
            step={TUTORIAL_STEP}
            buttonText="Sounds good"
            gotoNextStep
            visible
          >
            You can click here any time to visit your personal{' '}
            <b>Collections</b>.
          </TourHighlight>
        </div>
        <div className="username">
          <DisplayName user={this.props.user} />
        </div>
      </div>
    );
  }
}

export default NavbarProfile;

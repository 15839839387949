import { Icon, ICONS } from 'components/Icons';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from 'redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './EditWritingPropertiesObjectives.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { offsetToTimeString } from 'helpers';
import ReactSwitch from 'react-switch';

// Get the current timezone offset
const timezoneOffset = new Date().getTimezoneOffset() / 60;

const timezones = {
  ...allTimezones,
  'Asia/Calcutta': 'Chennai, Kolkata, Mumbai, New Delhi'
};

function EditWritingPropertiesObjectives({
  onClose,
  topic,
  actions,
  properties,
  objectives,
}) {
  const [writingPromptSettings, setWritingPromptSettings] = useState(() => {
    const writingPromptSettings = topic.writingPromptSettings || {};

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const momentZone = moment.tz.zone(currentTimezone);
    const currentTimezoneAbbr = momentZone ? momentZone.abbr(new Date().getTime()) : ''

    const defaultTimezone = {
      value: currentTimezone,
      label: `(${offsetToTimeString(-1 * timezoneOffset)}) ${currentTimezoneAbbr}`,
      offset: -1 * timezoneOffset,
      altName: timezones[currentTimezone],
      abbrev: currentTimezoneAbbr,
    };

    // const defaultTimezone = {
    //   value: 'Etc/GMT',
    //   label: '(GMT+0:00) UTC',
    //   offset: 0,
    //   abbrev: 'GMT',
    //   altName: 'British Standard Time',
    // };

    return {
      ...writingPromptSettings,
      timezone: writingPromptSettings.timezone || defaultTimezone,
    }
  });

  const handleWritingPromptSettingsChange = (variable, value, timePart) => {
    setWritingPromptSettings((writingPromptSettings) => {
      const originalWritingPromptSettings = topic.writingPromptSettings || {};

      const newWritingPromptSettings = JSON.parse(JSON.stringify(writingPromptSettings));

      if (!timePart) {
        newWritingPromptSettings[variable] = value;
      } else {
        const dateValue = newWritingPromptSettings[variable];
        if (timePart === 'HH') {
          newWritingPromptSettings[variable] = moment(dateValue).set('hours', value).format('YYYY-MM-DDTHH:mm');
        } else if (timePart === 'mm') {
          newWritingPromptSettings[variable] = moment(dateValue).set('minutes', value).format('YYYY-MM-DDTHH:mm');
        } else if (timePart === 'a') {
          const hours = moment(dateValue).get('hours');
          let aHours;

          if (value === 'am') {
            if (hours === 12) {
              aHours = 0;
            } else if (hours > 12) {
              aHours = hours - 12;
            } else {
              aHours = hours;
            }
          } else {
            if (hours === 12) {
              aHours = 12;
            } else {
              aHours = hours + 12;
            }
          }

          newWritingPromptSettings[variable] = moment(dateValue).set('hours', aHours).format('YYYY-MM-DDTHH:mm');
        }
      }


      if (variable === 'dueDate' && !value) {
        newWritingPromptSettings['lastDateOfSubmission'] = null;
      }

      if (variable === 'scheduleDate') {
        if (value) {
          newWritingPromptSettings['status'] = 'scheduled';
        } else {
          newWritingPromptSettings['status'] = originalWritingPromptSettings.status !== 'scheduled'
            ? originalWritingPromptSettings.status
            : 'notStarted';
        }
      }

      if (variable === 'status' && !['notStarted', 'scheduled'].includes(value)) {
        newWritingPromptSettings['isVisible'] = true;
      }

      if (variable === 'status' && value !== 'notStarted') {
        newWritingPromptSettings['scheduleDate'] = null;
      }

      if (variable === 'status' && value === 'overdue') {
        newWritingPromptSettings['dueDate'] = null;
      }

      if (variable === 'status' && value === 'completed') {
        newWritingPromptSettings['lastDateOfSubmission'] = null;
      }

      return newWritingPromptSettings;
    });
  };

  useEffect(() => {
    const props = [...properties, ...objectives];
    for (let i = 0; i < props.length; i++) {
      const property = props[i];

      if (!writingPromptSettings[property.variable]) {
        if (property.config.defaultValue !== undefined) {
          writingPromptSettings[property.variable] =
            property.config.defaultValue;
        } else if (property.config.type === 'number') {
          writingPromptSettings[property.variable] = 0;
        }
      }
    }

    setWritingPromptSettings({ ...writingPromptSettings });
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateDates = (variable, value) => {
    if (variable === 'scheduleDate' && value) {
      const scheduleDate = moment(value);

      if (writingPromptSettings.dueDate && moment(writingPromptSettings.dueDate).isBefore(scheduleDate)) {
        return {
          valid: false,
          message: 'Select a date before the due date',
        };
      }

      if (writingPromptSettings.lastDateOfSubmission && moment(writingPromptSettings.lastDateOfSubmission).isBefore(scheduleDate)) {
        return {
          valid: false,
          message: 'Select a date before the last date of submission',
        };
      }
    }

    if (variable === 'dueDate' && value) {
      const dueDate = moment(value);

      if (writingPromptSettings.lastDateOfSubmission && moment(writingPromptSettings.lastDateOfSubmission).isBefore(dueDate)) {
        return {
          valid: false,
          message: 'Select a date before the last date of submission',
        };
      }
    }

    return {
      valid: true,
    };
  }

  function validateProperty(property) {
    const { variable, config } = property;
    const { type, required, min } = config;
    const value = writingPromptSettings[variable];

    if (required && !value) {
      return false;
    }

    if (variable === 'studentShouldCompleteCount' && value !== 'N/A') {
      if (writingPromptSettings['assignToStudentCount'] !== 'N/A' && writingPromptSettings['assignToStudentCount'] < value) {
        return false;
      }
    }

    const isValid = validateDates(variable, value);
    if (!isValid.valid) {
      return false;
    }

    if (type === 'number') {
      if (value === 'N/A' && config.allowNone) {
        return true;
      }

      if (value && isNaN(+value)) {
        return false;
      }

      if (min && (!value || min > value)) {
        return false;
      }
    }

    return true;
  }

  function validate() {
    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];

      if (!validateProperty(property)) {
        return false;
      }
    }

    for (let i = 0; i < objectives.length; i++) {
      let objective = objectives[i];

      if (!validateProperty(objective)) {
        return false;
      }
    }

    return true;
  }

  function removeTimezone(date) {
    if (!date) {
      return null;
    }

    const dateStringWithoutTimezone = date.replace(/:\d\d.\d\d\d.*/g, '');

    return dateStringWithoutTimezone;
  }

  async function onSubmit() {
    setIsSubmitted(true);

    if (!validate()) {
      return;
    }

    const updatedWritingPromptSettings = JSON.parse(JSON.stringify(writingPromptSettings));
    updatedWritingPromptSettings.scheduleDate = removeTimezone(updatedWritingPromptSettings.scheduleDate);
    updatedWritingPromptSettings.dueDate = removeTimezone(updatedWritingPromptSettings.dueDate);
    updatedWritingPromptSettings.lastDateOfSubmission = removeTimezone(updatedWritingPromptSettings.lastDateOfSubmission);

    await actions.updateWritingPromptSettings(
      topic.courseId,
      topic._id,
      updatedWritingPromptSettings,
    );

    onClose();
  }

  function checkIsDisabled(variable, config, writingPromptSettings, optionValue) {
    if (config.dependentVariable && !writingPromptSettings[config.dependentVariable]) {
      return true;
    }

    if (variable === 'isVisible') {
      return !['notStarted', 'scheduled'].includes(writingPromptSettings.status);
    }

    if (variable === 'status' && optionValue === 'notStarted') {
      return ['notStarted', 'scheduled'].includes(writingPromptSettings.status);
    }

    if (variable === 'status' && optionValue === 'scheduled') {
      return true;
    }

    if (variable === 'scheduleDate') {
      return !['notStarted', 'scheduled'].includes(writingPromptSettings.status);
    }

    if (variable === 'dueDate') {
      return !['notStarted', 'scheduled', 'live'].includes(writingPromptSettings.status);
    }

    if (variable === 'lastDateOfSubmission') {
      return !['notStarted', 'scheduled', 'live', 'overdue'].includes(writingPromptSettings.status);
    }

    return false;
  }

  function renderRequiredCheck(variable, config) {
    if (config.isRequired) {
      return <span className="av-red">*</span>;
    }

    return null;
  }

  function renderAllowNone(variable, config) {
    if (!config.allowNone) {
      return null;
    }

    const value = writingPromptSettings[variable];

    return (
      <React.Fragment>
        <span className={value !== 'N/A' ? 'gray-3' : ''}>{config.labelForNone}</span>
        <ReactSwitch
          className="mx-3"
          offColor="#dbe1e4"
          offHandleColor="#65737b"
          onColor="#d5e7f4"
          onHandleColor="#1286C8"
          width={30}
          height={16}
          checkedIcon={false}
          uncheckedIcon={false}
          checked={value !== 'N/A'}
          onChange={(checked) => {
            handleWritingPromptSettingsChange(variable, !checked ? 'N/A' : 0);
          }}
        />
      </React.Fragment>
    );
  }

  function renderValue(variable, config) {
    const value = writingPromptSettings[variable];

    switch (config.type) {
      case 'number':
        return (
          <div className="d-flex align-items-center">
            {renderAllowNone(variable, config)}
            <input
              type="text"
              className="form-control text-right value number"
              value={value}
              onChange={(e) => {
                handleWritingPromptSettingsChange(variable, e.target.value);
              }}
              onClick={(event) => event.target.select()}
              disabled={value === 'N/A'}
            ></input>
          </div>
        );
      case 'select':
        return (
          <select
            className="form-control value"
            value={value || config.defaultValue}
            onChange={(e) => {
              handleWritingPromptSettingsChange(variable, e.target.value);
            }}
            disabled={checkIsDisabled(variable, config, writingPromptSettings)}
          >
            {config.options.map((i) => (
              <option
                value={i.value}
                disabled={checkIsDisabled(variable, config, writingPromptSettings, i.value)}
              >
                {i.label}
              </option>
            ))}
          </select>
        );
      case 'datetime-local':
        return (
          <div className="d-flex align-items-center">
            <input
              type="date"
              className="form-control value datetime-local"
              value={value
                ? moment(value).format('YYYY-MM-DD')
                : ''}
              min={moment(writingPromptSettings[config.minVariable] || undefined).format('YYYY-MM-DD')}
              onChange={(e) => {
                handleWritingPromptSettingsChange(variable, e.target.value ? `${e.target.value}T00:00` : e.target.value);
              }}
              disabled={checkIsDisabled(variable, config, writingPromptSettings)}
            />
            <input
              type="text"
              className="form-control text-right value hours ml-2"
              value={value ? moment(value).format('hh') : '00'}
              onChange={(e) => {
                const value = Number(e.target.value);

                if (!e.target.value || value === 0 || value > 12) {
                  return;
                }

                handleWritingPromptSettingsChange(variable, e.target.value, 'HH');
              }}
              onClick={(event) => event.target.select()}
              maxLength={3}
              disabled={!value || checkIsDisabled(variable, config, writingPromptSettings)}
            />
            <span className="ml-2">:</span>
            <input
              type="text"
              className="form-control text-right value hours ml-2"
              value={value ? moment(value).format('mm') : '00'}
              onChange={(e) => {
                const value = Number(e.target.value);

                if (!e.target.value || value > 59) {
                  return;
                }

                handleWritingPromptSettingsChange(variable, e.target.value, 'mm');
              }}
              onClick={(event) => event.target.select()}
              maxLength={3}
              disabled={!value || checkIsDisabled(variable, config, writingPromptSettings)}
            />
            <select
              className="form-control value am-pm-picker"
              value={value ? moment(value).format('a') : 'am'}
              onChange={(e) => {
                handleWritingPromptSettingsChange(variable, e.target.value, 'a');
              }}
              disabled={!value || checkIsDisabled(variable, config, writingPromptSettings)}
            >
              <option value="am">AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
        );
      case 'timezone':
        return (
          <TimezoneSelect
            value={value}
            onChange={(timezoneValue) => handleWritingPromptSettingsChange(variable, timezoneValue)}
            timezones={timezones}
          />
        );
    }
  }

  function renderError(variable, config) {
    const value = writingPromptSettings[variable];

    if (!isSubmitted) {
      return null;
    }

    const isValidDate = validateDates(variable, value);
    if (!isValidDate.valid) {
      return <small className="text-danger">{isValidDate.message}</small>;
    }

    if (config.type === 'number' && !config.allowNone) {
      if (value && isNaN(+value)) {
        return <small className="text-danger">Number only</small>;
      }
    }

    if (config.required && !value) {
      return <small className="text-danger">* Required</small>;
    }

    if (config.type === 'number') {
      // if (value && isNaN(+value)) {
      //   return <small className="text-danger">Value should be number</small>;
      // }

      if (config.min && (!value || config.min > value)) {
        return (
          <small className="text-danger">
            Minimum value should be {config.min}
          </small>
        );
      }
    }

    if (variable === 'studentShouldCompleteCount' && value !== 'N/A') {
      if (writingPromptSettings['assignToStudentCount'] !== 'N/A' && writingPromptSettings['assignToStudentCount'] < value) {
        return (
          <small className="text-danger">
            Should be less than assigned prompt count
          </small>
        );
      }
    }

    return null;
  }

  function renderProperty(property, variable, config) {
    return (
      <div className="mb-3 d-flex align-items-center">
        <div className="flex-fill">
          {property}
          {renderRequiredCheck(variable, config)}
        </div>
        <div className="d-inline-flex flex-column align-items-end">
          {renderValue(variable, config)}
          <div>{renderError(variable, config)}</div>
        </div>
      </div>
    );
  }

  function renderProperties() {
    return (
      <div className="properties">
        <div className="font-size-16 mb-3 pb-2 title">Properties</div>
        {properties.map((i) => renderProperty(i.text, i.variable, i.config))}
      </div>
    );
  }

  function renderObjectives() {
    return (
      <div className="properties mt-4">
        <div className="font-size-16 mb-3 title pb-2">Objectives</div>
        {objectives.map((i) => renderProperty(i.text, i.variable, i.config))}
      </div>
    );
  }

  return (
    <Modal show={true} className="edit-writing-properties-objectives-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Manage writing properties & objectives</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {renderProperties()}
        {renderObjectives()}
        <div className="d-flex align-items-center justify-content-end">
          <RectangularButton
            className="mt-4"
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </RectangularButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditWritingPropertiesObjectives);

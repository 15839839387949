import * as Actions from 'redux/actions/index';
import moment from 'moment';

const initialState = {
  thisWeekGoals: [],
  lastWeekGoals: [],
  dailyActivity: {},
};

export default function weeklyGoals(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_DAILY_ACTIVITY: {
      return {
        ...state,
        dailyActivity: action.dailyActivity,
      };
    }
    case Actions.LOAD_LAST_WEEK_DAILY_ACTIVITY: {
      return {
        ...state,
        lastWeekDailyActivity: action.lastWeekDailyActivity,
      };
    }
    case Actions.LOAD_THIS_WEEK_GOALS: {
      return {
        ...state,
        thisWeekGoals: action.weeklyGoals,
      };
    }
    case Actions.LOAD_THIS_MID_WEEK_GOALS: {
      return {
        ...state,
        thisMidWeekGoals: action.midWeeklyGoals,
      };
    }
    case Actions.LOAD_LAST_WEEK_GOALS: {
      return {
        ...state,
        lastWeekGoals: action.weeklyGoals,
      };
    }
    case Actions.LOAD_LAST_MID_WEEK_GOALS: {
      return {
        ...state,
        lastMidWeekGoals: action.midWeeklyGoals,
      };
    }
    case Actions.LOAD_LEVEL_GOALS: {
      return {
        ...state,
        levelGoals: action.levelGoals,
      };
    }
    case Actions.LOAD_LAST_WEEK_LEVEL_GOALS: {
      return {
        ...state,
        lastWeekLevelGoals: action.levelGoals,
      };
    }
    case Actions.LOAD_LEVEL_GOALS_HELPER: {
      return {
        ...state,
        levelGoalsHelper: action.levelGoals,
      };
    }
    case Actions.LOAD_GOALS_DEFINITION: {
      return {
        ...state,
        goalsDefinition: action.goalsDefinition,
      };
    }
    case Actions.LOAD_NOT_EARNED_BADGES: {
      return {
        ...state,
        notEarnedBadges: action.notEarnedBadges,
      };
    }
    case Actions.LOAD_EARNED_BADGES: {
      return {
        ...state,
        earnedBadges: action.earnedBadges,
      };
    }
    case 'MEDIA_LOGGED': {
      const day = moment(action.mediaLog.logDate).format('ddd');
      return {
        ...state,
        dailyActivity: {
          ...state.dailyActivity,
          [day]: true,
        },
      };
    }
    default:
      return state;
  }
}

import * as React from 'react';
import * as Actions from 'redux/actions/index';

import AddToCollectionModal from 'components/modals/AddToCollection/AddToCollectionModal';
import AddToGroup from '../../components/modals/AddToGroup/AddToGroup';
import AddPictureModal from 'components/modals/AddPicture/AddPictureModal';
import AddPointModal from 'components/modals/AddPoint/AddPointModal';
import ConfirmModal from 'components/modals/Confirm/ConfirmModal';
import CreateCollectionModal from 'components/modals/CreateCollection/CreateCollectionModal';
import ErrorModal from 'components/modals/Error/ErrorModal';
import InvitePointModal from 'components/modals/InvitePoint/InvitePointModal';
import InviteAverpointModal from 'components/modals/InviteAverpoint/InviteAverpointModal';
import PictureModal from 'components/modals/PictureModal/PictureModal';
import PointModal from 'components/modals/PointModal/PointModal';
import Publish from '../../components/modals/Publish/PublishModal';
import RespondInvitePointModal from '../../components/modals/RespondInvitePoint/RespondInvitePointModal';
import RespondInviteGroupModal from 'components/modals/RespondInviteGroup/RespondInviteGroupModal';
import RespondInviteCollectionModal from '../../components/modals/RespondInviteCollection/RespondInviteCollectionModal';
import SuggestRevisionModal from '../../components/modals/SuggestRevision/SuggestRevisionModal';
import Share from '../../components/modals/Share/Share';
import SuccessModal from '../../components/modals/Success/SuccessModal';
import WalkthroughModal from '../../components/modals/WalkthroughModal';
import VerticalModal from 'components/modals/VerticalModal/VerticalModal';
import CallToActionModal from '../../components/modals/CallToAction/CallToActionModal';
import MediaCollectionModal from '../../components/modals/MediaCollectionModal/MediaCollectionModal';
import CollectionSelectGroupsModal from '../../components/modals/CollectionSelectGroups/CollectionSelectGroupsModal';
import WelcomeModal from '../../components/modals/WelcomeModal/WelcomeModal';
import LogReadingModal from '../../components/modals/LogReadingModal/LogReadingModal';
import BadgeDetails from '../../components/modals/BadgeDetails/BadgeDetails';
import Badges from '../../components/modals/Badges/Badges';
import CurateArticleModal from '../../components/modals/CurateArticleModal/CurateArticleModal';
import RequestSourceModal from '../../components/modals/RequestSourceModal/RequestSourceModal';
import ProgressBarNextSteps from 'components/modals/ProgressBarNextSteps/ProgressBarNextSteps';
import VerifySubscriptionRequest from '../../components/modals/VerifySubscriptionRequest/VerifySubscriptionRequest';
import VerifySubscription from '../../components/modals/VerifySubscription/VerifySubscription';
import ClubhouseSignup from '../../components/modals/ClubhouseSignup/ClubhouseSignup';
import FlagCommunityPickedArticle from '../../components/modals/FlagCommunityPickedArticle/FlagCommunityPickedArticle';
import DeleteCommunityPickedArticle from '../../components/modals/DeleteCommunityPickedArticle/DeleteCommunityPickedArticle';
import ReclassifyCommunityPickedArticle from '../../components/modals/ReclassifyCommunityPickedArticle/ReclassifyCommunityPickedArticle';
import InviteStudents from '../../components/modals/InviteStudents/InviteStudents';
import ClassroomReadingQuestions from '../../components/modals/ClassroomReadingQuestions/ClassroomReadingQuestions';
import ManageCourse from '../../components/modals/ManageCourse/ManageCourse';
import ManageTopic from 'components/modals/ManageTopic/ManageTopic';
import ManageGoals from 'components/modals/ManageGoals/ManageGoals';
import ManageReadings from 'components/modals/ManageReadings/ManageReadings';
import ManageQuizQuestions from 'components/modals/ManageQuizQuestions/ManageQuizQuestions';
import ManageWritingPrompts from '../../components/modals/ManageWritingPrompts/ManageWritingPrompts';
import ManageWritingPromptPoints from '../../components/modals/ManageWritingPromptPoints/ManageWritingPromptPoints';
import UpdateSourceDetails from 'components/modals/UpdateSourceDetails/UpdateSourceDetails';
import UpdateArticleDetails from 'components/modals/UpdateArticleDetails/UpdateArticleDetails';
import DeleteConfirmationModal from 'components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import StudentCommentsOnSource from 'components/modals/StudentCommentsOnSource/StudentCommentsOnSource';
import DuplicatePointDetails from 'components/modals/DuplicatePointDetails/DuplicatePointDetails';
import RequestClassroomDemoModal from 'components/modals/RequestClassroomDemoModal/RequestClassroomDemoModal';
import SyncCourses from '../../components/modals/SyncCourses/SyncCourses';
import UpdateSourceFormat from 'components/modals/UpdateSourceFormat/UpdateSourceFormat';
import ManageClassroomGroup from 'components/modals/ManageClassroomGroup/ManageClassroomGroup';
import EditWritingPropertiesObjectives from 'components/modals/EditWritingPropertiesObjectives/EditWritingPropertiesObjectives';
import EditResearchPropertiesObjectives from 'components/modals/EditResearchPropertiesObjectives/EditResearchPropertiesObjectives';
import ManageResearchPrompts from 'components/modals/ManageResearchPrompts/ManageResearchPrompts';

function getModalComponent(modalName, key, props) {
  props.key = key;
  let opt = {
    props,
  };
  switch (modalName) {
    case 'add-picture':
      opt.component = AddPictureModal;
      props.onAddPicture = (actions, terms) => {
        return actions.addPicture(terms);
      };
      break;
    case 'add-point-modal':
      console.log(modalName, key, props, opt);
      opt.component = AddPointModal;
      break;
    case 'add-to-collection':
      opt.component = AddToCollectionModal;
      props.onAddPoint = (actions, pointId, collectionId) => {
        return actions.saveSubPointToPoint(pointId, collectionId);
      };
      props.onCreateCollection = (actions, collection) => {
        return actions.createCollection(collection);
      };
      break;
    case 'create-collection':
      opt.component = CreateCollectionModal;
      props.onCreateCollection = (
        actions,
        collection,
        additionalAuthors,
        isTopLevelCollection,
      ) => {
        return actions.createCollection(
          collection,
          additionalAuthors,
          isTopLevelCollection,
        );
      };
      props.lookupURL = (actions, url) => {
        return actions.lookupURL(url);
      };
      break;
    case 'confirm':
      opt.component = ConfirmModal;
      break;
    case 'error':
      opt.component = ErrorModal;
      break;
    case 'invite-averpoint':
      opt.component = InviteAverpointModal;
      props.onInvite = (actions, email) => {
        return actions.inviteAverpoint(email);
      };
      break;
    case 'clubhouse-signup':
      opt.component = ClubhouseSignup;
      break;
    case 'invite-point':
      opt.component = InvitePointModal;
      props.onInvite = (
        actions,
        point,
        authors,
        permissionLevel,
        inviteOption,
      ) => {
        return actions.invitePoint(
          point,
          authors,
          permissionLevel,
          inviteOption,
        );
      };
      break;
    case 'invite-group':
      opt.component = InvitePointModal;
      opt.props.model.type = 'GROUP';
      break;
    case 'full-picture':
      opt.component = PictureModal;
      break;
    case 'publish':
      opt.component = Publish;
      break;
    case 'Point':
      opt.component = PointModal;
      break;
    case 'suggest-revision':
      opt.component = SuggestRevisionModal;
      props.onSuggestFactRevision = (actions, factId, reason) => {
        return actions.suggestFactRevision(factId, reason);
      };
      break;

    case 'respond-invite-point':
      opt.component = RespondInvitePointModal;
      props.onRespondInvite = (actions, point, response) => {
        return actions.respondInvitePoint(point, response);
      };
      break;
    case 'respond-invite-collection':
      opt.component = RespondInviteCollectionModal;
      props.onRespondInvite = (actions, collectionPoint, response) => {
        return actions.respondInvitePoint(collectionPoint, response);
      };
      break;
    case 'respond-invite-group':
      opt.component = RespondInviteGroupModal;
      props.onRespondInvite = (actions, groupId, groupType, response) => {
        return actions.respondInviteGroup(groupId, groupType, response);
      };
      break;
    case 'Share':
      opt.component = Share;
      break;
    case 'success':
      opt.component = SuccessModal;
      break;
    case 'walkthrough-modal':
      opt.component = WalkthroughModal;
      break;
    case 'vertical-modal':
      opt.component = VerticalModal;
      break;
    case 'add-collection-to-group':
      opt.component = AddToGroup;
      break;
    case 'call-to-action':
      opt.component = CallToActionModal;
      props.onUpdate = (actions, point, type) => {
        if (type === 'POINT') {
          return actions.savePoint(point);
        } else if (type === 'COLLECTION') {
          return actions.updateCollection(point);
        }
      };
      break;
    case 'media-collection':
      opt.component = MediaCollectionModal;
      props.onUpdate = (actions, point) => {
        return actions.updateCollection(point);
      };
      props.lookupURL = (actions, url) => {
        return actions.lookupURL(url);
      };
      break;
    case 'collection-select-groups':
      opt.component = CollectionSelectGroupsModal;
      break;
    case 'welcome':
      opt.component = WelcomeModal;
      break;
    case 'progress-bar-next-steps':
      opt.component = ProgressBarNextSteps;
      break;
    case 'log-reading-help':
      opt.component = LogReadingModal;
      break;
    case 'badge-details':
      opt.component = BadgeDetails;
      break;
    case 'badges':
      opt.component = Badges;
      break;
    case 'curate-article':
      opt.component = CurateArticleModal;
      break;
    case 'request-source':
      opt.component = RequestSourceModal;
      break;
    case 'request-verify-subscription':
      opt.component = VerifySubscriptionRequest;
      break;
    case 'verify-subscription':
      opt.component = VerifySubscription;
      break;
    case 'flag-article':
      opt.component = FlagCommunityPickedArticle;
      break;
    case 'delete-article':
      opt.component = DeleteCommunityPickedArticle;
      break;
    case 'reclassify-article':
      opt.component = ReclassifyCommunityPickedArticle;
      break;
    case 'invite-students':
      opt.component = InviteStudents;
      break;
    case 'classroom-reading-questions':
      opt.component = ClassroomReadingQuestions;
      break;
    case 'manage-course':
      opt.component = ManageCourse;
      break;
    case 'manage-topic':
      opt.component = ManageTopic;
      break;
    case 'manage-goals':
      opt.component = ManageGoals;
      break;
    case 'manage-readings':
      opt.component = ManageReadings;
      break;
    case 'manage-quiz-questions':
      opt.component = ManageQuizQuestions;
      break;
    case 'manage-writing-prompts':
      opt.component = ManageWritingPrompts;
      break;
    case 'manage-writing-prompt-points':
      opt.component = ManageWritingPromptPoints;
      break;
    case 'update-source-details':
      opt.component = UpdateSourceDetails;
      break;
    case 'update-article-details':
      opt.component = UpdateArticleDetails;
      break;
    case 'delete-confirmation':
      opt.component = DeleteConfirmationModal;
      break;
    case 'student-comments-on-source':
      opt.component = StudentCommentsOnSource;
      break;
    case 'duplicate-point-details':
      opt.component = DuplicatePointDetails;
      break;
    case 'request-classroom-demo-modal':
      opt.component = RequestClassroomDemoModal;
      break;
    case 'sync-courses':
      opt.component = SyncCourses;
      break;
    case 'open-update-source-format':
      opt.component = UpdateSourceFormat;
      break;
    case 'manage-classroom-group':
      opt.component = ManageClassroomGroup;
      break;
    case 'edit-writing-properties-objective':
      opt.component = EditWritingPropertiesObjectives;
      break;
    case 'manage-research-prompts':
      opt.component = ManageResearchPrompts;
      break;
    case 'edit-research-properties-objective':
      opt.component = EditResearchPropertiesObjectives;
      break;
    default:
      console.log('Modal not found');
  }

  return opt;
}

export default function modal(state = [], action) {
  switch (action.type) {
    case Actions.OPEN_MODAL:
      return [
        ...state,
        getModalComponent(action.data.modalName, Date.now(), action.data.props),
      ];
      break;
    case Actions.HIDE_MODAL:
      let key = action.data.key;
      let modal = state.filter((i) => {
        return i.props.key === key;
      })[0];

      if (modal && modal.props && modal.props.onResponse) {
        modal.props.onResponse(action.data.response);
      }

      return state.filter((i) => {
        return i.props.key !== key;
      });
      break;
    default:
      return state;
  }
}

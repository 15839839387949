import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useReducer,
} from 'react';
import classnames from 'classnames';

import QuestionText from './QuestionText';
import PointContainer from 'components/cards/Point/PointContainer';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import AddPoint from 'components/modals/AddPoint/AddPoint';
import { Icon, ICONS } from 'components/Icons';

function SelectPointsQuestion(
  {
    question,
    onChange,
    model,
    point,
    points,
    onContinue,
    user,
    onAction,
    isMock,
    collectionPoint,
    isExtensionCL,
  },
  ref,
) {
  const addPointModel = {
    collectionId: point._id,
    showStance: true,
    hideImportFromPortfolio: false,
    stance: question.conditionType === 'OPPOSING' ? 'Against' : 'For',
    isProvidingEvidence: true,
    isExtensionCL,
  };

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_PROVIDE_EVIDENCE':
          return {
            ...state,
            showProvideEvidence: action.payload,
          };
        case 'SET_SUB_POINTS':
          return {
            ...state,
            subPoints: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showProvideEvidence: false,
      subPoints: [],
    },
  );

  useEffect(() => {
    let subPoints;

    if (question.conditionType === 'SUPPORTING') {
      subPoints = point.subPoints.filter(
        (i) => !i.stance || i.stance === 'For',
      );
    } else if (question.conditionType === 'OPPOSING') {
      subPoints = point.subPoints.filter((i) => i.stance === 'Against');
    } else {
      subPoints = point.subPoints;
    }

    subPoints = subPoints.filter((subPoint) => {
      const pointObject = points[subPoint.pointId];
      return pointObject && pointObject.point;
    });

    dispatch({ type: 'SET_SUB_POINTS', payload: subPoints });
  }, [point]);

  useEffect(() => {
    onAction && onAction('SURVEY_SELECT_POINTS_QUESTIONS', true);
    return () => {
      onAction && onAction('SURVEY_SELECT_POINTS_QUESTIONS', false);
    };
  }, []);

  useEffect(() => {
    onAction &&
      onAction(
        'SURVEY_SELECT_POINTS_QUESTIONS_PROVIDE_EVIDENCE',
        state.showProvideEvidence,
      );
  }, [state.showProvideEvidence]);

  useEffect(() => {
    dispatch({
      type: 'SHOW_PROVIDE_EVIDENCE',
      payload: !state.subPoints.length,
    });
  }, [state.subPoints.length]);

  useImperativeHandle(ref, () => ({
    allowContinue: () => {
      const value = getValue();
      return !question.required || value.length;
    },
  }));

  function getValue() {
    return model[question.variable] || [];
  }

  function isPointSelected(pointId) {
    const value = getValue();
    return value.indexOf(pointId) !== -1;
  }

  function onToggleSelect(pointIds) {
    let value = [...getValue()];

    pointIds.forEach((pointId) => {
      const isSelected = isPointSelected(pointId);
      if (isSelected) {
        const index = value.indexOf(point._id);
        // value = [...value.slice(0, index), ...value.slice(index + 1)];
        value.splice(index, 1);
      } else {
        // value = [...value, pointId];
        value.push(pointId);
      }
    });

    onChange(value, question.variable);
  }

  function onPointAdded(_, point) {
    if (point) {
      // in case of import point, it will be Array
      if (point instanceof Array) {
        onToggleSelect(point.map((i) => i._id));
      } else {
        onToggleSelect([point._id]);
      }

      onContinue();
      return;
    }

    dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: false });
  }

  function renderAddPoint() {
    if (!state.showProvideEvidence) {
      return null;
    }

    return (
      <div className="position-relative">
        {state.subPoints.length ? (
          <button
            className="btn btn-clear btn-close gray-2 ml-auto p-0"
            onClick={() =>
              dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: false })
            }
            style={{ right: 4, top: 4 }}
          >
            <Icon icon={ICONS.CLOSE} className="mr-0" />
          </button>
        ) : null}
        <AddPoint
          model={addPointModel}
          type="LIGHTWEIGHT"
          onClose={onPointAdded}
          placeholder="Start typing or paste a link to your evidence"
          mediaClaim={point}
          isMock={isMock}
          collectionPoint={collectionPoint}
          submitText="Save"
        />
        <div className="gray-3 font-weight-bold pb-3 text-right">
          <small>
            <span className="gray-4">Enter</span> to add a line,{' '}
            <span className="gray-4">Shift + Enter</span> to save
          </small>
        </div>
      </div>
    );
  }

  function renderProvideEvidenceButton() {
    if (!question.allowAddPoint || state.showProvideEvidence) {
      return null;
    }

    // return (
    //   <div className="ml-2 d-inline">
    //     or
    //     <a
    //       className="ml-2 mr-2 btn-provide-evidence"
    //       onClick={() =>
    //         dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: true })
    //       }
    //     >
    //       add your own Point.
    //     </a>
    //   </div>
    // );

    return (
      <RectangularButton
        className="mt-1 mb-3 w-100"
        onClick={() =>
          dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: true })
        }
      >
        Add my own evidence
      </RectangularButton>
    );
  }

  function renderText() {
    if (state.subPoints.length) {
      return (
        <div className="mb-2 mr-4">
          <QuestionText question={question} />
          {/* {renderProvideEvidenceButton()} */}
        </div>
      );
    } else {
      if (question.conditionType === 'OPPOSING') {
        return (
          <div className="mb-2 mr-4 av-blue">
            Please add a Point that <strong>opposes</strong> this claim.
          </div>
        );
      } else {
        return (
          <div className="mb-2 mr-4 av-blue">
            Please add a Point that <strong>supports</strong> this claim.
          </div>
        );
      }
    }
  }

  function renderToggleButton(point) {
    const isSelected = isPointSelected(point._id);
    const text = isSelected ? 'Unselect' : 'Select';
    const className = classnames('toggle-btn', { active: isSelected });
    return (
      <RectangularButton
        className={className}
        onClick={() => onToggleSelect([point._id])}
      >
        {text}
      </RectangularButton>
    );
  }

  function renderPoint(point, subPoint) {
    return (
      <PointContainer
        point={point}
        subPointInfo={subPoint}
        user={user}
        cardType="top-evidence"
        key={point._id}
        onPointDetailView={() => {}}
        hideHeader={true}
      />
    );
  }

  function renderPoints() {
    if (!state.subPoints.length) {
      // return (
      //   <div className="mt-3">
      //     Please add evidence to{' '}
      //     {question.conditionType === 'OPPOSING' ? 'disprove' : 'verify'} this
      //     claim.
      //   </div>
      // );
      return null;
    }

    const pointElms = state.subPoints.map((subPoint, index) => {
      const pointObject = points[subPoint.pointId];
      const className = classnames('position-relative', {
        'mb-2': index !== state.subPoints.length - 1,
      });
      return (
        <div className={className}>
          {renderToggleButton(pointObject.point)}
          {renderPoint(pointObject.point, subPoint)}
        </div>
      );
    });

    return <div>{pointElms}</div>;
  }

  // if (state.showProvideEvidence) {
  //   return (
  //     <div
  //       className="select-points-question clearfix"
  //       style={{ position: 'relative', zIndex: 1 }}
  //     >
  //       <div className="mb-2 mr-4 av-blue">
  //         {question.conditionType === 'OPPOSING'
  //           ? 'Please add a Point that opposes this claim.'
  //           : 'Please add a Point that supports this claim.'}
  //       </div>
  //       {renderAddPoint()}
  //     </div>
  //   );
  // } else {
  return (
    <div className="select-points-question">
      {renderText()}
      {renderProvideEvidenceButton()}
      {renderAddPoint()}
      {renderPoints()}
    </div>
  );
  // }
}

export default forwardRef(SelectPointsQuestion);

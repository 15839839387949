import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle, debounce } from 'throttle-debounce';
import classnames from 'classnames';

import { Icon, ICONS } from 'components/Icons';

import './SearchItems.scss';

class SearchItems extends Component {
  static propTypes = {
    initialSearchTerm: PropTypes.string,
    placeholder: PropTypes.string,
    searchOnTyping: PropTypes.func,
    searchOnEnter: PropTypes.func,
    onClose: PropTypes.func,
    showCloseOnBlankQuery: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.initialSearchTerm || '',
      searches: [],
    };
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.initialSearchTerm !== prevProps.initialSearchTerm) {
      this.setState({
        searchTerm: this.props.initialSearchTerm,
      });
    }
  }

  autocompleteSearch = searchTerm => {
    // Cache the search
    const { searches } = this.state;
    searches.push(searchTerm);
    this.props.searchOnTyping(searchTerm);
  };

  searchOnTyping = searchTerm => {
    if (searchTerm.length < 1) {
      console.log(`Throttled search for: ${searchTerm}`);
      this.autocompleteSearchThrottled(searchTerm);
    } else {
      console.log(`Debounced search for: ${searchTerm}`);
      this.autocompleteSearchDebounced(searchTerm);
    }
  };

  onChange = event => {
    this.setState({ searchTerm: event.target.value }, () => {
      const { searchTerm } = this.state;

      if (this.props.searchOnTyping) this.searchOnTyping(searchTerm);
    });
  };

  onKeyPress = e => {
    console.log('key pressed and code is ' + e.key);

    if (!this.props.searchOnEnter) return;

    if (e.key !== 'Enter') return;

    //this.autocompleteSearchDebounced(this.state.searchTerm);
    this.props.searchOnEnter(this.state.searchTerm);
  };

  render() {
    const { showCloseOnBlankQuery, className } = this.props;
    const containerClassName = classnames('search-items p-3', className);
    return (
      <div className={containerClassName}>
        <Icon icon={ICONS.SEARCH} />
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.state.searchTerm}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          className="flex-fill"
        />
        {this.state.searchTerm || showCloseOnBlankQuery ? (
          <span className="clear-search-icon" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </span>
        ) : (
          undefined
        )}
      </div>
    );
  }

  onClose = () => {
    this.setState({ searchTerm: '' }, () => {
      // if(this.props.searchOnTyping) {
      //   this.props.searchOnTyping('');
      // }
      // if(this.props.searchOnEnter) {
      //   this.props.searchOnEnter('');
      // }
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
}

export default SearchItems;

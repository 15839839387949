// import React from 'react';
import sanitizeHtml from "sanitize-html";

export const Validator = () => ({
  TEXTAREA_INPUT: {
    MIN: 5,
    MAX: 200,
  },
  TEXTAREA_INPUT_1000: {
    MIN: 0,
    MAX: 1000,
  },
  ARTICLE_HEADLINE: {
    MIN: 5,
    MAX: 200,
  },
  ARTICLE_TEXT: {
    MIN: 5,
    MAX: 1000000,
  },
  TAG_TEXT: {
    MIN: 2,
    MAX: 50,
  },
  ARTICLE_PROOFPOINT_REQUEST: {
    MIN: 5,
    MAX: 1000,
  },
  ARTICLE_QUESTION: {
    MIN: 5,
    MAX: 1000,
  },
  ARTICLE_COMMENT: {
    MIN: 5,
    MAX: 1000,
  },
  PROOFPOINT_TEXT: {
    MIN: 10,
    MAX: 300,
  },
  PROOFPOINT_SOURCE: {
    MIN: 1,
    MAX: 200,
  },
  PROOFPOINT_SOURCE_URL: {
    MIN: 10,
    MAX: 200,
  },
  PROOFPOINT_SOURCE_TITLE: {
    MIN: 10,
    MAX: 200,
  },
  PROOFPOINT_SOURCE_AUTHOR: {
    MIN: 3,
    MAX: 200,
  },
  PROOFPOINT_SOURCE_DATE: {
    MIN: 10,
    MAX: 200,
  },
  PROOFPOINT_SOURCE_TEXT: {
    MIN: 0,
    MAX: 500,
  },
  PROOFPOINT_SOURCE_NOTES: {
    MIN: 5,
    MAX: 500,
  },
  PROOFPOINT_SOURCE_LOCATION: {
    MIN: 0,
    MAX: 200,
  },
  VERSION_NOTES: {
    MIN: 5,
    MAX: 500,
  },
  REVIEW_NOTES: {
    MIN: 5,
    MAX: 500,
  },
  validateArticle(plainArticleText, article, articleVersion, errorMessages) {
    //validate the headline
    var errorMessage = this.validateText(
      articleVersion.headline,
      this.ARTICLE_HEADLINE,
      "The article's headline"
    );
    errorMessages.articleHeadline = errorMessage;

    //validate the article text
    var errorMessage = this.validateText(
      plainArticleText,
      this.ARTICLE_TEXT,
      "The article's text"
    );
    errorMessages.articleText = errorMessage;

    //validate the tags
    for (var i = 0; i < article.tags.length; i++) {
      var errorMessage = this.validateText(
        article.tags[i].text,
        this.TAG_TEXT,
        "The tag " + article.tags[i].text + " "
      );
      errorMessages.tags = errorMessage;
      if (errorMessage != "") break;
    }

    //validate the picture
    if (articleVersion.previewPhotoApproved) {
      if (
        !articleVersion.previewPhotoCredit ||
        articleVersion.previewPhotoCredit.length <= 0
      ) {
        var errorMessage = "You must credit your photo to a source.";
        errorMessages.previewPhotoCredit = errorMessage;
      } else {
        errorMessages.previewPhotoCredit = "";
      }
      if (
        !articleVersion.previewPhotoLicense ||
        articleVersion.previewPhotoLicense.length <= 0
      ) {
        var errorMessage = "You must indicate your photo license.";
        errorMessages.previewPhotoLicense = errorMessage;
      } else {
        errorMessages.previewPhotoLicense = "";
      }

      if (
        articleVersion.previewPhotoLicense != "No copyright" &&
        (!articleVersion.previewPhotoURL ||
          articleVersion.previewPhotoURL.length <= 0)
      ) {
        var errorMessage =
          "For a " +
          articleVersion.previewPhotoLicense +
          " license, you must provide a link to your original picture.";
        errorMessages.previewPhotoURL = errorMessage;
      } else {
        errorMessages.previewPhotoURL = "";
      }
    }

    if (articleVersion.headline == "") {
      errorMessages.articleErrorMessage = "Your article must have a headline.";
    } else if (errorMessages.articleHeadline != "") {
      errorMessages.articleErrorMessage =
        "Please fix the error with your article's headline.";
    } else if (articleVersion.text == "") {
      errorMessages.articleErrorMessage = "Your article must have text.";
    } else if (errorMessages.articleText != "") {
      errorMessages.articleErrorMessage =
        "Please fix the error with your article's text.";
    } else if (errorMessages.tags != "") {
      errorMessages.articleErrorMessage =
        "Please fix the error with your article's tags";
    } else if (articleVersion.previewPhotoApproved) {
      if (
        errorMessages.previewPhotoCredit != "" ||
        errorMessages.previewPhotoLicense != "" ||
        errorMessages.previewPhotoURL != ""
      ) {
        errorMessages.articleErrorMessage =
          "Please fix the error with your article's preview photo";
      } else {
        errorMessages.articleErrorMessage = "";
      }
    } else {
      errorMessages.articleErrorMessage = "";
    }
    return errorMessages;
  },
  validatePublishArticle(articleVersion, errorMessages) {
    if (articleVersion.notes == "") {
      errorMessages.publishErrorMessage = "Your version must include notes.";
    } else if (errorMessages.versionNotes != "") {
      errorMessages.publishErrorMessage =
        "Please fix the error with your version notes.";
    } else {
      errorMessages.publishErrorMessage = "";
    }
    return errorMessages;
  },
  validateProofPoint(proofPoint, errorMessages) {
    if (proofPoint.status != "new" && proofPoint.status != "private") {
      console.log(
        "Trying to validate a ProofPoint but status is " + proofPoint.status
      );
      errorMessages.proofPointErrorMessage =
        "Trying to save a ProofPoint but looks it has already been submitted.";
    } else if (proofPoint.text == "") {
      errorMessages.proofPointText = "ProofPoint text is required.";
      errorMessages.proofPointErrorMessage =
        "You must enter the main text for your ProofPoint. This is what readers see when they are reading your article.";
    } else if (errorMessages.proofPointText != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with your ProofPoint text.";
    } else if (proofPoint.writerText == "") {
      errorMessages.writerText =
        "You haven't highlighted text in the main article.";
      errorMessages.proofPointErrorMessage =
        "You must highlight text in the main article. This is what connects your evidence to your argument.";
    } else if (errorMessages.writerText != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the text you highlighted in the main article.";
    } else if (!proofPoint.range) {
      errorMessages.range =
        "Your ProofPoint looks disconnected from the main text - it's missing a range.";
      errorMessages.proofPointErrorMessage =
        "It looks like your ProofPoint is not connected to the main text.";
    } else if (proofPoint.source == "") {
      errorMessages.source =
        "You haven't entered a source for your ProofPoint.";
      errorMessages.proofPointErrorMessage = "You must enter a source.";
    } else if (errorMessages.source != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with your source.";
    } else if (proofPoint.sourceTitle == "") {
      errorMessages.sourceTitle =
        "You haven't entered a title for your source article.";
      errorMessages.proofPointErrorMessage =
        "You must enter a title for your source article.";
    } else if (errorMessages.sourceTitle != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the title for your source.";
    } else if (proofPoint.sourceURL == "") {
      errorMessages.sourceURL = "You haven't entered a URL for your source.";
      errorMessages.proofPointErrorMessage =
        "You must enter a link to your source so reviewers can easily verify your ProofPoint.";
    } else if (errorMessages.sourceURL != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the link for your source.";
    } else if (errorMessages.sourceText != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the text you're citing from your source.";
    } else if (errorMessages.sourceAuthor != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the author for your source.";
    } else if (errorMessages.sourceDate != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the date for your source.";
    } else if (errorMessages.sourceNotes != "") {
      errorMessages.proofPointErrorMessage =
        "You have an error with the notes for your source.";
    } else {
      errorMessages.proofPointErrorMessage = "";
    }

    return errorMessages;
  },
  validateText(text, limitJSON, initialText) {
    if (text.length < limitJSON.MIN) {
      return (
        initialText + " must be at least " + limitJSON.MIN + " characters."
      );
    } else if (text.length > limitJSON.MAX) {
      return (
        initialText + " must be less than " + limitJSON.MAX + " characters."
      );
    } else {
      return "";
    }
  },
  validateEmpty(text) {
    if (text.length === 0) {
      return "Cannot be empty.";
    }
  },

  validatePassword(text) {
    if (text.length === 0) {
      return "Cannot be empty.";
    }

    if (text.length < 8) {
      return "Password cannot be less then 8 characters";
    }
  },

  // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
  // credit to Elian Ebbing on Stack Overflow
  validateDate: function (dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
      return "Article date must be in MM/DD/YYYY format (e.g., Independence Day is 07/04/1776)";

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 0 || year > 3000 || month == 0 || month > 12)
      return "The source date year or month is wrong.";

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    if (day < 0 || day > monthLength[month - 1]) {
      return "The day looks wrong. Please check.";
    }

    return "";
  },
  validateURL: function (URL) {
    return "";
  },

  validateEmail(value) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(value);

    if (!isValid) {
      return "Please Enter a valid Email Address";
    }
  },
  sanitizeUserInput(text) {
    //This is the default way to sanitize user input
    //Some fields will sanitize with customer filters (e.g., allow HTML tags)
    var cleanText = sanitizeHtml(text);
    return cleanText;
  },
  sanitizeRegularText(text) {
    let cleanHTML = sanitizeHtml(text, {
      allowedTags: [],
      allowedAttributes: [],
    });

    var clean = this.getRegularText(cleanHTML);
    return clean;
  },
  getRegularText: function (htmlText) {
    var regExForQuote = /&quot;/gi;
    let regularText = htmlText.replace(regExForQuote, '"');
    return regularText;
  },
  sanitizeArticleHTML(articleHTML) {
    //console.log("In sanitizeArticleHTML and articleHTML is "+articleHTML);

    var cleanHTML = sanitizeHtml(articleHTML, {
      allowedTags: [
        "h3",
        "h4",
        "h5",
        "h6",
        "blockquote",
        "p",
        "a",
        "ul",
        "ol",
        "nl",
        "li",
        "b",
        "i",
        "u",
        "center",
        "strong",
        "em",
        "strike",
        "code",
        "hr",
        "br",
        "div",
        "table",
        "thead",
        "caption",
        "tbody",
        "tr",
        "th",
        "td",
        "pre",
        "span",
        "img",
        "iframe",
      ],
      allowedAttributes: {
        a: ["href", "name", "target"],
        // We don't currently allow img itself by default, but this
        // would make sense if we did
        img: ["src"],
        span: ["style", "id"],
        iframe: ["frameborder", "allowfullscreen", "src"],
      },
      allowedClasses: {
        span: ["has-proof", "highlight"],
        iframe: ["ql-video"],
        p: ["ql-center"],
      },
      selfClosing: [
        "img",
        "br",
        "hr",
        "area",
        "base",
        "basefont",
        "input",
        "link",
        "meta",
      ],
    }); //need to sanitize this with special rules to allow HTML

    //console.log("In sanitizeArticleHTML and cleanHTML is "+cleanHTML);
    var cleanerHTML = cleanHTML;
    //console.log("In sanitizeArticleHTML and cleanerHTML is "+cleanerHTML);
    //var colorTag = '<span style="color: rgb(0, 0, 0);">';
    //var backgroundTag = '<span style="background-color: rgb(228, 228, 228);">';
    //var backgroundTagOld = '<span style="background-color: rgb(232, 237, 223);">';
    //var highlightFragmentBackgroundTag = '<span style="background-color: rgb(179, 205, 224);">';

    //console.log("In sanitizeArticleHTML and cleanerHTML is "+cleanerHTML);

    //cleanerHTML = this.stripTag(cleanerHTML, colorTag);
    //cleanerHTML = this.stripTag(cleanerHTML, backgroundTag);
    //cleanerHTML = this.stripTag(cleanerHTML, backgroundTagOld);
    //cleanerHTML = this.stripTag(cleanerHTML, highlightFragmentBackgroundTag);
    var colorRegex = /<span style=(\\"|\")color:.*>/;
    var backgroundRegex = /<span style=(\\"|\")background-color:.*>/;

    cleanerHTML = this.stripTag(cleanerHTML, colorRegex);
    cleanerHTML = this.stripTag(cleanerHTML, backgroundRegex);

    return cleanerHTML;
  },
  insertProofPointSpanTags: function (htmlText, proofPoint) {
    //console.log("insertProofPointSpanTags: got called with proofPoint "+JSON.stringify(proofPoint));
    if (
      !proofPoint.range ||
      proofPoint.range.start < 0 ||
      proofPoint.range.end < 0
    ) {
      //console.log("insertProofPointSpanTags: didn't pass range test and range is "+JSON.stringify(proofPoint.range));
      return;
    }

    var newHtmlText = htmlText;
    var newHtmlIndex = 0;
    var textIndex = 0;
    var buildingTag = false;
    var buildingHtmlCharacter = false;

    //console.log("insertProofPointSpanTags: start of for loop: "+newHtmlText);
    //interate through html text
    for (
      var htmlTextIndex = 0;
      htmlTextIndex < htmlText.length;
      htmlTextIndex++
    ) {
      var letter = htmlText[htmlTextIndex];
      //console.log("insertProofPointSpanTags: letter: "+letter+" html index: "+htmlTextIndex+" text index: "+textIndex);

      if (buildingTag) {
        if (letter == ">") {
          //console.log("insertProofPointSpanTags: closing a tag ");
          buildingTag = false;
        }
      } else if (buildingHtmlCharacter) {
        if (letter == ";") {
          //console.log("insertProofPointSpanTags: closing an html special character ");
          buildingHtmlCharacter = false;
        }
      } else if (letter == "<") {
        //console.log("insertProofPointSpanTags: starting a tag ");
        buildingTag = true;
        var testForBR = htmlText.slice(htmlTextIndex, htmlTextIndex + 3);
        if (testForBR == "<br") {
          //Quill converts breaks into 2 carriage returns
          //If we don't adjust the textIndex by 2, the ProofPoint will start 2 characters
          //After it is intended to.
          textIndex += 2;
        }
      } else if (letter == "&") {
        //console.log("insertProofPointSpanTags: starting a special character");
        buildingHtmlCharacter = true;
      } else {
        //letter is regular text
        //console.log("insertProofPointSpanTags: letter is regular text");
        if (proofPoint.range.start == textIndex) {
          console.log(
            "insertProofPointSpanTags: letter is start of ProofPoint range: " +
              proofPoint.range.start
          );
          //insert the <span class="has-proof" id=....>
          var firstPart = "";
          if (newHtmlIndex > 0)
            firstPart = newHtmlText.substring(0, newHtmlIndex);
          var secondPart = newHtmlText.substring(
            newHtmlIndex,
            newHtmlText.length
          );
          //var spanTag = '<span class="has-proof" id='+proofPoint._id._str+'>';
          var spanTag =
            '<span class="has-proof" id="' + proofPoint._id._str + '">';
          newHtmlText = firstPart + spanTag + secondPart;
          newHtmlIndex += spanTag.length;
        } else if (proofPoint.range.end == textIndex) {
          //insert the close </span>
          //console.log("insertProofPointSpanTags: letter is end of ProofPoint range: "+proofPoint.range.end);
          var firstPart = "";
          if (newHtmlIndex > 0)
            firstPart = newHtmlText.substring(0, newHtmlIndex);
          var secondPart = newHtmlText.substring(
            newHtmlIndex,
            newHtmlText.length
          );
          var spanTag = "</span>";
          newHtmlText = firstPart + spanTag + secondPart;
          newHtmlIndex += spanTag.length;
        }
        textIndex++;
      }
      newHtmlIndex++;
    }
    //console.log("insertProofPointSpanTags: end of for loop: "+newHtmlText);
    return newHtmlText;
  },
  stripTag(text, tagRegex) {
    //console.log("In stripTag and text is "+text);

    if (!text) return;

    /*  
replacing strings
  while(true){
      var nextTagIndex = text.indexOf(tag);
      //console.log("In stringTag and nextTagIndex is "+nextTagIndex);
      if(nextTagIndex == -1) break;

      text = text.replace(tag,"");

      var firstPartOfString = text.substring(0, nextTagIndex);
      var restOfString = text.substring(nextTagIndex, text.length);
      restOfString = restOfString.replace("</span>","");
      text = firstPartOfString + restOfString;
    }*/
    //console.log("In stripTag and looking for "+tagRegex);
    //console.log("Looking in text: "+text);

    while (true) {
      var nextTagIndex = text.search(tagRegex);
      //console.log("In stripTag and nextTagIndex is "+nextTagIndex);
      if (nextTagIndex == -1) break;

      var nextTagEndIndex = text.indexOf(">", nextTagIndex);

      var firstPartOfString = text.substring(0, nextTagIndex);
      var restOfString = text.substring(nextTagEndIndex + 1, text.length);
      restOfString = restOfString.replace("</span>", "");
      text = firstPartOfString + restOfString;
    }
    return text;
  },
  sanitizeArticle(article) {
    //called when saving article
    article.headline = this.sanitizeRegularText(article.headline);
    //console.log("sanitizeArticle: The article text before save is.... "+article.text);
    article.text = this.sanitizeArticleHTML(article.text);
    //console.log("sanitizeArticle: The article text after save is.... "+article.text);
    for (var i = 0; i < article.tags.length; i++) {
      article.tags[i].text = this.sanitizeRegularText(article.tags[i].text);
    }
    for (var i = 0; i < article.factNotes.length; i++) {
      article.factNotes[i].text = this.sanitizeRegularText(
        article.factNotes[i].text
      );
      //sanitizeRestOfProofPoint
    }
    return article;
  },
  sanitizeArticleVersion(articleVersion) {
    //called when saving article
    articleVersion.headline = this.sanitizeRegularText(articleVersion.headline);
    //console.log("sanitizeArticleVersion: The article version text before save is.... "+articleVersion.text);
    articleVersion.text = this.sanitizeArticleHTML(articleVersion.text);
    //console.log("sanitizeArticleVersion: The article version text after save is.... "+articleVersion.text);
    articleVersion.notes = this.sanitizeRegularText(articleVersion.notes);
    return articleVersion;
  },
  sanitizeProofPoint(proofPoint) {
    //called when saving proof point
    proofPoint.text = this.sanitizeRegularText(proofPoint.text);
    proofPoint.writerText = this.sanitizeRegularText(proofPoint.writerText);
    proofPoint.source = this.sanitizeRegularText(proofPoint.source);
    proofPoint.sourceURL = this.sanitizeRegularText(proofPoint.sourceURL);
    proofPoint.sourceTitle = this.sanitizeRegularText(proofPoint.sourceTitle);
    proofPoint.sourceText = this.sanitizeRegularText(proofPoint.sourceText);
    proofPoint.sourceAuthor = this.sanitizeRegularText(proofPoint.sourceAuthor);
    proofPoint.sourceDate = this.sanitizeRegularText(proofPoint.sourceDate);
    proofPoint.sourceNotes = this.sanitizeRegularText(proofPoint.sourceNotes);
    return proofPoint;
  },
  render() {
    return null;
  },
});

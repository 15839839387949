import * as Actions from '../actions/index';

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'APP_VARIABLES':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useReducer, Fragment } from 'react';
import classnames from 'classnames';

import './PointSelectText.scss';

export default function PointSelectText({
  captions,
  onChange,
  selectedCaptions,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_MOUSE_DOWN':
          return {
            ...state,
            isMouseDown: true,
            mouseDownPosition: action.payload,
          };
        case 'SET_START_POSITION':
          return {
            ...state,
            startPosition: action.payload,
          };
        case 'SET_END_POSITION':
          return {
            ...state,
            endPosition: action.payload,
          };
        case 'SET_MOUSE_UP':
          return {
            ...state,
            isMouseDown: false,
          };
        default:
          return state;
      }
    },
    {
      isMouseDown: false,
      mouseDownPosition: '',
      startPosition: {
        x: 0,
        y: 0,
        index: 0,
      },
      endPosition: {
        x: 0,
        y: 0,
        index: 0,
      },
    },
  );

  useEffect(() => {
    function onMouseUp() {
      dispatch({ type: 'SET_MOUSE_UP' });
    }

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (selectedCaptions && selectedCaptions.length) {
      const caption = selectedCaptions[0];
      const captionIndex = captions.findIndex(i => i.start === caption.start);
      const position = getPositionByIndex(captionIndex);
      dispatch({
        type: 'SET_START_POSITION',
        payload: {
          x: position.x,
          y: position.y,
          index: captionIndex,
        },
      });
    }

    if (selectedCaptions && selectedCaptions.length) {
      const caption = selectedCaptions[selectedCaptions.length - 1];
      const captionIndex = captions.findIndex(i => i.start === caption.start);
      let { x, y } = getPositionByIndex(captionIndex + 1);
      dispatch({
        type: 'SET_END_POSITION',
        payload: {
          x,
          y,
          index: captionIndex,
        },
      });
    } else {
      let dummy = document.getElementById('point-select-text-dummy');
      let x = dummy.offsetLeft;
      let y = dummy.offsetTop;

      dispatch({
        type: 'SET_END_POSITION',
        payload: {
          x,
          y,
          index: captions.length - 1,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      state.startPosition.index === 0 &&
      state.endPosition.index + 1 === captions.length
    ) {
      onChange();
    } else {
      onChange(
        captions.slice(state.startPosition.index, state.endPosition.index + 1),
      );
    }
  }, [state.startPosition.index, state.endPosition.index]);

  function getPositionByIndex(index) {
    const captionElm =
      index > captions.length - 1
        ? document.getElementById('point-select-text-dummy')
        : document.querySelector(`span[caption-index="${index}"]`);
    return {
      x: captionElm.offsetLeft - 3,
      y: captionElm.offsetTop,
    };
  }

  function onMouseDown(event, position) {
    event.stopPropagation();
    event.preventDefault();
    dispatch({ type: 'SET_MOUSE_DOWN', payload: position });
  }

  function onMouseMove(event) {
    if (state.isMouseDown && event.target === event.currentTarget) {
      const captionIndex = parseInt(
        event.target.getAttribute('caption-index'),
        10,
      );

      if (state.mouseDownPosition === 'start') {
        if (captionIndex > state.endPosition.index) {
          return;
        }
        dispatch({
          type: 'SET_START_POSITION',
          payload: {
            x: event.target.offsetLeft - 3,
            y: event.target.offsetTop,
            index: captionIndex,
          },
        });
      } else if (state.mouseDownPosition === 'end') {
        if (captionIndex < state.startPosition.index) {
          return;
        }

        let x, y;

        if (captionIndex === captions.length - 1) {
          let dummy = document.getElementById('point-select-text-dummy');
          x = dummy.offsetLeft - 3;
          y = dummy.offsetTop;
        } else {
          x = event.target.nextSibling.offsetLeft - 3;
          y = event.target.nextSibling.offsetTop;
        }

        dispatch({
          type: 'SET_END_POSITION',
          payload: {
            x,
            y,
            index: captionIndex,
          },
        });
      }
    }
  }

  function renderMarker(style, position) {
    return (
      <div
        className="marker"
        onMouseDown={e => onMouseDown(e, position)}
        style={style}
      >
        <div className="round" />
      </div>
    );
  }

  function renderSelectionStart() {
    const style = {
      left: state.startPosition.x + 'px',
      top: state.startPosition.y + 'px',
    };
    return renderMarker(style, 'start');
  }

  function renderSelectionEnd() {
    const style = {
      left: state.endPosition.x + 'px',
      top: state.endPosition.y + 'px',
    };
    return renderMarker(style, 'end');
  }

  function renderText() {
    const captionElms = captions.map((caption, index) => {
      const className = classnames('caption', {
        selected:
          state.startPosition.index <= index &&
          state.endPosition.index >= index,
      });
      return (
        <span
          onMouseMove={onMouseMove}
          className={className}
          key={index}
          caption-index={index}
        >
          {caption.text + ' '}
        </span>
      );
    });
    return (
      <Fragment>
        {captionElms}
        <span id="point-select-text-dummy" />
      </Fragment>
    );
  }

  return (
    <div className="point-select-text position-relative">
      {renderText()}
      {renderSelectionStart()}
      {renderSelectionEnd()}
    </div>
  );
}

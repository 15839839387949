import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import './ProgressBarNextSteps.scss';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

function ProgressBarNextSteps({ step, onClose, history, message, options }) {
  console.log('In ProgressBarNextSteps and message and options:');
  console.log(message);
  console.log(options);
  function renderTitle() {
    return <div className="title">Your Next Steps</div>;
  }

  function renderDescription() {
    return (
      <div className="description">
        {message}
        {/* Nice work Old Sport! You're now activated as a Level 1 Community Member. We'll analyze your news reading and send you weekly updates. Remember, you can log your articles 3 ways: */}
      </div>
    );
  }

  function renderOption(option, index) {
    return (
      <div className="option">
        <ActionLink
          label={option.text}
          key={index}
          onClick={() => {
            onClose();
            history.push(option.link);
          }}
        />
      </div>
    );
  }

  function renderOptions() {
    // return step.options.map(renderOption);
    const optionsToDisplay = options.map((option, index) => (
      <li key={'option ' + index}>{option.text}</li>
    ));
    return (
      <ul className="mt-4">
        {optionsToDisplay}
        {/* <li>
          The browser extension will analyze news sites in the background if you gave us permission.
        </li>
        <li>
          Email your articles to save@averpoint.com.
        </li>
        <li>
          Share articles from your news apps to the AverPoint app.
        </li> */}
      </ul>
    );
  }

  return (
    <Modal show className="progress-bar-next-steps-modal">
      <Modal.Body>
        {renderTitle()}
        <Button bsStyle="link" className="btn-close" onClick={onClose}>
          <Icon icon={ICONS.CLOSE} />
        </Button>
        {renderDescription()}
        {renderOptions()}
        <div className="mt-4 text-center">
          <RectangularButton onClick={onClose}>Sounds good!</RectangularButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProgressBarNextSteps),
);

import _ from 'lodash';
import * as Actions from '../actions/index';

const initialState = {
  topics: [],
  topicKeywords: [],
};

export default function helps(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_ADMIN_TOPICS:
      return {
        ...state,
        topics: action.topics,
      };
    case Actions.LOAD_TOPIC_KEYWORDS:
      return {
        ...state,
        topicKeywords: action.topicKeywords,
      };
    case Actions.UPDATE_TOPIC_KEYWORDS: {
      let topicsClone = _.cloneDeep(state.topicKeywords);

      const topic = topicsClone.find((i) => i._id === action.topicId);

      if (!topic) {
        topicsClone = [...topicsClone, { ...action.topic }];
      } else {
        topic.keywords = action.topic.keywords;
      }

      return {
        ...state,
        topicKeywords: topicsClone,
      };
    }
    default:
      return state;
  }
}

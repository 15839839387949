import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import CognitoPool from '../config/cognitoPool';
import { CognitoStatusCodes } from '../config/cognitoStatusCodes';
import Cookies from 'js-cookie';

const isProduction = process.env.NODE_ENV === 'production';

class AWSCognito {
  login(username, password) {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData,
      );
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const accessToken = result.getIdToken().getJwtToken();

          setCookies(result);

          resolve(
            new AWSCognitoResponse(CognitoStatusCodes.LOGIN_SUCCESSFUL, {
              accessToken,
            }),
          );
        },
        onFailure: (error) => {
          if (error && error.code === 'PasswordResetRequiredException') {
            return resolve(
              new AWSCognitoResponse(
                CognitoStatusCodes.MIGRATED_USER_RESET_REQUIRED,
              ),
            );
          }
          reject(new AWSCognitoError(CognitoStatusCodes.LOGIN_FAILED, error));
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve(
            new AWSCognitoResponse(CognitoStatusCodes.RESET_PASSWORD, {
              requiredAttributes,
            }),
          );
        },
      });
    });
  }

  signUp(username, email, password, id = null) {
    return new Promise((resolve, reject) => {
      const userPool = AWSCognitoUserRepo.getUserPool(CognitoPool);

      const attributeList = [];
      const dataEmail = {
        Name: 'email',
        Value: email,
      };
      const attributeEmail = new CognitoUserAttribute(dataEmail);
      attributeList.push(attributeEmail);
      if (id) {
        const dataId = {
          Name: 'custom:user_id',
          Value: id,
        };
        const attributeId = new CognitoUserAttribute(dataId);
        attributeList.push(attributeId);
      }

      userPool.signUp(
        username,
        password,
        attributeList,
        null,
        (error, result) => {
          if (error) {
            return reject(
              new AWSCognitoError(CognitoStatusCodes.SIGNUP_FAILED, error),
            );
          }
          return resolve(
            new AWSCognitoResponse(CognitoStatusCodes.SIGNUP_SUCCESSFUL, {
              cognitoUser: result.user,
              codeDeliveryDetails: result.codeDeliveryDetails,
            }),
          );
        },
      );
    });
  }

  update(username, id) {
    return new Promise((resolve, reject) => {
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      const attributeList = [];

      const attribute = {
        Name: 'custom:user_id',
        Value: id,
      };
      const attributeId = new CognitoUserAttribute(attribute);

      attributeList.push(attributeId);

      cognitoUser.updateAttributes(attributeList, (error, result) => {
        if (error) {
          reject(new AWSCognitoError(CognitoStatusCodes.UPDATE_FAILED, error));
        }
        return resolve(
          new AWSCognitoResponse(CognitoStatusCodes.UPDATE_SUCCESSFUL),
        );
      });
    });
  }

  verifyCode(username, code) {
    return new Promise((resolve, reject) => {
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      cognitoUser.confirmRegistration(code, true, (error, result) => {
        if (error) {
          return reject(
            new AWSCognitoError(
              CognitoStatusCodes.VERIFICATION_CODE_FAILED,
              error,
            ),
          );
        }
        return resolve(
          new AWSCognitoResponse(
            CognitoStatusCodes.VERIFICATION_CODE_SUCCESSFUL,
          ),
        );
      });
    });
  }

  resetPassword(username, userAttributes, newPassword) {
    return new Promise((resolve, reject) => {
      console.log('userAttributes', userAttributes);
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      delete userAttributes.email_verified;

      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (result) => {
          console.log('result', result);
          return resolve(
            new AWSCognitoResponse(
              CognitoStatusCodes.RESET_PASSWORD_SUCCESSFUL,
            ),
          );
        },
        onFailure: (error) => {
          return reject(
            new AWSCognitoError(
              CognitoStatusCodes.RESET_PASSWORD_FAILED,
              error,
            ),
          );
        },
      });
    });
  }

  logout(username) {
    try {
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      cognitoUser.signOut();

      removeCookies();
    } catch (error) {
      console.log('[Logout] Failed to logout', error);
    }
  }

  forgotPassword(username) {
    return new Promise((resolve, reject) => {
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          console.log('[Forgot Password Success]', result);
          resolve(
            new AWSCognitoResponse(
              CognitoStatusCodes.FORGOT_PASSWORD_SUCCESSFUL,
              {
                result,
              },
            ),
          );
        },
        onFailure: (error) => {
          reject(
            new AWSCognitoError(
              CognitoStatusCodes.FORGOT_PASSWORD_FAILED,
              error,
            ),
          );
        },
      });
    });
  }

  changePassword(username, code, newPassword) {
    return new Promise((resolve, reject) => {
      const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          console.log('[Change Password Success]', result);
          resolve(
            new AWSCognitoResponse(
              CognitoStatusCodes.CHANGE_PASSWORD_SUCCESSFUL,
            ),
          );
        },
        onFailure: (error) => {
          reject(
            new AWSCognitoError(
              CognitoStatusCodes.CHANGE_PASSWORD_FAILED,
              error,
            ),
          );
        },
      });
    });
  }

  getAccessToken(username = '') {
    return new Promise((resolve, reject) => {
      let cognitoUser;
      if (username) {
        cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);
      } else {
        const cognitoUserPool = AWSCognitoUserRepo.getUserPool();

        cognitoUser = cognitoUserPool.getCurrentUser();

        if (!cognitoUser) {
          return reject(
            new AWSCognitoError(CognitoStatusCodes.GET_SESSION_FAILED),
          );
        }
      }

      cognitoUser.getSession((error, session) => {
        if (error) {
          console.error('Failed to get session', error);
          return reject(
            new AWSCognitoError(CognitoStatusCodes.GET_SESSION_FAILED, error),
          );
        }
        if (session.isValid()) {
          setCookies(session);

          return resolve(
            new AWSCognitoResponse(
              CognitoStatusCodes.GET_SESSION_SUCCESSFUL,
              session.idToken.jwtToken,
            ),
          );
        }

        const refreshToken = session.refreshToken.token;

        cognitoUser.refreshSession(refreshToken, (error, session) => {
          if (error) {
            console.error('Failed to refresh session', error);
            return reject(
              new AWSCognitoError(CognitoStatusCodes.GET_SESSION_FAILED, error),
            );
          }
          setCookies(session);

          return resolve(
            new AWSCognitoResponse(
              CognitoStatusCodes.GET_SESSION_SUCCESSFUL,
              session.idToken.jwtToken,
            ),
          );
        });
      });
    });
  }

  createSession(username, tokens) {
    const cognitoUser = AWSCognitoUserRepo.getCognitoUser(username);

    const sessionTokens = {
      AccessToken: new CognitoAccessToken(tokens.AccessToken),
      RefreshToken: new CognitoRefreshToken(tokens.RefreshToken),
      IdToken: new CognitoIdToken(tokens.IdToken),
    };

    const session = new CognitoUserSession(sessionTokens);

    cognitoUser.setSignInUserSession(session);
  }
}

/**
 * Setting Cognito Tokens in the cookies for Chrome Extension
 * */

function setCookies(session) {
  Cookies.set('averpoint.user.token', session.idToken.jwtToken, {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
  Cookies.set('averpoint.user.accessToken', session.accessToken.jwtToken, {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
  Cookies.set('averpoint.user.refreshToken', session.refreshToken.token, {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
}

function removeCookies() {
  Cookies.remove('averpoint.user.token', {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
  Cookies.remove('averpoint.user.accessToken', {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
  Cookies.remove('averpoint.user.refreshToken', {
    sameSite: 'None',
    secure: true,
    path: '/',
    domain: isProduction ? '.averpoint.com' : undefined,
    expires: 3650,
  });
}

export class AWSCognitoUserRepo {
  static repo = {};
  static CognitoPool = CognitoPool;

  static getUserPool() {
    return new CognitoUserPool(this.CognitoPool);
  }

  static getCognitoUser(username) {
    if (this.repo[username]) {
      return this.repo[username];
    }

    const userData = {
      Username: username,
      Pool: this.getUserPool(),
    };

    this.repo[username] = new CognitoUser(userData);

    return this.repo[username];
  }
}

export class AWSCognitoResponse {
  constructor(code, data) {
    this.code = code;
    this.data = data;
  }
}

export class AWSCognitoError {
  constructor(code, error) {
    this.code = code;
    this.error = error;
  }
}

export const AWSCognitoInstance = new AWSCognito();

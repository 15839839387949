import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import ReviewForm from "./ReviewForm/ReviewForm";
import MessageThread from "./MessageThread/MessageThread";
import "./ReviewDetails.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "redux/actions/index";

import ToolTip from "components/elements/atoms/ToolTip/ToolTip.js";
import { Icon, ICONS } from "components/Icons";

var initialState = {
  userReview: "",
  editable: true,
  message: "",
  neutralLanguage: false,
  verifiedInSource: false,
  balancedLanguage: false,
  factsSupportClaims: false,
  authorResponse: "",
  existingReviewId: "",
  errorMessage: "",
};

class ReviewDetails extends Component {
  static propTypes = {
    displayReview: PropTypes.object,
    actions: PropTypes.object,
    user: PropTypes.object,
    point: PropTypes.object,
    pointObject: PropTypes.object,
    isChallenge: PropTypes.bool,
    onSubmitFactReview: PropTypes.func,
    onSubmitOpinionReview: PropTypes.func,
    openAddPointModal: PropTypes.func,
    handleReviewModal: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { point, displayReview } = this.props;
    let { userReview } = this.state;

    const existingReview = this.props.displayReview;
    if (existingReview) {
      if (existingReview.type == "Opinion") {
        this.setState({
          factsSupportClaims: existingReview.opinionReview.factual,
          balancedLanguage: existingReview.opinionReview.balanced,
          userReview: existingReview.opinionReview.agree,
          existingReviewId: existingReview._id,
          messageThreadId: existingReview.messageThreadId,
          editable: false,
        });
      } else if (existingReview.type == "Fact") {
        this.setState({
          userReview: existingReview.factReview.userReview,
          neutralLanguage: existingReview.factReview.neutralLanguage,
          verifiedInSource: existingReview.factReview.verifiedInSource,
          existingReviewId: existingReview._id,
          messageThreadId: existingReview.messageThreadId,
          editable: false,
        });
      }
    }
    if (
      this.props.displayReview &&
      this.props.displayReview.messageThreadId &&
      !this.props.displayReview.messageThread
    ) {
      //If there is a message thread, but it's not loaded in state - load it
      this.props.actions.getMessageThread(
        this.props.point._id,
        this.props.displayReview._id,
        this.props.displayReview.messageThreadId
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.displayReview) {
      //If the nextProps are set - and they changed from the old one -> load the review and change the state to read only
      if (
        !prevProps.displayReview ||
        prevProps.displayReview._id != this.props.displayReview._id
      ) {
        let review = this.props.displayReview;

        if (review.type == "Opinion") {
          this.setState({
            factsSupportClaims: review.opinionReview.factual,
            balancedLanguage: review.opinionReview.balanced,
            userReview: review.opinionReview.agree,
            existingReviewId: review._id,
            messageThreadId: review.messageThreadId,
            editable: false,
          });
        } else if (review.type == "Fact") {
          this.setState({
            userReview: review.factReview.userReview,
            neutralLanguage: review.factReview.neutralLanguage,
            verifiedInSource: review.factReview.verifiedInSource,
            existingReviewId: review._id,
            messageThreadId: review.messageThreadId,
            editable: false,
          });
        }
      }
    } else {
      //If the newProps are null, the user can edit in initial state
      if (prevProps.displayReview) this.setState(initialState);
    }
  }

  handleReviewSubmitted = userReview => {
    //const userReview = e.target.id;
    if (!userReview) {
      console.log("Error in submit review");
      // console.log(e.target);
      this.setState({
        errorMessage: "Please try again. The review did not submit.",
      });
      return;
    }

    if (this.props.point.type == "Fact") {
      // Do conditional checks
      if (userReview == "verify") this.confirmVerify();
      else if (userReview == "reject") this.confirmReject();
    } else if (this.props.point.type == "Opinion") {
      console.log("The user review is " + userReview);
      this.props.onSubmitOpinionReview(
        this.props.point,
        userReview,
        this.state.balancedLanguage,
        this.state.factsSupportClaims
      );
      this.setState({
        reviewerId: this.props.user._id,
        editable: false,
      });
    }

    this.setState({
      userReview,
    });
  };

  confirmVerify = () => {
    // These checks were used before to make sure user looked at sources, and didn't go too fast.
    // if (!this.checkSourceClicked())
    //   return;

    // if (!this.checkTimeOfReview())
    //   return;

    let errorMessage = "";
    if (this.props.isChallenge && this.props.point.status == "verified") {
      errorMessage =
        "To challenge a verified Fact, you must submit a rejection.";
    } else if (!this.state.neutralLanguage) {
      //alert("To verify the Fact, you must confirm it is a neutral statement. Please check that box.");
      errorMessage =
        "To verify the Fact, you must confirm it is a neutral statement. Please check that box.";
    } else if (!this.state.verifiedInSource) {
      errorMessage =
        "To verify the Fact, you must confirm it can be verified in a reputable source. Please check that box.";
    }

    if (errorMessage == "") {
      this.props.onSubmitFactReview(
        this.props.point,
        "verify",
        this.state.neutralLanguage,
        this.state.verifiedInSource
      );
      this.setState({
        reviewerId: this.props.user._id,
        editable: false,
      });
    }

    this.setState({ errorMessage: errorMessage });
    //TODO - show error modal
    //        this.props.onReviewFact();
  };

  confirmReject = () => {
    // if (!this.checkSourceClicked())
    //   return;

    let errorMessage = "";
    if (this.props.isChallenge && this.props.point.status == "rejected") {
      errorMessage =
        "To challenge a verified Fact, you must submit a rejection.";
    } else if (this.state.neutralLanguage && this.state.verifiedInSource) {
      errorMessage =
        "You can't reject a Fact if it's a neutral statement and verified in reputable sources. Either verify it or change your selections.";
    }

    if (errorMessage) {
      this.setState({ errorMessage: errorMessage });
      return;
    }

    this.props.onSubmitFactReview(
      this.props.point,
      "reject",
      this.state.neutralLanguage,
      this.state.verifiedInSource
    );
    this.setState({
      reviewerId: this.props.user._id,
      editable: false,
    });
  };

  handleBalancedLanguage = () => {
    this.setState({
      balancedLanguage: !this.state.balancedLanguage,
    });
  };

  handleFactsSupportClaims = () => {
    this.setState({
      factsSupportClaims: !this.state.factsSupportClaims,
    });
  };
  handleNeutralLanguage = () => {
    this.setState({
      neutralLanguage: !this.state.neutralLanguage,
    });
  };

  handleVerifiedInSource = () => {
    this.setState({
      verifiedInSource: !this.state.verifiedInSource,
    });
  };

  onReviewChange = arrow => {
    const { reviews, point } = this.props;
    let { userReview, reviewerId } = this.state;
    let displayReview,
      displayReviewIndex = 0;

    if (arrow === "Up") {
      displayReviewIndex++;
      displayReview = reviews.filter(
        (review, index) => index === displayReviewIndex
      );
    }
    if (arrow === "Down") {
      displayReviewIndex--;
      displayReview = reviews.filter(
        (review, index) => index === displayReviewIndex
      );
    }
    if (displayReview.length) {
      userReview = displayReview[0].userReview;
      reviewerId = displayReview[0].reviewerId;
    }
    this.setState({
      userReview,
      reviewerId,
      displayReview: (displayReview.length && displayReview[0]) || {},
    });
  };

  onSubmitReview = () => {
    this.setState({
      reviewerId: this.props.user._id,
      editable: false,
    });
    if (this.props.point.type == "Fact") {
      this.props.onSubmitFactReview(
        this.props.point,
        this.state.userReview,
        this.state.neutralLanguage,
        this.state.verifiedInSource
      );
    }
    if (this.props.point.type == "Opinion") {
      this.props.onSubmitOpinionReview(
        this.props.point,
        this.state.userReview,
        this.state.balancedLanguage,
        this.state.factsSupportClaims
      );
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSend = () => {
    var { existingReviewId, message } = this.state;
    //let sendMessage = (message != "") ? message : text;
    //let sendProposedPointId = (proposedPointId)

    this.props.actions.sendMessage(
      existingReviewId,
      message,
      null, //addedPointId if there is one
      this.props.point._id
    );
    this.setState({
      message: "",
    });
  };

  onSendWithPoint = (text, proposedPointId) => {
    var { existingReviewId } = this.state;

    this.props.actions.sendMessage(
      existingReviewId,
      text,
      proposedPointId,
      this.props.point._id
    );
    this.setState({
      message: "",
    });
  };

  onToggleEditable = () => {
    this.setState({
      editable: !this.state.editable,
    });
  };

  renderEditOrSubmitMode = () => {
    //If user is not logged in, can't edit or submit a review
    if (!this.props.user) return;

    //If this is not a blank review - and the user is not looking at their own review, can't edit or submit
    if (
      this.props.displayReview != null &&
      this.props.user._id != this.props.displayReview.reviewerId
    )
      return;

    if (this.state.editable) {
      return;
      // return(
      //   <div>
      //     <div className="send-btn submit" onClick={this.onSubmitReview}>
      //       Submit
      //     </div>
      //   </div>
      // )
    } else {
      let toolTipText = "";
      let disabledText = "";
      let handler = "";
      if (this.props.user.score.level >= this.props.point.level) {
        toolTipText =
          "You can change your review - for example, if the author's language or evidence is more convincing, you might change from Reject to Verify.";
        handler = this.onToggleEditable;
      } else {
        toolTipText =
          "You can't change your review if the Point is at a higher level than you. The Point has escalated to level " +
          this.props.point.level +
          " and you are at level " +
          this.props.user.score.level;
        disabledText = "disabled";
        handler = null;
      }

      return (
        <div>
          <ToolTip toolTipText={toolTipText} toolTipPosition={"bottom"}>
            <div
              className={"send-btn submit " + disabledText}
              onClick={handler}
            >
              Change my review
            </div>
          </ToolTip>
        </div>
      );
    }
  };

  onAddPoint = e => {
    var point = this.props.point;
    var status = "under review"; //A Point offered through a reviewer has to be public

    let title = "Suggest evidence to " + point.authorName + '"s Point';

    this.props.openAddPointModal(
      point.subPoints,
      this.afterPointCreated,
      status,
      title,
      this.props.pointObject,
      this.saveSubPointToPoint
    );
  };

  afterPointCreated = (pointId, dispatch, stance) => {
    console.log("saving subpoint with stance " + stance);
    this.saveSubPointToPoint(pointId, stance);
    this.onSendWithPoint("Point Proposed", pointId);
  };

  saveSubPointToPoint = (subPointId, stance) => {
    this.props.actions.saveSubPointToPoint(
      subPointId,
      this.props.point._id,
      stance
    );
  };

  renderMessageThread = () => {
    if (!this.props.displayReview) return; //Don't show the messenger if the review is blank

    return (
      <MessageThread
        point={this.props.point}
        handleReviewSubmitted={this.handleReviewSubmitted}
        userReview={this.state.userReview}
        message={this.state.message}
        messageThread={this.props.displayReview.messageThread}
        onChange={this.onChange}
        onSend={this.onSend}
        onAddPoint={this.onAddPoint}
        user={this.props.user}
        displayReview={this.props.displayReview}
      />
    );
  };

  renderReviewTitle = () => {
    let title = "";
    if (
      !this.props.displayReview ||
      (this.props.user &&
        this.props.user._id === this.props.displayReview.reviewerId)
    )
      title = "My review of this " + this.props.point.type;
    else
      title =
        this.props.displayReview.reviewerUsername +
        "'s review of this " +
        this.props.point.type;

    let toolTipText;
    if (this.props.point.type == "Opinion") {
      toolTipText =
        "The author classified this Point as an Opinion. Reviewers evaluate if an opinion is expressed with balance and with sufficient supporting facts.";
    } else {
      toolTipText =
        "The author classified this Point as a Fact. Reviewers evaluate if the language is neutral, and if reputable sources verify the claims.";
    }

    return (
      <span className="head-title">
        {title}
        <span className="help-icon">
          <ToolTip toolTipText={toolTipText} toolTipPosition={"bottom"}>
            <Icon icon={ICONS.HELP} />
          </ToolTip>
        </span>
      </span>
    );
  };

  render() {
    const { user, point, isChallenge } = this.props;
    const {
      userReview,
      reviewerId,
      displayReview,
      message,
      neutralLanguage,
      messageThread,
    } = this.state;

    return (
      <div className="review-details">
        <div className="user-review">
          <div className="review-head">
            <span className="head-label">{this.renderReviewTitle()} </span>
            &nbsp;
            <span
              className="link pointer"
              onClick={() =>
                this.props.handleReviewModal("reviewSummary", null)
              }
            >
              See all
            </span>
          </div>
          <ReviewForm
            user={user}
            displayReview={displayReview}
            editable={this.state.editable}
            onReviewChange={this.onReviewChange}
            handleCheckbox={this.handleCheckbox}
            handleNeutralLanguage={this.handleNeutralLanguage}
            handleVerifiedInSource={this.handleVerifiedInSource}
            neutralLanguage={this.state.neutralLanguage}
            verifiedInSource={this.state.verifiedInSource}
            handleReviewSubmitted={this.handleReviewSubmitted}
            userReview={userReview}
            type={point.type}
            handleBalancedLanguage={this.handleBalancedLanguage}
            handleFactsSupportClaims={this.handleFactsSupportClaims}
            factsSupportClaims={this.state.factsSupportClaims}
            balancedLanguage={this.state.balancedLanguage}
            errorMessage={this.state.errorMessage}
            point={point}
            isChallenge={isChallenge}
          />
        </div>
        {this.renderEditOrSubmitMode()}
        {this.renderMessageThread()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openAddPointModal: (
      existingSubPoints,
      afterPointCreated,
      status,
      title,
      pointObject,
      onPointImported
    ) => {
      let props = {
        model: {
          collectionId: null,
          afterPointCreated,
          onPointImported,
          existingSubPoints,
          title,
          status,
          showAddToCollection: true, //Choose which Collection to add the new Point into
          showStance: true,
          pointObject: pointObject,
        },
      };
      dispatch(Actions.openModal("add-point-modal", props));
    },
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(ReviewDetails);

import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddPoint from 'components/modals/AddPoint/AddPoint';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from 'components/Icons';
import PointContainer from '../../PointContainer';
import * as Actions from 'redux/actions/index';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { Fragment } from 'react';

function EvidenceSection({
  point,
  points,
  user,
  actions,
  pointDetailView,
  onPointDetailView,
  showPointSelectText,
  selectedCaptions,
  onAction,
  isSelectionEnabled,
  collection,
  addingRTP,
  isMock,
  showProvideEvidence = false,
  isExtensionCL,
  priorityUsers,
  teacherMode,
}) {
  const model = {
    collectionId: point._id,
    showStance: true,
    hideImportFromPortfolio: false,
    stance: 'For',
    isProvidingEvidence: true,
    isExtensionCL,
  };
  const [firstRender, setFirstRender] = useState(true);

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_PROVIDE_EVIDENCE':
          return {
            ...state,
            showProvideEvidence: action.payload,
          };
        case 'TOGGLE_SHOW_EVIDENCE':
          return {
            ...state,
            showEvidence: !state.showEvidence,
          };
        default:
          return state;
      }
    },
    { showProvideEvidence: showProvideEvidence, showEvidence: true },
  );

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: false });
    }

    // load point
    if (point._id) {
      actions.getPoint(point._id);

      if (!point.rtps) {
        actions.getRTPsForPoint(point._id);
      }
    }
  }, [point._id]);

  useEffect(() => {
    showPointSelectText(state.showProvideEvidence);
    onAction && onAction(state.showProvideEvidence);
  }, [state.showProvideEvidence]);

  function onPointAdded(res) {
    dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: false });
  }

  // function renderProvideEvidenceButton() {
  //   if (state.showProvideEvidence) {
  //     return null;
  //   }

  //   const btn = (
  //     <RectangularButton
  //       className="light-blue w-100"
  //       iconName="EVIDENCE"
  //       iconSize={24}
  //       onClick={() => {
  //         dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: true });
  //       }}
  //       disabled={!user.user._id}
  //     >
  //       Provide Evidence
  //     </RectangularButton>
  //   );

  //   if (user.user._id) {
  //     return btn;
  //   } else {
  //     return (
  //       <ToolTip
  //         toolTipText="Please login to provide evidence"
  //         toolTipPosition="bottom"
  //         className="w-100"
  //       >
  //         {btn}
  //       </ToolTip>
  //     );
  //   }
  // }

  function renderAddEvidenceBtn() {
    if (addingRTP) {
      return null;
    }

    if (teacherMode) {
      return null;
    }

    let disabled = false;
    let hasReviewed = false;
    let isAuthor = false;

    if (point._id) {
      const rtps = points[point._id].rtps;

      if (!rtps || !rtps.length) {
        hasReviewed = false;
      } else {
        hasReviewed =
          rtps.findIndex((rtp) => rtp.userId === user.user._id) !== -1;
      }
    }

    if (collection && user.user._id) {
      // isAuthor =
      //   collection.authors.findIndex(i => i.userId === user.user._id) !== -1;
      if (points[collection._id]) {
        isAuthor = points[collection._id].isAuthor;
      }
    }

    if (!isAuthor && user.user._id && point._id) {
      // isAuthor =
      //   point.authors.findIndex(i => i.userId === user.user._id) !== -1;
      isAuthor = point.isAuthor;
    }

    if (!user.user._id) {
      disabled = true;
    }

    disabled = !hasReviewed && !isAuthor;

    return (
      <ActionLink
        linkClassName="ml-auto"
        iconName="ADD"
        iconType="averPointIcon"
        iconClassName="av-blue mr-3"
        onClick={() => {
          dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: true });
        }}
        disabled={disabled}
        disabledToolTipText="You must review this Point before you can add evidence."
      >
        Add Evidence
      </ActionLink>
    );
  }

  function renderHeader() {
    return (
      <div className="d-flex align-items-center">
        <ActionLink
          linkClassName=""
          iconName={
            state.showEvidence ? 'ARROW_DOWN_ROUND' : 'ARROW_RIGHT_ROUND'
          }
          iconType="averPointIcon"
          iconClassName="av-blue"
          iconSize={24}
          onClick={() => {
            dispatch({ type: 'TOGGLE_SHOW_EVIDENCE' });
          }}
        />
        <div className="font-weight-700 av-blue ml-3 font-size-12">
          EVIDENCE
        </div>
        <div className="ml-4 sub-points-count">{[point.totalSubPoints]}</div>
        {renderAddEvidenceBtn()}
      </div>
    );
  }

  function renderNoEvidence() {
    if (state.showProvideEvidence || point.subPoints.length) {
      return null;
    }

    return (
      <div className="d-flex align-items-center justify-content-center mt-4 pt-4 mb-4 pb-4">
        <Icon icon={ICONS.EVIDENCE} size={29} className="av-light-blue" />
        <div className="gray-3 ml-3 font-weight-700">
          This Point has no evidence
        </div>
      </div>
    );
  }

  function renderAddPoint() {
    return (
      <div className="mt-4 position-relative">
        <div className="font-weight-700 av-blue ml-3 font-size-12">
          ADD EVIDENCE
        </div>
        <button
          className="btn btn-clear btn-close gray-2 ml-auto p-0 position-absolute bg-av-white"
          onClick={() =>
            dispatch({ type: 'SHOW_PROVIDE_EVIDENCE', payload: false })
          }
          style={{ right: 10, top: 0 }}
        >
          <Icon icon={ICONS.CLOSE} className="mr-0" />
        </button>
        <AddPoint
          model={model}
          type="LIGHTWEIGHT"
          onClose={onPointAdded}
          placeholder="Start typing or paste a link to your evidence"
          mediaClaim={point}
          selectedCaptions={selectedCaptions}
          isMock={isMock}
          collectionPoint={collection}
        />
        <div className="gray-3 font-weight-bold mt-3 d-flex justify-content-end">
          <small>
            <span className="gray-4">Enter</span> to add a line,{' '}
            <span className="gray-4">Shift + Enter</span> to save
          </small>
        </div>
      </div>
    );
  }

  function renderPoints() {
    if (!state.showEvidence) {
      return null;
    }

    let subPoints = point.subPoints;

    if (priorityUsers && priorityUsers.length) {
      subPoints = subPoints.sort((i) =>
        priorityUsers.map((i) => i.userId).indexOf(i.postedBy) === -1 ? 1 : -1,
      );
    }

    return subPoints.map((subPoint) => {
      const pointObject = points[subPoint.pointId];

      if (!pointObject || !pointObject.point || !pointObject.point._id) {
        return null;
      }

      return (
        <PointContainer
          point={pointObject.point}
          user={user}
          cardType="top-evidence"
          key={subPoint.pointId}
          className="mt-3"
          subPointInfo={subPoint}
          // pointDetailView={pointDetailView}
          onPointDetailView={onPointDetailView}
          isSelectionEnabled={isSelectionEnabled}
          isMock={isMock}
        />
      );
    });
  }

  function renderContent() {
    if (state.showProvideEvidence) {
      return renderAddPoint();
    } else {
      return (
        <Fragment>
          {/* {renderProvideEvidenceButton()} */}
          {renderHeader()}
          {renderNoEvidence()}
          {renderPoints()}
        </Fragment>
      );
    }
  }

  return <div className="evidence-tab">{renderContent()}</div>;
}

function mapStateToProps(state) {
  return {
    points: state.points,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceSection);

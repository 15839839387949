import * as React from "react";
require("./css/portfoliocard.scss");

export default class PortfolioCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let portfolio = this.props.Portfolio;
    return (
      <div className="portfolio-card">
        <div className="header">{portfolio.username}'s portfolio</div>
        <div className="portfolio-card-container">
          <div className="user-profile">
            <section>
              <div className="description">
                Portfolio includes {portfolio.collectionPoints.length} collections
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

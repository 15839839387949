/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './CloudinaryImage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { Image, Transformation } from 'cloudinary-react';
import { Icon, ICONS } from 'components/Icons';
const config = require('config/config.public.js');

class CloudinaryImage extends React.Component {
  static propTypes = {
    cloudinaryImageId: PropTypes.string,
    cloudinaryVersion: PropTypes.number,
    imageClass: PropTypes.string,
    style: PropTypes.object,
    allowUserToChangePic: PropTypes.bool,
    handleClick: PropTypes.func,
    onLicenseTermsAccepted: PropTypes.func,
    defaultImage: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    face: PropTypes.bool,
    cropType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const { placeholderImage } = this.props;

    if (placeholderImage) {
      return (
        <img
          alt="placeholder"
          src={placeholderImage}
          className={`${this.props.imageClass} cld-responsive`}
          style={this.props.style}
          width={this.props.width}
          height={this.props.height}
          onClick={this.onClick}
        />
      );
    }
    const transformations = [];

    if (this.props.width && this.props.face) {
      transformations.push(
        <div
          width={this.props.width}
          height={this.props.height}
          gravity="face"
          radius="max"
          crop={this.props.cropType}
          key="transform-face"
        />,
      );
    } else {
      transformations.push(
        <Transformation
          width={this.props.width}
          height={this.props.height}
          crop={this.props.cropType}
          key="transform-size"
        />,
      );
    }

    const imageCldImage = (
      <Image
        dpr="auto"
        responsive
        width="auto"
        cloudName={config.cloudinary.cloud_name}
        version={this.props.cloudinaryVersion}
        publicId={this.props.cloudinaryImageId}
        className={`${this.props.imageClass} cld-responsive`}
        style={this.props.style}
        defaultImage={this.props.defaultImage}
        onClick={this.onClick}
      >
        {transformations}
      </Image>
    );

    const cldWrapper = this.props.allowUserToChangePic ? (
      <div className="position-relative">
        {imageCldImage}

        <div
          role="button"
          className="position-absolute image-overlay"
          onClick={this.onClick}
          tabIndex={0}
        />
        <div
          role="button"
          tabIndex={-1}
          onClick={this.onClick}
          className="position-absolute pencil-wrapper"
        >
          <Icon className="profile-pencil-icon" icon={ICONS.PENCIL} />
        </div>
      </div>
    ) : (
        imageCldImage
      );

    return <React.Fragment>{cldWrapper}</React.Fragment>;
  }

  onClick = (e) => {
    if (this.props.allowUserToChangePic) {
      // this.picChange();
      //this.props.photoOwnerId == this.props.user._id

      this.props.dispatch({
        type: 'SET_CLOUDINARY',
        imageId: this.props.cloudinaryImageId,
        isOpen: true,
        onLicenseTermsAccepted: this.props.onLicenseTermsAccepted,
      });
      return;
    }

    if (!this.props.handleClick) return;

    return this.props.handleClick(e);
  }
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;

  return {
    authenticated,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloudinaryImage);

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import { toast } from 'components/elements/molecules/Toast/Toast';
import LinkToGoogle from './LinkToGoogle';

import './SyncCourses.scss';
import { hasAppLinked } from './helper';
import { EXTERNAL_APP_CONFIG } from './constants';
import LinkToSchoology from '../../../routes/User/LinkToSchoology/LinkToSchoology';

const getSteps = (app) => {
  const externalAppConfig = EXTERNAL_APP_CONFIG[app];

  return [
    { value: 0, name: `${externalAppConfig.providerName} To AverPoint` },
    { value: 1, name: `AverPoint To ${externalAppConfig.providerName}` },
  ];
}

function Steps({ step, app }) {
  return <div className="steps-container">
    {
      getSteps(app).map((stepValue) => (
        <div className="step-container">
          <div
            key={stepValue.value}
            className={`step ${stepValue.value === step ? 'active' : 'inactive'}`}
          >
            {stepValue.value + 1}
          </div>
          <div className={`step-name ${stepValue.value === step ? 'active' : 'inactive'}`}>
            {stepValue.name}
          </div>
        </div>
      ))
    }
  </div>;
}

function SyncCourses({ app, user, refetchCourses, onClose, actions }) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [addedCourses, setAddedCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState([]);
  const [unlinkedCourses, setUnlinkedCourses] = useState([]);
  const [syncStatus, setSyncStatus] = useState({
    isLoading: false,
    status: '',
    response: '',
  });
  const externalAppConfig = EXTERNAL_APP_CONFIG[app];

  const hasLinkedWithApp = hasAppLinked(app, user);

  useEffect(() => {
    if (hasLinkedWithApp) {
      setLoading(true);

      actions
        .compareCourseChanges(app)
        .then((response) => {
          setAddedCourses(response.newCourses);
          setUnlinkedCourses(response.unLinkedCourses);
          setUpdatedCourses(response.updatedCourses);
        })
        .catch((error) => {
          console.log(error);
          toast('Error occurred while syncing courses. Please try again.', {
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [hasLinkedWithApp]);

  const syncCoursesToAverPoint = () => {
    setSyncStatus({
      isLoading: true,
    });

    actions
      .syncCoursesToAverPoint(addedCourses, updatedCourses, app)
      .then(() => {
        try {
          refetchCourses();
        } catch (error) {
          console.error('Error while refreshing courses', error);
        }

        setSyncStatus({
          isLoading: false,
          status: 'success',
          response: `We successfully synched from ${externalAppConfig.appName} to AverPoint.`,
        });
      })
      .catch((error) => {
        console.log(error);

        setSyncStatus({
          isLoading: false,
          status: 'error',
          response: `We were unable to synch from ${externalAppConfig.appName} to AverPoint. Please email feedback@averpoint.com for help.`,
        });
      });
  };

  const syncCoursesToExternalApp = () => {
    setSyncStatus({
      isLoading: true,
    });

    actions
      .syncCoursesToExternalApp(unlinkedCourses, app)
      .then(() => {
        setSyncStatus({
          isLoading: false,
          status: 'success',
          response: `We successfully synched from AverPoint to ${externalAppConfig.appName}.`,
        });
      })
      .catch((error) => {
        console.log(error);

        setSyncStatus({
          isLoading: false,
          status: 'error',
          response: `We were unable to synch from AverPoint to ${externalAppConfig.appName}. Please email feedback@averpoint.com for help.`,
        });
      })
  };

  const showLoader = () => (
    <div
      className="fa fa-spin fa-spinner text-primary"
      style={{ fontSize: '40px' }}
    />
  );

  const syncChanges = () => {
    switch (step) {
      case 0:
        syncCoursesToAverPoint();
        break;
      case 1:
        syncCoursesToExternalApp();
        break;
    }
  };

  const checkIsDisabled = () => {
    switch (step) {
      case 0:
        return !addedCourses.length && !updatedCourses.length;
      case 1:
        return !unlinkedCourses.length;
    }
  };

  const showSkipButton = () => {
    if (syncStatus.status) {
      return null;
    }

    const clickHandler = () => {
      switch (step) {
        case 0:
          setStep((prevStep) => prevStep + 1);
          break;
        case 1:
          onClose();
          break;
      }
    };

    return (
      <RectangularButton
        className="mr-4"
        onClick={clickHandler}
        lightButton
      >
        Skip
      </RectangularButton>
    );
  };

  const showSyncButton = () => {
    if (syncStatus.isLoading) {
      return showLoader();
    }

    const clickHandler = () => {
      if (step === 1 && syncStatus.status) {
        return onClose();
      }

      if (step === 0 && syncStatus.status) {
        setSyncStatus({
          isLoading: false,
          status: '',
          response: '',
        });
        return setStep((prevStep) => prevStep + 1);
      }

      return syncChanges();
    };

    const showLabel = () => {
      if (step === 1 && syncStatus.status) {
        return 'Finish';
      }

      if (step === 0 && syncStatus.status) {
        return 'Continue';
      }

      return 'Sync Now';
    };

    return (
      <RectangularButton
        onClick={() => clickHandler()}
        disabled={checkIsDisabled()}
        darkButton
      >
        {showLabel()}
      </RectangularButton>
    );
  };

  const showActions = () => {
    return (
      <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
        {showSkipButton()}
        {showSyncButton()}
      </div>
    );
  };

  const showTitle = () => {
    if (syncStatus.status === 'success') {
      return <span className="av-green">{syncStatus.response}</span>;
    }

    if (syncStatus.status === 'error') {
      return <span className="av-green">{syncStatus.response}</span>;
    }

    if (!loading) {
      return 'Here\'s what we found. Should we sync?';
    }

    switch (step) {
      case 0:
        return `First, we\'re comparing ${externalAppConfig.appName} to AverPoint and looking for any new and changed courses.`;
      case 1:
        return `Next, we\'re comparing AverPoint to ${externalAppConfig.appName}, and looking for anything new or updated.`;
    }
  };

  const changedGoogleCourses = () => {
    if (!addedCourses.length && !updatedCourses.length) {
      return (
        <div>
          <div className="text-center py-1 no-course-message">
            There are no new/updated courses.
          </div>
        </div>
      );
    }

    if (syncStatus.status) {
      return null;
    }

    return (
      <ul>
        {
          addedCourses.map((course) => <li>{course.name}<span className="ml-2 course-status green">New</span></li>)
        }
        {
          updatedCourses.map((course) => <li>{course.name}<span className="ml-2 course-status yellow">Updated</span></li>)
        }
      </ul>
    );
  };

  const changedAverPointCourses = () => {
    if (!unlinkedCourses.length) {
      return (
        <div>
          <div className="text-center py-1 no-course-message">
            There are no new/updated courses.
          </div>
        </div>
      );
    }

    if (syncStatus.status) {
      return null;
    }

    return (
      <ul>
        {
          unlinkedCourses.map((course) => (
            <li>
              {course.name}
              <span className={`ml-2 course-status ${course.unLinkedTopics ? 'yellow' : 'green'}`}>
                {course.unLinkedTopics ? 'Updated' : 'New'}
              </span>
            </li>
          ))
        }
      </ul>
    );
  };

  const showChanges = () => {
    switch (step) {
      case 0:
        return changedGoogleCourses();
      case 1:
        return changedAverPointCourses();
    }
  };

  const showSyncSteps = () => (
    <React.Fragment>
      <Steps step={step} app={app} />
      <div className="mb-4">
        {showTitle()}
      </div>
      {
        loading
          ? <div className="text-center mt-4">{showLoader()}</div>
          : showChanges()
      }
      {showActions()}
    </React.Fragment>
  );

  const showLinkToApp = () => {
    switch (app) {
      case 'google':
        return <div>
          <span>You need to link to {externalAppConfig.providerName} Account to sync courses.</span>
          <LinkToGoogle />
        </div>;
      case 'canvas':
        return <div>
          Please contact <span className="av-blue">feedback@averpoint.com</span> to set up a Canvas account.
        </div>;
      case 'schoology':
        return <div>
          <span>You need to link to {externalAppConfig.providerName} Account to sync courses.</span>
          <LinkToSchoology text="Connect to Schoology Account" />
        </div>;
      default:
        return <div>
          We have support for Google Classroom and Canvas LMS for now. Please contact{' '}
          <span className="av-blue">feedback@averpoint.com</span> if you want to add support for other LMS.
        </div>;
    }
  };

  return (
    <Modal show={true} className="sync-courses-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Sync Courses with {externalAppConfig.providerName}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {
          hasLinkedWithApp ? showSyncSteps() : showLinkToApp()
        }
      </Modal.Body>
    </Modal>
  );
}


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncCourses);

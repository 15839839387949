import * as React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ProfileImage from 'components/elements/molecules/Images/profileImage';

export default class SupportersOpponentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRows(users) {
    let arr = [];

    arr = users.map(user => {
      return (
        <div className="item clearfix">
          <div className="col-sm-4">
            <ProfileImage
              userId={user.userId}
              imageClass="profile-pic"
              username={user.username}
            />
          </div>
          <div className="col-sm-8">
            <h4>
              <strong>{user.userName}</strong>
            </h4>
          </div>
        </div>
      );
    });

    return arr;
  }

  render() {
    let supporters = this.props.supporters;
    let opponents = this.props.opponents;

    return (
      <Tabs>
        <Tab eventKey={1} title={'Supporters (' + supporters.length + ')'}>
          {this.renderRows(supporters)}
        </Tab>
        <Tab eventKey={2} title={'Opposers (' + opponents.length + ')'}>
          {this.renderRows(opponents)}
        </Tab>
      </Tabs>
    );
  }
}

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import { Icon, ICONS } from '../../Icons';

import './RequestSourceModal.scss';

function RequestSourceModal({ onClose, actions, refetch }) {
  const [source, setSource] = useState('');
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);

  const requestSource = () => {
    setLoading(true);
    actions
      .submitSourceRequest({
        source,
        domain,
      })
      .then(result => {
        console.log('Response of request source', result);
        setLoading(false);
        onClose();
      })
      .catch(error => {
        console.error('Error while requesting source', error);
        setLoading(false);
      });
  };

  return (
    <Modal show={true} className="log-reading-modal">
      <Modal.Body>
        <div className="header">
          <span>Request Source</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingInput
          value={source}
          onChange={e => setSource(e.target.value)}
          placeholder="Enter source name"
        />
        <FloatingInput
          value={domain}
          onChange={e => setDomain(e.target.value)}
          placeholder="Enter source domain"
        />
        {!loading ? (
          <RoundedButton
            label="Request"
            onClick={requestSource}
            disabled={!source || !domain}
          />
        ) : (
          <div
            className="fa fa-spin fa-spinner text-primary spinner"
            style={{ fontSize: '16px' }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    appData: state.app ? state.app : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestSourceModal);

/* eslint-disable react/button-has-type */
import * as React from 'react';
import RespondInvitePointContainer from './RespondInvitePointContainer';
import { Modal, Button } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import './css/respondInvitePoint.scss';

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class RespondInvitePointModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
    };
  }

  onSave = () => {
    if (this.validate()) {
      this.props.onAction(this.props.onSave, this.props.model).then(() => {
        this.props.onClose(true);
      });
    }

    this.setState({
      isSubmitted: true,
    });
  }

  onRespondInvite = (response) => {
    this.props
      .onAction(this.props.onRespondInvite, this.props.model.point, response)
      .then(() => {
        this.props.onClose(true);
      });
  }

  onClose = () => {
    this.props.onClose(true);
  }

  render() {
    var authorName = this.props.model.point.authorName;
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    const type = this.props.model.point.isCollection ? 'Collection' : 'Point';

    return (
      <Modal
        show={true}
        className="general-modal respond-invite-point"
        container={container}
      >
        <Modal.Header>
          <Button bsStyle="link" className="btn-close" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <div className="modal-title">
            <Icon icon={ICONS.INVITE} /> {authorName} invited you to this {type}
          </div>
        </Modal.Header>
        <Modal.Body>
          <RespondInvitePointContainer point={this.props.model.point} />
        </Modal.Body>
        <Modal.Footer>
          <div className="footrBtn">
            <button
              onClick={() => this.onRespondInvite('rejected')}
              className="btn btn-outline btn-md btn-reject"
            >
              Decline
            </button>
            <button
              onClick={() => this.onRespondInvite('accepted')}
              className="btn btn-primary btn-outline btn-md btn-accept"
            >
              Accept
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RespondInvitePointModal;

//import * as Actions from '../actions/index';

export default function session(state = {}, action) {
  switch (action.type) {
    case "LOAD_STORED_STATE":
      return action.storedState;
    default:
      return state;
  }
}

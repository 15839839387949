import axios from 'axios';

export const ADD_CTP = 'ADD_CTP';
export const REMOVE_CTP = 'REMOVE_CTP';

export function addCTP(ctp) {
  return async (dispatch, getState) => {
    let res;
    // if (qtp.pointId === 'Mock-Point-1') {
    if (
      ctp.pointId &&
      (ctp.pointId.indexOf('Mock-Point') === 0 ||
        ctp.pointId.indexOf('example') === 0 ||
        ctp.isMock)
    ) {
      const point = getState().points[ctp.pointId].point;
      ctp.user = getState().user.user;
      res = addMockCTP(point, ctp);
    } else {
      res = await axios.post('/api/ctps/addCTP', { ctp: ctp });
    }

    if (res.data.point) {
      dispatch({
        type: 'UPDATE_POINT',
        point: res.data.point,
        user: getState().user,
      });
    }

    if (ctp.pointId) {
      dispatch({
        type: ADD_CTP,
        point: { _id: ctp.pointId },
        ctp: res.data.ctp,
      });
    }

    return res.data;
  };
}

function addMockCTP(point, ctp) {
  point.numCTP++;

  if (!ctp._id) {
    ctp._id = Date.now().toString();
  }

  const mockResult = {
    data: {
      point,
      ctp: ctp,
    },
  };
  return mockResult;
}

// updateCTP
export function updateCTP(ctp) {
  return (dispatch, getState) => {
    const res = axios.post('/api/ctps/updateCTP', { ctp: ctp });
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
  };
}

// removeCTP
export function removeCTP(ctp) {
  return async (dispatch, getState) => {
    const res = await axios.delete('/api/ctps/removeCTP/' + ctp._id);
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
    dispatch({ type: REMOVE_CTP, point, ctp });
  };
}

// getCTPsForPoint
export function getCTPsForPoint(pointId) {
  return async (dispatch, getState) => {
    let ctps;

    if (getState().points[pointId] && getState().points[pointId].point.isMock) {
      ctps = getState().points[pointId].ctps || [];
    } else {
      const res = await axios.get('/api/ctps/getCTPs/' + pointId);
      console.log(res.data);
      ctps = res.data;
    }

    dispatch({
      type: 'UPDATE_POINT',
      point: { _id: pointId },
      ctps: ctps,
    });
  };
}

export function getRepliesToCTP(commentId) {
  return async (dispatch, getState) => {
    const res = await axios.get('/api/ctps/getRepliesToCTP/' + commentId);
    return res.data;
  };
}

exports.isValidEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

exports.isValidPhone = phoneNum => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(phoneNum);
};

exports.isValidUrl = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

exports.validateCollectionField = (key, value, errorMessages) => {
  let valid = true;
  switch (key) {
    case 'text':
      if (value.length < 5 || value.length > 100) {
        errorMessages.text = '5-100 characters';
        valid = false;
      } else {
        errorMessages.text = '';
      }
      break;
    case 'description':
      if (value.length > 500) {
        errorMessages.description = '500 characters';
        valid = false;
      } else {
        errorMessages.description = '';
      }
      break;
    default:
  }

  return { valid, errorMessages };
};

import axios from "axios";
import _ from "lodash";
import getClient from "services/search";

export const GET_SEARCH_KEY = "GET_SEARCH_KEY";
export const START_SEARCH = "START_SEARCH";
export const SEARCH_PORTFOLIO = "SEARCH_PORTFOLIO";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export function searchPortfolio(searchTerm, filter, pageType, searchId) {
  return (dispatch, getState) => {
    const { user, search } = getState();
    if (!searchTerm) {
      dispatch({
        type: CLEAR_SEARCH,
        payload: {
          pageType,
        },
      });
    } else {
      let { searchKey } = user;
      if (!searchKey) {
        searchKey = search.searchKey;
      }

      const index = getClient("points", searchKey);

      dispatch({
        type: START_SEARCH,
        payload: {
          searchTerm,
          pageType,
          searchId,
        },
      });

      index
        .search({
          query: searchTerm,
          facets: [
            "authorName",
            "portfolios.name",
            "portfolios.username",
            "sourceName",
            "sourceAuthor",
            "sourceText",
            "tags",
            "text",
            "isCollection",
          ],
          filters: filter,
        })
        .then(({ hits, facets }) => {
          let points = hits.filter(i => !i.isCollection);
          let collectionPoints = hits.filter(i => i.isCollection);
          points = points.map(p => {
            if (!p.subPoints) p.subPoints = [];
            if (!p.authors) p.authors = [];
            return p;
          });
          collectionPoints = collectionPoints.map(p => {
            if (!p.subPoints) p.subPoints = [];
            if (!p.authors) p.authors = [];
            return p;
          });

          const data = summarizeSearchResults({
            facets,
            points,
            collectionPoints,
            searchTerm,
            pageType,
          });
          dispatch({
            type: SEARCH_PORTFOLIO,
            payload: data,
          });
        });
    }
  };
}

const summarizeSearchResults = data => {
  data.topSources = _.filter(data.points, point => {
    return point.sourceName !== null && point.sourceName !== undefined;
  });
  data.topSources = _.countBy(data.topSources, "sourceName");

  return data;
};

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

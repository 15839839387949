import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';
import React from 'react';

export default function SourceStatusChange({ source, handleFormChange }) {
  const isApproved =
    source.isApprovedForAnalysis || source.sourceApprovalStatus === 'approved';
  const isLimitedToCourse = source.sourceApprovalStatus === 'limitedToCourse';
  const isFlagged = source.sourceApprovalStatus === 'flagged';
  const notApproved = source.sourceApprovalStatus === 'notApproved';

  return (
    <RadioGroup
      name="sourceApprovalStatus"
      label="Approval Status"
      handleChange={(e) => handleFormChange(e.target.name, e.target.value)}
      errorMessage=""
    >
      <RadioOption
        name="sourceApprovalStatus"
        id="approved"
        value="approved"
        label="Approved"
        checked={isApproved}
      />
      <RadioOption
        name="sourceApprovalStatus"
        id="limitedToCourse"
        value="limitedToCourse"
        label="Limited To Course"
        checked={isLimitedToCourse}
      />
      <RadioOption
        name="sourceApprovalStatus"
        id="flagged"
        value="flagged"
        label="Flagged"
        checked={isFlagged}
      />
      <RadioOption
        name="sourceApprovalStatus"
        id="notApproved"
        value="notApproved"
        label="Not Approved"
        checked={notApproved}
      />
    </RadioGroup>
  );
}

var config = (module.exports = {});

config.cloudinary = {
  cloud_name: 'averpoint',
  api_key: '464329334857913',
};

//config.stage = "local";
config.stage = 'staging';

config.local = {
  walkthrough: {
    pointId: '5b834a903870e287756787c2',
  },
};

config.staging = {
  walkthrough: {
    pointId: '5b463116d556e30014b1a5c7',
  },
};

config.ux = {
  tagsLimit: 10,
};

config.algolia = {
  app: {
    production: 'N7SF5ZZSO8',
    development: '3XDAQ02BHA',
  },
};

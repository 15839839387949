import * as React from 'react';
import { findDOMNode } from 'react-dom';
// import PropTypes from 'prop-types';
import {
  DropTarget,
  // DropTargetConnector,
  // DropTargetMonitor,
  DragSource,
  // DragSourceMonitor,
  // DragSourceCollector,
  // ConnectDropTarget,
  // DragPreviewImage,
} from 'react-dnd';
import flow from 'lodash-es/flow';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as Actions from 'redux/actions/index';
import PointContainer from './Point/PointContainer';
import CollectionCard from './CollectionCard/CollectionCard';
import { Icon, ICONS } from 'components/Icons';

import './DnDContainer.scss';

/** ***************Drag n Drop ******************** */

let PREVIOUS_X = null;
let PREVIOUS_Y = null;
const DRAG_AREA_SIZE = 15;

const Types = {
  CARD: 'card',
};

const cardSource = {
  canDrag(props) {
    return props.canDrag;
  },

  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    const item = props;
    const pointId = item.point._id;
    const { parentObject } = item;

    const sourceType = item.point.isCollection ? 'collection' : 'point';

    // setTimeout(() => {
    props.actions.onDragStarted(sourceType, pointId, parentObject);
    // }, 10);

    const clientOffset = monitor.getClientOffset();
    PREVIOUS_X = clientOffset.x + window.scrollX;
    PREVIOUS_Y = clientOffset.y + window.scrollY;

    props.actions.showHint(
      'info',
      'Drag to reorder cards. To add a SubPoint to a Point, hold the SubPoint card over the target Point card for 2 seconds.',
    );

    return {
      item,
      id: item._id,
    };
  },

  endDrag(props, monitor, component) {
    if (
      !monitor.didDrop() ||
      monitor.getDropResult().dropTarget !== 'bullseye'
    ) {
      console.log('Ending drag without without drop on Bullseye');
      if (props.moveCardOnHover) {
        console.log('Calling onMoveCardOnEndDrag');
        props.actions.moveSubPointOnServer('drag');
      } else {
        console.log(
          'Ending drag without drop on Bullseye, but no permission to moveCardOnHover',
        );
      }
    } else {
      console.log('Ending drag and not moving on server');
    }

    // props.dispatch({
    //   type: 'END_DRAG',
    // });
    // console.log('Ending drag and there was a drop so not handling it here');

    PREVIOUS_X = null;
    PREVIOUS_Y = null;

    props.actions.hideHint('info');
  },
};

const cardTarget = {
  canDrop(props, monitor, component) {
    return false; // comment if full card is droppable
  },
  hover(props, monitor, component) {
    if (!component) return null;

    if (!props.moveCardOnHover) return null;

    // Allow half second before allowing moves
    if ((Date.now() - props.dnd.lastMoveTimeStamp) / 1000 < 0.5) return;

    // Source: The item being dragged
    const sourceSubPoint = monitor.getItem().item;
    const { dragParent } = props.dnd.sourceInfo;

    // Target: The item being hovered over
    const targetSubPoint = props;
    const targetParent = props.parentObject; // When dragging down across levels, will change target

    // Don't replace items with themselves
    if (sourceSubPoint.point._id == targetSubPoint.point._id) {
      return;
    }

    if (
      dragParent.id == targetParent.id &&
      dragParent.index == targetParent.index
    ) {
      return;
    }

    /** *******    Logic for drag collision
    //  1. Determine if the drag has moved far enough to trigger a collision.
    //  2. If it has, change parent and index of the Point being dragged
    //  to the parent of the target Point. Look for boundary cases - like if the target's parent is the source.
    //  3. Change the index appropriately. 
            a. If same level, just swap indeces. 
            b. If different levels, 
              If dragging down, insert at target index + 1.
              If dragging up, insert at target.parent's next sibling.
        4. Make the change on state.points. During re-render, dnd objects will automatically get the right state index, level, etc.
    ********************** */

    // Determine rectangle on screen and horizontal and veritcal middles
    const targetDOM = findDOMNode(component);
    const targetBoundingRect = targetDOM.getBoundingClientRect();
    // const targetMiddleY =
    //   (targetBoundingRect.bottom - targetBoundingRect.top) / 2;
    // const targetMiddleX =
    //   (targetBoundingRect.right - targetBoundingRect.left) / 2;

    const targetBottomY = targetBoundingRect.bottom - targetBoundingRect.top;
    const targetRightX = targetBoundingRect.right - targetBoundingRect.left;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const targetClientY = clientOffset.y - targetBoundingRect.top;
    const targetClientX = clientOffset.x - targetBoundingRect.left;

    let dragDirectionY = 'no vertical';
    let dragDirectionX = 'no horizontal';

    const newY = clientOffset.y + window.scrollY;
    const previousY = PREVIOUS_Y ? PREVIOUS_Y : newY;

    if (newY < previousY) {
      dragDirectionY = 'up';
    } else if (newY > previousY) {
      dragDirectionY = 'down';
    }

    const newX = clientOffset.x;
    const previousX = PREVIOUS_X ? PREVIOUS_X : newX;

    if (newX < previousX) {
      dragDirectionX = 'left';
    } else if (newX > previousX) {
      dragDirectionX = 'right';
    }

    if (dragDirectionY == 'no vertical' && dragDirectionX == 'no horizontal')
      return;

    let isCollision = false;

    if (dragDirectionY == 'down') {
      if (targetClientY > targetBottomY - DRAG_AREA_SIZE) {
        isCollision = true;

        // This inserts source after the target on down drags across levels
        if (dragParent.level != targetParent.level) {
          targetParent.index++;
        }
      }
    }

    if (dragDirectionY == 'up') {
      if (targetClientY < DRAG_AREA_SIZE) {
        isCollision = true;
      }
    }

    if (dragDirectionX == 'right') {
      if (targetClientX > targetRightX - DRAG_AREA_SIZE) {
        isCollision = true;
      }
    }

    if (dragDirectionX == 'left') {
      if (targetClientX < DRAG_AREA_SIZE) {
        isCollision = true;
      }
    }

    // targetDOM.classList.remove(
    //   'direction-up',
    //   'direction-down',
    //   'direction-right',
    //   'direction-left',
    // );

    // if (dragDirectionY === 'up') {
    //   targetDOM.classList.add('direction-up');
    // }

    // if (dragDirectionY === 'down') {
    //   targetDOM.classList.add('direction-down');
    // }

    // if (dragDirectionX === 'left') {
    //   targetDOM.classList.add('direction-left');
    // }

    // if (dragDirectionX === 'right') {
    //   targetDOM.classList.add('direction-right');
    // }

    if (!isCollision) return;

    PREVIOUS_X = newX;
    PREVIOUS_Y = newY;

    props.actions.reorderPointOnState(dragParent, targetParent);
  },
};

function dragCollect(connect, monitor) {
  return {
    isDragging: monitor.isDragging(),
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    // canDrop: monitor.canDrop(),
  };
}

class DnDContainer extends React.Component {
  setDragPreview = (ref) => {
    const { connectDragPreview } = this.props;
    connectDragPreview(ref);
  }

  shouldComponentUpdate(nextProps) {
    // if (
    //   this.props.dnd.isDragging !== nextProps.dnd.isDragging ||
    //   this.props.point !== nextProps.point ||
    //   this.props.isOver !== nextProps.isOver ||
    //   !this.props.canDrag
    // )
    //   return true;

    // return false;
    return true;
  }

  render() {
    const {
      isDragging,
      isOver,
      canDrag,
      canDrop,
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
      dnd,
      ...rest
    } = this.props;

    const style = {
      // opacity: 1,
    };

    let dragSource;

    if (canDrag) {
      dragSource = (
        <div
          className="position-absolute drag-icon av-grayish-cyan drag"
          ref={connectDragSource}
        >
          <Icon icon={ICONS.DRAG} size={24} />
        </div>
      );
    }

    let card;

    if (this.props.collectionPoint || this.props.point.isCollection) {
      card = (
        <CollectionCard canDrop={canDrop} isDragging={isDragging} {...rest} />
      );
    } else {
      card = (
        <PointContainer canDrop={canDrop} isDragging={isDragging} {...rest} />
      );
    }

    let overlay, dragPreview;

    if (canDrag) {
      overlay = (
        <div
          className="position-absolute w-100 bg-av-grayish-cyan drag-overlay"
          style={{
            display:
              dnd.sourceInfo && dnd.sourceInfo.id === this.props.point._id
                ? 'block'
                : 'none',
          }}
        />
      );

      dragPreview = (
        <div
          className="position-absolute drag-preview"
          ref={this.setDragPreview}
        >
          {card}
        </div>
      );
    }

    const dropTragetClassName = classnames('dragable-card position-relative', {
      'fade-in': rest.cardType !== 'outline',
      'is-dragging': dnd.isDragging,
      hover: isOver,
    });

    let dropTarget = (
      <div
        className={dropTragetClassName}
        style={style}
        key={`dragable-${this.props.point._id}`}
      >
        {dragSource}
        {card}
        {overlay}
        {dragPreview}
      </div>
    );

    if (canDrag) {
      return connectDropTarget(dropTarget);
    } else {
      return dropTarget;
    }
  }
}

DnDContainer = flow(
  DragSource(Types.CARD, cardSource, dragCollect),
  DropTarget(Types.CARD, cardTarget, dropCollect),
)(DnDContainer);

function mapStateToProps(state, ownProps) {
  const { user } = state.user;

  let canDrop = ownProps.point.authorId == user._id; // only the author of the Point can drop into the Point
  if (state.page.dnd.sourceInfo.type == 'collection') {
    if (!ownProps.point.isCollection) {
      canDrop = false; // can't drop a collection into Point
    }
  }

  let { canDrag } = ownProps;
  let pointObject = state.points[ownProps.point._id];

  if (ownProps.point && ownProps.point._id && pointObject) {
    if (pointObject.isEditing) {
      canDrag = false;
    }

    // Do not allow drag if invited. Also make sure, we do not modify if canDrag is already false by previous conditions.
    if (canDrag) {
      const { invitationStatus } = pointObject;
      const isInvited =
        invitationStatus === 'invited' &&
        pointObject.point.authorId !== user.id;
      canDrag = !isInvited;
    }
  } else {
    // console.log('DnD container created without a Point. Props are...');
    // console.log(ownProps);
  }

  return {
    user,
    dnd: state.page.dnd,
    // dndDragParent:
    //   state.page.dnd.sourceInfo && state.page.dnd.sourceInfo.dragParent,
    // dndDragParentLevel:
    //   state.page.dnd.sourceInfo &&
    //   state.page.dnd.sourceInfo.dragParent &&
    //   state.page.dnd.sourceInfo.dragParent.level,
    canDrop,
    canDrag,
    // iamDragged:
    //   state.page.dnd.sourceInfo &&
    //   state.page.dnd.sourceInfo.id === ownProps.point._id,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export const DragableCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DnDContainer);

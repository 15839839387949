import axios from 'axios';

export const ADD_RTP = 'ADD_RTP';

export function addRTP(rtp) {
  return async (dispatch, getState) => {
    let res;
    // if (rtp.pointId === 'Mock-Point-1') {
    if (
      rtp.pointId.indexOf('Mock-Point') === 0 ||
      rtp.pointId.indexOf('example') === 0 ||
      rtp.isMock
    ) {
      const point = getState().points[rtp.pointId].point;
      rtp.user = getState().user.user;
      res = addMockRTP(point, rtp);
    } else {
      res = await axios.post('/api/rtps/addRTP', { rtp });
    }

    console.log(res.data);
    dispatch({
      type: 'UPDATE_POINT',
      point: res.data.point,
      user: getState().user,
    });
    dispatch({
      type: ADD_RTP,
      point: res.data.point,
      rtp: res.data.rtp,
    });

    return res.data;
  };
}

function addMockRTP(point, rtp) {
  point.numRTP++;

  if (!rtp._id) {
    rtp._id = Date.now().toString();
  }

  const mockResult = {
    data: {
      point,
      rtp,
    },
  };
  return mockResult;
}

// updateRTP
export function updateRTP(rtp) {
  return (dispatch, getState) => {
    const res = axios.post('/api/rtps/updateRTP', { rtp });
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
  };
}

// removeRTP
export function removeRTP(pointId) {
  return (dispatch, getState) => {
    const res = axios.delete('/api/rtps/removeRTP' + pointId);
    console.log(res.data);
    const point = res.data;
    dispatch({
      type: 'UPDATE_POINT',
      point,
      user: getState().user,
    });
  };
}

// getRTPsForPoint
export function getRTPsForPoint(pointId) {
  return async (dispatch, getState) => {
    // if (
    //   pointId &&
    //   (pointId.toString().includes('Mock') || pointId.includes('example'))
    // ) {
    //   // const point = getState().points[pointId];
    //   // resolve(point);
    //   return;
    // }
    let rtps;

    if (getState().points[pointId] && getState().points[pointId].point.isMock) {
      rtps = getState().points[pointId].rtps || [];
    } else {
      const res = await axios.get('/api/rtps/getRTPsForPoint/' + pointId);
      console.log(res.data);
      rtps = res.data;
    }

    dispatch({
      type: 'UPDATE_POINT',
      point: { _id: pointId },
      rtps,
    });
  };
}

export function getReviewedPointsForUser(userId) {
  return async (dispatch, getState) => {
    const res = await axios.get('/api/rtps/getReviewedPointsForUser/' + userId);
    console.log(res.data);
    const { points, rtps } = res.data;

    for (let i = 0; i < rtps.length; i++) {
      const rtp = rtps[i];
      // console.log('Checking this rtp');
      // console.log(rtp);
      for (let j = 0; j < points.length; j++) {
        // console.log("Checking if it's this point");
        // console.log(points[j]._id);
        if (points[j]._id === rtp.pointId) {
          // console.log('Found the pointId. Assigning RTP');
          points[j].rtps = [{ ...rtp, type: 'RTP' }];
          // console.log('Not point.rtps is ');
          // console.log(points[j].rtps);
          break;
        }
      }
    }

    dispatch({
      type: 'UPDATE_POINTS',
      updatedPoints: points,
    });

    return res.data;
  };
}

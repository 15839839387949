import React, { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Icon, ICONS } from 'components/Icons';

import QuestionText from './QuestionText';

import './LocationPicker.scss';

const REACT_APP_GOOGLE_API_PLACES = process.env.REACT_APP_GOOGLE_API_PLACES;

export default function LocationPicker({
  question,
  onChange,
  model,
  autoFocus = false,
}) {
  const [input, setInput] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);

  const value = model[question.variable];

  function renderText() {
    return <QuestionText question={question} />;
  }

  function picker() {
    return (
      <input
        ref={ref}
        type="text"
        className="form-control"
        value={input}
        onChange={e => setInput(e.target.value)}
        placeholder="Search for location"
        autoFocus={autoFocus}
      />
    );
  }

  function getValue() {
    const defaultValue = [];

    return value || defaultValue;
  }

  function onLocationSelection(address) {
    const addressData = { ...address };

    return onChange([...getValue(), addressData]);
  }

  useEffect(() => {
    if (selectedPlace) {
      onLocationSelection(selectedPlace);
      setInput('');
      setSelectedPlace(null);
    }
  }, [selectedPlace]);

  const { ref } = usePlacesWidget({
    apiKey: REACT_APP_GOOGLE_API_PLACES,
    onPlaceSelected: place => {
      if (!place || !Array.isArray(place.address_components)) {
        return;
      }
      const address_components = place.address_components;

      const country = address_components.find(
        c => c.types.indexOf('country') > -1,
      );
      const state = address_components.find(
        c => c.types.indexOf('administrative_area_level_1') > -1,
      );
      const city = address_components.find(
        c =>
          c.types.indexOf('locality') > -1 ||
          c.types.indexOf('administrative_area_level_2') > -1,
      );

      const address = {
        id: new Date().getTime(),
        country: country ? country.long_name : '',
        state: state ? state.long_name : '',
        city: city ? city.long_name : '',
        countryCode: country ? country.short_name : '',
        isHome: false,
        isCurrent: false,
      };

      setSelectedPlace(address);
    },
  });

  const mark = (id, isHome = false) => {
    let places = JSON.parse(JSON.stringify(getValue()));

    if (isHome) {
      places = places.map(place => ({
        ...place,
        isHome: place.id === id ? true : false,
      }));
    } else {
      places = places.map(place => ({
        ...place,
        isCurrent: place.id === id ? true : false,
      }));
    }

    onChange(places);
  };

  const remove = id => {
    let places = JSON.parse(JSON.stringify(getValue()));

    places = places.filter(p => p.id !== id);

    onChange(places);
  };

  function showHeader() {
    const location = getValue();

    if (!Array.isArray(location) || location.length === 0) {
      return;
    }

    return (
      <div className="container header">
        <div className="row">
          <div className="col-xs-7 place">Place</div>
          <div className="col-xs-2 homeCity">Home City</div>
          <div className="col-xs-2 currentCity">Current City</div>
          <div className="col-xs-1 remove" />
        </div>
      </div>
    );
  }

  function showLocation() {
    const location = getValue();

    if (!Array.isArray(location) || location.length === 0) {
      return;
    }

    return location.map(l => (
      <div className="container places">
        <div className="row">
          <div className="col-xs-7 place">
            <Icon className="icon" icon={ICONS.LOCATION} size={14} />
            <div key={l.city} className="user-selected-location">
              {l.city}, {l.state}, {l.country}, {l.countryCode}
            </div>
          </div>
          <div className="col-xs-2 homeCity">
            <input
              type="radio"
              onChange={() => mark(l.id, true)}
              checked={l.isHome}
            />
          </div>
          <div className="col-xs-2 currentCity">
            <input
              type="radio"
              onChange={() => mark(l.id)}
              checked={l.isCurrent}
            />
          </div>
          <div className="col-xs-1 remove">
            <Icon
              className="icon"
              icon={ICONS.CLOSE}
              size={10}
              onClick={() => remove(l.id)}
            />
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="location-picker">
      {renderText()}
      {picker()}
      {showHeader()}
      {showLocation()}
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditableText from 'components/elements/atoms/EditableComponent/EditableComponent';
import Source from 'components/elements/organisms/Source/Source';
import { EditableTags } from 'components/elements/molecules/tags';
import './pointDetail.scss';
import { withTheme } from 'styled-components';

const REACT_APP_NEW_TAG_TIME_DIFF = process.env.REACT_APP_NEW_TAG_TIME_DIFF;

class PointDetail extends Component {
  static propTypes = {
    point: PropTypes.object.isRequired,
    citationStyle: PropTypes.string,
    isEditing: PropTypes.bool,
    onPointFieldChanged: PropTypes.func,
    errorMessages: PropTypes.object,
    view: PropTypes.string,
    isEditingFields: PropTypes.object,
  };

  renderNewTag(i) {
    console.log(i.createdAt);
    if (
      new Date() - new Date(i.createdAt) <
      REACT_APP_NEW_TAG_TIME_DIFF * 60 * 60 * 1000
    ) {
      return (
        <span
          className="badge border-0 mr-3 body-small new pt-2 pb-2 rounded-0"
          style={{ background: 'green', color: 'white' }}
        >
          NEW
        </span>
      );
    }
  }

  renderTitle() {
    const { point, onPointFieldChanged, cardType } = this.props;

    if (cardType === 'default' || cardType === 'page') {
      return;
    }

    return (
      <div className="body-large d-flex align-items-center mb-3">
        {/* {this.renderNewTag(point)} */}
        <EditableText
          value={point.text}
          editOnClick={this.props.isEditingFields.text}
          editOnIcon={this.props.isEditingFields.text}
          onSave={text => {
            onPointFieldChanged('text', text);
          }}
        />
      </div>
    );
  }

  render() {
    const { point, isEditing, cardType } = this.props;

    if (!point.sourceName && !isEditing) {
      return null;
    }

    if (cardType === "claim") return null;

    // const minContentLength = 300;

    // const sourceText =
    //   point.sourceText && point.sourceText.length > minContentLength
    //     ? `${point.sourceText.substr(0, minContentLength)}...`
    //     : point.sourceText;

    // Step 3: Prepare the source date, if it exists
    let momentDate = '';
    if (point.sourceDateFormatted) momentDate = point.sourceDateFormatted;
    else if (point.sourceDate && moment(point.sourceDate).isValid()) {
      momentDate = moment(point.sourceDate).format('MMMM D YYYY');
    }

    // let EditableText = contentEditable('div');

    const allowSourceEdit = this.props.isEditingFields.source;
    // const allowSourceTextEdit =
    //   allowSourceEdit ||
    //   (isEditing && point.sourceText && point.sourceText.length > 0);

    return (
      <div className="point-details" onClick={this.onClick}>
        {/* {this.renderTitle()} */}
        {this.renderSourceCitation(allowSourceEdit)}
        {/* {this.renderPointTags()}s */}
      </div>
    );
  }

  renderSourceCitation(allowSourceEdit) {
    const { isEditing } = this.props;

    const openEditFields =
      allowSourceEdit &&
      (this.props.point.sourceName == null ||
        this.props.point.sourceName.length === 0);

    return (
      <Source
        point={this.props.point}
        onPointFieldChanged={this.props.onPointFieldChanged}
        editable={isEditing}
        openEditFields={openEditFields}
        citationStyle={this.props.citationStyle}
        sourceFieldsFromURL={this.props.sourceFieldsFromURL}
        lookupSourceFieldsFromURL={this.props.lookupSourceFieldsFromURL}
        onUrlChange={this.props.onUrlChange}
        hideAddSourceLink
        isExtension={false}
        errorMessages={this.props.errorMessages}
        isFetching={this.props.isFetching}
        updateMasonry={this.props.updateMasonry}
        viewType="pointDetail"
        cardType={this.props.cardType}
        className=""
      />
    );
  }

  renderPointTags = () => {
    const { point, view, isEditingFields, onPointFieldChanged } = this.props;

    if (view === 'outline') {
      //Tags only visible in edit mode for outline view
      if (!this.props.isEditingFields.tags) return null;
    }

    if (!point.tags || point.tags.length == 0) {
      if (!this.props.isEditingFields.tags) return null;
    }

    return (
      <div className="point-tags">
        <EditableTags
          tags={point.tags}
          displayType={'piped'}
          setTags={tags => onPointFieldChanged('tags', tags)}
          tagsEditable={isEditingFields.tags}
          startEditable={isEditingFields.tags}
        />
      </div>
    );
  };

  onClick = e => {
    if (this.props.isEditing) e.stopPropagation();
  };
}

export default PointDetail;

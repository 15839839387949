import * as Actions from 'redux/actions/index';

export default function bottomSheet(state = {}, action) {
  switch (action.type) {
    case Actions.OPEN_BOTTOM_SHEET:
      return {
        ...state,
        open: true,
        child: action.data.child,
      };
    case Actions.HIDE_BOTTOM_SHEET:
    default:
      return {
        ...state,
        open: false,
      };
  }
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import FloatingTextarea from 'components/elements/atoms/FloatingTextarea/FloatingTextarea';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageWritingPrompts.scss';

function ManageWritingPrompts({
  courseId,
  topicId,
  writingPrompt = {},
  onClose,
  actions,
}) {
  const [text, setText] = useState(writingPrompt.text || '');
  // const [date, setDate] = useState(writingPrompt.dueDate || '');
  const [wordLimit, setWordLimit] = useState(writingPrompt.wordLimit || '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [text]);

  const createWritingPrompt = () => {
    if (writingPrompt._id) {
      actions
        .updateWritingPrompt({
          courseId,
          topicId,
          promptId: writingPrompt._id,
          text,
          // dueDate: date,
          wordLimit,
        })
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while updating Writing Prompt. Please try again.',
          );
        });
    } else {
      actions
        .createWritingPrompt({
          courseId,
          topicId,
          text,
          // dueDate: date,
          wordLimit,
        })
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while adding Writing Prompt. Please try again.',
          );
        });
    }
  };

  return (
    <Modal show={true} className="manage-course-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>{writingPrompt._id ? 'Edit' : 'Create'} Writing Prompt</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingTextarea
          autoFocus={true}
          value={text}
          labelName="Prompt text"
          onChange={(e) => setText(e.target.value)}
          error={error || ''}
          maxRows={3}
        />
        {/* <div>
          <FloatingInput
            value={date ? moment(date).format('YYYY-MM-DDThh:mm') : ''}
            type="datetime-local"
            labelName="Due Date"
            onChange={(e) => setDate(e.target.value)}
            min={moment().format('YYYY-MM-DDThh:mm')}
            onFocus={(e) => {
              try {
                e.target.showPicker();
              } catch { }
            }}
            onClick={(e) => {
              e.target.showPicker();
            }}
          />
        </div> */}
        <FloatingInput
          type="number"
          value={wordLimit}
          labelName="Word Limit"
          onChange={(e) => setWordLimit(e.target.value)}
        />
        {/* <div className="mb-5">
          <DropDown
            labelName="Status"
            value={status}
            onChange={(selectedOption) => setStatus(selectedOption)}
            options={writingPromptStatuses}
            isDisabled={writingPrompt.status === 'published'}
          />
        </div> */}
        <RectangularButton
          className="next-button"
          // disabled={!text || !date}
          disabled={!text}
          onClick={() => createWritingPrompt()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageWritingPrompts);

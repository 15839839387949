import * as React from "react";
import { Modal } from "react-bootstrap";
import InviteAverpointComponent from "./InviteAverpointComponent";
import { isValidEmail } from 'helpers/validation';
export default class InviteAverpointContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailToInvite: "",
      errorMessage: "",
      message: ""
    };
  }

  render() {
    return (
      <InviteAverpointComponent
        usersForInviteList={this.state.usersForInviteList}
        existingUsersToInvite={this.state.existingUsersToInvite}
        emailToInvite={this.state.emailToInvite}
        addEmailToInvite={this.addEmailToInvite}
        message={this.state.message}
        setMessage={this.setMessage}
        onInvite={this.onInvite}
        userList={this.props.userList}
        errorMessage={this.state.errorMessage}
      />
    );
  }

  onClose() {
    this.setState({
      emailToInvite: "",
      errorMessage: "",
      message: ""
    });

    //this.props.onClose();
  }

  addEmailToInvite = (e) => {
    //console.log("addEmailToInviteList got called and value is "+e.target.value);
    this.setState({
      errorMessage: "",
      emailToInvite: e.target.value,
      existingUsersToInvite: []
    });
  }

  setMessage = (e) => {
    this.setState({
      message: e.target.value
    });
  }

  onInvite = () => {
    console.log("send invite got called");

    const emailsToInvite = [];

    const emailArray = this.state.emailToInvite.split(",");

    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i].trim();
      if (isValidEmail(email)) {
        emailsToInvite.push(email);
      } else {
        this.setState({
          errorMessage: `${email} is not a valid email.`
        });
        return;
      }
    }

    this.props.onInvite(emailsToInvite);
  }
}

import axios from "axios";
import qs from "qs";

/*** Constants */
export const UPDATE_TAGS = "UPDATE_TAGS";
export const TOP_TAGS = "TOP_TAGS";
export const FEATURED_TAGS = "FEATURED_TAGS";

export function updateTags(tagObject) {
  return {
    type: UPDATE_TAGS,
    tagObject
  };
}

/*** Actions */

export function loadTags() {
  return (dispatch, getState) => {
    var method = "GET";
    var api = "/api/tags/getTags";
    //var data = { id: pointId };

    return new Promise((resolve, reject) => {
      //axios.get(api, {params: data})
      axios
        .get(api)
        .then(response => {
          //console.log("OG responded successfully")
          console.log(response);

          let data = response.data;

          let tagsObject = {
            tags: data.tags,
            timeStamp: data.timeStamp
          };

          dispatch(updateTags(tagsObject));
          resolve(tagsObject);
        })
        .catch(response => {
          console.log("Load Tags responded error");
          console.log(response);
          //dispatch();
          reject(response);
        });
    });
  };
}

export function getFeaturedTags() {
  return dispatch => {
    return axios.get("/api/tags/featured").then(
      resp => {
        dispatch({
          type: FEATURED_TAGS,
          tags: resp.data.tags
        });
      },
      err => err.response.data
    );
  };
}

export function getTopTags() {
  return dispatch => {
    return axios.get("/api/tags/top").then(
      resp => {
        dispatch({
          type: TOP_TAGS,
          tags: resp.data.tags
        });
      },
      err => err.response.data
    );
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { Icon, ICONS } from 'components/Icons';

import AddTags from './addTags';
import DisplayTags from './displayTags';
import './tags.scss';

class EditableTags extends Component {
  static propTypes = {
    startEditable: PropTypes.bool,
    tags: PropTypes.array,
    tagsEditable: PropTypes.bool,
    autofocus: PropTypes.bool,
    tagsLimit: PropTypes.number,
    selectTopTags: PropTypes.bool,
    onTagSelect: PropTypes.func,
    hasTag: PropTypes.func,
    goToPage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      editMode: this.props.startEditable,
    };
  }

  render() {
    return <div className="auto-complete">{this.renderTags()}</div>;
  }

  renderTags() {
    let tags = this.props.tags || [];
    //if (!tags) return;
    if (this.state.editMode && this.props.tagsEditable) {
      // console.log("Rendering add tags");
      return (
        <AddTags
          tags={tags}
          setTags={this.setTags}
          //onFocusOut={this.toggleTagsEditMode}
          autofocus={this.props.autofocus}
          tagsLimit={this.props.tagsLimit}
        />
      );
    } else {
      return (
        <div className="editable-tags-container">
          {this.renderAddTagsLink()}
          <DisplayTags
            tags={tags}
            displayType={this.props.displayType}
            selectTopTags={this.props.selectTopTags}
            onTagSelect={this.props.onTagSelect}
            hasTag={this.props.hasTag}
            tagsEditable={this.props.tagsEditable}
            handleTagClick={this.toggleTagsEditMode}
            goToPage={this.props.goToPage}
          />
          {this.props.tagsEditable && (
            <Icon icon={ICONS.PENCIL} onClick={this.toggleTagsEditMode} />
          )}
        </div>
      );
    }
  }

  renderAddTagsLink = () => {
    if (!this.props.tagsEditable) return;
    if (this.props.tags && this.props.tags.length > 0) return;
    return (
      <div
        className="add-tags-link"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionLink
          onClick={this.toggleTagsEditMode}
          linkClassName={''}
          iconType={'averPointIcon'}
          iconName={'ADD'}
          label={'Tags'}
          toolTipText="Add tags to organize your Point"
          toolTipPosition="bottom"
        />
      </div>
    );
  };

  toggleTagsEditMode = e => {
    // var caller = e.target || e.srcElement;
    // console.log( caller );

    if (!this.props.tagsEditable) return;
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  setTags = (tags) => {
    this.props.setTags(tags);
  }
}
export default EditableTags;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

// Components
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import SearchableDropDown from 'components/elements/molecules/SearchableDropDown/SearchableDropDown';

// SCSS
import './MediaCollectionModal.scss';

class MediaCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    let point = this.props.model.point;

    this.state = {
      point: {
        ...point,
        // media: {
        //   ...point.media,
        // },
      },
      isEditing: point.sourceURL && point.sourceType,
      isSubmitted: false,
    };
  }

  lookupURL = () => {
    let expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);
    let { point } = this.state;

    if (point.sourceURL.match(regex)) {
      this.props.onAction(this.props.lookupURL, point.sourceURL).then(res => {
        console.log(res);
        this.setState({
          point: {
            ...this.state.point,
            ...res,
          },
        });
      });
    }
  }

  onClose = () => {
    this.props.onClose(false);
  }

  // onChange(e) {
  //   const { name, value } = e.target;
  //   this.setState({
  //     media: {
  //       ...this.state.media,
  //       [name]: value,
  //     },
  //   });
  // }

  onChangeURL(sourceURL) {
    this.setState({
      point: {
        ...this.state.point,
        sourceURL,
      },
    });
  }

  onChangeType(type) {
    this.setState({
      point: {
        ...this.state.point,
        // media: {
        //   ...this.state.point.media,
        //   type,
        // },
        sourceType: type,
      },
    });
  }

  onChangeTitle(sourceTitle) {
    this.setState({
      point: {
        ...this.state.point,
        sourceTitle,
      },
    });
  }

  onChangeDescription(sourceDescription) {
    this.setState({
      point: {
        ...this.state.point,
        sourceDescription,
      },
    });
  }

  onChangeThumbnail(sourceImageURL) {
    this.setState({
      point: {
        ...this.state.point,
        sourceImageURL,
      },
    });
  }

  onSubmit = () => {
    this.setState({
      isSubmitted: true,
    });

    var { point } = this.state;

    if (point.sourceURL && point.sourceType) {
      this.props.onAction(this.props.onUpdate, point).then(() => {
        this.props.onClose(true);
      });
    }
  }

  renderURL() {
    let { isSubmitted, point } = this.state;

    return (
      <FloatingInput
        name="url"
        onChange={e => this.onChangeURL(e.target.value)}
        labelName="URL"
        value={point.sourceURL}
        error={isSubmitted && !point.sourceURL ? 'Please enter URL' : ''}
        onBlur={this.lookupURL}
      />
    );
  }

  renderMediaType() {
    let { isSubmitted, point } = this.state;

    return (
      <div className="media-type">
        <select
          onChange={e => this.onChangeType(e.target.value)}
          name="type"
          value={point.sourceType}
        >
          <option value="">Select Media Type</option>
          <option value="VIDEO">Video</option>
        </select>
        {isSubmitted && !point.sourceType && (
          <p className="error">Please select media type</p>
        )}
      </div>
    );
  }

  renderVideoMeta() {
    let { point } = this.state;

    return (
      <div>
        <FloatingInput
          name="title"
          onChange={e => this.onChangeTitle(e.target.value)}
          labelName="Title"
          value={point.sourceTitle}
        />
        <FloatingInput
          name="description"
          onChange={e => this.onChangeDescription(e.target.value)}
          labelName="Description"
          value={point.sourceDescription}
        />
        <FloatingInput
          name="placeholder"
          onChange={e => this.onChangeThumbnail(e.target.value)}
          labelName="Thumbnail"
          value={point.sourceImageURL}
        />
      </div>
    );
  }

  renderMeta() {
    let { point } = this.state;

    switch (point.sourceType) {
      case 'VIDEO':
        return this.renderVideoMeta();
    }
  }

  render() {
    return (
      <Modal show={true} className="media-collection-modal">
        <Modal.Header closeButton onHide={this.onClose}>
          <Modal.Title>
            {this.state.isEditing ? 'Edit Media' : 'Add Media'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderURL()}
          {this.renderMediaType()}
          {this.renderMeta()}
        </Modal.Body>
        <Modal.Footer>
          <RoundedButton
            label={this.state.isEditing ? 'Update' : 'Save'}
            onClick={this.onSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(MediaCollectionModal);

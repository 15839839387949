import * as React from 'react';
import { Modal } from 'react-bootstrap';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import questionImg from './img/question.svg';

require('./css/confirmModal.scss');

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
  }

  onSave = () => {
    this.props.onClose(true);
  };

  onClose = () => {
    this.props.onClose(false);
  };

  render() {
    // let title = this.props.model.title || "Confirm";
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    return (
      <Modal show={true} className="confirm-modal" container={container}>
        <Modal.Body className="modalBody">
          <img src={questionImg} className="confirmImg" />
          <div className="confirmHeader">
            {this.props.model.message || 'Are you sure?'}
          </div>
          <div className="description" />
          <div className="doneDiv">
            <RoundedButton
              mainClassName="doneButton cancelButton"
              label="No"
              onClick={this.onClose}
            />
            <RoundedButton
              mainClassName="doneButton tryagainButton"
              label="Yes"
              onClick={this.onSave}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmModal;

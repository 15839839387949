import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {};

export default function writingVersions(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_WRITING_VERSIONS:
      return {
        ...state,
        [action.writingId]: action.versions,
      };
    case Actions.UPDATE_WRITING_VERSION: {
      const index = state[action.writingId].findIndex(
        (i) => i._id === action.version._id,
      );
      return {
        ...state,
        [action.writingId]: [
          ...state[action.writingId].slice(0, index),
          action.version,
          ...state[action.writingId].slice(index + 1),
        ],
      };
    }

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import './radio.scss';

const RadioOption = (props) => {
  let disabled = false;
  if (props.disabled) disabled = true;
  let radioOptionClassName = disabled
    ? 'radio-option-disabled'
    : 'radio-option';
  //let icon = props.checked ? <Icon icon={ICONS.VERIFIED} /> : "";

  return (
    <div
      className={`${radioOptionClassName} ${props.className}`}
      onClick={handleClick}
    >
      <input
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value}
        //onChange={handleClick}
        defaultChecked={props.checked}
      />
      <label key={props.id} for={props.id} onClick={handleClick}>
        {props.label}
      </label>
    </div>
  );
};

const handleClick = (e) => {
  e.stopPropagation();
};

export default RadioOption;

RadioOption.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
};

import React, {
  useReducer,
  useImperativeHandle,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import classnames from 'classnames';

import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';
import QuestionText from './QuestionText';
import SelectPointsQuestion from './SelectPointsQuestion';
import MultipleChoicePointQuestion from './MultipleChoicePointQuestion';
import MultipleSelectPointsQuestion from './MultipleSelectPointsQuestion';
import InputTextQuestion from './InputTextQuestion';
import InputTextAreaQuestion from './InputTextAreaQuestion';
import InputQuestions from './InputQuestions';
import SingleChoiceButton from './SingleChoiceButton';
import CounterGroup from './CounterGroup';
import PreFillSources from './PreFillSources';
import SourcePickerChoice from './SourcePickerChoice';
import LocationPicker from './LocationPicker';

import './Survey.scss';
import InfoQuestion from './InfoQuestion';

function Survey(
  {
    questions,
    // totalSteps,
    onFinish,
    className,
    onChangeIndex,
    onChangeModel,
    point,
    points,
    oldModel,
    onAction,
    startIndex = 0,
    isMock,
    collectionPoint,
    firstName,
    lastName,
    isExtensionCL,
  },
  ref,
) {
  const index = useMemo(() => {
    const firstQuestion = questions[0];

    if (firstQuestion.goToIfNameExists && firstName && lastName) {
      return firstQuestion.goToIfNameExists;
    }

    return startIndex;
  }, [questions]);

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_INDEX':
          return {
            ...state,
            index: action.payload,
            submitted: false,
          };
        case 'SET_VARIABLE':
          return {
            ...state,
            model: {
              ...state.model,
              [action.payload.variable]: action.payload.value,
            },
          };
        case 'SET_SUBMITTED':
          return { ...state, submitted: action.payload };
        case 'SET_PREVIOUS_INDEX':
          return {
            ...state,
            previousIndexes: action.payload,
          };
        default:
          return state;
      }
    },
    { index, model: {}, submitted: false, previousIndexes: [] },
  );
  const questionRef = useRef(null);

  useImperativeHandle(ref, () => ({
    totalQuestions: () => {
      return questions.length;
    },
    getActiveIndex: () => {
      return getActiveIndex();
    },
    getActiveQuestion: () => {
      return getActiveQuestion();
    },
    allowContinue: () => {
      return allowContinue();
    },
    allowPrevious: () => {
      return state.previousIndexes.length;
    },
    previous: () => {
      onPrevious();
    },
    continue: () => {
      onContinue();
    },
  }));

  useEffect(() => {
    if (onChangeIndex) {
      onChangeIndex(state.index);
    }

    // set old model value
    if (oldModel) {
      const question = questions[state.index];
      const variable = question.variable;

      if (variable) {
        onChange(oldModel[variable]);
      }
    }
  }, [state.index]);

  useEffect(() => {
    if (onChangeModel) {
      onChangeModel(state.model);
    }
  }, [state.model]);

  function onContinue() {
    dispatch({ type: 'SET_SUBMITTED', payload: true });

    if (!allowContinue()) {
      return;
    }

    if (questionRef.current && questionRef.current.continue) {
      const ret = questionRef.current.continue();

      if (ret) {
        return;
      }
    }

    next();
  }

  function allowContinue() {
    if (questionRef.current && questionRef.current.allowContinue) {
      return questionRef.current.allowContinue();
    } else {
      const question = getActiveQuestion();
      return (
        !question.required ||
        (question.variable && state.model[question.variable])
      );
    }
  }

  function getActiveIndex() {
    return state.index;
  }

  function getActiveQuestion() {
    return questions[getActiveIndex()];
  }

  function setIndex(index, push) {
    if (push) {
      dispatch({
        type: 'SET_PREVIOUS_INDEX',
        payload: [...state.previousIndexes, state.index],
      });
    } else {
      dispatch({
        type: 'SET_PREVIOUS_INDEX',
        payload: state.previousIndexes.slice(
          0,
          state.previousIndexes.length - 1,
        ),
      });
    }
    dispatch({ type: 'SET_INDEX', payload: index });
  }

  function next() {
    const question = getActiveQuestion();
    question.type === 'END' ? onFinishQuestions() : onNext();
  }

  function onChange(value, variable) {
    if (!variable) {
      const question = questions[state.index];
      variable = question.variable;
    }

    if (variable) {
      dispatch({
        type: 'SET_VARIABLE',
        payload: { variable, value },
      });
    }
  }

  function onNext() {
    const question = questions[state.index];
    const questionModel = state.model[question.variable];
    let goto;

    if (
      question.type === 'SINGLE_CHOICE' ||
      question.type === 'SINGLE_CHOICE_BUTTON'
    ) {
      goto = question.options.find((i) => i.text === questionModel).goto;
    } else if (
      question.type === 'MULTIPLE_CHOICE' &&
      (!questionModel || !questionModel.length) &&
      question.noSelectGoto !== undefined
    ) {
      goto =
        question.noSelectGoto === 'END' ? undefined : question.noSelectGoto;
    } else {
      goto = question.goto;
    }
    if (goto && questions[goto] && questions[goto].goToIfNameExists) {
      if (firstName && lastName) {
        goto = questions[goto].goToIfNameExists;
      }
    }

    if (!goto && question.finalQuestion) {
      onFinishQuestions();
    } else {
      setIndex(goto, true);
      dispatch({ type: 'SET_VALUE', payload: undefined });
    }
  }

  function onPrevious() {
    if (questionRef.current && questionRef.current.previous) {
      const ret = questionRef.current.previous();

      if (ret) {
        return;
      }
    }

    onChange(undefined);
    setIndex(state.previousIndexes[state.previousIndexes.length - 1], false);
  }

  function onFinishQuestions() {
    onFinish(state.model);
  }

  function renderQuestion() {
    const question = questions[state.index];
    let Component;

    switch (question.type) {
      case 'TEXT':
      case 'END':
        Component = TextQuestion;
        break;
      case 'SINGLE_CHOICE':
        Component = SingleChoiceQuestion;
        break;
      case 'MULTIPLE_CHOICE':
        Component = MultipleChoiceQuestion;
        break;
      case 'PRE_FILL_SOURCES':
        Component = PreFillSources;
        break;
      case 'INPUT_TEXT':
        Component = InputTextQuestion;
        break;
      case 'INPUT_TEXT_AREA':
        Component = InputTextAreaQuestion;
        break;
      case 'INPUT_QUESTIONS':
        Component = InputQuestions;
        break;
      case 'SELECT_POINTS':
        Component = SelectPointsQuestion;
        break;
      case 'MULTIPLE_CHOICE_POINT':
        Component = MultipleChoicePointQuestion;
        break;
      case 'MULTIPLE_SELECT_POINTS':
        Component = MultipleSelectPointsQuestion;
        break;
      case 'SINGLE_CHOICE_BUTTON':
        Component = SingleChoiceButton;
        break;
      case 'INFO':
        Component = InfoQuestion;
        break;
      case 'COUNTER_GROUP':
        Component = CounterGroup;
        break;
      case 'SOURCE_PICKER':
        Component = SourcePickerChoice;
        break;
      case 'LOCATION_PICKER':
        Component = LocationPicker;
        break;
    }

    return (
      <Component
        questions={questions}
        question={question}
        onChange={onChange}
        model={state.model}
        submitted={state.submitted}
        point={point}
        points={points}
        ref={questionRef}
        onContinue={onContinue}
        onAction={onAction}
        isMock={isMock}
        collectionPoint={collectionPoint}
        firstName={firstName}
        lastName={lastName}
        isExtensionCL={isExtensionCL}
      />
    );
  }

  const containerClass = classnames('survey-component', className);

  return (
    <div className={containerClass} ref={ref}>
      {renderQuestion()}
    </div>
  );
}

export default React.forwardRef(Survey);

function TextQuestion({ question }) {
  function renderText() {
    return <QuestionText question={question} />;
  }
  return <React.Fragment>{renderText()}</React.Fragment>;
}

export function SingleChoiceQuestion({ question, onChange, model }) {
  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderCheckbox(option, key) {
    const value = model[question.variable];

    // if (option && option.text === value && option.showNote) {
    //   return null;
    // }

    return (
      <Checkbox
        onClick={() => {
          onChange(option.text);
        }}
        onChange={() => { }}
        checked={value === option.text}
        key={key}
      >
        {option.text}
      </Checkbox>
    );
  }

  function renderCheckboxes() {
    return question.options.map((option, index) =>
      renderCheckbox(option, index),
    );
  }

  function renderNote() {
    const value = model[question.variable];
    const option = question.options.find((option) => option.text === value);

    if (!option || !option.showNote) {
      return null;
    }

    const noteValue = model[option.showNoteVariable];

    return (
      <textarea
        className="form-control"
        placeholder={option.showNotePlaceholder}
        value={noteValue}
        onChange={(e) => onChange(e.target.value, option.showNoteVariable)}
      />
    );
  }

  return (
    <React.Fragment>
      {renderText()}
      {renderCheckboxes()}
      {renderNote()}
    </React.Fragment>
  );
}

function MultipleChoiceQuestion({ question, onChange, model }) {
  function getValue() {
    return model[question.variable] || [];
  }

  function isChecked(option) {
    if (option.clear) {
      return getValue().length === 0;
    } else {
      return getValue().indexOf(option.text) !== -1;
    }
  }

  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderCheckbox(option, key) {
    const value = getValue();
    const checked = isChecked(option);

    return (
      <Checkbox
        onClick={(e) => {
          e.preventDefault();

          if (checked) {
            value.splice(value.indexOf(option.text), 1);
            onChange([...value]);
          } else {
            if (option.clear) {
              onChange([]);
            } else {
              onChange([...value, option.text]);
            }
          }
        }}
        onChange={(e) => { }}
        checked={checked}
        key={key}
      >
        {option.text}
      </Checkbox>
    );
  }

  function renderCheckboxes() {
    return question.options.map((option, index) =>
      renderCheckbox(option, index),
    );
  }

  function renderNote() {
    const value = getValue();

    const option = question.options.find(
      (option) => isChecked(option) && option.showNote,
    );

    if (!option) {
      return null;
    }

    const noteValue = model[option.showNoteVariable];

    return (
      <textarea
        className="form-control"
        placeholder={option.showNotePlaceholder}
        value={noteValue}
        onChange={(e) => onChange(e.target.value, option.showNoteVariable)}
      />
    );
  }

  return (
    <React.Fragment>
      {renderText()}
      {renderCheckboxes()}
      {renderNote()}
    </React.Fragment>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon, ICONS } from 'components/Icons';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import SourceInfo from './SourceInfo';
import TextareaAutosize from 'components/elements/molecules/TextareaAutosize/TextareaAutosize';

import './Source.scss';

class Source extends Component {
  static propTypes = {
    point: PropTypes.object,
    citationStyle: PropTypes.string,
    userHighlightType: PropTypes.string,
    editable: PropTypes.bool,
    openEditFields: PropTypes.bool,
    onPointFieldChanged: PropTypes.func,
    isExtension: PropTypes.bool,
    errorMessages: PropTypes.object,
    focusQuote: PropTypes.bool,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
    cardType: PropTypes.oneOf(['outline', 'default', 'page']),
  };

  static defaultProps = {
    focusQuote: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSourceDetails:
        props.type === 'LIGHTWEIGHT'
          ? false
          : !props.isExtension && props.editable,
      sourceEditable: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openEditFields != this.props.openEditFields) {
      this.setState({
        showSourceDetails: this.props.openEditFields,
      });
    }

    if (
      prevProps.errorMessages.sourceName !==
        this.props.errorMessages.sourceName &&
      this.props.errorMessages.sourceName === 'Required'
    ) {
      this.setState({ showSourceDetails: true });
    }

    // Expand source info if no source URL. Only for Extension CL
    if (
      this.props.isExtensionCL &&
      prevProps.point.showQuote !== this.props.point.showQuote &&
      this.props.point.showQuote &&
      !this.props.point.sourceURL
    ) {
      this.setState({ showSourceDetails: true });
    }
  }

  render() {
    const { point, editable } = this.props;

    let classNames = classnames('source', this.props.className, {
      'd-none': editable && !point.showQuote,
    });

    return (
      <div className={classNames}>
        {this.renderSourceQuote()}
        {this.renderCitation()}
      </div>
    );
  }

  renderAddSourceLink() {
    if (!this.props.point.sourceName) {
      if (this.props.hideAddSourceLink) return null;
      if (!this.state.showSourceDetails) {
        return (
          <div
            className="add-source-link"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ActionLink
              onClick={this.toggleShowSourceDetails}
              linkClassName=""
              iconType="averPointIcon"
              iconName="ADD"
              label="Source"
              toolTipText="Add a Source to back up your Point"
              toolTipPosition="bottom"
            />
          </div>
        );
      }
      return (
        <div
          className="add-source-link"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ActionLink
            onClick={this.toggleShowSourceDetails}
            linkClassName=""
            label="Hide source info"
            toolTipText="Add a Source to back up your Point"
            toolTipPosition="bottom"
          />
        </div>
      );
    }
  }

  renderSourceQuote() {
    const { editable, focusQuote, point, onPointFieldChanged } = this.props;
    const { textExpanded } = this.state;

    if (!editable && !point.sourceText) {
      return;
    }

    let control;

    if (editable) {
      control = (
        <TextareaAutosize
          placeholder="Paste quote here"
          value={point.sourceText}
          onChange={(e) => {
            onPointFieldChanged('sourceText', e.target.value);
          }}
          readOnly={!editable}
          autoFocus={focusQuote}
        />
      );
    } else {
      let html;
      const MAX_CHARACTER_LIMIT = 150;
      let hasMoreText = point.sourceText.length > MAX_CHARACTER_LIMIT;

      if (hasMoreText && !textExpanded) {
        html = point.sourceText.substr(0, MAX_CHARACTER_LIMIT) + ' ... ';
      } else {
        html = point.sourceText;
      }

      control = (
        <Fragment>
          <p className="source-quote">
            {html}
            {hasMoreText ? (
              <span
                className="av-blue font-size-12 cursor-pointer"
                onClick={(e) => {
                  this.setState({ textExpanded: !textExpanded });
                  e.stopPropagation();
                }}
              >
                {textExpanded ? 'less' : 'more'}
              </span>
            ) : null}
          </p>
        </Fragment>
      );
    }

    return (
      <div className="d-flex p-3 quote">
        <div className="av-blue font-size-22 mr-3 align-self-start">“</div>
        <div className="flex-fill align-self-center">{control}</div>
      </div>
    );
  }

  renderCitation() {
    const { editable, isExtension } = this.props;
    const { showSourceDetails } = this.state;

    const {
      sourceName,
      sourceTitle,
      sourceAuthor,
      sourceURL,
      sourceDate,
      sourceTextModified,
      sourceInfoModified,
      sourceText,
      sourceLogoURL,
      showQuote,
    } = this.props.point;

    let citation = [];

    if (sourceAuthor) {
      citation.push(`${sourceAuthor} in `);
    }

    if (sourceName && sourceURL) {
      citation.push(
        <a
          href={sourceURL}
          target="_blank"
          key="sourceURL"
          onClick={(e) => e.stopPropagation()}
        >
          {sourceName}
        </a>,
      );
    } else if (sourceName) {
      citation.push(sourceName);
    }

    if (sourceLogoURL) {
      citation.push(
        <img
          src={sourceLogoURL}
          key="sourceLogoURL"
          style={{ height: '12px' }}
          className="ml-2 mr-2"
        />,
      );
    }

    if (sourceDate) {
      citation.push(`, ${sourceDate}`);
    }

    if (sourceName && sourceURL) {
      citation.push(
        <a
          href={sourceURL}
          target="_blank"
          key="sourceURLLink"
          onClick={(e) => e.stopPropagation()}
          className="position-absolute pl-3 bg-av-light-gray"
          style={{ right: 10 }}
        >
          Link <i className="fa fa-external-link pl-1" />
        </a>,
      );
    }

    // If nothing to show, have some placeholder
    if (!citation.length) {
      citation.push('Source Information');
    }

    const className = classnames('d-flex citation bg-av-light-gray', {
      'has-text': (editable && showQuote) || sourceText,
    });

    const isMissing = !sourceTitle && !sourceAuthor && !sourceDate;

    return (
      <div className={className}>
        <Icon
          icon={showSourceDetails ? ICONS.DROPDOWN_DOWN : ICONS.DROPDOWN_RIGHT}
          size={24}
          className="ml-1 mr-1 pt-1 mt-2 flex-shrink-0 toggle-expand"
          onClick={(e) => {
            this.setState({ showSourceDetails: !this.state.showSourceDetails });
            e.stopPropagation();
          }}
        />
        <div
          className="flex-fill pt-3 pb-3 pr-3 citation-text position-relative"
          style={{ minWidth: '0' }}
        >
          <div>{sourceTitle}</div>
          <div className="d-flex">
            <div className="citation-text flex-fill">{citation}</div>
            {(sourceTextModified || sourceInfoModified) && (
              <Icon
                icon={ICONS.USER_EDITED}
                className="av-grayish-cyan ml-2"
                width={16}
                height={18}
              />
            )}
          </div>
          {editable && isMissing && isExtension && (
            <div className="av-gray mt-2">
              We could not read the title, author, and date. To enter source
              info manually,{' '}
              <span
                className="av-blue"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={this.toggleShowSourceDetails}
              >
                click here
              </span>
            </div>
          )}
          {this.renderSourceDetails()}
        </div>
      </div>
    );
  }

  renderSourceLogo = () => {
    if (!this.props.point.sourceLogoURL) return null;

    return (
      <span className="source-logo">
        <img src={this.props.point.sourceLogoURL} />
      </span>
    );
  };

  renderDetailsButton = () => {
    // if (this.props.editable) return; //No button to open and close - have to save it to close

    return (
      <ToolTip
        toolTipText="See more info about the source"
        toolTipPosition="bottom"
        className="button ml-2"
      >
        <span
          className="details-button-wrapper"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {this.renderToggleDetails()}
        </span>
      </ToolTip>
    );
  };

  renderToggleDetails = () => {
    let icon;

    if (this.state.showSourceDetails) icon = <Icon icon={ICONS.CLOSE} />;
    else {
      icon = this.props.editable ? (
        <Icon icon={ICONS.PENCIL} />
      ) : (
        <Icon icon={ICONS.INFO} />
      );
    }

    return (
      <button
        onClick={this.toggleShowSourceDetails}
        className="btn btn-link btn-xs edit-source-icon"
      >
        {icon}
      </button>
    );
  };

  renderSourceDetails() {
    const { showSourceDetails } = this.state;

    const showCloseButton = !this.props.point.sourceName;

    // const showSourceDetails =
    //   this.state.showSourceDetails || this.props.openEditFields;

    const errorMessages = {
      ...this.props.errorMessages,
    };

    const className = classnames('mt-4', {
      'd-none': !showSourceDetails,
    });

    return (
      <SourceInfo
        isExtension={this.props.isExtension}
        point={this.props.point}
        editable={this.props.editable}
        onPointFieldChanged={this.props.onPointFieldChanged}
        showCloseButton={showCloseButton}
        showSourceDetails={showSourceDetails}
        toggleShowSourceDetails={this.toggleShowSourceDetails}
        errorMessages={errorMessages}
        sourceTitleError={this.props.errorMessages.sourceTitle}
        viewType={this.props.viewType}
        className={className}
      />
    );
  }

  toggleShowSourceDetails = (e) => {
    console.log(this.state.showSourceDetails);
    e.stopPropagation();
    this.setState(
      {
        showSourceDetails: !this.state.showSourceDetails,
      },
      () => {
        console.log(this.state.showSourceDetails);
        if (this.props.onToggleShowSourceDetails)
          this.props.onToggleShowSourceDetails();
        if (this.props.updateMasonry) this.props.updateMasonry();
      },
    );
  };
}

export default Source;

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './UpdateArticleDetails.scss';
import BrowserExtensionStatus from '../UpdateSourceDetails/BrowserExtensionStatus';

function ManageCourse({ source, onClose, actions }) {
  const [sourceForm, setSourceForm] = useState(source);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isAdminNote = Array.isArray(source.adminNotes) && source.adminNotes.length > 0;

  useEffect(() => {
    if (!isAdminNote) {
      let browserExtensionStatus = sourceForm.browserExtensionStatus;

      if (!Array.isArray(browserExtensionStatus)) {
        browserExtensionStatus = [
          {
            componentType: 'credibilityLayer',
          },
          {
            componentType: 'quiz',
          },
          {
            componentType: 'readTime',
          },
        ];
      }

      setSourceForm((current) => ({
        ...current,
        browserExtensionStatus,
      }));
    }
  }, []);

  const createCourse = () => {
    setLoading(true);
    const source = JSON.parse(JSON.stringify(sourceForm));

    if (!isAdminNote) {
      actions
        .updateArticle(source._id, source.browserExtensionStatus, source.comment)
        .then(() => onClose())
        .catch(() => {
          console.log('Error occurred while updating article');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      actions
        .noteToDeveloperResolved(source._id)
        .then(() => onClose())
        .catch(() => {
          console.log('Error occurred while updating article');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFormChange = (name, value) => {
    setSourceForm((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const showExtensionStatus = () => {
    return (
      <React.Fragment>
        <BrowserExtensionStatus
          source={sourceForm}
          handleFormChange={handleFormChange}
        />
        <FloatingInput
          autoFocus={true}
          value={sourceForm.comment || ''}
          labelName="Comment"
          name="comment"
          onChange={(e) => handleFormChange(e.target.name, e.target.value)}
          error={error || ''}
        />
      </React.Fragment>
    );
  };

  const showAdminNote = () => {
    return (
      <React.Fragment>
        <div className="mb-2">
          <b>Source URL:</b> {source.sourceURL}
        </div>
        <div className="mb-2">
          <b>Source Title:</b> {source.sourceTitle}
        </div>
        <div className="mb-4">
          <b>Admin note:</b> {source.adminNotes[0].note}
        </div>
      </React.Fragment>
    );
  };

  return (
    <Modal show={true} className="edit-source-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Edit Article</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {
          !isAdminNote ? showExtensionStatus() : showAdminNote()
        }
        {loading ? (
          <div className="mt-4">
            <div
              className="fa fa-spin fa-spinner text-primary"
              style={{ fontSize: '40px' }}
            />
          </div>
        ) : (
          <RectangularButton
            className="next-button"
            onClick={() => createCourse()}
          >
            {!isAdminNote ? 'Submit' : 'Resolved'}
          </RectangularButton>
        )}
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageCourse);

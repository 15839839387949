import axios from 'axios';

/*** Constants */
export const LOAD_PENDING_ACTIONS = 'LOAD_PENDING_ACTIONS';
export const RESOLVE_PENDING_ACTION = 'RESOLVE_PENDING_ACTION';

export function loadPendingActions(pendingActions) {
  return {
    type: LOAD_PENDING_ACTIONS,
    pendingActions,
  };
}

export function getPendingActions(entityId, entityType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/pendingActions/getPendingActions',
        params: {
          entityId,
          entityType,
        },
      })
        .then((response) => {
          let data = response.data;
          dispatch(loadPendingActions(data.pendingActions));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching pending actions');
          console.log(error);
          resolve();
        });
    });
  };
}

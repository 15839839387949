import * as React from "react";

export default class RespondInviteCollectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="desc">
          Accept or Reject invitation to give feedback on this Collection.
        </div>
      </div>
    );
  }
}

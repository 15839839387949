import React from 'react';
import { Popover, Overlay, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, ICONS } from 'components/Icons';
import * as Actions from '../../../../redux/actions/index';
import './PopoverCard.scss';

class PopoverCard extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    popover: PropTypes.object,
  };

  hidePopover = () => {
    this.props.actions.hidePopover();
  };

  renderActionLinks = () => {
    const { actionLinks } = this.props.popover;
    return (
      <div className="d-flex flex-row flex-wrap mt-2">
        {actionLinks.map((actionLink) => {
          const { title, url } = actionLink;
          return (
            <a className="popover-action-link mr-3" target="_blank" href={url}>
              {title}
            </a>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      show,
      placement,
      title,
      description,
      icon,
      target,
      imageUrl,
      videoUrl,
      actionLinks,
      isHTML,
    } = this.props.popover;

    if (show) {
      return (
        <Overlay
          show={show}
          target={target.current}
          placement={placement || 'bottom'}
          container={target.current}
        >
          <Popover className="popover">
            <div className="popover-data d-flex flex-row align-items-center">
              {icon && (
                <div className="align-self-start mt-1 mr-3">
                  <Icon icon={icon} size={16} className="popover-icon" />
                </div>
              )}
              <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex flex-row align-items-center justify-content-end">
                  {title && (
                    <div className="flex-fill popover-main-title mr-3">
                      {title}
                    </div>
                  )}
                  <button
                    type="button"
                    className="close-btn"
                    onClick={this.hidePopover}
                  >
                    <Icon icon={ICONS.CLOSE} size={10} className="close-icon" />
                  </button>
                </div>
                {description && !isHTML && (
                  <div className="popover-main-description mt-2">
                    {description}
                  </div>
                )}
                {description && isHTML && (
                  <div
                    className="popover-main-description mt-2"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
                {imageUrl && (
                  <Image src={imageUrl} responsive className="pt-3 pb-3" />
                )}
                {videoUrl && (
                  <ReactPlayer
                    url={videoUrl}
                    className="pt-3 pb-3 react-player"
                    width="100%"
                    height="100%"
                  />
                )}
                {actionLinks &&
                  actionLinks.length > 0 &&
                  this.renderActionLinks()}
              </div>
            </div>
          </Popover>
        </Overlay>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    popover: state.popover,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PopoverCard);

import axios from 'axios';

export function getAppVariables() {
  return (dispatch, getState) => {
    console.log('getAppVariables called ');

    return axios.get('/api/utility/getAppVariables').then(response => {
      console.log('getAppVariables responded successfully');
      //console.log(response);
      const data = response.data;
      dispatch({
        type: 'APP_VARIABLES',
        payload: data,
      });
      return data.appVariables;
    });
  };
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FloatingTextarea from 'components/elements/atoms/FloatingTextarea/FloatingTextarea';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageResearchPrompts.scss';

function ManageResearchPrompts({
  courseId,
  topicId,
  researchPrompt = {},
  onClose,
  actions,
}) {
  const [text, setText] = useState(researchPrompt.text || '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [text]);

  const createResearchPrompt = () => {
    if (researchPrompt._id) {
      actions
        .updateResearchPrompt({
          courseId,
          topicId,
          promptId: researchPrompt._id,
          text,
        })
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while updating Research Prompt. Please try again.',
          );
        });
    } else {
      actions
        .createResearchPrompt({
          courseId,
          topicId,
          text,
        })
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
            'Error occurred while adding Research Prompt. Please try again.',
          );
        });
    }
  };

  return (
    <Modal show={true} className="manage-research-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>{researchPrompt._id ? 'Edit' : 'Create'} Research Prompt</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingTextarea
          autoFocus={true}
          value={text}
          labelName="Prompt text"
          onChange={(e) => setText(e.target.value)}
          error={error || ''}
          maxRows={3}
        />
        <RectangularButton
          className="next-button"
          disabled={!text}
          onClick={() => createResearchPrompt()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageResearchPrompts);

import * as React from "react";
import PropTypes from "prop-types";
import { Swipeable } from "react-swipeable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { scroller } from "react-scroll";
import * as Actions from "redux/actions/index";
import { gotoNextStepAsync } from "redux/actions/walkthrough";
import PointPageComponent from "./../PointPageComponent";
import LandingCopy from "routes/Collection/Component/LandingCopy/LandingCopy";

import "./PointPageContainer.scss";

const URLSearchParams = require("url-search-params");

class PointPageContainer extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    pointObject: PropTypes.object,
    point: PropTypes.object,
    actions: PropTypes.object,
    parentObject: PropTypes.object,
    isModal: PropTypes.bool,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    pointId: PropTypes.string,
    walkthrough: PropTypes.object,
    openWalkthroughModal: PropTypes.func,
    showReviewOnOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    // Redirect if invited and not logged in
    if (this.props.location) {
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const invited = params.get("invite");
      if (invited && !this.props.authenticated) {
        // location - send back with success page
        let parentInfo = null;
        if (this.props.location.state)
          parentInfo = this.props.location.state.parentInfo;

        const location = {
          pathname: "/login",
          state: {
            nextPathname: this.props.location.pathname,
            nextSearch: this.props.location.search,
            nextParentInfo: parentInfo,
          },
        };
        this.props.dispatch(push(location));
      }
    }

    this.state = {};
  }

  componentDidMount() {
    // Get parent info to load Point from Collection
    let parentType = null;
    let parentId = null;
    let parentObject = null;

    if (this.props.parentObject) parentObject = this.props.parentObject;

    if (parentObject) {
      parentType = parentObject.type;
      parentId = parentObject.id;
    }

    // Always re-load the Point after first mounting to get latest Point
    this.props.actions.getPoint(this.props.pointId, parentType, parentId);

    // Walkthrough steps
    const { tourStatus, tourStep } = this.props.walkthrough;
    if (tourStatus === "inProgress" && tourStep === 9) {
      this.props.openWalkthroughModal();
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.location) {
      if (this.props.location.pathname != prevProps.location.pathname) {
        const search = this.props.location && this.props.location.search;
        const params = new URLSearchParams(search);
        const invited = params.get("invite");
        if (invited && !this.props.authenticated) {
          const location = {
            pathname: "/",
            state: {
              nextPathname: this.props.location.pathname,
              nextSearch: this.props.location.search,
            },
          };
          this.props.dispatch(push(location));
        }
      }
    }

    // Get parent info to load Point from Collection
    let parentType = null;
    let parentId = null;
    let parentObject = null;

    if (this.props.parentObject) parentObject = this.props.parentObject;

    if (parentObject) {
      parentType = parentObject.type;
      parentId = parentObject.id;
    }

    if (this.props.authenticated != prevProps.authenticated) {
      this.props.actions.getPoint(this.props.pointId, parentType, parentId);
    }

    if (this.props.pointId != prevProps.pointId) {
      this.props.actions.getPoint(this.props.pointId, parentType, parentId);
    }

    if (this.props.walkthrough.currentStep === 10) {
      setTimeout(() => {
        scroller.scrollTo("evidence", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }, 300);
    }
  }

  renderViewComponent() {
    const { pointObject, pointDetailView, onFourPackAction } = this.props;

    if (!pointObject) {
      return <div className="loading-message">Loading Point...</div>;
    }

    if (this.props.pointObject.errorMessage) {
      return (
        <div className="loading-message">
          <br />
          <br />
          {this.props.pointObject.errorMessage}
        </div>
      );
    }

    return (
      <Swipeable
        preventDefaultTouchmoveEvent
        delta={120}
        onSwiped={this.handleSwipe}
      >
        <PointPageComponent
          actions={this.props.actions}
          user={this.props.user}
          authenticated={this.props.authenticated}
          pointObject={pointObject}
          point={pointObject.point}
          parentObject={this.props.parentObject}
          user={this.props.user}
          isModal={this.props.isModal}
          showReviewOnOpen={this.props.showReviewOnOpen}
          respondInvitePoint={respond =>
            this.props.actions.respondInvitePoint(pointObject.point, respond)
          }
          pointDetailView={pointDetailView}
          onFourPackAction={onFourPackAction}
        />
      </Swipeable>
    );
  }

  // handleSwipe = ({ dir }) => {
  //   if (dir === 'Right') {
  //     this.onArrowClicked('previous');
  //   } else if (dir === 'Left') {
  //     this.onArrowClicked('next');
  //   }
  // };

  renderLandingCopy() {
    const { user } = this.props;

    if (user._id) {
      return null;
    }

    return <LandingCopy />;
  }

  render() {
    return (
      <div
        className="point-page-container container pt-4"
        style={{ maxWidth: "717px" }}
      >
        {this.renderViewComponent()}
        {this.renderLandingCopy()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;
  const { parentStack } = state.page;
  let parentObject;
  if (parentStack.length > 0)
    parentObject = parentStack[parentStack.length - 1];

  let pointId;

  if (ownProps.match && ownProps.match.params && ownProps.match.params.id) {
    pointId = ownProps.match.params.id;
  } else {
    pointId = ownProps.point._id;
  }

  const pointObject = state.points[pointId];

  return {
    pointId,
    pointObject,
    authenticated,
    user,
    parentObject,
    walkthrough: state.walkthrough,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: { title, message },
        onResponse,
      };
      dispatch(Actions.openModal("confirm", props));
    },
    setParent: parentObject => {
      dispatch(Actions.setParent(parentObject));
    },
    setParentIndex: index => {
      dispatch(Actions.setParentIndex(index));
    },
    dispatch,
    gotoNextStepAsync: step => dispatch(gotoNextStepAsync(step)),
    openWalkthroughModal: () => {
      const props = {
        model: {
          title: "Welcome to your first Point!",
        },
      };
      dispatch(Actions.openModal("walkthrough-modal", props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PointPageContainer);

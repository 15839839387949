import React, { Fragment, useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as Actions from 'redux/actions/index';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import CTPCard from './CTPCard';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import CommentPointForm from 'routes/Collection/Component/CollectionDetails/CommentPoint/CommentPointForm';

function CommentTab({
  point,
  user,
  actions,
  ctps,
  selectedCaptions,
  showCommentForm = false,
  commentId,
  collection,
  collectionPoint,
  isMock,
  captions,
  rid,
  courseId,
  priorityUsers,
  teacherMode,
  studentMode,
  topicId,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_CTP':
          return {
            ...state,
            showCTPForm: action.payload.show,
            updatingCTP: action.payload.ctp,
          };
        case 'TOGGLE_SHOW_HIDE_ALL':
          return {
            ...state,
            showAll: !state.showAll,
          };
        case 'SET_TOPIC_USER_SUMMARY':
          return {
            ...state,
            topicUserSummary: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showCommentForm,
      // showAll: !qtpId,
      showAll: !!priorityUsers,
      commentId,
    },
  );

  useEffect(() => {
    // load point
    if (point._id && !ctps) {
      actions.getCTPsForPoint(point._id);
    }
  }, [point._id]);

  function isStudentNotInteracted() {
    return (
      !teacherMode &&
      studentMode &&
      (!state.topicUserSummary ||
        state.topicUserSummary.comments + state.topicUserSummary.comments === 0)
    );
  }

  function onUpdate(comment) {
    dispatch({ type: 'SHOW_COMMENT', payload: { show: true, comment } });
  }

  function renderCTPForm() {
    return (
      <CommentPointForm
        className="pt-2 pb-2"
        point={point}
        close={(isQTPAdded) => {
          dispatch({ type: 'SHOW_CTP', payload: { show: false } });

          // if (isQTPAdded && onEvent) {
          //   onEvent('QTP_TAB_QTP_ADDED');
          // }
        }}
        selectedCaptions={selectedCaptions}
        ctp={state.updatingCTP}
        rid={rid}
        collectionPoint={collectionPoint}
        isMock={isMock}
        captions={captions}
        courseId={courseId}
      />
    );
  }

  function renderLoading() {
    if (ctps || !point._id) {
      return null;
    }

    return (
      <div
        className="fa fa-spin fa-spinner text-primary"
        style={{ fontSize: '14px' }}
      />
    );
  }

  function renderCommentPointButton() {
    if (!ctps && point._id) {
      return null;
    }

    if (teacherMode) {
      return null;
    }

    // if (comments) {
    //   if (rid) {
    //     if (qtps.findIndex((i) => i.surveyResponse.rid === rid) !== -1) {
    //       return null;
    //     }
    //   } else if (qtps.findIndex((i) => i.userId === user.user._id) !== -1) {
    //     return null;
    //   }
    // }

    const btn = (
      <RectangularButton
        className="w-100 mb-3 big-button"
        iconName="CHAT"
        iconSize={24}
        onClick={() => {
          dispatch({ type: 'SHOW_CTP', payload: { show: true } });
        }}
        disabled={!user.user._id}
      >
        Comment on this Point
      </RectangularButton>
    );

    if (user.user._id) {
      return btn;
    } else {
      return (
        <ToolTip
          toolTipText="Please login to QTP"
          toolTipPosition="bottom"
          className="w-100"
        >
          {btn}
        </ToolTip>
      );
    }
  }

  function renderStudentNoCommentMessage() {
    let text;

    if (teacherMode && ctps && !ctps.length) {
      text = 'Students have not Commented this Point.';
    }

    if (teacherMode && ctps && ctps.length && priorityUsers) {
      const filterCTPs = ctps.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );

      if (!filterCTPs.length) {
        if (priorityUsers.length === 1) {
          text = `${priorityUsers[0].username} hasn't Commented this Point.`;
        } else if (priorityUsers.length === 0) {
          text = 'The selected students have not Commented this Point.';
        }
      }
    }

    if (isStudentNotInteracted() && state.topicUserSummary) {
      text =
        'You need to question or comment at least one claim to view the others.';
    }

    if (text) {
      return (
        <div className="text-center font-italic font-size-12 av-gray">
          {text}
        </div>
      );
    }

    return null;
  }

  function renderCard(ctp) {
    const className = classnames('mt-3 mb-3', {});
    return (
      <CTPCard
        ctp={ctp}
        className={className}
        key={ctp._id}
        onUpdate={onUpdate}
        point={point}
        collection={collection}
        rid={rid}
        courseId={courseId}
        teacherMode={teacherMode}
      />
    );
  }

  function renderMyCard() {
    if (!ctps || !ctps.length) {
      return null;
    }

    const myCTPs = ctps.filter((i) => i.userId === user.user._id);

    if (!myCTPs.length) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    return myCTPs.map(renderCard);
  }

  function renderShowHideToggleButton() {
    if (!ctps || !ctps.length) {
      return null;
    }

    let otherCTPs = ctps.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      otherCTPs = otherCTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }

    if (!otherCTPs.length) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let text;

    if (state.showAll) {
      text = 'Hide Comments';
    } else {
      if (otherCTPs.length === 1) {
        text = 'See 1 Comment';
      } else if (otherCTPs.length === 2) {
        text = 'See both Comments';
      } else {
        text = `See all ${otherCTPs.length} Comments`;
      }
    }

    return (
      <div className="">
        <ActionLink
          linkClassName="btn-show-hide-toggle"
          onClick={() => dispatch({ type: 'TOGGLE_SHOW_HIDE_ALL' })}
        >
          {text}
        </ActionLink>
      </div>
    );
  }

  function renderOtherCards() {
    if (!ctps || !ctps.length || !state.showAll) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let sortedCTPs;

    if (rid) {
      sortedCTPs = ctps.sort((i) => (i.surveyResponse.rid === rid ? -1 : 1));
      sortedCTPs = sortedCTPs.slice(0, 5);
    } else {
      // sortedQTPs = qtps.sort((i) => (i.userId === user.user._id ? -1 : 1));
      sortedCTPs = ctps;
    }

    sortedCTPs = sortedCTPs.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      sortedCTPs = sortedCTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }

    return sortedCTPs.map((comment) => {
      return renderCard(comment);
    });
  }

  function renderContent() {
    if (state.showCTPForm) {
      return renderCTPForm();
    } else {
      return (
        <Fragment>
          {renderLoading()}
          {renderCommentPointButton()}
          {renderStudentNoCommentMessage()}
          {renderMyCard()}
          {renderShowHideToggleButton()}
          {renderOtherCards()}
        </Fragment>
      );
    }
  }

  return <div className="">{renderContent()}</div>;
}

function mapStateToProps(state, props) {
  let ctps;

  if (props.point._id) {
    ctps = state.points[props.point._id].ctps;
  }

  return {
    user: state.user,
    ctps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentTab);

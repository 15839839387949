import React, { useReducer, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import SearchItems from 'components/elements/molecules/SearchItems/SearchItems';

import './QTPRTPGroupsSelection.scss';

function QTPRTPGroupsSelection({
  type,
  close,
  topGroups,
  groups,
  collectionPoint,
  actions,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_GROUPS_SELECTION':
          return { ...state, showGroupsSelection: true };
        default:
          return state;
      }
    },
    {
      showGroupsSelection:
        !collectionPoint || collectionPoint.groups.length === 0,
    },
  );

  async function onNotify(groupIds) {
    actions.pointQTPRTPGroup({ groupIds, type });
    close();
  }

  function renderReviewAdded() {
    return (
      <Fragment>
        <div className="p-3">
          <div className="av-blue font-weight-bold d-flex align-items-center">
            <Icon icon={ICONS.CHECK} className="mr-3" />{' '}
            {type === 'QTP' ? 'Question Added' : 'Review Added'}
          </div>
          <div className="mt-3">
            We'll send your {type === 'QTP' ? 'question' : 'review'} to the{' '}
            <u className="av-blue">{collectionPoint.groups[0].name}</u> group.
            Members should respond within 24 hours.
          </div>
          <div className="mt-3">
            <RectangularButton className="btn-sounds-good" onClick={close}>
              Sounds Good
            </RectangularButton>
          </div>
        </div>
        <div className="qtp-rtp-group-selection-footer p-3">
          <ActionLink
            label="Should other groups on AverPoint check out this claim?"
            linkClassName="av-blue"
            onClick={() => dispatch({ type: 'SHOW_GROUPS_SELECTION' })}
          />
        </div>
      </Fragment>
    );
  }

  function renderSelectGroups() {
    return (
      <ReviewPointFormSelectGroups
        topGroups={topGroups}
        groups={groups}
        onNotify={onNotify}
      />
    );
  }

  return (
    <div className="qtp-rtp-groups-selection">
      {state.showGroupsSelection ? renderSelectGroups() : renderReviewAdded()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    topGroups: state.app.topGroups,
    groups: state.groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QTPRTPGroupsSelection);

function ReviewPointFormSelectGroups({ topGroups, groups, onNotify }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'TOGGLE_SELECT_GROUP': {
          const index = state.selectedGroups.indexOf(action.payload);

          if (index === -1) {
            return {
              ...state,
              selectedGroups: [...state.selectedGroups, action.payload],
            };
          } else {
            return {
              ...state,
              selectedGroups: [
                ...state.selectedGroups.slice(0, index),
                ...state.selectedGroups.slice(index + 1),
              ],
            };
          }
        }
        case 'SET_SEARCH_TERM':
          return {
            ...state,
            searchTerm: action.payload,
          };
        default:
          return state;
      }
    },
    {
      selectedGroups: [],
      searchTerm: '',
    },
  );
  function onToggleGroup(groupId) {
    dispatch({ type: 'TOGGLE_SELECT_GROUP', payload: groupId });
  }

  function renderTopGroup(group) {
    const className = classnames('btn-group', {
      selected: state.selectedGroups.indexOf(group.id) !== -1,
    });
    return (
      <RectangularButton
        key={group.id}
        className={className}
        onClick={() =>
          // dispatch({ type: 'TOGGLE_SELECT_GROUP', payload: group.id })
          onToggleGroup(group.id)
        }
      >
        {group.name}
      </RectangularButton>
    );
  }

  function renderTopGroups() {
    return <div className="mt-4">{topGroups.map(renderTopGroup)}</div>;
  }

  function renderGroup(group) {
    const className = classnames('group', {
      selected: state.selectedGroups.indexOf(group._id) !== -1,
    });
    return (
      <div
        className={className}
        onClick={() => onToggleGroup(group._id)}
        key={group._id}
      >
        {group.name}
      </div>
    );
  }

  function renderGroups() {
    return (
      <div className="groups-container mt-2">
        {groups.mine
          .map((groupId) => groups.entities[groupId])
          .filter((group) =>
            group.name
              ? group.name
                  .toLowerCase()
                  .indexOf(state.searchTerm.toLowerCase()) !== -1
              : true,
          )
          .map((group) => renderGroup(group))}
      </div>
    );
  }

  function renderSearchInput() {
    return (
      <div className="mt-3">
        <SearchItems
          placeholder="Search Groups"
          searchOnEnter={(searchTerm) =>
            dispatch({ type: 'SET_SEARCH_TERM', payload: searchTerm })
          }
        />
        {renderGroups()}
      </div>
    );
  }

  function renderNotifiyBtn() {
    return (
      <RectangularButton
        className="ml-auto btn-notify"
        disabled={!state.selectedGroups.length}
        onClick={() => onNotify(state.selectedGroups)}
      >
        Notify <Icon icon={ICONS.ARROW_FORWARD} className="ml-3" />
      </RectangularButton>
    );
  }

  function renderFooter() {
    return (
      <div className="qtp-rtp-group-selection-footer p-3 d-flex mt-3">
        {renderNotifiyBtn()}
      </div>
    );
  }

  return (
    <div className="p-3">
      <div className="av-blue font-weight-bold d-flex align-items-center">
        What other Groups should we notify?
      </div>
      {renderTopGroups()}
      <div className="mt-3 av-blue">
        Not related to any of the above? Search for other groups on AverPoint:
      </div>
      {renderSearchInput()}
      {renderFooter()}
    </div>
  );
}

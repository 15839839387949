import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from '../../Icons';

import './DeleteConfirmationModal.scss';

function DeleteConfirmationModal({
  deleteFunction,
  confirmationMessage,
  onClose,
  forceDeletion,
  title,
  buttonText,
}) {
  const [forceDelete, setForceDelete] = useState(forceDeletion || false);
  const [loading, setLoading] = useState(false);

  const confirmDelete = () => {
    setLoading(true);

    deleteFunction(forceDelete)
      .then((response) => {
        if (response.success || forceDelete) {
          return onClose();
        }

        return setForceDelete(true);
      })
      .catch((error) => {
        console.error('Error while deleting', error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={true} className="delete-confirmation-modal">
      <Modal.Body>
        <div className="header">
          <span>{title || 'Delete Confirmation'}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="my-3">
          {forceDelete
            ? confirmationMessage
            : 'Are you sure you want to delete?'}
        </div>
        <div>
          {loading ? (
            <div className="mt-3">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '40px' }}
              />
            </div>
          ) : (
            <RectangularButton
              className="next-button mt-3 red"
              onClick={() => confirmDelete()}
            >
              {buttonText || 'Delete'}
            </RectangularButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps() {
  return {};
}

export default connect(null, mapDispatchToProps)(DeleteConfirmationModal);

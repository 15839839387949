import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import Chrome from 'assets/images/chrome.svg';
import Mail from 'assets/images/mailIcon.svg';
import Exit from 'assets/images/exitIcon.svg';
import Media from 'assets/images/mediaIcon.svg';

import './LogReadingModal.scss';

export default function LogReadingModal({ onClose }) {
  return (
    <Modal show={true} className="log-reading-modal">
      <Modal.Body>
        <div className="header">
          <span>How to log your reading</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="text">
          This is your reading analytics page, helping you understand what news
          you've consumed.
        </div>
        <div className="text">You can log articles in 4 ways:</div>
        <div className="help-row">
          <img src={Chrome} />
          <span>Use our Chrome Extension.</span>
          <a
            href="https://chrome.google.com/webstore/detail/averpoint/delinpnhfdkcjeifmapikhinioghdeia"
            className="link"
            target="_blank"
          >
            Go to Chrome store
          </a>
        </div>
        <div className="help-row">
          <img src={Mail} />
          <span>
            Email the link to an article to{' '}
            <a href="mailto:save@averpoint.com" className="emailLink">
              save@averpoint.com.
            </a>
          </span>
          {/* <span className="link">Show me how</span> */}
        </div>
        <div className="help-row">
          <img src={Exit} />
          <span>Share the article to AverPoint app.</span>
          {/* <span className="link">Show me how</span> */}
        </div>
        <div className="help-row">
          <img src={Media} />
          <span>
            Click an article from Channel page in webapp or mobile app.
          </span>
          {/* <span className="link">Show me how</span> */}
        </div>
        {/* <div className="text">
          <span className="link">Click here</span> to see the list of approved
          sources
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

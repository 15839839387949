import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';

// import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './QuizQuestions.scss';
import { ICONS, Icon } from 'components/Icons';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { handleEvent } from '../../CoursesTab/helpers/helpers';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

function QuizQuestions({
  question,
  updateQuestion,
  user,
  actions,
  onNext,
  onPrevious,
  readOnly = false,
  articleMediaCollectionId,
  // courseId,
  topicId,
  quizQuestionIndex,
  pendingQuestions,
  onFeedback,
  isChannelPageQuiz,
}) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [rightAnswers, setRightAnswers] = useState(question.rightAnswers || []);
  const [loading, setLoading] = useState(false);

  const isEmptyUser = useMemo(() => {
    return typeof user === 'object' && Object.keys(user).length === 0;
  }, [user]);

  useEffect(() => {
    if (selectedAnswers.length === 0) {
      if (question.answers) {
        setSelectedAnswers(question.answers);
      } else {
        setSelectedAnswers(
          question.userAnswer ? question.userAnswer.answersIndex : [],
        );
      }
    }

    setRightAnswers(question.rightAnswers || []);
  }, [question]);

  useEffect(() => {
    if (updateQuestion && question && selectedAnswers) {
      const questionAnswers = Array.isArray(question.answers)
        ? question.answers
        : ((question.userAnswer && Array.isArray(question.userAnswer.answersIndex) && question.userAnswer.answersIndex) || []);

      if (questionAnswers.join(',') !== selectedAnswers.join(',')) {
        updateQuestion({
          ...question,
          userAnswer: {
            ...(question.userAnswer || {}),
            answersIndex: selectedAnswers,
          },
        });
      }
    }
  }, [question, selectedAnswers]);

  const correctAnswer =
    rightAnswers.length && _.isEqual(rightAnswers, selectedAnswers);

  const selectAnswer = (answerIndex, type) => {
    if (rightAnswers.length > 0 || readOnly) {
      return;
    }

    if (type === 'SINGLE_CHOICE') {
      return setSelectedAnswers([answerIndex]);
    }

    if (selectedAnswers.includes(answerIndex)) {
      setSelectedAnswers((prevState) =>
        prevState.filter((s) => s !== answerIndex),
      );
    } else {
      setSelectedAnswers((prevState) => prevState.concat([answerIndex]));
    }
  };

  const confirmAnswer = (questionId, answers) => {
    if (readOnly) {
      if (onNext) {
        onNext();
      }

      return;
    }

    handleEvent('QUIZ_QUESTION_ANSWERED', {
      questionId,
      answers,
      questionText: question.text,
      articleMediaCollectionId,
      topicId,
    });

    setLoading(true);

    // if (articleMediaCollectionId && courseId && topicId) {
    actions
      .checkQuizAnswer(questionId, answers)
      .then(
        ({
          success,
          rightAnswerIndexes,
          isCorrect,
          requiresManualVerification,
        }) => {
          setRightAnswers(rightAnswerIndexes);
          setLoading(false);
          question.isCorrect = isCorrect;
          question.answers = answers;
          question.rightAnswerIndexes = rightAnswerIndexes;
          question.requiresManualVerification = requiresManualVerification;
        },
      )
      .catch((error) => {
        console.error('Error while checking answer', error);
        setLoading(false);
      })
      .finally(() => {
        if (onNext) {
          onNext();
        }
      });
  };

  const styleOption = (index) => {
    const classes = [];
    if (
      readOnly &&
      question.userAnswer &&
      question.userAnswer.answersIndex.includes(index)
    ) {
      classes.push(question.userAnswer.isCorrect ? 'correct' : 'wrong');
    }

    if (
      readOnly &&
      question.correctAnswer &&
      question.correctAnswer.includes(index)
    ) {
      classes.push('correct');
    }

    if (selectedAnswers.includes(index)) {
      classes.push('selected');
    }

    return classes.join(' ');
  };

  function renderQuestionText() {
    return <div className="question-text">{question.text}</div>;
  }

  function renderUserSignInFeedback() {
    if (!isEmptyUser) {
      return null;
    }

    return (
      <div className="question-feedback wrong">
        <span>You must be signed in to take the quiz.</span>
      </div>
    );
  }

  function renderQuestionCorrectFeedback() {
    return (
      <div className="question-feedback correct">
        {rightAnswers.length !== 0 && correctAnswer && (
          <span>Answer was correct</span>
        )}
      </div>
    );
  }

  function renderQuestionWrongFeedback() {
    return (
      <div className="question-feedback wrong">
        {rightAnswers.length !== 0 && !correctAnswer && (
          <span>Answer was incorrect</span>
        )}
      </div>
    );
  }

  function renderAnswerStatus(index) {
    if (
      !readOnly ||
      !question.userAnswer ||
      question.userAnswer.answersIndex[0] !== index
    ) {
      return null;
    }

    // if (question.userAnswer.isCorrect) {
    //   return <Icon icon={ICONS.CHECK} className="ml-auto flex-shrink-0" />;
    // } else {
    //   return <Icon icon={ICONS.CLOSE} className="ml-auto flex-shrink-0" />;
    // }
    return null;
  }

  function renderOptions() {
    if (
      question.type !== 'SINGLE_CHOICE' &&
      question.type === 'MUTLIPLE_CHOICE'
    ) {
      return null;
    }

    return (
      <div className="question-options">
        {question.options.map((option, index) => (
          <div
            className={`d-flex align-items-center question-option ${styleOption(
              index,
            )}`}
            onClick={() => selectAnswer(index, question.type)}
            key={index}
          >
            {/* <div className={`option-number ${styleOption(index + 1)}`}>
              {index + 1}
            </div> */}
            {option.text}
            {renderAnswerStatus(index)}
          </div>
        ))}
      </div>
    );
  }

  function renderTextInput() {
    if (question.type !== 'SHORT_ANSWER') {
      return null;
    }

    return (
      <textarea
        type="text"
        className="form-control question-text-input"
        placeholder="Type here"
        value={selectedAnswers[0] || ''}
        onChange={(event) => {
          const answers = event.target.value ? [event.target.value] : [];
          setSelectedAnswers(answers);
        }}
        disabled={readOnly}
      ></textarea>
    );
  }

  function renderBack() {
    if (quizQuestionIndex === 0) {
      return null;
    }

    return (
      <ActionLink
        linkClassName="av-blue mr-3"
        onClick={() => {
          onPrevious();
        }}
      >
        {/* Confirm */}
        <Icon icon={ICONS.ARROW_BACKWARD} />
      </ActionLink>
    );
  }

  function renderConfirm() {
    // if (quizQuestionIndex + 1 === pendingQuestions.length) {
    //   return null;
    // }

    return (
      <ActionLink
        linkClassName="av-blue"
        onClick={() => {
          confirmAnswer(question._id, selectedAnswers);
        }}
        disabled={isEmptyUser || !selectedAnswers.length || rightAnswers.length}
        disabledToolTipText="Please answer"
      >
        {/* Confirm */}
        <Icon icon={ICONS.ARROW_FORWARD} />
      </ActionLink>
    );
  }

  function renderFeedback() {
    if (!readOnly || !onFeedback) {
      return null;
    }

    return (
      <div className="d-flex mt-2">
        <ActionLink
          onClick={async () => {
            onFeedback();
          }}
          linkClassName="av-blue ml-auto"
          label="Feedback"
        />
      </div>
    );
  }

  function renderFooter() {
    if (isChannelPageQuiz) {
      return null;
    }

    if (!onPrevious || !onNext) {
      return null;
    }

    return (
      <div
        className="d-flex align-items-center justify-content-between my-4"
        style={{ overflow: 'hidden' }}
      >
        <div className="quiz-question-count">
          {quizQuestionIndex + 1} of {pendingQuestions.length}
        </div>
        <div className="text-right d-flex align-items-center">
          {renderBack()}
          {loading ? (
            <div className="text-center">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '20px' }}
              />
            </div>
          ) : (
            renderConfirm()
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-question">
      {renderQuestionText()}
      {renderUserSignInFeedback()}
      {renderQuestionCorrectFeedback()}
      {renderQuestionWrongFeedback()}
      {renderOptions()}
      {renderTextInput()}
      {renderFeedback()}
      {renderFooter()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestions);

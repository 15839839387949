import React from 'react';

import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';
import QuestionText from './QuestionText';

export default function InputCheckboxQuestion({ question, onChange, model }) {
  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderInput() {
    const checked = model[question.variable];

    return (
      <Checkbox
        onClick={e => {
          e.preventDefault();
          onChange(!checked);
        }}
        onChange={() => {}}
        checked={checked}
      >
        {question.label}
      </Checkbox>
    );
  }

  return (
    <React.Fragment>
      {renderText()}
      {renderInput()}
    </React.Fragment>
  );
}

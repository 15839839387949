import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ClubhouseSignup.scss';

function ClubhouseSignup({ onClose, actions }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [email]);

  const clubhouseSignup = () => {
    actions
      .clubhouseSignup({ email })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  return (
    <Modal show={true} className="clubhouse-signup-modal">
      <Modal.Body>
        <div className="header">
          <span>Sign Up</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="my-3">
          Enter your email, and we'll send you a sign up link for AverPoint.
        </div>
        <FloatingInput
          autoFocus={true}
          value={email}
          labelName="Email"
          onChange={(e) => setEmail(e.target.value)}
          error={error || ''}
        />
        <RectangularButton
          className="next-button"
          disabled={
            !email ||
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
          }
          onClick={() => clubhouseSignup()}
        >
          Sign Up
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ClubhouseSignup);

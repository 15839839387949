import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";

import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from "../../Icons";

import "./VerticalModal.scss";

class VerticalModal extends Component {
    static propTypes = {};
  
    constructor(props) {
      super(props);
  
      this.state = {};
    }

    render() {
        const { model, step, onClose, walkthrough } = this.props;

        const { vertical } = model;
    
        // const title = model.title;
        const title = "Welcome to AverPoint!";

        const showModal = true;
    
        return (
          <Modal
            show={showModal}
            className="vertical-modal"
            backdropClassName="vertical-modal-backdrop"
          >
            <Modal.Header bsClass="vertical-modal-header">
              <Button bsStyle="link" className="btn-close" onClick={onClose}>
                <Icon icon={ICONS.CLOSE} />
              </Button>
              <Modal.Title bsClass="vertical-modal-title pl-10">{title}</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-content">
                        Thanks for joining our project to improve the {vertical} debate!
                        <br/>
                        <br/>
                        We're currently developing videos, podcasts, and articles that use research collections to earn your trust. We'll email you as those are ready. Until then, you can
                        <br/>
                        <br/>
                        <div className="options d-flex">
                            {/*<div className="option">
                                <Icon icon={ICONS.SEARCH} size="60"/>
                                Browse AverPoint research by a climate expert.
                            </div>
                            <div className="option">
                                <Icon icon={ICONS.ADD} size="60"/>
                                Create my own research collections.
                            </div>
                             <div className="option">
                                <Icon icon={ICONS.LOGO_NO_TEXT} size="60"/>
                                Take a tour of AverPoint
                            </div> */}
                            <RectangularButton
                                iconName="SEARCH"
                                className="option"
                                iconSize={60}
                                onClick={()=>this.goToPage("https://www.averpoint.com/collection/5de689d932582800167428f8?embed=false")}
                            >
                                Browse AverPoint research by a climate expert.
                            </RectangularButton>
                            <RectangularButton
                                iconName="ADD"
                                className="option"
                                iconSize={60}
                                onClick={onClose}
                            >
                                Create my own research collections.
                            </RectangularButton>
                            <RectangularButton
                                iconName="LOGO_NO_TEXT"
                                className="option"
                                iconSize={60}
                                onClick={onClose}
                            >
                                Take a tour of AverPoint
                            </RectangularButton>
                        <br/>
                        <br/>
                        </div>

                        </div>
                    </div>
                </div>
                </div>
            
            </Modal.Body>
          </Modal>
        );
      }
      goToPage(URL) {
        //this.props.history.push(path);
        console.log("Got to go to page");
        window.open(URL, '_blank');
      }
    }
    
    export default withRouter(
        connect(
          null,
          { push }
        )(VerticalModal)
      );
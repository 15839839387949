import createAction from "../createAction";
// import * as groupSvc from 'services/groups.svc';
import axios from "axios";
import { resolved, rejected } from "../createAction";

const make = createAction("groups");

/** * Constants */
export const GET_FEATURED_GROUPS = make("GET_FEATURED_GROUPS");
export const GET_MY_GROUPS = make("GET_MY_GROUPS");
export const SAVE_GROUP = make("SAVE_GROUP");
export const DELETE_GROUP = make("DELETE_GROUP");
export const GET_GROUP = make("GET_GROUP");
export const INVITE_LEADER = make("INVITE_LEADER");
export const INVITE_MEMBER = make("INVITE_MEMBER");
export const RESPOND_INVITE_GROUP = make("RESPOND_INVITE_GROUP");
export const JOIN_PUB_GROUP = make("JOIN_PUB_GROUP");
export const LEAVE_GROUP = make("LEAVE_GROUP");
export const REMOVE_GROUP_MEMBER = make("REMOVE_GROUP_MEMBER");
export const REMOVE_GROUP_LEADER = make("REMOVE_GROUP_LEADER");
export const REQUEST_ACCESS = make("REQUEST_ACCESS");
export const GET_GROUP_COLLECTIONS = make("GET_GROUP_COLLECTIONS");
export const GET_ANNOUNCEMENTS = make("GET_ANNOUNCEMENTS");
export const CREATE_ANNOUNCEMENT = make("CREATE_ANNOUNCEMENT");
export const REMOVE_COLLECTION_FROM_GROUP = make(
  "REMOVE_COLLECTION_FROM_GROUP"
);

/** * Actions */

// export const getGroup = id => ({
//   type: GET_GROUP,
//   payload: {
//     promise: groupSvc.getGroup(id),
//     groupId: id,
//   },
// });

export function getGroup(groupId) {
  return (dispatch, getState) => {
    console.log(`getGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `/api/groups/${groupId}`,
        data: {},
      })
        .then(response => {
          console.log("Get Group responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(GET_GROUP),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
        })
        .catch(error => {
          console.log("Get group responded error");
          console.log(error);
          dispatch({
            type: rejected(GET_GROUP),
            payload: {
              message: error.message,
            },
          });
        });
    });
  };
}

// export const getFeaturedGroups = () => ({
//   type: GET_FEATURED_GROUPS,
//   payload: {
//     promise: groupSvc.getFeaturedGroups(),
//   },
// });

export function getFeaturedGroups() {
  return (dispatch, getState) => {
    console.log(`getFeaturedGroups was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: "/api/groups/getFeaturedGroups",
        data: {},
      })
        .then(response => {
          console.log("getFeaturedGroups responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(GET_FEATURED_GROUPS),
            // meta: {
            //   ...data.meta,
            //   groupId,
            // },
            payload: {
              ...data,
            },
          });
        })
        .catch(error => {
          console.log("getFeaturedGroups responded error");
          console.log(error);
          dispatch({
            type: rejected(GET_FEATURED_GROUPS),
            payload: {
              message: error.message,
            },
          });
        });
    });
  };
}

// export const getMyGroups = () => ({
//   type: GET_MY_GROUPS,
//   payload: {
//     promise: groupSvc.getMyGroups(),
//   },
// });

export function getMyGroups() {
  return (dispatch, getState) => {
    console.log(`getMyGroups was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: "/api/groups/mine",
        data: {},
      })
        .then(response => {
          console.log("getMyGroups responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(GET_MY_GROUPS),
            // meta: {
            //   ...data.meta,
            //   groupId,
            // },
            payload: {
              ...data,
            },
          });
        })
        .catch(error => {
          console.log("getMyGroups responded error");
          console.log(error);
          dispatch({
            type: rejected(GET_MY_GROUPS),
            payload: {
              message: error.message,
            },
          });
        });
    });
  };
}

// export const getGroupCollections = groupId => ({
//   type: GET_GROUP_COLLECTIONS,
//   payload: {
//     promise: groupSvc.getGroupCollections(groupId),
//     groupId,
//   },
// });

export function getGroupCollections(groupId) {
  return (dispatch, getState) => {
    console.log(`getGroupCollections was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `/api/groups/${groupId}/collections`,
        data: {
          groupId,
        },
      })
        .then(response => {
          console.log("getGroupCollections responded successfully");
          console.log(response);
          let collections = response.data;

          dispatch({
            type: resolved(GET_GROUP_COLLECTIONS),
            meta: {
              groupId,
            },
            payload: collections,
          });
          resolve(collections);
        })
        .catch(error => {
          console.log("getGroupCollections responded error");
          console.log(error);
          dispatch({
            type: rejected(GET_GROUP_COLLECTIONS),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const removeCollectionFromGroup = (groupId, collectionId) => ({
//   type: REMOVE_COLLECTION_FROM_GROUP,
//   payload: {
//     promise: groupSvc.removeCollectionFromGroup(groupId, collectionId),
//     groupId,
//     collectionId,
//   },
// });

export function removeCollectionFromGroup(groupId, collectionId) {
  return (dispatch, getState) => {
    console.log(`removeCollectionFromGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `/api/collections/${collectionId}/groups/${groupId}`,
      })
        .then(response => {
          console.log("removeCollectionFromGroup responded successfully");
          console.log(response);
          dispatch({
            type: resolved(REMOVE_COLLECTION_FROM_GROUP),
            meta: {
              groupId,
              collectionId,
            },
            payload: response.data,
          });
          // resolve(collections);
        })
        .catch(error => {
          console.log("removeCollectionFromGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(REMOVE_COLLECTION_FROM_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const saveGroup = data => ({
//   type: SAVE_GROUP,
//   payload: {
//     promise: groupSvc.saveGroup(data),
//   },
// });

export function saveGroup(group) {
  return (dispatch, getState) => {
    console.log(`saveGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups",
        data: group,
      })
        .then(response => {
          console.log("saveGroup responded successfully");
          console.log(response);
          let group = response.data;

          dispatch({
            type: resolved(SAVE_GROUP),
            // meta: {
            //   groupId,
            // },
            payload: group,
          });
          resolve(group);
        })
        .catch(error => {
          console.log("saveGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(SAVE_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const deleteGroup = id => ({
//   type: DELETE_GROUP,
//   payload: {
//     promise: groupSvc.deleteGroup(id),
//     id: id,
//   },
// });

export function deleteGroup(groupId) {
  return (dispatch, getState) => {
    console.log(`deleteGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `/api/groups/${groupId}`,
      })
        .then(response => {
          console.log("deleteGroup responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(DELETE_GROUP),
            meta: {
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("deleteGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(DELETE_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const inviteGroupLeaders = (groupId, emails) => ({
//   type: INVITE_LEADER,
//   payload: {
//     promise: groupSvc.inviteLeaders(groupId, emails),
//     groupId,
//   },
// });

export function inviteGroupLeaders(groupId, emails) {
  return (dispatch, getState) => {
    console.log(`inviteGroupLeaders was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/inviteGroupLeaders",
        data: {
          groupId,
          emailsToInvite: emails,
        },
      })
        .then(response => {
          console.log("inviteGroupLeaders responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(INVITE_LEADER),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("inviteGroupLeaders responded error");
          console.log(error);
          dispatch({
            type: rejected(INVITE_LEADER),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const inviteGroupMembers = (groupId, emails) => ({
//   type: INVITE_MEMBER,
//   payload: {
//     promise: groupSvc.inviteMembers(groupId, emails),
//     groupId,
//   },
// });

export function inviteGroupMembers(groupId, emails) {
  return (dispatch, getState) => {
    console.log(`inviteGroupMembers was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/inviteGroupMembers",
        data: {
          groupId,
          emailsToInvite: emails,
        },
      })
        .then(response => {
          console.log("inviteGroupMembers responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(INVITE_MEMBER),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("inviteGroupMembers responded error");
          console.log(error);
          dispatch({
            type: rejected(INVITE_MEMBER),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const respondInviteGroup = (groupId, groupType, response) => ({
//   type: RESPOND_INVITE_GROUP,
//   payload: {
//     promise: groupSvc.respondToInvite(groupId, groupType, response),
//     groupId,
//   },
// });

export function respondInviteGroup(groupId, groupType, response) {
  return (dispatch, getState) => {
    console.log(`respondInviteGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/respondToGroupInvitation",
        data: {
          groupId,
          groupType,
          response,
        },
      })
        .then(response => {
          console.log("respondInviteGroup responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(RESPOND_INVITE_GROUP),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("respondInviteGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(RESPOND_INVITE_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const joinPublicGroup = groupId => ({
//   type: JOIN_PUB_GROUP,
//   payload: {
//     promise: groupSvc.joinGroup(groupId),
//     groupId,
//   },
// });

export function joinPublicGroup(groupId) {
  return (dispatch, getState) => {
    console.log(`joinPublicGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/joinPublicGroup",
        data: {
          groupId,
        },
      })
        .then(response => {
          console.log("joinPublicGroup responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(JOIN_PUB_GROUP),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("joinPublicGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(JOIN_PUB_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const leaveGroup = (groupId, groupType) => ({
//   type: LEAVE_GROUP,
//   payload: {
//     promise: groupSvc.leaveGroup(groupId, groupType),
//     groupId,
//   },
// });

export function leaveGroup(groupId, groupType) {
  return (dispatch, getState) => {
    console.log(`leaveGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/leaveGroup",
        data: {
          groupId,
          groupType,
        },
      })
        .then(response => {
          console.log("leaveGroup responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(LEAVE_GROUP),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("leaveGroup responded error");
          console.log(error);
          dispatch({
            type: rejected(LEAVE_GROUP),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const removeMember = (groupId, userId) => ({
//   type: REMOVE_GROUP_MEMBER,
//   payload: {
//     promise: groupSvc.removeMember(groupId, userId),
//     groupId,
//   },
// });

export function removeMember(groupId, userToRemoveId) {
  return (dispatch, getState) => {
    console.log(`removeMember was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/removeGroupMember",
        data: {
          groupId,
          userToRemoveId,
        },
      })
        .then(response => {
          console.log("removeMember responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(REMOVE_GROUP_MEMBER),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("removeMember responded error");
          console.log(error);
          dispatch({
            type: rejected(REMOVE_GROUP_MEMBER),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const removeGroupLeader = (groupId, userToRemoveId) => ({
//   type: REMOVE_GROUP_LEADER,
//   payload: {
//     promise: groupSvc.removeLeader(groupId, userToRemoveId),
//     groupId,
//   },
// });

export function removeGroupLeader(groupId, userToRemoveId) {
  return (dispatch, getState) => {
    console.log(`removeGroupLeader was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/removeGroupMember",
        data: {
          groupId,
          userToRemoveId,
        },
      })
        .then(response => {
          console.log("removeGroupLeader responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(REMOVE_GROUP_MEMBER),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("removeGroupLeader responded error");
          console.log(error);
          dispatch({
            type: rejected(REMOVE_GROUP_MEMBER),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const requestGroupAccess = groupId => ({
//   type: REQUEST_ACCESS,
//   payload: {
//     promise: groupSvc.requestAccess(groupId),
//     groupId,
//   },
// });

export function requestGroupAccess(groupId) {
  return (dispatch, getState) => {
    console.log(`requestGroupAccess was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/groups/requestGroupAccess",
        data: {
          groupId,
        },
      })
        .then(response => {
          console.log("requestGroupAccess responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(REQUEST_ACCESS),
            meta: {
              ...data.meta,
              groupId,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("requestGroupAccess responded error");
          console.log(error);
          dispatch({
            type: rejected(REQUEST_ACCESS),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const createAnnouncement = (id, subject, message) => ({
//   type: CREATE_ANNOUNCEMENT,
//   payload: {
//     promise: groupSvc.createAnnouncement(id, subject, message),
//     groupId: id,
//   },
// });

export function createAnnouncement(id, subject, message) {
  return (dispatch, getState) => {
    console.log(`createAnnouncement was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `/api/groups/${id}/announcements`,
        data: {
          subject,
          message,
        },
      })
        .then(response => {
          console.log("createAnnouncement responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(CREATE_ANNOUNCEMENT),
            meta: {
              ...data.meta,
              groupId: id,
            },
            payload: {
              ...data,
            },
          });
          resolve();
        })
        .catch(error => {
          console.log("createAnnouncement responded error");
          console.log(error);
          dispatch({
            type: rejected(CREATE_ANNOUNCEMENT),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

// export const getAnnouncements = groupId => ({
//   type: GET_ANNOUNCEMENTS,
//   payload: {
//     promise: groupSvc.getAnnouncements(groupId),
//     groupId,
//   },
// });

export function getAnnouncements(id, subject, message) {
  return (dispatch, getState) => {
    console.log(`getAnnouncements was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `/api/groups/${id}/announcements`,
      })
        .then(response => {
          console.log("getAnnouncements responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: resolved(GET_ANNOUNCEMENTS),
            meta: {
              ...data.meta,
              groupId: id,
            },
            payload: data,
          });
          resolve(data);
        })
        .catch(error => {
          console.log("getAnnouncements responded error");
          console.log(error);
          dispatch({
            type: rejected(GET_ANNOUNCEMENTS),
            payload: {
              message: error.message,
            },
          });
          reject();
        });
    });
  };
}

export function followGroup(groupId) {
  return (dispatch, getState) => {
    console.log(`followGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `/api/groups/${groupId}/follow`,
        data: {},
      })
        .then(response => {
          console.log("Follow responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: "FOLLOW_GROUP",
            payload: { groupId },
          });
        })
        .catch(response => {
          console.log("Follow group responded error");
          console.log(response);
        });
    });
  };
}

export function unFollowGroup(groupId) {
  return (dispatch, getState) => {
    console.log(`unFollowGroup was called`);

    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `/api/groups/${groupId}/follow`,
        data: {},
      })
        .then(response => {
          console.log("Unfollow responded successfully");
          console.log(response);
          let data = response.data;

          dispatch({
            type: "UNFOLLOW_GROUP",
            payload: { groupId },
          });
        })
        .catch(response => {
          console.log("Unfollow group responded error");
          console.log(response);
        });
    });
  };
}

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import QuizQuestions from 'routes/Portfolio/Component/ChannelPage/components/QuizQuestions';
import { Icon, ICONS } from '../../Icons';

import './ClassroomReadingQuestions.scss';

export default function ClassroomReadingQuestions({ onClose, questions }) {
  const readingQuestions = questions.map((question) => ({
    ...question,
    rightAnswers: question.rightAnswersIndex,
    givenAnswers: question.answersIndex,
  }));

  return (
    <Modal show={true} className="classroom-reading-questions-modal">
      <Modal.Body>
        <div className="header">
          <span>Quiz</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div>
          {readingQuestions.map((question) => (
            <QuizQuestions question={question} readOnly />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

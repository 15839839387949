import React, { useEffect, useRef } from 'react';
import _ from 'lodash';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import { Icon, ICONS } from 'components/Icons';

import './Question.scss';

export default function Question({
  question,
  index,
  updateQuestion,
  updateQuestionValue,
  deleteQuestion,
}) {
  const questionRef = useRef();
  const answerRef = useRef();

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.style.height = 'auto';
      questionRef.current.style.height = (questionRef.current.scrollHeight) + 'px';
    }
  }, [questionRef.current]);

  useEffect(() => {
    if (answerRef.current) {
      answerRef.current.style.height = 'auto';
      answerRef.current.style.height = (answerRef.current.scrollHeight) + 'px';
    }
  }, [answerRef.current]);

  const addOption = () => {
    const clonedQuestion = _.cloneDeep(question);

    clonedQuestion.options.push({
      text: '',
      isCorrect: false,
    });

    updateQuestion(index, clonedQuestion);
  };

  const updateOption = (optionIndex, key, value) => {
    const clonedQuestion = _.cloneDeep(question);

    if (!clonedQuestion.options[optionIndex]) {
      return;
    }

    if (
      key === 'isCorrect' &&
      value === true &&
      question.type === 'SINGLE_CHOICE'
    ) {
      clonedQuestion.options = clonedQuestion.options.map((option) => ({
        ...option,
        isCorrect: false,
      }));
    }

    clonedQuestion.options[optionIndex][key] = value;

    updateQuestion(index, clonedQuestion);
  };

  const deleteOption = (optionIndex) => {
    const clonedQuestion = _.cloneDeep(question);

    clonedQuestion.options.splice(optionIndex, 1);

    updateQuestion(index, clonedQuestion);
  };

  const showOptions = () => {
    return question.options.map((option, optionIndex) => (
      <div key={optionIndex} className="option-container">
        <Icon
          icon={ICONS.DELETE}
          className="icon"
          size={20}
          onClick={() => deleteOption(optionIndex)}
        />
        <div className="input">
          <FloatingInput
            value={option.text}
            labelName={`Option ${optionIndex + 1}`}
            onChange={(e) => updateOption(optionIndex, 'text', e.target.value)}
          />
        </div>
        <div className="radio-correct">
          <input
            id={`correct-${index}-${optionIndex}`}
            type={question.type === 'SINGLE_CHOICE' ? 'radio' : 'checkbox'}
            name={`correct-${index}-${optionIndex}`}
            checked={option.isCorrect}
            onChange={(e) =>
              updateOption(optionIndex, 'isCorrect', e.target.checked)
            }
          />
          <label for={`correct-${index}-${optionIndex}`}>Correct</label>
        </div>
      </div>
    ));
  };

  const showAddOption = () => (
    <div className="mt-5">
      <RectangularButton
        className="add-option"
        iconName="ADD"
        iconSize={16}
        onClick={addOption}
        noBorder
      >
        Add Option
      </RectangularButton>
    </div>
  );

  const showQuestionTypeBadge = () => {
    if (!question.typeOfQuestion) {
      return;
    }

    return (
      <span className="type-of-question-badge">
        {_.startCase(question.typeOfQuestion)}
      </span>
    );
  }

  const showAutoGeneratedBadge = () => {
    if (!question.isGenerated) {
      return;
    }

    return (
      <span className="generated-badge">
        AI Generated
      </span>
    );
  }

  const showModifiedBadge = () => {
    if (!question.isModifiedByTeacher) {
      return;
    }

    return (
      <span className="teacher-modified-badge">
        Teacher Modified
      </span>
    );
  }

  const showCreatedBadge = () => {
    if (question.isGenerated || !question.isTeacherCreated) {
      return;
    }

    return (
      <span className="teacher-modified-badge">
        Teacher Created
      </span>
    );
  }

  const showStanfordCORBadge = () => {
    if (!question.isStanfordCOR) {
      return;
    }

    return (
      <span className="stanford-badge">
        Stanford COR
      </span>
    );
  }

  const showAnswer = () => (
    <React.Fragment>
      <label className="question-text-label">Answer</label>
      <textarea
        ref={answerRef}
        type="text"
        className="form-control question-text-input"
        value={question.answer || ''}
        rows={1}
        onChange={(e) => {
          updateQuestionValue(index, 'answer', e.target.value);
          if (answerRef.current) {
            answerRef.current.style.height = 'auto';
            answerRef.current.style.height = (answerRef.current.scrollHeight) + 'px';
          }
        }}
      ></textarea>
    </React.Fragment>
  );

  return (
    <div key={index} className="body question-body" id={`question-${index}`}>
      <div className="question-title">
        <h4>
          Question {index + 1}
          {showQuestionTypeBadge()}
          {showAutoGeneratedBadge()}
          {showStanfordCORBadge()}
          {showModifiedBadge()}
          {showCreatedBadge()}
        </h4>
        <Icon
          icon={ICONS.DELETE}
          className="icon"
          size={20}
          onClick={() => deleteQuestion(index)}
        />
      </div>
      <div className="mt-4">
        <div className="input">
          {/* <FloatingInput
            value={question.text}
            labelName="Question"
            onChange={(e) => updateQuestionValue(index, 'text', e.target.value)}
          /> */}
          <label className="question-text-label">Question</label>
          <textarea
            ref={questionRef}
            type="text"
            className="form-control question-text-input"
            value={question.text || ''}
            rows={1}
            onChange={(e) => {
              updateQuestionValue(index, 'text', e.target.value);
              if (questionRef.current) {
                questionRef.current.style.height = 'auto';
                questionRef.current.style.height = (questionRef.current.scrollHeight) + 'px';
              }
            }}
          ></textarea>
        </div>
        <div className="dropdown">
          <select
            className="dropdown-selector"
            value={question.type}
            onChange={(e) => updateQuestionValue(index, 'type', e.target.value)}
          >
            <option value="SINGLE_CHOICE">Single choice</option>
            <option value="MULTIPLE_CHOICE">Multiple choice</option>
            <option value="SHORT_ANSWER">Short Answer</option>
          </select>
        </div>
        {['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(question.type) && (
          <React.Fragment>
            {showOptions()}
            {showAddOption()}
          </React.Fragment>
        )}
        {['SHORT_ANSWER'].includes(question.type) && (
          <React.Fragment>
            {showAnswer()}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

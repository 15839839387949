import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import QTPCard from './QTPCard';
import QTPSummary from './QTPSummary';
import QuestionPointForm from 'routes/Collection/Component/CollectionDetails/QuestionPoint/QuestionPointForm';
import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

function QTPTab({
  point,
  user,
  actions,
  qtps,
  // points,
  showQTPForm = false,
  onPointDetailView,
  showPointSelectText,
  selectedCaptions,
  onAction,
  qtpId,
  collection,
  rid,
  onEvent,
  collectionPoint,
  isMock,
  captions,
  courseId,
  priorityUsers,
  teacherMode,
  studentMode,
  topicId,
}) {
  // let qtps;

  // if (point._id) {
  //   qtps = points[point._id].qtps;
  // } else {
  //   qtps = [];
  // }

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SHOW_QTP':
          return {
            ...state,
            showQTPForm: action.payload.show,
            updatingQTP: action.payload.qtp,
          };
        case 'TOGGLE_SHOW_HIDE_ALL':
          return {
            ...state,
            showAll: !state.showAll,
          };
        case 'SET_TOPIC_USER_SUMMARY':
          return {
            ...state,
            topicUserSummary: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showQTPForm,
      // showAll: !qtpId,
      showAll: !!priorityUsers,
      qtpId,
    },
  );

  useEffect(() => {
    if (point._id && !qtps) {
      // load point
      actions.getQTPsForPoint(point._id);
    }
  }, [point._id]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QTP', payload: showQTPForm });
  }, [showQTPForm]);

  useEffect(() => {
    onEvent && onEvent('QTP_TAB_SHOW_QTP_FORM', { show: state.showQTPForm });
  }, [state.showQTPForm]);

  useEffect(() => {
    loadTopicUserSummary();
  }, [topicId, qtps ? qtps.length : 0]);

  useEffect(() => {
    showPointSelectText(
      state.showQTPForm,
      state.updatingQTP ? state.updatingQTP.surveyResponse.captions : [],
    );
    onAction && onAction(state.showQTPForm);
  }, [state.showQTPForm]);

  async function loadTopicUserSummary() {
    if (studentMode && topicId) {
      const res = await actions.getTopicGoals(topicId, courseId, false, true);
      dispatch({ type: 'SET_TOPIC_USER_SUMMARY', payload: res.userSummary });
    }
  }

  function isStudentNotInteracted() {
    return (
      !teacherMode &&
      studentMode &&
      (!state.topicUserSummary ||
        state.topicUserSummary.qtps + state.topicUserSummary.rtps === 0)
    );
  }

  function onUpdate(qtp) {
    dispatch({ type: 'SHOW_QTP', payload: { show: true, qtp } });
  }

  function renderLoading() {
    if (qtps || !point._id) {
      return null;
    }

    return (
      <div
        className="fa fa-spin fa-spinner text-primary"
        style={{ fontSize: '14px' }}
      />
    );
  }

  function renderQTPForm() {
    return (
      <QuestionPointForm
        className="pt-2 pb-2"
        point={point}
        close={(isQTPAdded) => {
          dispatch({ type: 'SHOW_QTP', payload: { show: false } });

          if (isQTPAdded && onEvent) {
            onEvent('QTP_TAB_QTP_ADDED');
          }
        }}
        selectedCaptions={selectedCaptions}
        qtp={state.updatingQTP}
        rid={rid}
        collectionPoint={collectionPoint}
        isMock={isMock}
        captions={captions}
        courseId={courseId}
      />
    );
  }

  function renderQuestionPointButton() {
    if (!qtps && point._id) {
      return null;
    }

    if (teacherMode) {
      return null;
    }

    if (qtps) {
      if (rid) {
        if (qtps.findIndex((i) => i.surveyResponse.rid === rid) !== -1) {
          return null;
        }
      } else if (qtps.findIndex((i) => i.userId === user.user._id) !== -1) {
        return null;
      }
    }

    const btn = (
      <RectangularButton
        className="w-100 mb-3 big-button"
        iconName="QUESTION"
        iconSize={24}
        onClick={() => {
          dispatch({ type: 'SHOW_QTP', payload: { show: true } });
        }}
        disabled={!user.user._id}
      >
        Question this Point
      </RectangularButton>
    );

    if (user.user._id) {
      return btn;
    } else {
      return (
        <ToolTip
          toolTipText="Please login to QTP"
          toolTipPosition="bottom"
          className="w-100"
        >
          {btn}
        </ToolTip>
      );
    }
  }

  function renderSummary() {
    if (!qtps || !qtps.length) {
      return null;
    }

    if (teacherMode && priorityUsers && priorityUsers.length === 1) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    return <QTPSummary qtps={qtps} />;
  }

  function renderStudentNoQTPMessage() {
    let text;

    if (teacherMode && qtps && !qtps.length) {
      text = 'Students have not Questioned this Point.';
    }

    if (teacherMode && qtps && qtps.length && priorityUsers) {
      const filterQTPs = qtps.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );

      if (!filterQTPs.length) {
        if (priorityUsers.length === 1) {
          text = `${priorityUsers[0].username} hasn't Questioned this Point.`;
        } else if (priorityUsers.length === 0) {
          text = 'The selected students have not Questioned this Point.';
        }
      }
    }

    if (isStudentNotInteracted() && state.topicUserSummary) {
      text =
        'You need to question or review at least one claim to view the others.';
    }

    if (text) {
      return (
        <div className="text-center font-italic font-size-12 av-gray">
          {text}
        </div>
      );
    }

    return null;
  }

  function renderCard(qtp) {
    const className = classnames('mt-3 mb-3', {});
    return (
      <QTPCard
        qtp={qtp}
        className={className}
        key={qtp._id}
        onUpdate={onUpdate}
        point={point}
        collection={collection}
        rid={rid}
        courseId={courseId}
        teacherMode={teacherMode}
      />
    );
  }

  function renderMyCard() {
    if (!qtps || !qtps.length) {
      return null;
    }

    const qtp = qtps.find((i) => i.userId === user.user._id);

    if (!qtp) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    return renderCard(qtp);
  }

  function renderShowHideToggleButton() {
    if (!qtps || !qtps.length) {
      return null;
    }

    let otherQTPs = qtps.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      otherQTPs = otherQTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }

    if (!otherQTPs.length) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let text;

    if (state.showAll) {
      text = 'Hide Questions';
    } else {
      if (otherQTPs.length === 1) {
        text = 'See 1 Question';
      } else if (otherQTPs.length === 2) {
        text = 'See both Questions';
      } else {
        text = `See all ${otherQTPs.length} Questions`;
      }
    }

    return (
      <div className="">
        <ActionLink
          linkClassName="btn-show-hide-toggle"
          onClick={() => dispatch({ type: 'TOGGLE_SHOW_HIDE_ALL' })}
        >
          {text}
        </ActionLink>
      </div>
    );
  }

  function renderOtherCards() {
    if (!qtps || !qtps.length || !state.showAll) {
      return null;
    }

    if (isStudentNotInteracted()) {
      return null;
    }

    let sortedQTPs;

    if (rid) {
      sortedQTPs = qtps.sort((i) => (i.surveyResponse.rid === rid ? -1 : 1));
      sortedQTPs = sortedQTPs.slice(0, 5);
    } else {
      // sortedQTPs = qtps.sort((i) => (i.userId === user.user._id ? -1 : 1));
      sortedQTPs = qtps;
    }

    sortedQTPs = sortedQTPs.filter((i) => i.userId !== user.user._id);

    if (priorityUsers && priorityUsers.length) {
      sortedQTPs = sortedQTPs.filter(
        (i) => priorityUsers.map((i) => i.userId).indexOf(i.userId) !== -1,
      );
    }

    return sortedQTPs.map((qtp) => {
      return renderCard(qtp);
    });
  }

  function renderContent() {
    if (state.showQTPForm) {
      return renderQTPForm();
    } else {
      return (
        <React.Fragment>
          {renderLoading()}
          {renderQuestionPointButton()}
          {renderSummary()}
          {renderStudentNoQTPMessage()}
          {renderMyCard()}
          {renderShowHideToggleButton()}
          {renderOtherCards()}
        </React.Fragment>
      );
    }
  }

  return <div className="">{renderContent()}</div>;
}

function mapStateToProps(state, props) {
  let qtps;

  if (props.point._id) {
    qtps = state.points[props.point._id].qtps;
  }

  return {
    user: state.user,
    qtps,
    classRoom: state.classRoom,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QTPTab);

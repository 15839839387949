import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from 'redux/actions/index';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import STPCard from './STPCard';

function STPTab({ point, user, actions, stps }) {
  useEffect(() => {
    if (point._id && !stps) {
      actions.getSTPsForPoint(point._id);
    }
  }, [point._id]);

  function renderSavePointButton() {
    const btn = (
      <RectangularButton
        className="w-100 mb-3 big-button"
        iconName="STP"
        iconSize={22}
        onClick={() => {
          if (window.self !== window.top) {
            window.parent.postMessage(
              {
                type: 'averpoint:add:point',
                text: point.text,
              },
              '*',
            );
          } else {
            // TODO ::
          }
        }}
        disabled={!user.user._id}
      >
        Save this Point
      </RectangularButton>
    );

    if (user.user._id) {
      return btn;
    } else {
      return (
        <ToolTip
          toolTipText="Please login to QTP"
          toolTipPosition="bottom"
          className="w-100"
        >
          {btn}
        </ToolTip>
      );
    }
  }

  function renderCard(stp) {
    const className = classnames('mt-3 mb-3', {});
    return <STPCard className={className} stp={stp} key={stp._id} />;
  }

  function renderCards() {
    if (!stps || !stps.length) {
      return null;
    }

    return stps.map(renderCard);
  }

  function renderContent() {
    return (
      <React.Fragment>
        {renderSavePointButton()}
        {renderCards()}
      </React.Fragment>
    );
  }

  return <div className="">{renderContent()}</div>;
}

function mapStateToProps(state, props) {
  let stps;

  if (props.point._id) {
    stps = state.points[props.point._id].stps;
  }

  return {
    user: state.user,
    stps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(STPTab);

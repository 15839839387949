import React from "react";

export const PointRules = () => ({
  VERIFY_EMAIL: {
    points: 1,
    string: "+1",
  },
  READ_ARTICLE: {
    points: 1,
    string: "+1",
    dayLimit: 10,
  },
  VERIFY_VERIFY: {
    points: 1,
    string: "+1",
  },
  REJECT_REJECT: {
    points: 2,
    string: "+2",
  },
  EXPIRE_EXPIRE: {
    points: 10,
    string: "+10",
  },
  VERIFY_REJECT: {
    points: -5,
    string: "-5",
  },
  VERIFY_EXPIRE: {
    points: 1,
    string: "0",
  },
  REJECT_VERIFY: {
    points: -10,
    string: "-10",
  },
  REJECT_EXPIRE: {
    points: -10,
    string: "-10",
  },
  REJECT_REVISE: {
    points: 1,
    string: "+1",
  },
  EXPIRE_VERIFY: {
    points: -10,
    string: "-10",
  },
  EXPIRE_REJECT: {
    points: -10,
    string: "-10",
  },
  EXPIRE_REVISE: {
    points: 1,
    string: "+1",
  },
  REVISE_REVISE: {
    points: 3,
    string: "+3",
  },
  CHALLENGE_AGREES: {
    points: 50,
    string: "+50",
  },
  CHALLENGE_DISAGREES: {
    points: -50,
    string: "-50",
  },
  SUBMIT_ARTICLE_POINTS: {
    points: 5,
    string: "+5",
  },
  PROOFPOINT_VERIFY: {
    type: "writer",
    points: 5,
    string: "+5",
  },
  PROOFPOINT_REJECT: {
    type: "writer",
    points: -5,
    string: "-5",
  },
  PROOFPOINT_EXPIRE: {
    type: "writer",
    points: -5,
    string: "-5",
  },
  PROOFPOINT_RETIRE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  PROOFPOINT_UNDER_REVIEW: {
    type: "writer",
    points: 0,
    string: "0",
  },
  PROOFPOINT_ESCALATE: {
    type: "writer",
    points: 0,
    string: "0",
  },
  PROOFPOINT_REVISE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  FACT_REVISE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  POINT_SOURCE_REVISE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  SUBMIT_ARTICLE: {
    type: "writer",
    points: 5,
    string: "+5",
  },
  render() {
    return null;
  },
});

export const Points = () => ({
  VERIFY_EMAIL: {
    points: 1,
    string: "+1",
  },
  READ_ARTICLE: {
    points: 1,
    string: "+1",
    dayLimit: 10,
  },
  VERIFY_VERIFY: {
    points: 1,
    string: "+1",
  },
  REJECT_REJECT: {
    points: 2,
    string: "+2",
  },
  EXPIRE_EXPIRE: {
    points: 10,
    string: "+10",
  },
  VERIFY_REJECT: {
    points: -5,
    string: "-5",
  },
  VERIFY_EXPIRE: {
    points: 1,
    string: "0",
  },
  REJECT_VERIFY: {
    points: -10,
    string: "-10",
  },
  REJECT_EXPIRE: {
    points: -10,
    string: "-10",
  },
  REJECT_REVISE: {
    points: 1,
    string: "+1",
  },
  EXPIRE_VERIFY: {
    points: -10,
    string: "-10",
  },
  EXPIRE_REJECT: {
    points: -10,
    string: "-10",
  },
  EXPIRE_REVISE: {
    points: 1,
    string: "+1",
  },
  REVISE_REVISE: {
    points: 3,
    string: "+3",
  },
  CHALLENGE_AGREES: {
    points: 50,
    string: "+50",
  },
  CHALLENGE_DISAGREES: {
    points: -50,
    string: "-50",
  },
  SUBMIT_ARTICLE_POINTS: {
    points: 5,
    string: "+5",
  },
  PROOFPOINT_VERIFY: {
    type: "writer",
    points: 5,
    string: "+5",
  },
  PROOFPOINT_REJECT: {
    type: "writer",
    points: -5,
    string: "-5",
  },
  PROOFPOINT_EXPIRE: {
    type: "writer",
    points: -5,
    string: "-5",
  },
  PROOFPOINT_RETIRE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  PROOFPOINT_UNDER_REVIEW: {
    type: "writer",
    points: 0,
    string: "0",
  },
  PROOFPOINT_ESCALATE: {
    type: "writer",
    points: 0,
    string: "0",
  },
  PROOFPOINT_REVISE: {
    type: "writer",
    points: -1,
    string: "-1",
  },
  SUBMIT_ARTICLE: {
    type: "writer",
    points: 5,
    string: "+5",
  },
  render() {
    return null;
  },
});

// export const Privileges = () => ({
//   checkPrivilege: function (privilege) {
//     var errorMessage = "";

//     if (!Meteor.user()) {
//       errorMessage =
//         "Please log in to " +
//         privilege.action +
//         ". Don't you want the world to know how brilliant you are!";
//     } else if (!Meteor.user().emails[0].verified) {
//       errorMessage = "Please verify your email to " + privilege.action + ".";
//     } else if (
//       Meteor.user().profile.isExpert &&
//       (privilege.action == this.SUGGEST_PROOFPOINT.action ||
//         privilege.action == this.SUBMIT_ARTICLE.action)
//     ) {
//       errorMessage = "";
//     } else if (privilege.level > Meteor.user().profile.level) {
//       errorMessage = (
//         <span>
//           To {privilege.action}, you need to be Level {privilege.level} or
//           above. Looks like you are at Level {Meteor.user().profile.level}.
//           Check your <a href="score">Score</a> page to see your points, and how
//           to earn more!
//         </span>
//       );
//     }

//     var privilegeJSON = {
//       errorMessage: errorMessage,
//       popOverTitle: "AverPoint Message",
//       placement: privilege.placement,
//     };

//     return privilegeJSON;
//   },
//   hasPrivilege: function (privilege) {
//     return Meteor.user().profile.level >= privilege.level;
//   },
//   CHALLENGE_PROOFPOINT: {
//     type: "reader",
//     /*level: this.data.level, doesn't work */
//     string: "You can challenge any Proof Point at your level or below.",
//   },
//   REQUEST_REVIEW: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "request evidence",
//     placement: "left",
//   },
//   ASK_QUESTION: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "ask a question",
//     placement: "left",
//   },
//   LEAVE_COMMENT: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "leave a comment",
//     placement: "left",
//   },
//   UPVOTE: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "up vote",
//     placement: "left",
//   },
//   DOWNVOTE: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "down vote",
//     placement: "left",
//   },
//   LIKE_ARTICLE: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "dislike an article",
//     placement: "left",
//   },
//   DISLIKE_ARTICLE: {
//     type: "reader",
//     level: 3,
//     string: "Level 3+",
//     action: "dislike an article",
//     placement: "left",
//   },
//   REVIEW_PROOFPOINT: {
//     type: "reviewer",
//     /*level: this.data.level, doesn't work */
//     string: "You can review any Proof Point at your level or below.",
//   },
//   SUBMIT_ARTICLE: {
//     type: "writer",
//     level: 2,
//     string: "Level 2+",
//     action: "publish an article",
//     placement: "left",
//   },
//   RESUBMIT_ARTICLE: {
//     type: "writer",
//     level: 2,
//     string: "Level 2+",
//   },
//   LEAVE_FEEDBACK: {
//     type: "reader",
//     level: 1,
//     string: "Level 1",
//     action: "leave feedback",
//     placement: "left",
//   },
//   SEND_INTERESTS: {
//     type: "reader",
//     level: 1,
//     string: "Level 1",
//     action: "tell us about your interests",
//     placement: "left",
//   },
//   SUGGEST_PROOFPOINT: {
//     type: "reader",
//     level: 2,
//     string: "Level 2",
//     action: "suggest a Proof Point",
//     placement: "right",
//   },
//   render() {
//     return null;
//   },
// });

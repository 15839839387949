/* eslint-disable no-else-return */
import React, { Component, useReducer } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { MenuItem, Dropdown } from "react-bootstrap";

import { DragableCard } from "components/cards/DnDContainer.js";
import { Icon, ICONS } from "components/Icons";
import ActionLink from "components/elements/molecules/ActionLink/ActionLink";
import SubPoints from "routes/Point/Component/PointEvidence/Component/SubPoints";
import * as Actions from "redux/actions/index";
import ProfileImage from "components/elements/molecules/Images/profileImage";

import "./SubPoint.scss";

class SubPoint extends Component {
  static propTypes = {
    user: PropTypes.object,
    key: PropTypes.string,
    subPointInfo: PropTypes.object,
    subPoint: PropTypes.object,
    parentObject: PropTypes.object,
    cardType: PropTypes.oneOf(["outline"]),
    confirmDrop: PropTypes.bool,
    onConfirmDrop: PropTypes.func,
    canDrag: PropTypes.bool,
    actions: PropTypes.object,
    selectedPointIds: PropTypes.array,
    expandedPointIds: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      // expandedSubPoints: {},
      isExpanded: false,
    };
  }

  isExpanded() {
    const { expandedPointIds, subPoint } = this.props;

    if (expandedPointIds) {
      return expandedPointIds.indexOf(subPoint._id) !== -1;
    } else {
      return this.state.isExpanded;
    }
  }

  componentDidMount() {
    if (!this.props.pointObject) {
      this.props.actions.getPoint(this.props.subPointInfo.pointId);
    }
  }

  renderProfilePics() {
    const { cardType, subPoint, user, parentObject, history } = this.props;

    if (cardType !== "outline" || !subPoint.authorId) {
      return null;
    }

    return (
      <SubPointProfileImages
        user={user}
        parentObject={parentObject}
        subPoint={subPoint}
        history={history}
      />
    );
  }

  renderExpandCollapse() {
    const { cardType, subPoint, expandedPointIds } = this.props;

    if (!subPoint || cardType !== "outline") {
      return null;
    }

    const isExpanded = this.isExpanded();
    const className = classnames(
      "flex-shrink-0 expand-sub-points position-absolute d-flex align-items-center justify-content-center",
      {
        expanded: isExpanded,
        "no-sub-points": subPoint.totalSubPoints === 0,
      }
    );

    if (subPoint.totalSubPoints > 0) {
      return (
        <div className={className} onClick={this.toggleExpandedPoint}>
          <Icon
            icon={isExpanded ? ICONS.MINUS : ICONS.ADD}
            width={isExpanded ? 18 : 10}
          />
        </div>
      );
    } else {
      return (
        <div className={className}>
          <Icon icon={ICONS.BULLET} width={10} />
        </div>
      );
    }
  }

  renderVerticalLine() {
    const { cardType, subPoint, user } = this.props;

    if (cardType !== "outline" || !this.isExpanded()) {
      return null;
    }

    return (
      <ExpandVerticalLine
        point={subPoint}
        onAddSubPoint={offsetY => {
          let index = 0;

          for (let i = 0; i < subPoint.subPoints.length; i++) {
            const pointCardElm = document.querySelector(
              `div[point-card-point-id="${subPoint.subPoints[i].pointId}"]`
            );
            const pointOffsetY = pointCardElm.getBoundingClientRect().y;

            if (pointOffsetY >= offsetY) {
              break;
            }

            index += 1;
          }

          console.log("Adding sub point at index", index);
          this.props.onAddSubPoint(subPoint, index);
        }}
        allowAdd={!!user._id}
      />
    );
  }

  render() {
    const {
      subPointInfo,
      user,
      subPoint,
      parentObject,
      cardType,
      canDrag,
      pointObject,
      selectedPointIds,
      openPointModal,
    } = this.props;

    if (!pointObject) {
      return null;
    }

    // const { isExpanded } = this.state;
    const isExpanded = this.isExpanded();

    const className = classnames("sub-point position-relative", cardType, {
      neutral:
        subPointInfo.stance !== "For" && subPointInfo.stance !== "Against",
      for: subPointInfo.stance === "For",
      against: subPointInfo.stance === "Against",
      "has-sub-points":
        subPoint.subPoints && subPoint.subPoints.length && isExpanded,
      expanded: isExpanded,
      editing: pointObject && pointObject.isEditing,
      selected:
        selectedPointIds &&
        subPoint._id === selectedPointIds[selectedPointIds.length - 1],
    });

    const allowRemove = user && subPointInfo.postedBy == user._id;

    return (
      <div className={className}>
        {this.renderVerticalLine()}
        <DragableCard
          point={subPoint}
          cardType={cardType}
          citationStyle="short"
          parentObject={parentObject}
          allowRemove={allowRemove}
          allowDownload={false}
          onRemove={() =>
            this.removeSubPointFromPoint(subPointInfo.pointId, parentObject.id)
          }
          showTotalSubPoints
          canDrag={canDrag}
          moveCardOnHover={canDrag}
          // disableClickThru={cardType === 'outline'}
          onClick={() => {
            this.props.onSelect && this.props.onSelect([subPoint._id]);
          }}
          onPointDetailView={arg => {
            if (arg.tab === "EVIDENCE") {
              this.toggleExpandedPoint();
            } else {
              openPointModal(subPoint, parentObject, arg.tab);
            }
          }}
          fourPackShowActiveTab={isExpanded ? "EVIDENCE" : ""}
        />
        {this.renderProfilePics()}
        {this.renderExpandCollapse()}
        {this.renderSubPoints()}
      </div>
    );
  }

  // renderExpander = subPoint => {
  //   if (!subPoint || !subPoint.subPoints || subPoint.subPoints.length == 0)
  //     return;

  //   const isExpanded = !!this.state.expandedSubPoints[subPoint._id];

  //   const icon = isExpanded ? ICONS.REMOVE : ICONS.ADD;

  //   return (
  //     <Icon
  //       className="expander"
  //       icon={icon}
  //       onClick={() => {
  //         this.toggleExpandedSubPoint(subPoint._id);
  //       }}
  //     />
  //   );
  // };

  // toggleExpandedSubPoint = subPointId => {
  //   const isExpanded = !!this.state.expandedSubPoints[subPointId];

  //   this.setState({
  //     expandedSubPoints: {
  //       ...this.state.expandedSubPoints,
  //       [subPointId]: !isExpanded,
  //     },
  //   });
  // };

  toggleExpandedPoint = (ids = []) => {
    if (this.props.onToggleExpand) {
      this.props.onToggleExpand([this.props.subPoint._id, ...ids]);
    } else {
      this.setState({
        isExpanded: !this.state.isExpanded,
      });
    }
  };

  renderSubPoints() {
    const { cardType, expandedPointIds } = this.props;
    let point = this.props.subPoint;
    const isExpanded = this.isExpanded();

    if (!point.subPoints || !point.subPoints.length) {
      return;
    }

    let toggleAction;

    if (cardType !== "outline") {
      toggleAction = (
        <div className="mb-3 mt-3 expand-sub-points-with-count">
          <ActionLink
            onClick={() => {
              this.toggleExpandedPoint();
            }}
            linkClassName="font-size-12 p-0"
            iconType="averPointIcon"
            iconName={isExpanded ? "DROPDOWN_DOWN" : "DROPDOWN_RIGHT"}
            label={`${point.subPoints.length} SubPoints`}
            iconClassName="av-white mr-2"
          />
        </div>
      );
    }

    let subPoints;

    if (isExpanded) {
      const subPointIds = point.subPoints
        .filter(i => !!i)
        .map((subPoint, index) => {
          return subPoint.pointId;
        });

      subPoints = (
        <div className="sub-point-wrapper">
          <SubPoints
            user={this.props.user}
            point={point}
            //points={this.props.points}
            subPointIds={subPointIds}
            filter={""}
            subPointView={this.props.cardType}
            selectedPointIds={this.props.selectedPointIds}
            expandedPointIds={expandedPointIds}
            onToggleExpand={
              this.props.onToggleExpand ? this.toggleExpandedPoint : null
            }
            onSelect={this.props.onSelect}
            onAddSubPoint={this.props.onAddSubPoint}
            addSubPointModel={this.props.addSubPointModel}
          />
        </div>
      );
    }

    return (
      <div className="sub-points position-relative">
        {/* {this.renderVerticalLine()} */}
        {toggleAction}
        {subPoints}
      </div>
    );
  }

  removeSubPointFromPoint = (subPointId, pointId) => {
    const props = {
      model: {
        title: "Remove SubPoint",
        message:
          "Are you sure you want to remove this SubPoint from the Point?",
      },
      onResponse: () => {
        this.props.actions.removeSubPointFromPoint(subPointId, pointId);
      },
    };
    this.props.actions.openModal("confirm", props);
  };
}

function mapStateToProps(state, ownProps) {
  const { user } = state.user;

  const pointObject = state.points[ownProps.subPointInfo.pointId];

  return {
    user,
    pointObject,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: {
          title,
          message,
        },
        onResponse,
      };
      dispatch(Actions.openModal("confirm", props));
    },
    openPointModal: (point, parentObject, fourPackActiveTab) => {
      let props = {
        model: {
          point,
          parentObject,
          isModal: true,
          showReviewOnOpen: false,
          fourPackActiveTab,
        },
      };
      dispatch(Actions.setParent(parentObject));
      dispatch(Actions.openModal("Point", props));
    },
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubPoint));

function SubPointProfileImages({ parentObject, subPoint, user, history }) {
  function renderProfilePic(
    userId,
    userName,
    hostedImageVersion,
    allowClickToPortfolio
  ) {
    return (
      <ProfileImage
        key={userId}
        userId={userId}
        hostedImageVersion={hostedImageVersion}
        allowUserToChangePic={false}
        allowClickToPortfolio={allowClickToPortfolio}
        username={userName}
        size={18}
      />
    );
  }

  function goToPortfolio(userName) {
    const route = `/portfolio/${userName}`;
    history.push(route);
  }

  const postedByAuthor = parentObject.subPoints.find(
    i => i.pointId === subPoint._id
  );

  let authorElm, postedByElm, dropdown, hoverTooltip;

  if (subPoint.authorId === postedByAuthor.postedBy) {
    if (subPoint.authorId !== user._id) {
      authorElm = renderProfilePic(
        subPoint.authorId,
        subPoint.authorName,
        subPoint.authorHostedImageVersion,
        true
      );

      return (
        <div
          className="position-absolute profile-images-container"
          style={{ top: "5px" }}
        >
          {authorElm}
        </div>
      );
    }

    return null;
  } else {
    authorElm = renderProfilePic(
      subPoint.authorId,
      subPoint.authorName,
      subPoint.authorHostedImageVersion,
      false
    );
    postedByElm = renderProfilePic(
      postedByAuthor.postedBy,
      postedByAuthor.postedByName,
      postedByAuthor.hostedImageVersion,
      false
    );
    dropdown = (
      <Dropdown
        id="profile-images-dropdown"
        className="profile-images-dropdown"
        onClick={e => e.stopPropagation()}
      >
        <Dropdown.Toggle noCaret>
          {authorElm}
          {postedByElm}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onClick={e => goToPortfolio(subPoint.authorName)}>
            {authorElm}{" "}
            <div className="ml-2" style={{ marginTop: "3px" }}>
              <span className="gray-3">Point author:</span>{" "}
              {subPoint.authorName}
            </div>
          </MenuItem>
          <MenuItem onClick={e => goToPortfolio(postedByAuthor.postedByName)}>
            {postedByElm}{" "}
            <div className="ml-2" style={{ marginTop: "3px" }}>
              <span className="gray-3">Posted By:</span>{" "}
              {postedByAuthor.postedByName}
            </div>
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );

    hoverTooltip = (
      <div className="tooltip av-white p-3 text-center">
        <div className="triangle" />
        <div className="font-weight-bold">
          {postedByAuthor.postedByName} posted {subPoint.authorName}'s Point
        </div>
        <div className="pt-1 font-size-10">Click to visit user's profiles</div>
      </div>
    );

    return (
      <div className="position-absolute profile-images-container">
        {dropdown}
        {hoverTooltip}
      </div>
    );
  }
}

function ExpandVerticalLine({ point, onAddSubPoint, allowAdd }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_SHOW_ADD":
          return {
            ...state,
            showAdd: action.payload,
          };
        case "SET_POSITION":
          return {
            ...state,
            position: action.payload,
          };
        default:
          return state;
      }
    },
    {
      showAdd: false,
      position: { x: 0, y: 0 },
    }
  );

  function onMouseEnter() {
    dispatch({ type: "SET_SHOW_ADD", payload: true });
  }

  function onMouseLeave() {
    dispatch({ type: "SET_SHOW_ADD", payload: false });
  }

  function onMouseMove(e) {
    if (e.target !== e.currentTarget) {
      return;
    }

    dispatch({
      type: "SET_POSITION",
      payload: { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY },
    });
  }

  function renderAdd() {
    if (!allowAdd || !state.showAdd) {
      return null;
    }

    const style = { left: -8, top: state.position.y - 8, cursor: "pointer" };

    return (
      <span
        onClick={e => {
          onAddSubPoint(
            e.currentTarget.getBoundingClientRect().y + e.nativeEvent.offsetY
          );
        }}
        className="av-blue position-absolute"
        style={style}
      >
        <Icon icon={ICONS.ADD_CIRCLE} />
      </span>
    );
  }
  return (
    <div
      className="vertical-line"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {renderAdd()}
    </div>
  );
}

import * as Actions from '../actions/index';

const initialState = {};

export default function helps(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_HELPS:
      return {
        ...state,
        helps: action.helps,
      };
    case Actions.ADD_HELP:
      return {
        ...state,
        helps: [...state.helps, action.help],
      };
    case Actions.UPDATE_HELP: {
      const index = state.helps.findIndex((i) => i._id === action.help._id);
      return {
        ...state,
        helps: [
          ...state.helps.slice(0, index),
          action.help,
          ...state.helps.slice(index + 1),
        ],
      };
    }
    case Actions.DELETE_HELP: {
      const index = state.helps.findIndex((i) => i._id === action.help._id);
      return {
        ...state,
        helps: [
          ...state.helps.slice(0, index),
          ...state.helps.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class CallToActionModal extends React.Component {
  constructor(props) {
    super(props);

    let point = this.props.model.point;

    this.state = {
      callToAction: { ...point.callToAction },
      isEditing: point.callToAction.text && point.callToAction.url,
      isSubmitted: false,
    };
  }

  onClose = () => {
    this.props.onClose(false);
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({
      callToAction: {
        ...this.state.callToAction,
        [name]: value,
      },
    });
  };

  onSubmit = () => {
    this.setState({
      isSubmitted: true,
    });

    var { callToAction } = this.state;

    if (callToAction.text && callToAction.url) {
      let point = {
        ...this.props.model.point,
        callToAction: {
          ...this.state.callToAction,
        },
      };
      this.props
        .onAction(this.props.onUpdate, point, this.props.model.type)
        .then(() => {
          this.props.onClose(true);
        });
    }
  }

  render() {
    var { isSubmitted, callToAction } = this.state;
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    return (
      <Modal show={true} className="general-modal" container={container}>
        <Modal.Header closeButton onHide={this.onClose}>
          <Modal.Title>
            {this.state.isEditing
              ? 'Edit Call to Action'
              : 'Add Call to Action'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingInput
            name="text"
            onChange={this.onChange}
            labelName="CTA Action"
            value={this.state.callToAction.text}
            error={isSubmitted && !callToAction.text ? 'Please enter text' : ''}
          />
          <FloatingInput
            name="url"
            onChange={this.onChange}
            labelName="URL"
            value={this.state.callToAction.url}
            error={isSubmitted && !callToAction.url ? 'Please enter URL' : ''}
          />
        </Modal.Body>
        <Modal.Footer>
          <RoundedButton
            label={this.state.isEditing ? 'Update' : 'Save'}
            onClick={this.onSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(CallToActionModal);

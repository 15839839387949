import React from 'react';
import PropTypes from 'prop-types';

import SearchableDropDown from 'components/elements/molecules/SearchableDropDown/SearchableDropDown';
import { Icon, ICONS } from 'components/Icons';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CollectionListItem from './CollectionListItem';
import * as Actions from '../../../redux/actions/index';

class AddToCollectionComponent extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    selectedCollectionId: PropTypes.string,
    onCollectionSelected: PropTypes.func,
    openCreateCollectionModal: PropTypes.func,
    /** *Redux */
    authenticated: PropTypes.bool,
    user: PropTypes.object,
    userCollections: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.userCollections.length == 1) {
      this.props.onCollectionSelected(this.props.userCollections[0]._id);
    }
  };

  render() {
    if (!this.props.authenticated)
      return <div>Please log in to add a Point to a Collection.</div>;

    let collectionPoint = null;
    // This is only showing the single collection if you create it.
    // Changing to show single if create OR select.
    if (this.props.selectedCollectionId) {
      collectionPoint = this.props.userCollections.find(
        collectionPoint =>
          collectionPoint._id == this.props.selectedCollectionId,
      );
    }

    return (
      <div className="add-to-collection-wrapper">
        {this.renderSelectedCollection(collectionPoint)}
        {this.renderSelectCollection(collectionPoint)}
      </div>
    );
  }

  renderItem(collectionPoint, props, index) {
    return (
      <CollectionListItem
        collectionPoint={collectionPoint}
        selectedCollectionId={props.selectedCollectionId}
        handleClick={props.handleClick}
        key={`collection-drop-down-item-${index}`}
      />
    );
  }

  renderSelectedCollection = collectionPoint => {
    if (!this.props.selectedCollectionId || !collectionPoint) return null;

    return (
      <div className="selected-collection">
        <span className="help-text">
          {' '}
          Collection:
          <span className="collection-name">{collectionPoint.text}</span>
        </span>
        <ActionLink
          onClick={() => {
            this.props.onCollectionSelected(null);
          }}
          linkClassName="change-collection"
          iconType="averPointIcon"
          iconName="PENCIL"
          label=""
          toolTipText="Change where you save this Point."
          toolTipPosition="bottom"
        />
      </div>
    );
  };

  renderSelectCollection = collectionPoint => {
    if (this.props.selectedCollectionId && collectionPoint) return null;

    //const showNewCollectionHotSpot = (this.props.errorMessages.selectCollection && this.props.userCollections.length === 0);
    const showNewCollectionHotSpot =
      this.props.showSelectCollectionError &&
      this.props.userCollections.length === 0;
    console.log(`showNewCollectionHotSpot is ${showNewCollectionHotSpot}`);

    return (
      <div className="select-collection">
        <div className="first-row">
          <div className="help-text">
            <span>Collection: </span>
            <span className="none-selected">None selected</span>
          </div>
          <div className="new-collection-link">
            <ActionLink
              onClick={this.props.openCreateCollectionModal}
              linkClassName=""
              iconType="averPointIcon"
              iconName="ADD"
              label="New Collection"
              toolTipText="Create a new Collection for your new Point."
              toolTipPosition="bottom"
            />
            {showNewCollectionHotSpot && <Icon icon={ICONS.ARROW_BACKWARD} />}
          </div>
        </div>
        <div className="collections-list">
          {this.renderSearchableDropDown(collectionPoint)}
        </div>
      </div>
    );
  };

  renderSearchableDropDown = collectionPoint => {
    if (this.props.selectedCollectionId && collectionPoint) return null;

    return (
      <SearchableDropDown
        hideSearchInput={this.props.userCollections.length < 5}
        objKey="text"
        data={this.props.userCollections}
        renderItem={this.renderItem}
        labelName="Search your Collections"
        handleClick={this.props.onCollectionSelected}
        selectedCollectionId={this.props.selectedCollectionId}
      />
    );
  };
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;
  const userCollections = [];
  if (authenticated) {
    const portfolioObject = state.portfolios[user.username];
    if (portfolioObject) {
      // const loadedCollections = portfolioObject.portfolio.collectionPoints.filter( (collectionId) => state.points[collectionId] != null);
      portfolioObject.portfolio.collectionPoints.forEach(collectionPointId => {
        const collectionPoint = state.points[collectionPointId];
        if (!collectionPoint) return;
        if (
          collectionPoint.permissionLevel !== 'write' ||
          collectionPoint.invitationStatus !== 'accepted'
        )
          return;
        userCollections.push(collectionPoint.point);
      });
      // userCollections = loadedCollections.map(
      //   collectionId => state.points[collectionId].point,
      // );
    } else {
      console.log('Error - trying to add to collection but not logged in');
    }
  }
  return {
    authenticated,
    user,
    userCollections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: {
          title,
          message,
        },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
    openErrorModal: (type, isExtension, message) => {
      const title = 'Error';
      const props = {
        model: {
          title,
          message,
          isExtension,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddToCollectionComponent);

import React from 'react';

import QuestionText from './QuestionText';

export default function InputTextQuestion({
  question,
  onChange,
  model,
  submitted,
}) {
  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderInput() {
    const value = model[question.variable] || '';

    return (
      <input
        type="text"
        className="form-control"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={question.placeholder}
        autoFocus={question.autoFocus}
      />
    );
  }

  function renderValidationMessage() {
    if (!submitted || !question.validationMessage) {
      return null;
    }

    const value = model[question.variable] || '';

    if (question.minLength && value.length >= question.minLength) {
      return null;
    }

    if (question.required && value) {
      return null;
    }

    return (
      <div className="av-red mt-1 font-size-12">
        {question.validationMessage}
      </div>
    );
  }
  return (
    <React.Fragment>
      {renderText()}
      {renderInput()}
      {renderValidationMessage()}
    </React.Fragment>
  );
}

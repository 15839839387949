import React from 'react';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';

import CloudinaryImage from 'components/elements/molecules/Images/CloudinaryImage';

import Checkbox from 'components/elements/atoms/Checkbox/Checkbox';
import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';

import './addPicture.scss';
//import RadioGroup from '../../elements/Radio/RadioGroup';

const erroMessageTypes = {
  NotesError: 'NotesError',
};

class AddPictureComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      photographerName: '',
      originalURL: '',
      errorMessage: '',
      errorMessages: [],
      notes: '',
    };
  }
  handleChange = (event) => {
    const key = event.target.name;
    this.setState({
      [key]: event.target.value,
    });
  };

  onSubmit = (e) => {
    //do validation
    e.preventDefault();

    const isValid = this.validateTerms();

    const { licenseType, photographerName, originalURL, acceptTerms } =
      this.state;
    const terms = {
      licenseType,
      photographerName,
      originalURL,
      acceptTerms,
    };

    if (isValid) this.props.onLicenseTermsAccepted(terms);
  };

  validateTerms = () => {
    const licenseType = this.state.licenseType;
    let errorMessages = this.state.errorMessages;

    if (!licenseType) {
      errorMessages['licenseType'] = 'You must select a license type.';
      this.setState({
        errorMessages,
      });
      return false;
    } else if (licenseType == 'notrequired') {
      errorMessages['licenseType'] = '';
      this.setState({
        errorMessages,
      });
      return true;
    } else {
      if (!this.state.photographerName) {
        errorMessages['photographerName'] =
          'Please include the name of photographer';
        this.setState({
          errorMessages,
        });
        return false;
      } else {
        errorMessages['photographerName'] = '';
      }

      if (!this.state.originalURL) {
        errorMessages['originalURL'] =
          'Please include the url to the original photo.';
        this.setState({
          errorMessages,
        });
        return false;
      } else {
        errorMessages['originalURL'] = '';
      }

      if (!this.state.acceptTerms) {
        errorMessages['acceptTerms'] = 'You must accept terms of this license.';
        this.setState({
          errorMessages,
        });
        return false;
      } else {
        errorMessages['acceptTerms'] = '';
      }

      this.setState({
        errorMessages,
      });
      return true;
    }
  };

  render() {
    var submitDisabled = this.state.errorMessage != '';

    return (
      <div>
        <form>
          <div className="add-picture-component">
            <div className="container">
              <div className="row">
                <div className="col-xs-3">{this.renderPhoto()}</div>
                <div className="col-xs-9">
                  <RadioGroup
                    name="licenseType"
                    label={'License type'}
                    handleChange={this.handleChange}
                    errorMessage={this.state.errorMessages['licenseType']}
                  >
                    <RadioOption
                      name="licenseType"
                      id="notrequired"
                      value="notrequired"
                      label="No license required"
                      isChecked
                    />
                    <RadioOption
                      name="licenseType"
                      id="sharealike"
                      value="sharealike"
                      label="Creative commons - Share Alike"
                    />
                    <RadioOption
                      name="licenseType"
                      id="attribution"
                      value="attribution"
                      label="Creative commons - Attribution"
                    />
                    <RadioOption
                      name="licenseType"
                      id="commercial"
                      value="commerial"
                      label="Commerial"
                    />
                  </RadioGroup>
                  <div className="photographerName">
                    <FloatingInput
                      name="photographerName"
                      id="photographer-name"
                      value={this.state.photographerName}
                      onChange={this.handleChange}
                      error={this.state.errorMessages['photographerName']}
                      labelName="Photographer"
                    />
                  </div>
                  <div className="originalURL">
                    <FloatingInput
                      name="originalURL"
                      id="original-url"
                      value={this.state.originalURL}
                      onChange={this.handleChange}
                      error={this.state.errorMessages['originalURL']}
                      labelName="Original URL"
                    />
                  </div>
                  <div className="acceptTerms">
                    <Checkbox
                      name="acceptTerms"
                      handleChange={() => {
                        this.setState({ acceptTerms: !this.state.acceptTerms });
                      }}
                      checked={this.state.acceptTerms}
                      error={this.state.errorMessages['acceptTerms']}
                    >
                      I agree to the terms and conditions of this license.
                    </Checkbox>
                  </div>
                </div>
                <div className="col-xs-12">
                  <button type="cancel" className="btn btn-secondary">
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    onClick={this.onSubmit}
                    className="btn btn-success"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderPhoto() {
    return (
      <CloudinaryImage
        cloudinaryImageId={this.props.cloudinaryImageId}
        cloudinaryVersion={this.props.hostedImageVersion}
        height={200}
        cropType="fit"
        imageClass={'previewPhoto'}
        handleClick={null}
        allowUserToChangePic={false}
        photoLicenseTerms={{ status: 'pending' }}
      />
    );
  }
}

export default AddPictureComponent;

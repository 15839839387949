import React from 'react';

export default function QuestionText({ question }) {
  function renderTitle() {
    if (!question.title) {
      return null;
    }

    return <h3 className="mt-0">{question.title}</h3>;
  }

  function renderSubTitle() {
    if (!question.subText) {
      return null;
    }

    return <div className="question-sub-title">{question.subText}</div>;
  }

  return (
    <React.Fragment>
      {renderTitle()}
      <div className="question-title">{question.text}</div>
      {renderSubTitle()}
    </React.Fragment>
  );
}

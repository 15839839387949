import React, { Fragment, useReducer } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import './InteractionReview.scss';
import { Icon, ICONS } from 'components/Icons';

function getDate(date) {
  let createdAt = moment(date);
  let isSameDay = createdAt.isSame(new Date(), 'day');
  let dateStr;

  if (isSameDay) {
    dateStr = `today at ${createdAt.format('hh:mm A')}`;
  } else {
    dateStr = createdAt.format('DD MMM, yyyy');
  }

  return dateStr;
}

export default function InteractionReviews({
  interactionId,
  interactionType,
  courseId,
  actions,
  interactionReviews,
  user,
  interaction,
  point,
  teacherMode,
}) {
  function renderTeacherComment() {
    if (
      !courseId ||
      !teacherMode ||
      (interactionReviews &&
        interactionReviews.length &&
        interactionReviews[interactionReviews.length - 1].userRole ===
          'teacher')
    ) {
      return null;
    }

    return (
      <TeacherComment
        interactionId={interactionId}
        interactionType={interactionType}
        courseId={courseId}
        actions={actions}
        point={point}
        interaction={interaction}
      />
    );
  }

  function renderInteractionReview(interactionReview) {
    return (
      <InteractionReview interactionReview={interactionReview} user={user} />
    );
  }

  function renderInteractionReviews() {
    if (!interactionReviews) {
      return <span className="fa fa-spin fa-spinner" />;
    }

    const isStudent = user.user.profile.roles.find(
      (i) => i.type === 'student' && i.status === 'activated',
    );

    let reverseInteractionReviews = [...interactionReviews].reverse();

    if (
      isStudent &&
      (!reverseInteractionReviews.length ||
        reverseInteractionReviews[0].userRole === 'student')
    ) {
      reverseInteractionReviews = [{}, ...reverseInteractionReviews];
    }

    const reviews = reverseInteractionReviews.map((interactionReview) => {
      return (
        <div className="review" key={interactionReview._id}>
          {renderInteractionReview(interactionReview)}
        </div>
      );
    });

    return <div className="interaction-reviews">{reviews}</div>;
  }

  return (
    <div className="mt-3">
      {renderTeacherComment()}
      {renderInteractionReviews()}
    </div>
  );
}

function InteractionReview({ interactionReview, user }) {
  function renderTeacherReviewPending() {
    return (
      <div className="interaction-review">
        <i>Teacher's review pending</i>
      </div>
    );
  }

  function renderStudentUpdated() {
    return (
      <div className="interaction-review">
        Student updated on {getDate(interactionReview.createdAt)}.
      </div>
    );
  }

  function renderTeacherReview() {
    return <TeacherReview interactionReview={interactionReview} />;
  }

  if (interactionReview.userRole === 'teacher') {
    return renderTeacherReview();
  } else if (interactionReview.userRole === 'student') {
    return renderStudentUpdated();
  } else {
    return renderTeacherReviewPending();
  }

  return null;
}

function TeacherReview({ interactionReview }) {
  function renderTitle() {
    if (interactionReview.status === 'Approved') {
      return (
        <div>Teacher approved on {getDate(interactionReview.createdAt)}.</div>
      );
    } else if (interactionReview.status === 'Rejected') {
      return (
        <div>
          Teacher sent this back for revision on{' '}
          {getDate(interactionReview.createdAt)}.
        </div>
      );
    }
  }

  return (
    <div className="interaction-review">
      {renderTitle()}
      <div className="mt-2 comment">
        "<i>{interactionReview.comment}</i>"
      </div>
    </div>
  );
}

function TeacherComment({
  interactionId,
  interactionType,
  courseId,
  actions,
  interaction,
  point,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_COMMENT':
          return {
            ...state,
            comment: action.payload,
          };
        case 'SET_STATUS':
          return {
            ...state,
            status: action.payload,
          };
        default:
          return state;
      }
    },
    {
      comment: '',
      status: 'Approved',
    },
  );

  async function onSubmit() {
    const interactionReview = {
      interactionId,
      interactionType,
      courseId,
      comment: state.comment,
      status: state.status,
    };
    await actions.addInteractionReview(interactionReview);
    await actions.getInteractionReviews(interaction, interactionType, point);
    dispatch({ type: 'SET_COMMENT', payload: '' });
    dispatch({ type: 'SET_STATUS', payload: 'Approved' });
  }

  function renderApproved() {
    const className = classnames(
      'btn approved mr-2 d-inline-flex align-items-center justify-content-center',
      {
        green: state.status === 'Approved',
      },
    );
    return (
      <Fragment>
        <button
          className={className}
          onClick={() => {
            dispatch({ type: 'SET_STATUS', payload: 'Approved' });
          }}
        >
          <Icon icon={ICONS.CHECK} size={10} className="mr-0" />
        </button>
        Approved
      </Fragment>
    );
  }

  function renderRejected() {
    const className = classnames(
      'btn rejected mr-2 ml-4 d-inline-flex align-items-center justify-content-center',
      {
        red: state.status === 'Rejected',
      },
    );
    return (
      <Fragment>
        <button
          className={className}
          onClick={() => {
            dispatch({ type: 'SET_STATUS', payload: 'Rejected' });
          }}
        >
          <Icon icon={ICONS.REFRESH} size={10} className="mr-0" />
        </button>
        Send Back
      </Fragment>
    );
  }

  return (
    <div className="interaction-review">
      <p className="font-weight-bold gray-3 mb-2">Your Review</p>
      <div className="d-flex align-items-center">
        {renderApproved()} {renderRejected()}
      </div>
      <textarea
        className="mt-2 form-control comment"
        placeholder="Comment"
        onChange={(event) => {
          dispatch({ type: 'SET_COMMENT', payload: event.target.value });
        }}
        value={state.comment}
      ></textarea>
      <div className="text-right mt-2">
        <button
          className="btn btn-primary btn-xs"
          type="button"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_RESEARCH_SUBMISSIONS = 'LOAD_RESEARCH_SUBMISSIONS';
export const ADD_RESEARCH_SUBMISSION = 'ADD_RESEARCH_SUBMISSION';
export const UPDATE_RESEARCH_SUBMISSION = 'UPDATE_RESEARCH_SUBMISSION';
export const REMOVE_RESEARCH_SUBMISSION = 'REMOVE_RESEARCH_SUBMISSION';
export const LOAD_RESEARCH_SUBMISSION_REVIEWS = 'LOAD_RESEARCH_SUBMISSION_REVIEWS';

const loadResearchSubmissions = (researchSubmissions) => {
  return { type: LOAD_RESEARCH_SUBMISSIONS, researchSubmissions };
};

const addResearchSubmission = (researchSubmission) => {
  return { type: ADD_RESEARCH_SUBMISSION, researchSubmission };
};

const updateResearchSubmissionFn = (researchSubmission) => {
  return { type: UPDATE_RESEARCH_SUBMISSION, researchSubmission };
};

const deleteResearchSubmissionFn = (researchSubmissionId) => {
  return {
    type: REMOVE_RESEARCH_SUBMISSION,
    researchSubmissionId,
  };
};

const loadResearchSubmissionReviews = (researchSubmissionId, reviews) => {
  return {
    type: LOAD_RESEARCH_SUBMISSION_REVIEWS,
    researchSubmissionId,
    reviews,
  };
};

export function getResearchSubmission(promptId, researchSubmissionId, studentId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchSubmissions/getResearchSubmission', {
          params: { promptId, researchSubmissionId, studentId },
        })
        .then((response) => {
          const data = response.data;

          if (data.researchSubmission) {
            dispatch(addResearchSubmission(data.researchSubmission));
          }

          return resolve(data.researchSubmission);
        })
        .catch((error) => {
          console.log('Error while fetching research submission', error);
          toast('Error occurred while fetching research submission. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function createResearchSubmission(researchSubmission) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/researchSubmissions/createResearchSubmission', researchSubmission)
        .then((response) => {
          const data = response.data;
          dispatch(addResearchSubmission(data.researchSubmission));
          return resolve(data.researchSubmission);
        })
        .catch((error) => {
          console.log('Error while create research submission', error);
          toast('Error occurred while creating research submission. Please try again.', {
            type: 'error',
          });
          return reject();
        });
    });
  };
}

export function updateResearchSubmission(researchSubmission) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/researchSubmissions/updateResearchSubmission', researchSubmission)
        .then((response) => {
          const data = response.data;
          // data.researchSubmission.promptId = { _id: data.researchSubmission.promptId };
          dispatch(updateResearchSubmissionFn(data.researchSubmission));
          return resolve(data.researchSubmission);
        })
        .catch((error) => {
          console.log('Error while update research submission', error);
          toast('Error occurred while updating research submission. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function updateStatusOfResearchSubmission(researchSubmission, comment, grade) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/researchSubmissions/updateStatusOfResearchSubmission', {
          researchSubmissionId: researchSubmission._id,
          status: researchSubmission.status,
          comment,
          grade,
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateResearchSubmissionFn(data.researchSubmission));
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while update research submission', error);
          toast('Error occurred while updating research submission. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getResearchSubmissionsForStudents(courseId, topicId, studentId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchSubmissions/getResearchSubmissionsForStudent', {
          params: { courseId, topicId, studentId },
        })
        .then((response) => {
          const data = response.data;

          dispatch(loadResearchSubmissions(data.researchSubmissions));

          return resolve(data.researchSubmissions);
        })
        .catch((error) => {
          console.log('Error while fetching research submission', error);
          toast('Error occurred while fetching research submission. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function deleteResearchSubmission(courseId, researchSubmissionId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/researchSubmissions/deleteResearchSubmission', {
          params: { courseId, researchSubmissionId },
        })
        .then(() => {
          dispatch(deleteResearchSubmissionFn(researchSubmissionId));
          toast('ResearchSubmission has been deleted successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while deleting research submission', error);
          toast('Error occurred while deleting research submission. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getResearchSubmissionsForResearchPrompt(courseId, topicId, promptId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchSubmissions/getResearchSubmissionsForResearchPrompt', {
          params: { courseId, topicId, promptId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadResearchSubmissions(data.researchSubmissions));
          return resolve(data.researchSubmissions);
        })
        .catch((error) => {
          console.log('Error while fetching research submission', error);
          toast('Error occurred while fetching research submission. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getResearchSubmissionReviews(researchSubmissionId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/researchSubmissions/getResearchSubmissionReviews', {
          params: { researchSubmissionId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadResearchSubmissionReviews(researchSubmissionId, data.reviews));
          return resolve(data.reviews);
        })
        .catch((error) => {
          console.log('Error while fetching research submission reviews', error);
          toast(
            'Error occurred while fetching research submission reviews. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

import React, { useEffect } from 'react';
import { Icon, ICONS } from 'components/Icons';

import QuestionText from './QuestionText';

export default function CounterGroup({ question, onChange, model }) {
  useEffect(() => {
    const value = [];
    question.items.forEach((item, index) => {
      const goal = getInitialGoal(item, index);
      value.push({
        goal: item.key,
        target: goal,
      });
    });
    onChange(value, question.variable);
  }, []);

  useEffect(() => {}, model);

  function renderText() {
    return <QuestionText question={question} />;
  }

  function changeCounter(key, isDecrement = false) {
    let value = model[question.variable];

    if (!value && typeof value !== 'object') {
      value = [];
    }

    const existingGoalIndex = value.findIndex((v) => v.goal === key);

    const existingValue =
      existingGoalIndex > -1 ? value[existingGoalIndex].target : 0;

    if (existingValue === 0 && isDecrement) {
      return;
    }

    const newValue = isDecrement ? existingValue - 1 : existingValue + 1;

    const goal = {
      goal: key,
      target: newValue,
    };

    if (existingGoalIndex > -1) {
      value.splice(existingGoalIndex, 1, goal);
    } else {
      value.push(goal);
    }

    onChange(value, question.variable);
  }

  function getInitialGoal(item) {
    let goal = 0;
    switch (item.key) {
      case 'NUM_ARTICLES_READ':
        goal = getFrequencyGoal();
        break;
      case 'NUM_TOPICS_READ':
        goal = getTopicGoal();
        break;
      case 'NUM_INTERNATIONAL_READ':
        goal = getInternationalGoal();
        break;
      case 'NUM_NATIONAL_READ':
        goal = 3;
        break;
      case 'NUM_REGIONAL_OR_LOCAL_READ':
        goal = getLocalGoal();
        break;
      default:
        goal = 0;
    }
    return goal;
  }

  function getFrequencyGoal() {
    switch (model.frequencyOfNews) {
      case 'Every day':
        return 7;
      case 'A few times a week':
        return 5;
      case 'Rarely, if ever':
        return 3;
      default:
        return 2;
    }
  }

  function getTopicGoal() {
    if (model.newsGoals.indexOf('Read about more topics') !== -1) return 5;
    else return 3;
  }

  function getInternationalGoal() {
    if (model.newsGoals.indexOf('Read more international news') !== -1)
      return 5;
    else return 3;
  }

  function getLocalGoal() {
    if (model.newsGoals.indexOf('Read more local news') !== -1) return 5;
    else return 3;
  }

  function renderCounter(item, index) {
    const value = model[question.variable];

    let counter = 0;
    if (Array.isArray(value)) {
      const existingGoal = value.find((v) => v.goal === item.key);

      counter = existingGoal ? existingGoal.target : 0;
    }

    return (
      <div className="d-flex align-items-center justify-content-between py-3">
        <div className="d-flex">
          <div className="av-blue">{item.text}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="av-blue mx-4">
            <Icon
              icon={ICONS.REMOVE}
              size={16}
              onClick={() => changeCounter(item.key, true)}
            />
          </div>
          <div className="count">{counter}</div>
          <div className="icon">
            <Icon
              icon={ICONS.ADD}
              size={16}
              onClick={() => changeCounter(item.key)}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderCounters() {
    return (
      <div className="mt-3">
        {question.items.map((item, index) => renderCounter(item, index))}
      </div>
    );
  }

  return (
    <div className="counter-group">
      {renderText()}
      {renderCounters()}
    </div>
  );
}

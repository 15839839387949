import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, ICONS } from 'components/Icons';

import './selectionTag.scss';

import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';

class SelectionTag extends Component {
  static = {
    defaultTag: PropTypes.string,
    onSelectType: PropTypes.func,
    fieldName: PropTypes.string,
    tags: PropTypes.array,
    toolTips: PropTypes.array,
    disabledTags: PropTypes.array,
    readOnly: PropTypes.bool,
    icons: PropTypes.array,
    isRound: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.defaultTag ? this.props.defaultTag : '',
    };
  }

  handleChange = (event) => {
    if (this.props.readOnly) {
      return;
    }

    const type = event.target.value;
    this.setState({
      type,
    });
    console.log('handleChange', event, type, this);
    this.props.onSelectType(type);
  }

  render() {
    const fieldName = this.props.fieldName;
    const tags = this.props.tags;
    const toolTips = this.props.toolTips;

    let className = 'selection-container btn-group-toggle';

    if (this.props.readOnly) {
      className += ' read-only';
    }

    return (
      <div className={className}>
        {tags.map((tag, index) => {
          const toolTipText =
            toolTips && toolTips[index] ? toolTips[index] : null;
          const disabled =
            this.props.disabledTags && this.props.disabledTags[index];
          return (
            <label
              key={tag}
              htmlFor={`${fieldName}_${tag}Radio`}
              className={`${this.props.isRound ? '' : 'btn tag-item'} mb-2 ${this.state.type === tag ? 'selected' : ''
                } ${disabled ? ' disabled' : ''}`}
            >
              <ToolTip
                toolTipText={toolTipText}
                toolTipPosition="bottom"
                className={`${this.props.isRound ? 'roundTagToolTip' : 'tagToolTip'
                  }`}
              >
                {this.props.icons && (
                  <Icon icon={ICONS[this.props.icons[index]]} size={12} />
                )}
                <input
                  type="radio"
                  name={fieldName}
                  id={`${fieldName}_${tag}Radio`}
                  autoComplete="off"
                  onChange={this.handleChange}
                  checked={this.state.type === tag}
                  value={tag}
                  className={'mt-0 mr-2'}
                />
                {tag}
              </ToolTip>
            </label>
          );
        })}
      </div>
    );
  }
}

export default SelectionTag;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";
import { Icon, ICONS } from "../../Icons";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";
import Step7 from "./components/Step7";
import Step8 from "./components/Step8";
import Step9 from "./components/Step9";
import Step10 from "./components/Step10";
import StepFinal from "./components/StepFinal";


import "./css/WalkthroughModal.scss";
class WalkthroughModal extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  renderStep = step => {
    let stepText = "";
    let buttonText = "Got it";
    const nextStepNumber = step + 1;

    switch (step) {
      case 1:
        buttonText = "Let's start!";
        stepText = (
          <div>
            Let us show you around! <br />
            <br />
            This quick interactive tour will introduce the 3 parts of AverPoint:
            <ol>
              <li>Collections</li>
              <li>Points</li>
              <li>Sources</li>
            </ol>
            <br />
            In the tour, you'll create your first <b>Collection</b> and your
            first <b>Point</b>.
            <br />
            <br />
          </div>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);
      // return <Step1 gotoNextStep={this.props.gotoNextStep} />;
      case 2:
        stepText = (
          <div>
            This is your personal page on AverPoint. It lists all the{" "}
            <b>Collections</b> you are researching.
            <br />
            <br />
          </div>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);
      // return <Step1 gotoNextStep={this.props.gotoNextStep} />;
      case 4:
        stepText = (
          <div>
            Each <b>Collection</b> is a bucket for your research and organized
            around a topic like Travel, Health care, or Sports.
            <br />
          </div>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);
      // return <Step1 gotoNextStep={this.props.gotoNextStep} />;
      case 7:
        stepText = (
          <div>
            Nice job! This is your first <b>Collection</b>... When you create{" "}
            <b>Points</b> on this topic, you will save them here.
            <br />
          </div>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);
      case 8:
        buttonText = "I made a Point from the internet";
        stepText = (
          <div>
              Now, it's your turn to create a Point! <br />
            <br />
              If you installed the{" "}
            <a
              href="https://chrome.google.com/webstore/detail/averpoint/delinpnhfdkcjeifmapikhinioghdeia?authuser=0"
              target="_blank"
            >
                Chrome extension
            </a>
              , follow these steps to create your first Point from the internet:
            <br />
            <ol>
              <li> Open a new tab. </li>
              <li> Go to your favorite news site. </li>
              <li> Click on an article. </li>
              <li> Highlight an interesting sentence. </li>
              <li>
                {" "}
                  Click the AverPoint button in the top right of the browser.{" "}
              </li>
              <li>
                {" "}
                  Create your <b>Point.</b>{" "}
              </li>
            </ol>
            <br />
          </div>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);
      case 9:
        buttonText = "Visit the example Point";
        stepText = (
          <p>
            Now that you can create <b>Points</b> from any website, let’s visit
            a <b>Point</b> we created for this tutorial.
            <br />
          </p>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);

      case 10:
        stepText = (
          <p>
            This is a <b>Point</b> we created for the tutorial. It has it's main
            text, a source, and is classified by its author as a Fact or
            Opinion.
          </p>
        );
        return this.renderSimpleStep(stepText, buttonText, nextStepNumber);

      case 100:
        return <Step10 gotoNextStep={this.props.gotoNextStep} />;
      case 12:
        return <StepFinal onComplete={this.props.onComplete} />;
      default:
        return <div>End of tour</div>;
    }
  };

  renderSimpleStep = (stepText, buttonText, nextStepNumber) => (
      <div className="simple-step">
        {stepText}
        <div className="button-row">
          <div className="next-step-button">
            <RoundedButton
              label={buttonText}
              onClick={() => this.props.gotoNextStep(nextStepNumber)}
            />
          </div>
          <div className="skip-link" onClick={this.props.onComplete}>
            End tour
          </div>
        </div>
      </div>
    );

  render() {
    const { model, step, onClose, walkthrough } = this.props;
    const modalSteps = [1, 2, 4, 7, 8, 9, 10, 100, 12];
    const visibleStep = step && modalSteps.indexOf(step) != -1;
    const showModal = visibleStep && walkthrough.tourStatus === "inProgress";

    if (walkthrough.tourStatus == "finished") return <div />;

    // const title = model.title;
    let title = `AverPoint Tutorial (${  step  }/12)`;
    if (step == 1) title = "AverPoint Tutorial";

    return (
      <Modal
        show={showModal}
        className="walkthrough-modal"
        backdropClassName="walkthrough-modal-backdrop"
      >
        <Modal.Header bsClass="walkthrough-modal-header">
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <Modal.Title bsClass="walkthrough-modal-title">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{this.renderStep(step)}</Modal.Body>
      </Modal>
    );
  }
}

export default WalkthroughModal;

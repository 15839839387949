import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import SourceStatusChange from './SourceStatusChange';
import BrowserExtensionStatus from './BrowserExtensionStatus';
import sourceFormatOptions from 'config/sourceFormat';

import './UpdateSourceDetails.scss';

function ManageCourse({ source, onClose, actions }) {
  const sourceNameRef = useRef(source.sourceName);
  const [sourceForm, setSourceForm] = useState(source);
  const [notWorkingCount, setNotWorkingCount] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let browserExtensionStatus = sourceForm.browserExtensionStatus;

    if (!Array.isArray(browserExtensionStatus)) {
      browserExtensionStatus = [
        {
          componentType: 'credibilityLayer',
        },
        {
          componentType: 'quiz',
        },
        {
          componentType: 'readTime',
        },
      ];
    }

    if (!Array.isArray(sourceForm.specialURLs)) {
      setSourceForm((current) => ({
        ...current,
        homePage: '',
        opinionPage: '',
        browserExtensionStatus,
      }));

      return;
    }

    const homePage = sourceForm.specialURLs.find((s) => s.rule === 'HOME_PAGE');
    const opinionPage = sourceForm.specialURLs.find(
      (s) => s.rule === 'OPINION_PAGE',
    );

    setSourceForm((current) => ({
      ...current,
      isApprovedForAnalysis:
        sourceForm.isApprovedForAnalysis ||
        sourceForm.sourceApprovalStatus === 'approved',
      sourceApprovalStatus: sourceForm.isApprovedForAnalysis
        ? 'approved'
        : sourceForm.sourceApprovalStatus,
      notApprovedReason: sourceForm.notApprovedReason || '',
      homePage: homePage ? homePage.URL : '',
      opinionPage: opinionPage ? opinionPage.URL : '',
      browserExtensionStatus,
    }));
  }, []);

  const createCourse = () => {
    setLoading(true);

    const source = JSON.parse(JSON.stringify(sourceForm));

    let specialURLs = source.specialURLs;

    if (!Array.isArray(specialURLs)) {
      specialURLs = [];
    }

    const homeSpecialURL = specialURLs.find((s) => s.rule === 'HOME_PAGE');

    if (homeSpecialURL) {
      homeSpecialURL.URL = source.homePage;
    } else {
      specialURLs.push({ rule: 'HOME_PAGE', URL: source.homePage });
    }

    const opinionSpecialURL = specialURLs.find(
      (s) => s.rule === 'OPINION_PAGE',
    );

    if (opinionSpecialURL) {
      opinionSpecialURL.URL = source.opinionPage;
    } else {
      specialURLs.push({ rule: 'OPINION_PAGE', URL: source.opinionPage });
    }

    const sourceDetails = {
      sourceId: source._id,
      sourceName: source.sourceName,
      originalSourceName: sourceNameRef.current,
      mediaClaimRule: source.mediaClaimRule,
      specialURLs,
      sourceApprovalStatus: source.sourceApprovalStatus,
      notApprovedReason: (source.sourceApprovalStatus === 'notApproved' && source.notApprovedReason) || '',
      componentReviews: source.browserExtensionStatus,
      sourceFormat: source.sourceFormat,
    };

    actions
      .updateSourceDetails(sourceDetails)
      .then((response) => {
        if (!response || !response.notWorkingArticlesCount) {
          onClose();
          return;
        }

        setNotWorkingCount(response.notWorkingArticlesCount);
      })
      .catch(() => {
        console.log('Error occurred while updating source');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormChange = (name, value) => {
    setSourceForm((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const showSourceFormat = () => (
    <React.Fragment>
      <div className="d-flex align-items-center mt-4">
        <div className="mr-4">
          Source Format
        </div>
        <select
          className="source-format-selector"
          value={sourceForm.sourceFormat}
          onChange={(e) => {
            handleFormChange('sourceFormat', e.target.value);
          }}
        >
          <option value="">Select Format</option>
          {
            sourceFormatOptions.map((sourceFormatOption, index) => (
              <option key={index} value={sourceFormatOption.value}>{sourceFormatOption.label}</option>
            ))
          }
        </select>
      </div>
    </React.Fragment>
  );

  const showButton = () => {
    if (loading) {
      return (
        <div className="mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '40px' }}
          />
        </div>
      );
    }

    return (
      <RectangularButton className="next-button" onClick={() => createCourse()}>
        Submit
      </RectangularButton>
    );
  };

  return (
    <Modal show={true} className="edit-source-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Edit Source</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {!notWorkingCount ? (
          <React.Fragment>
            <FloatingInput
              autoFocus={true}
              value={sourceForm.sourceName || ''}
              labelName="Source Name"
              name="sourceName"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              error={error || ''}
            />
            <SourceStatusChange
              source={sourceForm}
              handleFormChange={handleFormChange}
            />
            {
              sourceForm.sourceApprovalStatus === 'notApproved' ? (
                <div className="mt-4">
                  <FloatingInput
                    value={sourceForm.notApprovedReason || ''}
                    labelName="Not approved reason"
                    name="notApprovedReason"
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    error={error || ''}
                  />
                </div>
              ) : null
            }
            {showSourceFormat()}
            <FloatingInput
              value={sourceForm.homePage || ''}
              labelName="Home Page"
              name="homePage"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              error={error || ''}
              className="mt-4"
            />
            <FloatingInput
              value={sourceForm.opinionPage || ''}
              labelName="Opinion page"
              name="opinionPage"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              error={error || ''}
            />
            <FloatingInput
              value={sourceForm.mediaClaimRule || ''}
              labelName="Media Claim Rule"
              name="mediaClaimRule"
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
              error={error || ''}
            />
            <BrowserExtensionStatus
              source={sourceForm}
              handleFormChange={handleFormChange}
            />
            {showButton()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="mb-4">
              The {sourceForm.sourceName} is now marked as working. There are{' '}
              {notWorkingCount} specific articles that require your attention.
              Please go to the Articles tab and search for{' '}
              {sourceForm.sourceName} to individually verify these links are
              working.
            </div>
            <RectangularButton
              className="next-button"
              onClick={() => onClose()}
            >
              Close
            </RectangularButton>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageCourse);

import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import RequestClassroomDemo from 'routes/LandingPage/components/RequestClassroomDemo';

import './RequestClassroomDemoModal.scss';

function RequestClassroomDemoModal({ onClose, onSubmit = null }) {
  return (
    <Modal
      show={true}
      className="request-classroom-demo-modal"
    >
      <Modal.Body>
        <div className="header">
          <span>Request a demo</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <RequestClassroomDemo onClose={onClose} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps() {
  return {};
}

export default connect(null, mapDispatchToProps)(RequestClassroomDemoModal);

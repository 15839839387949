import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import SyncAutoComplete from 'components/elements/molecules/SyncAutoComplete/SyncAutoComplete';
import MultiChipComponent from '../../../elements/molecules/MultiChipComponent/MultiChipComponent';

export default function AnalyticsDetails({
  appData,
  point,
  changeSourceAnalytics,
}) {
  const level = point && point.sourceAnalytics && point.sourceAnalytics.level;
  const { topics = {}, additionalIssues, mainIssues } =
    (appData && appData.topics) || {};

  const [secondLevelTopics, thirdLevelTopics] = useMemo(() => {
    let topicsForSecondLevel = [];

    if (Array.isArray(point.sourceAnalytics.topic)) {
      const selectedMainTopics = point.sourceAnalytics.topic;

      for (const mainTopic of selectedMainTopics) {
        topicsForSecondLevel = topicsForSecondLevel.concat(
          Object.keys(topics[mainTopic]),
        );
      }
    }

    if (Array.isArray(point.sourceAnalytics.secondLevelTopic)) {
      const updatedSecondLevelTopic = _.intersection(
        point.sourceAnalytics.secondLevelTopic,
        topicsForSecondLevel,
      );
      if (
        updatedSecondLevelTopic.length !==
        point.sourceAnalytics.secondLevelTopic.length
      ) {
        changeSourceAnalytics(
          'secondLevelTopic',
          _.intersection(
            point.sourceAnalytics.secondLevelTopic,
            topicsForSecondLevel,
          ),
        );
      }
    }

    let topicsForThirdLevel = [];

    if (Array.isArray(point.sourceAnalytics.secondLevelTopic)) {
      const selectedMainTopics = point.sourceAnalytics.topic;
      const selectedTopics = point.sourceAnalytics.secondLevelTopic;

      for (const secondLevelTopic of selectedTopics) {
        for (const mainTopic of selectedMainTopics) {
          if (topics[mainTopic][secondLevelTopic]) {
            topicsForThirdLevel = topicsForThirdLevel.concat(
              Object.keys(topics[mainTopic][secondLevelTopic]),
            );
          }
        }
      }
    }

    if (Array.isArray(point.sourceAnalytics.thirdLevelTopic)) {
      const updatedThirdLevelTopic = _.intersection(
        point.sourceAnalytics.thirdLevelTopic,
        topicsForThirdLevel,
      );
      if (
        updatedThirdLevelTopic.length !==
        point.sourceAnalytics.thirdLevelTopic.length
      ) {
        changeSourceAnalytics(
          'thirdLevelTopic',
          _.intersection(
            point.sourceAnalytics.thirdLevelTopic,
            topicsForThirdLevel,
          ),
        );
      }
    }

    return [topicsForSecondLevel, topicsForThirdLevel];
  }, [
    point.sourceAnalytics.topic,
    point.sourceAnalytics.secondLevelTopic,
    topics,
  ]);

  return (
    <div className="body">
      <div className="section">
        <div className="multi-chip-component">
          <MultiChipComponent
            title="Main Topic"
            placeholder="Add main topic"
            type="topic"
            className="topic-input"
            onChange={changeSourceAnalytics}
            values={point.sourceAnalytics.topic || []}
            isMulti={true}
            isAutoComplete={true}
            options={Object.keys(topics)}
          />
        </div>
        {secondLevelTopics.length > 0 && (
          <div className="multi-chip-component mb-4">
            <MultiChipComponent
              title="Level 2 Topics"
              placeholder="Add topic"
              type="secondLevelTopic"
              className="topic-input"
              onChange={changeSourceAnalytics}
              values={point.sourceAnalytics.secondLevelTopic || []}
              isMulti={true}
              isAutoComplete={true}
              options={secondLevelTopics}
            />
          </div>
        )}
        {thirdLevelTopics.length > 0 && (
          <div className="multi-chip-component mb-4">
            <MultiChipComponent
              title="Level 3 Topics"
              placeholder="Add topic"
              type="thirdLevelTopic"
              className="topic-input"
              onChange={changeSourceAnalytics}
              values={point.sourceAnalytics.thirdLevelTopic || []}
              isMulti={true}
              isAutoComplete={true}
              options={thirdLevelTopics}
            />
          </div>
        )}
        <SyncAutoComplete
          autoFocus={false}
          value={point.sourceAnalytics.issue || ''}
          onSelect={value => changeSourceAnalytics('issue', value)}
          label="Main issue"
          options={mainIssues}
        />
        <SyncAutoComplete
          autoFocus={false}
          value={point.sourceAnalytics.additionalIssue || ''}
          onSelect={value => changeSourceAnalytics('additionalIssue', value)}
          label="Additional issue"
          options={additionalIssues}
        />
        <div className="multi-chip-component">
          <MultiChipComponent
            title="Location(s)"
            placeholder="Add location"
            type="locations"
            className="location-input"
            onChange={changeSourceAnalytics}
            values={point.sourceAnalytics.locations || []}
            isMulti={true}
          />
        </div>
        <div className="title mt-3">Level</div>
        <div className="level-container">
          <div
            className={`level ${level === 'beginner' ? 'active' : ''}`}
            onClick={() => changeSourceAnalytics('level', 'beginner')}
          >
            Beginner
          </div>
          <div
            className={`level ${level === 'intermediate' ? 'active' : ''}`}
            onClick={() => changeSourceAnalytics('level', 'intermediate')}
          >
            Intermediate
          </div>
          <div
            className={`level ${level === 'advanced' ? 'active' : ''}`}
            onClick={() => changeSourceAnalytics('level', 'advanced')}
          >
            Advanced
          </div>
        </div>
        <FloatingInput
          value={point.sourceAnalytics.people || ''}
          labelName="People"
          onChange={e => changeSourceAnalytics('people', e.target.value)}
        />
        <FloatingInput
          value={point.sourceAnalytics.organizations || ''}
          labelName="Organizations"
          onChange={e => changeSourceAnalytics('organizations', e.target.value)}
        />
        <FloatingInput
          value={point.sourceAnalytics.note || ''}
          labelName="Optional Note"
          onChange={e => changeSourceAnalytics('note', e.target.value)}
        />
      </div>
    </div>
  );
}

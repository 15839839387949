import axios from 'axios';

export const generateUniqueId = async () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: '/api/utility/getUniqueId',
    })
      .then((result) => {
        resolve(result.data._id);
        console.log('Got unique _id ');
        console.log(result.data._id);
      })
      .catch((result) => {
        reject();
        console.log('Error getting unique _id');
        console.log(result);
      });
  });
};

export const getInviteDetails = async (inviteId) => {
  return new Promise((resolve) => {
    axios({
      method: 'get',
      url: '/api/auth/getInviteDetails',
      params: {
        inviteId,
      },
    })
      .then((result) => {
        resolve(result.data.email);
        console.log('result.data getInviteDetails');
        console.log(result.data.email);
      })
      .catch((error) => {
        resolve('');
        console.log('Error getting getInviteDetails');
        console.log(error);
      });
  });
};

export const getFileTypeFromUrl = async (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: '/api/utility/getFileTypeFromUrl',
      params: { url },
    })
      .then((result) => {
        resolve(result.data.fileType || '');
        console.log('Got File Type');
        console.log(result.data.fileType);
      })
      .catch((error) => {
        resolve('');
        console.log('Error getting file type');
        console.log(error);
      });
  });
};

import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, NavLink, Link } from 'react-router-dom';
import {
  DropdownButton,
  MenuItem,
  Tabs,
  Tab,
  Nav,
  NavItem,
  Dropdown,
} from 'react-bootstrap';

import * as Actions from '../../../redux/actions/index';
import SearchItems from 'components/elements/molecules/SearchItems/SearchItems';
import { Icon, ICONS } from 'components/Icons';
import NavbarProfile from './Component/NavbarProfile/NavbarProfile';
import Notifications from './Component/Notifications/Notifications';
import MoreInfo from './Component/MoreInfo/MoreInfo';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';
import { getBrowserType, isIOS, isMobile } from '../../../helpers';
import AddPoint from 'components/modals/AddPoint/AddPoint';
import ProgressBar from 'components/elements/organisms/ProgressBar/ProgressBar';
import { AWSCognitoInstance } from '../../../services/AWSCognito';

import './Header.scss';

const REACT_APP_APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;
const REACT_APP_PLAY_STORE_URL = process.env.REACT_APP_PLAY_STORE_URL;

// function removeClass(el, className) {
//   if (!el) return;
//   if (el.classList) el.classList.remove(className);
//   else
//     el.className = el.className.replace(
//       new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
//       ' ',
//     );
// }

// function addClass(el, className) {
//   if (!el) return;
//   if (el.classList) el.classList.add(className);
//   else el.className += ` ${className}`;
// }

// const updateExtensionStatus = (() => {
//   let updated = false;
//   return (browser, isInstalled, userAgent) => {
//     if (updated) return;
//     updated = true;
//     axios.post('/api/users/updateExtensionInfo', {
//       browser,
//       isInstalled,
//       userAgent,
//     });
//   };
// })();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: false,
      showSearchBar: false,
      showAddPoint: false,
      showWhatAverpoint: false,
    };
  }

  // updateExtensionStatus = () => {
  //   const browser = getBrowserType();
  //   const isInstalled = !!document.getElementById('averpoint_app');
  //   const browserName = browser.isChrome
  //     ? 'Chrome'
  //     : browser.isFirefox
  //       ? 'Firefox'
  //       : null;
  //   updateExtensionStatus(browserName, isInstalled, navigator.userAgent);
  // };

  onNotificationsSeen = () => {
    // Todo - call action that notifications seen
    console.log('Notification seen called - need to implement action.');
    this.props.actions.userViewedNotifications();
  };

  onLevelUpModalSeen() {
    // Todo - call action that level up modal seen
    console.log('Level up modal seen - need to implement action.');
  }

  onInviteUser = () => {
    this.props.openInviteAverpointModal();
  };

  onLoginClicked = () => {
    const location = {
      pathname: '/auth/login',
      state: {
        nextPathname: this.props.location.pathname,
      },
    };
    this.props.dispatch(push(location));
  };

  onSignUpClicked = () => {
    if (this.props.location.search.includes('clubhouse=true')) {
      this.props.openSignUpModal();
      return;
    }

    if (isMobile()) {
      window.location.href = isIOS()
        ? REACT_APP_APP_STORE_URL
        : REACT_APP_PLAY_STORE_URL;
      return;
    }

    const location = {
      pathname: '/auth/signup',
    };
    this.props.dispatch(push(location));
  };

  onLogoutClicked = () => {
    // console.log("Logout clicked");
    AWSCognitoInstance.logout(this.props.user.username);

    this.props.actions.manualLogout(this.props.location.pathname);
    // this.closeMobileNav()
  };

  onPortfolioClicked = (e) => {
    // console.log("Portfolio clicked", e)
    e.preventDefault();
    const location = {
      pathname: `/portfolio/${this.props.user.username}`,
    };
    this.props.dispatch(push(location));
    this.closeMobileNav();
  };

  goToPage = (pathname) => {
    const location = {
      pathname,
    };
    this.props.dispatch(push(location));
    this.closeMobileNav();
  };

  closeMobileNav() {
    // document.getElementById('navbarItemsCollapse').classList.remove('in')
  }

  afterPointCreated() {
    console.log(
      'nav bar Create link... point created .... do something with this?',
    );
  }

  onShowAddPoint = () => {
    this.setState({ showAddPoint: true });
  };

  onHideAddPoint = (e) => {
    this.setState({ showAddPoint: false });
    e && e.stopPropagation();
  };

  getActiveRoute = (pathname) => {
    const { location } = this.props;
    return this.props.location.pathname == pathname ? 'active' : 'inactive';
  };

  onCloseExtensionPromit = () => {
    localStorage.setItem('hideExtensionPrompt', true);
    this.forceUpdate();
  };

  getExtLink = () => {
    return;

    const browser = getBrowserType();
    const browserName = browser.isChrome
      ? 'Chrome'
      : browser.isFirefox
        ? 'Firefox'
        : null;

    if (!browserName) return null;
    const url =
      browserName === 'Chrome'
        ? 'https://chrome.google.com/webstore/detail/averpoint/delinpnhfdkcjeifmapikhinioghdeia'
        : 'https://addons.mozilla.org/en-US/firefox/addon/averpoint/';
    return (
      <a href={url} target="_blank" className="message">
        Add the AverPoint extension for {browserName}
      </a>
    );
  };

  renderLeftLinks() {
    if (this.props.authenticated) {
      let name = `${this.props.user.profile.firstName} ${this.props.user.profile.lastName}`;
      return (
        <div>
          <div
            className="navbar-brand"
            onClick={() => this.goToPage('/home/intro')}
          >
            <Icon icon={ICONS.LOGO} />
          </div>
          <div className="d-block d-md-none">
            {/* <DropdownButton
              id="dropdown-top-collections"
              bsStyle="default"
              title="Top Collections"
              className="border-0"
            >
              <MenuItem onClick={() => this.goToPage('/groups/me')}>
                Groups
              </MenuItem>
              <MenuItem onClick={() => this.goToPage(`/portfolio/averpoint`)}>
                Averpoint
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.goToPage(`/portfolio/${this.props.username}`)
                }
              >
                <ProfileImage
                  userId={this.props.user._id}
                  photoLicenseTerms={this.props.user.photoLicenseTerms}
                  hostedImageVersion={this.props.user.hostedImageVersion}
                  size={24}
                  allowUserToChangePic={false}
                  imageClass="profile-image mr-3"
                  username={this.props.user.username}
                />
                {name}
              </MenuItem>
            </DropdownButton> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="navbar-brand" onClick={() => this.goToPage('/home')}>
          <Icon icon={ICONS.LOGO} />
        </div>
      );
    }
  }

  render() {
    const routeName = this.props.location.pathname;
    // const { walkthrough } = this.props;

    let searchBoxText = 'Search';
    if (this.props.location.tag) {
      searchBoxText = this.props.location.tag;
    }
    const { searchInput } = this.state;

    if (!this.props.showHeader) {
      return <div className="no-header" />;
    }

    return (
      <div className="averpoint-navbar">
        <div className="mobile-header-placeholder"></div>
        {this.renderLeftLinks()}
        {this.renderSearchBar()}
        {this.renderHeaderLinks()}
        {this.renderMobileHeader()}
        {/* {this.renderExtensionPrompt()} */}
      </div>
    );
  }

  renderExtensionPrompt = () => {
    const hide = localStorage.getItem('hideExtensionPrompt');
    const isInstalled = document.getElementById('averpoint_app'); // check extension element
    const mobile = isMobile();
    const url = this.getExtLink();

    if (mobile || isInstalled || hide || !url) return null;

    return (
      <div className="extension-bar">
        {url}
        <span className="close" onClick={this.onCloseExtensionPromit}>
          &times;
        </span>
      </div>
    );
  };

  renderInstallExtLink = () => {
    const isInstalled = document.getElementById('averpoint_app');
    if (!isInstalled) {
      return this.getExtLink();
    }
  };

  renderNotifications() {
    return (
      <div className=" nav-icon notifications">
        <Notifications
          notifications={this.props.notifications}
          notificationsSeen={this.onNotificationsSeen}
        />
      </div>
    );
  }

  renderProgressBar() {
    return null;
    return <ProgressBar />;
  }

  renderBlogsLink() {
    return (
      <div
        href="https://learn.averpoint.com/blogs"
        onClick={() =>
          window.open('https://learn.averpoint.com/blogs', '_blank')
        }
      >
        <ToolTip
          toolTipText="See the blogs."
          toolTipPosition="bottom"
          className="div"
        >
          Blog
        </ToolTip>
      </div>
    );
  }

  renderNewsClubLink() {
    return (
      <NavLink to="/newsClub" className="nav-link">
        Club
      </NavLink>
    );
  }

  renderAuthenticatedLinks() {
    return (
      <div className="authenticated-links">
        {/* <NavLink to="/sources/me" className="nav-link sources">
        {/* <NavLink to="/sources" className="nav-link sources">
          <ToolTip
            toolTipText="Sources that organize research collections on AverPoint."
            toolTipPosition="bottom"
            className="div"
          >
            Sources
          </ToolTip>
        </NavLink> */}
        {this.renderProgressBar()}
        {/* <NavLink
          to="/portfolio/averpoint/channel"
          className="nav-link featured"
        >
          <ToolTip
            toolTipText="See the videos with Points chosen by AverPoint staff."
            toolTipPosition="bottom"
            className="div"
          >
            Top Videos
          </ToolTip>
        </NavLink> */}
        {/* <NavLink to="/groups/me" className="nav-link groups">
          <ToolTip
            toolTipText="Groups that organize research collections on AverPoint."
            toolTipPosition="bottom"
            className="div"
          >
            Groups
          </ToolTip>
        </NavLink> */}
        {/* <NavLink
          to={'/portfolio/' + this.props.username}
          className="nav-link nav-profile"
        >
          <ToolTip
            toolTipText="Go to your personal Collections of Points"
            toolTipPosition="bottom"
            className="div"
          >
            <NavbarProfile user={this.props.user} goToPage={this.goToPage} />
          </ToolTip>
        </NavLink> */}
        {/* {this.renderBlogsLink()} */}
        {/* {this.renderSearchIcon()} */}
        {this.renderAddPoint()}
        {this.renderNotifications()}
        <div className=" nav-icon ">
          {/* <Icon icon={ICONS.MORE}/> */}
          <MoreInfo
            onLogoutClicked={this.onLogoutClicked}
            onPortfolioClicked={this.onPortfolioClicked}
            user={this.props.user}
            inviteUser={this.onInviteUser}
            goToPage={this.goToPage}
          />
        </div>
      </div>
    );
  }

  renderUnauthenticatedLinks() {
    return (
      <div className="unauthenticated-links">
        {/* <NavLink
          to="/portfolio/averpoint/channel"
          className="nav-link featured"
        >
          <ToolTip
            toolTipText="Videos with Points chosen by AverPoint staff."
            toolTipPosition="bottom"
            className="div"
          >
            Top videos
          </ToolTip>
        </NavLink> */}
        {/* {this.renderSearchIcon()} */}
        {/* <NavLink to="/about" className="nav-link">
          About
        </NavLink> */}
        <NavLink to="/home/intro" className="nav-link">
          Home
        </NavLink>
        <NavLink to="/testimonials" className="nav-link">
          Testimonials
        </NavLink>
        {/* <NavLink to="/home/news" className="nav-link">
          News
        </NavLink> */}
        {/* {this.renderNewsClubLink()} */}
        {/* <a href="https://learn.averpoint.com/schools" className="nav-link">
          Classrooms
        </a> */}
        {/* <a href="https://learn.averpoint.com/readers" className="nav-link">
          Individuals
        </a> */}
        {/* <NavLink to="/home/analytics" className="nav-link">
          Analytics
        </NavLink>
        <NavLink to="/home/review" className="nav-link">
          Community review
        </NavLink> */}
        <NavLink to="/pricing" className="nav-link">
          Pricing
        </NavLink>
        <div
          className={`nav-link ${this.getActiveRoute('/login')}`}
          onClick={this.onLoginClicked}
        >
          Login
        </div>
        <div className="nav-link sign-up" onClick={this.onSignUpClicked}>
          Sign up
        </div>
        <Dropdown
          className="more-menu"
          id="header-more-dropdown"
          onClick={(e) => e.stopPropagation()}
          pullRight
        >
          <Dropdown.Toggle noCaret>
            <Icon icon={ICONS.MORE} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem>{this.renderBlogsLink()}</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  renderHeaderLinks = () => {
    if (this.state.showSearchBar) return null;

    return (
      <div className="header-links">
        {this.renderInstallExtLink()}
        {/* <NavLink to="/groups" className="nav-link">
          Groups
        </NavLink> */}
        {this.props.authenticated
          ? this.renderAuthenticatedLinks()
          : this.renderUnauthenticatedLinks()}
      </div>
    );
  };

  renderMobileHeader = () => {
    if (this.state.showSearchBar) return null;

    if (this.props.authenticated) {
      return (
        <div className="mobile-header-links">
          {this.renderProgressBar()}
          <NavbarProfile user={this.props.user} goToPage={this.goToPage} />
          {!isMobile() && this.renderSearchIcon()}
          {this.renderNotifications()}
          <div className=" nav-icon ">
            <MoreInfo
              onLogoutClicked={this.onLogoutClicked}
              onPortfolioClicked={this.onPortfolioClicked}
              user={this.props.user}
              inviteUser={this.onInviteUser}
              goToPage={this.goToPage}
            />
          </div>
        </div>
      );
    }

    let whatIsAverPoint;
    if (this.state.showWhatAverpoint) {
      whatIsAverPoint = (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            background: 'white',
            zIndex: '100000',
          }}
          className="p-3"
        >
          <div className="d-flex align-items-center">
            <div
              className=""
              onClick={() => this.goToPage('/portfolio/averpoint')}
            >
              <Icon icon={ICONS.LOGO} width={121} />
            </div>
            <button
              className="btn av-blue font-size-12 ml-auto"
              style={{ height: '38px' }}
              onClick={() => this.setState({ showWhatAverpoint: false })}
            >
              <Icon icon={ICONS.CLOSE} className="mr-0" />
            </button>
          </div>
          <div className="font-size-12 mt-3">
            <span className="av-blue">AverPoint</span> connects today's videos,
            podcasts, and articles to their underlying evidence. You can view,
            review, and challenge the evidence. You can even ask for more
            evidence or suggest you own facts and sources.
          </div>
          <div className="av-black mt-3">
            Our mission is to help credit content win.
          </div>
          <div className="d-flex mt-4">
            <button
              className="btn av-blue font-size-12 w-50 mr-1"
              style={{ height: '38px' }}
              onClick={() => this.setState({ showWhatAverpoint: false })}
            >
              Back to Content
            </button>
            <button
              className="btn av-blue font-size-12 w-50 ml-1"
              style={{ height: '38px' }}
              onClick={() => {
                this.setState({ showWhatAverpoint: false });
                this.goToPage('/auth/signup');
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="mobile-header-links">
        {/* <button
          className="btn av-blue font-size-12"
          style={{ height: '38px' }}
          onClick={() => this.setState({ showWhatAverpoint: true })}
        >
          What is AverPoint?
        </button> */}
        <Link
          to="/auth/login"
          className="btn av-blue ml-2 mr-2"
          style={{ height: '38px' }}
        >
          Login
        </Link>
        <div
          className="btn av-blue ml-2 mr-2 sign-up"
          style={{ height: '38px' }}
          onClick={this.onSignUpClicked}
        >
          Sign Up
        </div>
        {/* {whatIsAverPoint} */}
      </div>
    );
  };

  renderSearchIcon = () => {
    if (this.props.location.pathname == '/search') return null;

    return (
      <div className="nav-icon search" onClick={this.toggleSearchBar}>
        <ToolTip
          toolTipText="Search AverPoint"
          toolTipPosition="left"
          className="div"
        >
          <div className="search-point-link">
            <Icon icon={ICONS.SEARCH} className="d-block" />
          </div>
        </ToolTip>
      </div>
    );
  };

  renderAddPoint() {
    const { showAddPoint } = this.state;
    const { user } = this.props;

    if (
      user &&
      !user.profile.roles.find(
        (i) => ((i.type === 'creator' && i.status === 'activated') ||
          (i.type === 'student' && i.status === 'activated') ||
          (i.type === 'teacher' && i.status === 'activated')
        ),
      )
    ) {
      return null;
    }

    let tabs;

    if (showAddPoint) {
      tabs = (
        <div className="position-absolute tabs-container">
          <Tab.Container defaultActiveKey="1">
            <div className="row clearfix">
              <div className="col-sm-12 bg-white">
                <Nav bsStyle="tabs">
                  <NavItem eventKey="1">New Point</NavItem>
                  {/* <NavItem disabled>
                    <Icon
                      icon={ICONS.TAB_SWITCH}
                      size={20}
                      className="gray-3"
                    />
                  </NavItem>
                  <NavItem eventKey="2">New Collection</NavItem> */}
                </Nav>
              </div>
              <div className="col-sm-12">
                <Tab.Content animation>
                  <Tab.Pane eventKey="1">
                    <AddPoint
                      model={{
                        hideImportFromPortfolio: true,
                        showAddToCollection: true,
                      }}
                      onClose={this.onHideAddPoint}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="2" /> */}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
          <Icon
            icon={ICONS.CLOSE}
            onClick={this.onHideAddPoint}
            className="close-add-point"
          />
        </div>
      );
    }

    return (
      <div className="nav-icon create-point" onClick={this.onShowAddPoint}>
        <ToolTip
          toolTipText="Create a new Point"
          toolTipPosition="left"
          className="div"
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className="create-point-link d-inline-flex align-items-center justify-content-center">
            <Icon icon={ICONS.ADD} />
          </div>
        </ToolTip>
        {tabs}
      </div>
    );
  }

  renderSearchBar = () => {
    if (!this.state.showSearchBar) return null;

    return (
      <div className="search-items-container">
        <SearchItems
          // searchOnTyping={this.onSearch} -- no reason to return search results while typing
          searchOnEnter={this.onSearchComplete}
          placeholder="Search AverPoint"
          onClose={this.onCloseSearchBar}
          showCloseOnBlankQuery
        />
      </div>
    );
  };

  onSearch = (searchTerm) => {
    this.props.actions.searchPortfolio(
      searchTerm,
      '',
      'allAverPoint',
      'allAverPoint',
    );
  };

  onSearchComplete = async (searchTerm) => {
    await this.props.actions.searchPortfolio(
      searchTerm,
      '',
      'allAverPoint',
      'allAverPoint',
    );

    if (this.props.location.pathname != '/search') this.goToPage('/search');

    this.toggleSearchBar();
  };

  onCloseSearchBar = () => {
    this.props.actions.searchPortfolio('', '', 'allAverPoint', 'allAverPoint');
    this.toggleSearchBar();
  };

  toggleSearchBar = () => {
    this.setState({ showSearchBar: !this.state.showSearchBar });
  };
}

function mapStateToProps(state) {
  const { authenticated } = state.user;
  const { user } = state.user;
  let notifications = state.user.notifications ? state.user.notifications : [];
  // Sorting notifications by date desc
  notifications = notifications.sort((a, b) => {
    // return +new Date(a.date) - +new Date(b.date) ? -1 : 1;
    return new Date(b.date) - new Date(a.date);
  });
  const { showHeader } = state.page;

  return {
    authenticated,
    user,
    username: user && user.username,
    notifications,
    showHeader,
    walkthrough: state.walkthrough,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openInviteAverpointModal: (email) => {
      const props = {
        model: { email },
      };
      dispatch(Actions.openModal('invite-averpoint', props));
    },
    openSignUpModal: () => {
      const props = {};
      dispatch(Actions.openModal('clubhouse-signup', props));
    },
    dispatch,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react';

import SelectPointsQuestion from './SelectPointsQuestion';

function MultipleSelectPointsQuestion(
  {
    questions,
    question,
    onChange,
    model,
    point,
    points,
    onContinue,
    onAction,
    isMock,
    collectionPoint,
  },
  ref,
) {
  const questionRef = useRef(null);

  const sourceQuestion = questions[question.sourceQuestionIndex];
  const sourceQuestionValue = model[sourceQuestion.variable];
  const options = sourceQuestion.options.filter(
    (option) =>
      option.variable && sourceQuestionValue.indexOf(option.text) !== -1,
  );

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_INDEX':
          return { ...state, index: action.payload };
        default:
          return state;
      }
    },
    { index: 0 },
  );

  useEffect(() => {
    if (!options.length) {
      onContinue();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    allowContinue: () => {
      if (questionRef.current && questionRef.current.allowContinue) {
        return questionRef.current && questionRef.current.allowContinue();
      } else {
        return true;
      }
    },
    continue: () => {
      if (state.index + 1 < options.length) {
        dispatch({ type: 'SET_INDEX', payload: state.index + 1 });
        return true;
      }

      return false;
    },
    previous: () => {
      if (state.index - 1 >= 0) {
        dispatch({ type: 'SET_INDEX', payload: state.index - 1 });
        return true;
      }

      return false;
    },
  }));

  function renderQuestion() {
    let option = options[state.index];

    if (!option) {
      return null;
    }

    option = {
      ...option,
      text: option.multipleSelectPointsText,
      conditionType:
        option.multipleSelectPointsConditionType || option.conditionType,
    };

    return (
      <SelectPointsQuestion
        questions={questions}
        question={option}
        onChange={onChange}
        model={model}
        point={point}
        points={points}
        ref={questionRef}
        onContinue={onContinue}
        onAction={onAction}
        isMock={isMock}
        collectionPoint={collectionPoint}
      />
    );
  }

  return renderQuestion();
}

export default forwardRef(MultipleSelectPointsQuestion);

import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {
  classroomGroups: {},
  studentsProgress: {},
};

export default function classRooms(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_CLASSROOM_GROUPS: {
      const courseId = action.courseId;
      const classroomGroups = action.classroomGroups;

      const stateClassroomGroups = JSON.parse(JSON.stringify(state.classroomGroups));
      stateClassroomGroups[courseId] = classroomGroups;

      return {
        ...state,
        classroomGroups: stateClassroomGroups,
      };
    }
    case Actions.ADD_CLASSROOM_GROUP: {
      const courseId = action.courseId;
      const classroomGroup = action.classroomGroup;

      const stateClassroomGroups = JSON.parse(JSON.stringify(state.classroomGroups));
      stateClassroomGroups[courseId] = [
        ...stateClassroomGroups[courseId],
        classroomGroup,
      ];

      return {
        ...state,
        classroomGroups: stateClassroomGroups,
      };
    }
    case Actions.UPDATE_CLASSROOM_GROUP: {
      const courseId = action.courseId;
      const updatedClassroomGroup = action.classroomGroup;

      const stateClassroomGroups = JSON.parse(JSON.stringify(state.classroomGroups));
      const classroomGroups = stateClassroomGroups[courseId];

      const classroomGroupIndex = classroomGroups.findIndex((group) => group._id === updatedClassroomGroup._id);

      if (classroomGroupIndex === -1) {
        return state;
      }

      classroomGroups.splice(classroomGroupIndex, 1, updatedClassroomGroup);

      return {
        ...state,
        classroomGroups: stateClassroomGroups,
      };
    }
    case Actions.REMOVE_CLASSROOM_GROUP: {
      const courseId = action.courseId;
      const classroomGroupId = action.classroomGroupId;

      const stateClassroomGroups = JSON.parse(JSON.stringify(state.classroomGroups));
      const classroomGroups = stateClassroomGroups[courseId];

      const classroomGroupIndex = classroomGroups.findIndex((group) => group._id === classroomGroupId);

      if (classroomGroupIndex < -1) {
        return state;
      }

      classroomGroups.splice(classroomGroupIndex, 1);

      return {
        ...state,
        classroomGroups: stateClassroomGroups,
      };
    }
    case Actions.LOAD_GROUP_STUDENTS_PROGRESS: {
      const courseId = action.courseId;
      const topicId = action.topicId;
      const studentsProgress = action.studentsProgress;

      const stateStudentsProgress = JSON.parse(JSON.stringify(state.studentsProgress));
      stateStudentsProgress[courseId] = stateStudentsProgress[courseId] || {};
      stateStudentsProgress[courseId][topicId] = studentsProgress;

      return {
        ...state,
        studentsProgress: stateStudentsProgress,
      };
    }
    default:
      return state;
  }
}

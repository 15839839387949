import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = [];

export default function writings(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_WRITINGS: {
      let newState = [...state];
      action.writings.forEach((writing) => {
        const index = newState.findIndex((i) => i._id === writing._id);

        if (index === -1) {
          newState.push(writing);
        } else {
          newState.splice(index, 1, writing);
        }
      });
      return newState;
    }
    case Actions.ADD_WRITING: {
      const index = state.findIndex((i) => i._id === action.writing._id);

      if (index === -1) {
        return [...state, action.writing];
      } else {
        return [...state.splice(index, 1, action.writing)];
      }
    }
    case Actions.UPDATE_WRITING: {
      const index = state.findIndex((i) => i._id === action.writing._id);
      return [
        ...state.slice(0, index),
        action.writing,
        ...state.slice(index + 1),
      ];
    }
    case Actions.REMOVE_WRITING: {
      const index = state.findIndex((i) => i._id === action.writingId);

      if (index === -1) {
        return state;
      } else {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
    }
    default:
      return state;
  }
}

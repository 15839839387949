import * as React from "react";
// import AutoComplete from '../../../widget/autocomplete/autocomplete';

export default class InviteAverpointComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <div>
        <div className="inviteByEmail">
          <input
            type="text"
            onChange={this.props.addEmailToInvite}
            className="inviteInput"
            placeholder="Enter email(s) separated by commas"
          />
        </div>
        {/* <div className="form-group">
                    <label className="control-label primary-color">Message to users</label> */}
        {/* <textarea
          className="form-control message"
          style={{ resize: "none" }}
          value={this.props.message}
          onChange={this.props.setMessage}
          placeholder="Enter a message"
        /> */}
        {/*     </div> */}
        <div className="sendButton">
          <button
            type="button"
            onClick={this.props.onInvite}
            className="btn btn-default done-button send-button"
          >
            <span className="shareBtnTxt">Send</span>
          </button>
        </div>
        <div className="error-message">
          {this.props.errorMessage}
        </div>
      </div>
    );
  }
}

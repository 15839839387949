import React from 'react';

import {
  ToastContainer as ReactToastContainer,
  toast as reactToast,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Icon, ICONS } from 'components/Icons';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import './toast.scss';

const generalStyle = {
  color: '#1571bc',
};

const errorStyle = {
  color: 'red',
};

const successStyle = {
  //color: '#7fb800',
};

export const ToastContainer = props => {
  return (
    <ReactToastContainer
      position={'bottom-right'}
      hideProgressBar={true}
      autoClose={3000}
      {...props}
    />
  );
};

export const toast = (text, options, styleType) => {
  const style = styleType ? styleType : generalStyle;
  const message = <div style={{ style }}>{text}</div>;
  reactToast(message, options);
};

export function toastByType(toastType, options) {
  let toastMessage;

  if (toastType === 'NEW_POINT_ADDED') {
    const point = options.target;

    toastMessage = (
      <div className="d-flex align-items-start">
        <Icon icon={ICONS.CHECK} className="av-green mr-4 ml-4 mt-2" />
        <div>
          <p className="av-black mb-1">Done!</p>
          <p className="av-gray">New Point has been saved.</p>
          <ActionLink
            linkClassName="av-blue font-weight-bold"
            onClick={() => options.onViewPoint()}
            label="View Point"
          />
          {options.onViewCollection && (
            <ActionLink
              linkClassName="av-blue font-weight-bold ml-4"
              onClick={() => options.onViewCollection()}
              label="View Collection"
            />
          )}
        </div>
      </div>
    );
  }

  reactToast(toastMessage);
}

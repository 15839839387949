import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, ICONS } from 'components/Icons';

import './RectangularButton.scss';

const RectangularButton = (props) => {
  let btnClassName = 'rectangular-button';
  btnClassName += props.disabled ? ' disabled ' : '';

  btnClassName += props.size === 'small' ? ' small-button ' : '';

  btnClassName += props.darkButton ? ' dark-button ' : '';

  btnClassName += props.heroButton ? ' hero-button ' : '';

  btnClassName += props.lightButton ? ' light-button ' : '';

  btnClassName += props.noBorder ? ' no-border ' : '';

  btnClassName += props.className ? ` ${props.className} ` : '';

  const onClick = props.disabled ? null : props.onClick;

  return (
    <button
      id={props.id}
      className={btnClassName}
      title={props.title}
      onClick={onClick}
      disabled={props.disabled}
    >
      <div className="content">
        {props.iconName && (
          <Icon icon={ICONS[props.iconName]} size={props.iconSize} />
        )}
        {props.children}
      </div>
    </button>
  );
};

RectangularButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  id: PropTypes.string,
  size: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  className: PropTypes.string,
  darkButton: PropTypes.bool,
  noBorder: PropTypes.bool,
  heroButton: PropTypes.bool,
  lightButton: PropTypes.bool,
};

export default RectangularButton;

import React, { Fragment, useReducer } from 'react';

import './FourPackTabs.scss';

import EvidenceSection from './EvidenceSection';
import QTPTab from './QTPTab';
import RTPTab from './RTPTab';
import CTPTab from './CTPTab';
import STPTab from './STPTab';

export default function FourPackTabs({
  activeTab,
  point,
  pointDetailView,
  onPointDetailView,
  showPointSelectText,
  selectedCaptions,
  onRTPCreate,
  onAction,
  isSelectionEnabled,
  isUpdateReview,
  qtpId,
  rtpId,
  collection,
  rid,
  onEvent,
  collectionPoint,
  isMock,
  captions,
  showProvideEvidence,
  isExtensionCL,
  courseId,
  priorityUsers,
  teacherMode,
  studentMode,
  topicId,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SURVEY_SELECT_POINTS_QUESTIONS':
          return {
            ...state,
            showSurveySelectPointsQuestions: action.payload,
          };
        case 'SET_ADDING_RTP':
          return {
            ...state,
            addingRTP: action.payload,
          };
      }
    },
    {
      surveySelectPointsQuestions: false,
      addingRTP: false,
    },
  );

  function onActionCallback(type, data) {
    if (type === 'SURVEY_SELECT_POINTS_QUESTIONS') {
      dispatch({
        type: 'SURVEY_SELECT_POINTS_QUESTIONS',
        payload: data,
      });
    } else if (type === 'RTP_TAB_SHOW_RTP_FORM') {
      dispatch({
        type: 'SET_ADDING_RTP',
        payload: data,
      });
    }

    onAction && onAction(type, data);
  }

  function renderEvidenceSection() {
    if (state.showSurveySelectPointsQuestions) {
      return null;
    }

    return (
      <Fragment>
        <div className="evidence-connector" />
        <EvidenceSection
          point={point}
          pointDetailView={pointDetailView}
          onPointDetailView={onPointDetailView}
          showPointSelectText={showPointSelectText}
          selectedCaptions={selectedCaptions}
          onAction={onAction}
          isSelectionEnabled={isSelectionEnabled}
          collection={collection}
          addingRTP={state.addingRTP}
          isMock={isMock}
          captions={captions}
          showProvideEvidence={showProvideEvidence}
          isExtensionCL={isExtensionCL}
          priorityUsers={priorityUsers}
          teacherMode={teacherMode}
        />
      </Fragment>
    );
  }

  function renderQTPTab() {
    return (
      <QTPTab
        point={point}
        showQTPForm={pointDetailView ? pointDetailView.showQTP : false}
        onPointDetailView={onPointDetailView}
        showPointSelectText={showPointSelectText}
        selectedCaptions={selectedCaptions}
        onAction={onAction}
        qtpId={qtpId}
        collection={collection}
        rid={rid}
        onEvent={onEvent}
        collectionPoint={collectionPoint}
        isMock={isMock}
        captions={captions}
        isExtensionCL={isExtensionCL}
        courseId={courseId}
        priorityUsers={priorityUsers}
        teacherMode={teacherMode}
        studentMode={studentMode}
        topicId={topicId}
      />
    );
  }

  function renderRTPTab() {
    return (
      <RTPTab
        point={point}
        showPointSelectText={showPointSelectText}
        selectedCaptions={selectedCaptions}
        onRTPCreate={onRTPCreate}
        onAction={onActionCallback}
        isUpdateReview={isUpdateReview}
        rtpId={rtpId}
        collection={collection}
        collectionPoint={collectionPoint}
        isMock={isMock}
        captions={captions}
        isExtensionCL={isExtensionCL}
        courseId={courseId}
        priorityUsers={priorityUsers}
        teacherMode={teacherMode}
        studentMode={studentMode}
        topicId={topicId}
      />
    );
  }

  function renderCommentTab() {
    return (
      <CTPTab
        point={point}
        showQTPForm={pointDetailView ? pointDetailView.showQTP : false}
        onPointDetailView={onPointDetailView}
        showPointSelectText={showPointSelectText}
        selectedCaptions={selectedCaptions}
        onAction={onAction}
        qtpId={qtpId}
        collection={collection}
        rid={rid}
        onEvent={onEvent}
        collectionPoint={collectionPoint}
        isMock={isMock}
        captions={captions}
        isExtensionCL={isExtensionCL}
        courseId={courseId}
        priorityUsers={priorityUsers}
        teacherMode={teacherMode}
        studentMode={studentMode}
        topicId={topicId}
      />
    );
  }

  function renderSTPTab() {
    return <STPTab point={point} />;
  }

  function renderTab() {
    let tab;

    switch (activeTab) {
      case 'QTP':
        tab = renderQTPTab();
        break;
      case 'REVIEW':
        tab = renderRTPTab();
        break;
      case 'COMMENT':
        tab = renderCommentTab();
        break;
      case 'STP':
        tab = renderSTPTab();
        break;
    }

    if (!tab) {
      return null;
    }

    return <div className="four-pack-tabs">{tab}</div>;
  }

  // const tab = renderTab();

  return (
    <Fragment>
      {renderTab()}
      {renderEvidenceSection()}
    </Fragment>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from 'redux/actions/index';
import SubPoint from './SubPoint';
import AddPoint from 'components/modals/AddPoint/AddPoint';
import { Icon, ICONS } from 'components/Icons';

import './subPoints.scss';

// const withDragDropContext = DragDropContext(HTML5Backend);

class SubPoints extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    point: PropTypes.object,
    subPointIds: PropTypes.array,
    filter: PropTypes.string,
    subPointView: PropTypes.string, //outline or card,
    selectedPointIds: PropTypes.array,
    expandedPointIds: PropTypes.array,
  };

  renderAddSubPoint() {
    const { addSubPointModel, point, onAddSubPoint, onSelect } = this.props;
    const model = {
      collectionId: addSubPointModel.collectionId,
      afterPointCreated: async subPointId => {
        const finalSubPoints = [
          ...this.props.points[point._id].point.subPoints,
        ];
        const sourceIndex = finalSubPoints.findIndex(
          i => i.pointId === subPointId,
        );
        const targetIndex = addSubPointModel.index;
        const sourceSubPoint = finalSubPoints[sourceIndex];
        finalSubPoints[sourceIndex] = finalSubPoints[targetIndex];
        finalSubPoints[targetIndex] = sourceSubPoint;

        if (sourceIndex !== targetIndex) {
          const data = {
            sourceIndex,
            sourceParentId: point._id,
            sourceParentType: point.isCollection ? 'collection' : 'point',
            targetIndex,
            targetParentId: point._id,
            targetParentType: point.isCollection ? 'collection' : 'point',
            finalSubPoints,
          };
          await this.props.actions.movePoint(data);
        }

        onAddSubPoint(null, -1);
        onSelect && onSelect([point._id, subPointId]);
      },
      hideImportFromPortfolio: true,
    };
    return (
      <div className="mb-3 mt-3 d-flex align-items-start position-relative add-point-inline">
        <div
          className="remove-add-point-inline d-flex align-items-center justify-content-center mr-3"
          onClick={() => {
            onAddSubPoint(null, -1);
          }}
        >
          <Icon icon={ICONS.MINUS} size={18} />
        </div>
        <div className="flex-fill">
          <AddPoint type="LIGHTWEIGHT" model={model} focusPointText={true} />
        </div>
      </div>
    );
  }

  render() {
    const {
      point,
      subPointIds,
      filter,
      user,
      selectedPointIds,
      expandedPointIds,
      points,
      addSubPointModel,
    } = this.props;

    // //If the user authored the main Point, they can drag any of the subPoints.
    const canDrag = points[point._id].permissionLevel === 'write';

    const subPointList = point.subPoints
      .filter(i => !!i)
      .filter(i => subPointIds.indexOf(i.pointId) !== -1)
      .map((subPointInfo, index) => {
        // const subPoint = subPoints.find((subPoint) => {
        //   return subPoint._id == subPointInfo.pointId
        // });
        let parentObject = {
          type: 'point',
          name: point.text,
          list: subPointIds,
          id: point._id,
          level: 0,
          index,
          subPoints: point.subPoints,
        };

        const subPointObject = this.props.points[subPointInfo.pointId];
        const subPoint =
          subPointObject && subPointObject.point
            ? subPointObject.point
            : { _id: subPointInfo.pointId };

        if (subPoint.isCollection) {
          return null;
        }

        if (filter === 'For' && subPointInfo.stance !== 'For') {
          return null;
        }

        if (filter === 'Against' && subPointInfo.stance !== 'Against') {
          return null;
        }

        if (filter === 'Neutral' && subPointInfo.stance !== 'Neutral') {
          return null;
        }

        const subPointElm = (
          <SubPoint
            user={user}
            subPointInfo={subPointInfo}
            subPoint={subPoint}
            parentObject={parentObject}
            cardType={this.props.subPointView}
            canDrag={canDrag}
            selectedPointIds={selectedPointIds}
            expandedPointIds={expandedPointIds}
            onToggleExpand={this.props.onToggleExpand}
            // onSelect={this.props.onSelect}
            onSelect={ids => {
              this.props.onSelect && this.props.onSelect([point._id, ...ids]);
            }}
            onAddSubPoint={this.props.onAddSubPoint}
            addSubPointModel={addSubPointModel}
          />
        );

        let addPointElm;

        if (
          addSubPointModel &&
          addSubPointModel.collectionId === point._id &&
          index === addSubPointModel.index
        ) {
          addPointElm = this.renderAddSubPoint();
        }

        return (
          <React.Fragment key={subPoint._id}>
            {addPointElm}
            {subPointElm}
          </React.Fragment>
        );
      });
    return (
      <div className="sub-points">
        {subPointList}
        {addSubPointModel &&
        addSubPointModel.collectionId === point._id &&
        addSubPointModel.index === point.subPoints.length
          ? this.renderAddSubPoint()
          : null}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.user.user;
  const points = state.points;

  return {
    user,
    points,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      let props = {
        model: {
          title,
          message,
        },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
    dispatch,
  };
}

const ConnectedSubPoints = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubPoints);

//export const ConnectedSubPointsWithoutDragDropContext = ConnectedSubPoints;

//export default withDragDropContext(ConnectedSubPoints);

export default ConnectedSubPoints;

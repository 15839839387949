/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Element, scroller } from 'react-scroll';
// import MultipleProfileImages from '../../elements/molecules/Images/MultipleProfileImages/MultipleProfileImages'
import TourHighlight from 'components/elements/molecules/TourHighlight';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import './CollectionCard.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import * as Actions from '../../../redux/actions/index';

import CollectionEmbed from 'routes/Embed/Collection';

import EditableText from 'components/elements/atoms/EditableComponent/EditableComponent';
import CollectionHeaderControls from './Component/CollectionHeaderControls';
import CollectionEditControls from './Component/CollectionEditControls';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import { DnDBullseye } from 'components/cards/DnDBullseye.js';
import { Icon, ICONS } from 'components/Icons';
import InviteResponse from 'components/elements/organisms/InviteResponse/InviteResponse';
import MultipleProfileImages from 'components/elements/molecules/Images/MultipleProfileImages/MultipleProfileImages';

const TUTORIAL_STEP = 6; // STEP count for tutorial

class CollectionCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionPointInState: {},
      errorMessages: {},
      isEditing: false,
      isEditingFields: {
        text: false,
        description: false,
        tags: false,
        //image: false
      },
    };
    this.target = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.walkthrough.tourStep === TUTORIAL_STEP) {
      setTimeout(() => {
        scroller.scrollTo('scroll-to-element', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }, 300);
    }
  }

  componentDidMount() {
    if (!this.props.collectionPoint) {
      this.props.actions.getCollection(this.props.collectionId);
    }
  }

  onRemove = e => {
    // e.stopPropagation();
    this.props.onRemove(this.props.collectionPoint._id);
  };

  onRemoveFromGroup = () => {
    this.props.onRemoveFromGroup(this.props.collectionPoint);
  };

  onToggleEdit = () => {
    let newEditingFields = {};
    let collectionPointInState;

    if (this.state.isEditing) {
      newEditingFields = {
        text: false,
        description: false,
        tags: false,
        //image: false
      };
      collectionPointInState = null;

      this.props.dispatch({
        type: 'FINISH_EDITING_POINT',
        data: {
          pointId: this.props.collectionPoint._id,
        },
      });
    } else {
      newEditingFields = {
        text: this.props.collectionPoint.text.length > 0,
        description:
          this.props.collectionPoint.description &&
          this.props.collectionPoint.description.length > 0,
        tags:
          this.props.collectionPoint.tags &&
          this.props.collectionPoint.tags.length > 0,
        //image: this.props.point.imageURL.length > 0
      };
      collectionPointInState = {
        ...this.props.collectionPoint,
      };
      this.props.dispatch({
        type: 'START_EDITING_POINT',
        data: {
          pointId: this.props.collectionPoint._id,
        },
      });
    }
    this.setState({
      isEditing: !this.state.isEditing,
      isEditingFields: newEditingFields,
      collectionPointInState,
      errorMessages: {}, //Clear error messages on any toggle
    });
  };

  onEditCollectionPointField = key => {
    const existingFields = this.state.isEditingFields;
    const newFields = {
      ...existingFields,
      [key]: !existingFields[key],
    };

    this.setState({
      isEditingFields: newFields,
    });
  };

  renderSourceName() {
    const { collectionPoint } = this.props;

    if (!collectionPoint.sourceName) {
      return null;
    }

    let icon;

    if (collectionPoint.sourceType === 'VIDEO') {
      icon = ICONS.VIDEO;
    } else if (collectionPoint.sourceType === 'TEXT') {
      icon = ICONS.ARTICLE;
    }

    const className = classnames('source-name d-flex align-items-center mb-3', {
      video: collectionPoint.sourceType === 'VIDEO',
      text: collectionPoint.sourceType === 'TEXT',
    });
    return (
      <div className={className}>
        {icon && <Icon icon={icon} className="mr-3" />}
        {collectionPoint.sourceName}
      </div>
    );
  }

  renderTitle() {
    const { collectionPoint } = this.props;
    const collectionPointToRender = this.state.isEditing
      ? this.state.collectionPointInState
      : collectionPoint;
    const { errorMessages } = this.state;

    return (
      <h3 className="mt-0 mb-3 title test">
        <EditableText
          value={collectionPointToRender.text}
          editOnClick={this.state.isEditingFields.text}
          editOnIcon={this.state.isEditingFields.text}
          onSave={text => {
            this.onCollectionPointFieldChanged('text', text);
          }}
          maxLength={100}
        />
        <span className="error-message">{errorMessages.text}</span>
      </h3>
    );
  }

  renderDescription() {
    const { collectionPoint } = this.props;
    const collectionPointToRender = this.state.isEditing
      ? this.state.collectionPointInState
      : collectionPoint;
    const { errorMessages } = this.state;
    const description = this.state.isEditing
      ? collectionPointToRender.description
      : collectionPointToRender.description &&
        collectionPointToRender.description.substring(0, 250);

    return (
      <div>
        <EditableText
          value={description}
          editOnClick={this.state.isEditingFields.description}
          editOnIcon={this.state.isEditingFields.description}
          onSave={description => {
            this.onCollectionPointFieldChanged('description', description);
          }}
          maxLength={500}
          placeholder="Enter a description"
        />
        <span className="error-message">{errorMessages.description}</span>
      </div>
    );
  }

  // renderGroupName() {
  //   const { collectionPoint, isChannelGroup } = this.props;

  //   if (collectionPoint.groups && collectionPoint.groups[0]) {
  //     let groups = collectionPoint.groups.map((group, key) => {
  //       return (
  //         <div
  //           role="button"
  //           tabIndex={0}
  //           key={key}
  //           className="group-name d-flex align-items-center"
  //           onClick={e => {
  //             e.stopPropagation();

  //             if (isChannelGroup) {
  //               this.props.dispatch(push(`/groups/${group._id}/channel`));
  //             } else {
  //               this.props.dispatch(push(`/groups/${group._id}/research`));
  //             }
  //           }}
  //         >
  //           <span className="mr-3">
  //             <ProfileImage
  //               userId={group._id}
  //               allowUserToChangePic={false}
  //               allowClickToPortfolio={false}
  //               username={group.name}
  //               hostedImageVersion={group.hostedImageVersion}
  //               size={24}
  //             />
  //           </span>
  //           {group.name}
  //         </div>
  //       );
  //     });

  //     return (
  //       <div className="group-container av-white bg-av-grayish-cyan p-3 ml-3 mr-3 mb-3">
  //         {groups}
  //       </div>
  //     );
  //   }
  // }

  renderVideoMedia() {
    const { collectionPoint } = this.props;

    let placeholder;

    if (collectionPoint.sourceImageURL) {
      placeholder = (
        <img src={collectionPoint.sourceImageURL} className="w-100" />
      );
    }

    return (
      <div className="position-relative mb-3" style={{ minHeight: '32px' }}>
        {placeholder}
        <div className="play-icon" />
      </div>
    );
  }

  renderMedia() {
    const { collectionPoint, cardType } = this.props;

    if (
      collectionPoint.sourceURL &&
      collectionPoint.collectionType === 'media' &&
      cardType === 'default'
    ) {
      switch (collectionPoint.sourceType) {
        case 'VIDEO':
          return this.renderVideoMedia();
      }
    }
  }

  onRemoveUserFromCollection = userId => {
    return this.props.actions.removeAuthorFromPoint(
      this.props.collectionPoint._id,
      userId,
    );
  };

  renderAuthor() {
    const { collectionPoint, collectionObject, user } = this.props;

    if (collectionPoint.authorName.indexOf('anonymous') !== -1) {
      return null;
    }

    let otherAuthors = collectionPoint.authors.filter(
      i => i.userId !== collectionPoint.authorId,
    );

    if (user._id !== collectionPoint.authorId) {
      otherAuthors = otherAuthors.filter(
        i => i.invitationStatus === 'accepted',
      );
    }

    const canInvite =
      collectionObject.permissionLevel == 'write' &&
      collectionObject.invitationStatus === 'accepted';

    return (
      <div className="p-3 author-container d-flex align-items-center mt-2">
        <ProfileImage
          userId={collectionPoint.authorId}
          allowUserToChangePic={false}
          allowClickToPortfolio
          username={collectionPoint.authorName}
          hostedImageVersion={collectionPoint.authorHostedImageVersion}
          size={24}
        />
        <div className="m-0 ml-3 body-medium">{collectionPoint.authorName}</div>
        <div className="ml-auto">
          <MultipleProfileImages
            authors={otherAuthors}
            permissionLevel={collectionObject.permissionLevel}
            canInvite={canInvite}
            onInvite={this.onInviteCollection}
            onRemove={this.onRemoveUserFromCollection}
            primaryAuthorId={collectionPoint.authorId}
            pullRight={true}
            maxImages={2}
          />
        </div>
      </div>
    );
  }

  renderDetail() {
    const { collectionPoint, cardType } = this.props;

    if (collectionPoint) {
      const collectionPointToRender = this.state.isEditing
        ? this.state.collectionPointInState
        : collectionPoint;

      return (
        <div>
          <div className="body">
            <div className="top pt-3 pl-3 pr-3">
              <div className="error">{this.state.errorMessages.name}</div>
              {this.renderSourceName()}
              {this.renderTitle()}
              <div className="body-medium">{this.renderDescription()}</div>
              <div className="body-medium mb-3">{this.renderNumPoints()}</div>
              {this.renderMedia()}
            </div>
            {this.renderAuthor()}
            {this.renderEditControls()}
          </div>
          {this.renderBullseye()}
          {/* {this.renderGroupName()} */}
          {this.renderDonate()}
        </div>
      );
    }
  }

  renderDonate() {
    const { collectionPoint } = this.props;
    const { callToAction } = collectionPoint;

    // Check call to action is present
    if (!callToAction || !callToAction.text || !callToAction.url) {
      return null;
    }

    const text = callToAction.text;
    const url = callToAction.url;

    return (
      <a
        className="p-3 d-flex align-items-center av-blue donate-section"
        href={url}
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        <Icon icon={ICONS.LINK} className="mr-3 p-2" size={24} />
        <h5 className="m-0 mr-3">{text}</h5>
        <Icon icon={ICONS.ARROW_FORWARD} size={15} />
      </a>
    );
  }

  render() {
    const { collectionPoint, cardType } = this.props;

    if (!collectionPoint) return <div>Loading CollectionCard...</div>;

    if (!collectionPoint.isCollection)
      return <div>Error: Trying to render Point as a Collection...</div>;

    if (cardType === 'embed')
      return <CollectionEmbed collectionPointId={collectionPoint._id} />;

    const {
      tourStatus,
      tourStep,
      collectionId: targetWalkthroughCollectionId,
    } = this.props.walkthrough;

    let componentClass;
    if (
      tourStatus === 'inProgress' &&
      tourStep === TUTORIAL_STEP &&
      targetWalkthroughCollectionId === this.props.collectionId
    ) {
      componentClass = classnames(
        'collection-card collection-card--highlighted',
      );
    } else {
      componentClass = classnames(
        'collection-card position-relative',
        this.props.cardType,
        {
          editing: this.state.isEditing,
        },
      );
    }

    return (
      <div
        className={componentClass}
        onClick={this.onClick}
        key={this.props.collectionPoint._id}
      >
        <TourHighlight
          target={this.target}
          placement="bottom"
          step={TUTORIAL_STEP}
          visible={targetWalkthroughCollectionId === this.props.collectionId}
          buttonText="Let's go!"
        >
          <Element name="scroll-to-element">
            You just created this <b>Collection</b>. Now, let's go there!
          </Element>
        </TourHighlight>
        {this.renderDropMessages()}
        {this.renderHeader()}
        {this.renderDetail()}
      </div>
    );
  }

  renderBullseye = () => {
    return (
      <DnDBullseye
        // isDragging={this.props.isDragging}
        canDrop={this.props.canDrop}
        point={this.props.collectionPoint}
        toolTipText={'Add Point to this Collection'}
      />
    );
  };

  renderNumPoints = () => {
    const { collectionPoint } = this.props;
    const collectionLengthLabel =
      collectionPoint.totalSubPoints === 1 ? 'point' : 'points';
    const collectionLengthText = `${
      collectionPoint.totalSubPoints
    } ${collectionLengthLabel}`;

    return <span className="av-blue">{collectionLengthText}</span>;
  };

  renderInvite() {
    let { isInvited, collectionPoint } = this.props;

    if (!isInvited) {
      return null;
    }

    return (
      <div
        className="flex-fill"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <InviteResponse source="COLLECTION" pointId={collectionPoint._id} />
      </div>
    );
  }

  renderHeaderControls() {
    const { isInvited } = this.props;

    // Do not render controls if user is invited to collection.
    if (isInvited) {
      return;
    }

    return (
      <div className="ml-auto">
        <CollectionHeaderControls
          collectionPoint={this.props.collectionPoint}
          cardType={this.props.cardType}
          user={this.props.user}
          authenticated={this.props.authenticated}
          onCollectionPointFieldChanged={this.onCollectionPointFieldChanged}
          errorMessages={this.props.errorMessages}
          isEditing={this.state.isEditing}
          allowRemove={this.props.allowRemove}
          onRemove={this.onRemove}
          onToggleEdit={this.onToggleEdit}
          allowFollow={false}
          isDragging={this.props.onDragging}
          isOver={this.props.isOver}
          confirmDrop={this.props.confirmDrop}
          onRemoveFromGroup={
            this.props.onRemoveFromGroup && this.onRemoveFromGroup
          }
        />
      </div>
    );
  }

  renderHeader = () => {
    let className = classnames('header d-flex align-items-center', {
      'w-100 pl-3 pr-3 pt-2 pb-2':
        this.props.cardType !== 'page' && !this.state.isEditing,
    });
    return (
      <div className={className} style={{ height: '43px' }}>
        {this.renderInvite()}
        {this.renderHeaderControls()}
      </div>
    );
  };

  renderEditControls = () => {
    if (!this.state.isEditing) return;

    return (
      <div>
        <CollectionEditControls
          collectionPoint={this.props.collectionPoint}
          onEditCollectionPointField={this.onEditCollectionPointField}
          isEditing={this.state.isEditing}
          onToggleEdit={this.onToggleEdit}
          errorMessages={this.state.errorMessages}
        />
      </div>
    );
  };

  onClick = e => {
    e.stopPropagation();
    const { selectedPoints, collectionPoint } = this.props;

    if (
      this.state.isEditing ||
      (this.props.cardType != 'embed' && !this.props.allowClick) ||
      selectedPoints.length > 0
    ) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(this.props.collectionId, this.props.parentObject);
      return;
    }

    if (collectionPoint.sourceType === 'TEXT' && collectionPoint.sourceURL) {
      window.open(collectionPoint.sourceURL, '_blank');
      return;
    }

    this.props.actions.setParent(this.props.parentObject);

    let search = '?embed=';
    search += this.props.cardType == 'embed' ? 'true' : 'false';

    const location = {
      pathname: `/collection/${this.props.collectionId}`,
      search,
    };
    this.props.dispatch(push(location));
  };

  renderDropMessages() {
    const { canDrop } = this.props;

    const confirmDrop =
      this.props.dnd.targetParent.id == this.props.collectionPoint._id;

    const sourceType = this.props.dnd.sourceInfo.type;

    if (confirmDrop) {
      return (
        <div className="card-overlay">
          <p>Are you sure you want to move your selected {sourceType} here?</p>
          <div>
            <button className="btn btn-primary" onClick={this.onConfirmDrop}>
              Yes
            </button>{' '}
            <button className="btn btn-danger" onClick={this.onCancelDrop}>
              No
            </button>
          </div>
        </div>
      );
    }
    // if (canDrop) {
    //   return (
    //     <div className="card-overlay">
    //       Drop your selected {sourceType} here.
    //     </div>
    //   );
    // }
  }

  onConfirmDrop = e => {
    e.stopPropagation();
    this.props.actions.moveSubPointOnServer('target');
    // this.props.onConfirmDrop();
  };

  onCancelDrop = e => {
    e.stopPropagation();
    this.props.actions.moveSubPointOnServer('drag');
    this.props.actions.updateTargetInfo(null, null, null, null);
    // this.props.onCancelDrop();
  };

  onCollectionPointFieldChanged = (key, value) => {
    let currentCollection = {
      ...this.state.collectionPointInState,
    };

    currentCollection[key] = value;

    this.setState({
      collectionPointInState: currentCollection,
    });

    const validField = this.validateCollectionField(key, value);

    if (!validField) return;

    const validFields = this.validate();

    if (!validFields) return;
    //If it's valid save it
    this.props.actions.updateCollection(currentCollection);
  };

  validateCollectionField = (key, value) => {
    let valid = true;
    let errorMessages = this.state.errorMessages;
    switch (key) {
      case 'text':
        if (value.length < 5 || value.length > 100) {
          errorMessages.text = '5 to 100 characters';
          valid = false;
        } else {
          errorMessages.text = '';
        }
        break;
      case 'description':
        if (value.length > 500) {
          errorMessages.description = '500 characters';
          valid = false;
        } else {
          errorMessages.description = '';
        }
        break;
    }

    this.setState({
      errorMessages,
      valid,
    });

    return valid;
  };

  validate() {
    let { collectionPointInState: collectionPoint, errorMessages } = this.state;
    let errorMessage = '';

    if (!collectionPoint.text) {
      errorMessage = 'Your Collection must have a name';
    }

    for (var key in errorMessages) {
      if (errorMessages.hasOwnProperty(key)) {
        //console.log(key + " -> " + p[key]);
        if (errorMessages[key] != '') {
          errorMessage = 'Please fix the errors and re-submit';
        }
      }
    }

    this.setState({
      errorMessages,
      errorMessage,
    });

    return errorMessage === '';
  }

  onInviteCollection = () => {
    const { collectionPoint } = this.props;
    this.props.openInviteCollectionModal(collectionPoint);
  };
}

CollectionCard.defaultProps = {
  cardType: 'default',
  onRemove: () => {
    console.log('remove clicked');
  },
};

CollectionCard.propTypes = {
  allowRemove: PropTypes.bool,
  cardType: PropTypes.oneOf([
    'default',
    'preview',
    'preview highlight-on-hover',
    'embed',
    'page',
  ]),
  collectionObject: PropTypes.object,
  collectionPoint: PropTypes.object,
  point: PropTypes.object,
  walkthrough: PropTypes.object,
  onRemoveFromGroup: PropTypes.func,
  isInvited: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;

  let collectionId = null;
  let collectionPoint = null;
  let collectionObject = null;

  if (ownProps.point) {
    collectionPoint = ownProps.point;
    collectionId = ownProps.point._id;
    collectionObject =
      state.points[collectionId] || state.points[Object.keys(state.points)[2]];
  } else if (ownProps.match) {
    // If there isn't one - then check for the parameter
    collectionId = ownProps.match.params.id;
    //  TBD: Temporary Fix. Remove once we have services
    collectionObject =
      state.points[collectionId] || state.points[Object.keys(state.points)[2]];
    collectionPoint = collectionObject ? collectionObject.point : null;
  }
  const { invitationStatus } = collectionObject;
  const isInvited =
    invitationStatus &&
    invitationStatus === 'invited' &&
    collectionPoint.authorId !== user.id;

  return {
    authenticated,
    user,
    collectionId,
    collectionPoint,
    collectionObject,
    walkthrough: state.walkthrough,
    dnd: state.page.dnd,
    isInvited,
    selectedPoints: state.points.selectedPoints,
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
    openInviteCollectionModal: point => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal('invite-point', props));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionCard);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions/index';

import PointPageContainer from 'routes/Point/PointPageContainer/PointPageContainer';
import { Icon, ICONS } from 'components/Icons';

import './PointModal.scss';

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;
const REACT_APP_SCROLLING_CONTAINER_ID =
  process.env.REACT_APP_SCROLLING_CONTAINER_ID;

class PointModal extends Component {
  static propTypes = {
    model: PropTypes.object,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      point: this.props.model.point,
    };
  }

  windowKeydown = event => {
    if (this.state.isFourPackActionShowing) {
      return;
    }

    if (event.keyCode === 37) {
      this.onArrowClicked('previous');
    } else if (event.keyCode === 39) {
      this.onArrowClicked('next');
    }
  };

  componentWillMount() {
    let elm = document.getElementById(REACT_APP_SCROLLING_CONTAINER_ID);

    if (elm) {
      elm.style.overflow = 'hidden';
    }
  }

  componentDidMount() {
    // analytics.track('PointDetailsViewed');
  }

  onEntered = dialog => {
    // dynamic part
    let containerSelector = '.point-modal';
    let stickySelector = '.point-card-main';
    let contentContainerSelector = '.modal-content';

    let container = dialog.querySelector(containerSelector);
    let scrollingContainer = container;
    let sticky = container.querySelector(stickySelector);
    let contentContainer = container.querySelector(contentContainerSelector);

    let ignoreScroll = false;
    const pointImage = container.querySelector('.point-image-container');

    const stickyOn = () => {
      sticky.classList.add('is-sticky');

      ignoreScroll = true;
      setTimeout(() => {
        ignoreScroll = false;
      }, 1000);

      let imageWidth =
        (contentContainer.parentElement.offsetWidth -
          contentContainer.offsetWidth) /
        2;
      imageWidth = imageWidth > 432 ? 432 : imageWidth;

      if (pointImage) {
        pointImage.style.left = -imageWidth + 'px';
        pointImage.style.width = imageWidth + 'px';
      }
    };

    const stickyOff = () => {
      sticky.classList.remove('is-sticky');

      if (pointImage) {
        // pointImage.style.left = -imageWidth + 'px';
        pointImage.style.width = 'inherit';
      }
    };

    scrollingContainer.addEventListener('scroll', e => {
      if (ignoreScroll) {
        return;
      }

      if (
        scrollingContainer.scrollHeight - sticky.offsetHeight <
        scrollingContainer.offsetHeight
      ) {
        return;
      }

      if (e.target.scrollTop > 50 && !sticky.classList.contains('is-sticky')) {
        stickyOn();
      } else if (
        e.target.scrollTop === 0 &&
        sticky.classList.contains('is-sticky')
      ) {
        stickyOff();
      }
    });

    new ResizeObserver(() => {
      if (
        container.offsetHeight < scrollingContainer.offsetHeight &&
        sticky.classList.contains('is-sticky')
      ) {
        stickyOff();
      }
    }).observe(container);

    sticky && sticky.classList.add('sticky');

    // navigation
    const collectionPointsNavigation = container.querySelector(
      '#collection-points-navigation',
    );

    if (collectionPointsNavigation) {
      container.parentElement.appendChild(collectionPointsNavigation);
    }

    window.addEventListener('keydown', this.windowKeydown);

    container.addEventListener(
      'click',
      e => {
        if (e.target === container) {
          this.onClose();
        }
      },
      true,
    );
  };

  componentWillUnmount() {
    let elm = document.getElementById(REACT_APP_SCROLLING_CONTAINER_ID);

    if (elm) {
      elm.style.overflow = 'unset';
    }

    window.removeEventListener('keydown', this.windowKeydown);
  }

  onClose = () => {
    // Called in a lot of places, but all this does is call wrappers for actions.goBack
    this.props.onClose(false);
  };

  changePoint = newPointId => {
    this.setState({
      point: {
        _id: newPointId,
      },
    });
  };

  onArrowClicked = direction => {
    const { parentObject } = this.props.model;
    const { list, index } = parentObject;
    let newIndex;
    if (direction === 'previous') {
      newIndex = index - 1;
    } else {
      newIndex = index + 1;
    }
    const newPageId = list[newIndex];

    if (!newPageId) return;

    this.props.actions.setParentIndex(newIndex);

    this.changePoint(newPageId);
  };

  onFourPackAction = show => {
    this.setState({ isFourPackActionShowing: show });
  };

  renderNavigation() {
    const { parentObject } = this.props.model;

    if (!parentObject) {
      return;
    }

    return (
      <div
        className="collection-points-navigation"
        id="collection-points-navigation"
      >
        <div className="count d-none d-md-block">
          {parentObject.index + 1} of {parentObject.list.length} points
        </div>
        <div className="count d-md-none">
          {parentObject.index + 1} / {parentObject.list.length}
        </div>
        <div
          className="previous"
          onClick={() => {
            this.onArrowClicked('previous');
          }}
        >
          <Icon icon={ICONS.CHEVRON_LEFT} />
        </div>
        <div
          className="next"
          onClick={() => {
            this.onArrowClicked('next');
          }}
        >
          <Icon icon={ICONS.CHEVRON_RIGHT} />
        </div>
      </div>
    );
  }

  render() {
    const {
      parentObject,
      showReviewOnOpen,
      fourPackActiveTab,
    } = this.props.model;
    const { point } = this.state;
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    return (
      <Modal
        show={true}
        dialogClassName="point-modal custom-modal"
        container={container}
        backdrop={true}
        onHide={this.onClose}
        onEntered={this.onEntered}
      >
        <Modal.Body className="pl-0 pr-0">
          <Icon
            icon={ICONS.CLOSE}
            onClick={this.onClose}
            className="position-absolute mr-3 mt-4"
          />
          <PointPageContainer
            point={point}
            parentObject={parentObject}
            onChangePoint={this.changePoint}
            onBack={this.onClose}
            showReviewOnOpen={showReviewOnOpen}
            isModal
            pointDetailView={
              fourPackActiveTab
                ? { point: point, tab: fourPackActiveTab }
                : null
            }
            onFourPackAction={this.onFourPackAction}
          />
          {this.renderNavigation()}
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointModal);

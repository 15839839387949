import * as React from "react";
import { Modal } from "react-bootstrap";
import RespondInvitePointComponent from "./RespondInvitePointComponent";

export default class RespondInvitePointContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      message: ""
    };

  }

  render() {
    return (
      <RespondInvitePointComponent
        message={this.state.message}
        onRespondInvite={this.onRespondInvite}
      />
    );
  }

  onClose = () => {
    this.setState({
      errorMessage: "",
      message: ""
    });

    //this.props.onClose();
  }

  onRespondInvite = (response) => {
    console.log("send invite got called");

    this.props.onRespondInvite(response);
    //this.props.onClose();
  }
}

import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import { toast } from 'components/elements/molecules/Toast/Toast';
import { scopes } from 'config/googleConfig';

import './LinkToGoogle.scss';

function LinkToGoogle({ actions }) {
  const [loading, setLoading] = useState(false);
  const googleClient = useRef(null);

  useEffect(() => {
    const init = () => {
      googleClient.current = window.google.accounts.oauth2.initCodeClient({
        client_id: '208379708790-krb2m1pog4aok6frv6rpe587ubclj4k4.apps.googleusercontent.com',
        scope: scopes.join(' '),
        ux_mode: 'popup',
        response_type: 'code',
        callback: handleResponse,
      });
    };

    init();
  }, []);

  const connectToGoogle = () => {
    if (!googleClient.current) {
      return;
    }

    googleClient.current.requestCode();
  };

  const handleResponse = async (response) => {
    console.log('Response received from google:', response);
    if (!response || !response.code) {
      console.error('Not a valid response received from google');
      toast(
        'Valid response not received from google. Please try again later.',
        {
          type: 'error',
        },
      );
      return;
    }

    try {
      setLoading(true);

      const linkResponse = await actions.linkToGoogle(response.code);

      console.log('Response from lintToGoogle', linkResponse);
    } catch (error) {
      console.error('Error occurred while linking to google', error);

      if (error.errorCode === 'EMAIL_DOES_NOT_MATCH') {
        toast(
          'Please link to the google account with same email id',
          {
            type: 'error',
          },
        );

        return;
      }
      toast(
        'Error occurred while linking to google. Please try again later.',
        {
          type: 'error',
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return <div className="link-to-google">
    {
      !loading ? (
        <button type="button" className="login-with-google-btn mt-4" onClick={() => connectToGoogle()}>
          Connect to Google Account
        </button>
      ) : (
        <div className="text-center mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '40px' }}
          />
        </div>
      )
    }
  </div>;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(LinkToGoogle);

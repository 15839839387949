import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import * as Actions from '../../../redux/actions';
import { Icon, ICONS } from '../../Icons';

import './VerifySubscription.scss';

function VerifySubscription({
  onClose,
  subscriptionId,
  sourceName,
  status,
  actions,
}) {
  const [adminNote, setAdminNote] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    actions
      .verifySubscriptionRequest({
        subscriptionId,
        status,
        adminNote,
      })
      .then((data) => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.error(
          'Error while submitting source subscription request',
          error,
        );
        setError(
          error.message || 'Error while submitting source subscription request',
        );
        setLoading(false);
      });
  };

  return (
    <Modal show={true} className="badge-details-modal">
      <Modal.Body>
        <div className="header">
          <span>
            {status === 'approved' ? 'Approve' : 'Reject'} <b>{sourceName}</b>{' '}
            Subscription
          </span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="subscription-container my-5">
          <React.Fragment>
            <div className="my-4">
              <FloatingInput
                value={adminNote}
                labelName="Note"
                onChange={(e) => setAdminNote(e.target.value)}
              />
            </div>
            {error && <div className="error-message my-3">{error}</div>}
          </React.Fragment>
        </div>
        <div className="submit-container">
          {loading ? (
            <div className="text-center mt-4">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '40px' }}
              />
            </div>
          ) : (
            <RectangularButton onClick={onSubmit}>Submit</RectangularButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifySubscription);

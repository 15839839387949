import React from 'react';
import { Icon, ICONS } from '../../Icons';

class CollectionListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    this.props.handleClick(this.props.collectionPoint._id);
  };

  handleRemove = () => {
    this.props.handleClick(null);
  };

  renderRemoveButton() {
    const { collectionPoint, selectedCollectionId } = this.props;
    if (collectionPoint._id != selectedCollectionId) {
      return;
    }
    return (
      <div className="remove-collection" onClick={this.handleRemove}>
        <Icon icon={ICONS.CLOSE} />
      </div>
    );
  }

  render() {
    const { collectionPoint, selectedCollectionId } = this.props;
    let itemClassName = 'collection-list-item';
    if (collectionPoint._id === selectedCollectionId)
      itemClassName += ' selected';
    return (
      <div>
        <div className={itemClassName} onClick={this.handleClick}>
          <span className="collection-name">{collectionPoint.text}</span>
          <span className="visibility-icon">
            <Icon
              icon={
                collectionPoint.status === 'public' ? ICONS.GLOBE : ICONS.LOCK
              }
            />
          </span>
        </div>
        {this.renderRemoveButton()}
      </div>
    );
  }
}

export default CollectionListItem;

import React, { useState, useEffect } from 'react';

import { Icon, ICONS } from 'components/Icons';
import { AuthorTree } from './../AuthorTree/AuthorTree';

import './InviteAuthors.scss';

export function InviteAuthors({
  authors,
  selectedAuthors = [],
  onSelect,
  onRemove,
  allAuthorsText,
}) {
  const [showTree, setShowTree] = useState(false);

  useEffect(() => {
    function onDocumentClick() {
      setShowTree(false);
    }
    window.addEventListener('click', onDocumentClick, false);
    return () => {
      window.removeEventListener('click', onDocumentClick, false);
    };
  }, []);

  function isAllAuthorsSelected() {
    return authors.length === selectedAuthors.length;
  }

  function renderAuthor(author, authorId) {
    return (
      <div
        className="d-inline-block bg-av-light-gray pt-2 pb-2 pl-3 pr-3 mr-3 mt-1 mb-1"
        style={{ borderRadius: '20px' }}
        key={authorId}
      >
        <span>{author}</span>
        <Icon
          icon={ICONS.CLOSE}
          className="av-red ml-3"
          onClick={() => {
            onRemove(authorId);
          }}
        />
      </div>
    );
  }

  function renderDropdown() {
    let elmAuthors = [];

    if (selectedAuthors.length === 0) {
      elmAuthors = <div className="av-grayish-cyan">Select Authors</div>;
    } else if (isAllAuthorsSelected()) {
      elmAuthors.push(renderAuthor(allAuthorsText, 'ALL'));
    } else {
      elmAuthors.push(
        ...selectedAuthors.map((authorId) => {
          let author = authors.find((i) => i.userId === authorId);
          return renderAuthor(author.username, author.userId);
        }),
      );
    }

    return (
      <div
        className="pb-0 d-flex align-items-center"
        onClick={(event) => {
          setShowTree(!showTree);
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div
          className="m-0 flex-fill"
          style={{
            minWidth: 0,
          }}
        >
          {elmAuthors}
        </div>
        <Icon icon={ICONS.ARROW_DOWN} size={14} className="flex-shrink-0" />
      </div>
    );
  }

  function renderAuthorsTree() {
    if (showTree) {
      return (
        <AuthorTree
          authors={authors}
          selectedAuthors={selectedAuthors}
          onSelect={onSelect}
          onClose={() => setShowTree(false)}
          allAuthorsText={allAuthorsText}
        />
      );
    }
  }

  return (
    <div className="invite-authors position-relative">
      <div>{renderDropdown()}</div>
      {renderAuthorsTree()}
    </div>
  );
}

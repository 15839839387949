import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import './css/FloatingTextarea.scss';

class FloatingTextarea extends Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    tabIndex: PropTypes.number,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onPaste: PropTypes.func,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    autoComplete: PropTypes.string,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    const { props } = this;
    const minRows = props.minRows ? props.minRows : 1;
    const maxRows = props.maxRows ? props.maxRows : 10;

    return (
      <div className={`floating-textarea ${props.error ? 'errorInput' : ''}`}>
        <Textarea
          value={this.state.value}
          id={props.id}
          tabIndex={props.tabIndex}
          name={props.name}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
          onChange={this.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          maxLength={props.maxLength}
          autoComplete={'off'}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={props.placeholder}
          required
        />
        {props.hideBar ? '' : <span className="bar" />}
        <label>{props.labelName}</label>
        {props.error && <span className="error">{props.error}</span>}
        {props.children}
      </div>
    );
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    if (this.props.onChange) this.props.onChange(e);
  };
}

// props => {
//   const minRows = props.minRows ? props.minRows : 1;

//   return (
//     <div className={`floating-textarea ${props.error ? 'errorInput' : ''}`}>
//       <Textarea
//         value={props.value}
//         id={props.id}
//         tabIndex={props.tabIndex}
//         name={props.name}
//         autoFocus={props.autoFocus}
//         onKeyDown={props.onKeyDown}
//         onChange={props.onChange}
//         onBlur={props.onBlur}
//         disabled={props.disabled}
//         maxLength={props.maxLength}
//         autoComplete={'off'}
//         minRows={minRows}
//         required
//       />
//       {props.hideBar ? '' : <span className="bar" />}
//       <label>{props.labelName}</label>
//       {props.error && <span className="error">{props.error}</span>}
//       {props.children}
//     </div>
//   );
// };

export default FloatingTextarea;

import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_CLUB_TALKS = 'LOAD_CLUB_TALKS';
export const LOAD_TALK_READINGS = 'LOAD_TALK_READINGS';

export function loadClubTalks(clubTalks) {
  return {
    type: LOAD_CLUB_TALKS,
    clubTalks,
  };
}

export function loadTalkReadings(id, readings) {
  return {
    type: LOAD_TALK_READINGS,
    id,
    readings,
  };
}

export function getClubTalks() {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/newsClub/clubTalks')
        .then((response) => {
          const data = response.data;
          dispatch(loadClubTalks(data));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching club talks', error);
          toast('Error occurred while fetching club talks. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function fetchTalkReadings(id) {
  return (dispatch) => {
    console.log('id', id);
    return new Promise((resolve) => {
      axios
        .get('/api/newsClub/fetchTalkReadings', { params: { id } })
        .then((response) => {
          const data = response.data;
          dispatch(loadTalkReadings(id, data));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching readings', error);
          toast('Error occurred while fetching readings. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

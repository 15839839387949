export const SHOW_HEADER = 'SHOW_HEADER';
export const SET_PARENT = 'SET_PARENT';
export const SET_PARENT_INDEX = 'SET_PARENT_INDEX';
export const REMOVE_PARENT = 'REMOVE_PARENT';
export const CLEAR_PARENT_STACK = 'CLEAR_PARENT_STACK';
export const SET_RECENT_TAGS = 'SET_RECENT_TAGS';
export const SET_RECENT_SOURCE_INFO = 'SET_RECENT_SOURCE_INFO';

export function showHeader(isVisible) {
  return {
    type: SHOW_HEADER,
    data: {
      showHeader: isVisible,
    },
  };
}

export function setParent(parentObject) {
  return {
    type: SET_PARENT,
    data: {
      parentObject,
    },
  };
}

export function setParentIndex(index) {
  return {
    type: SET_PARENT_INDEX,
    data: {
      index: index,
    },
  };
}

export function goBack() {
  return {
    type: REMOVE_PARENT,
    data: {},
  };
}

export function clearParentHistory() {
  return {
    type: CLEAR_PARENT_STACK,
    data: {},
  };
}

export function setRecentTags(recentTags) {
  return {
    type: SET_RECENT_TAGS,
    data: {
      recentTags,
    },
  };
}

export function setRecentSourceInfo(savedPoint) {
  const recentSourceInfo = {
    sourceURL: savedPoint.sourceURL,
    sourceName: savedPoint.sourceName,
    sourceTitle: savedPoint.sourceTitle,
    sourceDate: savedPoint.sourceDate,
    sourceDateFormatted: savedPoint.sourceDateFormatted,
    sourceAuthor: savedPoint.sourceAuthor,
    sourceLogoURL: savedPoint.sourceLogoURL,
  };
  return {
    type: SET_RECENT_SOURCE_INFO,
    data: {
      recentSourceInfo,
    },
  };
}

export function onDragStarted(sourceType, sourceId, originalParent) {
  return {
    type: 'START_DND',
    data: {
      sourceType,
      sourceId,
      originalParent,
    },
  };
}

export function updateDragInfo(x, y) {
  console.log('Update drag info got called');
  return {
    type: 'UPDATE_DRAG_INFO',
    data: {
      x,
      y,
    },
  };
}

export function updateTargetInfo(type, id, level, index) {
  return {
    type: 'UPDATE_TARGET_INFO',
    data: {
      targetParent: {
        type,
        id,
        level,
        index,
      },
    },
  };
}

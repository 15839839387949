const selectGroupsRoot = state => state.groups;
const selectPointsRoot = state => state.points;

export const selectFeaturedGroups = state => {
  const { featured, entities, loading } = selectGroupsRoot(state);

  const data = [];
  featured.forEach(id => {
    const group = entities[id];
    if (group) data.push(group);
  });
  return {
    groups: data,
    loading,
  };
};

export const selectMyGroups = state => {
  const { mine, entities, loading } = selectGroupsRoot(state);

  const data = [];
  mine.forEach(id => {
    const group = entities[id];
    if (group) data.push(group);
  });
  return {
    groups: data,
    loading,
  };
};

export const selectGroupDetails = (state, id) => {
  const { loading, errorMessage, entities } = selectGroupsRoot(state);
  const group = (entities[id] && entities[id].meta) ? entities[id] : null; // don't return details for partial Group

  return {
    loading,
    errorMessage,
    group,
  };
};

export const selectGroupCollections = (state, id) => {
  const { entities, groupCollections } = selectGroupsRoot(state);

  const points = selectPointsRoot(state);

  const group = entities[id];

  const collections = [];

  // if(!groupCollections.loading && groupCollections[id]){
  if (groupCollections[id]) {
    groupCollections[id].forEach(groupCollection => {
      const collectionPointObject = points[groupCollection._id];
      if (collectionPointObject) {
        collections.push(collectionPointObject.point);
      }
    })
  }

  //const collections = groupCollections[id] || [];
  return {
    loading: groupCollections.loading,
    collections,
  };
};

export const selectAnnouncements = (state, id) => {
  const { groupAnnouncements } = selectGroupsRoot(state);
  const announcements = groupAnnouncements[id] || [];
  return {
    loading: groupAnnouncements.loading,
    announcements,
  };
};

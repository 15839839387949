import axios from "axios";
import { push } from "react-router-redux";
import config from "config/config.public.js";

export const START_TUTORIAL = "START_TUTORIAL";
export const RESTORE_TUTORIAL_STATE = "RESTORE_TUTORIAL_STATE";
export const GOTO_NEXT_STEP = "GOTO_NEXT_STEP";
export const COMPLETE_TUTORIAL = "COMPLETE_TUTORIAL";

const makeApiRequest = user =>
  axios.put(`/api/users/updateUserSiteProgress/${user._id}`, user, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const startTutorial = payload => ({ type: START_TUTORIAL, payload });

export const startTutorialAsync = user => {
  return dispatch =>
    makeApiRequest(user)
      .then(response => {
        const { siteProgress } = response.data;
        dispatch(startTutorial(siteProgress));
      })
      .catch(error => console.log(error));
};

export const restoreTutorialState = (payload, dispatch) => {
  setTimeout(function () {
    dispatch({
      type: RESTORE_TUTORIAL_STATE,
      payload,
    });
  }, 100); // timeout to prevent dispatching while constructing `session` middleware
};

export const gotoNextStep = payload => ({
  type: GOTO_NEXT_STEP,
  payload,
});

export const gotoNextStepAsync = payload => {
  const { user, step } = payload;
  return (dispatch, getState) =>
    makeApiRequest({
      ...user,
      siteProgress: {
        tourStatus: getState().walkthrough.tourStatus,
        tourStep: step,
      },
    })
      .then(() => {
        if (step == 10) {
          const stage = config.stage;
          //const walkthrough = config[stage]
          const location = {
            pathname: "/Point/" + config[stage].walkthrough.pointId,
          };
          dispatch(push(location));
        }
        dispatch(gotoNextStep(step));
      })
      .catch(error => console.log(error));
};

export const completeTutorial = payload => ({
  type: COMPLETE_TUTORIAL,
  payload,
});

export const completeTutorialAsync = payload => {
  const { user, status = "finished" } = payload;
  return dispatch =>
    makeApiRequest({
      ...user,
      siteProgress: {
        tourStatus: status,
        tourStep: 0,
      },
    })
      .then(() => dispatch(completeTutorial(status)))
      .catch(error => console.log(error));
};

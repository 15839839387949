import React, { Component } from "react";

import { Button } from "react-bootstrap";

import PointContainer from "components/cards/Point/PointContainer.js";
import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";

require("../css/SuccessModal.scss");

class SuccessComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <img src="../img/success.svg" className="successImg" />
        <div className="successHeader">Success!</div>
        <div className="description">{this.props.message}</div>
        {this.props.point ? (
          <div>
            <div> Preview</div>
            {
              <PointContainer
                cardType="preview"
                point={this.props.point}
                citationStyle="short"
                disableClickThru={true}
              />
            }
          </div>
        ) : (
          <div />
        )}
        <div className="doneDiv">
          <RoundedButton
            mainClassName="doneButton"
            label={this.props.doneLabel}
            onClick={this.props.onClose}
          />
        </div>
      </div>
    );
  }
}

export default SuccessComponent;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from 'redux/actions/index';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import TourHighlight from 'components/elements/molecules/TourHighlight';
import AddPoint from 'components/modals/AddPoint/AddPoint';
import SubPoints from './Component/SubPoints';
import EvidenceFilter from './Component/EvidenceFilter';

import './PointEvidence.scss';

class PointEvidence extends Component {
  static propTypes = {
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    pointId: PropTypes.string.isRequired,
    permissionLevel: PropTypes.string,
    /** ***redux  */
    actions: PropTypes.object,
    point: PropTypes.object,
    pointObject: PropTypes.object,
    onAddPoint: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      againstTag: '',
      filter: 'All',
      // subPointView: 'page-sub-point',
      // subPointView: 'card',
      subPointView: 'outline',
    };
    this.target = React.createRef();
  }

  componentDidMount() {
    // this.calculateSubPointInfo();
  }

  filterSubPoints = (filter) => {
    this.setState({
      filter,
    });
  }

  renderEvidence() {
    const { filter } = this.state;
    let numFor = 0;
    let numAgainst = 0;
    let numNeutral = 0;
    let totalSubPoints = 0;

    if (this.props.point.subPoints) {
      this.props.point.subPoints.forEach(subPoint => {
        if (subPoint.stance == 'For') numFor++;
        else if (subPoint.stance == 'Against') numAgainst++;
        else if (subPoint.stance === 'Neutral') numNeutral++;
      });
      totalSubPoints = this.props.point.totalSubPoints;
    }

    const indicatorWidth =
      numFor + numAgainst > 0 ? (numFor * 100) / (numFor + numAgainst) : 50;

    return (
      <div className="evidence-actions">
        <div className="evidence-filter">
          <EvidenceFilter
            user={this.props.user}
            filter={filter}
            filterSubPoints={this.filterSubPoints}
            handleEvidenceTab={this.handleEvidenceTab}
            numTotal={totalSubPoints}
            numFor={numFor}
            numAgainst={numAgainst}
            numNeutral={numNeutral}
          />
        </div>
      </div>
    );
  }

  renderHeader() {
    const { point } = this.props;

    return (
      <div className="d-flex align-items-center bg-av-light-gray pt-4 pb-4 pl-4 pr-4">
        {this.renderEvidence()}
        <div className="av-black font-size-12">
          {point.subPoints.length}{' '}
          <span className="av-grayish-cyan">Subpoints</span>
        </div>
        {this.renderAddEvidenceLink()}
      </div>
    );
  }

  render() {
    const subPointIds = [];
    if (this.props.point.subPoints) {
      this.props.point.subPoints.forEach(subPoint => {
        if (subPoint && subPoint.pointId) subPointIds.push(subPoint);
        // If we had clean subPoint data, wouldn't need this
      });
    }


    return (
      <div className="point-evidence p-4" ref={this.target}>
        <TourHighlight
          target={this.target}
          placement="top"
          step={11}
          visible
          gotoNextStep
        >
          To support your main Point, you can also add evidence or sub-Points.
          In this Point, we added 3 sub-points to support the main Point.
        </TourHighlight>
        {/* {this.renderHeader()} */}
        {this.renderAddPoint()}
        {this.renderSubPoints(subPointIds)}
      </div>
    );
  }

  renderAddPoint() {
    const { point } = this.props;

    const model = {
      collectionId: point._id,
      hideImportFromPortfolio: true,
    };
    return (
      <div className="mb-3">
        <AddPoint
          type="LIGHTWEIGHT"
          model={model}
          placeholder="Add a SubPoint"
        />
      </div>
    );
  }

  renderCardOrListLinks = () => (
    <div className="card-or-list">
      <ActionLink
        onClick={() => this.setState({ subPointView: 'card' })}
        linkClassName={this.state.subPointView == 'card' ? ' active' : ''}
        iconType="averPointIcon"
        iconName="GRID"
        label="Card"
        toolTipText="View sub-Points as Cards."
        toolTipPosition="bottom"
      />
        &nbsp;
      <ActionLink
        onClick={() => this.setState({ subPointView: 'outline' })}
        linkClassName={this.state.subPointView == 'outline' ? ' active' : ''}
        iconType="averPointIcon"
        iconName="LIST"
        label="Outline"
        toolTipText="View sub-Points as an Outline."
        toolTipPosition="bottom"
      />
    </div>
  );

  renderAddEvidenceLink = () => {
    // Uncomment to only allow authors to add evidence.
    // if (this.props.permissionLevel != 'write') return;

    if (!this.props.authenticated) return;

    return (
      <div className="add-evidence-row ml-auto">
        <ActionLink
          onClick={this.onAddPoint}
          linkClassName="add-evidence-link font-size-12"
          iconType="averPointIcon"
          iconName="ADD"
          iconClassName="av-blue mr-3"
          label="Add a sub-Point"
          toolTipText="Add a Point to back up the main Point."
          toolTipPosition="bottom"
          iconSize={10}
        />
      </div>
    );
  };

  renderSubPoints(subPointIds) {
    return (
      <SubPoints
        user={this.props.user}
        point={this.props.point}
        subPointIds={subPointIds}
        filter={this.state.filter}
        subPointView={this.state.subPointView}
      />
    );
  }

  onAddPoint = e => {
    const { pointObject } = this.props;
    const { status } = pointObject.point;

    let title;
    if (pointObject.point.authorId === this.props.user._id) {
      title = 'Add evidence to your Point';
    } else {
      title = `Suggest evidence to ${pointObject.point.authorName} Point`;
    }
    this.props.openAddPointModal(
      pointObject.point.subPoints,
      this.afterPointCreated,
      status,
      title,
      pointObject,
      this.saveSubPointToPoint,
    );
  };

  afterPointCreated = (pointId, dispatch, stance) => {
    console.log(`saving subpoint with stance ${stance}`);
    // Create Point Container is calling this for saves to Collections and Points
    // this.saveSubPointToPoint(pointId, stance);
  };

  saveSubPointToPoint = (subPointId, stance) => {
    this.props.actions.saveSubPointToPoint(
      subPointId,
      this.props.point._id,
      stance,
    );
  };
}

function mapStateToProps(state, ownProps) {
  const { user } = state.user;

  const { pointId } = ownProps;
  const pointObject = state.points[pointId];

  return {
    user,
    authenticated: state.user.authenticated,
    pointObject,
    point: pointObject.point,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openAddPointModal: (
      existingSubPoints,
      afterPointCreated,
      status,
      title,
      pointObject,
      onPointImported,
    ) => {
      const props = {
        model: {
          // collectionId: null,
          collectionId: pointObject.point._id,
          afterPointCreated,
          onPointImported,
          existingSubPoints,
          title,
          status,
          showAddToCollection: false, // When saving subPoints, you don't also add to a Collection. This is confusing to users. Instead, you can achieve this selecting Collect on the subPoint
          showStance: true,
          pointObject,
        },
      };
      dispatch(Actions.openModal('add-point-modal', props));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointEvidence);

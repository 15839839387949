export const SHOW_SUBSCRIBE_NEWSLETTER = 'SHOW_SUBSCRIBE_NEWSLETTER';
export const HIDE_SUBSCRIBE_NEWSLETTER = 'HIDE_SUBSCRIBE_NEWSLETTER';
export const SUBSCRIBED_NEWSLETTER = 'SUBSCRIBED_NEWSLETTER';
export const CLOSE_SUBSCRIBE_NEWSLETTER = 'CLOSE_SUBSCRIBE_NEWSLETTER';

export function showSubscribeNewsletter() {
  return {
    type: SHOW_SUBSCRIBE_NEWSLETTER,
  };
}

export function hideSubscribeNewsletter() {
  return {
    type: HIDE_SUBSCRIBE_NEWSLETTER,
  };
}

export function subscribedNewsletter() {
  return {
    type: SUBSCRIBED_NEWSLETTER,
  };
}

export function closeNewsletter() {
  return {
    type: CLOSE_SUBSCRIBE_NEWSLETTER,
  };
}

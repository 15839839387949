import React, { Component } from 'react';
import { Icon, ICONS } from 'components/Icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import * as Actions from '../../../../../redux/actions';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import './Notifications.scss';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationMessage: '',
      open: false,
    };

    if (props.authenticated) {
      this.props.actions.getUserInvites();
      this.props.actions.getNotifications();
    }
  }

  componentDidMount() {
    const controlsicon = document.getElementById('notifications-bell');
    document.body.addEventListener(
      'click',
      (e) => {
        const target = e.target;
        if (target !== controlsicon && !this.isChildOf(target, controlsicon)) {
          this.setState({
            open: false,
          });
        }
      },
      false,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.actions.getUserInvites();
      this.props.actions.getNotifications();
    }
  }

  isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    }
    return this.isChildOf(child.parentNode, parent);
  }

  sendVerificationEmailAgain = () => {
    //TODO - sendVerificationEmailAgain action
    this.setState({
      confirmationMessage: (
        <p className="confirmation-message">
          A new verification link was sent to your email on record.
        </p>
      ),
    });
  };

  toggleNotifications = () => {
    // if (
    //   !this.state.open &&
    //   this.props.notifications.filter(i => !i.seen).length
    // ) {
    //   this.props.notificationsSeen();
    // }

    this.setState({
      open: !this.state.open,
    });
  };

  renderMyInvites() {
    const { invitesCount } = this.props;

    if (invitesCount === 0) {
      return null;
    }

    return (
      <div className="notification d-flex align-items-center bg-av-light-gray shadow-sm">
        <div>
          <span
            className="bg-av-blue av-white pl-2 pr-2"
            style={{ borderRadius: '24px' }}
          >
            {invitesCount}
          </span>
          <span className="ml-3 av-gray">New Invites</span>
        </div>
        <Link to="/my-invites" className="ml-auto go-to-my-invite">
          Go to My Invites
        </Link>
      </div>
    );
  }

  renderNotificationCount() {
    return (
      <div className="notification-count notification">
        You have {this.props.notifications.length} notifications (
        {this.props.unreadNotificationsCount} unread)
      </div>
    );
  }

  renderNotifications() {
    return this.props.notifications.map((notification, index) => {
      return this.renderNotification(notification, index);
    });
  }

  renderNotification(notification, index) {
    let notificationText = notification.text;
    if (notification.URL) {
      notificationText = (
        <Link to={'/' + notification.URL}>{notification.text}</Link>
      );
    }

    let profileImage;

    if (notification.userId) {
      if (notification.meta && notification.meta.photoUserId) {
        profileImage = (
          <span className="mr-3">
            <ProfileImage
              userId={notification.meta.photoUserId}
              allowUserToChangePic={false}
              allowClickToPortfolio={false}
              size={24}
            />
          </span>
        );
      }
    }
    return (
      <div className="notification d-flex align-items-top" key={index}>
        {profileImage}
        <div>
          <div>{notificationText}</div>
          <div className="gray-3 mt-2">
            {moment(notification.date).format('MMMM DD, YYYY hh:mm A')}
          </div>
        </div>
      </div>
    );
  }

  renderMarkAllAsRead() {
    const { unreadNotificationsCount } = this.props;

    // if (unreadNotificationsCount === 0) {
    //   return;
    // }

    return (
      <div
        className="notification text-center font-weight-bold av-blue mark-all-as-read shadow-sm"
        onClick={() => this.props.notificationsSeen()}
      >
        Mark all as read
        <ActionLink
          onClick={() => {
            this.props.dispatch(push('/settings?tab=notifications'));
          }}
          linkClassName="float-right av-grayish-cyan"
          iconType="averPointIcon"
          iconName="SETTINGS"
          iconSize={24}
          iconClassName="mr-0"
        />
      </div>
    );
  }

  render() {
    const hasNotificationClass =
      this.props.unreadNotificationsCount > 0 || this.props.invitesCount
        ? 'has-notifications'
        : '';

    return (
      <div className="navbar-notifications-container">
        <div id="notifications-bell" onClick={this.toggleNotifications}>
          <div className={'notification-icon ' + hasNotificationClass}>
            <Icon icon={ICONS.NOTIFICATIONS} />
          </div>
        </div>
        <div
          className={`notifications-list ${
            this.state.open ? 'open' : 'closed'
          }`}
        >
          {/* <div className="visible-xs">
            <Button
              bsStyle="link"
              className="go-back"
              onClick={this.toggleNotifications}
            >
              <Icon icon={ICONS.ANGLE_LEFT} /> back
            </Button>
          </div> */}
          {this.renderMyInvites()}
          <div className="notifications">
            {this.renderNotificationCount()}
            {this.renderNotifications()}
          </div>
          {this.renderMarkAllAsRead()}
        </div>
      </div>
    );
  }
}

Notifications.defaultProps = {
  notifications: [],
};

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  let user = state.user.user;
  let allCollectionPoints = Object.keys(state.points)
    .filter(
      (i) =>
        state.points[i] &&
        state.points[i].point &&
        state.points[i].invitationStatus === 'invited',
    )
    .map((i) => state.points[i].point);
  const groups = Object.keys(state.groups.entities)
    .map((i) => state.groups.entities[i])
    .filter(
      (i) => i.meta && (i.meta.isInvitedLeader || i.meta.isInvitedMember),
    );

  const unreadNotifications = ownProps.notifications.filter(
    (notification) => notification.seen === false,
  );

  return {
    authenticated,
    invitesCount: allCollectionPoints.length + groups.length,
    unreadNotificationsCount: unreadNotifications.length,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

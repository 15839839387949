import React, { useReducer } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import * as Actions from 'redux/actions';
import { Icon, ICONS } from 'components/Icons';
import { isMobile } from 'helpers';

import './FourPack.scss';

function FourPack({
  point,
  collection,
  onChange,
  activeTab = '',
  className,
  openSharePointModal,
  evidenceDisabled = false,
  qtpDisabled = false,
  rtpDisabled = false,
  commentDisabled = false,
  stpDisabled = false,
  shareDisabled = false,
  priorityUsers,
  priorityNumQTP,
  priorityNumRTP,
  showCTPs = false,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_ACTIVE_TAB':
          return {
            ...state,
            activeTab: action.payload,
          };
        default:
          return state;
      }
    },
    {
      activeTab,
    },
  );

  function getActiveTab() {
    return onChange ? activeTab : state.activeTab;
  }

  function onActiveTab(tab) {
    if (tab !== 'SHARE') {
      if (onChange) {
        onChange(tab);
      } else {
        dispatch({
          type: 'SET_ACTIVE_TAB',
          payload: tab,
        });
      }
    } else {
      let props = { model: point, type: 'POINT' };

      if (collection) {
        let url;

        if (point._id) {
          url = `${window.location.origin}/collection/${collection._id}?claimId=${point._id}`;
        } else {
          url = `${window.location.origin}/collection/${collection._id}?claimTimestamp=${point.sourceTimeStampStart}`;
        }

        props.model = {
          ...props.model,
          shareUrl: url,
          iframeUrl: url,
          directLink: url,
          mediumLink: url,
        };
      } else {
        const url = `${window.location.origin}/point/${point._id}`;
        props.model = {
          ...props.model,
          shareUrl: url,
          iframeUrl: url,
          directLink: url,
          mediumLink: url,
        };
      }

      openSharePointModal(props);
    }
  }

  function renderTabButton(
    tabValue,
    title,
    icon,
    iconSize,
    count,
    cn,
    toolTipText,
    disabled,
    toolTipPosition,
  ) {
    const aTab = getActiveTab();
    const className = classnames(
      'btn tab bg-transparent d-inline-flex align-items-center font-size-12 p-0',
      cn,
      {
        active: aTab === tabValue,
        'font-weight-700': aTab,
      },
    );
    let text;

    if (aTab && !isMobile() && text) {
      text = <span className="mr-2">{title}</span>;
    }

    return (
      <button
        className={className}
        type="button"
        onClick={(event) => {
          onActiveTab(tabValue);
          event.stopPropagation();
          event.preventDefault();
        }}
        disabled={disabled}
      >
        <Icon
          icon={icon}
          size={iconSize}
          toolTipClassName="mr-2"
          toolTipText={toolTipText}
          toolTipPosition={toolTipPosition}
        />
        {text}
        <span>{count}</span>
      </button>
    );
  }

  // function renderEvidenceButton() {
  //   return renderTabButton(
  //     'EVIDENCE',
  //     'Evidence',
  //     ICONS.EVIDENCE,
  //     22,
  //     point.subPoints.length,
  //     'evidence',
  //     'The evidence for and against the claim',
  //     evidenceDisabled,
  //     'bottom',
  //   );
  // }

  function renderSTPButton() {
    return renderTabButton(
      'STP',
      '',
      ICONS.STP,
      22,
      point.numSTP,
      'stp ml-auto',
      'Save this point',
      stpDisabled,
      'rightbottom',
    );
  }

  function renderShareButton() {
    return renderTabButton(
      'SHARE',
      '',
      ICONS.SAVE_1,
      22,
      point.numShares,
      'share mr-0',
      'Share on social media, Email, embed, or save to your AverPoint collections',
      shareDisabled,
      'rightbottom',
    );
  }

  function renderQTPButton() {
    return renderTabButton(
      'QTP',
      'Questioned',
      ICONS.QUESTION,
      20,
      priorityUsers && priorityUsers.length ? priorityNumQTP : point.numQTP,
      'qtp',
      'Question this Point',
      qtpDisabled,
      'bottom',
    );
  }

  function renderReviewButton() {
    return renderTabButton(
      'REVIEW',
      'Reviews',
      ICONS.REVIEW,
      22,
      priorityUsers && priorityUsers.length ? priorityNumRTP : point.numRTP,
      'rtp',
      'Review this Point',
      rtpDisabled,
      'bottom',
    );
  }

  function renderCommentButton() {
    if (!showCTPs) {
      return null;
    }

    return renderTabButton(
      'COMMENT',
      'Comments',
      ICONS.CHAT,
      22,
      point.numCTP,
      'comment',
      'Comment on this Point',
      commentDisabled,
      'bottom',
    );
  }

  // function renderTag() {
  //   if (state.activeTab) {
  //     return null;
  //   }

  //   return (
  //     <div
  //       className="d-inline-flex align-items-center font-size-12 bg-av-accent-cyan-light av-accent-cyan font-weight-bold pt-2 pb-2 pl-3 pr-3"
  //       style={{ borderRadius: '2px' }}
  //     >
  //       <Icon icon={ICONS.CHECK} width={13} height={9} className="mr-2" />
  //       {isMobile() ? 'VERIFIED' : 'VERIFIED FACT'}
  //     </div>
  //   );
  // }

  const containerClassName = classnames('four-pack', className);
  return (
    <div className={containerClassName}>
      {/* {renderEvidenceButton()} */}
      {renderQTPButton()}
      {renderReviewButton()}
      {renderCommentButton()}
      {renderSTPButton()}
      {renderShareButton()}
      {/* {renderTag()} */}
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    openSharePointModal: (props) => {
      dispatch(Actions.openModal('Share', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourPack);

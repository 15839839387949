import axios from 'axios';
import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_ADMIN_TOPICS = 'LOAD_ADMIN_TOPICS';
export const LOAD_TOPIC_KEYWORDS = 'LOAD_TOPIC_KEYWORDS';
export const UPDATE_TOPIC_KEYWORDS = 'UPDATE_TOPIC_KEYWORDS';

export function loadAdminTopics(topics) {
  return {
    type: LOAD_ADMIN_TOPICS,
    topics,
  };
}

export function loadTopicKeywords(topicKeywords) {
  return {
    type: LOAD_TOPIC_KEYWORDS,
    topicKeywords,
  };
}

export function modifyTopicKeywords(topicId, topic) {
  return {
    type: UPDATE_TOPIC_KEYWORDS,
    topicId,
    topic,
  };
}

export function getTopics() {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'get',
        url: '/api/topic/getTopics',
      });
      const { topics } = response.data;
      dispatch(loadAdminTopics(topics));
      return topics;
    } catch (error) {
      console.error('Error while getting topics', error);
      toast('Error while getting topics. Please try again later.', {
        type: 'error',
      });
    }
  };
}

export function getTopicKeywords() {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'get',
        url: '/api/topic/getTopicKeywords',
      });
      const { topicKeywords } = response.data;
      dispatch(loadTopicKeywords(topicKeywords));
      return topicKeywords;
    } catch (error) {
      console.error('Error while getting topics', error);
      toast('Error while getting topics. Please try again later.', {
        type: 'error',
      });
    }
  };
}

export function updateTopicKeywords(topicId, keywords) {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/topic/updateTopicKeywords',
        data: {
          topicId,
          keywords,
        },
      });
      dispatch(modifyTopicKeywords(topicId, response.data.topic));
      toast('Successfully updated topic keywords');
      return keywords;
    } catch (error) {
      console.error('Error while updating topic', error);
      toast('Error while updating topic. Please try again later.', {
        type: 'error',
      });
    }
  };
}

import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_WRITINGS = 'LOAD_WRITINGS';
export const ADD_WRITING = 'ADD_WRITING';
export const UPDATE_WRITING = 'UPDATE_WRITING';
export const REMOVE_WRITING = 'REMOVE_WRITING';
export const LOAD_WRITING_VERSIONS = 'LOAD_WRITING_VERSIONS';
export const UPDATE_WRITING_VERSION = 'UPDATE_WRITING_VERSION';
export const LOAD_WRITING_REVIEWS = 'LOAD_WRITING_REVIEWS';

const loadWritings = (writings) => {
  return { type: LOAD_WRITINGS, writings };
};

const addWriting = (writing) => {
  return { type: ADD_WRITING, writing };
};

const updateWritingFn = (writing) => {
  return { type: UPDATE_WRITING, writing };
};

const deleteWritingFn = (writingId) => {
  return {
    type: REMOVE_WRITING,
    writingId,
  };
};

const loadWritingVersions = (writingId, versions) => {
  return {
    type: LOAD_WRITING_VERSIONS,
    writingId,
    versions,
  };
};

const updateWritingVersion = (writingId, version) => {
  return {
    type: UPDATE_WRITING_VERSION,
    writingId,
    version,
  };
};

const loadWritingReviews = (writingId, reviews) => {
  return {
    type: LOAD_WRITING_REVIEWS,
    writingId,
    reviews,
  };
};

export function getWriting(promptId, writingId, studentId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWriting', {
          params: { promptId, writingId, studentId },
        })
        .then((response) => {
          const data = response.data;

          if (data.writing) {
            dispatch(addWriting(data.writing));
          }

          return resolve(data.writing);
        })
        .catch((error) => {
          console.log('Error while fetching writing', error);
          toast('Error occurred while fetching writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function createWriting(writing) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writings/createWriting', writing)
        .then((response) => {
          const data = response.data;
          dispatch(addWriting(data.writing));
          return resolve(data.writing);
        })
        .catch((error) => {
          console.log('Error while create writing', error);
          toast('Error occurred while creating writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function updateWriting(writing) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writings/updateWriting', writing)
        .then((response) => {
          const data = response.data;
          // data.writing.promptId = { _id: data.writing.promptId };
          dispatch(updateWritingFn(data.writing));
          return resolve(data.writing);
        })
        .catch((error) => {
          console.log('Error while update writing', error);
          toast('Error occurred while updating writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function updateStatusOfWriting(writing, comment, grade) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writings/updateStatusOfWriting', {
          writingId: writing._id,
          status: writing.status,
          comment,
          grade,
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateWritingFn(data.writing));
          return resolve(data.writing);
        })
        .catch((error) => {
          console.log('Error while update writing', error);
          toast('Error occurred while updating writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getWritingsForStudents(
  courseId,
  topicId,
  studentId,
  getCurrentVersionTime,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingsForStudent', {
          params: { courseId, topicId, studentId, getCurrentVersionTime },
        })
        .then((response) => {
          const data = response.data;

          dispatch(loadWritings(data.writings));

          return resolve(data.writings);
        })
        .catch((error) => {
          console.log('Error while fetching writing', error);
          toast('Error occurred while fetching writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getWritingDrafts(writingId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingDrafts', {
          params: { writingId },
        })
        .then((response) => {
          const data = response.data;

          return resolve(data.writingDrafts);
        })
        .catch((error) => {
          console.log('Error while fetching writing drafts', error);
          toast(
            'Error occurred while fetching writing drafts. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function overwriteWritingByAdmin(writingId, title, body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writings/overwriteWritingByAdmin', {
          writingId,
          title,
          body,
        })
        .then((response) => {
          return resolve();
        })
        .catch((error) => {
          console.log('Error while updating writing', error);
          toast('Error occurred while updating writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function deleteWriting(courseId, writingId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/writings/deleteWriting', {
          params: { courseId, writingId },
        })
        .then(() => {
          dispatch(deleteWritingFn(writingId));
          toast('Writing has been deleted successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while deleting Writing', error);
          toast('Error occurred while deleting writing. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getWritingsForWritingPrompt(courseId, topicId, promptId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingsForWritingPrompt', {
          params: { courseId, topicId, promptId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadWritings(data.writings));
          return resolve(data.writings);
        })
        .catch((error) => {
          console.log('Error while fetching writing', error);
          toast('Error occurred while fetching writing. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getWritingVersions(writingId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingVersions', {
          params: { writingId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadWritingVersions(writingId, data.versions));
          return resolve(data.versions);
        })
        .catch((error) => {
          console.log('Error while fetching writing versions', error);
          toast(
            'Error occurred while fetching writing versions. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getWritingReviews(writingId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingReviews', {
          params: { writingId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadWritingReviews(writingId, data.reviews));
          return resolve(data.versions);
        })
        .catch((error) => {
          console.log('Error while fetching writing reviews', error);
          toast(
            'Error occurred while fetching writing reviews. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getWritingVersion(writingId, versionId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writings/getWritingVersion', {
          params: { versionId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateWritingVersion(writingId, data.version));
          return resolve(data.version);
        })
        .catch((error) => {
          console.log('Error while fetching writing version', error);
          toast(
            'Error occurred while fetching writing version. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

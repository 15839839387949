export function hasAppLinked(app, user) {
  if (!user.user) {
    return false;
  }

  switch (app) {
    case 'google':
      return !!(user.user.google && user.user.google.refreshToken);
    case 'canvas':
      return !!(user.user.canvas && user.user.canvas.accessToken);
    case 'schoology':
      return !!(user.user.schoology && user.user.schoology.accessToken && user.user.schoology.accessTokenSecret);
    default:
      return false;
  }
};
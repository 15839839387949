import React, { useEffect, useRef, useReducer, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import Survey from 'components/elements/molecules/Survey/Survey';
import ReviewQuestions from 'helpers/RTP.json';
import QTPRTPGroupsSelection from '../QTPRTPGroupsSelection/QTPRTPGroupsSelection';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './ReviewPointForm.scss';

function ReviewPointForm({
  actions,
  point,
  close,
  user,
  className,
  points,
  selectedCaptions,
  rtp,
  collectionPoint,
  onAction,
  isMock = false,
  captions,
  isExtensionCL,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'REVIEW_POINT_SUBMIT':
          return {
            ...state,
            submitting: true,
          };
        case 'REVIEW_POINT_SUBMIT_SUCCESS':
          return {
            ...state,
            submitting: false,
          };

        case 'SET_SURVEY_INDEX':
          return {
            ...state,
            surveyIndex: action.payload,
          };
        case 'SET_SURVEY_MODEL':
          return {
            ...state,
            surveyModel: action.payload,
          };
        case 'SHOW_GROUPS_SELECTION':
          return {
            ...state,
            showGroupsSelection: true,
          };
        case 'SET_RTP':
          return {
            ...state,
            rtp: action.payload,
          };
        case 'SURVEY_SELECT_POINTS_QUESTIONS_PROVIDE_EVIDENCE':
          return {
            ...state,
            showSurveySelectPointsQuestionsProvideEvidence: action.payload,
          };
        default:
          return state;
      }
    },
    {
      submitting: false,
      surveyModel: {},
      oldSurveyModel: rtp ? { ...rtp.surveyResponse } : undefined,
      showGroupsSelection: false,
      showSurveySelectPointsQuestionsProvideEvidence: false,
    },
  );

  const surveyRef = useRef(null);

  useEffect(() => {
    if (point && point._id) {
      actions.getPoint(point._id);
    }
  }, []);

  async function onReviewPoint() {
    actions.showHint(
      'info',
      rtp ? 'Updating your review' : 'Adding your review',
    );
    dispatch({ type: 'REVIEW_POINT_SUBMIT' });
    let mediaClaim = point;

    if (!mediaClaim._id) {
      // mediaClaim hasn't been created yet
      point.isMock = isMock;
      const claim = {
        mediaClaimPoint: point,
        articleMediaCollection: collectionPoint,
      };
      const res = await actions.createMediaClaim(claim);
      mediaClaim = res.mediaClaimPoint;
    }

    if (mediaClaim) {
      const newRTP = {
        pointId: mediaClaim._id,
        userId: user._id,
        surveyResponse: {
          ...state.surveyModel,
          captions: selectedCaptions,
        },
        isMock,
        articleMediaCollectionId: collectionPoint
          ? collectionPoint._id
          : undefined,
      };

      if (rtp) {
        newRTP._id = rtp._id;
      }

      const res = await actions.addRTP(newRTP);
      dispatch({ type: 'SET_RTP', payload: res.rtp });
      dispatch({ type: 'REVIEW_POINT_SUBMIT_SUCCESS' });

      if (res.rtp._id && collectionPoint && collectionPoint.groups.length) {
        dispatch({ type: 'SHOW_GROUPS_SELECTION' });
      } else {
        close(true, res.rtp);
      }

      if (rtp && rtp._id) {
        const interactionReview = {
          interactionId: rtp._id,
          interactionType: 'RTP',
          comment: '',
          status: 'Pending',
        };
        await actions.addInteractionReview(interactionReview);
        await actions.getInteractionReviews(rtp, 'RTP', point);
      }
    } else {
      console.log(
        "We tried everything we could do, but the claim point didn't get created.",
      );
      close(false);
    }

    actions.hideHint();
  }

  function renderClose() {
    return (
      <div className="pl-3 pr-3">
        <div className="av-blue d-flex align-items-center">
          <button
            className="btn btn-clear btn-close av-gray ml-auto p-0"
            onClick={() => close(false)}
          >
            <Icon icon={ICONS.CLOSE} className="mr-0" />
          </button>
        </div>
      </div>
    );
  }

  function renderForm() {
    return (
      <React.Fragment>
        <Survey
          questions={ReviewQuestions}
          onFinish={onReviewPoint}
          className="pl-3 pr-3 pb-3 mt-4"
          onChangeIndex={(index) =>
            dispatch({ type: 'SET_SURVEY_INDEX', payload: index })
          }
          onChangeModel={(model) => {
            dispatch({ type: 'SET_SURVEY_MODEL', payload: model });
          }}
          point={point}
          points={points}
          oldModel={state.oldSurveyModel}
          ref={surveyRef}
          onAction={(type, data) => {
            onAction(type, data);

            if (type === 'SURVEY_SELECT_POINTS_QUESTIONS_PROVIDE_EVIDENCE') {
              dispatch({
                type: 'SURVEY_SELECT_POINTS_QUESTIONS_PROVIDE_EVIDENCE',
                payload: data,
              });
            }
          }}
          startIndex={
            collectionPoint ? (captions && captions.length === 1 ? 1 : 0) : 1
          }
          isMock={isMock}
          collectionPoint={collectionPoint}
          isExtensionCL={isExtensionCL}
        />
      </React.Fragment>
    );
  }

  function renderContinueButton() {
    const disabled =
      !(surveyRef.current && surveyRef.current.allowContinue()) ||
      state.submitting;
    const buttonText =
      surveyRef.current && surveyRef.current.getActiveQuestion().finalQuestion
        ? rtp
          ? 'Update'
          : 'Submit'
        : 'Continue';

    return (
      <RectangularButton
        className="ml-auto mr-3"
        onClick={() => {
          surveyRef.current && surveyRef.current.continue();
        }}
        disabled={disabled}
      >
        {buttonText}
        <Icon icon={ICONS.ARROW_FORWARD} className="ml-2" />
      </RectangularButton>
    );
  }

  function renderBackButton() {
    if (!(surveyRef.current && surveyRef.current.allowPrevious())) {
      return null;
    }

    return (
      <button
        className="btn bg-transparent av-blue d-inline-flex align-items-center font-weight-600"
        type="button"
        onClick={() => surveyRef.current && surveyRef.current.previous()}
      >
        <Icon icon={ICONS.ARROW_BACKWARD} className="mr-2" /> Back
      </button>
    );
  }

  function renderFooter() {
    if (state.showSurveySelectPointsQuestionsProvideEvidence) {
      return null;
    }

    return (
      <div className="d-flex pt-3 pb-3 align-items-center review-point-form-footer">
        {renderBackButton()}
        {renderContinueButton()}
      </div>
    );
  }

  function renderReviewForm() {
    return (
      <Fragment>
        {renderForm()}
        {renderFooter()}
      </Fragment>
    );
  }

  function renderReviewGroups() {
    return (
      <QTPRTPGroupsSelection
        type="RTP"
        close={() => {
          close(true, state.rtp);
        }}
        point={point}
        collectionPoint={collectionPoint}
      />
    );
  }

  const containerClassName = classnames(className, 'review-point-form');

  return (
    <div className={containerClassName}>
      {renderClose()}
      {state.showGroupsSelection ? renderReviewGroups() : renderReviewForm()}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
    points: state.points,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPointForm);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './DeleteCommunityPickedArticle.scss';

function DeleteCommunityPickedArticle({
  pointId,
  removeCommunityPickArticle,
  onClose,
}) {
  const [error, setError] = useState('');

  const deleteArticle = () => {
    axios
      .get('/api/mediaChannel/deleteArticle', {
        params: {
          pointId,
        },
      })
      .then(() => {
        removeCommunityPickArticle(pointId);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  return (
    <Modal show={true} className="clubhouse-signup-modal">
      <Modal.Body>
        <div className="header">
          <span>Delete This Article</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="text-center">
          <RectangularButton
            className="next-button mt-3"
            onClick={() => deleteArticle()}
          >
            Delete
          </RectangularButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    removeCommunityPickArticle: (pointId) => {
      dispatch(Actions.removeCommunityPickArticle(pointId));
    },
  };
}

export default connect(null, mapDispatchToProps)(DeleteCommunityPickedArticle);

import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions';
import { toast } from 'components/elements/molecules/Toast/Toast';

import './LinkToSchoology.scss';
import { ICONS, Icon } from 'components/Icons';

function LinkToSchoology({ text, actions }) {
  const [loading, setLoading] = useState(false);

  const connectToSchoology = async () => {
    setLoading(true);

    try {
      const response = await actions.initializeLogin();

      const verificationUrl = response.verificationUrl;

      window.location.href = verificationUrl;
    } catch (error) {
      console.error('Error while initializing login', error);

      toast(
        'Error while initializing login with Schoology. Please try again later.',
        {
          type: 'error',
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return <div className="link-to-schoology">
    {
      !loading ? (
        <div role="presentation" className="login-with-schoology-btn mt-4" onClick={() => connectToSchoology()}>
          <Icon icon={ICONS.SCHOOLOGY_LOGO} width={24} height={24} />
          {text}
        </div>
      ) : (
        <div className="text-center mt-4">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '40px' }}
          />
        </div>
      )
    }
  </div>;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(LinkToSchoology);

import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_WRITING_PROMPTS = 'LOAD_WRITING_PROMPTS';
export const ADD_WRITING_PROMPT = 'ADD_WRITING_PROMPT';
export const UPDATE_WRITING_PROMPT = 'UPDATE_WRITING_PROMPT';
export const REMOVE_WRITING_PROMPT = 'REMOVE_WRITING_PROMPT';
export const RESET_WRITING_PROMPTS = 'RESET_WRITING_PROMPTS';

const loadWritingPrompts = (writingPrompts) => {
  return {
    type: LOAD_WRITING_PROMPTS,
    writingPrompts,
  };
};

const addWritingPrompt = (writingPrompt) => {
  return {
    type: ADD_WRITING_PROMPT,
    writingPrompt,
  };
};

const updateWritingPromptFn = (promptId, writingPrompt) => {
  return {
    type: UPDATE_WRITING_PROMPT,
    promptId,
    writingPrompt,
  };
};

const deleteWritingPromptFn = (promptId) => {
  return {
    type: REMOVE_WRITING_PROMPT,
    promptId,
  };
};

export function resetWritingPrompts() {
  return {
    type: RESET_WRITING_PROMPTS,
  };
}

export function getWritingPrompt(promptId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writingPrompts/getWritingPrompt', {
          params: { promptId },
        })
        .then((response) => {
          const data = response.data;
          if (data.writingPrompt) {
            dispatch(loadWritingPrompts([data.writingPrompt]));
          } else {
            toast(
              'Error occurred while fetching writing prompt. It is not assigned to you or the deadline has passed.',
              {
                type: 'error',
              },
            );
          }
          return resolve(data.writingPrompt);
        })
        .catch((error) => {
          console.log('Error while fetching writing prompt', error);
          toast(
            'Error occurred while fetching writing prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getWritingPrompts(courseId, topicId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/writingPrompts/getWritingPrompts', {
          params: { courseId, topicId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadWritingPrompts(data.writingPrompts));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching writing prompts', error);
          toast(
            'Error occurred while fetching writing prompts. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function createWritingPrompt(writingPrompt) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writingPrompts/createWritingPrompt', {
          ...writingPrompt,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addWritingPrompt(data.writingPrompt));
          toast('Writing Prompt has been created successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding Writing Prompt', error);
          toast(
            'Error occurred while adding writing prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function updateWritingPrompt(writingPrompt) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writingPrompts/updateWritingPrompt', {
          ...writingPrompt,
        })
        .then((response) => {
          const data = response.data;
          dispatch(
            updateWritingPromptFn(writingPrompt.promptId, data.writingPrompt),
          );
          toast('Writing Prompt has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating Writing Prompt', error);
          toast(
            'Error occurred while updating writing prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function deleteWritingPrompt(
  courseId,
  topicId,
  promptId,
  forceDelete = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/writingPrompts/deleteWritingPrompt', {
          params: { courseId, topicId, promptId, forceDelete },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(deleteWritingPromptFn(promptId));
            toast('Writing Prompt has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting Writing Prompt', error);
          toast(
            'Error occurred while deleting writing prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function updatePointsInWritingPrompt(promptId, points) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/writingPrompts/updatePointsInWritingPrompt', {
          promptId,
          points,
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateWritingPromptFn(promptId, data.writingPrompt));
          toast('Writing Prompt has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating Writing Prompt', error);
          toast(
            'Error occurred while updating writing prompt. Please try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

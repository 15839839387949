import React from 'react';

import './radio.scss';
import PropTypes from 'prop-types';

const RadioGroup = (props) => {
  let disabled = false;
  if (props.disabled) disabled = true;
  let radioGroupClassName = disabled ? 'radioGroup-disabled' : 'radioGroup';

  return (
    <div className={radioGroupClassName} onClick={handleClick}>
      {props.label && <p>{props.label}</p>}
      <div
        className={`radioGroupOptions ${props.className}`}
        onChange={props.handleChange}
      >
        {props.children}
        {/*
              props.options.map( (option) => {
                return(
                  <RadioOption
                    id={option.id}
                    name={props.name}
                    value={option.value}
                    label={option.label}
                  />
                )
              })
            */}
      </div>
      <div className="error-message">{props.errorMessage}</div>
    </div>
  );
};

const handleClick = (e) => {
  e.stopPropagation();
};

export default RadioGroup;

RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';

require('./css/ErrorModal.scss');

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    //setTimeout(function(){props.onClose(false)},3000);
  }

  onClose = () => {
    this.props.onClose(false);
  }

  render() {
    // let title = this.props.model.title || "Error";
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    return (
      <Modal show={true} className="error-modal" container={container}>
        <Modal.Body className="modalBody">
          <img src="./img/attention.svg" className="errorImg" />
          <div className="errorHeader">
            {this.props.model.message
              ? this.props.model.message
              : 'Something went wrong!'}
          </div>
          <div className="description" />
          <div className="doneDiv">
            <RoundedButton
              mainClassName="doneButton cancelButton"
              label="Got it"
              onClick={this.onClose}
            />
            {/* <RoundedButton
                          mainClassName="doneButton tryagainButton"
                          label='Try Again'
                          onClick={this.onClose}
                        /> */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ErrorModal;

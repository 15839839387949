import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import { Modal } from 'react-bootstrap';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';

import { toast } from 'components/elements/molecules/Toast/Toast';

require('./css/publishModal.scss');

class PublishModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionPublishOption: 'CollectionAndPoints',
    };
  }

  onSave = () => {
    const {
      collectionPoint,
      onCollectionPublished,
      isAuthor,
    } = this.props.model;

    if (!isAuthor) {
      console.log(
        'User is not collectionPoint creator and trying to publish collectionPoint.',
      );
      return;
    }

    if (collectionPoint.status === 'private') collectionPoint.status = 'public';
    else if (collectionPoint.status === 'public')
      collectionPoint.status = 'private';

    const options = {
      collectionPublishOption: this.state.collectionPublishOption,
    };
    this.props.actions.updateCollection(collectionPoint, options).then(() => {
      onCollectionPublished();
      toast('Your Collection is public!');
    });

    this.props.onClose(true);
  }

  onClose = () => {
    this.props.onClose(false);
  }

  handleChangePublishOption = e => {
    this.setState({
      collectionPublishOption: e.target.value,
    });
  };

  render() {
    const title = this.props.model.title || 'Publish';
    return (
      <Modal show className="publish-modal">
        <Modal.Body className="modalBody">
          {/* <img src="./img/question.svg" className="confirmImg" /> */}
          <div className="publishHeader">
            {this.props.model.message || 'Publish options:'}
          </div>
          <div className="description">
            <RadioGroup
              name="publishOption"
              label=""
              handleChange={this.handleChangePublishOption}
              errorMessage=""
            >
              <ToolTip
                toolTipText="Publish the Collection and all Points you've written."
                toolTipPosition="bottom"
              >
                <RadioOption
                  name="publishOption"
                  id="CollectionAndPoints"
                  value="CollectionAndPoints"
                  label="Entire Collection including the private Points (if you're an author)."
                  checked
                />
              </ToolTip>
              <ToolTip
                toolTipText="Publish just the Collection."
                toolTipPosition="bottom"
              >
                <RadioOption
                  name="publishOption"
                  id="CollectionOnly"
                  value="CollectionOnly"
                  label="Just the Collection. Keep the private Points hidden."
                />
              </ToolTip>
            </RadioGroup>
          </div>
          <div className="doneDiv">
            <RoundedButton
              mainClassName="doneButton cancelButton"
              label="Cancel"
              onClick={this.onClose}
            />
            <RoundedButton
              mainClassName="doneButton tryagainButton"
              label="Publish"
              onClick={this.onSave}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state.user;

  return {
    user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default (PublishModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishModal));

import * as React from "react";
import RespondInviteCollectionContainer from "./RespondInviteCollectionContainer";
import { Modal, Button } from "react-bootstrap";
import { Icon, ICONS } from "../../Icons";
import "./css/respondInviteCollection.scss";

class RespondInviteCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {}
    };
  }

  onSave() {
    if (this.validate()) {
      this.props.onAction(this.props.onSave, this.props.model).then(() => {
        this.props.onClose(true);
      });
    }

    this.setState({
      isSubmitted: true
    });
  }

  onRespondInvite = (response) => {
    this.props
      .onAction(
        this.props.onRespondInvite,
        this.props.model.collectionPoint,
        response
      )
      .then(() => {
        this.props.onClose(true);
      });
  }

  onClose = () => {
    this.props.onClose(true);
  }

  render() {
    return (
      <Modal show={true} className="general-modal respond-invite-collection">
        <Modal.Header>
          <Button bsStyle="link" className="btn-close" onClick={this.onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
          <div className="modal-title">
            <Icon icon={ICONS.INVITE} /> Invitation to Collection
          </div>
        </Modal.Header>
        <Modal.Body>
          <RespondInviteCollectionContainer point={this.props.model.point} />
        </Modal.Body>
        <Modal.Footer>
          <div className="footrBtn">
            <button
              onClick={() => this.onRespondInvite("rejected")}
              className="btn btn-outline btn-md btn-reject"
            >
              Decline
            </button>
            <button
              onClick={() => this.onRespondInvite("accepted")}
              className="btn btn-primary btn-outline btn-md btn-accept"
            >
              Accept
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RespondInviteCollectionModal;

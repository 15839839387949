import React, { useEffect } from 'react';
import { Icon, ICONS } from 'components/Icons';
import AsyncAutoComplete from 'components/elements/molecules/AsyncAutoComplete/AsyncAutoComplete';
import axios from 'axios';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { unapprovedSources } from 'helpers/unapprovedSources';

import QuestionText from './QuestionText';

import './SourcePickerChoice.scss';

const SourcePicker = connect(
  function(state, ownProps) {
    return {
      top50Sources: state.app.top50Sources,
    };
  },
  function(dispatch) {
    return {
      actions: bindActionCreators(Actions, dispatch),
    };
  },
)(({ onChange, value, scopes, label, isChecked, actions, top50Sources }) => {
  useEffect(() => {
    actions.getMediaChannel();
  }, []);

  function onSelect(source) {
    const isSelected = value.findIndex(v => v.source === source) > -1;

    if (isSelected) {
      return;
    }

    onChange([...value, { sourceName: source, paidSubscriber: false }]);
  }

  function renderSourceAutocomplete() {
    let sources = top50Sources.map(i => ({
      name: i.sourceName,
      logo: i.sourceLogoURL,
    }));

    return (
      <AsyncAutoComplete
        autoFocus={false}
        value=""
        onSelect={onSelect}
        scopes={scopes}
        label={label}
        isChecked={isChecked}
        quickOptions={[...sources, ...unapprovedSources]}
        onSearch={searchTerm => {
          return axios({
            method: 'get',
            url: `${
              process.env.REACT_APP_SOURCE_PROVIDER_LAMBDA
            }/default?searchterm=${searchTerm}&type=source`,
          });
        }}
      />
    );
  }

  return <div className="counter-group">{renderSourceAutocomplete()}</div>;
});

export default function SourcePickerChoice({ question, onChange, model }) {
  function getValue() {
    return model[question.variable] || [];
  }

  function renderText() {
    return <QuestionText question={question} />;
  }

  const paidSubscriber = (source, isChecked = false) => {
    let sources = JSON.parse(JSON.stringify(getValue()));

    sources = sources.map(s => ({
      ...s,
      paidSubscriber: s.sourceName === source ? isChecked : s.paidSubscriber,
    }));

    onChange(sources);
  };

  const remove = source => {
    let sources = JSON.parse(JSON.stringify(getValue()));

    sources = sources.filter(s => s.sourceName !== source);

    onChange(sources);
  };

  function isChecked(source) {
    return getValue().findIndex(s => s.sourceName === source) !== -1;
  }

  const value = getValue();

  function showHeader() {
    if (!Array.isArray(value) || value.length === 0) {
      return;
    }

    return (
      <div className="container header">
        <div className="row">
          <div className="col-xs-7 source-title">Source</div>
          <div className="col-xs-4 paid-subscriber">I’m a paid subscriber</div>
          <div className="col-xs-1 remove" />
        </div>
      </div>
    );
  }

  function showSources() {
    if (!Array.isArray(value) || value.length === 0) {
      return;
    }

    return value.map(v => (
      <div key={v.sourceName} className="container sources">
        <div className="row">
          <div className="col-xs-7 source-title">
            <div className="user-selected-source">{v.sourceName}</div>
          </div>
          <div className="col-xs-4 paid-subscriber">
            <input
              type="checkbox"
              onChange={() => paidSubscriber(v.sourceName, !v.paidSubscriber)}
              checked={v.paidSubscriber}
            />
          </div>
          <div className="col-xs-1 remove">
            <Icon
              className="icon"
              icon={ICONS.CLOSE}
              size={10}
              onClick={() => remove(v.sourceName)}
            />
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="source-picker">
      {renderText()}
      <SourcePicker
        onChange={onChange}
        value={value}
        label="Start typing and choose from approved list"
        isChecked={isChecked}
      />
      {showHeader()}
      {showSources()}
    </div>
  );
}

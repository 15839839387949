/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MenuItem, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "redux/actions";

import { Icon, ICONS } from "components/Icons";
import RoundedButton from "components/elements/molecules/RoundedButton/RoundedButton";
import Checkbox from "components/elements/atoms/Checkbox/Checkbox";
import ToolTip from "components/elements/atoms/ToolTip/ToolTip.js";

import "./CollectionHeaderControls.scss";

class CollectionHeader extends React.Component {
  static propTypes = {
    cardType: PropTypes.oneOf([
      "default",
      "preview",
      "preview highlight-on-hover",
      "embed",
      "page",
    ]),
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    collectionPoint: PropTypes.object.isRequired,
    //   citationStyle: PropTypes.string,
    //   editable: PropTypes.bool,
    onCollectionFieldChanged: PropTypes.func,
    allowRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    allowFollow: PropTypes.bool,
    onFollow: PropTypes.func,
    onUnfollow: PropTypes.func,
    onToggleEdit: PropTypes.func,
    //   showTotalSubPoints: PropTypes.bool,
    //   showReviews: PropTypes.bool,
    //   allowDownload: PropTypes.bool,
    isDragging: PropTypes.bool,
    isOver: PropTypes.bool,
    confirmDrop: PropTypes.bool,
    openAddToGroupModal: PropTypes.func,
    onRemoveFromGroup: PropTypes.func,
    isAuthor: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      collectionPublishOption: "CollectionOnly",
    };
  }

  onInviteCollection = () => {
    const { collectionPointObject } = this.props;
    this.props.openInviteCollectionModal(collectionPointObject.point);
  };

  onShareCollection = () => {
    const { collectionPoint } = this.props;
    collectionPoint.shareUrl = `${window.location.origin}/Collection/${collectionPoint._id}`;
    collectionPoint.iframeUrl = `${window.location.origin}/CollectionCard/${collectionPoint._id}`;
    this.props.openShareCollectionModal(collectionPoint, "COLLECTION");
  };

  async onUpdatePublishOption(collectionPublishOption) {
    const options = {
      collectionPublishOption,
    };
    this.props.collectionPoint.status = "public";
    await this.props.actions.updateCollection(
      this.props.collectionPoint,
      options
    );
  }

  renderFollowButton() {
    if (this.props.cardType !== "page") {
      return null;
    }

    if (this.props.isAuthor) return null; // Author can't follow Point

    const { collectionPoint, user, authenticated, onFollow, onUnfollow } =
      this.props;

    if (collectionPoint.authorId === user._id) return null; // Author can't follow their own Point

    if (!authenticated) return null;

    if (collectionPoint.following) {
      return (
        <RoundedButton
          mainClassName="ml-3 d-none d-md-inline-block hover-action"
          className="font-size-sm-12"
          label="Following"
          onClick={() => onUnfollow(collectionPoint._id)}
          title="Unfollow"
          hoverLabel="Unfollow"
        />
      );
    }

    return (
      <RoundedButton
        mainClassName="ml-3 d-none d-md-inline-block"
        className="font-size-sm-12"
        label="Follow"
        onClick={() => onFollow(collectionPoint._id)}
        title="Follow"
      />
    );
  }

  renderPointIcon() {
    if (this.props.isEditing || this.props.isAuthor) return null;

    let { onUnfollow, collectionPoint } = this.props;

    let style = {};
    let className = "font-size-sm-12";

    if (this.props.cardType === "default") {
      // style.borderRadius = '8px';
      className = "p-2";
    }

    if (this.props.authenticated) {
      if (this.props.collectionInPortfolio) {
        return (
          <RoundedButton
            mainClassName=""
            className={className}
            label="Saved"
            disabled={true}
            onClick={() => onUnfollow(collectionPoint._id)}
            title="This Collection is saved to your Portfolio."
            style={style}
          />
        );
      }
    }

    const toolTipText = this.props.authenticated
      ? "Save this Point to your Collections"
      : "Login to Save this Point to your Collections";

    if (!this.props.authenticated) className += " disabled";

    return (
      <RoundedButton
        mainClassName=""
        className={className}
        label="Save"
        onClick={this.onPoint}
        title={toolTipText}
        style={style}
      />
    );
  }

  renderSaveButton() {
    return (
      <div className="save-button-container ml-3 hover-action">
        {this.renderPointIcon()}
      </div>
    );
  }

  renderLockIcon() {
    if (this.props.collectionPoint.status !== "private") return null;

    // return (
    //   <ActionLink
    //     onClick={null}
    //     linkClassName="ml-3 av-grayish-cyan"
    //     iconType="averPointIcon"
    //     iconName="LOCK"
    //     iconClassName="mr-0"
    //     label=""
    //     disabled={true}
    //     disabledToolTipText="This Collection is private. Publish it to make it public."
    //   />
    // );

    return (
      <Dropdown
        id="collection-header-dropdown"
        pullRight
        className="ml-3 more-menu"
        onClick={e => e.stopPropagation()}
      >
        <Dropdown.Toggle noCaret>
          <ToolTip
            toolTipText="This Collection is private. Click to see publish options."
            toolTipPosition="left"
          >
            <Icon icon={ICONS.LOCK} className="m-0" />
          </ToolTip>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem
            onClick={() => this.onUpdatePublishOption("CollectionAndPoints")}
          >
            <div className="pt-2 pb-2">
              <h5 className="av-black mb-2 mt-0">
                Publish Collection and Points
              </h5>
              <div
                className="gray-4 body-medium"
                style={{ maxWidth: "230px", whiteSpace: "break-spaces" }}
              >
                Make this Collection public, including the private Points (if
                you're the author)
              </div>
            </div>
          </MenuItem>
          <MenuItem divider className="m-0" />
          <MenuItem
            onClick={() => this.onUpdatePublishOption("CollectionOnly")}
          >
            <div className="pt-2 pb-2">
              <h5 className="av-black mb-2 mt-0">
                Publish just the Collection
              </h5>
              <div
                className="gray-4 body-medium"
                style={{ maxWidth: "230px", whiteSpace: "break-spaces" }}
              >
                Publish this Collection but keep the private Points hidden.
              </div>
            </div>
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderEditOrSaveMenuItem() {
    const { collectionPoint, user, isAuthor } = this.props;

    // Only author can edit or save
    if (!isAuthor) return null;

    const isRealAuthor = collectionPoint.authors.find(
      a => a.username === user.username
    );
    if (!isRealAuthor) return null;

    const label = this.props.isEditing ? "Finish editing" : "Edit";

    return (
      <MenuItem onClick={this.onEdit}>
        <Icon icon={ICONS.PENCIL} className="mr-3" />
        {label}
      </MenuItem>
    );
  }

  renderPublishMenuItem() {
    const { collectionPoint, collectionPointObject, user, isAuthor } =
      this.props;

    // Only publish a private Point
    if (!isAuthor || collectionPoint.status != "private") {
      return null;
    }

    const isRealAuthor = collectionPoint.authors.find(
      a => a.username === user.username
    );
    if (!isRealAuthor) return null;

    const publishOptions = (
      <div
        className="notification-settings right position-absolute"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className="p-3 item hover"
          onClick={() => {
            this.onUpdatePublishOption("CollectionAndPoints");
          }}
        >
          <h5 className="av-black mb-2 mt-0">Publish Collection and Points</h5>
          <div
            className="gray-4 body-medium"
            style={{ maxWidth: "230px", whiteSpace: "break-spaces" }}
          >
            Make this Collection public, including the private Points (if you're
            the author)
          </div>
        </div>
        <div
          className="p-3 item hover"
          onClick={() => {
            this.onUpdatePublishOption("CollectionOnly");
          }}
        >
          <h5 className="av-black mb-2 mt-0">Publish just the Collection</h5>
          <div
            className="gray-4 body-medium"
            style={{ maxWidth: "230px", whiteSpace: "break-spaces" }}
          >
            Publish this Collection but keep the private Points hidden.
          </div>
        </div>
      </div>
    );

    return (
      <MenuItem
        className="notification-settings-menu-item position-relative"
        onClick={() =>
          this.props.openPublishCollectionModal(
            collectionPoint,
            this.onCollectionPublished,
            isAuthor
          )
        }
      >
        <Icon icon={ICONS.GLOBE} className="mr-3" />
        Publish
        {publishOptions}
      </MenuItem>
    );
  }

  onCollectionPublished = () => {
    if (!this.props.isEditing) return;
    this.props.onToggleEdit();
  };

  renderInviteMenuItem() {
    const { cardType, collectionPoint, user, isEditing, isAuthor } = this.props;

    if (cardType !== "page" || !isAuthor || isEditing) {
      return null;
    }

    return (
      <MenuItem onClick={this.onInviteCollection}>
        <Icon icon={ICONS.INVITE_USER} className="mr-3" />
        Invite
      </MenuItem>
    );
  }

  // renderRespondToInviteMenuItem() {
  //   const { collectionPoint, user } = this.props;

  //   const { invitationStatus } = this.props.collectionPointObject;

  //   if (invitationStatus !== 'invited') return null;
  //   if (collectionPoint.authorId == user._id) return null; // Author can't respond to an invite to their own Collection

  //   return (
  //     <div className="visible-links d-flex justify-content-end mt-3">
  //       <ActionLink
  //         onClick={() => this.onRespondInvite(collectionPoint._id)}
  //         linkClassName="pt-2 pb-2 pl-3 pr-3"
  //         iconType="averPointIcon"
  //         iconName="RESPOND"
  //         label="Respond to Invite"
  //       />
  //     </div>
  //   );
  // }

  onRespondInvite = () => {
    const { collectionPointObject } = this.props;
    if (collectionPointObject.invitationStatus == "invited") {
      this.props.openAcceptInviteToCollectionModal(collectionPointObject.point);
    }
  };

  renderRemoveMenuItem() {
    if (
      !this.props.authenticated ||
      !this.props.allowRemove ||
      !this.props.onRemove
    )
      return null;

    return (
      <MenuItem onClick={this.props.onRemove}>
        <Icon icon={ICONS.DELETE} className="mr-3" />
        Remove
      </MenuItem>
    );
  }

  renderRemoveFromGroupMenuItem() {
    if (
      !this.props.authenticated ||
      (this.props.cardType !== "page" && this.props.cardType !== "default") ||
      !this.props.onRemoveFromGroup
    )
      return null;

    return (
      <MenuItem onClick={this.props.onRemoveFromGroup}>
        <Icon icon={ICONS.REMOVE_FROM_COLLECTION} className="mr-3" />
        Remove from group
      </MenuItem>
    );
  }

  renderShareMenuItem() {
    const { collectionPoint } = this.props;

    // Can't share unless the Collection is published
    // if (collectionPoint.status == 'private' || !this.props.authenticated) {
    //   return null;
    // }

    if (collectionPoint.status === "private") {
      return null;
    }

    return (
      <MenuItem onClick={this.onShareCollection}>
        <Icon icon={ICONS.SHARE} className="mr-3" />
        Share
      </MenuItem>
    );
  }

  renderFollowMenuItem() {
    const {
      collectionPoint,
      user,
      collectionPointObject,
      authenticated,
      onFollow,
      onUnfollow,
      cardType,
    } = this.props;

    // Author can't follow their own Point
    if (
      collectionPoint.authorId === user._id ||
      !authenticated ||
      cardType !== "page"
    ) {
      return null;
    }

    if (collectionPoint.following) {
      return (
        <MenuItem onClick={() => onUnfollow(collectionPoint._id)}>
          <Icon icon={ICONS.FOLLOW} className="mr-3" />
          Unfollow
        </MenuItem>
      );
    }
    return (
      <MenuItem onClick={() => onFollow(collectionPoint._id)}>
        <Icon icon={ICONS.FOLLOW} className="mr-3" />
        Follow
      </MenuItem>
    );
  }

  renderAddToGroupMenuItem() {
    const { collectionPoint, cardType, isAuthor } = this.props;

    if (!isAuthor) return null;

    if (cardType === "page" && collectionPoint.groups.length === 0) {
      return (
        <MenuItem
          onClick={() => this.props.openAddToGroupModal(collectionPoint)}
        >
          <Icon icon={ICONS.GROUP} className="mr-3" />
          Add to group
        </MenuItem>
      );
    }
  }

  renderMore() {
    return (
      <div className="ml-2">
        <Dropdown
          id="collection-header-dropdown"
          pullRight
          className="more-menu"
          onClick={e => e.stopPropagation()}
        >
          <Dropdown.Toggle noCaret>
            <Icon icon={ICONS.MORE} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.renderEditOrSaveMenuItem()}
            {this.renderPublishMenuItem()}
            {this.renderInviteMenuItem()}
            {/* {this.renderRespondToInviteMenuItem()} */}
            {this.renderRemoveMenuItem()}
            {this.renderRemoveFromGroupMenuItem()}
            {this.renderShareMenuItem()}
            {this.renderFollowMenuItem()}
            {this.renderAddToGroupMenuItem()}
            {this.renderAddorEditCallToActionMenuItem()}
            {/* {this.renderAddOrEditMediaMenuItem()} */}
            {this.renderNotificationSettingsMenuItem()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  render() {
    let className = classnames(
      "collection-header-controls",
      `card-type-${this.props.cardType}`
    );

    if (this.props.isEditing && this.props.cardType !== "page") return null;

    return (
      <div className={className}>
        <div className="d-flex align-items-center">
          {this.renderFollowButton()}
          {this.renderSaveButton()}
          {this.renderLockIcon()}
          {this.renderMore()}
        </div>
        {/* {this.renderRespondToInviteMenuItem()} */}
      </div>
    );
  }

  onPoint = e => {
    e.stopPropagation();

    if (!this.props.authenticated) return;

    this.props.actions.addCollectionToPortfolio(this.props.collectionPoint._id);
  };

  onEdit = () => {
    if (this.props.collectionPoint.status === "public") {
      this.toggleCollectionVisibility();
    }
    this.props.onToggleEdit();
  };

  toggleCollectionVisibility = () => {
    const { collectionPoint, isAuthor } = this.props;

    if (!isAuthor) {
      console.log(
        "User is not collectionPoint creator and trying to publish collectionPoint."
      );
      return;
    }

    if (collectionPoint.status === "private") collectionPoint.status = "public";
    else if (collectionPoint.status === "public")
      collectionPoint.status = "private";

    const options = {
      collectionPublishOption: this.state.collectionPublishOption,
    };
    this.props.actions.updateCollection(collectionPoint, options);
  };

  // renderHeader = () => (
  //   <CollectionDropDownHeader
  //     collectionPoint={this.props.collectionPoint}
  //     user={this.props.user}
  //   />
  // );

  renderAddorEditCallToActionMenuItem() {
    const { collectionPoint, user, isAuthor } = this.props;

    // Only author can edit or save
    if (!isAuthor) {
      return null;
    }

    let isEditing =
      collectionPoint.callToAction.text && collectionPoint.callToAction.url;

    return (
      <MenuItem
        onClick={() => {
          this.onAddorEditCallToAction();
        }}
      >
        <Icon icon={ICONS.CTA} className="mr-3" />
        {isEditing ? "Edit Call to Action" : "Add Call to Action"}
      </MenuItem>
    );
  }

  onAddorEditCallToAction() {
    let collectionPoint = this.props.collectionPoint;
    this.props.openCallToActionModal(collectionPoint);
  }

  renderAddOrEditMediaMenuItem() {
    const { collectionPoint, user } = this.props;

    // Only author can edit or save
    if (collectionPoint.authorId != user._id) {
      return null;
    }

    let isEditing =
      collectionPoint.sourceURL && collectionPoint.collectionType === "media";

    return (
      <MenuItem
        onClick={() => {
          this.onAddorEditMedia();
        }}
      >
        <Icon icon={ICONS.MEDIA} className="mr-3" />
        {isEditing ? "Edit Media" : "Add Media"}
      </MenuItem>
    );
  }

  onChangeNotificationPref() {
    const { collectionPoint } = this.props;
    let notificationPreferences = collectionPoint.notificationPreferences;
    this.props.actions.setPointNotificationPreferences(
      collectionPoint._id,
      notificationPreferences
    );
  }

  renderNotificationSetting(text, pref) {
    return (
      <div className="p-3 item">
        <p className="font-weight-600">{text}</p>
        <div className="d-flex align-items-center">
          <Checkbox
            onClick={e => {
              pref.email = pref.email === 2 ? 0 : 2;
              this.onChangeNotificationPref();
            }}
            checked={pref.email === 2}
            disabled={false}
          >
            Email
          </Checkbox>
          <Checkbox
            className="ml-4"
            onClick={() => {
              pref.push = pref.push == 0 ? 1 : 0;
              this.onChangeNotificationPref();
            }}
            checked={pref.push === 1}
          >
            Push Notification
          </Checkbox>
        </div>
      </div>
    );
  }

  renderNotificationSettingsMenuItem() {
    const { collectionPoint, user, isAuthor } = this.props;

    if (!isAuthor) {
      return null;
    }

    // double check actual author - not through group
    const isRealAuthor = collectionPoint.authors.find(
      a => a.username === user.username
    );
    if (!isRealAuthor) return null;

    let notificationPreferences = collectionPoint.notificationPreferences;

    let notificationSettings;

    if (notificationPreferences) {
      notificationSettings = (
        <div
          className="notification-settings right position-absolute"
          style={{ top: "-20px" }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {this.renderNotificationSetting(
            "Points added to this Collection",
            notificationPreferences.subPointAdded
          )}
        </div>
      );
    } else {
      notificationSettings = (
        <div
          className="notification-settings right position-absolute"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="p-3 font-weight-600">Loading Preferences...</div>
        </div>
      );
    }

    return (
      <MenuItem
        className="notification-settings-menu-item position-relative"
        onMouseEnter={() => {
          if (!collectionPoint.notificationPreferences) {
            this.props.actions.getPointNotificationPreferences(
              collectionPoint._id
            );
          }
        }}
      >
        <Icon icon={ICONS.NOTIFICATIONS} className="mr-3" />
        Notification Settings
        {notificationSettings}
      </MenuItem>
    );
  }

  onAddorEditMedia() {
    let collectionPoint = this.props.collectionPoint;
    this.props.openMediaModal(collectionPoint);
  }
}

function mapStateToProps(state, ownProps) {
  let collectionInPortfolio = false;

  if (state.user.authenticated) {
    const portfolioObject = state.portfolios[state.user.user.username];

    if (
      portfolioObject &&
      portfolioObject.portfolio &&
      portfolioObject.portfolio.collectionPoints.indexOf(
        ownProps.collectionPoint._id
      ) != -1
    ) {
      collectionInPortfolio = true;
    }
  }

  //  TBD: Temporary Fix. Remove once we have services
  const collectionPointObject =
    state.points[ownProps.collectionPoint._id] ||
    state.points[Object.keys(state.points)[2]];
  const collectionPoint = collectionPointObject.point;

  // const isAuthor =
  //   collectionPointObject.permissionLevel === 'write' &&
  //   (collectionPointObject.invitationStatus === 'accepted' ||
  //     collectionPointObject.invitationStatus === 'group');
  const isAuthor = collectionPointObject.isAuthor;

  return {
    authenticated: state.user.authenticated,
    collectionInPortfolio,
    collectionPointObject,
    collectionPoint,
    isAuthor,
    isFollowing: collectionPoint.following,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openAddToCollectionModal: (collectionPointId, onResponse) => {
      const props = {
        model: { collectionPointId },
        onResponse,
      };
      dispatch(Actions.openModal("add-to-collection", props));
    },
    openPublishCollectionModal: (
      collectionPoint,
      onCollectionPublished,
      isAuthor
    ) => {
      const props = {
        model: { collectionPoint, onCollectionPublished, isAuthor },
      };
      dispatch(Actions.openModal("publish", props));
    },
    openInviteCollectionModal: point => {
      const props = {
        model: { point },
      };
      dispatch(Actions.openModal("invite-point", props));
    },
    openAcceptInviteToCollectionModal: collectionPoint => {
      const props = {
        model: { point: collectionPoint },
      };
      dispatch(Actions.openModal("respond-invite-point", props));
    },
    openShareCollectionModal: (model, type) => {
      const props = { model, type };
      dispatch(Actions.openModal("Share", props));
    },
    openCallToActionModal: point => {
      const props = {
        model: {
          point,
          type: "COLLECTION",
        },
      };
      dispatch(Actions.openModal("call-to-action", props));
    },
    openMediaModal: point => {
      const props = {
        model: {
          point,
        },
      };
      dispatch(Actions.openModal("media-collection", props));
    },
    openAddToGroupModal: collectionPoint => {
      const props = {
        collectionId: collectionPoint._id,
        collectionPoint,
      };
      dispatch(Actions.openModal("add-collection-to-group", props));
    },
    onFollow: id => {
      dispatch(Actions.followCollection(id));
    },
    onUnfollow: id => {
      dispatch(Actions.unFollowCollection(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionHeader);

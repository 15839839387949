import React from 'react';

import QuestionText from './QuestionText';

export default function InputTextAreaQuestion({ question, onChange, model }) {
  function renderText() {
    return <QuestionText question={question} />;
  }

  function renderInput() {
    const value = model[question.variable];

    return (
      <textarea
        type="text"
        className="form-control"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={question.placeholder}
      />
    );
  }
  return (
    <React.Fragment>
      {renderText()}
      {renderInput()}
    </React.Fragment>
  );
}

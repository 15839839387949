import SourceDefault from './source-default.svg';

const isGoogleProvider =
  process.env.REACT_APP_SOURCE_LOGO_PROVIDER === 'google' ? true : false;

export const logoProvider = isGoogleProvider
  ? 'https://s2.googleusercontent.com/s2/favicons?domain='
  : '//logo.clearbit.com/';

export function getLogo(option) {
  if (option.logo) {
    return option.logo;
  }

  if (!option.domain) {
    return SourceDefault;
  }

  if (option.domain === 'localhost:3000' || option.domain.includes('averpoint.com')) {
    return `${logoProvider}averpoint.com`;
  }

  return `${logoProvider}${option.domain}`;
}

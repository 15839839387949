import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  gotoNextStepAsync,
  completeTutorialAsync
} from "redux/actions/walkthrough";
import WalkthroughModalComponent from "./WalkthroughModalComponent";
import "./css/WalkthroughModal.scss";

class WalkthroughModal extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      step: 1
    };
  }

  gotoNextStep = step => {
    // this.setState({
    //   step,
    // });
    this.props.gotoNextStepAsync({
      user: this.props.user,
      step
    });
  };

  onComplete = () => {
    this.props.completeTutorialAsync({
      user: this.props.user
    });
  };

  render() {
    const step = this.props.walkthrough.tourStep;
    return (
      <WalkthroughModalComponent
        {...this.props}
        step={step}
        gotoNextStep={this.gotoNextStep}
        onComplete={this.onComplete}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    walkthrough: state.walkthrough
  };
}

function mapDispatchToProps(dispatch) {
  return {
    completeTutorialAsync: payload => dispatch(completeTutorialAsync(payload)),
    gotoNextStepAsync: payload => dispatch(gotoNextStepAsync(payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalkthroughModal);

import _ from 'lodash';
import * as Actions from 'redux/actions/index';

const initialState = {
  researchPrompts: [],
};

export default function classRooms(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_RESEARCH_PROMPTS: {
      const researchPrompts = action.researchPrompts;
      const researchPromptIds = researchPrompts.map((w) => w._id);

      const cloneResearchPrompts = _.cloneDeep(state.researchPrompts);

      return {
        ...state,
        researchPrompts: cloneResearchPrompts
          .filter((w) => !researchPromptIds.includes(w._id))
          .concat(researchPrompts),
      };
    }
    case Actions.ADD_RESEARCH_PROMPT: {
      const researchPrompt = action.researchPrompt;

      const cloneResearchPrompts = _.cloneDeep(state.researchPrompts);

      return {
        ...state,
        researchPrompts: cloneResearchPrompts.concat([researchPrompt]),
      };
    }
    case Actions.UPDATE_RESEARCH_PROMPT: {
      const promptId = action.promptId;
      const researchPrompt = action.researchPrompt;

      const cloneResearchPrompts = _.cloneDeep(state.researchPrompts);
      const researchPromptIndex = cloneResearchPrompts.findIndex(
        (wt) => wt._id === promptId,
      );
      cloneResearchPrompts.splice(researchPromptIndex, 1, researchPrompt);

      return {
        ...state,
        researchPrompts: cloneResearchPrompts,
      };
    }
    case Actions.REMOVE_RESEARCH_PROMPT: {
      const promptId = action.promptId;

      let cloneResearchPrompts = _.cloneDeep(state.researchPrompts);
      cloneResearchPrompts = cloneResearchPrompts.filter(
        (wt) => wt._id !== promptId,
      );

      return {
        ...state,
        researchPrompts: cloneResearchPrompts,
      };
    }
    case Actions.RESET_RESEARCH_PROMPTS: {
      return {
        researchPrompts: [],
      };
    }
    default:
      return state;
  }
}

import React, { useState } from 'react';
import classnames from 'classnames';

import { Icon, ICONS } from 'components/Icons';
import ProfileImage from 'components/elements/molecules/Images/profileImage';

import './AuthorTree.scss';

export function AuthorTree({
  authors,
  selectedAuthors,
  onClose,
  onSelect,
  allAuthorsText,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * @returns {boolean}
   */
  function isAllAuthorsSelected() {
    return authors.length === selectedAuthors.length;
  }

  function renderItem(author, authorId) {
    let selected = false;

    if (authorId === 'ALL') {
      selected = isAllAuthorsSelected();
    } else {
      selected = selectedAuthors.indexOf(authorId) !== -1;
    }

    let profile;

    if (authorId !== 'ALL') {
      profile = (
        <div className="d-inline mr-3">
          <ProfileImage
            userId={authorId}
            allowUserToChangePic={false}
            allowClickToPortfolio={false}
            username={author}
            size={24}
          />
        </div>
      );
    }

    let className = classnames(
      'mt-3 mb-3 d-flex align-items-center list-item',
      {
        'mb-3': authorId !== 'ALL',
      },
    );

    return (
      <div
        onClick={() => onSelect(authorId)}
        className={className}
        key={authorId}
      >
        <div className="checkbox-container d-inline-flex mr-3">
          {selected && <Icon icon={ICONS.CHECK} className="av-blue" size={8} />}
        </div>
        <div className="flex-fill m-0">
          {profile}
          <span>{author}</span>
        </div>
      </div>
    );
  }

  function renderItems() {
    let filterAuthors = authors.filter(
      i => i.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
    );
    return filterAuthors.map(author => {
      return renderItem(author.username, author.userId);
    });
  }

  return (
    <div
      className="position-absolute bg-av-white w-100 author-tree pt-3"
      style={{ zIndex: 1 }}
      onClick={e => e.stopPropagation()}
    >
      <div className="d-flex align-items-center search-container mb-3 av-gray pb-2 ml-3 mr-3">
        <Icon icon={ICONS.SEARCH} />
        <input
          type="text"
          className="flex-fill ml-3 mr-3"
          placeholder="Search Users"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <Icon
          icon={ICONS.CLOSE}
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {authors.length !== 1 ? (
        <div className="pl-3 pr-3">{renderItem(allAuthorsText, 'ALL')}</div>
      ) : null}
      <div
        className="pr-3 bg-av-light-gray"
        style={{
          paddingLeft: '25px',
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        {renderItems()}
      </div>
    </div>
  );
}

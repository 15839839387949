import React, { Component } from "react";
import { Row, Col, BUtton, Radio, FormGroup, Image } from "react-bootstrap";
import { Icon, ICONS } from "../../../../../components/Icons";
import Checkbox from "components/elements/atoms/Checkbox/Checkbox";
import "./ReviewForm.scss";
import { LOGIN_USERNAME_REQUIRE } from "../../../../../redux/actions";

class ReviewForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="form-group review-form">
        {/*this.renderVotes()*/}
        <div className="content">
          {this.renderFactForm()}
          {this.renderOpinionForm()}
          <div className="error-message">{this.props.errorMessage}</div>
        </div>
      </div>
    );
  }

  renderFactForm = () => {
    if (this.props.type != "Fact") return;

    const {
      user,
      userReview,
      handleReviewSubmitted,
      handleNeutralLanguage,
      handleVerifiedInSource,
      neutralLanguage,
      verifiedInSource,
    } = this.props;
    const verifyBtnClass = userReview == "verify" ? " active" : "";
    const rejectBtnClass = userReview == "reject" ? " active" : "";

    return (
      <div>
        {this.renderChallengeText()}
        <div className="review-options">
          <div className="option">
            <Checkbox
              handleChange={handleNeutralLanguage}
              checked={neutralLanguage}
              disabled={!this.props.editable}
            >
              The Point uses neutral language - it's not expressing an Opinion.
            </Checkbox>
          </div>
          <br />
          <div className="option">
            <Checkbox
              handleChange={handleVerifiedInSource}
              checked={verifiedInSource}
              disabled={!this.props.editable}
            >
              The Point provides reputable Sources that verify its claims.
            </Checkbox>
          </div>
        </div>
        <br />
        <div className="button-container">
          <button
            type="button"
            className={"btn" + verifyBtnClass}
            disabled={!this.props.editable}
            onClick={() => this.props.handleReviewSubmitted("verify")}
            id={"verify"}
          >
            <Icon icon={ICONS.VERIFIED} className="av-green" />
            Verify
          </button>
          <button
            type="button"
            className={"btn" + rejectBtnClass}
            disabled={!this.props.editable}
            onClick={() => this.props.handleReviewSubmitted("reject")}
            id={"reject"}
          >
            <Icon icon={ICONS.REJECTED} />
            Reject
          </button>
        </div>
      </div>
    );
  };

  renderChallengeText = () => {
    if (!this.props.isChallenge) return;

    const { point } = this.props;

    return (
      <div className="challenge-text">
        This review has been {point.status} at Level {point.level}. Your
        challenge will escalate the Point to Level {point.level + 1} and
        re-start the review process.
      </div>
    );
  };

  renderOpinionForm = () => {
    if (this.props.type != "Opinion") return;

    const {
      userReview,
      handleBalancedLanguage,
      handleFactsSupportClaims,
      balancedLanguage,
      factsSupportClaims,
    } = this.props;

    const agreeBtnClass = userReview == "agree" ? " active" : "";
    const disagreeBtnClass = userReview == "disagree" ? " active" : "";

    return (
      <div>
        <ul className="list-unstyled">
          <li className="state-square">
            <Checkbox
              handleChange={handleBalancedLanguage}
              checked={balancedLanguage}
              disabled={!this.props.editable}
            >
              The opinion uses{" "}
              <span className="balance">balanced language.</span>
            </Checkbox>
          </li>
          <li className="state-square">
            <Checkbox
              handleChange={handleFactsSupportClaims}
              checked={factsSupportClaims}
              disabled={!this.props.editable}
            >
              The opinion provides <span className="Facts">Facts</span> to
              support its claims.
            </Checkbox>
          </li>
        </ul>
        <div className="button-container">
          <button
            type="button"
            className={"btn" + agreeBtnClass}
            disabled={!this.props.editable}
            onClick={() => this.props.handleReviewSubmitted("agree")}
            id={"agree"}
          >
            <img
              src={require("../../../../../assets/images/LikeIcon.png")}
              className="button-image"
            />
            Agree
          </button>
          <button
            type="button"
            className={"btn" + disagreeBtnClass}
            disabled={!this.props.editable}
            onClick={() => this.props.handleReviewSubmitted("disagree")}
            id={"disagree"}
          >
            <img
              src={require("../../../../../assets/images/DislikeIcon.png")}
              className="button-image"
            />
            Disagree
          </button>
        </div>
      </div>
    );
  };

  renderVotes() {
    const { onReviewChange, netVotes } = this.props;

    if (!this.props.displayReview) return;

    return (
      <Col xs={2}>
        <p className={`pointer`} onClick={() => onReviewChange("Up")}>
          <span className="fa fa-arrow-circle-up" />
        </p>
        <div className="show-review">{netVotes}</div>
        <p className={`pointer`} onClick={() => onReviewChange("Down")}>
          <span className="fa fa-arrow-circle-down" />
        </p>
      </Col>
    );
  }
}
export default ReviewForm;

import * as React from "react";
import SuggestRevisionComponent from "./SuggestRevisionComponent";
import { Modal } from "react-bootstrap";

class SuggestRevisionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {}
    };
  }

  onSave = () => {
    if (this.validate()) {
      this.props.onAction(this.props.onSave, this.props.model).then(() => {
        this.props.onClose(true);
      });
    }

    this.setState({
      isSubmitted: true
    });
  }

  onClose = () => {
    this.props.onClose(false);
  }

  onSuggestRevision = (revisionReason) => {
    var fact = this.props.model;
    this.props
      .onAction(this.props.onSuggestFactRevision, fact._id, revisionReason)
      .then(() => {
        this.props.onClose(true);
      });
  }

  render() {
    return (
      <Modal show={true} className="general-modal">
        <Modal.Header>
          <Modal.Title>Suggest a Revision to this Fact</Modal.Title>
          <div className="actions-container pull-right">
            <button
              type="button"
              className="btn btn-close"
              onClick={this.onClose}
            >
              Close
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <SuggestRevisionComponent
            fact={this.props.model}
            onSuggestRevision={this.onSuggestRevision}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuggestRevisionModal;

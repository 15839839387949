import axios from 'axios';

export function initializeLogin() {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'get',
      url: '/api/schoologyAuth/handleSchoologyLogin',
    });
    return response.data;
  };
}

export function signupUsingSchoology(data) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'post',
      url: '/api/schoologyAuth/handleSchoologySignup',
      data,
    });
    return response.data;
  };
}

export function getCognitoTokens(params) {
  return async (dispatch, getState) => {
    const response = await axios({
      method: 'get',
      url: '/api/schoologyAuth/getCognitoTokens',
      params,
    });
    return response.data;
  };
}

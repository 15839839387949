export const OPEN_MODAL = "OPEN_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export function openModal(modalName, props) {
  return {
    type: OPEN_MODAL,
    data: {
      modalName,
      props
    }
  };
}

export function hideModal(key, response) {
  return {
    type: HIDE_MODAL,
    data: {
      key,
      response
    }
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import PointImage from 'components/cards/Point/Component/PointImage/PointImage';

import { Icon, ICONS } from 'components/Icons';

import './PictureModal.scss';

class PictureModal extends Component {
  static propTypes = {
    model: PropTypes.object,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.props.onClose(false);
  };

  render() {
    const { user, point } = this.props;

    return (
      <Modal
        show={true}
        dialogClassName="custom-modal"
        className="picture-modal"
      >
        <Modal.Body>
          <Icon icon={ICONS.CLOSE} onClick={this.onClose} />
          <PointImage
            point={point}
            user={user}
            isSaved
            isEditing={false}
            onPointFieldChanged={this.props.onPointFieldChanged}
            cardType="full-screen"
            className="mt-4"
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default PictureModal;

import React from "react";
import PropTypes from "prop-types";

import { push } from "react-router-redux";
import { connect, connectAdvanced } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "redux/actions/index";

import config from "config/config.public";

// import { Icon, ICONS } from "components/Icons";

import ReactTags from "react-tag-autocomplete";
import classNames from "classnames";
import _ from "lodash";

import "./tags.scss";

class AddTags extends React.Component {
  static propTypes = {
    tags: PropTypes.array,
    allTags: PropTypes.array,
    tagTimeStamp: PropTypes.number,
    onFocusOut: PropTypes.func,
    setTags: PropTypes.func,
    actions: PropTypes.object,
    tagsLimit: PropTypes.number,
    tagsChangeable: PropTypes.bool,
    autofocus: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    let existingTags = this.modifyTags(this.props.tags);
    let suggestions = this.modifyTags(this.props.allTags);

    let tagsStale = true;
    let now = new Date().getTime();
    let dayAgo = now - 24 * 60 * 60 * 1000; //day ago in milliseconds
    if (this.props.tagTimeStamp && this.props.tagTimeStamp > dayAgo) {
      tagsStale = false;
    }

    this.state = {
      currentInputValue: "",
      suggestions,
      existingTags,
      tagsStale,
    };
  }

  componentDidUpdate(prevProps) {
    let change = false;

    if (prevProps.tags.length != this.props.tags.length) change = true;
    else {
      for (let i = 0; i < this.props.tags.length; i++) {
        if (this.props[i] !== prevProps[i]) {
          change = true;
          break;
        }
      }
    }

    if (change) {
      let existingTags = this.modifyTags(this.props.tags);
      this.setState({
        existingTags,
      });
    }
  }

  componentDidMount() {
    this.tagContainer.addEventListener("focusout", this.props.onFocusOut);
  }

  modifyTags = tags => {
    let modifiedTags = tags.map((tag, index) => {
      let modifiedTag = {
        id: index,
        name: tag,
      };
      return modifiedTag;
    });
    return modifiedTags;
  };

  handleAddition = tagObject => {
    if (
      this.state.currentInputValue.length >= 3 &&
      this.state.currentInputValue.length <= 20
    ) {
      let newTags = this.props.tags.concat(tagObject.name);
      this.props.setTags(newTags);
    }
    this.setState({
      currentInputValue: "",
    });
  };

  handleDelete = index => {
    let newTags = [
      ...this.props.tags.slice(0, index),
      ...this.props.tags.slice(index + 1),
    ];
    this.props.setTags(newTags);
  };

  handleInputChange = input => {
    //if(this.state.suggestions && this.state.suggestions.length > 1) return; //Already loaded
    //if(this.props.allTags && this.props.allTags.length > 1) return; //Already loaded

    //If tags are stale, re-load them
    //var now = new Date().getTime();
    //var dayAgo = now - 24 * 60 * 60 * 1000; //day ago in milliseconds

    this.setState({
      currentInputValue: input,
    });

    if (this.state.tagsStale) {
      this.props.actions.loadTags().then(tags => {
        let suggestions = this.modifyTags(tags.tags);
        this.setState({
          suggestions,
          tagsStale: false,
        });
      });
    }
    // else {
    //   var suggestions = this.modifyTags(this.props.allTags);
    //   this.setState({suggestions});
    // }
  };

  render() {
    return <div className="auto-complete">{this.renderAddTagsContainer()}</div>;
  }

  renderAddTagsContainer() {
    //var existingTags = this.modifyTags(this.props.tags);
    //Existing tags come from parent and are modified in getInitialState and componentWillReceieveProps
    //Suggestions are loaded on first input

    // console.log(
    //   "renderAddTagsContainer",
    //   this.state.existingTags.length,
    //   this.props.tagsLimit,
    //   this.state.currentInputValue.length
    // );

    const limitReached = this.state.existingTags.length >= this.props.tagsLimit;

    let containerClass = classNames("tags-container", {
      "tags-limit-reached": limitReached,
    });

    return (
      <div
        className={containerClass}
        ref={tagContainer => {
          this.tagContainer = tagContainer;
        }}
      >
        <ReactTags
          readOnly={!this.props.tagsChangeable}
          tags={this.state.existingTags}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleInputChange={this.handleInputChange}
          allowNew={true}
          autofocus={this.props.autofocus}
          classNames={{
            root: "react-tags",
            rootFocused: "is-focused",
            selected: "react-tags__selected ",
            selectedTag: "react-tags__selected-tag piped",
            selectedTagName: "react-tags__selected-tag-name piped",
            search: "react-tags__search",
            searchInput: "react-tags__search-input piped",
            suggestions: "react-tags__suggestions",
            suggestionActive: "is-active",
            suggestionDisabled: "is-disabled",
          }}
        />
        {!this.state.existingTags.length &&
          !this.state.currentInputValue.length && (
            <span className="tags-help-text av-green">
              Type a tag to stay organized
            </span>
          )}

        {!this.state.existingTags.length &&
          !!this.state.currentInputValue.length && (
            <span className="tags-help-text av-green">
              Hit enter to add the tag
            </span>
          )}

        {!!this.state.existingTags.length &&
          !this.state.currentInputValue.length &&
          !limitReached && (
            <span className="tags-help-text av-green">
              Add another tag or click to remove
            </span>
          )}

        {limitReached && (
          <span className="tags-help-text limit-reached av-orange">
            {this.props.tagsLimit} tag limit, click to remove
          </span>
        )}

        {this.state.currentInputValue.length > 1 &&
          this.state.currentInputValue.length < 3 && (
            <span className="tags-help-text av-red">
              tag must be at least 3 characters
            </span>
          )}

        {this.state.currentInputValue.length > 20 && (
          <span className="tags-help-text av-red">
            tag exceeds 20 character limit
          </span>
        )}
      </div>
    );
  }

  onFilterSuggestions(searchString, dataList) {
    if (searchString) {
      return _.filter(dataList, data => {
        return data.toLowerCase().search(searchString.toLowerCase()) > -1;
      });
    }

    return dataList;
  }
}

AddTags.defaultProps = {
  tagsLimit: config.ux.tagsLimit,
};

function mapStateToProps(state) {
  //const authenticated = state.user.authenticated;
  //const user = state.user.user;
  const allTags = state.tags.tags;
  const tagTimeStamp = state.tags.timeStamp;

  return {
    allTags,
    tagTimeStamp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(AddTags);

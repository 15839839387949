import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';
import { convertCamelCase } from 'helpers';
import React from 'react';

export default function BrowserExtensionStatus({ source, handleFormChange }) {
  const handleChange = (componentType, name, value) => {
    const browserExtensionStatus = JSON.parse(
      JSON.stringify(source.browserExtensionStatus),
    );

    const status = browserExtensionStatus.find(
      (be) => be.componentType === componentType,
    );

    status[name] = value;

    handleFormChange('browserExtensionStatus', browserExtensionStatus);
  };

  return (
    <div className="mb-4">
      <div>
        <b>Browser Extension Status</b>
      </div>
      {source.browserExtensionStatus.map((be) => (
        <div className="row d-flex align-items-center">
          <div className="col-xs-4">
            {convertCamelCase(be.componentType || '-')}
          </div>
          <div className="col-xs-8">
            <RadioGroup
              name={`browserExtensionStatus-${be.componentType}`}
              handleChange={(e) =>
                handleChange(be.componentType, 'status', e.target.value)
              }
              errorMessage=""
              className="d-flex"
            >
              <RadioOption
                name={`browserExtensionStatus-${be.componentType}`}
                id={`working-${be.componentType}`}
                value="working"
                label="Working"
                checked={be.status === 'working'}
              />
              <RadioOption
                name={`browserExtensionStatus-${be.componentType}`}
                id={`notWorking-${be.componentType}`}
                value="notWorking"
                label="Not Working"
                className="ml-4"
                checked={be.status === 'notWorking'}
              />
            </RadioGroup>
          </div>
        </div>
      ))}
    </div>
  );
}

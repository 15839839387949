import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ProfileImage from "components/elements/molecules/Images/profileImage";
import "./messageThread.scss";

const MessageList = props => {
  const { point, messageThread, user } = props;

  return (
    <div className="message-list">
      {messageThread &&
        messageThread.messages &&
        messageThread.messages.map((message, index) =>
          point.authorId == message.messageAuthorId ? (
            <div className="your-message" key={index}>
              <div className="sent-message">
                {/* <div className="message-label">“I add your fact”</div> */}
                {renderPoint(message)}
                <div className="message-body">{message.note}</div>
              </div>
              <div className="user-profile-image">
                <ProfileImage
                  userId={message.messageAuthorId}
                  size={25}
                />
              </div>
            </div>
          ) : (
            <div className="other-message" key={index}>
              <div className="user-profile-image">
                <ProfileImage
                  userId={message.messageAuthorId}
                  size={25}
                />
              </div>
              <div className="sent-message">
                {renderPoint(message)}
                <div className="message-body">{message.note}</div>
              </div>
            </div>
          )
        )}
    </div>
  );
};

const renderPoint = message => {
  if (message.pointId) return "There's a Point";
  else return;
  //else return "There's no Point";
};

export default MessageList;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
/* eslint-disable default-case */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'throttle-debounce';
import moment from 'moment';

import * as Actions from 'redux/actions/index';
// import PointComponent from './PointComponent';

import PointCard from './Views/PointCard/PointCard';

import classnames from 'classnames';
import { Element, scroller } from 'react-scroll';
import TourHighlight from 'components/elements/molecules/TourHighlight';
const TUTORIAL_STEP = 11; // STEP count for tutorial

require('./Point.scss');

class PointContainer extends React.Component {
  static defaultProps = {
    isSelectionEnabled: true,
    isMock: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      pointObject: '',
      errorMessages: {},
      isEditing: false,
      isEditingFields: {
        text: false,
        source: false,
        tags: false,
        image: false,
        sourceTimeStampStart: false,
      },
    };

    this.savePointDebounced = debounce(500, this.props.actions.savePoint);

    this.target = React.createRef();
    this.elem = React.createRef();
  }

  componentDidMount() {
    if (!this.props.point) {
      this.props.actions.getPoint(this.props.pointId);
    }

    if (this.props.cardType === 'embed' && !this.props.isInEmbeddedCollection) {
      this.setEmbedHeight();

      window.addEventListener('resize', this.setEmbedHeight);
    }
  }

  componentWillUnmount() {
    if (this.props.cardType === 'embed' && !this.props.isInEmbeddedCollection) {
      window.removeEventListener('resize', this.setEmbedHeight);
    }
  }

  render() {
    const { className } = this.props;
    const point = this.state.isEditing
      ? this.state.pointInState
      : this.props.point;

    const { _id: pointId } = point;

    const {
      tourStatus,
      tourStep,
      pointId: targetWalkthroughPointId,
    } = this.props.walkthrough;

    const componentClass = classnames(
      'point-card-main',
      className,
      this.props.cardType,
      {
        // 'bg-av-white shadow':
        //   this.props.cardType !== 'page' && this.props.cardType !== 'claim',
        'point-card-main-highlighted':
          tourStatus === 'inProgress' &&
          tourStep === TUTORIAL_STEP &&
          targetWalkthroughPointId === pointId,
        // outline: this.props.view == 'outline',
        disableClickThru: this.props.disableClickThru,
        editing: this.state.isEditing,
        'not-editing': !this.state.isEditing,
        focused: this.props.focused,
        selected: this.props.selected,
        'p-3 bg-av-light-blue': this.props.cardType === 'claim',
      },
    );

    if (this.props.errorMessage)
      return (
        <div className="error-message point-load-error">
          Point failed to load... Check permissions and privacy.
        </div>
      );

    if (!point) return <div className="loading-point">Loading Point</div>;

    const confirmDrop = this.props.dnd.targetParent.id === point._id;

    return (
      <div
        className={componentClass}
        onClick={this.onClick}
        ref={this.elem}
        onKeyDown={this.onKeyDown}
        point-card-point-id={pointId}
      >
        {this.renderCard()}
        {this.renderDropMessages()}
        <TourHighlight
          target={this.target}
          placement="bottom"
          step={TUTORIAL_STEP}
          visible={targetWalkthroughPointId === pointId}
        >
          <Element name="scroll-to-element">
            Click to see your new Point!
          </Element>
        </TourHighlight>
      </div>
    );
  }

  renderCard = () => {
    const point = this.state.isEditing
      ? this.state.pointInState
      : this.props.point;

    return (
      <PointCard
        key={this.props.key}
        point={point}
        view={this.props.view}
        user={this.props.user}
        authenticated={this.props.authenticated}
        cardType={this.props.cardType}
        citationStyle={this.props.citationStyle}
        onPointFieldChanged={this.onPointFieldChanged}
        errorMessages={this.state.errorMessages}
        onClick={this.onClick}
        allowRemove={this.props.allowRemove}
        onReviewPoint={() => this.goToPointPage(true)}
        onRemove={this.props.onRemove}
        isEditing={this.state.isEditing}
        isEditingFields={this.state.isEditingFields}
        onToggleEdit={this.onToggleEdit}
        onEditPointField={this.onEditPointField}
        allowDownload={this.props.allowDownload}
        showTotalSubPoints={this.props.showTotalSubPoints}
        showReviews={this.props.showReviews}
        toggleReviewModal={this.props.toggleReviewModal}
        totalReviews={this.props.totalReviews}
        userPointReview={this.props.userPointReview}
        updateMasonry={this.props.updateMasonry}
        canDrop={this.props.canDrop}
        focused={this.props.focused}
        // isDragging={this.props.isDragging}
        showTimeStamp={this.props.showTimeStamp}
        onClickTimeStamp={this.props.onClickTimeStamp}
        showImport={this.props.showImport}
        onImport={this.props.onImport}
        hideSave={this.props.hideSave}
        hideMenu={this.props.hideMenu}
        subPointInfoPermissionLevel={this.props.subPointInfoPermissionLevel}
        previousPoint={this.props.previousPoint}
        isInvited={this.props.isInvited}
        onSavePoint={this.onSavePoint}
        parentObject={this.props.parentObject}
        onPointDetailView={this.props.onPointDetailView}
        pointDetailView={this.props.pointDetailView}
        fourPackShowActiveTab={this.props.fourPackShowActiveTab}
        hideHeader={this.props.hideHeader}
        hideText={this.props.hideText}
        subPointInfo={this.props.subPointInfo}
        fourPackEvidenceDisabled={this.props.fourPackEvidenceDisabled}
        fourPackQTPDisabled={this.props.fourPackQTPDisabled}
        fourPackRTPDisabled={this.props.fourPackRTPDisabled}
        fourPackShareDisabled={this.props.fourPackShareDisabled}
        fourPackActiveTab={this.props.fourPackActiveTab}
        captions={this.props.captions}
        activeCaption={this.props.activeCaption}
        showOnlyActiveCaption={this.props.showOnlyActiveCaption}
        onRTPCreate={this.props.onRTPCreate}
        onFourPackAction={this.props.onFourPackAction}
        isSelectionEnabled={this.props.isSelectionEnabled}
        isUpdateReview={this.props.isUpdateReview}
        claimQTPId={this.props.claimQTPId}
        claimRTPId={this.props.claimRTPId}
        collection={this.props.collection}
        rid={this.props.rid}
        onEvent={this.props.onEvent}
        collectionPoint={this.props.collectionPoint}
        isMock={this.props.isMock}
        showProvideEvidence={this.props.showProvideEvidence}
        isExtensionCL={this.props.isExtensionCL}
        courseId={this.props.courseId}
        priorityUsers={this.props.priorityUsers}
        priorityNumQTP={this.props.priorityNumQTP}
        priorityNumRTP={this.props.priorityNumRTP}
        teacherMode={this.props.teacherMode}
        studentMode={this.props.studentMode}
        topicId={this.props.topicId}
        showCTPs={this.props.showCTPs}
        moreHeaderActions={this.props.moreHeaderActions}
      />
    );
  };

  onKeyDown = (e) => {
    const { cardType, onSavePoint } = this.props;

    if (cardType === 'outline') {
      if (e.keyCode === 13 && e.shiftKey) {
        onSavePoint();
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  setEmbedHeight = () => {
    const height = this.elem.current.clientHeight;
    console.log('Setting embed height of point card');
    //console.log("The height of the card is "+height);
    const adjustedHeight = height + 50;
    window.parent.postMessage(
      JSON.stringify({
        src: window.location.toString(),
        context: 'iframe.resize',
        height: adjustedHeight,
      }),
      '*',
    );
  };

  renderDropMessages() {
    const { confirmDrop } = this.props;

    if (!confirmDrop) {
      return null;
    }

    return (
      <div className="card-overlay">
        <p>Are you sure you want to move your selected Point here?</p>
        <div>
          <button className="btn btn-primary" onClick={this.onConfirmDrop}>
            Yes
          </button>{' '}
          <button className="btn btn-danger" onClick={this.onCancelDrop}>
            No
          </button>
        </div>
      </div>
    );
  }

  onConfirmDrop = (e) => {
    e.stopPropagation();
    this.props.actions.moveSubPointOnServer('target');
  };

  onCancelDrop = (e) => {
    e.stopPropagation();
    this.props.actions.moveSubPointOnServer('drag');
    this.props.actions.updateTargetInfo(null, null, null, null);
  };

  onToggleEdit = () => {
    const { point } = this.props;

    let newEditingFields = {};
    let pointInState;

    if (this.state.isEditing) {
      newEditingFields = {
        text: false,
        source: false,
        tags: false,
        image: false,
      };
      pointInState = null;

      this.props.dispatch({
        type: 'FINISH_EDITING_POINT',
        data: {
          pointId: point._id,
        },
      });
    } else {
      const imageExists =
        point.photoLicenseTerms &&
        (point.photoLicenseTerms.status == 'accepted' ||
          point.photoLicenseTerms.acceptTerms);

      newEditingFields = {
        text: point.text.length > 0,
        source: !!point.sourceName && point.sourceName.length > 0,
        tags: point.tags && this.props.point.tags.length > 0,
        image: point.showPhoto && imageExists,
      };
      pointInState = {
        ...point,
        showQuote: point.sourceName,
        showTags: point.tags.length,
      };

      // props for image carousel
      pointInState.sourceImages = [];

      if (pointInState.hostedImageURL) {
        pointInState.sourceImages.push(pointInState.hostedImageURL);
      } else if (pointInState.sourceImageURL) {
        pointInState.sourceImages.push(pointInState.sourceImageURL);
      }

      pointInState.sourceImageActiveIndex = 0;

      this.props.dispatch({
        type: 'START_EDITING_POINT',
        data: {
          pointId: point._id,
        },
      });
    }
    this.setState({
      isEditing: !this.state.isEditing,
      isEditingFields: newEditingFields,
      pointInState,
      errorMessages: {}, // Clear error messages on any toggle
    });
  };

  onEditPointField = (key) => {
    const existingFields = this.state.isEditingFields;
    const newFields = {
      ...existingFields,
      [key]: !existingFields[key],
    };

    this.setState({
      isEditingFields: newFields,
    });
  };

  onClick = (e) => {
    e.stopPropagation();

    if (this.state.isEditing) return;

    const {
      authenticated,
      selectedPoints,
      point,
      disableClickThru,
      onClick,
      actions,
      cardType,
      isSelectionEnabled,
    } = this.props;

    // Parent explicitly says do nothing onClick
    if (disableClickThru) {
      return;
    }

    // Parent explicitly gives an onClick method
    if (onClick) {
      onClick();
    }

    if (authenticated && cardType !== 'outline' && isSelectionEnabled) {
      if (selectedPoints.length == 0 || e.shiftKey) {
        actions.toggleSelectPoint(point._id);
      } else if (selectedPoints.length === 1) {
        if (selectedPoints[0] !== point._id) {
          actions.toggleSelectPoint(selectedPoints[0]);
        }

        actions.toggleSelectPoint(point._id);
      }
    }

    // Remove all the selection created by shift + click.
    // Commenting out below code to override any user selection.
    // document.getSelection().removeAllRanges();
  };

  onPointFieldChanged = (key, value) => {
    let currentPoint = this.state.isEditing
      ? this.state.pointInState
      : this.props.point;

    const sourceInfoFields = [
      'sourceText',
      'sourceTitle',
      'sourceAuthor',
      'sourceName',
      'sourceDate',
    ];

    if (key === 'sourceInfo') {
      const sourceFields = value;
      currentPoint = {
        ...currentPoint,
        ...sourceFields,
      };
    } else if (sourceInfoFields.indexOf(key) !== -1) {
      console.log('Manually changing source info');
      currentPoint = {
        ...currentPoint,
        [key]: value,
        sourceInfoModified: true,
      };
    } else if (key === 'hostedImage') {
      const hostedImageFields = value;
      currentPoint = {
        ...currentPoint,
        ...hostedImageFields,
        sourceImages: [
          ...currentPoint.sourceImages,
          hostedImageFields.hostedImageURL,
        ],
        sourceImageURL: hostedImageFields.hostedImageURL,
        sourceImageActiveIndex: currentPoint.sourceImages.length,
      };
    } else if (key === 'sourceImages') {
      currentPoint = {
        ...currentPoint,
        sourceImages: value,
        sourceImageURL: value[0],
        sourceImageActiveIndex: 0,
      };
    } else if (key === 'sourceImageURL') {
      currentPoint = {
        ...currentPoint,
        sourceImageURL: value,
        sourceImageActiveIndex: currentPoint.sourceImages.indexOf(value),
      };
    } else if (key === 'sourceTimeStampStart') {
      if (value) {
        currentPoint['sourceTimeStampStart'] = value;
      }
    } else {
      currentPoint[key] = value;
    }

    if (key === 'showPhoto' && this.state.isEditingFields.image != value) {
      // If showPhoto changed in value, change the +Image in PointEditControls
      this.onEditPointField('image');
    }

    this.setState(
      {
        pointInState: currentPoint,
      },
      async () => {
        const valid = this.validatePointField(key, value);

        if (valid) {
          console.log('Calling savePoint debounced');
          if (key === 'sourceTimeStampStart') {
            await this.props.actions.savePoint(this.state.pointInState);
            this.onEditPointField('sourceTimeStampStart');
            // this.props.onUpdateSubPointInfo(
            //   this.props.point._id,
            //   key,
            //   value,
            //   () => {
            //     this.onEditPointField('timeStamp');
            //   },
            // );
          }
        }
      },
    );
  };

  onSavePoint = async () => {
    await this.props.actions.savePoint(this.state.pointInState);
    this.onToggleEdit();
  };

  validatePointField(key, value) {
    const { errorMessages } = this.state;
    switch (key) {
      case 'text':
        if (value.length > 1000) {
          errorMessages.pointText = 'Max 1000 characters';
        } else {
          errorMessages.pointText = '';
        }
        break;
      case 'sourceTitle':
        if (value.length > 200) {
          errorMessages.sourceTitle = 'Max 200 characters';
        } else {
          errorMessages.sourceTitle = '';
        }
        break;
      case 'sourceAuthor':
        if (value.length > 200) {
          errorMessages.sourceAuthor = 'Max 200 characters';
        } else {
          errorMessages.sourceAuthor = '';
        }
        break;
      case 'sourceName':
        if (value.length > 100) {
          errorMessages.sourceName = 'Max 100 characters';
        } else {
          errorMessages.sourceName = '';
        }
        break;
      case 'sourceDate':
        let validDate = false;
        let formattedDate = '';

        if (!value) {
          validDate = true; // Date can be empty
        } else if (moment(value, 'YYYY', true).isValid()) {
          validDate = true;
          formattedDate = value;
        } else if (moment(value, ['M-YYYY', 'M/YYYY'], true).isValid()) {
          validDate = true;
          formattedDate = moment(value, ['M-YYYY', 'M/YYYY'], true).format(
            'MMMM YYYY',
          );
        } else if (moment(value, ['M-D-YYYY', 'M/D/YYYY'], true).isValid()) {
          validDate = true;
          formattedDate = moment(value).format('MMMM D YYYY');
        }

        if (!validDate) {
          errorMessages.sourceDate = "Invalid - click '?' for details";
        } else {
          errorMessages.sourceDate = '';
          this.setState({
            pointInState: {
              ...this.state.pointInState,
              sourceDate: value,
              sourceDateFormatted: formattedDate,
            },
          });
        }
        break;
      case 'type':
        if (value == 'Opinion') {
          if (errorMessages.sourceURL || errorMessages.sourceName) {
            errorMessages.sourceURL = '';
            errorMessages.sourceName = '';
          }
        }
    }

    let valid = true;
    errorMessages.overall = '';
    Object.keys(errorMessages).forEach((key) => {
      if (errorMessages[key] !== '') {
        if (key !== 'overall') {
          valid = false;
          errorMessages.overall = `Changes not saved. Please fix your errors. ("${key} ")`;
        }
      }
    });

    this.setState({
      errorMessages,
    });

    return valid;
  }

  goToPointPage(showReviewOnOpen) {
    if (this.props.location || this.props.isEmbed) {
      window.open(`${window.origin}/Point/${this.props.pointId}`, '_blank');
      return;
    }

    /********Point Modal */

    let props = {
      model: {
        point: this.props.point,
        isModal: true,
        parentObject: this.props.parentObject,
        showReviewOnOpen,
      },
    };
    this.props.actions.setParent(this.props.parentObject);
    this.props.dispatch(Actions.openModal('Point', props));
  }
}

function mapStateToProps(state, ownProps) {
  const { authenticated } = state.user;
  const { user } = state.user;
  const { points } = state;
  let pointId;
  let point;
  let pointObject;
  let reviews;
  let userPointReview;
  let isSavingPoint = false;
  let selected = false;
  let errorMessage = null;

  if (ownProps.match && ownProps.match.params && ownProps.match.params.id) {
    pointId = ownProps.match.params.id;
  } else {
    pointId = ownProps.point._id;
  }

  if (ownProps.cardType == 'preview') {
    point = ownProps.point;
  } else {
    pointObject = points[pointId];

    if (pointObject) {
      point = {
        ...pointObject.point,
        ...ownProps.point,
      };
      reviews = pointObject.reviews || [];
      userPointReview = pointObject.userPointReview;
      isSavingPoint = pointObject.isSavingPoint;
      errorMessage = pointObject.errorMessage;
    }
    // point = pointObject ? pointObject.point : null;
  }

  if (point) {
    selected = points.selectedPoints.includes(point._id);
  }

  let isInvited = false;

  if (pointObject) {
    const { invitationStatus } = pointObject;
    isInvited = invitationStatus === 'invited' && point.authorId !== user._id;
  }

  const confirmDrop = point && state.page.dnd.targetParent.id == point._id;

  return {
    authenticated,
    user,
    walkthrough: state.walkthrough,
    point: point || ownProps.point,
    pointId,
    reviews,
    userPointReview,
    isSavingPoint,
    dnd: state.page.dnd,
    selected,
    errorMessage,
    isInvited,
    selectedPoints: points.selectedPoints,
    confirmDrop,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

PointContainer.propTypes = {
  disableClickThru: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  location: PropTypes.object,
  point: PropTypes.object,
  pointId: PropTypes.string,
  point: PropTypes.object,
  actions: PropTypes.object,
  parentObject: PropTypes.object,
  setParent: PropTypes.func,
  allowRemove: PropTypes.bool,
  allowDownload: PropTypes.bool,
  cardType: PropTypes.oneOf([
    'default',
    'page',
    'preview',
    'preview highlight-on-hover',
    'embed',
    'list',
    'outline',
    'claim',
  ]),
  isEmbed: PropTypes.bool,
  isInEmbeddedCollection: PropTypes.bool,
  user: PropTypes.object,
  authenticated: PropTypes.bool,
  walkthrough: PropTypes.object,
  view: PropTypes.oneOf(['card', 'outline']),
  canDrop: PropTypes.bool,
  confirmDrop: PropTypes.bool,
  onConfirmDrop: PropTypes.func,
  onCancelDrop: PropTypes.func,
  focused: PropTypes.bool,
  onUpdateSubPointInfo: PropTypes.func,
  subPointInfoPermissionLevel: PropTypes.string,
  errorMessage: PropTypes.string,
  onPointDetailView: PropTypes.func,
  pointDetailView: PropTypes.object,
  className: PropTypes.string,
  isSelectionEnabled: PropTypes.bool,
};

PointContainer = connect(mapStateToProps, mapDispatchToProps)(PointContainer);

export default PointContainer;

export const SHOW_HINT = 'SHOW_HINT';
export const HIDE_HINT = 'HIDE_HINT';

export function showHint(
  type,
  message,
  hintIcon = null,
  showLinkToNewsClub = false,
  onTop = false,
) {
  return {
    type: SHOW_HINT,
    data: {
      type,
      message,
      hintIcon,
      showLinkToNewsClub,
      onTop,
    },
  };
}

export function hideHint() {
  return {
    type: HIDE_HINT,
  };
}

import * as Actions from 'redux/actions/index';

const initialState = {
  mediaLoggedAnalysis: [],
};

export default function hint(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGGED_MEDIA_ANALYTICS: {
      return {
        ...state,
        mediaLoggedAnalysis: action.payload,
      };
    }
    default:
      return state;
  }
}

import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import sourceFormatOptions from 'config/sourceFormat';

import './UpdateSourceFormat.scss';
import DropDown from 'components/elements/atoms/DropDown/DropDown';

function UpdateSourceFormat({
  courseId,
  topicId,
  reading,
  onClose,
  actions,
}) {
  const [sourceFormat, setSourceFormat] = useState(null);
  const [error, setError] = useState('');

  const submitSourceFormat = () => {
    if (!sourceFormat.value) {
      return null;
    }

    actions
      .updateSourceFormatForReading(
        courseId,
        topicId,
        reading.pointId,
        sourceFormat.value,
        reading.sourceFormat,
      )
      .then(() => onClose())
      .catch((error) => {
        setError(
          error.message ||
          'Error occurred while adding quiz questions. Please try again.',
        );
      });
  };

  const defaultValueOption = sourceFormatOptions.find(s => s.value === reading.sourceFormat)

  function renderSourceFormat() {
    return (
      <>
        <div className="dropdown">
          <DropDown
            defaultValue={defaultValueOption}
            labelName="Source Format"
            value={sourceFormat || defaultValueOption}
            onChange={(selectedOption) => setSourceFormat(selectedOption)}
            options={sourceFormatOptions}
          />
        </div>
      </>
    );
  }

  return (
    <Modal show={true} className="manage-quiz-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Update Source Format</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div>
          <b>Reading:</b> {reading.title}
        </div>
        {renderSourceFormat()}
        <div className="mt-5">
          <RectangularButton
            className="next-button"
            disabled={!sourceFormat}
            onClick={() => submitSourceFormat()}
          >
            Submit
          </RectangularButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(UpdateSourceFormat);

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileImage from 'components/elements/molecules/Images/profileImage';
import * as Actions from 'redux/actions/index';

import './STPCard.scss';

function STPCard({ stp, className, point, actions }) {
  // useEffect(() => {
  //   if (!point) {
  //     actions.getPoint(stp.pointId);
  //   }
  // }, []);
  const containerClassName = classnames('stp-card p-3', className);
  return (
    <div className={containerClassName}>
      <div className="user-name">
        <ProfileImage userId={stp.user._id} username={stp.user.username} />
        <span className="ml-3">{stp.user.username}</span>
      </div>
      <div className="stp-text">{stp.text}</div>
    </div>
  );
}

function mapStateToProps(state, props) {
  let point = state.points[props.stp.pointId];
  return {
    user: state.user,
    point,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(STPCard);

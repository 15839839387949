import * as React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import { isMobile } from 'helpers';
import { Icon, ICONS } from 'components/Icons';

import './pointParentHeader.scss';

class PointParentHeader extends React.Component {
  static propTypes = {
    parentObject: PropTypes.object,
    // goBack: PropTypes.func,
    scrolled: PropTypes.bool,
    // onArrowClicked: PropTypes.func,
    showSiblingArrows: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      // showScrollingHeader: false
    };
  }

  render() {
    const { scrolled } = this.props;

    if (!this.props.parentObject) return null;

    const parentObject = this.props.parentObject.backParentObject
      ? this.props.parentObject.backParentObject
      : this.props.parentObject;

    let typeText = '';
    let itemText = '';
    let displayName = '';
    if (!parentObject.name) parentObject.name = '';
    if (parentObject.type == 'collection') {
      typeText = 'Collection: ';
      //A Collection can have sub-Collections or Points
      itemText = parentObject.siblingType ? parentObject.siblingType : 'Points';
      displayName = `"${parentObject.name.substring(0, 20)}${
        parentObject.name.length > 20 ? '...' : '' + '"'
      }`;
    } else if (parentObject.type == 'point') {
      typeText = 'Point: ';
      itemText = 'Sub-Points';
      displayName = `"${parentObject.name.substring(0, 20)}${
        parentObject.name.length > 20 ? '...' : '' + '"'
      }`;
    } else if (parentObject.type == 'portfolio') {
      typeText = 'Portfolio';
      // itemText = 'Collections';
      displayName = `"${parentObject.name}'s Collections` + `"`;
    } else if (parentObject.type == 'search') {
      displayName = parentObject.name;
    }

    const detailDisplayed = scrolled ? 'row hidden-xs' : 'row';

    const { index, list } = this.props.parentObject;

    // const detailDisplayed = 'row';
    return (
      <div className="av-grayish-cyan d-flex align-items-center bg-av-light-gray point-parent-header">
        <div className="container">
          {this.renderPointName()}
          {this.renderBackLink(displayName)}
          <div className="d-flex align-items-center justify-content-end flex-fill collection-points-navigation">
            {this.renderLeftArrow()}
            <div className="mr-3 ml-3" style={{ whiteSpace: 'nowrap' }}>
              {index + 1} of {list.length} {itemText}
            </div>
            {this.renderRightArrow()}
          </div>
        </div>
      </div>
    );
  }

  renderPointName() {
    let { isModal, parentObject } = this.props;

    if (!isModal) return;

    return (
      <h5 className="av-blue d-flex align-items-center m-0">
        <Icon icon={ICONS.COLLECTION} className="mr-2" />
        {parentObject.name}
      </h5>
    );
  }

  renderBackLink(displayName) {
    if (this.props.isModal) return <div className="empty-left" />;

    if (this.props.parentObject) {
      const parentObject = this.props.parentObject.backParentObject
        ? this.props.parentObject.backParentObject
        : this.props.parentObject;
      const routeName = parentObject.type;
      if (
        routeName === 'portfolio' &&
        this.props.search &&
        this.props.search.searchTerm
      ) {
        displayName = ` '${this.props.search.searchTerm}' search results`;
      }
    }

    const backText = isMobile() ? '' : ' Back to ' + displayName;

    return (
      <div className="position-absolute" onClick={this.goBack}>
        <ActionLink
          onClick={this.goBack}
          label={backText}
          iconType="averPointIcon"
          iconName="ARROW_BACKWARD"
        />
      </div>
    );
  }

  renderLeftArrow() {
    if (!this.props.showSiblingArrows) return null;

    if (!this.props.parentObject || !this.props.parentObject.name) return null;
    const { index } = this.props.parentObject;
    if (index <= 0) return null; // You are on the first item in the parent's list

    return (
      <ActionLink
        onClick={() => this.onArrowClicked('previous')}
        iconType="averPointIcon"
        iconName="LEFT"
        iconClassName="mr-0"
        label=""
        iconSize={24}
        linkClassName="gray-3"
      />
    );
  }

  renderRightArrow() {
    if (!this.props.showSiblingArrows) return null;

    if (!this.props.parentObject || !this.props.parentObject.name) return null;
    const { list, index } = this.props.parentObject;
    if (index >= list.length - 1) return null; // You are on the last item in the parent's list
    return (
      <ActionLink
        onClick={() => this.onArrowClicked('next')}
        iconType="averPointIcon"
        iconName="RIGHT"
        label=""
        iconSize={24}
        linkClassName="gray-3"
      />
    );
  }

  onArrowClicked = (direction) => {
    const { parentObject } = this.props;
    const { list, index } = parentObject;
    let newIndex;
    if (direction === 'previous') {
      newIndex = index - 1;
    } else {
      newIndex = index + 1;
    }
    const newPageId = list[newIndex];

    if (!newPageId) return;

    this.props.actions.setParentIndex(newIndex);

    if (this.props.isModal) {
      this.props.onChangePoint(newPageId);
    } else {
      const routeName =
        parentObject.type === 'portfolio' ? 'collection' : 'Point';
      const newRoute = `/${routeName}/${newPageId}`;
      const location = {
        pathname: newRoute,
        state: {
          parentObject, // Should not need this if reading off redux stack
        },
      };
      this.props.dispatch(push(location));
    }
  };

  goBack = () => {
    if (!this.props.parentObject) {
      console.log('Error - trying to go back, but no parent object on redux');
      return;
    }

    this.props.actions.goBack(); // Pops current parent off parentStack in state

    if (this.props.isModal) {
      // this.props.onBack();
      /* All this did was call pointModal.onClose which called action.goBack. Since we this in this function, don't do again */
    } else {
      const parentObject = this.props.parentObject.backParentObject
        ? this.props.parentObject.backParentObject
        : this.props.parentObject;

      const routeName = parentObject.type;
      let newPathname = `/${routeName}/${parentObject.id}`;

      if (routeName === 'portfolio') {
        newPathname = newPathname + '/research';
      }

      const location = {
        pathname: newPathname,
        state: {},
      };

      this.props.dispatch(push(location));
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    search: state.search['portfolio'],
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PointParentHeader),
);
